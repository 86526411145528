import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { Table, Badge } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Defect } from '../../models/defects';
import { changeDrawerContent } from '../../models/drawer';
import arrowIcon from '../../assets/icon-arrow.svg';
import DefectDrawer from '../DefectDrawer/NewDrawer';

interface FlightDefectsTableProps {
  defects: Defect[];
}

const TableWrapper = styled.div`
  padding: 0 1px;
`;

const ArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FlightDefectsTable: React.FC<FlightDefectsTableProps> = ({ defects }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleRowClick = (id): void => {
    dispatch(changeDrawerContent({ payload: { content: <DefectDrawer defectId={id} />, backButton: true } }));
  };

  const status = useMemo(() => {
    return {
      open: {
        color: 'amber',
      },
      resolved: {
        badge: 'success',
        text: formatMessage({ id: 'status.resolved' }),
      },
      overdue: {
        color: 'red',
      },
      deferral_pending: {
        color: 'red',
      },
      draft: {
        color: 'grey',
      },
    };
  }, [formatMessage]);
  const columns = useMemo(
    () => [
      {
        accessor: 'status',
        width: 60,
        Header: formatMessage({ id: 'title.status' }),
        Cell: ({ value }): ReactNode => {
          if (value) return <Badge color={status[value].color} />;
          return null;
        },
      },
      {
        Header: formatMessage({ id: 'title.numberShort' }),
        accessor: 'number',
        width: 70,
      },
      {
        Header: formatMessage({ id: 'title.item' }),
        accessor: 'details',
        className: 'columnItem',
        width: 270,
        Cell: ({ value }): string => value || '-',
      },
      {
        Header: formatMessage({ id: 'title.type' }),
        accessor: 'display_data.type',
        Cell: ({ value }): string => value || '-',
        width: 70,
      },
      {
        Header: formatMessage({ id: 'title.ata' }),
        accessor: 'display_data.ata',
        Cell: ({ value }): string => {
          if (value === 'UNKNOWN') return '-';
          const number = value?.split(' ')[0];
          return number || '-';
        },
        width: 70,
      },
      {
        Header: formatMessage({ id: 'title.daysRemainingShort' }),
        accessor: 'days_remaining',
        width: 80,
        className: 'date',
        Cell: ({ value }): number => {
          if (value && Number(value) < 0) {
            return 0;
          }
          if (value) {
            return value;
          }
          return 0;
        },
      },
      {
        width: 50,
        id: 'arrow',
        Cell: ({ row }): ReactNode => {
          if (row?.original?.id)
            return (
              <ArrowWrapper>
                <img src={arrowIcon} alt="arrow icon" />
              </ArrowWrapper>
            );
          return null;
        },
      },
    ],
    [formatMessage, status],
  );

  const data = useMemo(() => defects, [defects]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );
  return (
    <TableWrapper>
      <Table
        tableInstance={tableInstance}
        handleRowClick={(row): void => (row?.original?.id ? handleRowClick(row.original.id) : null)}
      />
    </TableWrapper>
  );
};

export default FlightDefectsTable;
