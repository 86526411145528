/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, Button } from '@arcflight/tf-component-library';
import ToolTip from '../NewTooltip/NewTooltip';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import settingsIcon from '../../assets/icon-card-advanced-settings.svg';
import AircraftSettingsCard from './AircraftSettingsCard';

interface AircraftAdvancedSettingsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  padding: 0 16px 16px 16px;
  @media (max-width: 1790px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const OneGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  grid-column: span 6;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column: span 5;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column: span 4;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: span 3;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
    grid-column: span 2;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '600' : '400')};
  line-height: ${({ edit }): string => (edit ? '1.33' : 'normal')};
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

export const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
  min-height: 18px;
  text-transform: ${({ cap }): string => (cap ? 'capitalize' : 'none')};
  max-width: ${({ maxWidth }): string => maxWidth || 'unset'};
`;

export const ItemWrapper = styled.div`
  grid-column: ${({ span }): string => (span ? `span ${span}` : 'auto')};
  padding-right: 24px;
  @media (max-width: 451px) {
    grid-column: 1;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 16px 0 16px 16px;
`;

export const SaveWrapper = styled.div`
  margin-right: 16px;
`;

export const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

export const AccordionWrapper = styled.div`
  margin-bottom: 24px;
`;

const RadioWrapper = styled.div`
  display: flex;
`;

export const SubHeading = styled.div`
  grid-column: inherit;
  font-size: 10px;
  line-height: 1.6;
  color: rgba(36, 45, 65, 0.8);
  border-top: 1px solid rgba(36, 45, 65, 0.05);
  padding-top: 24px;
`;

const AircraftAdvancedSettings: React.FC<AircraftAdvancedSettingsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleInputChange = (value: any, key: string): void => {
    const newAppSettings = aircraft?.app_settings;
    newAppSettings[`${key}`] = value;
    updateAircraftData([{ value: newAppSettings, key: 'app_settings' }]);
  };

  const handleSyncInputChange = (value: string, key: string): void => {
    const newSyncLimits = aircraft?.app_settings?.sync_limits;
    let newValue = value as number | string;
    if (!value) {
      newValue = 0;
    } else {
      newValue = parseInt(value, 10);
    }
    newSyncLimits[`${key}`] = newValue;
    handleInputChange(newSyncLimits, 'sync_limits');
  };

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={settingsIcon}
            title="Advanced settings"
            buttonText="Edit advanced settings"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <StyledGrid>
          <ItemWrapper span="2" data-testid="AircraftAdvancedSettings--WorkpackApproval">
            <Label edit={editMode}>
              Workpack requires approval
              <ToolTip text="If enabled, Workpacks will enter a 'Pending' state when signed off via the public share link. These must be approved before the items are closed in our system." />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.workpacks_require_approval ? 'On' : 'Off'}</Text>
            ) : (
              <RadioWrapper>
                <RadioInputButton
                  text="Yes"
                  active={aircraft?.app_settings?.workpacks_require_approval}
                  marginRight="8px"
                  onClick={(): void => handleInputChange(true, 'workpacks_require_approval')}
                />
                <RadioInputButton
                  text="No"
                  active={!aircraft?.app_settings?.workpacks_require_approval}
                  marginRight="8px"
                  onClick={(): void => handleInputChange(false, 'workpacks_require_approval')}
                />
              </RadioWrapper>
            )}
          </ItemWrapper>
          <ItemWrapper span="2" data-testid="AircraftAdvancedSettings--IgnoreTolerance">
            <Label edit={editMode}>
              Ignore tolerance for repeats
              <ToolTip text="When creating automatic repeats of scheduled maintenance items, an item completed within + or - of its tolerance will repeat at due + interval by default. If enabled, this will have it repeat at performed + interval regardless of tolerance." />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.ignore_tolerance_for_repeats ? 'On' : 'Off'}</Text>
            ) : (
              <RadioWrapper>
                <RadioInputButton
                  text="Yes"
                  active={aircraft?.app_settings?.ignore_tolerance_for_repeats}
                  marginRight="8px"
                  onClick={(): void => handleInputChange(true, 'ignore_tolerance_for_repeats')}
                />
                <RadioInputButton
                  text="No"
                  active={!aircraft?.app_settings?.ignore_tolerance_for_repeats}
                  marginRight="8px"
                  onClick={(): void => handleInputChange(false, 'ignore_tolerance_for_repeats')}
                />
              </RadioWrapper>
            )}
          </ItemWrapper>
          <ItemWrapper span="2" data-testid="AircraftAdvancedSettings--SendEmails">
            <Label edit={editMode}>
              Send reminder emails
              <ToolTip text="Sends email reminders to crew if a planned flight has not had a preflight hit the server 2 hours after the scheduled departure time. Note that this requires an active flight planning integration." />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.send_reminder_emails ? 'On' : 'Off'}</Text>
            ) : (
              <RadioWrapper>
                <RadioInputButton
                  text="Yes"
                  active={aircraft?.app_settings?.send_reminder_emails}
                  marginRight="8px"
                  onClick={(): void => handleInputChange(true, 'send_reminder_emails')}
                />
                <RadioInputButton
                  text="No"
                  active={!aircraft?.app_settings?.send_reminder_emails}
                  marginRight="8px"
                  onClick={(): void => handleInputChange(false, 'send_reminder_emails')}
                />
              </RadioWrapper>
            )}
          </ItemWrapper>
          <OneGridRow>
            <SubHeading>
              These settings specify how many historic records will be transferred to the iPad Application when loading
              the aircraft. All upcoming defects and maintenance items will always be transferred. A lower number will
              improve the performance of loading the aircraft on a poor internet connection.
            </SubHeading>
          </OneGridRow>
          <OneGridRow>
            <ItemWrapper data-testid="AircraftAdvancedSettings--Trips">
              <Label edit={editMode}>Trips sync limits</Label>
              {!editMode ? (
                <Text>{aircraft?.app_settings?.sync_limits?.trips || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.app_settings?.sync_limits?.trips}
                  type="text"
                  onChange={(e): void => handleSyncInputChange(e.target.value, 'trips')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper data-testid="AircraftAdvancedSettings--Defects">
              <Label edit={editMode}>Defects sync limits</Label>
              {!editMode ? (
                <Text>{aircraft?.app_settings?.sync_limits?.defects || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.app_settings?.sync_limits?.defects}
                  type="text"
                  onChange={(e): void => handleSyncInputChange(e.target.value, 'defects')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper data-testid="AircraftAdvancedSettings--MaintenanceItems">
              <Label edit={editMode}>Maintenance items sync limits</Label>
              {!editMode ? (
                <Text>{aircraft?.app_settings?.sync_limits?.scheduled_mx_items || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.app_settings?.sync_limits?.scheduled_mx_items}
                  type="text"
                  onChange={(e): void => handleSyncInputChange(e.target.value, 'scheduled_mx_items')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper data-testid="AircraftAdvancedSettings--IntermittentFaults">
              <Label edit={editMode}>Intermittent faults sync limits</Label>
              {!editMode ? (
                <Text>{aircraft?.app_settings?.sync_limits?.intermittent_faults || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.app_settings?.sync_limits?.intermittent_faults}
                  type="text"
                  onChange={(e): void => handleSyncInputChange(e.target.value, 'intermittent_faults')}
                />
              )}
            </ItemWrapper>
            <ItemWrapper data-testid="AircraftAdvancedSettings--CabinIssues">
              <Label edit={editMode}>Cabin issues sync limits</Label>
              {!editMode ? (
                <Text>{aircraft?.app_settings?.sync_limits?.cabin_issues || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.app_settings?.sync_limits?.cabin_issues}
                  type="text"
                  onChange={(e): void => handleSyncInputChange(e.target.value, 'cabin_issues')}
                />
              )}
            </ItemWrapper>
          </OneGridRow>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftAdvancedSettings;
