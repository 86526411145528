import { useMutation } from '@tanstack/react-query';
import { createFRATCategory } from '../../services/api';

const useCreateFRATCategory = ({ handleAddCategoryOnSuccess, handleAddCategoryOnError }) => {
  const addFRATCategory = useMutation({
    mutationFn: (payload: { category: string; sequence_number: number; id: string }) => {
      return createFRATCategory(payload);
    },
    onSuccess: (data) => {
      handleAddCategoryOnSuccess(data);
    },
    onError: (err: any) => handleAddCategoryOnError(err),
  });

  return addFRATCategory;
};

export default useCreateFRATCategory;
