/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { addDocument, queryAircraftDocuments, removeDocument, updateDocument } from '../../services/api';
import { AircraftDocumentsActionTypes } from '.';

export const getDocuments =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryAircraftDocuments(payload);
    if (response) {
      dispatch({
        type: AircraftDocumentsActionTypes.SET_DOCUMENTS,
        payload: response,
      });
    }
  };
export const add =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await addDocument(payload);
    dispatch({
      type: AircraftDocumentsActionTypes.SAVE,
      payload: response,
    });
    dispatch(
      addToast({
        payload: {
          title: intl.formatMessage({ id: 'message.onFileSuccess' }),
          type: ToastTypes.SUCCESS,
          category: ToastCategories.FLASH,
          message: '',
        },
      }),
    );
  };
export const remove =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    await removeDocument(payload);
    dispatch({
      type: AircraftDocumentsActionTypes.REMOVE_DOCUMENT,
      payload: {
        removeId: payload.id,
      },
    });
  };
export const update =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updateDocument(payload);
    dispatch({
      type: AircraftDocumentsActionTypes.SAVE,
      payload: response,
    });
    dispatch(
      addToast({
        payload: {
          title: intl.formatMessage({ id: 'message.onFileUpdate' }),
          type: ToastTypes.SUCCESS,
          category: ToastCategories.FLASH,
          message: '',
        },
      }),
    );
  };
