import { useQuery } from '@tanstack/react-query';
import { FRATQuestionCategory, IndividualFRAT } from '../../models/frats';
import { getIndividualFRAT } from '../../services/api';

const useGetIndividualFRATData = ({
  drawerId,
}: {
  drawerId: string;
}): { data: IndividualFRAT; isLoading: boolean; categories: string[] } => {
  const { data, isLoading = false }: { data: IndividualFRAT; isLoading: boolean } = useQuery({
    queryKey: ['individualFRAT', drawerId],
    queryFn: () => getIndividualFRAT({ id: drawerId }),
    enabled: !!drawerId,
  });

  const categories = data?.flight_risk_assessment_question_categories?.map(
    (item: FRATQuestionCategory) => item.category,
  );

  return { data, isLoading, categories };
};

export default useGetIndividualFRATData;
