/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FRATOption, FRATQuestionCategory } from '../../models/frats';
import DraggableComponent from './DraggableComponent';

interface DroppableComponentProps {
  items: FRATQuestionCategory[] | FRATOption[];
  component: string;
}

const DroppableComponent: React.FC<DroppableComponentProps> = ({ items, component }) => {
  const getListStyle = () => ({
    background: 'transparent',
    width: '100%',
    minHeight: '80px',
    '& > div:firstChild': {
      height: '80px',
    },
  });

  return (
    <Droppable droppableId="droppable" type="droppableItem">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
          {items.map((item, index) => (
            <DraggableComponent key={item.id} item={item} index={index} component={component} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableComponent;
