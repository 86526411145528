import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Exception from '../components/Exception';

const Exception500: React.FC = () => {
  const { formatMessage } = useIntl();
  return <Exception type="500" desc={formatMessage({ id: 'app.exception.description.500' })} linkElement={Link} />;
};

export default Exception500;
