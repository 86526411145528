import React from 'react';
import { RadioWrapper } from '../CommonStyledComponents/CommonStyledComponents';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

interface TFCheckboxInputProps {
  options: string[];
  onChange: (value: string) => void;
  label?: string;
  id?: string;
  value?: string[];
  tooltip?: string;
  optional?: boolean;
}

const TFCheckboxInput: React.FC<TFCheckboxInputProps> = ({
  options,
  onChange,
  label,
  id,
  tooltip,
  optional,
  value,
}) => {
  return (
    <div data-testid="TFCheckboxInput--Wrapper">
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <RadioWrapper data-testid={`TFCheckboxInput--${id}`}>
        {options.map((item) => {
          return (
            <RadioInputButton
              text={item}
              active={!!(value && value.includes(item))}
              onClick={(): void => onChange(item)}
              key={item}
              marginRight="8px"
              dataTestId={`TFCheckboxInput--${id}-${item}`}
              checkbox
            />
          );
        })}
      </RadioWrapper>
    </div>
  );
};

export default TFCheckboxInput;
