import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DatePicker, message } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import FlightIcon from '../../assets/topNav/icon-logbook.svg';
import cogIcon from '../../assets/icon-button-settings.svg';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import { DashboardState } from '../../models';
import generateIcon from '../../assets/generate.svg';
import chevron from '../../assets/arrow.svg';
import LogbookTable from '../../components/LogbookTable/LogbookTable';
import { changeDrawerContent, changeDrawerVisibility } from '../../models/drawer';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { getAllAircraft } from '../../models/aircraft/actions';
import { fetch } from '../../models/aircraftLogbook/actions';
import servers from '../../utils/servers';
import LogbookDrawer from './LogbookDrawer';

const { MonthPicker } = DatePicker;

const columnOrder = [
  'ac_registration',
  'trip_date',
  'departure_airport',
  'arrival_airport',
  'trip_number',
  'blocks_off',
  'takeoff',
  'landing',
  'blocks_on',
  'flight_time',
  'blocks_time',
  'captain',
  'first_officer',
  'landings',
  'callsign',
  'airframe_hours',
  'engine_1_hours',
  'engine_2_hours',
  'engine_3_hours',
  'engine_4_hours',
  'engine_1_cycles',
  'engine_2_cycles',
  'engine_3_cycles',
  'engine_4_cycles',
  'apu_hours',
  'apu_cycles',
  'prop_1_hours',
  'prop_2_hours',
  'prop_3_hours',
  'prop_4_hours',
  'airframe_landings',
  'airframe_cycles',
  'hobbs_arrival',
  'hobbs_departure',
  'flight_hobbs',
  'passengers_male',
  'passengers_female',
  'passengers_children',
  'passengers_infants',
  'total_passengers',
  'engine_1_oil_uplift',
  'engine_2_oil_uplift',
  'engine_3_oil_uplift',
  'engine_4_oil_uplift',
  'initial_fuel',
  'adjusted_initial_fuel',
  'total_fuel_truck_uplift',
  'total_aircraft_uplift',
  'fuel_adjustment',
  'fuel_adjustment_justification',
  'departure_fuel',
  'arrival_fuel',
  'planned_fuel_burn',
  'actual_fuel_burn',
  'srp_number',
  'flight_source',
  'fuel_uplift_quantity',
  'fuel_uplift_price',
  'fuel_uplift_currency',
  'fuel_uplift_paid',
];

const Card = styled.div`
  padding: 24px;
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 2px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
`;

const TableActions = styled.div`
  display: flex;
  align-items: center;
`;

const AllButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MonthPickerWrapper = styled.div`
  display: flex;
  height: 32px;
  margin-bottom: 8px;
  justify-content: flex-end;
`;

const PrevButton = styled.div`
  margin-right: 8px;
`;

const NextButton = styled.div`
  margin-left: 8px;
`;

const DownloadIcon = styled.img`
  margin-right: 8px;
`;

const StyledChev = styled.img`
  transform: ${({ flip }): string => (flip ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: 10px;
  margin-top: 6px;
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const DateRangeWrapper = styled.div`
  display: flex;
  margin: 32px 0 20px;
`;

const StartMonthPicker = styled.div`
  margin-right: 20px;
`;

const MonthHeader = styled.div`
  margin-bottom: 8px;
`;

const HelperText = styled.div`
  font-size: 12px;
  margin: 20px 0;
`;

const GenerateButtonWrapper = styled.div`
  margin-right: 12px;
`;

const FleetLogbook: React.FC = () => {
  const {
    userSettings: { logbook_columns, dateFormat },
    aircraft: { aircraftMap },
    aircraftLogbook: { data },
  } = useSelector((state: DashboardState) => state);

  const [checkedColumns, setCheckedColumns] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [startMonth, setStartMonth] = useState(moment());
  const [endMonth, setEndMonth] = useState(moment());

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleCogClick = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(
      changeDrawerContent({
        payload: {
          content: (
            <LogbookDrawer
              selectedAircraft={selectedAircraft}
              setSelectedAircraft={setSelectedAircraft}
              checkedColumns={checkedColumns}
              setCheckedColumns={setCheckedColumns}
            />
          ),
        },
      }),
    );
  };

  const handleMonthClick = (type: string): void => {
    if (type === 'add') {
      setSelectedMonth(moment(selectedMonth).add(1, 'month'));
    } else {
      setSelectedMonth(moment(selectedMonth).subtract(1, 'month'));
    }
  };

  const exportMonth = (value: Moment, key: string): void => {
    if (key === 'startMonth') setStartMonth(value);
    if (key === 'endMonth') setEndMonth(value);
    if (key === 'startMonth' && value.isAfter(endMonth)) {
      setEndMonth(value);
    }
    if (key === 'endMonth' && value.isBefore(startMonth)) {
      setStartMonth(value);
    }
  };

  const generateCSV = (): void => {
    const start = moment(startMonth).startOf('month').format('YYYY-MM-DD');
    const end = moment(endMonth).endOf('month').format('YYYY-MM-DD');

    const csvURL = `${servers.api}/aircraft/${selectedAircraft[0].id}/generate_csv?from_date=${start}&to_date=${end}`;

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = csvURL;
    a.click();

    message.success(formatMessage({ id: 'message.csvGenerated' }));
  };

  const generateColumnsBasedOnData = () => {
    const columns = [];
    const buildColumns = checkedColumns || logbook_columns;
    if (!buildColumns?.includes('ac_registration')) buildColumns.unshift('ac_registration');
    buildColumns
      .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
      .forEach((column) => {
        let newTitle = column.replace(/_/g, ' ');
        if (newTitle === 'airframe landings') newTitle = 'total landings';
        if (newTitle === 'ac registration') newTitle = 'registration';
        if (newTitle === 'trip date') newTitle = 'date';
        if (newTitle === 'departure airport') newTitle = 'from';
        if (newTitle === 'arrival airport') newTitle = 'to';
        if (newTitle === 'airframe hours original') newTitle = 'airframe hours';
        if (newTitle.endsWith('uplift')) newTitle = newTitle.replace(' uplift', '');
        columns.push({
          Header: newTitle,
          accessor: column,
          width: 70,
          Cell: ({ value }) => {
            let formattedValue = value && value.value ? value.value : value;
            if (newTitle === 'date') formattedValue = moment(value).format(dateFormat);
            if (value?.value === 'Brought forwards' || value?.value === 'Totals')
              formattedValue = value && value.value ? value.value : value;
            return <div>{formattedValue}</div>;
          },
        });
      });
    return columns;
  };

  useEffect(() => {
    if (aircraftMap.size > 0) {
      const firstAircraft = aircraftMap.entries().next().value[1];
      setSelectedAircraft([firstAircraft]);
    } else {
      dispatch(getAllAircraft());
    }
  }, [aircraftMap, dispatch]);

  useEffect(() => {
    if (logbook_columns) {
      setCheckedColumns(logbook_columns);
    }
  }, [logbook_columns]);

  useEffect(() => {
    const getLogbookData = (): void => {
      setLoading(true);
      const aircraftIds = selectedAircraft.map((aircraft) => aircraft.id);
      if (aircraftIds.length) {
        dispatch(
          fetch({
            payload: {
              aircraftIds,
              from: moment(selectedMonth).startOf('month').format('YYYY-MM-DD'),
              to: moment(selectedMonth).endOf('month').format('YYYY-MM-DD'),
              columns: checkedColumns,
              noTotals: true,
            },
          }),
        );
      }
    };
    if (checkedColumns) {
      getLogbookData();
    }
  }, [checkedColumns, dispatch, selectedAircraft, selectedMonth]);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <PageHeaderWrapper icon={FlightIcon} title="Logbook">
      {loading ? (
        <Loading loading />
      ) : (
        <Card>
          <HeaderWrapper>
            <PageTitle>
              Fleet Logbook
              <StyledButton onClick={handleCogClick}>
                <img src={cogIcon} alt="Settings" />
              </StyledButton>
            </PageTitle>
            <TableActions>
              <AllButtons>
                <MonthPickerWrapper>
                  <PrevButton>
                    <Button primary={false} height="32px" onClick={(): void => handleMonthClick('sub')}>
                      <StyledChev src={chevron} alt="chevron" flip />
                    </Button>
                  </PrevButton>
                  <MonthPicker
                    onChange={(val): void => setSelectedMonth(val)}
                    disabledDate={(d) => !d || d.isBefore('1902-12-31') || d.isAfter(moment().endOf('month'))}
                    value={selectedMonth}
                    format="MMM-YY"
                    allowClear={false}
                    data-test="monthPicker"
                  />
                  <NextButton>
                    <Button
                      disabled={moment(selectedMonth).format('MM-YY') === moment().format('MM-YY')}
                      primary={false}
                      onClick={(): void => handleMonthClick('add')}
                      height="32px"
                    >
                      <StyledChev src={chevron} alt="chevron" />
                    </Button>
                  </NextButton>
                </MonthPickerWrapper>
                {false && (
                  <NextButton>
                    <Button
                      size={ButtonSize.MEDIUM}
                      onClick={(): void => setModalVisible(true)}
                      disabled={selectedMonth === null}
                      data-test="csvButton"
                    >
                      <DownloadIcon src={generateIcon} alt="generate icon" />
                      {formatMessage({ id: 'form.button.generateCSV' })}
                    </Button>
                  </NextButton>
                )}
              </AllButtons>
            </TableActions>
          </HeaderWrapper>
          <LogbookTable
            columns={generateColumnsBasedOnData()}
            data={data}
            total={10}
            resetToOne={false}
            pageSize={limit}
            pageIndex={page - 1}
            onPaginationChange={(currentPage, numberOfItems): void => {
              if (numberOfItems !== limit) {
                setPage(1);
                setLimit(numberOfItems);
              } else if (currentPage !== page) {
                setPage(currentPage);
              }
            }}
          />
        </Card>
      )}
      <Modal isOpen={modalVisible} handleClose={() => setModalVisible(false)}>
        <ModalTitle>Select date range for your export</ModalTitle>
        <DateRangeWrapper>
          <StartMonthPicker>
            <MonthHeader>From beginning of</MonthHeader>
            <MonthPicker
              onChange={(val): void => exportMonth(val, 'startMonth')}
              disabledDate={(d) =>
                !d ||
                d.isBefore('1902-12-31') ||
                d.isAfter(moment().endOf('month')) ||
                d.isBefore(moment(endMonth).subtract(12, 'month'))
              }
              value={startMonth}
              format="MMM-YY"
              allowClear={false}
              data-test="monthPicker"
            />
          </StartMonthPicker>
          <div>
            <MonthHeader>To end of</MonthHeader>
            <MonthPicker
              onChange={(val): void => exportMonth(val, 'endMonth')}
              disabledDate={(d) =>
                !d ||
                d.isBefore('1902-12-31') ||
                d.isAfter(moment().endOf('month')) ||
                d.isAfter(moment(startMonth).add(12, 'month'))
              }
              value={endMonth}
              format="MMM-YY"
              allowClear={false}
              data-test="monthPicker"
            />
          </div>
        </DateRangeWrapper>
        <HelperText>Max number of months exported at one time is 12</HelperText>
        <TableActions>
          <GenerateButtonWrapper>
            <Button size={ButtonSize.MEDIUM} data-test="secondCSVButton" onClick={generateCSV}>
              <DownloadIcon src={generateIcon} alt="generate icon" />
              {formatMessage({ id: 'form.button.generateCSV' })}
            </Button>
          </GenerateButtonWrapper>
          <Button primary={false} size={ButtonSize.MEDIUM} onClick={(): void => setModalVisible(false)}>
            Cancel
          </Button>
        </TableActions>
      </Modal>
    </PageHeaderWrapper>
  );
};

export default FleetLogbook;
