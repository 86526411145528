import React, { useEffect, useCallback } from 'react';

interface HeightUpdaterProps {
  height?: number;
  onHeightChange?: (height: number) => void;
}

const HeightUpdater: React.FC<HeightUpdaterProps> = ({ height, onHeightChange }) => {
  const onWindowResize = useCallback(() => {
    const newHeight = window.innerHeight;
    if (newHeight !== height && onHeightChange) {
      onHeightChange(newHeight);
    }
  }, [height, onHeightChange]);

  useEffect(() => {
    const handleResize = () => onWindowResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onWindowResize]);

  return null;
};

export default HeightUpdater;
