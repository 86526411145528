import React from 'react';
import styles from './TFPopover.module.less';

interface TFPopoverProps {
  onClick: () => void;
  top?: string;
  left?: string;
  right?: string;
}

const TFPopover: React.FC<TFPopoverProps> = ({ children, top = null, left = null, right = null, onClick }) => {
  return (
    <button
      type="button"
      className={styles.popoverBackground}
      onClick={(): void => onClick()}
      data-testid="TFPopover--PopoverBackground"
    >
      <div className={styles.popoverContainer} style={{ top, left, right }} data-testid="TFPopover--PopoverContainer">
        {children}
      </div>
    </button>
  );
};

export default TFPopover;
