/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import { getWorkpacks, getWorkpack, deleteWorkpack, addAttachmentWorkpack, putWorkpack } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { getPublicWorkpack } from '../../services/external';
import { WorkpacksActionTypes } from '.';

export const getAllWorkpacks =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getWorkpacks(payload);
    if (response?.workpacks) {
      dispatch({
        type: WorkpacksActionTypes.SAVE_ALL,
        payload: response?.workpacks,
      });
      return response;
    }
    return null;
  };

export const getSingleWorkpack =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getWorkpack(payload.id);
    dispatch({
      type: WorkpacksActionTypes.SAVE,
      payload: response,
    });
    return response;
  };

export const updateWorkpack =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await putWorkpack(payload);
    dispatch({
      type: WorkpacksActionTypes.UPDATE,
      payload: response,
    });
    return response;
  };

export const updateReduxWorkpack =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: WorkpacksActionTypes.UPDATE,
      payload,
    });
  };

export const uploadAttachment =
  ({ files, id }) =>
  async (dispatch): Promise<any> => {
    const response = await addAttachmentWorkpack({ attachments: files, id });
    dispatch({
      type: WorkpacksActionTypes.SAVE,
      payload: response,
    });
  };

export const removeWorkpack =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await deleteWorkpack(payload);
    if (response && response.statusCode === 204) {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.workpackDeleted' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
      dispatch({
        type: WorkpacksActionTypes.DELETE,
        payload: response ? { removeId: payload } : {},
      });
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.approveItemsError' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };

export const getPublicWorkpackDetails =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getPublicWorkpack(payload);
    dispatch({
      type: WorkpacksActionTypes.SAVE_PUBLIC,
      payload: response,
    });
  };

export const setPublicWorkpackDetailsWithoutAPI =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: WorkpacksActionTypes.SAVE_PUBLIC,
      payload,
    });
  };
