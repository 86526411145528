// Forward ref components cannot have proptypes =/
/* eslint-disable react/prop-types */
import { Divider, Icon, Select, Button } from 'antd';
import { Loading } from '@arcflight/tf-component-library';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getPeople } from '../../models/people/actions';
import { getAircraftPeople } from '../../services/apiNew';
import ModalAddUser from '../ModalAddUser';
import cancel from '../../assets/icon-clear.svg';
import searchIcon from '../../assets/search.svg';
import ImageAvatar from '../ImageAvatar';
import NonStyledButton from '../NonStyledButton/NonStyledButton';
import AuthenticationWrapper from '../_utils/AuthenticationWrapper';
import { DashboardResource, UserPermission } from '../../models/userSettings';
import styles from './index.module.less';

const { Option, OptGroup } = Select;

const SelectPerson = (
  {
    addPerson = false,
    blockedPeople = [],
    value = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange = (input) => {
      /* default prop */
    },
    onBlur = () => {
      /* default prop */
    },
    settingsPage = false,
    defectDrawer = false,
    organisation_id,
    aircraft_id = '',
    updateLoading = () => {
      // default func
    },
  },
  ref,
) => {
  const { aircraftMap, peopleMap, peopleLastFetched } = useSelector(({ people, aircraft }) => ({
    peopleMap: people.peopleMap,
    peopleLastFetched: people.lastFetched,
    aircraftMap: Array.from(aircraft.aircraftMap.values()),
  }));
  const [loading, setLoading] = useState(false);
  const [peopleCallMade, setPeopleCallMade] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [focused, setFocus] = useState(false);
  const [currentCrew, setCurrentCrew] = useState([]);

  let organisation = organisation_id;
  if (!organisation && aircraft_id) {
    const selectedAircraft = aircraftMap.find((item) => item.id === aircraft_id);
    organisation = selectedAircraft.operator_id;
  }

  const usePrevious = (prevVal) => {
    const prevRef = useRef(null);
    useEffect(() => {
      prevRef.current = prevVal;
    });
    return prevRef.current;
  };

  const prevAmount = usePrevious(peopleMap);

  const callback = () => {
    setLoading(false);
  };
  const dispatch = useDispatch();
  const refetchPeople = useCallback(() => dispatch(getPeople(callback)), [dispatch]);
  const { formatMessage } = useIntl();
  const dropdownRender = useMemo(
    () =>
      addPerson
        ? (menu) => (
            <div>
              {menu}
              <Divider className={styles.divider} />
              <AuthenticationWrapper
                requiredResource={DashboardResource.PEOPLE}
                requiredPermissionLevel={UserPermission.CREATE}
              >
                <Button
                  data-testid="SelectPerson--AddPersonButton"
                  type="link"
                  className={styles.addPerson}
                  onMouseDown={() => setModalVisible(true)}
                >
                  <Icon type="plus" /> {formatMessage({ id: 'form.button.addPerson' })}
                </Button>
              </AuthenticationWrapper>
            </div>
          )
        : undefined,
    [addPerson, formatMessage],
  );
  useEffect(() => {
    if ((peopleMap.size < 1 || Date.now() - peopleLastFetched > 30000) && !peopleCallMade) {
      setLoading(true);
      setPeopleCallMade(true);
      refetchPeople();
    }
  }, [loading, refetchPeople, peopleMap, peopleLastFetched, updateLoading, peopleCallMade]);

  useEffect(() => {
    if (aircraft_id) {
      const fetchData = async () => {
        const res = await getAircraftPeople(aircraft_id);
        setCurrentCrew(res.data);
      };
      fetchData();
    }
  }, [aircraft_id]);

  useEffect(() => {
    if (peopleMap.size > 0 && !_.isEqual(prevAmount, peopleMap)) {
      updateLoading(false);
    }
  }, [peopleMap, prevAmount, updateLoading]);

  const crewOptions = currentCrew.map((crew) => (
    <Option
      key={crew.id}
      disabled={blockedPeople.includes(crew.id)}
      data-testid="SelectPerson--Option"
      data-name={`${crew.first_name} ${crew.last_name}`}
    >
      {settingsPage && (
        <div className={styles.avatarContainer}>
          <ImageAvatar avatar_url={crew.avatar_url} user={crew} />
        </div>
      )}
      <div className={styles.nameContainer}>
        <div className={styles.nameText}>{`${crew.first_name} ${crew.last_name}`}</div>
        {settingsPage && <div className={styles.positionText}>{crew.position}</div>}
      </div>
    </Option>
  ));
  const crewIds = currentCrew.map((c) => c.id);
  const options =
    peopleMap?.size &&
    Array.from(peopleMap.values())
      .filter(
        (item) =>
          (item.position === 'Crew' || item.position === 'Pilot') &&
          !crewIds.includes(item.id) &&
          organisation === item.organisation.id &&
          item?.first_name,
      )
      .sort((a, b) => a?.last_name?.localeCompare(b?.last_name))
      .map((item) => (
        <Option
          key={item.id}
          disabled={blockedPeople.includes(item.id)}
          data-name={`${item.first_name} ${item.last_name}`}
          data-testid="SelectPerson--Option"
        >
          {settingsPage && (
            <div className={styles.avatarContainer}>
              <ImageAvatar avatar_url={item.avatar_url} user={item} />
            </div>
          )}
          <div className={styles.nameContainer}>
            <div className={styles.nameText}>{`${item.first_name} ${item.last_name}`}</div>
            {settingsPage && <div className={styles.positionText}>{item.position}</div>}
          </div>
        </Option>
      ));

  const defectDrawerStaff =
    peopleMap?.size &&
    Array.from(peopleMap.values())
      .filter((item) => item.organisation.id === organisation && item?.first_name)
      .sort((a, b) => a?.last_name?.localeCompare(b?.last_name))
      .map((item) => (
        <Option
          key={item.id}
          disabled={blockedPeople.includes(item.id)}
          data-name={`${item.first_name} ${item.last_name}`}
          data-testid="SelectPerson--AllStaff"
        >
          <div className={styles.nameContainer}>
            <div className={styles.nameText}>{`${item.first_name} ${item.last_name}`}</div>
            {settingsPage && <div className={styles.positionText}>{item.position}</div>}
          </div>
        </Option>
      ));

  const optionIds =
    peopleMap?.size &&
    Array.from(peopleMap.values())
      .filter(
        (item) =>
          (item.position === 'Crew' || item.position === 'Pilot') && item?.first_name && !crewIds.includes(item.id),
      )
      .map((item) => item.id);
  const allStaff =
    peopleMap?.size &&
    Array.from(peopleMap.values())
      .filter((item) => {
        return (
          item.organisation.id === organisation &&
          item?.first_name &&
          !optionIds.includes(item.id) &&
          !crewIds.includes(item.id)
        );
      })
      .sort((a, b) => a?.last_name?.localeCompare(b?.last_name))
      .map((item) => (
        <Option key={item.id} disabled={blockedPeople.includes(item.id)} data-testid="SelectPerson--AllStaff">
          {settingsPage && (
            <div className={styles.avatarContainer}>
              <ImageAvatar avatar_url={item.avatar_url} user={item} />
            </div>
          )}
          <div className={styles.nameContainer}>
            <div className={styles.nameText}>{`${item.first_name} ${item.last_name}`}</div>
            {settingsPage && <div className={styles.positionText}>{item.position}</div>}
          </div>
        </Option>
      ));
  return (
    <div
      className={`${styles.searchHolder} ${settingsPage && focused ? styles.focused : undefined} ${
        loading && styles.searchLoading
      }`}
    >
      {settingsPage && (
        <div>
          <img
            src={searchIcon}
            alt="search"
            className={`${styles.searchIcon} ${styles.searchIconActive}`}
            data-test="searchIcon"
          />
        </div>
      )}
      {loading ? (
        <Loading loading size={32} contain />
      ) : (
        <Select
          value={value}
          onChange={onChange}
          className={settingsPage ? styles.selectPersonSettingsPage : styles.selectPerson}
          placeholder={formatMessage({ id: 'form.placeholder.selectPerson' })}
          showSearch
          optionFilterProp="children"
          notFoundContent={formatMessage({ id: 'text.noPeopleFound' })}
          filterOption={(input, option) => {
            if (option.key) {
              return option.props['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return null;
          }}
          dropdownRender={dropdownRender}
          allowClear
          data-testid="SelectPerson--Dropdown"
          onBlur={() => onBlur()}
          onFocus={() => setFocus(true)}
          ref={ref}
          clearIcon={
            settingsPage ? (
              <NonStyledButton onClick={() => onBlur()}>
                <img src={cancel} className={styles.cancelImg} alt="cancel" />
              </NonStyledButton>
            ) : undefined
          }
        >
          {defectDrawer && <OptGroup label="All staff">{defectDrawerStaff}</OptGroup>}
          {!settingsPage && !defectDrawer && <OptGroup label="Aircraft Crew">{crewOptions}</OptGroup>}
          {!settingsPage && !defectDrawer ? <OptGroup label="All Crew">{options}</OptGroup> : !defectDrawer && options}
          {!settingsPage && !defectDrawer && <OptGroup label="All staff">{allStaff}</OptGroup>}
        </Select>
      )}
      {addPerson ? (
        <ModalAddUser
          loading={loading}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          flightRolesOnly
          handleAddedPerson={onChange}
        />
      ) : undefined}
    </div>
  );
};

export default forwardRef(SelectPerson);
