import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { Label, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import ToolTip from '../../components/NewTooltip/NewTooltip';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const CustomFieldsViewMainDetails: React.FC = () => {
  const {
    aircraft: { aircraftMap },
    drawer: { drawerId },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const foundAircraft = aircraftMap.get(id);
  const customFieldsArray: any[] = foundAircraft ? Object.values(foundAircraft?.custom_fields || {}) : [];
  const foundCustomField = customFieldsArray?.find((field) => field?.key === drawerId);

  return (
    <Wrapper data-testid="CustomFieldViewMainDetails--Wrapper">
      <div data-testid="CustomFieldViewMainDetails--Title">
        <Label>
          {formatMessage({ id: 'text.title' })}
          <ToolTip text={formatMessage({ id: 'text.displayOnTheLeftSideOfIpad' })} />
        </Label>
        <Text>{foundCustomField?.title}</Text>
      </div>
      <div data-testid="CustomFieldViewMainDetails--Required">
        <Label>{formatMessage({ id: 'text.required' })}</Label>
        <Text>{foundCustomField?.required ? 'Yes' : 'No'}</Text>
      </div>
      <div data-testid="CustomFieldViewMainDetails--Type">
        <Label>
          {formatMessage({ id: 'text.type' })}
          <ToolTip text={formatMessage({ id: 'text.dataTypeEnteredIntoField' })} />
        </Label>
        <Text>{foundCustomField?.type}</Text>
      </div>
      <div data-testid="CustomFieldViewMainDetails--View">
        <Label>
          {formatMessage({ id: 'text.location' })}
          <ToolTip text={formatMessage({ id: 'text.fieldDataDisplayedOn' })} />
        </Label>
        <Text>{foundCustomField?.view}</Text>
      </div>
      <div data-testid="CustomFieldViewMainDetails--Heading">
        <Label>
          {formatMessage({ id: 'text.heading' })}
          <ToolTip text={formatMessage({ id: 'text.displayedAboveFieldOnIpad' })} />
        </Label>
        <Text>{foundCustomField?.heading}</Text>
      </div>
    </Wrapper>
  );
};

export default CustomFieldsViewMainDetails;
