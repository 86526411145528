import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import EmptyStateProfile from '../../assets/emptyState/empty-state-user-not-found.svg';
import servers from '../../utils/servers';

const ImageAvatar = ({ avatar_url }) => {
  return (
    <div>
      {avatar_url ? (
        <Avatar size="small" alt="avatar" src={`${servers.api}/${avatar_url}`} />
      ) : (
        <div>
          <Avatar size={28} alt="avatar" src={EmptyStateProfile} />
        </div>
      )}
    </div>
  );
};

ImageAvatar.defaultProps = {
  avatar_url: {},
};

ImageAvatar.propTypes = {
  avatar_url: PropTypes.object,
};

export default ImageAvatar;
