/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { PageTabs } from '@arcflight/tf-component-library';

interface TabsProps {
  value: string;
  label: string;
}

interface DamageMapsTabsProps {
  activeTab: string;
  tabList: TabsProps[];
  handleTabChange: () => void;
}

const StyledTabs = styled.div`
  background-color: #f6f8fb;
`;

const DamageMapsTabs: React.FC<DamageMapsTabsProps> = ({ activeTab, tabList, handleTabChange }) => {
  let tabDefaultValue;
  if (activeTab !== undefined && tabList) {
    // eslint-disable-next-line prefer-destructuring
    tabDefaultValue = tabList[0];
  }
  // const breadcrumb = selectedAircraft || loading.models.global ? null : this.conversionBreadcrumbList();
  const activeKeyProps: { [k: string]: any } = {
    defaultActiveKey: tabDefaultValue && tabDefaultValue.key,
  };
  if (activeTab !== undefined) {
    activeKeyProps.activeKey = activeTab;
  }
  return (
    <StyledTabs>
      <PageTabs
        items={tabList}
        selectedItem={tabList.find((item) => item.value === activeTab)}
        onSelect={handleTabChange}
      />
    </StyledTabs>
  );
};

export default DamageMapsTabs;
