import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import servers from '../../utils/servers';
import iconOverview from '../../assets/location-pin.svg';
import style from './style.json';

const icon = {
  path: `M14.5,32.3 L14.5,57.2 L17.9,57.2 L17.9,32.3 C26.1,31.5 32.4,24.5 32.4,16.2 C32.4,7.3 25.1,0 16.2,0 C7.3,0
    0,7.3 0,16.2 C0,24.5 6.4,31.4 14.5,32.3 Z`,
  fillColor: '#01417d',
  fillOpacity: 0.6,
  strokeWeight: 0,
  anchor: { x: 15, y: 55 },
  scale: 0.4,
};

const iconMarker = {
  path: `M14.5,32.3 L14.5,57.2 L17.9,57.2 L17.9,32.3 C26.1,31.5 32.4,24.5 32.4,16.2 C32.4,7.3 25.1,0 16.2,0 C7.3,0
    0,7.3 0,16.2 C0,24.5 6.4,31.4 14.5,32.3 Z`,
  fillColor: '#01417d',
  fillOpacity: 0,
  strokeWeight: 0,
  anchor: { x: 15, y: 55 },
  scale: 0.4,
};

class ReactGoogleMaps extends Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    maxZoomOut: PropTypes.number.isRequired,
    initialZoom: PropTypes.number.isRequired,
    fullscreenControl: PropTypes.bool.isRequired,
    className: PropTypes.string,
    containerHeight: PropTypes.string.isRequired,
    containerWidth: PropTypes.string.isRequired,
    currentLocation: PropTypes.object,
    intl: PropTypes.object.isRequired,
    mapSource: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    currentLocation: undefined,
    mapSource: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      className: '',
      containerHeight: '300px',
      containerWidth: '100%',
      fullscreenControl: null,
      scriptLoaded: false,
      mapInitialised: false,
      currentZoom: 2,
      maxZoomOut: 1,
      centerCoordinates: { lng: 0, lat: 0 },
      receivedRoutes: false,
    };
  }

  componentDidMount() {
    this.setMapConfig();
  }

  componentDidUpdate(prevProps) {
    const { maxZoomOut, initialZoom, fullscreenControl, routes } = this.props;
    if (maxZoomOut !== prevProps.maxZoomOut) {
      this.updateMaxZoomOut();
    }
    if (initialZoom !== prevProps.initialZoom) {
      this.updateCurrentZoom();
    }
    if (fullscreenControl !== prevProps.fullscreenControl) {
      this.updateFullScreenControl();
    }
    if (prevProps?.routes?.length !== routes?.length) this.setMapConfig();
  }

  setMapConfig = () => {
    this.setState(
      {
        routes: this.props.routes,
        className: this.props.className,
        containerHeight: this.props.containerHeight,
        containerWidth: this.props.containerWidth,
        fullscreenControl: this.props.fullscreenControl,
        maxZoomOut: this.props.maxZoomOut,
        currentZoom: this.props.initialZoom,
      },
      () => {
        this.getRoutes(this.props.routes);
      },
    );
  };

  getRoutes = (routesSource = []) => {
    // Accumulator for the centre coordinates calculations later on
    let lng = 0;
    let lat = 0;

    const routes = routesSource
      // Filter out routes that start and end at the same location.
      .filter(
        (route) =>
          (route && route.startLat !== route && route.endLat) || (route && route.startLng !== route && route.endLng),
      )
      .map((route) => {
        // Simple calculation of the centre point coordinates accumulator
        lng += route.startLng + route.endLng;
        lat += route.startLat + route.endLat;
        // Return the routes entry in the appropriate format for the new array
        return [
          {
            lat: route.startLat,
            lng: route.startLng,
          },
          {
            lat: route.endLat,
            lng: route.endLng,
          },
        ];
      });
    // Define centre coordinates based on whether we have valid routes to define.
    const centerCoordinates =
      routes.length > 0
        ? {
            lat: lat / (2 * routesSource.length),
            lng: lng / (2 * routesSource.length),
          }
        : {
            lat: 0,
            lng: 0,
          };

    setTimeout(() => {
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 200);
    return this.setState({
      routes,
      centerCoordinates,
      receivedRoutes: true,
    });
  };

  getZoom = debounce(() => {
    if (this.state.mapSource !== 'Overview') {
      if (window.innerWidth >= 0 && window.innerWidth < 526) {
        this.setState({ maxZoomOut: 1 });
      } else if (window.innerWidth >= 526 && window.innerWidth < 800) {
        this.setState({ maxZoomOut: 2 });
      } else if (window.innerWidth >= 800 && window.innerWidth < 1300) {
        this.setState({ maxZoomOut: 2 });
      } else if (window.innerWidth >= 1300 && window.innerWidth < 1600) {
        this.setState({ maxZoomOut: 3 });
      } else if (window.innerWidth >= 1600) {
        this.setState({ maxZoomOut: 3 });
      } else {
        this.setState({ maxZoomOut: 2 });
      }
    } else {
      let maxZoomLevel = 1;
      if (window.innerWidth >= 1600) {
        maxZoomLevel = 3;
      } else if (window.innerWidth <= 1200 && window.innerWidth > 768) {
        maxZoomLevel = 2;
      }
      this.setState({ maxZoomOut: maxZoomLevel });
    }
  }, 600);

  updateMaxZoomOut() {
    const { maxZoomOut } = this.props;
    this.setState({ maxZoomOut });
  }

  updateCurrentZoom() {
    const { maxZoomOut } = this.props;
    this.setState({ maxZoomOut });
  }

  updateFullScreenControl() {
    const { maxZoomOut } = this.props;
    this.setState({ maxZoomOut });
  }

  render() {
    const lineSymbol = {
      path: 'M 0,-0.1 0,0.1',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      scale: 4,
    };
    const {
      currentLocation,
      intl: { formatMessage },
      mapSource,
    } = this.props;
    const {
      receivedRoutes,
      scriptLoaded,
      className,
      containerHeight,
      containerWidth,
      currentZoom,
      centerCoordinates,
      maxZoomOut,
      fullscreenControl,
      routes,
      mapInitialised,
    } = this.state;
    const usedMarkersArr = [];
    return receivedRoutes ? (
      <LoadScript
        channel="weekly"
        version="3.38"
        googleMapsApiKey={servers.googleApiKey}
        onLoad={() => {
          this.setState({ scriptLoaded: true });
        }}
        onUnmount={() => {
          this.setState({ scriptLoaded: false });
        }}
        onError={() => {
          message.error(formatMessage({ id: 'message.errorWhileLoadingMapScript' }));
        }}
      >
        {scriptLoaded ? (
          <GoogleMap
            mapContainerClassName={className}
            mapContainerStyle={{
              height: containerHeight,
              width: containerWidth,
            }}
            zoom={currentZoom}
            center={centerCoordinates}
            options={{
              styles: style,
              maxZoom: 32,
              minZoom: maxZoomOut,
              fullscreenControl,
            }}
            onUnmount={() => {
              this.setState({ mapInitialised: false, receivedRoutes: false });
            }}
            onLoad={(map) => {
              this.map = map;
              this.setState({ mapInitialised: true });
            }}
          >
            {currentLocation && (
              <Marker
                position={{
                  lat: Number(currentLocation.latitude),
                  lng: Number(currentLocation.longitude),
                }}
                options={{
                  icon: iconMarker,
                  zIndex: 999,
                }}
              />
            )}
            {routes.length > 0 && mapInitialised
              ? routes.map((item, i, arr) => {
                  const previousItem = arr[i - 1];
                  let currentLocationIdent = null;
                  if (currentLocation) {
                    if (currentLocation.gps_code) {
                      currentLocationIdent = currentLocation.gps_code;
                    }
                  }
                  if (previousItem) {
                    usedMarkersArr.push(JSON.stringify(previousItem[0]));
                    usedMarkersArr.push(JSON.stringify(previousItem[1]));
                  }
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                      {(currentLocationIdent && routes[i] && currentLocationIdent === routes[i].startIdent) ||
                        usedMarkersArr.includes(JSON.stringify(item[0])) || (
                          <Marker
                            position={item[0]}
                            options={{
                              icon: mapSource ? iconOverview : icon,
                              clickable: false,
                            }}
                          />
                        )}
                      <Polyline
                        path={item}
                        draggable={false}
                        options={{
                          clickable: false,
                          geodesic: true,
                          strokeColor: mapSource ? '#126FD6' : '#545454',
                          strokeOpacity: mapSource ? 0 : 0.4,
                          strokeWeight: 1,
                          icons: mapSource && [
                            {
                              icon: lineSymbol,
                              offset: '0',
                              repeat: '5px',
                            },
                          ],
                        }}
                      />
                      {(currentLocationIdent && routes[i] && currentLocationIdent === routes[i].endIdent) ||
                        usedMarkersArr.includes(JSON.stringify(item[1])) || (
                          <Marker
                            position={item[item.length - 1]}
                            options={{
                              icon: mapSource ? iconOverview : icon,
                              clickable: false,
                            }}
                          />
                        )}
                    </Fragment>
                  );
                })
              : null}
          </GoogleMap>
        ) : null}
      </LoadScript>
    ) : null;
  }
}

export default injectIntl(ReactGoogleMaps);
