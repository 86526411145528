const convertArrayToKeyValuePairs = (array: string[]): any => {
  return array.reduce((obj, item, index) => {
    const newObj = obj;
    const key = `${index}_${item}`;
    newObj[key] = item;
    return newObj;
  }, {});
};

export default convertArrayToKeyValuePairs;
