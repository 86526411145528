import { Button, Col, Row } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { intl } from '../../providers/IntlProvider';
import ImageFile from './ImageFile';
import styles from './index.module.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class Magnifier extends Component {
  // Debounce because PDF rendering is expensive
  debouncedResize = debounce(() => {
    this.resize();
  }, 200);

  static propTypes = {
    file: PropTypes.object,
    loading: PropTypes.node,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    defaultZoom: PropTypes.number,
    zoomStep: PropTypes.number,
    scrollable: PropTypes.bool,
    height: PropTypes.number,
    getMagnifierImageDetails: PropTypes.func,
    displayCoordinates: PropTypes.func,
    onLoadError: PropTypes.func,
  };

  static defaultProps = {
    file: null,
    loading: undefined,
    minZoom: 1,
    maxZoom: 4,
    defaultZoom: 2.5,
    zoomStep: 0.5,
    getMagnifierImageDetails: undefined,
    scrollable: true,
    height: undefined,
    displayCoordinates: undefined,
    onLoadError: undefined,
  };

  state = {
    // Width of zoomed page
    width: 0,
    // Height of zoomed page
    height: 0,
    // Aspect ratio of attachment
    aspectRatio: 1,
    // Amount magnified version is zoomed
    zoomAmount: this.props.getMagnifierImageDetails ? 1 : this.props.defaultZoom,
    // Size of the navigator relative to min(width, height)
    navSize: 0.4,
    // Current zoomed position (from 0 to 1)
    zoomPos: {
      x: 0,
      y: 0,
    },
    // Currently dragging zoomed page
    draggingPage: false,
    // Currently dragging magnifier
    draggingMagnifier: false,
  };

  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
    this.zoomedRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('resize', this.debouncedResize);
    this.resize();
  }

  componentDidUpdate() {
    const { zoomPos } = this.state;
    // Update scroll position
    if (this.zoomedRef.current) {
      const { height, width } = this.state;
      const [zoomedWidth, zoomedHeight] = this.zoomedDimensions(this.state);

      const left = zoomPos.x * zoomedWidth - width * 0.5;
      const top = zoomPos.y * zoomedHeight - height * 0.5;

      if (left > 0) {
        this.zoomedRef.current.scrollLeft = left;
        this.zoomedRef.current.style.paddingLeft = '0';
      } else {
        this.zoomedRef.current.scrollLeft = 0;
        this.zoomedRef.current.style.paddingLeft = `${-left}px`;
      }

      if (top > 0) {
        this.zoomedRef.current.scrollTop = top;
        this.zoomedRef.current.style.paddingTop = '0';
      } else {
        this.zoomedRef.current.scrollTop = 0;
        this.zoomedRef.current.style.paddingTop = `${-top}px`;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('resize', this.debouncedResize);
    this.debouncedResize.cancel();
  }

  resize = () => {
    if (!this.parentRef.current) return;
    const width = this.parentRef.current.clientWidth;
    const height = this.props.height === undefined ? this.parentRef.current.clientHeight : this.props.height;
    this.setState((state) => this.transformZoomPos({ ...state, width, height }));
  };

  handleMouseUp = () => {
    this.setState({ draggingPage: false, draggingMagnifier: false });
  };

  zoomIn = (amount) => {
    this.setState((state, { minZoom, maxZoom }) => {
      const zoomAmount = Math.min(Math.max(state.zoomAmount + amount, minZoom), maxZoom);
      return this.transformZoomPos({ ...state, zoomAmount });
    });
  };

  zoomedScroll = (e) => {
    if (this.state.draggingMagnifier) return false;
    const left = e.target.scrollLeft;
    const top = e.target.scrollTop;
    this.setState((state) => {
      const [zoomedWidth, zoomedHeight] = this.zoomedDimensions(state);
      return this.transformZoomPos({
        ...state,
        zoomPos: {
          x: (left + state.width * 0.5) / zoomedWidth,
          y: (top + state.height * 0.5) / zoomedHeight,
        },
      });
    });
    return true;
  };

  startMoveMagnifier = (e) => {
    this.setState({ draggingMagnifier: true });
    this.moveMagnifier(e, true);
  };

  movePage = (e) => {
    if (!this.state.draggingPage) return;
    const el = this.zoomedRef.current;
    el.scrollTo(el.scrollLeft - e.movementX, el.scrollTop - e.movementY);
  };

  moveMagnifier = (e, override) => {
    if (!this.state.draggingMagnifier && !override) return;
    const rect = e.target.getBoundingClientRect();
    const moveEvent = e.changedTouches ? e.changedTouches[0] : e;
    const mouseX = moveEvent.clientX - rect.left;
    const mouseY = moveEvent.clientY - rect.top;
    this.setState((state) => {
      const [navWidth, navHeight] = this.navDimensions(state);
      const x = mouseX / navWidth;
      const y = mouseY / navHeight;
      return this.transformZoomPos({ ...state, zoomPos: { x, y } });
    });
  };

  transformZoomPos = (state) => {
    const [navWidth, navHeight] = this.navDimensions(state);
    const [zoomedWidth, zoomedHeight] = this.zoomedDimensions(state);

    let x;
    let y;

    if ((state.width / zoomedWidth) * navWidth >= navWidth) {
      x = 0.5;
    } else {
      const halfMagWidth = 0.5 * (state.width / zoomedWidth);
      x = Math.min(Math.max(state.zoomPos.x, halfMagWidth), 1 - halfMagWidth);
    }

    if ((state.height / zoomedHeight) * navHeight >= navHeight) {
      y = 0.5;
    } else {
      const halfMagHeight = 0.5 * (state.height / zoomedHeight);
      y = Math.min(Math.max(state.zoomPos.y, halfMagHeight), 1 - halfMagHeight);
    }

    return { ...state, zoomPos: { x, y } };
  };

  navDimensions = ({ width, height, navSize, aspectRatio }) => {
    if (width < height) {
      const w = width * navSize;
      return [w, w * aspectRatio];
    }
    const h = height * navSize;
    return [h / aspectRatio, h];
  };

  zoomedDimensions = ({ width, height, zoomAmount, aspectRatio }) => {
    if (height / width > aspectRatio) {
      const w = width * zoomAmount;
      return [w, w * aspectRatio];
    }
    const h = height * zoomAmount;
    return [h / aspectRatio, h];
  };

  handlePageLoad = (page) => {
    if (page.rotate === 90 || page.rotate === 270) {
      this.setState({ aspectRatio: page.originalWidth / page.originalHeight });
    } else {
      this.setState({ aspectRatio: page.originalHeight / page.originalWidth });
    }
    this.debouncedResize();
  };

  handleImageLoad = (e) => {
    this.setState({ aspectRatio: e.target.naturalHeight / e.target.naturalWidth });
    this.resize();
  };

  render() {
    const { file, scrollable, zoomStep, displayCoordinates, getMagnifierImageDetails, loading, onLoadError } =
      this.props;
    const { width, height, draggingPage, zoomPos } = this.state;
    const [zoomedWidth, zoomedHeight] = this.zoomedDimensions(this.state);
    const [navWidth, navHeight] = this.navDimensions(this.state);
    let magWidth = Math.min((width / zoomedWidth) * navWidth, navWidth);
    let magHeight = Math.min((height / zoomedHeight) * navHeight, navHeight);
    magWidth = Number.isNaN(magWidth) ? 0 : magWidth;
    magHeight = Number.isNaN(magHeight) ? 0 : magHeight;

    if (file.type !== 'application/pdf' && !file.type.startsWith('image')) {
      return <div>{intl.formatMessage({ id: 'message.noFilePreview' })}</div>;
    }

    const mediaElement = (mediaWidth, loadEvent) => {
      if (file.type === 'application/pdf') {
        return (
          <>
            <Page
              pageNumber={1}
              width={mediaWidth}
              onLoadSuccess={loadEvent && this.handlePageLoad}
              renderTextLayer={false}
            >
              {loadEvent && displayCoordinates ? displayCoordinates() : null}
            </Page>
          </>
        );
      }
      if (file.type.startsWith('image') || file.type.startsWith('text')) {
        return (
          <>
            <ImageFile
              file={file}
              width={mediaWidth}
              onLoad={loadEvent && this.handleImageLoad}
              displayCoordinates={loadEvent && displayCoordinates ? displayCoordinates() : null}
            />
          </>
        );
      }
      return <></>;
    };

    let magnifier = (
      <div className={styles.magnifier}>
        <div
          aria-hidden
          className={styles.zoomedPage}
          style={{ width, height, overflow: scrollable ? 'auto' : 'hidden' }}
          ref={this.zoomedRef}
          onScroll={this.zoomedScroll}
          onMouseDown={() => this.setState({ draggingPage: true })}
          onMouseMove={this.movePage}
        >
          {mediaElement(zoomedWidth, true)}
        </div>
        <Col className={styles.overlay} style={{ pointerEvents: draggingPage ? 'none' : 'all' }}>
          <Row>
            <Button.Group className={styles.zoomButtons}>
              <Button onClick={() => this.zoomIn(zoomStep)} icon="plus" />
              <Button onClick={() => this.zoomIn(-zoomStep)} icon="minus" />
            </Button.Group>
          </Row>
          <Row>
            <div
              aria-hidden
              className={styles.navPage}
              style={{ width: navWidth }}
              onMouseDown={this.startMoveMagnifier}
              onMouseMove={this.moveMagnifier}
              onTouchStart={this.startMoveMagnifier}
              onTouchMove={this.moveMagnifier}
            >
              {mediaElement(navWidth)}
              <div
                className={styles.magnifierThumb}
                style={{
                  width: magWidth,
                  height: magHeight,
                  left: navWidth * zoomPos.x - magWidth / 2,
                  top: navHeight * zoomPos.y - magHeight / 2,
                }}
              />
            </div>
          </Row>
        </Col>
      </div>
    );

    if (getMagnifierImageDetails) {
      getMagnifierImageDetails(zoomedWidth, zoomedHeight, zoomPos);
    }

    if (file.type === 'application/pdf') {
      magnifier = (
        <Document file={file} noData="" loading={loading} onLoadError={onLoadError}>
          {magnifier}
        </Document>
      );
    }

    return <div ref={this.parentRef}>{magnifier}</div>;
  }
}

export default Magnifier;
