import React from 'react';
import { Progress, Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import styles from './FlightsHours.module.less';

const FlightsHours = ({ aircraftUsageData, formatMessage, sectorsNameOverride }) => {
  const {
    current: { aircraftFlights, aircraftHours },
    prevMonth: { aircraftFlights: prevMonthAircraftFlight, aircraftHours: prevMonthAircraftHours },
    prevYear: { aircraftFlights: prevYearAircraftFlight, aircraftHours: prevYearAircraftHours },
  } = aircraftUsageData;
  const hours = aircraftHours && aircraftHours.length > 0 ? Math.round(aircraftHours[0].y) : 0;
  const flights = aircraftFlights && aircraftFlights.length > 0 ? aircraftFlights[0].y : 0;
  const prevMonthHours =
    prevMonthAircraftHours && prevMonthAircraftHours.length > 0 ? Math.round(prevMonthAircraftHours[0].y) : 0;
  const prevMonthFlights =
    prevMonthAircraftFlight && prevMonthAircraftFlight.length > 0 ? prevMonthAircraftFlight[0].y : 0;
  const prevYearHours =
    prevYearAircraftHours && prevYearAircraftHours.length > 0 ? Math.round(prevYearAircraftHours[0].y) : 0;
  const prevYearFlights = prevYearAircraftFlight && prevYearAircraftFlight.length > 0 ? prevYearAircraftFlight[0].y : 0;

  const hoursPercentage = prevMonthHours === 0 ? 'No data' : Math.round((hours / prevMonthHours) * 100);
  const yearHoursPercentage = prevYearHours === 0 ? 'No data' : Math.round((hours / prevYearHours) * 100);
  const flightsPercentage = prevMonthFlights === 0 ? 'No data' : Math.round((flights / prevMonthFlights) * 100);
  const yearFlightsPercentage = prevYearFlights === 0 ? 'No data' : Math.round((flights / prevYearFlights) * 100);
  return (
    <Row>
      <Row gutter={[24, 24]}>
        <Col md={24}>
          <div className={styles.hoursWrapper} data-testid="FlightsHours--HoursWrapper">
            <span className={styles.hours}>{`${hours} ${formatMessage({ id: 'text.hours' })}`}</span>
          </div>
          <div className={styles.progressDivWrapper}>
            <div className={styles.progressDiv} data-testid="FlightsHours--HoursProgressDiv">
              <Progress
                type="circle"
                width={40}
                showInfo={false}
                percent={hoursPercentage === 'No data' ? 0 : hoursPercentage}
                strokeColor={{ '0%': '#74c9ff', '100%': '#4099ff' }}
                strokeWidth={12}
                strokeLinecap="square"
              />
              <div className={styles.textDiv} data-testid="FlightsHours--HoursPrevPeriod">
                <span>{formatMessage({ id: 'text.previousPeriod' })}</span>
                <div>{hoursPercentage === 'No data' ? 'No data' : `${hoursPercentage}%`}</div>
              </div>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div className={styles.progressDiv} data-testid="FlightsHours--PrevPeriodProgress">
              <Progress
                type="circle"
                width={40}
                showInfo={false}
                percent={yearHoursPercentage === 'No data' ? 0 : yearHoursPercentage}
                strokeColor={{ '0%': '#74c9ff', '100%': '#4099ff' }}
                strokeWidth={12}
                strokeLinecap="square"
              />
              <div className={styles.textDiv}>
                <span>{formatMessage({ id: 'text.yearOnYear' })}</span>
                <div>{yearHoursPercentage === 'No data' ? 'No data' : `${yearHoursPercentage}%`}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col md={24}>
          <div className={styles.hoursWrapper} data-testid="FlightsHours--FlightsSection">
            <span className={styles.hours}>{`${flights} ${sectorsNameOverride}`}</span>
          </div>
          <div className={styles.progressDivWrapper} data-testid="FlightsHours--FlightsProgress">
            <div className={styles.progressDiv}>
              <Progress
                type="circle"
                width={40}
                showInfo={false}
                percent={flightsPercentage === 'No data' ? 0 : flightsPercentage}
                strokeColor={{ '0%': '#74c9ff', '100%': '#4099ff' }}
                strokeWidth={12}
                strokeLinecap="square"
              />
              <div className={styles.textDiv}>
                <span>{formatMessage({ id: 'text.previousPeriod' })}</span>
                <div>{flightsPercentage === 'No data' ? 'No data' : `${flightsPercentage}%`}</div>
              </div>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div className={styles.progressDiv} data-testid="FlightsHours--FlightsPrevPeriod">
              <Progress
                type="circle"
                width={40}
                showInfo={false}
                percent={yearFlightsPercentage === 'No data' ? 0 : yearFlightsPercentage}
                strokeColor={{ '0%': '#74c9ff', '100%': '#4099ff' }}
                strokeWidth={12}
                strokeLinecap="square"
              />
              <div className={styles.textDiv}>
                <span>{formatMessage({ id: 'text.yearOnYear' })}</span>
                <div>{yearFlightsPercentage === 'No data' ? 'No data' : `${yearFlightsPercentage}%`}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

FlightsHours.propTypes = {
  aircraftUsageData: PropTypes.object.isRequired,
  formatMessage: PropTypes.func.isRequired,
  sectorsNameOverride: PropTypes.string.isRequired,
};

export default FlightsHours;
