/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TopNav } from '@arcflight/tf-component-library';
import Cookies from 'universal-cookie';
import LogoutIcon from '../../assets/topNav/log-out.svg';
import ProfileSettings from '../../assets/topNav/profile-settings.svg';
import servers from '../../utils/servers';
import logout from '../../utils/logout';
import GridContent from './GridContent';
import styles from './index.module.less';

const cookies = new Cookies();

const PageHeaderWrapper = ({ children, wrapperClassName, userSettings, menu, ...restProps }) => {
  const { formatMessage } = useIntl();
  const { bannerVisible } = menu;
  const { title, icon } = restProps;
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [avatarIcon, setAvatarIcon] = useState(undefined);
  const history = useHistory();

  const isMobile = window.innerWidth < 451;
  useEffect(() => {
    if (loading && userSettings.details) {
      const { people } = userSettings?.details;
      let userRole = '-';
      if (people.length > 0) {
        userRole = people[0].type || '-';
      }
      if (userSettings.details.avatar_url) {
        const avatar = `${servers.api}/logins/${cookies.get('userId')}/avatar?${userSettings.details.avatar_url}`;
        setAvatarIcon(avatar);
      }
      setLoading(false);
      setRole(userRole);
    }
  }, [userSettings, loading]);

  const menuItems = [
    {
      label: `${formatMessage({ id: 'menu.account.settings', defaultMessage: 'Profile Settings' })}`,
      icon: ProfileSettings,
      onSelect: () => {
        history.push('/settings/account');
      },
    },
    {
      label: ` ${formatMessage({ id: 'menu.account.logout' })}`,
      icon: LogoutIcon,
      onSelect: () => {
        logout();
      },
    },
  ];

  return (
    <div
      className={`${bannerVisible ? styles.pageHeaderWrapperBanVis : styles.pageHeaderWrapper} ${wrapperClassName}`}
      style={{ marginTop: bannerVisible && !isMobile ? '50px' : '0' }}
    >
      <div id="tableList">
        <TopNav
          title={title}
          titleIcon={icon}
          isLoading={loading}
          userRole={role}
          username={
            userSettings?.displayName?.length
              ? userSettings.displayName
              : `${userSettings.details?.first_name} ${userSettings.details?.last_name}`
          }
          menuItems={menuItems}
          userIcon={avatarIcon}
        />
      </div>
      {children ? (
        <div className={styles.content}>
          <GridContent>{children}</GridContent>
        </div>
      ) : null}
    </div>
  );
};

PageHeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  contentWidth: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.object,
  menu: PropTypes.object.isRequired,
  userSettings: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

PageHeaderWrapper.defaultProps = {
  top: undefined,
  wrapperClassName: undefined,
  layout: null,
  userSettings: undefined,
};

export default connect(({ userSettings, menu }) => ({
  contentWidth: userSettings.ui.contentWidth,
  menu,
  userSettings,
}))(PageHeaderWrapper);
