import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Divider, Header, Label, Text, Title } from '../CommonStyledComponents/CommonStyledComponents';
import { Maintenance } from '../../models/maintenance';
import { stringToTitleCase } from '../../utils/utils';
import { DashboardState } from '../../models';
import timeUtils from '../../utils/timeUtils';

interface MaintenanceDrawerViewSectionProps {
  itemData: Maintenance;
  mxType: string;
  area: string;
}

const Wrapper = styled.div`
  width: 100%;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

const StyledHeader = styled(Header)`
  color: #181818;
  letter-spacing: -0.09px;
  font-weight: 500;
  text-transform: capitalize;
`;

const MaintenanceDrawerViewSection: React.FC<MaintenanceDrawerViewSectionProps> = ({ itemData, mxType, area }) => {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const [amberWarning, setAmberWarning] = useState(null);

  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const aircraftHasAPU = aircraftMap.get(id)?.apu_installed;

  const displayType = (value: string): string => {
    let newValue = value;
    if (value === 'scheduled') newValue = 'Scheduled';
    if (value === 'llp') newValue = 'LLP';
    if (value === 'oop') newValue = 'OOP';
    return newValue;
  };

  const formatHoursAndMinutes = (seconds): string => {
    if (!seconds) return '-';
    const hours = timeUtils.getOnlyHoursFromSeconds(seconds);
    const minutes = timeUtils.getOnlyMinutesFromSeconds(seconds);
    const newMinutes = minutes > 9 ? minutes : `0${minutes}`;
    return `${hours}:${newMinutes}`;
  };

  useEffect(() => {
    if (itemData?.calendar_days_amber_limit) {
      setAmberWarning(`${itemData?.calendar_days_amber_limit} days threshold`);
    }
    if (itemData?.calendar_seconds_amber_limit) {
      setAmberWarning(`${itemData?.calendar_seconds_amber_limit} hours threshold`);
    }
  }, [itemData]);

  return (
    <Wrapper>
      <Title data-testid="MaintenanceDrawerViewSection--Title">{`Maintenance Item: ${itemData?.name}`}</Title>
      <GridWrapper>
        <div style={{ gridColumn: 'span 2' }}>
          <Label data-testid="MaintenanceDrawerViewSection--TypeAreaLabel">
            {formatMessage({ id: 'text.type' })} - {formatMessage({ id: 'text.area' })}
          </Label>
          <Text data-testid="MaintenanceDrawerViewSection--TypeAreaText">
            {displayType(mxType)} - {`${stringToTitleCase(area?.replace(/_/g, ' ') || '')}`}
          </Text>
        </div>
        <div style={{ gridColumn: 'span 2' }} data-testid="MaintenanceDrawerViewSection--TaskDescription">
          <Label>{formatMessage({ id: 'form.labels.taskDescription' })}</Label>
          <Text>{itemData?.name}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--FlightHoursLimit">
          <StyledHeader>{formatMessage({ id: 'text.flightHoursLimit' })}</StyledHeader>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-Threshold">
          <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
          <Text>{itemData?.flight_seconds_threshold ? itemData?.flight_seconds_threshold / 3600 : '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-Repeat">
          <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
          <Text>{itemData?.flight_seconds_repeat ? itemData?.flight_seconds_repeat / 3600 : '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-Tolerance">
          <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
          <Text>{itemData?.flight_seconds_tolerance ? itemData?.flight_seconds_tolerance / 3600 : '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-NextDue">
          <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
          <Text>{formatHoursAndMinutes(itemData?.flight_seconds_due)}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit">
          <StyledHeader>{formatMessage({ id: 'text.flightCyclesLimit' })}</StyledHeader>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-Threshold">
          <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
          <Text>{itemData?.cycles_threshold || '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-Repeat">
          <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
          <Text>{itemData?.cycles_repeat || '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-Tolerance">
          <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
          <Text>{itemData?.cycles_tolerance || '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-NextDue">
          <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
          <Text>{itemData?.cycles_due || '-'}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--FlightMonthLimit">
          <StyledHeader>{formatMessage({ id: 'text.flightMonthLimit' })}</StyledHeader>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-Threshold">
          <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
          <Text>
            {itemData?.unit_of_time === 'days' ? itemData?.days_threshold || '-' : itemData?.months_threshold || '-'}
          </Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-Repeat">
          <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
          <Text>
            {itemData?.unit_of_time === 'days' ? itemData?.days_repeat || '-' : itemData?.months_repeat || '-'}
          </Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-Tolerance">
          <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
          <Text>
            {itemData?.unit_of_time === 'days' ? itemData?.days_tolerance || '-' : itemData?.months_tolerance || '-'}
          </Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-NextDue">
          <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
          <Text>{itemData?.datetime_due || itemData?.date_due || '-'}</Text>
        </div>
        {aircraftHasAPU ? (
          <>
            <div style={{ gridColumn: 'span 4' }}>
              <Divider />
            </div>
            <div style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--APUHoursLimit">
              <StyledHeader>{formatMessage({ id: 'text.apuHoursLimit' })}</StyledHeader>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-Threshold">
              <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
              <Text>{itemData?.apu_seconds_threshold ? itemData?.apu_seconds_threshold / 3600 : '-'}</Text>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-Repeat">
              <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
              <Text>{itemData?.apu_seconds_repeat ? itemData?.apu_seconds_repeat / 3600 : '-'}</Text>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-Tolerance">
              <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
              <Text>{itemData?.apu_seconds_tolerance ? itemData?.apu_seconds_tolerance / 3600 : '-'}</Text>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-NextDue">
              <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
              <Text>{formatHoursAndMinutes(itemData?.apu_seconds_due)}</Text>
            </div>
          </>
        ) : null}
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 2' }} data-testid="MaintenanceDrawerViewSection--RepeatFrom">
          <Label>{`Repeat from: `}</Label>
          <Text>
            {`${itemData?.time_based_repeat_calculation_method === 'date_date' ? 'Date due' : 'Date resolved'}`}
          </Text>
        </div>
        <div style={{ gridColumn: 'span 2' }} data-testid="MaintenanceDrawerViewSection--AmberWarningOverride">
          <Label>{`Amber warning override: `}</Label>
          <Text>{`${amberWarning || 'Not set'}`}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--CustomSettings">
          <StyledHeader>{formatMessage({ id: 'text.customSettings' })}</StyledHeader>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--ShowOnSRP">
          <Label>{formatMessage({ id: 'text.showOnSRP' })}</Label>
          <Text>{itemData?.show_on_srp ? 'Yes' : 'No'}</Text>
        </div>
      </GridWrapper>
    </Wrapper>
  );
};

export default MaintenanceDrawerViewSection;
