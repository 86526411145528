import { Accordion } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { checkNumberIsTwoDecimalPlaces, combineFluidUplifts } from '../../utils/utils';
import hydraulicIcon from '../../assets/icon-card-hydraulics.svg';
import errorIcon from '../../assets/login-error.svg';
import {
  ContentWrapper,
  DisplayText,
  Header,
  PartsDiv,
  RowHeader,
  StyledInput,
  StyledUnit,
  TitleWrapper,
  UnitWrapper,
} from './TripUpdateAirframeSection';

interface TripUpdateHydraulicSectionProps {
  trip: any;
  updateTripUpdateData: (changes: { value: any; key: string }[]) => void;
}

const OuterWrapper = styled.div`
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  padding: ${({ isMobile }): string => (isMobile ? '16px 20px' : '16px 0 20px 56px')};
`;

const HydraulicLogWrapper = styled.div`
  border-bottom: ${({ border }): string => (border ? '1px solid rgba(36, 45, 65, 0.05)' : 'none')};
  margin-bottom: ${({ border }): string => (border ? '20px' : '0')};
`;

const LogHeader = styled.span`
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #242d41;
  padding-bottom: 16px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const UnitSelectionWrapper = styled.div`
  grid-column: ${({ editable }): string => (editable ? 'span 3' : 'auto')};
`;

const ErrorWrapper = styled.div`
  font-size: '16px';
  color: '#ac1717';
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const TripUpdateHydraulicSection: React.FC<TripUpdateHydraulicSectionProps> = ({ trip, updateTripUpdateData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [hydraulicLogs, setHydraulicLogs] = useState([]);
  const [numberOfParts, setNumberOfParts] = useState(0);
  const [errorMap, setErrorMap] = useState(new Map());

  const isMobile = window.innerWidth < 451;

  const handleInputChange = (value: number, key: string, name: string): void => {
    if (checkNumberIsTwoDecimalPlaces(value.toString())) {
      errorMap.delete(`${key}${name}`);
      setErrorMap(errorMap);
    } else {
      errorMap.set(`${key}${name}`, true);
      setErrorMap(errorMap);
    }
    const foundIndex = hydraulicLogs.findIndex((uplift) => uplift?.hydraulic_circuit_name === name);
    hydraulicLogs[foundIndex] = { ...hydraulicLogs[foundIndex], [key]: value };
    const existingFluidArray = trip?.fluid_uplifts_attributes || trip?.fluid_uplifts;
    const newFluidArray = combineFluidUplifts(hydraulicLogs, existingFluidArray);
    updateTripUpdateData([{ value: newFluidArray, key: 'fluid_uplifts_attributes' }]);
  };

  const handleHydraulicUnitChange = (value: string, name: string): void => {
    const foundIndex = hydraulicLogs.findIndex((uplift) => uplift?.hydraulic_circuit_name === name);
    hydraulicLogs[foundIndex] = { ...hydraulicLogs[foundIndex], uplift_unit: value };
    const existingFluidUplifts = trip?.fluid_uplifts_attributes || trip?.fluid_uplifts;
    const newFluidArray = combineFluidUplifts(hydraulicLogs, existingFluidUplifts);
    updateTripUpdateData([{ value: newFluidArray, key: 'fluid_uplifts_attributes' }]);
  };

  const buildHydraulics = (): ReactElement[] => {
    return hydraulicLogs?.map((log, i) => {
      return (
        <HydraulicLogWrapper key={log?.hydraulic_circuit_name}>
          <LogHeader>{log?.hydraulic_circuit_name}</LogHeader>
          <ContentWrapper
            noBorder
            columns="1fr 1fr 1fr 1fr"
            padding={i + 1 === hydraulicLogs?.length ? '16px 0 0 0' : '16px 0'}
            isMobile={isMobile}
          >
            <div>
              <Header editable={mode !== 'view'}>Initial</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.initial_value || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.initial_value || undefined}
                    step={0.1}
                    onChange={(e): void =>
                      handleInputChange(parseFloat(e.target.value), 'initial_value', log.hydraulic_circuit_name)
                    }
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
              {errorMap.get(`initial_value${log?.hydraulic_circuit_name}`) ? (
                <ErrorWrapper>
                  <img src={errorIcon} alt="error" />
                  Value must be to 2 decimal places
                </ErrorWrapper>
              ) : null}
            </div>
            <div>
              <Header editable={mode !== 'view'}>Uplift</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.uplift || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.uplift || undefined}
                    step={0.1}
                    onChange={(e): void =>
                      handleInputChange(parseFloat(e.target.value), 'uplift', log.hydraulic_circuit_name)
                    }
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
              {errorMap.get(`uplift${log?.hydraulic_circuit_name}`) ? (
                <ErrorWrapper>
                  <img src={errorIcon} alt="error" />
                  Value must be to 2 decimal places
                </ErrorWrapper>
              ) : null}
            </div>
            <div>
              <Header editable={mode !== 'view'}>Carried forward</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.carried_forwards || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.carried_forwards || undefined}
                    step={0.1}
                    onChange={(e): void =>
                      handleInputChange(parseFloat(e.target.value), 'carried_forwards', log.hydraulic_circuit_name)
                    }
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
              {errorMap.get(`carried_forwards${log?.hydraulic_circuit_name}`) ? (
                <ErrorWrapper>
                  <img src={errorIcon} alt="error" />
                  Value must be to 2 decimal places
                </ErrorWrapper>
              ) : null}
            </div>
            <UnitSelectionWrapper editable={mode !== 'view'}>
              <Header editable={mode !== 'view'}>Unit</Header>
              {mode === 'view' ? (
                <DisplayText>{log?.uplift_unit}</DisplayText>
              ) : (
                <RadioWrapper>
                  <RadioInputButton
                    text="ml"
                    active={log?.uplift_unit === 'ml'}
                    marginRight="8px"
                    onClick={(): void => handleHydraulicUnitChange('ml', log.hydraulic_circuit_name)}
                  />
                  <RadioInputButton
                    text="qt"
                    active={log?.uplift_unit === 'qt'}
                    marginRight="8px"
                    onClick={(): void => handleHydraulicUnitChange('qt', log.hydraulic_circuit_name)}
                  />
                  <RadioInputButton
                    text="usg"
                    active={log?.uplift_unit === 'usg'}
                    marginRight="8px"
                    onClick={(): void => handleHydraulicUnitChange('usg', log.hydraulic_circuit_name)}
                  />
                </RadioWrapper>
              )}
            </UnitSelectionWrapper>
          </ContentWrapper>
        </HydraulicLogWrapper>
      );
    });
  };

  useEffect(() => {
    const createHydraulicUplifts = (passedArray) => {
      const newArray = [];
      if (aircraftMap?.get(id)?.hydraulic_circuits?.length) {
        aircraftMap.get(id).hydraulic_circuits.forEach((item) => {
          if (passedArray.find((uplift) => uplift?.hydraulic_circuit_id === item?.hydraulic_circuit_id)) {
            newArray.push(passedArray.find((uplift) => uplift?.hydraulic_circuit_id === item?.hydraulic_circuit_id));
          } else {
            newArray.push({
              hydraulic_circuit_name: item?.hydraulic_circuit_name,
              hydraulic_circuit_id: item?.hydraulic_circuit_id,
              initial_value: '',
              uplift: '',
              carried_forwards: '',
              uplift_unit: aircraftMap.get(id)?.oil_unit,
              fluid_type: 'hydraulic',
            });
          }
        });
      }
      return newArray;
    };
    if (trip) {
      if (trip?.fluid_uplifts_attributes?.some((uplift) => uplift?.fluid_type === 'hydraulic')) {
        const newLogs = trip?.fluid_uplifts_attributes.filter((uplift) => uplift?.fluid_type === 'hydraulic');
        const builtLogs = createHydraulicUplifts(newLogs);
        setHydraulicLogs(builtLogs);
        setNumberOfParts(builtLogs?.length * 3);
      } else if (trip?.fluid_uplifts?.some((uplift) => uplift?.fluid_type === 'hydraulic')) {
        const newLogs = trip?.fluid_uplifts.filter((uplift) => uplift?.fluid_type === 'hydraulic');
        const builtLogs = createHydraulicUplifts(newLogs);
        setHydraulicLogs(builtLogs);
        setNumberOfParts(builtLogs?.length * 3);
      } else {
        const builtLogs = createHydraulicUplifts([]);
        setHydraulicLogs(builtLogs);
        setNumberOfParts(builtLogs?.length * 3);
      }
    }
  }, [aircraftMap, id, trip]);

  return (
    <Accordion
      isExpanded={mode === 'add'}
      dash
      headerContent={
        <RowHeader>
          <TitleWrapper>
            <img src={hydraulicIcon} alt="oil" />
            Hydraulic
          </TitleWrapper>
          <PartsDiv>{`${numberOfParts} parts`}</PartsDiv>
        </RowHeader>
      }
    >
      <OuterWrapper isMobile={isMobile}>{buildHydraulics()}</OuterWrapper>
    </Accordion>
  );
};

export default TripUpdateHydraulicSection;
