/* eslint-disable max-len */
import React from 'react';
import styles from '../workpackDrawer.module.less';
import SignaturePad from './SignaturePad';

const CRSAcceptance = ({ setAcceptanceSig, operatorSettings }): JSX.Element => {
  return (
    <div className={styles.column}>
      <span className={styles.signaturePadHeading}>{`${operatorSettings?.crs_short_name} Acceptance`}</span>
      <span className={styles.signatureSubHeading}>
        {`Sign here to accept the issued ${operatorSettings?.crs_short_name} and mark the included maintenance items as complied with.`}
      </span>
      <SignaturePad setAcceptanceSig={setAcceptanceSig} />
    </div>
  );
};

export default CRSAcceptance;
