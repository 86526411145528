import React from 'react';
import { RadioWrapper } from '../CommonStyledComponents/CommonStyledComponents';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

interface TFRadioInputProps {
  options: string[];
  onChange: (value: string) => void;
  label?: string;
  id?: string;
  value?: string;
  tooltip?: string;
  optional?: boolean;
  disabled?: boolean;
}

const defaultProps = {
  label: '',
  id: '',
  value: '',
  tooltip: '',
  optional: false,
  disabled: false,
};

const TFRadioInput: React.FC<TFRadioInputProps> = ({
  options,
  onChange,
  label,
  id,
  value,
  tooltip,
  optional,
  disabled,
}) => {
  return (
    <div data-testid="TFRadioInput--Wrapper">
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <RadioWrapper data-testid={`TFRadioInput--${id}`}>
        {options.map((item) => {
          return (
            <RadioInputButton
              text={item}
              active={value === item}
              onClick={(): void => onChange(item)}
              key={item}
              marginRight="8px"
              dataTestId={`TFRadioInput--${id}-${item}`}
              disabled={disabled}
            />
          );
        })}
      </RadioWrapper>
    </div>
  );
};

TFRadioInput.defaultProps = defaultProps;

export default TFRadioInput;
