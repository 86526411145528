import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import styled from 'styled-components';
import { Loading, Button, Accordion } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
  handleBackButtonClickWithPayload,
  setDrawerId,
  setPassedData,
} from '../../models/drawer';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import { addIntermittentFault, getIntermittentFaultOccurrences, updateIntermittentFault } from '../../services/api';
import ButtonSection from '../../components/TripDrawer/ButtonSection';
import { IntermittentFault, IntermittentFaultOccurrence } from '../../models/intermittentFaults';
import DrawerIconCard from '../../components/Drawer/DrawerIconCard';
import DefectDrawer from '../../components/DefectDrawer/NewDrawer';
import backArrow from '../../assets/icon-back.svg';
import DeleteIcon from '../../assets/delete.svg';
import plusIcon from '../../assets/plus-blue.svg';
import EmptyStateFaults from '../../assets/emptyState/empty-state-sector-faults.png';
import DetailsSection from './DetailsSection';
import SectorSection from './SectorSection';
import IntermittentFaultOccurrenceDrawer from './IntermittentFaultOccurrenceDrawer';
import IntermittentFaultOccurrenceForm from './IntermittentFaultOccurrenceForm';

interface IntermittentFaultsDrawerProps {
  fault: IntermittentFault;
  intermittentFaultName: string;
  sectorFault?: boolean;
  flightId?: string;
  onUpdatedFault?: () => void;
  convertable?: boolean;
  handleDeleteIntermittentFault?: (id: string) => void;
}

const defaultProps = {
  sectorFault: false,
  convertable: false,
};

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: ${({ bannerVisible }): string => (bannerVisible ? '16px' : '24px')};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
  }
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 1.5rem 0 1rem 0;
  padding: 20px;
  span {
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const OccurrenceWrapper = styled.div`
  padding: ${({ edit }): string => (edit ? '20px 0px 0px 40px;' : '14px 0px 20px 60px;')};
  @media (max-width: 450px) {
    padding: ${({ edit }): string => (edit ? '20px 0 0 0;' : '14px 0px 20px 20px;')};
  }
`;

const Margins = styled.div`
  > * {
    margin-bottom: 1rem;
  }
`;

const StyledBackButton = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const NoDefectsText = styled.span`
  margin-left: 8px;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const AuthWrapper = styled.div``;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-top: 1rem;
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const OccurrenceNum = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.black};
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: '1fr 1fr';
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '16px')};
  grid-template-rows: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
  margin-top: 1rem;
`;

const IntermittentFaultsDrawer: React.FC<IntermittentFaultsDrawerProps & typeof defaultProps> = ({
  fault,
  intermittentFaultName,
  sectorFault,
  flightId,
  onUpdatedFault,
  convertable,
  handleDeleteIntermittentFault,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const {
    drawer: { mode, drawerHistory },
    people: { peopleMap },
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);
  const [faultData, setFaultData] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [localFlightId, setLocalFlightId] = useState(null);
  const [occurrences, setOccurrences] = useState([]);
  const [people, setPeople] = useState([]);
  const [convertToDefect, setConvertToDefect] = useState(convertable);
  const operatorId = aircraftMap.get(id)?.operator_id;

  const defectNameOverride = userSettings?.details?.operators?.find((op) => op?.id === aircraftMap.get(id)?.operator_id)
    ?.operator_setting?.defect_name;

  const useData = (): IntermittentFault[] => {
    const [data, setData] = useState([]);
    useEffect(() => {
      if (mode !== 'add') {
        getIntermittentFaultOccurrences(fault.id).then((res) => {
          setData(res.intermittent_fault_occurrences);
          setOccurrences(res.intermittent_fault_occurrences);
        });
      }
    }, []);
    return data;
  };

  useData();

  const validateFaultData = (): boolean => {
    if (faultData && !faultData.reported_at) {
      message.error('Date must be entered');
      return false;
    }
    if (faultData && !faultData.details) {
      message.error('Details must be entered');
      return false;
    }
    if (faultData && !faultData.reported_by_id) {
      message.error('Reporter must be selected');
      return false;
    }
    return true;
  };

  const submitFault = async (): Promise<void> => {
    if (validateFaultData()) {
      const params = {
        ...faultData,
        attachments_attributes: attachments,
        occurrences_attributes: occurrences,
        aircraft_id: id,
      };
      if (mode === 'add') {
        if (sectorFault && !flightId) {
          dispatch(handleBackButtonClickWithPayload({ payload: { type: 'fault', content: params } }));
          // pass back to flight drawer
          // dispatch(setDrawerId({ payload: }))
        } else {
          params.flight_id = localFlightId;
          setLoading(true);
          await addIntermittentFault(params);
          dispatch(handleBackButtonClick());
          dispatch(setDrawerId({ payload: flightId }));
          dispatch(changeDrawerMode({ payload: 'view' }));
        }
      } else {
        setLoading(true);
        params.flight_id = localFlightId;
        await updateIntermittentFault(params);
        dispatch(changeDrawerVisibility({ payload: false }));
        dispatch(changeDrawerMode({ payload: 'view' }));
      }
      onUpdatedFault();
    }
  };

  const handleAddOccurrence = (): void => {
    dispatch(
      changeDrawerContent({
        payload: {
          content: <IntermittentFaultOccurrenceDrawer faultId={fault.id} onNewFault={onUpdatedFault} />,
          backButton: true,
        },
      }),
    );
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  const handleBackClick = (): void => {
    dispatch(handleBackButtonClick());
  };

  const handleCancelClick = (): void => {
    if (mode === 'add') {
      dispatch(changeDrawerVisibility({ payload: false }));
    } else {
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const handleSaveClick = (): void => {
    submitFault();
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const onFormInputChange = (key, value, occurrenceIndex): void => {
    const occur = [...occurrences];
    occur[occurrenceIndex][key] = value;
    if (key === 'reported_by_id') {
      const selected = people.find((p) => p.value === value);
      occur[occurrenceIndex].reported_by_name = selected.title;
    }
    setOccurrences(occur);
  };

  const onAddOccurrence = (): void => {
    const loggedInUser = userSettings.details.people.find((p) => p.organisation.id === operatorId);
    const occur = [...occurrences];
    occur.push({
      details: null,
      reported_by_id: loggedInUser?.id,
      reported_by_name: `${loggedInUser?.first_name} ${loggedInUser?.last_name}`,
      reported_at: moment().utc().format(),
    });
    setOccurrences(occur);
  };

  const updateFaultData = (key: string, value: any, secondKey?: string, secondValue?: any): void => {
    let newFaultData = {
      ...faultData,
      [key]: value,
    };
    if (secondKey) {
      newFaultData = {
        ...newFaultData,
        [secondKey]: secondValue,
      };
    }
    setFaultData(newFaultData);
  };

  const removeOccurrence = (index): void => {
    const newArray = occurrences;
    const isStoredInCore = typeof newArray[index].id === 'string';
    if (isStoredInCore) {
      // eslint-disable-next-line no-underscore-dangle
      newArray[index]._destroy = true;
    } else {
      newArray.splice(index, 1);
    }
    setOccurrences([...newArray]);
  };

  useEffect(() => {
    if (!faultData?.reported_at)
      setFaultData({
        ...faultData,
        reported_at: moment().utc().format(),
      });
    if (convertToDefect && faultData) {
      dispatch(changeDrawerVisibility({ payload: true }));
      dispatch(changeDrawerMode({ payload: 'add' }));
      dispatch(changeDrawerContent({ payload: { content: <DefectDrawer defectId="" /> } }));
      dispatch(setPassedData({ payload: { type: 'fault', content: faultData } }));
    }
  }, [faultData, convertToDefect, dispatch]);

  useEffect(() => {
    setFaultData(fault);
    if (fault.attachments) {
      setAttachments(fault.attachments);
    }
    if (fault?.flight_id) setLocalFlightId(fault?.flight_id);
  }, [fault]);

  useEffect(() => {
    const newArray = Array.from(peopleMap.values());
    const peopleArray = newArray
      .filter((person) => person.organisation.id === operatorId)
      .map((person) => {
        return {
          value: person.id,
          title: `${person.first_name} ${person.last_name}`,
        };
      });
    setPeople(peopleArray);
  }, [peopleMap, userSettings, operatorId, fault]);

  useEffect(() => {
    if (flightId) setLocalFlightId(flightId);
  }, [flightId]);

  return (
    <DrawerWrapper>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <Title>
            {drawerHistory?.length ? (
              <StyledBackButton onClick={handleBackClick}>
                <img src={backArrow} alt="back arrow" />
              </StyledBackButton>
            ) : null}
            {mode === 'add' ? `Add ${intermittentFaultName}` : intermittentFaultName}
            {mode === 'view' ? (
              <AuthWrapper>
                <AuthDropdownMenu
                  options={{
                    read: false,
                    update: true,
                    delete: true,
                    custom: mode === 'view',
                    custom2: mode === 'view',
                  }}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
                  resource={AircraftResource.TRIP}
                  aircraftId={id}
                  editCallback={(): void => handleEditClick()}
                  customText="Add Occurrence"
                  customCallback={(): void => handleAddOccurrence()}
                  custom2Text={`Convert to ${defectNameOverride}`}
                  custom2Callback={(): void => setConvertToDefect(true)}
                  handleDelete={(): void => handleDeleteIntermittentFault(fault.id)}
                />
              </AuthWrapper>
            ) : null}
          </Title>

          <SectorSection
            fault={faultData}
            updateFaultData={updateFaultData}
            aircraftId={id}
            localFlightId={localFlightId}
            setLocalFlightId={setLocalFlightId}
          />
          <InfoDivWrapper>
            <DetailsSection
              fault={faultData}
              editable={mode !== 'view'}
              updateFaultData={updateFaultData}
              aircraftId={id}
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </InfoDivWrapper>
          <Margins>
            {mode === 'view' &&
              occurrences.map((occurrence: IntermittentFaultOccurrence) => (
                <Accordion
                  dash
                  add
                  isExpanded={false}
                  key={occurrence.id}
                  headerContent={
                    <div>
                      <img src={EmptyStateFaults} alt="no faults" />
                      <NoDefectsText>{`Occurrence ${occurrence.number + 1}`}</NoDefectsText>
                    </div>
                  }
                >
                  <OccurrenceWrapper>
                    <IntermittentFaultOccurrenceForm occurrence={occurrence} />
                  </OccurrenceWrapper>
                </Accordion>
              ))}
            {mode === 'edit' && (
              <DrawerIconCard
                icon={EmptyStateFaults}
                // eslint-disable-next-line no-underscore-dangle
                showAdd={occurrences.filter((o) => !o._destroy).length === 0}
                buttonText="Add Occurrence"
                handleAddClick={onAddOccurrence}
                cardText={`Add ${intermittentFaultName} occurrence`}
              >
                {occurrences.map((occurrence, index) => {
                  // eslint-disable-next-line no-underscore-dangle
                  if (occurrence._destroy) return null;
                  return (
                    <OccurrenceWrapper key={occurrence.number} edit>
                      <HeaderWrapper>
                        <OccurrenceNum>
                          {occurrence.number ? `Occurrence ${occurrence.number + 1}` : 'New Occurrence'}
                        </OccurrenceNum>
                        <ButtonWrapper>
                          <DeleteButton type="button" onClick={(): void => removeOccurrence(index)}>
                            <img src={DeleteIcon} alt="delete button" />
                          </DeleteButton>
                        </ButtonWrapper>
                      </HeaderWrapper>
                      <IntermittentFaultOccurrenceForm
                        editable
                        occurrence={occurrence}
                        people={people}
                        onHandleInputChange={(key: string, value: string): void => onFormInputChange(key, value, index)}
                      />
                      {index + 1 === occurrences.length && (
                        <ButtonDiv>
                          <Button height="24px" onClick={onAddOccurrence} primary={false}>
                            <AddTextWrapper>
                              <PlusIcon src={plusIcon} alt="plus icon" />
                              Add occurrence
                            </AddTextWrapper>
                          </Button>
                        </ButtonDiv>
                      )}
                      {index + 1 !== occurrences.length && <Separator />}
                    </OccurrenceWrapper>
                  );
                })}
              </DrawerIconCard>
            )}
          </Margins>
        </>
      )}

      {mode !== 'view' ? (
        <ButtonSection handleCancelClick={handleCancelClick} handleSaveClick={handleSaveClick} loading={loading} />
      ) : null}
    </DrawerWrapper>
  );
};

export default IntermittentFaultsDrawer;
