/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Table } from '@arcflight/tf-component-library';
import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components';

const TableWrapper = styled.div`
  & thead th {
    button {
      background-color: transparent;
      color: #c8c8c8;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.33px;
      border: none;
      text-transform: uppercase;
      border-spacing: 0;
      padding: 0;
    }
  }
`;

interface TFTableProps {
  columns: Array<any>;
  data: Array<any>;
  hiddenColumns?: Array<string>;
  handleRowClick: (id: string) => void | null;
  total: number;
  pageSize: number;
  pageIndex: number;
  onPaginationChange: (currentPage: number, numberOfItems: number) => void;
  onSortChange: (sort) => void;
  resetToOne: boolean;
}

const TFTable: React.FC<TFTableProps> = ({
  columns,
  data,
  hiddenColumns,
  handleRowClick,
  total,
  pageSize,
  pageIndex,
  onPaginationChange,
  onSortChange,
  resetToOne,
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      initialState: { hiddenColumns, pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );

  return (
    <TableWrapper>
      <Table
        tableInstance={tableInstance}
        handleRowClick={(row): void => handleRowClick(row.original.id)}
        total={total}
        handlePaginationChange={(currentPage, numberOfItems): void => onPaginationChange(currentPage, numberOfItems)}
        passedPageSize={pageSize}
        handleSortChange={(test): void => onSortChange(test)}
        resetToOne={resetToOne}
      />
    </TableWrapper>
  );
};

export default TFTable;
