/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button, Table, Dropdown, Menu, Icon, Modal } from 'antd';
import { Search } from '@arcflight/tf-component-library';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loading from '../../components/TFLoading';
import TFTag from '../../components/TFTag/TFTag';
import servers from '../../utils/servers';
import EmptyState from '../../components/EmptyState/EmptyState';
import expandIcon from '../../assets/expand.svg';
import collapseIcon from '../../assets/collapse.svg';
import Header from '../../components/Modals/common/header';
import FileViewer from '../../components/TFFileViewer/fileViewer';
import TFCard from '../../components/TFCard/TFCard';
import { fetch as fetchDamageMaps } from '../../models/damageMaps/actions';
import { fetch as fetchDamageReports } from '../../models/damageReports/actions';
import NonStyledButton from '../../components/NonStyledButton/NonStyledButton';
import EmptyStateDamageMaps from '../../assets/emptyState/empty-state-damage-maps.svg';
import styles from './DamageMaps.module.less';

const mobileViewWidth = 576;

class ListView extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    damageMaps: PropTypes.object.isRequired,
    damageReports: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = props;
    this.state = {
      globalFilter: '',
      selectedRowKeys: [],
      damageMapsLoading: false,
      damageReport: [],
      displayingDamageReports: [],
      originalList: [],
      sortOrder: null,
      sortColumn: null,
      activePage: 1,
      itemIndex: -1,
      selectorButtons: [],
      showImageModal: false,
      onRow: false,
      selectorDropDown: [],
      viewTitle: 'all',
      categoryButtonSelected: 'general',
      highlightId: '',
      attachmentThumbnails: [],
      thumbnailIndex: 0,
      selectorButtonsNames: ['general', 'fuselage', 'wings', 'empennage', 'powerplant', 'propellor', 'windows'],
      status: {
        reported: {
          text: formatMessage({ id: 'status.reported' }),
        },
        approved: {
          text: formatMessage({ id: 'status.approved' }),
        },
        abrasion: {
          text: formatMessage({ id: 'status.rejected' }),
        },
      },
      columnFilter: {},
      reset: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getDamageReports();
      this.getSelectorButtons();
      this.getSelectorDropDown();
    }
  }

  componentDidUpdate(prevProps) {
    const { damageMaps, damageReports, match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.getDamageReports();
      this.getSelectorButtons();
      this.getSelectorDropDown();
      this.resetLocationState();
    }
    if (damageMaps.damageMapsMap !== prevProps.damageMaps.damageMapsMap) {
      this.setUpDamageMaps();
    }
    if (damageReports.damageReportsMap !== prevProps.damageReports.damageReportsMap) {
      const damageReportArray = Array.from(damageReports.damageReportsMap.get(match.params.id).values()).map((row) => ({
        expanded: false,
        ...row,
      }));
      this.setUpDamageReports(damageReportArray);
    }
  }

  setUpDamageMaps = () => {
    const { damageMaps, match } = this.props;
    this.setState(
      {
        damageMaps: Array.from(damageMaps.damageMapsMap.get(match.params.id).values()),
        damageMapsLoading: false,
      },
      () => {
        this.getDamageReportCategory();
        this.highlightRow();
      },
    );
  };

  setUpDamageReports = (damageReportArray) => {
    const { originalList } = this.state;
    if (!originalList.length) this.setState({ originalList: damageReportArray });
    this.setState(
      {
        damageReport: damageReportArray,
        damageReportsLoading: false,
        displayingDamageReports: damageReportArray,
        data: damageReportArray,
      },
      () => {
        this.getDamageMaps();
      },
    );
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getColumns = (columnFilter, status, onRow) => {
    const {
      intl: { formatMessage },
      userSettings: { dateFormat },
    } = this.props;
    return [
      {
        title: formatMessage({ id: 'title.damageType' }),
        dataIndex: 'type',
        width: 350,
        className: styles.firstColumn,
        filters: Object.keys(status).map((item) => {
          return {
            text: status[item].text,
            value: item,
          };
        }),
        filteredValue: columnFilter.status,
        onFilter: (value, record) => {
          return record.status === value;
        },
        render: (text, record) => {
          const recordLength = record.details ? record.details.length : 0;
          let displayDetails;
          if (record.expanded === false && recordLength > 60) {
            displayDetails = record.details.slice(0, 57).concat('...');
          } else {
            displayDetails = record.details;
          }

          let classStyle = styles.statusAbrasion;
          if (record.status === 'reported') {
            classStyle = styles.statusReported;
          } else if (record.status === 'approved') {
            classStyle = styles.statusApproved;
          }

          let tagColor = 'amber';
          if (record.status === 'reported') {
            tagColor = 'red';
          } else if (record.status === 'approved') {
            tagColor = 'green';
          }

          return (
            <div>
              <span className={classStyle}>
                {record.type ? record.type : '-'}
                <TFTag colour={tagColor} margin="0 0 0 8px">
                  {record.status}
                </TFTag>
              </span>
              <div className={styles.detailsDiv}>
                <div
                  className={
                    record.expanded && recordLength > 60 ? styles.expandableDivExpanded : styles.expandableDivCollapsed
                  }
                >
                  <span className={styles.displayDetailsSpan}>{displayDetails}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: formatMessage({ id: 'title.dateIssued' }),
        dataIndex: 'created_at',
        width: 180,
        render: (text, record) => {
          return moment(record.created_at).format(dateFormat);
        },
        sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
      },
      {
        title: formatMessage({ id: 'title.reportBy' }),
        dataIndex: 'reported_by',
        align: 'center',
        className: styles.tableColumn,
        width: 170,
        sorter: (a, b) => {
          const aReportBy = a.reported_by ? a.reported_by : '';
          const bReportBy = b.reported_by ? b.reported_by : '';
          return aReportBy.localeCompare(bReportBy);
        },
        render: (text, record) => {
          return record.reported_by ? record.reported_by : '-';
        },
      },
      {
        title: formatMessage({ id: 'title.approvedBy' }),
        dataIndex: 'approved_by',
        align: 'center',
        className: styles.tableColumn,
        width: 170,
        sorter: (a, b) => {
          const aApprovedBy = a.approved_by ? a.approved_by : '';
          const bApprovedBy = b.approved_by ? b.approved_by : '';
          return aApprovedBy.localeCompare(bApprovedBy);
        },
        render: (text, record) => {
          return record.approved_by ? record.approved_by : '-';
        },
      },
      {
        title:
          window.innerWidth > mobileViewWidth
            ? formatMessage({ id: 'title.filePreview' })
            : formatMessage({ id: 'title.preview' }),
        dataIndex: 'attachments',
        width: 170,
        align: 'center',
        render: (text, record) => {
          const thumbnails = [];
          for (let i = 0; i < 2; i += 1) {
            const attachment = record.attachments[i];
            if (attachment) {
              thumbnails.push(
                <NonStyledButton
                  onClick={() => this.handleThumbnailClick(true, attachment.thumbnail_url, record.attachments, i)}
                >
                  <div
                    key={attachment.id}
                    className={styles.listThumbnailImage}
                    style={{
                      backgroundImage: `url(${servers.api}/${attachment.thumbnail_url})`,
                      top: `${i * 5}px`,
                      left: `${i * 8}px`,
                      zIndex: `${10 - i}`,
                      height: i === 1 ? '55px' : '65px',
                    }}
                  />
                </NonStyledButton>,
              );
            }
          }
          return (
            <div className={styles.thumbnailWrapperDiv}>
              <FileViewer attachments={record.attachments} disableAddition={false} uploaderVisible={false} />
            </div>
          );
        },
        sorter: (a, b) => {
          return a.attachments.length - b.attachments.length;
        },
      },
      {
        dataIndex: 'id',
        width: 60,
        render: (id, record, index) => {
          const recordLength = record.details ? record.details.length : 0;
          let expandImage = null;
          if (recordLength > 60) {
            if (record.expanded === true) {
              expandImage = <img src={collapseIcon} className={styles.expandIcon} alt="collapse-icon" />;
            } else {
              expandImage = <img src={expandIcon} className={styles.expandIcon} alt="expand-icon" />;
            }
          }
          return (
            <div className={styles.buttonsDiv}>
              <div className={styles.expandIconWrapper}>{expandImage}</div>
              <div className={onRow === index ? styles.menuDots : `${styles.menuDots} ${styles.menuDotsHidden}`}>
                {/* <Dropdown
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <Menu.Item key="delete">{props.intl.formatMessage({ id: 'form.button.delete' })}</Menu.Item>
                    </Menu>
                  }
                >
                  <div>
                    <Icon className={styles.dropdownIcon} type="more" />
                  </div>
                </Dropdown> */}
              </div>
            </div>
          );
        },
      },
    ];
  };

  getDamageReports() {
    const { dispatch, match } = this.props;
    dispatch(
      fetchDamageReports({
        payload: {
          id: match.params.id,
        },
      }),
    );
  }

  getDamageMaps() {
    const { dispatch, match } = this.props;
    this.setState(
      {
        damageMapsLoading: true,
      },
      () => {
        dispatch(
          fetchDamageMaps({
            payload: {
              id: match.params.id,
            },
          }),
        );
      },
    );
  }

  getDamageReportCategory = () => {
    const { damageMaps, damageReport } = this.state;
    const newDamageReport = damageReport.map((report) => {
      const newReport = report;
      damageMaps.forEach((map) => {
        if (map.id === report.damage_map_id) {
          newReport.category = map.category;
        }
      });
      return newReport;
    });
    this.setState({
      damageReport: newDamageReport,
      displayingDamageReports: newDamageReport,
    });
  };

  getSelectorButtons = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { selectorButtonsNames, categoryButtonSelected } = this.state;
    const buttons = selectorButtonsNames.map((button) => {
      let buttonText = button;
      if (button === 'general') {
        buttonText = 'all';
      } else if (button === 'propellor') {
        buttonText = 'propeller';
      }
      return (
        <Button
          key={button}
          className={categoryButtonSelected === button ? styles.selectedSelectorButton : styles.selectorButton}
          onClick={() => {
            this.showReportsForSelected(button);
            this.updateSelectedButton(button);
          }}
        >
          {formatMessage({
            id: `form.button.${buttonText}`,
          })}
        </Button>
      );
    });
    this.setState({ selectorButtons: buttons });
  };

  getSelectorDropDown = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const dropdownOptions = this.state.selectorButtonsNames.map((button) => {
      let buttonText = button;
      if (button === 'general') {
        buttonText = 'all';
      } else if (button === 'propellor') {
        buttonText = 'propeller';
      }
      return (
        <Menu.Item
          key={button}
          className={styles.option}
          value={button}
          onClick={() => this.showReportsForSelected(button)}
        >
          {formatMessage({
            id: `form.button.${buttonText}`,
          })}
        </Menu.Item>
      );
    });
    const dropdown = <Menu className={styles.dropdown}>{dropdownOptions}</Menu>;
    this.setState({ selectorDropDown: dropdown });
  };

  getModalHeader = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Header
        headerIcon={null}
        handleModalVisible={this.handleModalVisible}
        headerTitle={formatMessage({ id: 'title.filePreview' })}
      />
    );
  };

  handleModalVisible = (visible) => {
    this.setState({
      showImageModal: visible,
    });
  };

  closeModal = () => {
    this.setState({ showImageModal: false });
  };

  showReportsForSelected = (button) => {
    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line array-callback-return
    const { damageReport } = this.state;
    const result = damageReport.filter((report) => {
      if (button === 'general') {
        return report;
      }
      if (report.category === button) {
        return report;
      }
      return null;
    });
    this.setState({ displayingDamageReports: result, viewTitle: button });
  };

  updateSelectedButton = (button) => {
    this.setState({ categoryButtonSelected: button }, () => {
      this.getSelectorButtons();
    });
  };

  highlightRow = () => {
    const { location } = this.props;
    this.setState(
      {
        highlightId: location.state ? location.state.damageReportID : '',
      },
      () => {
        const currentArr = this.state.displayingDamageReports ? this.state.displayingDamageReports : [];

        currentArr.sort(this.sortArrByCol);

        const foundItemIndex = currentArr.findIndex((item) => item.id === this.state.highlightId);
        const stateObj = this.state;
        const pageSizeObj = stateObj.pageSize ? stateObj.pageSize : 10;
        const pageNum = Math.floor(foundItemIndex / pageSizeObj + 1);

        this.setState({
          activePage: pageNum,
          itemIndex: foundItemIndex,
        });

        const row = document.getElementsByClassName(styles.highlightedRow)[0];
        const rowObject = {};
        rowObject.id = this.state.highlightId;
        this.handleExpandClick(rowObject);
        if (row) window.scrollTo(0, row.offsetTop);
        this.timeout = setTimeout(() => {
          this.setState({
            highlightId: '',
          });
        }, 4000);
      },
    );
  };

  resetLocationState = () => {
    if (this.props.location.state) this.props.location.state.damageReportID = '';
  };

  handleExpandClick = (e) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        displayingDamageReports: prevState.displayingDamageReports.map((row) => {
          return row.id === e.id ? { ...row, expanded: !row.expanded } : row;
        }),
      };
    });
  };

  sortArrByCol = (a, b) => {
    const { sortOrder, sortColumn } = this.state;
    if (!['ascend', 'descend'].includes(sortOrder)) {
      return 0;
    }
    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) {
      return sortOrder === 'ascend' ? -1 : 1;
    }
    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) {
      return sortOrder === 'descend' ? 1 : -1;
    }
    return 0;
  };

  displayThumbnail = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { attachmentThumbnails, thumbnailIndex } = this.state;
    const thumbnails = attachmentThumbnails.map((thumbnail, index) => {
      return (
        <div className={styles.thumbnailColumnDiv}>
          <NonStyledButton onClick={() => this.setState({ thumbnailIndex: index })}>
            <div
              key={thumbnail.id}
              className={styles.thumbnailWrapper}
              style={{
                backgroundImage: `url(${servers.api}/${thumbnail.thumbnail_url})`,
              }}
            />
          </NonStyledButton>
        </div>
      );
    });
    let attachmentThumbnailImage = null;
    if (attachmentThumbnails[thumbnailIndex]) {
      if (attachmentThumbnails[thumbnailIndex].url.includes('pdf')) {
        attachmentThumbnailImage = (
          <img
            src={`${servers.api}/${
              attachmentThumbnails[thumbnailIndex] ? attachmentThumbnails[thumbnailIndex].thumbnail_url : null
            }`}
            alt={formatMessage({ id: 'text.displayedImage' })}
          />
        );
      } else {
        attachmentThumbnailImage = (
          <img
            src={`${servers.api}/${
              attachmentThumbnails[thumbnailIndex] ? attachmentThumbnails[thumbnailIndex].url : null
            }`}
            alt={formatMessage({ id: 'text.displayedImage' })}
          />
        );
      }
    }
    if (window.innerWidth > mobileViewWidth) {
      return (
        <div className={styles.thumbnailModal}>
          <div className={styles.thumbnailDiv}>{thumbnails}</div>
          <div className={styles.imageDivWrapper}>
            <div className={styles.arrowDiv}>
              <Icon
                type="left"
                className={
                  thumbnailIndex !== null && thumbnailIndex !== 0 ? styles.arrowButtonClickable : styles.arrowButton
                }
                onClick={() => {
                  if (thumbnailIndex > 0) {
                    this.setState({ thumbnailIndex: thumbnailIndex - 1 }, () => {
                      this.displayThumbnail();
                    });
                  }
                }}
              />
            </div>
            <div className={styles.displayDiv}>{attachmentThumbnailImage}</div>
            <div className={styles.arrowDiv}>
              <Icon
                type="right"
                className={
                  (
                    attachmentThumbnails
                      ? attachmentThumbnails.length !== 0 && attachmentThumbnails.length - 1 > thumbnailIndex
                      : ''
                  )
                    ? styles.arrowButtonClickable
                    : styles.arrowButton
                }
                onClick={() => {
                  if (attachmentThumbnails.length === 0) {
                    return null;
                  }
                  if (thumbnailIndex === null || 0) {
                    this.setState({ thumbnailIndex: 0 }, () => {
                      this.displayThumbnail();
                    });
                  } else if (thumbnailIndex !== attachmentThumbnails.length - 1) {
                    this.setState({ thumbnailIndex: thumbnailIndex + 1 }, () => {
                      this.displayThumbnail();
                    });
                  }
                  return null;
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.thumbnailModalMobile}>
        <div className={styles.thumbnailDiv}>{thumbnails}</div>
        <div className={styles.imageDivWrapper}>
          <div className={styles.arrowDiv}>
            <Icon
              type="left"
              className={
                thumbnailIndex !== null && thumbnailIndex !== 0 ? styles.arrowButtonClickable : styles.arrowButton
              }
              onClick={() => {
                if (thumbnailIndex > 0) {
                  this.setState({ thumbnailIndex: thumbnailIndex - 1 }, () => {
                    this.displayThumbnail();
                  });
                }
              }}
            />
          </div>
          <div className={styles.displayDiv}>{attachmentThumbnailImage}</div>
          <div className={styles.arrowDiv}>
            <Icon
              type="right"
              className={
                (
                  attachmentThumbnails
                    ? attachmentThumbnails.length !== 0 && attachmentThumbnails.length - 1 > thumbnailIndex
                    : ''
                )
                  ? styles.arrowButtonClickable
                  : styles.arrowButton
              }
              onClick={() => {
                if (attachmentThumbnails.length === 0) {
                  return null;
                }
                if (thumbnailIndex === null || 0) {
                  this.setState({ thumbnailIndex: 0 }, () => {
                    this.displayThumbnail();
                  });
                } else if (thumbnailIndex !== attachmentThumbnails.length - 1) {
                  this.setState({ thumbnailIndex: thumbnailIndex + 1 }, () => {
                    this.displayThumbnail();
                  });
                }
                return null;
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  filterTable = () => {
    const { data } = this.state;
    const filteredBy = this.state.globalFilter.toLowerCase();
    const newDataSource = [];
    if (filteredBy === null || '') {
      this.setState({ displayingDamageReports: data });
    } else {
      this.state.data.forEach((d) => {
        const objKeys = Object.keys(d);
        let alreadyAdded = false;
        objKeys.forEach((k) => {
          let val = d[k];
          if (typeof val === 'string') {
            val = val.toLowerCase();
            if (val.includes(filteredBy) && !alreadyAdded) {
              newDataSource.push(d);
              alreadyAdded = true;
            }
          }
        });
      });
      this.setState({ displayingDamageReports: newDataSource });
    }
  };

  handleChangeSort = (pagination, filters, sorter) => {
    this.setState({
      sortOrder: sorter.order,
      sortColumn: sorter.columnKey,
    });
  };

  handleThumbnailClick = (showImageModal, thumbnailUrl, attachmentThumbnails, thumbnailIndex) => {
    this.setState({ showImageModal, thumbnailUrl, attachmentThumbnails, thumbnailIndex });
  };

  resetFilters = () => {
    this.setState({ reset: true, globalFilter: '' }, () => this.filterTable());
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      columnFilter,
      damageMapsLoading,
      displayingDamageReports,
      highlightId,
      onRow,
      activePage,
      selectorButtons,
      selectorDropDown,
      showImageModal,
      status,
      viewTitle,
      reset,
      originalList,
    } = this.state;
    const myColumns = this.getColumns(columnFilter, status, onRow);
    return (
      <>
        <TFCard border="none" className={styles.cardStyling}>
          <div id="searchWrapper" className={styles.filterDiv}>
            <Search
              onChange={(e) => {
                this.setState({ globalFilter: e.currentTarget.value, reset: false }, () => {
                  this.filterTable();
                });
              }}
              onClear={() => {
                this.setState({ globalFilter: '', reset: false }, () => {
                  this.filterTable();
                });
              }}
              reset={reset}
            />
          </div>
          <div className={styles.selectorButtons}>{selectorButtons}</div>
          <div className={styles.selectorDropdown}>
            <span>{formatMessage({ id: 'text.youAreViewing' })}</span>
            <Dropdown placement="bottomCenter" overlay={selectorDropDown}>
              <div className={styles.dropdown}>
                <span className={styles.dropdownTitle}>{viewTitle}</span> <Icon type="down" />
              </div>
            </Dropdown>
          </div>
          <div>
            <Loading loading={damageMapsLoading} contain />
            {displayingDamageReports.length ? (
              <Table
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
                onRow={(row, rowIndex) => {
                  return {
                    onMouseEnter: () => this.setState({ onRow: rowIndex }),
                    onMouseLeave: () => this.setState({ onRow: '' }),
                    onClick: () => this.handleExpandClick(row),
                  };
                }}
                dataSource={displayingDamageReports}
                scroll={{ x: 1000 }}
                columns={myColumns}
                onChange={this.handleChangeSort}
                pagination={{
                  showSizeChanger: true,
                  showQuickJumper: true,
                  current: activePage,
                  onChange: (page, size) => {
                    this.setState({
                      pageSize: size,
                      activePage: page,
                    });
                  },
                  onShowSizeChange: (page, size) => {
                    this.setState({
                      pageSize: size,
                      activePage: page,
                    });
                  },
                }}
                rowClassName={(record) => (record.id === highlightId ? styles.highlightedRow : '')}
              />
            ) : (
              <EmptyState
                image={EmptyStateDamageMaps}
                text={originalList.length ? "We couldn't find any matching damage points" : 'No damage points'}
                subText={
                  originalList.length
                    ? 'Try searching with another term.'
                    : 'Areas of damage will be visible here once noted on the schematic diagrams.'
                }
                button={originalList.length ? 'Clear all' : null}
                buttonAction={originalList.length ? this.resetFilters : null}
              />
            )}
          </div>
        </TFCard>
        <Modal
          title={this.getModalHeader()}
          visible={showImageModal}
          destroyOnClose
          closable={false}
          onCancel={this.closeModal}
          className={styles.thumbnailPreviewModal}
          footer={null}
        >
          {this.displayThumbnail()}
        </Modal>
      </>
    );
  }
}
const listViewWithRedux = connect(({ damageMaps, damageReports, userSettings }) => ({
  damageMaps,
  damageReports,
  dateFormat: userSettings.dateFormat,
  userSettings,
}))(ListView);
export default withRouter(injectIntl(listViewWithRedux));
