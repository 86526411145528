import { Loading } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { addIntermittentFaultOccurrence } from '../../services/api';
import { IntermittentFault } from '../../models/intermittentFaults';
import { updateDrawerContent, handleBackButtonClick } from '../../models/drawer';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import ButtonSection from '../../components/TripDrawer/ButtonSection';
import { getPeople } from '../../models/people/actions';
import DetailsSection from './DetailsSection';
import AddOccurrence from './AddOccurrence';

interface IntermittentFaultOccurrenceDrawerProps {
  faultId?: string;
  onNewFault?: () => void;
}

const defaultProps = {};

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 24px 0 32px;
  padding: 20px;
`;

const RecordedSection = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

const IntermittentFaultOccurrenceDrawer: React.FC<IntermittentFaultOccurrenceDrawerProps & typeof defaultProps> = ({
  faultId,
  onNewFault,
}) => {
  const {
    userSettings,
    intermittentFaults: { intermittentFaultsMap },
    aircraft: { aircraftMap },
    people: { peopleMap },
    drawer: { drawerHistory, mode },
  } = useSelector((state: DashboardState) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [furtherDetails, setFurtherDetails] = useState(null);
  const [reportedBy, setReportedBy] = useState(null);
  const [reportedDate, setReportedDate] = useState(moment().format());
  const [fault, setFault] = useState<IntermittentFault | null>(null);
  const { id } = useParams<{ id: string }>();
  const operatorId = aircraftMap.get(id)?.operator_id;

  useEffect(() => {
    if (peopleMap.size === 0) {
      dispatch(getPeople());
    }
  }, [peopleMap, dispatch]);

  useEffect(() => {
    const newArray = Array.from(peopleMap.values());
    const peopleArray = newArray
      .filter((person) => person.organisation.id === operatorId)
      .map((person) => {
        return {
          value: person.id,
          title: `${person.first_name} ${person.last_name}`,
        };
      });
    const loggedInUser = userSettings.details.people.find((p) => p.organisation.id === operatorId);
    if (loggedInUser) {
      setReportedBy({
        value: loggedInUser.id,
        title: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
      });
    }

    setPeople(peopleArray);
  }, [peopleMap, userSettings, operatorId]);

  useEffect(() => {
    const f = intermittentFaultsMap.get(faultId);
    setFault(f);
    setLoading(null);
  }, [faultId, intermittentFaultsMap, fault]);

  const handleSaveOccurrence = async (): Promise<void> => {
    const params = {
      reported_at: reportedDate,
      intermittent_fault_id: fault.id,
      reported_by_id: reportedBy.value,
      details: furtherDetails,
    };
    await addIntermittentFaultOccurrence(params);
    dispatch(updateDrawerContent({ payload: true }));
    dispatch(handleBackButtonClick());
    onNewFault();
  };

  const handleBackClick = (): void => {
    dispatch(updateDrawerContent({ payload: true }));
    dispatch(handleBackButtonClick());
  };

  const handleCancelClick = (): void => {
    if (drawerHistory.length && mode === 'add') {
      handleBackClick();
    }
  };
  return (
    <DrawerWrapper>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <div>{`Add Occurrence: ${fault?.number || ''}`}</div>
          <RecordedSection>
            <div>
              <DateTimePicker
                headerDate="Date Recorded"
                headerTime="Time Recorded"
                handleDateTimeChange={setReportedDate}
                dateTime={reportedDate}
                noFuture
              />
            </div>
          </RecordedSection>
          <InfoDivWrapper>
            <DetailsSection fault={fault} editable={false} aircraftId={id} attachments={fault.attachments || []} />
          </InfoDivWrapper>
          <InfoDivWrapper>
            <AddOccurrence
              handleFurtherDetailsChange={setFurtherDetails}
              reportedBy={reportedBy}
              handleReportedByChange={setReportedBy}
              people={people}
            />
          </InfoDivWrapper>
          <ButtonSection
            handleSaveClick={handleSaveOccurrence}
            handleCancelClick={handleCancelClick}
            loading={loading}
          />
        </>
      )}
    </DrawerWrapper>
  );
};

export default IntermittentFaultOccurrenceDrawer;
