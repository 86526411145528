/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Loading } from '@arcflight/tf-component-library';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Card from '../../components/DefectDrawer/components/Card';
import { DashboardState } from '../../models';
import TFInput from '../../components/TFInput/TFInput';
import updateLocalDataObject from '../../utils/updateLocalDataObject';
import TFAddRemoveDropdown from '../../components/TFAddRemoveDropdown/TFAddRemoveDropdown';
import { Divider, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import plusIcon from '../../assets/plus-blue.svg';
import minusIcon from '../../assets/minus.svg';
import { changeDrawerVisibility } from '../../models/drawer';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import TFNumberInput from '../../components/TFNumberInput/TFNumberInput';
import TFDragAndDrop from '../../components/TFDragAndDrop/TFDragAndDrop';
import PageFooterActions from '../../components/PageFooterActions/PageFooterActions';
import findObjectById from '../../utils/findObjectById';
import { FRATOption, FRATQuestion, IndividualFRAT } from '../../models/frats';
import useGetIndividualFRATData from './useGetIndividualFRATData';
import useCreateFRATQuestion from './useCreateFRATQuestion';
import useUpdateFRATQuestion from './useUpdateFRATQuestion';

interface FRATDrawerProps {
  id?: string;
  categoryId?: string;
}

const defaultProps = {
  id: '',
  categoryId: '',
};

const Wrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
`;

const DrawerTitle = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  display: flex;
  align-items: baseline;
  span {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  letter-spacing: 0.06px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const MainDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 6px;
  img {
    margin-right: 4px;
    margin-bottom: 3px;
  }
`;

const ButtonSection = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
`;

const WeightWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const OptionsWrapper = styled.div`
  margin-top: 20px;
`;

const ReorderWrapper = styled.div`
  margin-right: 8px;
`;

const FooterActionsWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterButtonWrapper = styled.div`
  margin-right: 20px;
`;

const FRATDrawer: React.FC<FRATDrawerProps> = ({ id, categoryId }) => {
  const {
    drawer: { mode, drawerId },
  } = useSelector((state: DashboardState) => state);

  const queryClient = useQueryClient();

  // drawerId is the selectedFrat, passed id relates to the question id

  const { data, isLoading }: { data: IndividualFRAT; isLoading: boolean } = useGetIndividualFRATData({ drawerId });

  const [fratData, setFratData] = useState<FRATQuestion>(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [localOptions, setLocalOptions] = useState<FRATOption[]>([]);
  const [questionReorder, setQuestionReorder] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const updateFratData = (changes: { value: string; key: string }[]): void => {
    const newData = fratData ? _.cloneDeep(fratData) : {};
    const updatedData = updateLocalDataObject(changes, newData);
    setFratData(updatedData);
  };

  const handleAddFRATQuestionOnSuccess = (): void => {
    queryClient.invalidateQueries(['individualFRAT', drawerId]);
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleAddFRATQuestionOnError = (): void => {
    dispatch(
      addToast({
        payload: {
          title: 'Error adding FRAT question',
          message: '',
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const addNewQuestion = useCreateFRATQuestion({ handleAddFRATQuestionOnSuccess, handleAddFRATQuestionOnError });

  const handleUpdateQuestionOnSuccess = (): void => {
    queryClient.invalidateQueries(['individualFRAT', drawerId]);
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleUpdateQuestionOnError = (): void => {
    // todo
  };

  const updateQuestion = useUpdateFRATQuestion({ handleUpdateQuestionOnSuccess, handleUpdateQuestionOnError });

  const handleCategorySelect = (value: { title: string; value: string }): void => {
    setSelectedCategory([value]);
  };

  const handleOptionTitleChange = (value: string, index: number): void => {
    const newOptions = _.cloneDeep(localOptions);
    newOptions[index].option = value;
    setLocalOptions(newOptions);
  };

  const handleOptionWeightingChange = (value: number, index: number): void => {
    const newOptions = _.cloneDeep(localOptions);
    newOptions[index].weight = value;
    setLocalOptions(newOptions);
  };

  const handleRemoveOption = (index: number): void => {
    const newOptions = _.cloneDeep(localOptions);
    newOptions[index]._destroy = true;
    setLocalOptions(newOptions);
  };

  const handleReorderOptions = (): void => {
    setQuestionReorder(!questionReorder);
  };

  const buildOptions = (options: FRATOption[]): ReactElement => {
    if (questionReorder) {
      return <TFDragAndDrop items={options} component="FRATQuestion" setItemOrder={setLocalOptions} />;
    }
    return (
      <>
        {options
          ?.filter((option) => !option?._destroy)
          ?.map((option, index) => (
            <StyledGrid marginBottom="16px" key={option?.id}>
              <TFInput
                label={`Option ${index + 1}`}
                id={option?.id}
                value={option?.option}
                placeholder={`Text for option ${index + 1}`}
                onChange={(e): void => handleOptionTitleChange(e.target.value, index)}
              />
              <WeightWrapper>
                <TFNumberInput
                  value={option?.weight}
                  onChange={(value): void => handleOptionWeightingChange(value, index)}
                  id={`Weighting--${option?.id}`}
                  label="Weighting"
                />
                <ButtonWrapper data-testid={`FRATDrawer--RemoveOptionButtonWrapper-${option?.id}`}>
                  <Button height="24px" primary={false} onClick={(): void => handleRemoveOption(index)}>
                    <img src={minusIcon} alt="plus" />
                    {formatMessage({ id: 'text.removeOption' })}
                  </Button>
                </ButtonWrapper>
              </WeightWrapper>
            </StyledGrid>
          ))}
      </>
    );
  };

  const handleAddOption = (): void => {
    const newOptions = _.cloneDeep(localOptions);
    newOptions.push({
      option: `Option ${localOptions.length + 1}`,
      weight: 0,
      flight_risk_assessment_question_id: fratData?.id,
      sequence_number: (localOptions.length || 0) + 1,
    });
    setLocalOptions(newOptions);
  };

  const handleCancelClick = (): void => {
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleSaveClick = (): void => {
    if (!selectedCategory?.length) {
      dispatch(
        addToast({
          payload: {
            title: 'Question creation error',
            message: 'Please select a category',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      return;
    }

    let newSequenceNumber = 0;
    if (
      data?.flight_risk_assessment_question_categories?.find((cat) => cat.id === selectedCategory[0].value)
        ?.flight_risk_assessment_questions?.length
    ) {
      newSequenceNumber =
        data?.flight_risk_assessment_question_categories?.find((cat) => cat.id === selectedCategory[0].value)
          ?.flight_risk_assessment_questions?.length + 1;
    }
    const payload: {
      question: string;
      flight_risk_assessment_question_category_id: string;
      sequence_number: number;
      flight_risk_assessment_options_attributes: { option: string; sequence_number: number; weight: number }[];
      id?: string;
    } = {
      question: fratData?.question,
      flight_risk_assessment_question_category_id: selectedCategory[0]?.value,
      sequence_number: newSequenceNumber,
      flight_risk_assessment_options_attributes: localOptions,
    };

    if (fratData?.id) {
      payload.id = fratData?.id;
      delete payload.sequence_number;

      updateQuestion.mutate(payload);
    } else {
      addNewQuestion.mutate(payload);
    }
  };

  const handleFooterSaveClick = (): void => {
    setQuestionReorder(false);
  };

  const handleFooterCancelClick = (): void => {
    setQuestionReorder(false);
  };

  const footerActions = (
    <FooterActionsWrapper>
      <FooterButtonWrapper>
        <Button height="30px" onClick={handleFooterSaveClick}>
          {formatMessage({ id: 'text.save' })}
        </Button>
      </FooterButtonWrapper>
      <Button height="30px" onClick={handleFooterCancelClick} primary={false}>
        {formatMessage({ id: 'text.cancel' })}
      </Button>
    </FooterActionsWrapper>
  );

  useEffect(() => {
    if (data) {
      const newCategories = data?.flight_risk_assessment_question_categories?.map((cat) => {
        return { title: cat.category, value: cat.id };
      });
      setCategoryOptions(newCategories);

      const foundData = findObjectById(data, id);
      if (foundData) {
        setFratData(foundData);
      }
    }
  }, [data, id]);

  useEffect(() => {
    if (categoryOptions && (fratData || categoryId)) {
      const foundCategory = categoryOptions?.find(
        (cat) => cat.value === fratData?.flight_risk_assessment_question_category_id || cat.value === categoryId,
      );
      if (foundCategory) {
        setSelectedCategory([foundCategory]);
      }
    }
  }, [categoryId, categoryOptions, fratData]);

  useEffect(() => {
    if (fratData?.flight_risk_assessment_options) {
      setLocalOptions(fratData?.flight_risk_assessment_options);
    } else {
      const newOptions = [
        {
          option: `Option 1`,
          weight: 0,
          flight_risk_assessment_question_id: fratData?.id,
          sequence_number: 0,
        },
        {
          option: `Option 2`,
          weight: 0,
          flight_risk_assessment_question_id: fratData?.id,
          sequence_number: 1,
        },
      ];

      setLocalOptions(newOptions);
    }
  }, [fratData]);

  return (
    <Wrapper>
      {isLoading ? (
        <Loading loading={isLoading} contain />
      ) : (
        <>
          <DrawerTitle data-testid="FRATDrawer--DrawerTitle">
            {mode === 'edit'
              ? formatMessage({ id: 'text.editFRATQuestion' })
              : formatMessage({ id: 'text.addNewFRATQuestion' })}
          </DrawerTitle>
          <Card>
            <Title data-testid="FRATDrawer--DrawerHeader">{formatMessage({ id: 'text.mainDetails' })}</Title>
            {mode === 'view' ? null : (
              <>
                <StyledGrid>
                  <MainDetailsWrapper>
                    <TFInput
                      label="Name"
                      id="itemTitle"
                      value={fratData?.question}
                      onChange={(e): void => updateFratData([{ key: 'question', value: e.target.value }])}
                    />
                    {/* We have restricted this to add mode only as on edit core would need to know about the sequence
                    number which adds complexity and we are on a time crunch. In future this could be added back */}
                    {mode === 'add' && (
                      <TFAddRemoveDropdown
                        label="Category"
                        id="category"
                        initialValues={selectedCategory}
                        options={categoryOptions}
                        onSelect={handleCategorySelect}
                      />
                    )}
                  </MainDetailsWrapper>
                </StyledGrid>
                <Divider />
                <div>
                  <StyledGrid>
                    <div>
                      <Title data-testid="FRATDrawer--OptionsHeader">{formatMessage({ id: 'text.options' })}</Title>
                      <Text>{formatMessage({ id: 'text.addASingleChoice' })}</Text>
                    </div>
                    <ButtonWrapper data-testid="FRATDrawer--AddOptionButtonWrapper">
                      {localOptions.length > 0 && (
                        <ReorderWrapper>
                          <Button height="24px" onClick={handleReorderOptions}>
                            {formatMessage({ id: 'text.reorder' })}
                          </Button>
                        </ReorderWrapper>
                      )}
                      <Button height="24px" primary={false} onClick={handleAddOption}>
                        <img src={plusIcon} alt="plus" />
                        {formatMessage({ id: 'text.addOption' })}
                      </Button>
                    </ButtonWrapper>
                  </StyledGrid>
                  <OptionsWrapper>{buildOptions(localOptions)}</OptionsWrapper>
                </div>
              </>
            )}
          </Card>
          {mode !== 'view' ? (
            <ButtonSection>
              <Button height="32px" onClick={handleSaveClick}>
                {formatMessage({ id: 'text.save' })}
              </Button>
              <Button height="32px" onClick={handleCancelClick} primary={false}>
                {formatMessage({ id: 'text.cancel' })}
              </Button>
            </ButtonSection>
          ) : null}
          {questionReorder ? <PageFooterActions>{footerActions}</PageFooterActions> : null}
        </>
      )}
    </Wrapper>
  );
};

FRATDrawer.defaultProps = defaultProps;

export default FRATDrawer;
