import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const DurationFormat = ({ time }) => {
  const result = useMemo(() => {
    if (Number.isNaN(parseInt(time, 10))) {
      return '-';
    }
    let hours = Math.floor(time / 3600).toString();
    // math.round(e,0)
    let mins = Math.floor((time - hours * 3600) / 60).toString();

    if (hours.length < 2) {
      hours = `0${hours}`;
    }

    if (mins.length < 2) {
      mins = `0${mins}`;
    }

    return `${hours}:${mins}`;
  }, [time]);

  return <span>{result}</span>;
};

DurationFormat.propTypes = {
  time: PropTypes.number,
};

DurationFormat.defaultProps = {
  time: undefined,
};

export default DurationFormat;
