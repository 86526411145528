import React from 'react';
import styled from 'styled-components';

interface StyledCheckboxProps {
  checked: boolean;
  handleCheckboxClick: () => void;
  marginRight: string;
  name?: string;
}

const Wrapper = styled.div`
  input[type='checkbox'] {
    width: 16px;
    height: 16px;
  }
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '0')};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledInput = styled.input``;

const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ checked, handleCheckboxClick, marginRight, name = '' }) => {
  return (
    <Wrapper marginRight={marginRight} data-testid="StyledCheckbox-Wrapper">
      <StyledInput type="checkbox" name={name} checked={checked} onClick={handleCheckboxClick} />
    </Wrapper>
  );
};

export default StyledCheckbox;
