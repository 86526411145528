import React from 'react';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import Aircraft from '../Manage/Aircraft';
import Fleet from './Fleet';

const DashboardStyleHandler: React.FC = () => {
  const { userSettings } = useSelector((state: DashboardState) => state);
  const fleetStyle = userSettings?.details?.dash_preferences?.fleet_style;
  if (fleetStyle === 'light') {
    return <Aircraft isDashboardPage />;
  }
  return <Fleet />;
};

export default DashboardStyleHandler;
