import React, { useState } from 'react';
import styled from 'styled-components';
import errorIcon from '../../assets/login-error.svg';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

interface TFNumberInputProps {
  margin?: string;
  value?: string | number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  width?: string;
  id?: string;
  label?: string;
  optional?: boolean;
  tooltip?: string;
}

const Wrapper = styled.div`
  margin: ${({ margin }): string => (margin ? `${margin}` : '0')};
  width: ${({ width }): string => (width ? `${width}` : '100%')};
`;

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

const ErrorWrapper = styled.div`
  font-size: 16px;
  color: #ac1717;
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const TFNumberInput: React.FC<TFNumberInputProps> = ({
  margin,
  value = '',
  onChange,
  min,
  max,
  width,
  id = '',
  label,
  optional,
  tooltip,
}) => {
  const [error, setError] = useState(null);

  const handleChange = (input: string): void => {
    const numericValue = parseInt(input, 10);
    if ((min || min === 0) && numericValue < min) {
      setError(`Value must be greater than ${min}`);
    } else if ((max || max === 0) && numericValue > max) {
      setError(`Value must be less than ${max}`);
    } else {
      setError(null);
    }
    onChange(numericValue);
  };

  return (
    <Wrapper margin={margin} width={width} data-testid="TFNumberInput--Wrapper">
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <StyledInput
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={(e): void => handleChange(e.target.value)}
        data-testid={`TFNumberInput-Input-${id}`}
      />
      {error ? (
        <ErrorWrapper data-testid={`TFNumberInput-Error-${id}`}>
          <img src={errorIcon} alt="error" />
          {error}
        </ErrorWrapper>
      ) : null}
    </Wrapper>
  );
};

export default TFNumberInput;
