const checkPrecisionOfNumber = (value: number, precision: number): boolean => {
  if (value === undefined || value === null || typeof value !== 'number') return false;
  const stringValue = value.toString();
  if (stringValue.indexOf('.') !== -1) {
    const decimalPart = stringValue.split('.')[1];
    if (decimalPart.length <= precision) {
      return true;
    }
  } else {
    return true;
  }
  return false;
};

export default checkPrecisionOfNumber;
