import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row, Col, Button, Icon } from 'antd';
import styles from './MXDraftQueue.module.less';
import DraftQueueSummaryProgress from './DraftQueueSummaryProgress';

const DraftQueueSummary = ({ totalItems, onIndividualModeSwitch, items, intl: { formatMessage } }) => {
  const packagesStrokeColor = {
    '0%': '#0085ff',
    '100%': '#0065c2',
  };
  const scheduledStrokeColor = {
    '0%': '#5400ff',
    '100%': '#4e00c7',
  };
  const oopStrokeColor = {
    '0%': '#8342ff',
    '100%': '#ae86f9',
  };
  const llpStrokeColor = {
    '0%': '#baa3ff',
    '100%': '#baa2ff',
  };
  const defectsStrokeColor = {
    '0%': '#2058a4',
    '100%': '#3f79b5',
  };

  const packageCount = items.filter((item) => item.package).length;
  const scheduledCount = items.filter((item) => item.mx_type.includes('scheduled') && !item.package).length;
  const llpCount = items.filter((item) => item.mx_type.includes('llp') && !item.package).length;
  const oopCount = items.filter((item) => item.mx_type.includes('oop') && !item.package).length;
  // Defects count is 0 since CORE is not yet returning any defects to MX List
  const defectsCount = 0;

  const onReviewIndividualClick = () => {
    onIndividualModeSwitch();
  };
  return (
    <Row className={styles.draftQueueWrapper}>
      <Col md={24} className={styles.draftQueue}>
        <Row className={styles.summaryHeader}>
          <Col md={12} className={styles.colTitle}>
            <div className={styles.subTitle} data-test="subTitle">
              {formatMessage({ id: 'title.queueTotal' })}
            </div>
            <div className={styles.title} data-test="title">
              {totalItems || 0}{' '}
              {totalItems === 1
                ? formatMessage({ id: 'title.draftRequirement' })
                : formatMessage({ id: 'title.draftRequirements' })}
            </div>
          </Col>
          <Col md={12} className={styles.summaryLinkWrapper}>
            {totalItems > 1 && (
              <Button
                type="link"
                className={styles.summaryLink}
                onClick={() => onReviewIndividualClick()}
                data-test="buttonIndividual"
              >
                {formatMessage({ id: 'text.reviewDraftItemsIndividually' })}
                <Icon type="arrow-right" />
              </Button>
            )}
          </Col>
        </Row>
        <Row className={styles.summaryContent}>
          <DraftQueueSummaryProgress
            title={formatMessage({ id: 'text.packages' })}
            totalItems={totalItems}
            itemsCount={packageCount}
            strokeColor={packagesStrokeColor}
            data-test="summaryProgressPackages"
          />
          <DraftQueueSummaryProgress
            title={formatMessage({ id: 'title.scheduledItems' })}
            totalItems={totalItems}
            itemsCount={scheduledCount}
            strokeColor={scheduledStrokeColor}
            data-test="summaryProgressScheduled"
          />
          <DraftQueueSummaryProgress
            title={formatMessage({ id: 'text.oopItems' })}
            totalItems={totalItems}
            itemsCount={oopCount}
            strokeColor={oopStrokeColor}
            data-test="summaryProgressOOP"
          />
          <DraftQueueSummaryProgress
            title={formatMessage({ id: 'text.llpItems' })}
            totalItems={totalItems}
            itemsCount={llpCount}
            strokeColor={llpStrokeColor}
            data-test="summaryProgressLLP"
          />
          <DraftQueueSummaryProgress
            title={formatMessage({ id: 'title.defects' })}
            totalItems={totalItems}
            itemsCount={defectsCount}
            strokeColor={defectsStrokeColor}
            data-test="summaryProgressDefects"
          />
        </Row>
      </Col>
    </Row>
  );
};

DraftQueueSummary.propTypes = {
  totalItems: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onIndividualModeSwitch: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(DraftQueueSummary);
