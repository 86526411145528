import React, { forwardRef } from 'react';
import styled from 'styled-components';
import errorIcon from '../../assets/login-error.svg';
import validIcon from '../../assets/icon-circle-tick.svg';
import warningIcon from '../../assets/login-error-amber.svg';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

export type Status = 'error' | 'valid' | 'warning';

interface TFInputProps {
  id: string;
  value: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  status?: Status;
  statusMessage?: string;
  placeholder?: string;
  onChange?: (event) => void;
  optional?: boolean;
  leftIcon?: string;
  tooltip?: string;
  rounded?: boolean;
  onKeyPress?: (event) => void;
  autofocus?: boolean;
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

export const StatusIconMap = {
  error: errorIcon,
  valid: validIcon,
  warning: warningIcon,
};

const Input = styled.input<{ readOnly?: boolean; leftIcon?: string; rounded: boolean }>`
  height: 40px;
  padding: 12px 8px 12px 8px;
  border-radius: ${({ rounded }): string => (rounded ? '25px' : '2px')};
  border: ${({ theme }): string => `solid 1px ${theme.colours.black10Alpha}`};
  background-color: ${({ theme }): string => theme.colours.softGray};
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.4px;
  color: ${({ theme }): string => theme.colours.black90Alpha};
  outline: none;
  width: 100%;
  &.valid {
    border-color: ${({ theme }): string => theme.colours.black50Alpha};
    padding-right: 32px;
  }
  &:hover {
    border-color: ${({ theme }): string => theme.colours.brandBlue40Alpha};
  }
  &:focus {
    border-color: ${({ theme }): string => theme.colours.blue};
  }
  &.warning {
    border-color: ${({ theme }): string => theme.colours.orange};
    background-color: ${({ theme }): string => theme.colours.orange02Alpha};
    padding-right: 32px;
    &:hover {
      background-color: ${({ theme }): string => theme.colours.orange07Alpha};
    }
  }
  &.error {
    border-color: ${({ theme }): string => theme.colours.red};
    background-color: ${({ theme }): string => theme.colours.red02Alpha};
    padding-right: 32px;
    &:hover {
      background-color: ${({ theme }): string => theme.colours.red07Alpha};
    }
  }
  /* Additional styles for disabled prop */
  ${({ disabled, theme }: { disabled?: boolean; theme: any }): string =>
    disabled &&
    `
    cursor: not-allowed;
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
  /* Additional styles for readOnly prop */
  ${({ readOnly, theme }: { readOnly?: boolean; theme: any }): string =>
    readOnly &&
    `
    border: none;
    background-color: unset;
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
    &:focus {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
    ${({ leftIcon }: { leftIcon?: boolean }): string => leftIcon && `padding-left: 32px;`}
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

const LeftIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
`;

const InputFeedback = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: -0.4px;
  &.error {
    color: ${({ theme }): string => theme.colours.red};
  }
  &.valid {
    color: ${({ theme }): string => theme.colours.green};
  }
  &.warning {
    color: ${({ theme }): string => theme.colours.orange};
  }
`;

const TFInput = forwardRef<HTMLInputElement, TFInputProps>(
  (
    {
      disabled,
      readOnly,
      status,
      statusMessage,
      placeholder,
      onChange,
      value,
      id,
      optional,
      label,
      leftIcon,
      tooltip,
      rounded,
      onKeyPress,
      autofocus,
    },
    ref,
  ) => {
    return (
      <div style={{ flex: 1 }}>
        {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
        <InputContainer>
          {leftIcon && (
            <LeftIconContainer>
              <img data-testid={`TFInput-Left-Icon-${id}`} src={leftIcon} alt="left icon" />
            </LeftIconContainer>
          )}
          <Input
            id={id}
            data-testid={`TFInput-Input-${id}`}
            name={id}
            type="text"
            className={status}
            disabled={disabled}
            placeholder={placeholder}
            readOnly={readOnly}
            value={value}
            onChange={onChange}
            leftIcon={leftIcon}
            rounded={rounded}
            onKeyPress={onKeyPress}
            ref={ref}
            autoFocus={autofocus}
          />
          {status && (
            <IconContainer>
              <img data-testid={`TFInput-Img-${id}`} src={StatusIconMap[status]} alt="info icon" />
            </IconContainer>
          )}
        </InputContainer>
        {statusMessage && (
          <InputFeedback data-testid={`TFInput-Feedback-${id}`} className={status}>
            {statusMessage}
          </InputFeedback>
        )}
      </div>
    );
  },
);
export default TFInput;
