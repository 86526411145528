import React, { useState } from 'react';
import { Accordion, Button, Modal } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import styled from 'styled-components';
import aircraftIcon from '../../assets/icon-card-general-settings.svg';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { OperatorSetting } from '../../models/userSettings';
import AircraftSettingsCard from './AircraftSettingsCard';
import GeneralSettingsGrid from './GeneralSettingsGrid';
import { AccordionWrapper } from './AircraftAdvancedSettings';

interface AircraftGeneralSettingsProps {
  aircraft: Aircraft | BaseAircraft;
  originalAircraftData: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
  operatorSettings: OperatorSetting;
}

const ButtonWrapper = styled.div`
  display: flex;
  margin: ${({ modal }): string => (modal ? '16px 0 0 0' : '16px 0 16px 16px')};
`;

const SaveWrapper = styled.div`
  margin-right: 16px;
`;

const ModalText = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
`;

const ModalTitle = styled.div`
  color: rgba(36, 54, 65, 0.7);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
`;

const AircraftGeneralSettings: React.FC<AircraftGeneralSettingsProps> = ({
  aircraft,
  originalAircraftData,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
  operatorSettings,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [displayAPUWarningModal, setDisplayAPUWarningModal] = useState(false);

  const { formatMessage } = useIntl();

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    if (aircraft?.apu_mode !== originalAircraftData?.apu_mode) {
      setDisplayAPUWarningModal(true);
    } else if (operatorSettings?.max_weight_for_flight_enabled && aircraft?.max_weight && aircraft.max_weight < 0) {
      message.error('Max weight cannot be negative');
    } else if (operatorSettings?.max_weight_for_flight_enabled && !aircraft?.max_weight) {
      message.error('Max weight cannot be nothing');
    } else {
      handleSaveClick();
      setEditMode(false);
    }
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={aircraftIcon}
            title="General settings"
            buttonText="Edit general settings"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <GeneralSettingsGrid
          aircraft={aircraft}
          updateAircraftData={updateAircraftData}
          editMode={editMode}
          operatorSettings={operatorSettings}
        />
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
      <Modal isOpen={displayAPUWarningModal} handleClose={(): void => setDisplayAPUWarningModal(false)}>
        <ModalTitle>APU mode change warning</ModalTitle>
        <ModalText>{formatMessage({ id: 'text.areYouSureAPUMode' })}</ModalText>
        <ModalText>{formatMessage({ id: 'text.thisMayCauseHistorical' })}</ModalText>
        <ButtonWrapper modal>
          <SaveWrapper data-testid="AircraftGeneralSettings-SaveButton">
            <Button
              height="32px"
              onClick={(): void => {
                handleSaveClick();
                setEditMode(false);
                setDisplayAPUWarningModal(false);
              }}
            >
              Yes
            </Button>
          </SaveWrapper>
          <Button height="32px" primary={false} onClick={(): void => setDisplayAPUWarningModal(false)}>
            No
          </Button>
        </ButtonWrapper>
      </Modal>
    </AccordionWrapper>
  );
};

export default AircraftGeneralSettings;
