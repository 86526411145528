import _ from 'lodash';

export enum ToastTypes {
  SUCCESS = 'success',
  INFO = 'information',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum ToastCategories {
  FLASH = 'flash',
  FLAG = 'flag',
}

export const initialState: ToastsState = {
  toasts: [
    // uncomment for testing
    // {
    //   message: 'This is for testing purposes and is a long string to see how it is handled and what not',
    //   type: ToastTypes.ERROR,
    //   title: 'This is a header',
    //   category: 'flash',
    // },
    // {
    //   message: 'This is for testing purposes and is a long string to see how it is handled and what not take 2',
    //   type: ToastTypes.ERROR,
    //   title: 'This is a header error 2',
    //   category: 'flash',
    // },
    // {
    //   message: 'This is for testing purposes and is a long string to see how it is handled and what not take 2',
    //   type: ToastTypes.ERROR,
    //   title: 'This is a header error 3',
    //   category: 'flash',
    // },
    // {
    //   message: 'This is for testing purposes',
    //   type: ToastTypes.SUCCESS,
    //   title: 'This is an error header',
    //   category: 'flag',
    // },
    // {
    //   message: 'This is for testing purposes take 2',
    //   type: ToastTypes.SUCCESS,
    //   title: 'This is an error header 2',
    //   category: 'flash',
    // },
    // {
    //   message: 'This is for testing purposes take 3',
    //   type: ToastTypes.SUCCESS,
    //   title: 'This is an error header 3',
    //   category: 'flash',
    // },
    // {
    //   message: 'This is for testing purposes take 4',
    //   type: ToastTypes.SUCCESS,
    //   title: 'This is an error header 4',
    //   category: 'flash',
    // },
    // {
    //   message: 'This is a warning about things',
    //   type: ToastTypes.WARNING,
    //   title: 'This is a warning header',
    //   category: 'flag',
    // },
    // {
    //   message: 'This is an informative message about some info you might like',
    //   type: ToastTypes.INFO,
    //   title: 'This is an info header',
    //   category: 'flash',
    // },
  ],
};

enum ToastsActionTypes {
  ADD_TOAST = 'toasts/addToast',
  REMOVE_TOASTS = 'toasts/removeToast',
}

interface AddToast {
  type: ToastsActionTypes.ADD_TOAST;
  payload: Toast;
}

interface RemoveToast {
  type: ToastsActionTypes.REMOVE_TOASTS;
  payload: Toast;
}

type ToastAction = AddToast | RemoveToast;

export const addToast =
  ({ payload }: { payload: Toast }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: ToastsActionTypes.ADD_TOAST,
      payload,
    });
  };

export const removeToast =
  ({ payload }: { payload: Toast }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: ToastsActionTypes.REMOVE_TOASTS,
      payload,
    });
  };

export default function (state: ToastsState = initialState, action: ToastAction): ToastsState {
  switch (action.type) {
    case ToastsActionTypes.ADD_TOAST: {
      const newToasts = [...state.toasts];
      if (action?.payload) newToasts.push(action.payload);

      return {
        ...state,
        toasts: newToasts,
      };
    }
    case ToastsActionTypes.REMOVE_TOASTS: {
      const newToasts = [...state.toasts];
      if (action?.payload) {
        let objectToRemoveIndex = null;
        newToasts.forEach((obj, i) => {
          if (_.isEqual(obj, action.payload)) {
            objectToRemoveIndex = i;
          }
        });
        newToasts.splice(objectToRemoveIndex, 1);
      }
      return {
        ...state,
        toasts: newToasts,
      };
    }
    default:
      return state;
  }
}

export interface ToastsState {
  toasts: Toast[];
}

export interface Toast {
  message: string;
  type: ToastTypes;
  title: string;
  category: string;
}
