import { BaseModel } from '..';

const initialState: IntermittentFaultState = {
  intermittentFaultsMap: new Map(),
  lastFetched: 0,
};

export enum IntermittentFaultsActionTypes {
  SAVE_ALL = 'intermittentFaults/saveAll',
  SAVE = 'intermittentFaults/save',
  REMOVE = 'intermittentFaults/remove',
}

export interface SaveAllIntermittentFaultsActionType {
  type: IntermittentFaultsActionTypes.SAVE_ALL;
  payload: IntermittentFault[];
}
export interface SaveIntermittentFaultsActionType {
  type: IntermittentFaultsActionTypes.SAVE;
  payload: IntermittentFault;
}

export interface RemoveIntermittentFaultsActionType {
  type: IntermittentFaultsActionTypes.REMOVE;
  payload: { removeId };
}

export interface IntermittentFaultState {
  intermittentFaultsMap: Map<string, IntermittentFault>;
  lastFetched: number;
}
export interface IntermittentFault extends BaseModel {
  id: string;
  number: string;
  details: string;
  occurrence_count: string;
  last_updated: string;
  is_deleted: boolean;
  reported_at: string;
  reported_by_name: string;
  reported_by_id?: string;
  flight_id?: string;
  attachments: Attachment[];
}

export interface IntermittentFaultOccurrence extends BaseModel {
  id: string;
  intermittent_fault_id: string;
  flight_id: string;
  reported_at: string;
  reported_by_id: string;
  reported_by_name: string;
  details: string;
  number: string;
  _destroy?: boolean;
}

export interface Attachment {
  id: string;
  url: string;
  thumbnail_url: string;
  _destroy?: boolean;
}

type IntermittentFaultAction =
  | SaveIntermittentFaultsActionType
  | SaveAllIntermittentFaultsActionType
  | RemoveIntermittentFaultsActionType;

export default function (state = initialState, action: IntermittentFaultAction): IntermittentFaultState {
  switch (action.type) {
    case IntermittentFaultsActionTypes.SAVE_ALL: {
      const intermittentFaultsMap = new Map(); // @TODO: build map from previous entries and use client side filtering
      if (action.payload && action.payload) {
        action.payload.forEach((fault) => {
          intermittentFaultsMap.set(fault.id, {
            ...intermittentFaultsMap.get(fault.id),
            ...fault,
          });
        });
      }
      return {
        ...state,
        intermittentFaultsMap,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}
