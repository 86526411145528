import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import { formatTime } from '../../utils/utils';
import { changeDrawerContent, changeDrawerVisibility } from '../../models/drawer';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import styles from './ProgressCircle.module.less';

const ProgressCircle = ({ widget, widgetNumber, aircraftId }) => {
  const CIRCLE_STYLES = {
    open: { '0%': '#7bc88d', '100%': '#4aa91c' },
    critical: { '0%': '#fad288', '100%': '#f2a650' },
    overdue: { '0%': '#ff7474', '100%': '#ff4040' },
  };
  const [timeNow, setTimeNow] = useState(moment());
  const dispatch = useDispatch();

  const getUnitOfTime = () => {
    let newUnitOfTime = 'days';
    if (widget?.unitOfTime === 'months') {
      newUnitOfTime = 'months';
    }
    return newUnitOfTime;
  };

  useEffect(() => {
    setTimeNow(moment());
  }, []);
  const { title, threshold, status } = widget;
  let { total, nextDue, remaining, tolerance } = widget;

  let unit = 'hours';
  let percentage;
  if (title === 'Days') {
    percentage = ((remaining + tolerance * 30) / (threshold * 4)) * 100;
  } else {
    percentage = ((remaining + tolerance) / (threshold * 4)) * 100;
  }
  if (Number.isNaN(percentage) || percentage < 0) percentage = 0;
  const itemRemaining = remaining;
  if (nextDue === null) remaining = 'Unknown';
  if (title !== 'Days' && remaining <= 0) {
    if (remaining <= 0) {
      remaining = 'Overdue';
    }
  }

  if (title === 'Cycles') {
    unit = tolerance === 1 ? 'cycle' : 'cycles';
    nextDue += tolerance !== null ? tolerance : 0;
  }

  if (title === 'Days') {
    const differentYear =
      moment().format('YYYY') !==
      (tolerance === 0 ? nextDue : moment(nextDue).add(tolerance, getUnitOfTime()).format('YYYY'));
    nextDue =
      nextDue !== null
        ? moment(nextDue)
            .add(tolerance, getUnitOfTime())
            .format(differentYear ? 'DD MMM YY' : 'DD MMM')
        : '-';
    total = moment().format('DD MMM');
    const displayUnit = `${widget?.unitOfTime}`;
    unit = tolerance === 1 ? `${displayUnit.split('s')[0]}` : `${displayUnit}`;
    if (tolerance !== null && remaining > 0) {
      const dateOfRemaining = timeNow.add(remaining, 'days');
      if (moment(dateOfRemaining).diff(timeNow, 'days') < 0) {
        remaining = 'Overdue';
      }
    } else if (remaining === 0) {
      remaining = '<1';
    } else if (remaining < 0) {
      remaining = 'Overdue';
    }
  }

  const workOutTimes = (hoursNextDue, hoursTotal, hoursRemaining, hoursTolerance) => {
    let newHours = hoursNextDue;

    if (hoursNextDue !== null) {
      newHours += hoursTolerance !== null ? hoursTolerance : 0;
      nextDue = formatTime(newHours);
    }
    total = hoursTotal && formatTime(hoursTotal, true);
    if (remaining !== 'Unknown' && remaining !== 'Overdue' && remaining !== 'Critical' && remaining !== null) {
      remaining = hoursRemaining !== 0 ? formatTime(hoursRemaining) : '00:00';
    }
  };

  if (title === 'Hours' || title === 'APU Hours') {
    percentage = itemRemaining / 3600;
    tolerance /= 3600;
    unit = tolerance === 1 ? 'hour' : 'hours';
    total = Math.round(total / 60) * 60;
    workOutTimes(nextDue, total, itemRemaining, tolerance);
  }

  if (remaining === 'Overdue' || remaining === 'Critical') percentage = 100;

  let strokeColour = CIRCLE_STYLES.open;
  if ((title === 'Hours' || title === 'APU Hours') && widget.remaining / 3600 < (threshold * 4) / 3) {
    strokeColour = CIRCLE_STYLES.critical;
  } else if (remaining < (threshold * 4) / 3) {
    strokeColour = CIRCLE_STYLES.critical;
  }
  if (remaining === 'Overdue') strokeColour = CIRCLE_STYLES.overdue;
  let remainingStyling = styles.innerNumber;
  if ((title === 'Hours' || title === 'APU Hours') && widget.remaining / 3600 < (threshold * 4) / 3) {
    remainingStyling = styles.criticalStyle;
  } else if (widget.remaining < (threshold * 4) / 3) {
    remainingStyling = styles.criticalStyle;
  }
  if (remaining === 'Overdue') {
    remainingStyling = styles.redStyle;
  }
  if (remaining === 'Unknown') {
    percentage = 0;
    remainingStyling = `${styles.innerNumber} ${styles.greyText}`;
  }

  // Logic for the 'Days' widget
  // status can be one of 'open', 'critical', 'overdue'
  // If there is no status then the widget is "Unknown"
  if (title === 'Days') {
    if (status) {
      strokeColour = CIRCLE_STYLES[status];
    }

    if (status === 'open') {
      remainingStyling = styles.innerNumber;
    } else if (status === 'critical') {
      remainingStyling = styles.criticalStyle;
    } else if (status === 'overdue') {
      remainingStyling = styles.redStyle;
    }
  }

  const innerText = () => (
    <div className={styles.innerTextWrapper}>
      <div className={remainingStyling} data-testid="ProgressCircle--Number">
        {remaining}
      </div>
      <div className={styles.innerName} data-testid="ProgressCircle--Name">
        {remaining === '<1' || remaining === 1 ? title.substring(0, title.length - 1) : title}
      </div>
    </div>
  );

  const showWarningDot = () => {
    let showDot = false;
    if (title === 'Days' && widget.tolerance) {
      if (itemRemaining - widget.tolerance * 30 <= 0) showDot = true;
    } else if (widget.tolerance && itemRemaining - widget.tolerance <= 0) {
      showDot = true;
    }
    return showDot;
  };

  const openDefectDrawer = (id) => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({ payload: { content: <DefectDrawer defectId={id} acId={aircraftId} /> } }));
  };

  let widgetWidth = 140;
  if (window.innerWidth < 1250 && widgetNumber === 4) widgetWidth = 120;

  return (
    <>
      {widget?.isDefect ? (
        <button type="button" className={styles.buttonLink} onClick={() => openDefectDrawer(widget.itemId)}>
          <Progress
            type="circle"
            percent={percentage}
            strokeWidth={5}
            strokeColor={strokeColour}
            strokeLinecap="square"
            width={widgetWidth}
            className={styles.progressBar}
            trailColor="#e6e8ed"
            format={() => innerText()}
            data-testid="ProgressCircle--ProgressCircle"
          />
          <div className={styles.total}>
            <div className={styles.label}>{title === 'Days' ? 'Today' : 'Total'}</div>
            <div className={remaining === 'Unknown' ? styles.greyValue : styles.value}>{total}</div>
          </div>
          <div className={styles.nextDue}>
            <div className={styles.label}>Due</div>
            <div className={remaining === 'Unknown' ? styles.greyValue : styles.value}>
              {showWarningDot() ? <div className={styles.warningCircle} /> : null}
              {nextDue === null ? '-' : nextDue}
            </div>
          </div>
          {tolerance ? <div className={styles.includingTolerance}>{`Inc. ${tolerance} ${unit} tolerance`}</div> : null}
        </button>
      ) : (
        <Link
          to={{
            pathname: widget.isDefect ? openDefectDrawer(widget.itemId) : `/aircraft/${aircraftId}/maintenance`,
            state: { id: widget.itemId, type: widget.title },
          }}
        >
          <Progress
            type="circle"
            percent={percentage}
            strokeWidth={5}
            strokeColor={strokeColour}
            strokeLinecap="square"
            width={widgetWidth}
            className={styles.progressBar}
            trailColor="#e6e8ed"
            format={() => innerText()}
            data-testid="ProgressCircle--ProgressCircle"
          />
          <div className={styles.total}>
            <div className={styles.label}>{title === 'Days' ? 'Today' : 'Total'}</div>
            <div className={remaining === 'Unknown' ? styles.greyValue : styles.value}>{total}</div>
          </div>
          <div className={styles.nextDue}>
            <div className={styles.label}>Due</div>
            <div className={remaining === 'Unknown' ? styles.greyValue : styles.value}>
              {showWarningDot() ? <div className={styles.warningCircle} /> : null}
              {nextDue === null ? '-' : nextDue}
            </div>
          </div>
          {tolerance ? <div className={styles.includingTolerance}>{`Inc. ${tolerance} ${unit} tolerance`}</div> : null}
        </Link>
      )}
    </>
  );
};

ProgressCircle.propTypes = {
  widget: PropTypes.object,
  widgetNumber: PropTypes.number,
  aircraftId: PropTypes.string.isRequired,
};

ProgressCircle.defaultProps = {
  widget: {
    title: '',
    total: 0,
    nextDue: 0,
  },
  widgetNumber: 3,
};

export default ProgressCircle;
