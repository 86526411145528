import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import tooltipIcon from '../../assets/icon-tooltip.svg';
import Portal from '../TFPortal/TFPortal';

interface TooltipProps {
  text: string;
  showLeft?: boolean;
  noMargin?: boolean;
  background?: string;
}

const Wrapper = styled.div`
  ${({ background }): string => {
    if (background) {
      const styles = `
        background-color: ${background};
        z-index: 2;
        padding-right: 8px;
      `;
      return styles;
    }
    return null;
  }}
`;

const StyledImg = styled.img`
  margin-left: ${({ noMargin }): string => (noMargin ? '0' : '8px')};
`;

const TooltipWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  color: #fff;
  background-color: #242d41;
  max-width: 160px;
  z-index: 800;
`;

const InvisibleBackground = styled.button`
  top: 0;
  left: 0;
  position: absolute;
  border: none;
  padding: 0;
  background: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 800;
`;

const ToolTip: React.FC<TooltipProps> = ({ text, showLeft = false, noMargin, background }) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [tooltipStyling, setTooltipStyling] = useState(null);
  const [backgroundStyle, setBackgroundStyle] = useState(null);

  const iconRef = useRef(null);

  const handleMouseOver = (): void => {
    setShowToolTip(true);
    const rect = iconRef?.current?.getBoundingClientRect();
    const { top, left } = rect;
    let newShowLeft = showLeft;
    if (window.innerWidth / 2 < left) newShowLeft = true;
    if (newShowLeft) {
      setTooltipStyling({
        position: 'absolute',
        top: window.innerWidth > 821 ? top + window.scrollY : top,
        left: window.innerWidth > 821 ? left + window.scrollX - 180 : left + window.scrollX - window.innerWidth / 2,
      });
    } else {
      setTooltipStyling({
        position: 'absolute',
        top: window.innerWidth > 821 ? top + window.scrollY : top,
        left: left + window.scrollX + 16,
      });
    }
    if (window.innerWidth < 821) setBackgroundStyle({ top: 0 + window.scrollY });
  };

  const handleMouseLeave = (): void => {
    setShowToolTip(false);
    setTooltipStyling(null);
  };

  return (
    <Wrapper background={background}>
      <StyledImg
        src={tooltipIcon}
        alt="tooltip"
        ref={iconRef}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseLeave={window.innerWidth > 821 ? handleMouseLeave : null}
        onBlur={window.innerWidth > 821 ? handleMouseLeave : null}
        onClick={(): void => handleMouseOver()}
        noMargin={noMargin}
      />
      {showToolTip ? (
        <Portal>
          {window.innerWidth > 821 ? (
            <TooltipWrapper onMouseLeave={handleMouseLeave} style={tooltipStyling}>
              {text}
            </TooltipWrapper>
          ) : (
            <InvisibleBackground onClick={handleMouseLeave} style={backgroundStyle}>
              <TooltipWrapper style={tooltipStyling}>{text}</TooltipWrapper>
            </InvisibleBackground>
          )}
        </Portal>
      ) : null}
    </Wrapper>
  );
};

export default ToolTip;
