import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button, Loading } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import UndoIcon from '../../assets/icon-editor-undo.svg';
import AIIcon from '../../assets/icon-card-ai.svg';
import { sendCustomFieldMessageToChatbot } from '../../services/api';
import TFTextBoxInput from '../../components/TFTextBoxInput/TFTextBoxInput';

interface CustomFieldChatbotProps {
  updateCustomFieldsData: (changes: { value: any; key: string }[]) => void;
  setCustomFieldReady: (value: boolean) => void;
  customFieldReady: boolean;
}

export interface BotMessageResponse {
  db_obj: any;
  messages: BotMessage[];
}

export interface BotMessage {
  content: string;
  role: string;
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  color: #242d41;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  margin-left: 20px;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${({ role }): string => (role === 'user' ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  padding: 10px 20px;
  background-color: ${({ theme, role }): string => (role === 'user' ? theme.colours.blue : '#f5f5f5')};
  color: ${({ role }): string => (role === 'user' ? 'white' : 'black')};
  border-radius: 20px;
`;

const MessageOuterWrapper = styled.div`
  padding: 10px;
  border: 1px solid rgba(36, 45, 65, 0.1);
  border-radius: 2px;
  min-height: 90px;
`;

const StartWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: ${({ displayBorder }): string => (displayBorder ? '1px solid rgba(36, 45, 65, 0.1)' : 'none')};
  padding: 16px 0 6px;
`;

const StyledImg = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 10px;
  margin-bottom: 3px;
  &:hover {
    cursor: pointer;
  }
`;

const MessageDiv = styled.div`
  min-height: 50px;
`;

const StyledAIIcon = styled.img`
  width: 30px;
  margin-right: 10px;
`;

const PrePopulatedQuestionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin: 20px 0 0;
`;

const StyledButton = styled.button`
  background: transparent;
  border-radius: 16px;
  border: 1px solid rgba(36, 45, 65, 0.1);
  padding: 8px;
  width: 100%;
  cursor: pointer;
  color: rgb(18, 111, 214);
  font-size: 14px;
  font-weight: 500;
  &:hover {
    border-color: rgb(18, 111, 214);
  }
  &:focus {
    outline: none;
  }
`;

const CustomFieldChatBot: React.FC<CustomFieldChatbotProps> = ({
  updateCustomFieldsData,
  setCustomFieldReady,
  customFieldReady,
}) => {
  const [messages, setMessages] = useState<BotMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [preQuestionClicked, setPreQuestionClicked] = useState(false);

  const inputRef = useRef(null);

  const { formatMessage } = useIntl();

  const handleMessageInput = (e): void => {
    setCurrentMessage(e.target.value);
    if (customFieldReady) setCustomFieldReady(false);
  };

  const handleSendMessage = async (): Promise<void> => {
    const params = {
      conversation_id: conversationId,
      message: currentMessage,
    };
    setLoading(true);
    setMessages([...messages, { content: currentMessage, role: 'user' }, { content: null, role: 'loading' }]);
    setCurrentMessage('');
    const response = new Promise((resolve, reject) => {
      try {
        resolve(sendCustomFieldMessageToChatbot(params));
      } catch (err) {
        reject(err);
        setLoading(false);
      }
    });
    response.then((res: any) => {
      setMessages(res.messages);
      setLoading(false);
      if (res?.db_obj) {
        const changes = Object.entries(res?.db_obj).map(([key, value]) => {
          return { value, key };
        });
        updateCustomFieldsData(changes);
      }
      inputRef.current.focus();
      setPreQuestionClicked(false);
    });
  };

  const handleStartNewConversation = (): void => {
    setMessages([]);
    setCurrentMessage('');
    setConversationId(null);
    setPreQuestionClicked(false);
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleQuestionClick = (type: string): void => {
    let newQuestion = formatMessage({ id: 'text.customFieldPrompt1' });
    if (type === 'pre') newQuestion = formatMessage({ id: 'text.customFieldPrompt2' });
    if (type === 'post') newQuestion = formatMessage({ id: 'text.customFieldPrompt3' });
    setCurrentMessage(newQuestion);
    setPreQuestionClicked(true);
  };

  const formatCompletedObject = (string: any): any => {
    const jsonString = string.replace('Completed.', '');
    // Parse the string into a JSON object
    const jsonObject = JSON.parse(jsonString.replace(/'/g, '"').replace(/False/g, 'false').replace(/True/g, 'true'));
    // Format the JSON object for better readability
    const formattedJsonString = JSON.stringify(jsonObject, null, 2);
    return formattedJsonString;
  };

  const formatMessages = (): ReactElement[] => {
    return messages.map((message, i) => {
      if (message.content === null && message.role === 'loading') {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <MessageWrapper key={`${message.role}${i}`} role={message.role}>
            <MessageBubble>
              <Loading size={40} loading={loading} contain hasTransparentBackground />
            </MessageBubble>
          </MessageWrapper>
        );
      }
      if (message.content.startsWith('Completed')) {
        setCustomFieldReady(true);
        const formattedJsonString = formatCompletedObject(message.content);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <MessageWrapper key={`${message.role}${i}`}>
            {`${message.role}: Completed.`}
            <pre>{formattedJsonString}</pre>
            <span>
              If you are happy with the above custom field, hit the preview button to see it filled in on the form
            </span>
          </MessageWrapper>
        );
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <MessageWrapper key={`${message.role}${i}`} role={message.role}>
          <MessageBubble role={message.role}>{`${message.content}`}</MessageBubble>
        </MessageWrapper>
      );
    });
  };

  useEffect(() => {
    if (preQuestionClicked) {
      handleSendMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preQuestionClicked]);

  useEffect(() => {
    if (!conversationId) {
      const newConversationId = uuidv4();
      setConversationId(newConversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div>
      <TitleWrapper>
        <StyledAIIcon src={AIIcon} alt="ai icon" />
        {formatMessage({ id: 'text.whatCustomFieldCreateToday' })}
      </TitleWrapper>
      <MessageOuterWrapper>
        <MessageDiv>{formatMessages()}</MessageDiv>
        {messages.length > 0 && (
          <StartWrapper displayBorder={messages.length === 0}>
            <Button height="30px" primary={false} onClick={handleStartNewConversation}>
              <StyledImg src={UndoIcon} alt="undo icon" />
              {formatMessage({ id: 'text.startOver' })}
            </Button>
          </StartWrapper>
        )}
      </MessageOuterWrapper>
      {messages.length === 0 && !preQuestionClicked ? (
        <PrePopulatedQuestionsWrapper>
          <StyledButton onClick={(): void => handleQuestionClick('none')}>
            {formatMessage({ id: 'text.customFieldPrompt1' })}
          </StyledButton>
          <StyledButton onClick={(): void => handleQuestionClick('pre')}>
            {formatMessage({ id: 'text.customFieldPrompt2' })}
          </StyledButton>
          <StyledButton onClick={(): void => handleQuestionClick('post')}>
            {formatMessage({ id: 'text.customFieldPrompt3' })}
          </StyledButton>
        </PrePopulatedQuestionsWrapper>
      ) : null}
      <InputWrapper>
        <TFTextBoxInput
          label=""
          id="chatbot-input"
          onChange={handleMessageInput}
          onKeyPress={handleKeyPress}
          value={currentMessage}
          ref={inputRef}
          disabled={loading}
        />
        <ButtonWrapper>
          <Button height="30px" disabled={loading} onClick={(): Promise<void> => handleSendMessage()}>
            {formatMessage({ id: 'text.send' })}
          </Button>
        </ButtonWrapper>
      </InputWrapper>
    </div>
  );
};

export default CustomFieldChatBot;
