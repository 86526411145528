import React from 'react';
import PropTypes from 'prop-types';
import logoDark from '../assets/logoDark.svg';
import logoTextDark from '../assets/logoTextDark.svg';
import styles from './notValidBrowser.module.less';

const NotValidBrowser = ({ browserName }) => (
  <div className={styles.notValidBrowser}>
    <div>
      <div className={styles.logo} id="logo" data-test="logo">
        <a href="https://trustflight.io" data-test="linkWebsite">
          <img src={logoDark} alt="logo" />
          <img id={styles.logoText} src={logoTextDark} alt="logo text" />
        </a>
      </div>
      <p data-test="notSupported">Your browser ({browserName}) is not supported</p>
      <p data-test="useFollowing">Please use one of the following:</p>
      <ul className={styles.browserList} data-test="browserList">
        {' '}
        <li>Microsoft Edge</li>
        <li>Google Chrome</li>
        <li>Mozilla Firefox</li>
        <li>Apple Safari</li>
      </ul>
      <p className={styles.contact} data-test="contactText">
        Get in touch with us at
      </p>
      <p>
        <a href="mailto:info@trustflight.io" data-test="contactEmail">
          info@trustflight.io
        </a>
      </p>
    </div>
  </div>
);

NotValidBrowser.propTypes = {
  browserName: PropTypes.string.isRequired,
};

export default NotValidBrowser;
