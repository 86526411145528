import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Progress } from 'antd';
import styles from './MXDraftQueue.module.less';

const DraftQueueSummaryProgress = ({ totalItems, itemsCount, title, strokeColor }) => {
  const getPercentage = () => {
    return totalItems ? (itemsCount / totalItems) * 100 : 0;
  };
  return (
    <>
      <Col
        className={`${styles.progressWrapper} ${itemsCount === 0 ? styles.progressWrapperDisabled : undefined}`}
        data-test="progressWrapper"
      >
        <Row gutter={8} className={styles.progressContent}>
          <Col>
            <Progress
              type="circle"
              percent={getPercentage()}
              width={50}
              strokeWidth={8}
              showInfo={false}
              strokeColor={strokeColor}
              strokeLinecap="square"
              className={styles.progressBar}
              data-test="progressCircle"
            />
          </Col>
          <Col className={styles.progressDescription}>
            <div className={styles.title} data-test="title">
              {title || '-'}
            </div>
            <div className={styles.count} data-test="count">
              {itemsCount || 0}
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

DraftQueueSummaryProgress.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  strokeColor: PropTypes.object.isRequired,
};

export default DraftQueueSummaryProgress;
