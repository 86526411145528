import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { AircraftResource } from '../../models/aircraft';
import { changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { deleteUpdate, getAircraftTripEntries } from '../../models/trips/actions';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';

interface TripUpdateHeaderProps {
  trip: any;
}

const SectionWrapper = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  height: 32px;
  align-items: center;
  @media (max-width: 450px) {
    flex-wrap: wrap;
    height: auto;
    max-width: 96%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  span {
    margin-right: 4px;
  }
`;

const BoldText = styled.div`
  font-weight: 500;
`;

const TripUpdateHeader: React.FC<TripUpdateHeaderProps> = ({ trip }) => {
  const {
    drawer: { mode },
    userSettings: {
      dateFormat,
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleDeleteClick = (): void => {
    dispatch(deleteUpdate({ payload: { id: trip.id } }, () => null));
    dispatch(getAircraftTripEntries({ payload: { id } }));
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleOverrideClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'override' }));
  };

  return (
    <SectionWrapper data-testid="TripUpdateHeader-SectionWrapper">
      <TitleWrapper data-testid="TripUpdateHeader-Title">
        <span>{`${operatorSettings?.trip_update}:`}</span>
        <BoldText>{`#${trip?.number || '-'}`}</BoldText>
      </TitleWrapper>
      <BoldText data-testid="TripUpdateHeader-Date">
        {trip?.date ? moment.utc(trip?.date).format(dateFormat) : '-'}
      </BoldText>
      <div>
        {mode !== 'view' ? null : (
          <AuthDropdownMenu
            options={{
              read: false,
              update: true,
              delete: true,
              custom: true,
            }}
            menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
            resource={AircraftResource.TRIP}
            aircraftId={id}
            editCallback={(): void => handleEditClick()}
            handleDelete={(): void => handleDeleteClick()}
            customText="Override"
            customCallback={(): void => handleOverrideClick()}
          />
        )}
      </div>
    </SectionWrapper>
  );
};

export default TripUpdateHeader;
