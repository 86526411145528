import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import getOperatorSetting from '../../utils/getOperatorSetting';
import { update } from '../../models/aircraft/actions';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalContent,
  changeModalVisibility,
  setDrawerId,
} from '../../models/drawer';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource, CustomField } from '../../models/aircraft';
import { DashboardState } from '../../models';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import updateLocalDataObject from '../../utils/updateLocalDataObject';
import Card from '../../components/DefectDrawer/components/Card';
import isFeatureFlagActive from '../../utils/isFeatureFlagActive';
import AIIconPurple from '../../assets/icon-ai.svg';
import infoIcon from '../../assets/icon-info-new.svg';
import UndoIcon from '../../assets/icon-editor-undo.svg';
import mainDetailsIcon from '../../assets/icon-card-custom-fields-main.svg';
import positioningIcon from '../../assets/icon-card-custom-fields-positioning.svg';
import formattingIcon from '../../assets/icon-card-custom-fields-formatting.svg';
import additionalIcon from '../../assets/icon-card-custom-fields-extras.svg';
import CustomFieldsSection from './CustomFieldsSection';
import CustomFieldsEditMainDetails from './CustomFieldsEditMainDetails';
import CustomFieldsEditPositioning from './CustomFieldsEditPositioning';
import CustomFieldsEditFormatting from './CustomFieldsEditFormatting';
import CustomFieldsEditAdditionalValues from './CustomFieldsEditAdditionalValues';
import CustomFieldsViewMainDetails from './CustomFieldsViewMainDetails';
import CustomFieldsViewPositioning from './CustomFieldsViewPositioning';
import CustomFieldsViewFormatting from './CustomFieldsViewFormatting';
import CustomFieldsViewAdditionalValues from './CustomFieldsViewAdditionalValues';
import CustomFieldChatBot from './CustomFieldChatBot';

interface CustomFieldDrawerProps {
  useAi?: boolean;
}

const Wrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const DrawerTitle = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }): string => theme.colours.black10Alpha};
  margin: 20px 0;
`;

const ButtonSection = styled.div`
  margin-top: 20px;
  display: flex;
`;

const AddNewWrapper = styled.div`
  margin: 0 16px;
`;

const StyledIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 10px;
  margin-bottom: 3px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonSpan = styled.span`
  color: ${({ purple, theme }): string => (purple ? `${theme.colours.purple}` : `${theme.colours.blue}`)};
`;

const ToolTipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  span {
    color: rgba(36, 45, 65, 0.4);
    margin-left: 8px;
  }
`;

const PreviewWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PreviewButton = styled.div`
  margin-right: 16px;
`;

const CustomFieldDrawer: React.FC<CustomFieldDrawerProps> = ({ useAi = false }) => {
  const {
    drawer: { mode, drawerId },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [customFieldsData, setCustomFieldsData] = useState({} as CustomField);
  const [validationErrors, setValidationErrors] = useState([]);
  const [fieldDisplayArray, setFieldDisplayArray] = useState([]);
  const [keyGenerated, setKeyGenerated] = useState(false);
  const [usingChatBot, setUsingChatBot] = useState(false);
  const [customFieldReady, setCustomFieldReady] = useState(false);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const chatBotFlagEnabled = isFeatureFlagActive('custom-field-chatbot', 'operator', aircraftMap.get(id)?.operator_id);
  const sectorNameOverride = getOperatorSetting('sector_name', id) || formatMessage({ id: 'sector' });

  const updateCustomFieldsData = (changes: { value: any; key: string }[]): void => {
    const newData = _.cloneDeep(customFieldsData || {});
    const updatedData = updateLocalDataObject(changes, newData);
    setCustomFieldsData(updatedData);
    let newErrors = validationErrors;
    for (let i = 0; i < changes.length; i += 1) {
      if (validationErrors?.indexOf(changes[i].key) !== -1) {
        newErrors = validationErrors.filter((error) => error !== changes[i].key);
      }
    }
    setValidationErrors(newErrors);
  };

  const formatCustomFields = (): any => {
    const currentCustomFields = aircraftMap.get(id)?.custom_fields || {};
    if (!customFieldsData?.required) {
      customFieldsData.required = false;
    }
    if (customFieldsData) {
      currentCustomFields[customFieldsData.key] = customFieldsData;
    }
    return currentCustomFields;
  };

  const handleCancleClick = (): void => {
    if (mode === 'add') {
      dispatch(changeDrawerVisibility({ payload: false }));
      setValidationErrors([]);
      setKeyGenerated(false);
    } else {
      dispatch(changeDrawerMode({ payload: 'view' }));
      const existingData = aircraftMap.get(id)?.custom_fields || {};
      setCustomFieldsData(existingData[drawerId]);
      setValidationErrors([]);
      setKeyGenerated(false);
    }
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleDeleteClick = (): void => {
    const formattedCustomFields = formatCustomFields();
    delete formattedCustomFields[drawerId];
    const payload = {
      aircraftId: id,
      aircraft: {
        custom_fields: formattedCustomFields,
      },
    };
    dispatch(update({ payload }));
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const isKeyUnique = (key: string): boolean => {
    let isUnique = true;
    const allCustomFields = aircraftMap.get(id)?.custom_fields || {};
    Object.keys(allCustomFields)
      .filter((k) => k !== drawerId)
      .forEach((customFieldKey) => {
        if (key?.trim() === customFieldKey?.trim()) {
          isUnique = false;
        }
      });
    return isUnique;
  };

  const validateCustomFields = (): boolean => {
    let valid = true;
    const newErrors = _.cloneDeep(validationErrors);
    if (!customFieldsData) {
      dispatch(
        addToast({
          payload: {
            title: 'Error',
            message: 'Custom field not found',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      return false;
    }
    if (!customFieldsData.title) {
      newErrors.push('title');
      valid = false;
    }
    if (!customFieldsData.key) {
      newErrors.push('key');
      valid = false;
    } else if (!isKeyUnique(customFieldsData.key)) {
      newErrors.push('key');
      valid = false;
    }
    if (!customFieldsData.view) {
      newErrors.push('view');
      valid = false;
    }
    if (!customFieldsData.type) {
      newErrors.push('type');
      valid = false;
    }
    if (customFieldsData.type === 'selector' && !customFieldsData?.options) {
      newErrors.push('options');
      valid = false;
    }
    setValidationErrors(newErrors);
    return valid;
  };

  const handleSaveClick = (): void => {
    if (validateCustomFields()) {
      const payload = {
        aircraftId: id,
        aircraft: {
          custom_fields: formatCustomFields(),
        },
      };
      dispatch(update({ payload }));
      dispatch(changeDrawerMode({ payload: 'view' }));
      dispatch(setDrawerId({ payload: customFieldsData?.key }));
      setValidationErrors([]);
      setKeyGenerated(false);
    }
  };

  const handleSaveAddNewClick = (): void => {
    handleSaveClick();
    dispatch(changeDrawerMode({ payload: 'add' }));
    dispatch(setDrawerId({ payload: null }));
    setCustomFieldsData({ title: '', key: '', heading: '' } as CustomField);
    setValidationErrors([]);
    setKeyGenerated(false);
  };

  const handleChatBotToggle = (): void => {
    dispatch(changeModalVisibility({ payload: true }));
    const payload = {
      title: usingChatBot ? 'Disabling AI chat bot' : 'Switching to AI chat bot',
      text: `${
        usingChatBot ? 'Disabling' : 'Switching to'
      } AI chat bot will reset your current changes. Do you wish to continue?`,
      saveAction: (): void => {
        setUsingChatBot(!usingChatBot);
        dispatch(changeModalVisibility({ payload: false }));
      },
      cancelAction: (): ((dispatch: (args: any) => any) => void) => dispatch(changeModalVisibility({ payload: false })),
      saveButtonText: `${usingChatBot ? 'Disable' : 'Switch to'} AI chat bot`,
    };
    dispatch(
      changeModalContent({
        payload,
      }),
    );
    setCustomFieldsData({} as CustomField);
  };

  const handlePreviewDataClick = (): void => {
    setUsingChatBot(false);
  };

  useEffect(() => {
    if (useAi) {
      setUsingChatBot(true);
    }
  }, [useAi]);

  useEffect(() => {
    if (drawerId) {
      const foundAircraft = aircraftMap.get(id);
      if (foundAircraft) {
        const customFields = foundAircraft.custom_fields;
        const foundCustomField = customFields[drawerId];
        if (foundCustomField) {
          setCustomFieldsData(foundCustomField);
        }
      }
    }
  }, [aircraftMap, drawerId, id]);

  useEffect(() => {
    if (customFieldsData?.type) {
      switch (customFieldsData.type) {
        case 'number':
          setFieldDisplayArray(['min', 'max', 'default', 'precision', 'display_previous_value']);
          break;
        case 'counter':
          setFieldDisplayArray(['min', 'max', 'default', 'precision']);
          break;
        case 'string':
          setFieldDisplayArray(['min', 'max', 'default', 'display_previous_value']);
          break;
        case 'text':
          setFieldDisplayArray(['min', 'max', 'default', 'display_previous_value']);
          break;
        case 'boolean':
          setFieldDisplayArray(['default']);
          break;
        case 'attachment':
          setFieldDisplayArray([]);
          break;
        case 'date':
          setFieldDisplayArray(['mode']);
          break;
        case 'selector':
          setFieldDisplayArray(['options']);
          break;
        case 'duration':
          setFieldDisplayArray(['interval']);
          break;
        default:
          setFieldDisplayArray([]);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldsData?.type]);

  let drawerTitle = formatMessage({ id: 'text.addNewCustomField' });
  if (mode === 'view') drawerTitle = `Custom field: ${customFieldsData?.title}`;
  if (mode === 'edit') drawerTitle = `Editing custom field: ${customFieldsData?.title}`;

  return (
    <Wrapper>
      <DrawerTitle data-testid="CustomFieldDrawer--Title">
        <span>{drawerTitle}</span>
        {mode === 'view' ? (
          <AuthDropdownMenu
            options={{
              read: false,
              update: true,
              delete: true,
            }}
            menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
            resource={AircraftResource.AIRCRAFT}
            aircraftId={id}
            editCallback={(): void => handleEditClick()}
            handleDelete={(): void => handleDeleteClick()}
          />
        ) : null}
        {chatBotFlagEnabled && mode === 'add' ? (
          <Button height="30px" primary={false} onClick={handleChatBotToggle}>
            <StyledIcon src={usingChatBot ? UndoIcon : AIIconPurple} alt="icon" />
            <ButtonSpan purple={!usingChatBot}>
              {usingChatBot ? 'Disable AI chat bot' : 'Enable AI chat bot'}
            </ButtonSpan>
          </Button>
        ) : null}
      </DrawerTitle>
      {usingChatBot ? (
        <ToolTipWrapper>
          <img src={infoIcon} alt="tooltip icon" />
          <span>{formatMessage({ id: 'text.asWithAllAI' })}</span>
        </ToolTipWrapper>
      ) : null}
      {usingChatBot ? (
        <Card>
          <CustomFieldChatBot
            updateCustomFieldsData={updateCustomFieldsData}
            setCustomFieldReady={setCustomFieldReady}
            customFieldReady={customFieldReady}
          />
        </Card>
      ) : (
        <Card>
          <CustomFieldsSection
            icon={mainDetailsIcon}
            title={formatMessage({ id: 'text.mainDetails' })}
            viewComponent={<CustomFieldsViewMainDetails />}
            editComponent={
              <CustomFieldsEditMainDetails
                customFieldsData={customFieldsData}
                updateCustomFieldsData={updateCustomFieldsData}
                validationErrors={validationErrors}
                keyGenerated={keyGenerated}
                setKeyGenerated={setKeyGenerated}
              />
            }
          />
          {customFieldsData?.type ? (
            <>
              <Divider />
              <CustomFieldsSection
                icon={positioningIcon}
                title={formatMessage({ id: 'text.positioning' })}
                viewComponent={<CustomFieldsViewPositioning fieldDisplayArray={fieldDisplayArray} />}
                editComponent={
                  <CustomFieldsEditPositioning
                    customFieldsData={customFieldsData}
                    updateCustomFieldsData={updateCustomFieldsData}
                    fieldDisplayArray={fieldDisplayArray}
                  />
                }
              />
            </>
          ) : null}
          {customFieldsData?.type &&
          (fieldDisplayArray?.includes('precision') ||
            fieldDisplayArray?.includes('options') ||
            fieldDisplayArray?.includes('min') ||
            fieldDisplayArray?.includes('max')) ? (
            <>
              <Divider />
              <CustomFieldsSection
                icon={formattingIcon}
                title={formatMessage({ id: 'text.formatting' })}
                viewComponent={<CustomFieldsViewFormatting fieldDisplayArray={fieldDisplayArray} />}
                editComponent={
                  <CustomFieldsEditFormatting
                    customFieldsData={customFieldsData}
                    updateCustomFieldsData={updateCustomFieldsData}
                    fieldDisplayArray={fieldDisplayArray}
                    validationErrors={validationErrors}
                  />
                }
              />
            </>
          ) : null}
          {customFieldsData?.type ? (
            <>
              <Divider />
              <CustomFieldsSection
                icon={additionalIcon}
                title={formatMessage({ id: 'text.additionalValues' })}
                viewComponent={
                  <CustomFieldsViewAdditionalValues
                    fieldDisplayArray={fieldDisplayArray}
                    sectorNameOverride={sectorNameOverride}
                  />
                }
                editComponent={
                  <CustomFieldsEditAdditionalValues
                    customFieldsData={customFieldsData}
                    updateCustomFieldsData={updateCustomFieldsData}
                    fieldDisplayArray={fieldDisplayArray}
                    sectorNameOverride={sectorNameOverride}
                  />
                }
              />
            </>
          ) : null}
        </Card>
      )}
      {!usingChatBot && mode !== 'view' ? (
        <ButtonSection>
          <Button height="32px" onClick={handleSaveClick}>
            {formatMessage({ id: 'text.save' })}
          </Button>
          <AddNewWrapper>
            <Button height="32px" onClick={handleSaveAddNewClick}>
              {formatMessage({ id: 'text.save&AddNewField' })}
            </Button>
          </AddNewWrapper>
          <Button height="32px" onClick={handleCancleClick} primary={false}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </ButtonSection>
      ) : null}
      {usingChatBot ? (
        <PreviewWrapper>
          <PreviewButton>
            <Button height="30px" disabled={!customFieldReady} onClick={(): void => handlePreviewDataClick()}>
              {formatMessage({ id: 'text.preview' })}
            </Button>
          </PreviewButton>
          <Button height="30px" primary={false} onClick={(): void => handleCancleClick()}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </PreviewWrapper>
      ) : null}
    </Wrapper>
  );
};

export default CustomFieldDrawer;
