import { Defect } from '../defects';
import { BaseModel } from '..';

export const initialState: AircraftState = {
  aircraftMap: new Map(),
  fleetAircraftMap: new Map(),
  newAircraftId: null,
  lastFetched: 0,
  ttl: 30000,
  data: {
    list: [],
  },
  dirty: false,
};

export enum AircraftActionTypes {
  SAVE = 'aircraft/save',
  SAVE_ALL = 'aircraft/saveAll',
  SAVE_NEW = 'aircraft/saveNew',
  SAVE_STATUS = 'aircraft/saveStatus',
  REMOVE = 'aircraft/removePerson',
  ADD_PEOPLE = 'aircraft/adPeople',
  SAVE_FLEET = 'aircraft/saveFleet',
}

export enum AircraftResource {
  ADDITIONAL_CREW = 'AdditionalCrew',
  AIRCRAFT = 'Aircraft',
  DAMAGE_MAP = 'DamageMap',
  DEFECT = 'Defect',
  DOCUMENT = 'Document',
  FLIGHT = 'Flight',
  LOGBOOK = 'Logbook',
  RELEASE = 'Release',
  SCHEDULED_MX_ITEM = 'ScheduledMxItem',
  TRIP = 'Trip',
  WORKPACK = 'Workpack',
  INTERMITTENT_FAULT = 'IntermittentFault',
}

export enum AircraftPermission {
  CREATE = 'to_create',
  READ = 'to_read',
  UPDATE = 'to_update',
  DELETE = 'to_delete',
}
export interface SaveAircraftAction {
  type: AircraftActionTypes.SAVE;
  payload: Aircraft;
}

export interface SaveAllAircraftAction {
  type: AircraftActionTypes.SAVE_ALL;
  payload: Aircraft[];
}

export interface SaveFleetAircraftAction {
  type: AircraftActionTypes.SAVE_FLEET;
  payload: Aircraft[];
}

export interface SaveNewAircraftAction {
  type: AircraftActionTypes.SAVE_NEW;
  payload: Aircraft;
}

export interface SaveStatusAircraftAction {
  type: AircraftActionTypes.SAVE_STATUS;
  payload: {
    status: string;
    id: string;
  };
}

export interface AddPeopleAircraftAction {
  type: AircraftActionTypes.ADD_PEOPLE;
  payload: {
    people: People[];
    id: string;
  };
}

export interface RemoveAircraftAction {
  type: AircraftActionTypes.REMOVE;
  payload: Aircraft;
}

type AircraftAction =
  | SaveAircraftAction
  | SaveAllAircraftAction
  | SaveFleetAircraftAction
  | SaveNewAircraftAction
  | SaveStatusAircraftAction
  | AddPeopleAircraftAction
  | RemoveAircraftAction;

export const isCompleteAircraftRecord = (record: BaseAircraft | Aircraft): record is Aircraft =>
  record &&
  'maintenance' in record &&
  'departure_airport' in record &&
  'destination_airport' in record &&
  'last_airport' in record &&
  'totals' in record &&
  'tiles' in record;

export default function (state = initialState, action: AircraftAction): AircraftState {
  switch (action.type) {
    case AircraftActionTypes.SAVE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      const stateAircraft = aircraftMap.get(action.payload.id);
      aircraftMap.set(action.payload.id, {
        ...stateAircraft,
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftMap,
      };
    }
    case AircraftActionTypes.SAVE_STATUS: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      const stateAircraft = aircraftMap.get(action.payload.id);
      aircraftMap.set(action.payload.id, {
        ...stateAircraft,
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftMap,
        dirty: true,
      };
    }
    case AircraftActionTypes.SAVE_NEW: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      const newAircraftId = action.payload.id;
      aircraftMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        newAircraftId,
        aircraftMap,
      };
    }
    case AircraftActionTypes.SAVE_ALL: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      action.payload.forEach((aircraft: Aircraft | BaseAircraft) => {
        const stateAircraft = aircraftMap.get(aircraft.id);
        const lastFetched = isCompleteAircraftRecord(aircraft) ? Date.now() : stateAircraft?.lastFetched || 0;
        aircraftMap.set(aircraft.id, {
          ...stateAircraft,
          ...aircraft,
          lastFetched,
        });
      });
      return {
        ...state,
        aircraftMap,
        lastFetched: Date.now(),
        dirty: false,
      };
    }
    case AircraftActionTypes.SAVE_FLEET: {
      const fleetMap = new Map(state.fleetAircraftMap.entries());
      action.payload.forEach((aircraft: Aircraft | BaseAircraft) => {
        fleetMap.set(aircraft.id, aircraft);
      });
      return {
        ...state,
        fleetAircraftMap: fleetMap,
        aircraftMap: fleetMap,
        lastFetched: Date.now(),
        dirty: false,
      };
    }
    case AircraftActionTypes.ADD_PEOPLE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      const stateAircraft = aircraftMap.get(action.payload.id);
      aircraftMap.set(action.payload.id, {
        ...stateAircraft,
        aircraft_people: action.payload.people,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftMap,
      };
    }
    case AircraftActionTypes.REMOVE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      aircraftMap.delete(action.payload.id);
      return {
        ...state,
        aircraftMap,
      };
    }
    default:
      return state;
  }
}

export interface AircraftState {
  aircraftMap: Map<string, Aircraft | BaseAircraft>;
  fleetAircraftMap: Map<string, Aircraft | BaseAircraft>;
  newAircraftId?: string;
  lastFetched: number;
  ttl: number;
  data: {
    list: Aircraft[];
  };
  dirty: boolean;
}

export interface AircraftType {
  id: string;
  manufacturer: string;
  model: string;
  designator: string;
  category: string;
  engine_count: number;
  engine_type: string;
  weight_category: string;
  aircraft_image_url: string;
}

export interface SimpleAircraft {
  id: string;
  clickable: boolean;
  fleet_number: string;
  locked: boolean;
  registration: string;
}

export interface BaseAircraft extends BaseModel {
  id: string;
  serial_number: string;
  registration: string;
  year: number;
  simulator: false;
  apu_installed: false;
  apu_mode: string;
  operator_id: string;
  max_fuel_value: number;
  fuel_unit: string;
  oil_unit: string;
  maintenance_status: string;
  flight_status: string;
  feature_flags: FeatureFlag[];
  mx_display_days: null;
  multipilot_rated: boolean;
  mx_thresholds: any;
  aircraft_type: AircraftType;
  camp_integration_enabled: boolean;
  camp_last_synced: string;
  standard_fields?: any;
  app_settings: any;
  app_permissions: any;
  fleet_number?: string;
  srp_template_id?: string;
  jl_template_id?: string;
  login?: boolean;
  billing_status?: string;
  locked?: boolean;
  custom_fields?: any;
  max_weight?: number;
  flight_risk_assessment_id?: string;
  hydraulic_circuits?: {
    hydraulic_circuit_id: string;
    hydraulic_circuit_name: string;
  }[];
  active_aircraft_drawing?: {
    id: string;
    name: string;
    version: number;
  };
  issue_types?: IssueType[];
}

export const SUPPORTED_CUSTOM_FIELDS = ['boolean', 'selector', 'number', 'string', 'text'];

export interface CustomField {
  heading: string;
  title: string;
  type: CustomFieldTypes;
  view: ViewTypes;
  key: string;
  order?: number;
  insert_after?: string;
  options?: string[];
  process_order?: number;
  precision?: number;
  min?: number | string;
  max?: number | string;
  unit?: string;
  show_only?: string[];
  default?: string;
  mode?: string;
  interval?: number;
  display_previous_value?: boolean;
  required?: boolean;
}

enum ViewTypes {
  PREFLIGHT = 'preflight',
  POSTFLIGHT = 'postflight',
  MXCHECK = 'mx_check',
  TRIP = 'trip',
}

enum CustomFieldTypes {
  NUMBER = 'number',
  TEXT = 'text',
  COUNTER = 'counter',
  STRING = 'string',
  BOOLEAN = 'boolean',
  ATTACHMENT = 'attachment',
  DATE = 'date',
  SELECTOR = 'selector',
  DURATION = 'duration',
}

interface FeatureFlag {
  id: string;
  feature_name: string;
}

interface FeatureFlag {
  id: string;
  feature_name: string;
}

export interface AppPermission {
  trip: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
  preflight: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
  postflight: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
  mx_check?: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
  crs?: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
  issue?: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
  uplift?: {
    can_sign: string | string[];
    can_sign_scope_type: string;
  };
}

export interface Aircraft extends BaseAircraft {
  last_airport: string;
  departure_airport: {
    ident: string;
    latitude: string;
    longitude: string;
  };
  destination_airport: {
    ident: string;
    latitude: string;
    longitude: string;
  };
  camp_integration_enabled: boolean;
  days_remaining: number;
  flight_seconds_remaining: number;
  apu_seconds_remaining: number;
  cycles_remaining: number;
  open_defects: Defect[];
  limitations: string[];
  estimated_next_due_date: string;
  aircraft_people: People[];
  maintenance_status_reason: string;
  missing_crs_workpack_id?: string;
  clickable: boolean;
  favourited: boolean;
}

export interface People {
  first_name: string;
  last_name: string;
  avatar_url: string;
  email: string;
  position: string;
  last_flown: string;
}

export interface IssueType {
  aircraft_id: string;
  id: string;
  name: string;
  status: string;
  type: string;
}

export const defaultWritePermissions = [
  {
    resource_type: AircraftResource.ADDITIONAL_CREW,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DAMAGE_MAP,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DEFECT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DOCUMENT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.FLIGHT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.LOGBOOK,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.RELEASE,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.SCHEDULED_MX_ITEM,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.TRIP,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.WORKPACK,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
];

export const defaultPilotPermissions = [
  {
    resource_type: AircraftResource.ADDITIONAL_CREW,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DAMAGE_MAP,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DEFECT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DOCUMENT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.FLIGHT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.LOGBOOK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.RELEASE,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.SCHEDULED_MX_ITEM,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.TRIP,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.WORKPACK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
];

export const defaultReadPermissions = [
  {
    resource_type: AircraftResource.ADDITIONAL_CREW,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DAMAGE_MAP,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DEFECT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DOCUMENT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.FLIGHT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.LOGBOOK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.RELEASE,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.SCHEDULED_MX_ITEM,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.TRIP,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.WORKPACK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
];
