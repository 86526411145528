import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { DashboardState } from '../models';
import BasicLayout from './BasicLayout';
import styles from './ContentLayout.module.less';

const ContentLayout: React.FC = ({ children }) => {
  const fixedHeader = useSelector((state: DashboardState) => state.userSettings.ui.fixedHeader);

  const getContentStyle = (): ContentStyle => {
    return {
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  return (
    <BasicLayout>
      <Layout style={getContentStyle()} className={styles.contentLayout}>
        {children}
      </Layout>
    </BasicLayout>
  );
};

export default ContentLayout;

export interface ContentStyle {
  paddingTop: number;
}
