export enum ConfigKey {
  /**
   * The API endpoint to be used by Dashboard.
   */
  API = 'api',
  /**
   * Cookie domain to store auth and other cookie data at.
   */
  COOKIEDOMAIN = 'cookieDomain',
  /**
   * Google Maps API key.
   */
  GOOGLEAPIKEY = 'googleApiKey',
  /**
   * Git commit hash to be displayed in version information in the footer of Dashboard.
   */
  HASHVERSION = 'hashVersion',
  /**
   * Public URL to be used for features of Dashboard that are openly accessible to the web.
   */
  PUB = 'pub',
  /**
   * Dashboard authentication client ID.
   */
  UID = 'uid',
  /**
   * Dashboard authentication client "secret" (not actually secret).
   */
  SECRET = 'secret',
  /**
   * URL to the IDP for SSO.
   */
  IDPURL = 'http://hydra_url/',
  /**
   * ORY client ID. Used for AuthService.
   */
  ORYCLIENTID = 'ory_oauth_client_id',
  /**
   * ORY client secret. Used for AuthService.
   */
  ORYCLIENTSECRET = 'secret_client_string',
}

/**
 * Default config to fallback to in case one is not provided to config manager.
 */
const defaultProdConfig = new Map<ConfigKey, string>([
  [ConfigKey.API, 'https://core.trustflight.io'],
  [ConfigKey.COOKIEDOMAIN, 'dashboard.trustflight.io'],
  [ConfigKey.GOOGLEAPIKEY, 'AIzaSyC57PHscUxklL1Nq7aJlq02fnlyzjDR_ro'],
  [ConfigKey.HASHVERSION, '000000'],
  [ConfigKey.PUB, 'https://dashboard.trustflight.io'],
  [ConfigKey.UID, '489d4b937cea5bfdb96de6ccacec339c929daaad2699ed655d05b7ac72601e48'],
  [ConfigKey.SECRET, '2053cb12784bd6721ef8abc343f9fae4667bdf03afcb54c7c55fbae4cd44b2e2'],
  [ConfigKey.IDPURL, 'https://p02--hydra--vcxx59dn6vg2.code.run'],
  [ConfigKey.ORYCLIENTID, '582c8b75-579b-470f-9a0d-8d50b7f821d7'],
  [ConfigKey.ORYCLIENTSECRET, 'secret_client_string'],
]);

/**
 * Config management class. Can be used to store any settings used by Dashboard at runtime.
 * @class ConfigManager
 */
class ConfigManager {
  private static instanceHolder: ConfigManager;

  public static get instance(): ConfigManager {
    return ConfigManager.instanceHolder || new ConfigManager();
  }

  private config: typeof defaultProdConfig = defaultProdConfig;

  private constructor() {
    if (!ConfigManager.instanceHolder) {
      ConfigManager.instanceHolder = this;
    }
    return ConfigManager.instanceHolder;
  }

  /**
   * Gets a config value from the manager.
   * @param {ConfigKey} key The key of the config value you're looking for.
   * @returns {string}
   * @memberof ConfigManager
   */
  public get(key: ConfigKey): string {
    return this.config.get(key);
  }

  /**
   * Sets a new value for a given config entry within the manager.
   * @param {ConfigKey} key The key of the config you wish to update
   * @param {string} value The value to update the entry with.
   * @memberof ConfigManager
   */
  public set(key: ConfigKey, value: string): void {
    if (!Object.values(ConfigKey).includes(key)) {
      throw new Error(`Trying to set invalid config '${key}' with value: ${value}`);
    }
    this.config.set(key, value);
  }

  /**
   * Updates the entire config store with values from the supplied object.
   * Mainly used to set up the config manager on page load.
   * @param {{ [k: string]: string }} config A config obejct where all keys are a {ConfigKey} and values are strings.
   * @memberof ConfigManager
   */
  public setConfig(config: { [k: string]: string }): void {
    for (const [key, value] of Object.entries(config) as [ConfigKey, string][]) {
      this.set(key, value);
    }
  }

  /**
   * Resets the config map to the default prod values, this should probably never be used but it exists either way.
   * @memberof ConfigManager
   */
  public resetConfig(): void {
    this.config = defaultProdConfig;
  }
}

const config = ConfigManager.instance;
Object.freeze(config);
export default config;
