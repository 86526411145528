import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import { calculateActualUplift } from '../../utils/fuelCalculations';
import errorIcon from '../../assets/login-error.svg';
import { Flight } from '../../models/flights';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';
import FuelUplifts from './FuelUplifts';

interface FuelSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  align-items: center;
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '20px')};
`;

const StyledInput = styled.input`
  width: 100px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const DepartureUnit = styled.div`
  margin-left: 4px;
`;

const LineBreak = styled.div`
  height: 1px;
  margin: 20px 0 20px;
  background-color: ${({ theme }): string => theme.colors.black05Alpha};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 0;
  img {
    margin-right: 8px;
  }
`;

const ErrorText = styled.span`
  line-height: 1.43;
  color: #ac1717;
`;

const FuelSection: React.FC<FuelSectionProps> = ({ flight, updateFlightData }) => {
  const { formatMessage } = useIntl();
  const isMobile = window.innerWidth < 451;

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const currentAircraft = aircraftMap.get(id);
  let fuelunit;
  if (currentAircraft) fuelunit = currentAircraft?.fuel_unit;

  const hideFuelAdjustments = aircraftMap.get(id)?.standard_fields?.hide_fuel_uplifts_and_adjustments?.enabled;

  const [initial, setInitial] = useState(null);
  const [departure, setDeparture] = useState(null);
  const [adjustment, setAdjustment] = useState(null);
  const [arrival, setArrival] = useState(null);
  const [fuelUsed, setFuelUsed] = useState(null);
  const [initialError, setInitialError] = useState(false);
  const [adjustmentError, setAdjustmentError] = useState(false);
  const [arrivalError, setArrivalError] = useState(false);

  const handleInputChange = (value: string, type: string): void => {
    const intValue = parseInt(value, 10);
    if (type === 'initial') {
      if (value && (Number.isNaN(intValue) || intValue.toString() !== value)) {
        setInitialError(true);
      } else {
        setInitialError(false);
      }
      setInitial(intValue);
      updateFlightData([{ value: intValue, key: 'departure_fuel_pre_uplifts' }]);
    }
    if (type === 'adjustment') {
      if (value && (Number.isNaN(intValue) || intValue.toString() !== value)) {
        setAdjustmentError(true);
      } else {
        setAdjustmentError(false);
      }
      setAdjustment(intValue);
      updateFlightData([{ value: intValue, key: 'departure_fuel_adjustment' }]);
    }
    if (type === 'arrival') {
      if (value && (Number.isNaN(intValue) || intValue.toString() !== value)) {
        setArrivalError(true);
      } else {
        setArrivalError(false);
      }
      setArrival(intValue);
      updateFlightData([{ value: intValue, key: 'arrival_fuel' }]);
    }
  };

  useEffect(() => {
    if (flight) {
      if (flight?.departure_fuel_pre_uplifts) {
        setInitial(flight.departure_fuel_pre_uplifts);
      } else {
        setInitial('');
      }
      if (flight?.departure_fuel_adjustment) {
        setAdjustment(flight.departure_fuel_adjustment);
      } else {
        setAdjustment('');
      }
      if (flight?.arrival_fuel) {
        setArrival(flight?.arrival_fuel);
      } else {
        setArrival('');
      }
    }
  }, [flight]);

  useEffect(() => {
    const fuelUplifts = flight?.fuel_uplifts_attributes || flight?.fuel_uplifts;
    let totalFuelUplifts = 0;
    if (fuelUplifts) {
      totalFuelUplifts = fuelUplifts?.reduce((acc, uplift) => {
        let newTotal = acc;
        if (uplift?.truck_actual_uplift) newTotal += calculateActualUplift(uplift, aircraftMap.get(id)?.fuel_unit);
        return newTotal;
      }, 0);
    }
    const newTotal = Math.ceil(parseInt(initial || 0, 10) + parseInt(adjustment || 0, 10) + totalFuelUplifts || 0);
    setDeparture(newTotal);
  }, [adjustment, aircraftMap, flight, id, initial]);

  useEffect(() => {
    const formattedDeparture = parseInt(departure, 10);
    const formattedArrival = arrival ? parseInt(arrival, 10) : 0;
    const newFuelUsed = formattedDeparture - formattedArrival;
    setFuelUsed(newFuelUsed);
  }, [arrival, departure]);

  return (
    <SectionWrapper>
      <SectionHeader data-testid="FuelSection-Header">Fuel</SectionHeader>
      <DetailsWrapper edit={mode !== 'view'} isMobile={isMobile}>
        <div data-testid="FuelSection-EstimatedUplift">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.initialSmall' })}</Header>
          <UnitWrapper>
            {mode === 'view' ? (
              <DisplayText cap>{initial || '-'}</DisplayText>
            ) : (
              <StyledInput
                type="number"
                placeholder="-"
                value={initial}
                onChange={(e): void => handleInputChange(e.target.value, 'initial')}
              />
            )}
            <StyledUnit edit={mode !== 'view'}>{initial || mode !== 'view' ? fuelunit : ''}</StyledUnit>
          </UnitWrapper>
          {initialError ? (
            <ErrorWrapper>
              <img src={errorIcon} alt="error icon" />
              <ErrorText>Value entered must be an integer</ErrorText>
            </ErrorWrapper>
          ) : null}
        </div>
      </DetailsWrapper>
      <LineBreak />
      <FuelUplifts flight={flight} updateFlightData={updateFlightData} />
      <LineBreak />
      <DetailsWrapper edit={mode !== 'view'} isMobile={isMobile} noMargin>
        <div data-testid="FuelSection-ActualUplift">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.departure' })}</Header>
          <UnitWrapper>
            <DisplayText cap>{departure || '-'}</DisplayText>
            <DepartureUnit>{departure || mode !== 'view' ? fuelunit : ''}</DepartureUnit>
          </UnitWrapper>
        </div>
        {!hideFuelAdjustments ? (
          <div data-testid="FuelSection-Adjustment">
            <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.adjustment' })}</Header>
            <UnitWrapper>
              {mode === 'view' ? (
                <DisplayText cap>{adjustment || 0}</DisplayText>
              ) : (
                <StyledInput
                  type="number"
                  placeholder="-"
                  value={adjustment}
                  onChange={(e): void => handleInputChange(e.target.value, 'adjustment')}
                />
              )}
              <StyledUnit edit={mode !== 'view'}>{adjustment || mode !== 'view' ? fuelunit : ''}</StyledUnit>
            </UnitWrapper>
            {adjustmentError ? (
              <ErrorWrapper>
                <img src={errorIcon} alt="error icon" />
                <ErrorText>Value entered must be an integer</ErrorText>
              </ErrorWrapper>
            ) : null}
          </div>
        ) : null}
      </DetailsWrapper>
      <LineBreak />
      <DetailsWrapper edit={mode !== 'view'} isMobile={isMobile} noMargin>
        <div data-testid="FuelSection-Arrival">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.arrival' })}</Header>
          <UnitWrapper>
            {mode === 'view' ? (
              <DisplayText cap>{arrival || 0}</DisplayText>
            ) : (
              <StyledInput
                type="number"
                placeholder="-"
                value={arrival}
                onChange={(e): void => handleInputChange(e.target.value, 'arrival')}
              />
            )}
            <StyledUnit edit={mode !== 'view'}>{arrival || mode !== 'view' ? fuelunit : ''}</StyledUnit>
          </UnitWrapper>
          {arrivalError ? (
            <ErrorWrapper>
              <img src={errorIcon} alt="error icon" />
              <ErrorText>Value entered must be an integer</ErrorText>
            </ErrorWrapper>
          ) : null}
        </div>
        <div data-testid="FuelSection-UsedFuel">
          <div>
            <Header>{formatMessage({ id: 'text.fuelUsed' })}</Header>
            <UnitWrapper>
              <DisplayText cap>{fuelUsed || '-'}</DisplayText>
              <DepartureUnit>{fuelUsed || mode !== 'view' ? fuelunit : ''}</DepartureUnit>
            </UnitWrapper>
          </div>
        </div>
      </DetailsWrapper>
    </SectionWrapper>
  );
};

export default FuelSection;
