import React from 'react';
import { ResponsiveContainer, AreaChart, CartesianGrid, Area, XAxis, YAxis, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import EmptyStateFuel from '../../assets/emptyState/empty-state-fuel.svg';
import EmptyState from '../EmptyState/EmptyState';
import styles from './FuelUsage.module.less';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#b0bac9" transform="rotate(0)">
        {payload.value}
      </text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
};

const FuelDelta = ({ monthly, formatMessage }) => {
  const gradientOffset = (data) => {
    if (data) {
      const dataMax = Math.max(...data.map((i) => i.fuelDeficit));
      const dataMin = Math.min(...data.map((i) => i.fuelDeficit));
      if (dataMax <= 0) {
        return 0;
      }
      if (dataMin >= 0) {
        return 1;
      }
      return dataMax / (dataMax - dataMin);
    }
    return 0;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={400} id="fuelGraph" data-testid="FuelDelta--Graph">
        {monthly.length > 0 ? (
          <AreaChart width={600} height={300} data={monthly} margin={{ top: 10, right: 50, left: 10, bottom: 20 }}>
            <XAxis dataKey="periodFormatted" tick={<CustomizedAxisTick />} />
            <YAxis unit=" kg" type="number" domain={['auto', 'auto']} padding={{ bottom: 20, top: 20 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset={gradientOffset(monthly)} stopColor="#00ab72" stopOpacity={0.6} />
                <stop offset={gradientOffset(monthly)} stopColor="red" stopOpacity={0.6} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              name={formatMessage({ id: 'text.fuelDelta' })}
              dataKey="fuelDeficit"
              stroke="#000000"
              fill="url(#splitColor)"
            />
          </AreaChart>
        ) : (
          <EmptyState
            image={EmptyStateFuel}
            text="No fuel usage data"
            subText="Fuel usage will be shown here when data has been added."
          />
        )}
      </ResponsiveContainer>
      {monthly.length > 0 ? (
        <div className={styles.fuelDeltaText}>
          <span>{formatMessage({ id: 'tooltip.fuelDelta' })}</span>
          {formatMessage({ id: 'tooltip.fuelDeltaExplain' })}
          <span>{formatMessage({ id: 'tooltip.fuelDeltaPositive' })}</span>
          {formatMessage({ id: 'tooltip.fuelDeltaPositiveExplain' })}
        </div>
      ) : null}
    </>
  );
};

FuelDelta.propTypes = {
  monthly: PropTypes.array,
  formatMessage: PropTypes.func.isRequired,
};

FuelDelta.defaultProps = {
  monthly: [],
};

export default FuelDelta;
