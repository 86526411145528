import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Button, DatePicker } from 'antd';
import { Button as TFButton, Modal } from '@arcflight/tf-component-library';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/TFLoading';
import DurationFormat from '../../components/DurationFormat';
import servers from '../../utils/servers';
import defaults from '../../utils/defaults';
import generateIcon from '../../assets/generate.svg';
import TFCard from '../../components/TFCard/TFCard';
import moreIcon from '../../assets/icon-button-settings.svg';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { add } from '../../models/userSettings/actions';
import { fetch } from '../../models/aircraftLogbook/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import TableWrappedWithInstance from '../../components/TableWrapper';
import ColumnDropdownMenu from './ColumnDropdown';
import styles from './Monthly.module.less';

const monthFormat = 'MMM-YY';
const { MonthPicker } = DatePicker;

const ENGINE_HAS_PROPELLERS = { piston: true, turboprop: true };

const fixedColumns = ['trip_date', 'departure_airport', 'arrival_airport'];

const defaultChecked = [
  'trip_date',
  'departure_airport',
  'arrival_airport',
  'trip_number',
  'blocks_off',
  'takeoff',
  'landing',
  'blocks_on',
  'flight_time',
  'blocks_time',
  'landings',
  'airframe_hours',
  'engine_1_hours',
  'engine_2_hours',
  'engine_1_cycles',
  'engine_2_cycles',
  'airframe_landings',
  'engine_1_oil_uplift',
  'engine_2_oil_uplift',
  'srp_number',
];

const columnOrder = [
  'trip_date',
  'departure_airport',
  'arrival_airport',
  'trip_number',
  'blocks_off',
  'takeoff',
  'landing',
  'blocks_on',
  'flight_time',
  'blocks_time',
  'captain',
  'first_officer',
  'landings',
  'callsign',
  'airframe_hours',
  'engine_1_hours',
  'engine_2_hours',
  'engine_3_hours',
  'engine_4_hours',
  'engine_1_cycles',
  'engine_2_cycles',
  'engine_3_cycles',
  'engine_4_cycles',
  'apu_hours',
  'apu_cycles',
  'prop_1_hours',
  'prop_2_hours',
  'prop_3_hours',
  'prop_4_hours',
  'airframe_landings',
  'airframe_cycles',
  'hobbs_arrival',
  'hobbs_departure',
  'flight_hobbs',
  'passengers_male',
  'passengers_female',
  'passengers_children',
  'passengers_infants',
  'total_passengers',
  'engine_1_oil_uplift',
  'engine_2_oil_uplift',
  'engine_3_oil_uplift',
  'engine_4_oil_uplift',
  'engine_1_hydraulic_uplift',
  'engine_2_hydraulic_uplift',
  'engine_3_hydraulic_uplift',
  'engine_4_hydraulic_uplift',
  'initial_fuel',
  'adjusted_initial_fuel',
  'total_fuel_truck_uplift',
  'total_aircraft_uplift',
  'fuel_adjustment',
  'fuel_adjustment_justification',
  'departure_fuel',
  'arrival_fuel',
  'planned_fuel_burn',
  'actual_fuel_burn',
  'srp_number',
  'flight_source',
  'fuel_uplift_quantity',
  'fuel_uplift_price',
  'fuel_uplift_currency',
  'fuel_uplift_paid',
];

class Monthly extends PureComponent {
  static propTypes = {
    aircraftLogbook: PropTypes.object.isRequired,
    aircraft: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
    userSettingsLoading: false,
    userLogbookColumnsUpdated: false,
    changeMonthLoading: false,
    month: moment(),
    nextDisabled: true,
    disableDownload: false,
    columnsDropdownVisible: false,
    buttonPosition: null,
    checkedColumns: [],
    selectableColumns: [],
    page: 1,
    limit: 10,
    resetToOne: false,
    modalVisible: false,
    startMonth: moment(),
    endMonth: moment(),
  };

  componentDidMount() {
    const {
      aircraft: { ttl },
      selectedAircraft,
      userSettings: { logbook_columns },
    } = this.props;
    if (!selectedAircraft.id || Date.now() - selectedAircraft.lastFetched >= ttl) {
      this.getAircraft(true);
    }
    this.getLogbookData(logbook_columns);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedAircraft,
      aircraftLogbook,
      userSettings,
      userSettings: { logbook_columns },
    } = this.props;
    const { checkedColumns, userLogbookColumnsUpdated } = this.state;
    if (!_.isEqual(selectedAircraft, prevProps.selectedAircraft)) {
      this.getLogbookData(checkedColumns.length ? checkedColumns : logbook_columns);
    }
    if (aircraftLogbook.data !== prevProps.aircraftLogbook.data || userLogbookColumnsUpdated) {
      this.createColumnOptions();
    }
    if (!_.isEqual(userSettings.logbook_columns, prevProps.userSettings.logbook_columns)) {
      this.updatedUserSettings();
    }
    if (!_.isEqual(userSettings, prevProps.userSettings)) {
      this.handleUserSettingsLoading();
    }
  }

  getAircraft = async (forceRefetch = false) => {
    const {
      dispatch,
      match: {
        params: { id },
      },
    } = this.props;
    if (forceRefetch) {
      await dispatch(
        getSingleAircraft({
          payload: id,
        }),
      );
    }
  };

  updatedUserSettings = () => {
    this.setState({ userLogbookColumnsUpdated: true });
  };

  createColumnOptions = () => {
    const {
      aircraftLogbook: { data, selectableColumns },
      userSettings: { logbook_columns },
    } = this.props;
    if (data?.length) {
      const columnOptions = selectableColumns
        .filter((col) => columnOrder.indexOf(col) !== -1)
        .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
        .map((item) => {
          const isChecked = logbook_columns.includes(item);
          return { title: item, checked: isChecked };
        });
      this.setState({
        selectableColumns: columnOptions,
        checkedColumns: logbook_columns,
        userLogbookColumnsUpdated: false,
      });
    }
  };

  filterColumns = (columns) => {
    const { selectedAircraft } = this.props;
    let filteredColumns = columns;
    if (!selectedAircraft?.standard_fields?.show_fuel_burn?.enabled) {
      const exclude = ['planned_fuel_burn', 'actual_fuel_burn'];
      const excludeRegex = new RegExp(exclude.join('|'), 'i');
      filteredColumns = filteredColumns.filter((col) => !excludeRegex.test(col));
    }
    if (!selectedAircraft?.apu_installed) {
      const exclude = ['apu_hours', 'apu_cycles'];
      const excludeRegex = new RegExp(exclude.join('|'), 'i');
      filteredColumns = filteredColumns.filter((col) => !excludeRegex.test(col));
    }
    return filteredColumns;
  };

  getLogbookData = async (columns) => {
    const { dispatch, selectedAircraft } = this.props;
    const filteredColumns = this.filterColumns(columns);
    if (selectedAircraft.id) {
      await new Promise((resolve) => {
        this.setState({ loading: true }, resolve);
      });

      await dispatch(
        fetch({
          payload: {
            aircraft: selectedAircraft.id,
            from: moment(this.state.month).startOf('month').format('YYYY-MM-DD'),
            to: moment(this.state.month).endOf('month').format('YYYY-MM-DD'),
            columns: filteredColumns || defaultChecked,
          },
        }),
      );
      await new Promise((resolve) => {
        this.setState({ loading: false, resetToOne: false }, resolve);
        this.getColumnButtonPosition();
      });
    }
  };

  handleUserSettingsLoading = () => {
    this.setState({ userSettingsLoading: false });
  };

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  getColumnButtonPosition = () => {
    const button = document.getElementById('columnsWrapper');
    if (button) {
      const rect = button.getBoundingClientRect();
      this.setState({ buttonPosition: { top: rect.top, left: rect.left } });
    }
  };

  aircraftHasPropellers = () => {
    const { selectedAircraft } = this.props;
    return ENGINE_HAS_PROPELLERS[selectedAircraft.aircraft_type.engine_type];
  };

  generateDataBasedOnData = () => {
    const {
      aircraftLogbook,
      intl: { formatMessage },
      selectedAircraft,
    } = this.props;

    const data = aircraftLogbook.data || [];
    const dataObj = [];
    const columns = selectedAircraft.id
      ? this.generateColumnsBasedOnData(selectedAircraft.aircraft_type.engine_count)
      : [];
    data.forEach((entry, index) => {
      const object = {};
      columns.forEach((column) => {
        const name = column.accessor;
        if (column.accessor === 'date') {
          object[name] =
            this.props.userSettings && this.props.userSettings.dateFormat
              ? moment(entry.date).format(this.props.userSettings.dateFormat)
              : moment(entry.date).format(defaults.defaultDateFormat);
        } else if (column.accessor === 'flight_time') {
          object[name] = <DurationFormat time={data[index][name]} />;
        } else {
          object[name] =
            data[index][name] || data[index][name] === 0 || data[index][name] === false ? data[index][name] : null;
        }
      });
      dataObj.push(object);

      // set date but if first or last collapse rows
      if (entry.date) {
        data[index].date =
          this.props.userSettings && this.props.userSettings.dateFormat
            ? moment(entry.date).format(this.props.userSettings.dateFormat)
            : moment(entry.date).format(defaults.defaultDateFormat);
      }
      if (index === 0) {
        dataObj[index].trip_date = {
          value: `${formatMessage({ id: 'text.broughtForwardFromPrevious' })}`,
          colSpan: 1,
        };
      }
      if (index === data.length - 1) {
        dataObj[index].trip_date = { value: `${formatMessage({ id: 'text.totals' })}`, colSpan: 1 };
      }
    });
    return dataObj;
  };

  generateColumnsBasedOnData = () => {
    const { checkedColumns } = this.state;
    const {
      userSettings: { logbook_columns, details },
      selectedAircraft,
    } = this.props;
    const columns = [];
    const currentOperator = details?.operators?.find((operator) => {
      return operator.id === selectedAircraft?.operator_id;
    });
    let buildColumns = logbook_columns || checkedColumns;
    if (!selectedAircraft?.standard_fields?.show_fuel_burn?.enabled) {
      const exclude = ['planned_fuel_burn', 'actual_fuel_burn'];
      const excludeRegex = new RegExp(exclude.join('|'), 'i');
      buildColumns = buildColumns.filter((col) => !excludeRegex.test(col));
    }
    buildColumns.forEach((column) => {
      let newTitle = column.replace(/_/g, ' ');
      if (newTitle === 'airframe landings') newTitle = 'total landings';
      if (newTitle === 'trip date') newTitle = 'date';
      if (newTitle === 'departure airport') newTitle = 'from';
      if (newTitle === 'arrival airport') newTitle = 'to';
      if (newTitle === 'airframe hours original') newTitle = 'airframe hours';
      if (newTitle.endsWith('uplift')) newTitle = newTitle.replace(' uplift', '');
      if (newTitle.includes('engine')) {
        const words = newTitle.split(' ');
        newTitle = `${currentOperator.operator_setting[`${words[0]}_${words[1]}`]} ${words[2]}`;
      }
      columns.push({
        Header: newTitle,
        accessor: column,
        Cell: ({ value }) => {
          let formattedValue = value && value.value ? value.value : value;
          if (newTitle === 'date') formattedValue = moment(value).format(this.props.userSettings.dateFormat);
          // if (newTitle === 'fuel uplift paid') {
          //   if (value === true) formattedValue = 'Paid';
          //   if (value === false) formattedValue = 'Unpaid';
          // }
          if (value?.value === 'Brought forwards' || value?.value === 'Totals')
            formattedValue = value && value.value ? value.value : value;
          return <div>{formattedValue}</div>;
        },
      });
    });
    return columns;
  };

  generateCSV = () => {
    const {
      selectedAircraft,
      intl: { formatMessage },
      userSettings: { logbook_columns },
      dispatch,
    } = this.props;
    const { checkedColumns } = this.state;
    const start = moment(this.state.startMonth).startOf('month').format('YYYY-MM-DD');
    const end = moment(this.state.endMonth).endOf('month').format('YYYY-MM-DD');
    const columns = this.filterColumns(checkedColumns.length ? checkedColumns : logbook_columns);
    columns.push('ac_registration', 'trip_custom_data', 'preflight_custom_data', 'postflight_custom_data');
    const csvURL = `${servers.api}/aircraft/${
      selectedAircraft.id
    }/generate_csv?from_date=${start}&to_date=${end}&columns=${columns || defaultChecked}`;

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = csvURL;
    a.click();

    dispatch(
      addToast({
        payload: {
          title: formatMessage({ id: 'message.csvGenerated' }),
          type: ToastTypes.SUCCESS,
          category: ToastCategories.FLASH,
          message: '',
        },
      }),
    );
  };

  previousMonth = async () => {
    const { month } = this.state;
    const current = month;
    const newMonth = moment(current).subtract(1, 'months');
    await new Promise((resolve) => this.setState({ month: newMonth }, resolve));
    this.handleMonthChange();
  };

  handleMonthChange = async () => {
    const { month, checkedColumns } = this.state;
    await new Promise((resolve) => this.setState({ changeMonthLoading: true }, resolve));
    if (month !== null) {
      this.setState({ disableDownload: false });
      await this.getLogbookData(checkedColumns);
      if (moment(month).format('MM-YY') === moment().format('MM-YY')) {
        this.setState({
          nextDisabled: true,
        });
      } else {
        this.setState({
          nextDisabled: false,
        });
      }
    } else {
      this.setState({ disableDownload: true });
    }
    await new Promise((resolve) => this.setState({ changeMonthLoading: false, page: 1, resetToOne: true }, resolve));
  };

  handleColumnDropdownClick = () => {
    const {
      dispatch,
      userSettings: { logbook_columns },
    } = this.props;
    const { columnsDropdownVisible, selectableColumns } = this.state;
    if (columnsDropdownVisible) {
      const checkedColumnTitles = selectableColumns.filter((col) => col.checked).map((col) => col.title);
      if (!_.isEqual(checkedColumnTitles, logbook_columns)) {
        this.getLogbookData(checkedColumnTitles);
        const formData = new FormData();
        formData.append('logbook_columns', JSON.stringify(checkedColumnTitles));
        this.setState({ loading: true, userSettingsLoading: true, checkedColumns: checkedColumnTitles });
        dispatch(add({ payload: { form: formData } }));
      }
    }
    this.setState({ columnsDropdownVisible: !columnsDropdownVisible });
  };

  handleColumnSelection = (value) => {
    const { selectableColumns } = this.state;
    const newSelection = selectableColumns.map((column) => {
      if (column.title === value) {
        // eslint-disable-next-line no-param-reassign
        column.checked = !column.checked;
      }
      return column;
    });
    this.setState({ selectableColumns: newSelection });
  };

  nextMonth = async () => {
    const { month } = this.state;
    const current = month;
    const newMonth = moment(current).add(1, 'months');
    await new Promise((resolve) => this.setState({ month: newMonth }, resolve));
    this.handleMonthChange();
  };

  pickerChange = async (val) => {
    await new Promise((resolve) => this.setState({ changeMonthLoading: true }, resolve));
    this.setState(
      {
        month: val,
      },
      () => {
        this.handleMonthChange();
      },
    );
  };

  onPaginationChange = (currentPage, numberOfItems) => {
    const { page, limit } = this.state;
    if (numberOfItems !== limit) {
      this.setState({ page: 1, limit: numberOfItems });
    } else if (currentPage !== page) {
      if (currentPage === 0) {
        // Handle if user types 0 into input box
        this.setState({ page: currentPage + 1 });
      } else {
        this.setState({ page: currentPage });
      }
    }
  };

  exportMonth = (value, key) => {
    const { startMonth, endMonth } = this.state;
    this.setState({ [`${key}`]: value });
    if (key === 'startMonth' && value.isAfter(endMonth)) {
      this.setState({ endMonth: value });
    }
    if (key === 'endMonth' && value.isBefore(startMonth)) {
      this.setState({ startMonth: value });
    }
  };

  render() {
    const {
      intl: { formatMessage },
      selectedAircraft,
    } = this.props;
    const {
      loading,
      userSettingsLoading,
      month,
      nextDisabled,
      disableDownload,
      changeMonthLoading,
      columnsDropdownVisible,
      selectableColumns,
      buttonPosition,
      page,
      limit,
      resetToOne,
      modalVisible,
      startMonth,
      endMonth,
    } = this.state;

    return (
      <InnerMenuLayout loading={loading}>
        <TFCard>
          <div className={styles.tableHeader}>
            <span className={styles.tableTitle}>
              <span data-test="pageTitle">Logbook</span>
              <button
                className={styles.columnsWrapper}
                id="columnsWrapper"
                type="button"
                onClick={this.handleColumnDropdownClick}
              >
                <img src={moreIcon} alt="menu" className={styles.menuIcon} />
                <ColumnDropdownMenu
                  visible={columnsDropdownVisible}
                  handleColumnDropdownClick={this.handleColumnDropdownClick}
                  selectedColumns={selectableColumns}
                  buttonPosition={buttonPosition}
                  handleColumnSelection={this.handleColumnSelection}
                  fixedColumns={fixedColumns}
                />
              </button>
            </span>
            <div className={styles.tableActions}>
              <div className={styles.allButtons}>
                <div className={styles.monthPickerWrapper}>
                  <span className={styles.firstBtn}>
                    <Button type="secondary" onClick={() => this.previousMonth()} data-test="prevMonthButton">
                      <Icon type="left" />
                    </Button>
                  </span>
                  <MonthPicker
                    onChange={(val) => this.pickerChange(val)}
                    disabledDate={(d) => !d || d.isBefore('1902-12-31') || d.isAfter(moment().endOf('month'))}
                    value={month}
                    format={monthFormat}
                    allowClear={false}
                    data-test="monthPicker"
                  />
                  <span className={styles.lastBtn}>
                    <Button
                      disabled={nextDisabled}
                      type="secondary"
                      onClick={() => this.nextMonth()}
                      data-test="nextMonthButton"
                    >
                      <Icon type="right" />
                    </Button>
                  </span>
                </div>
                <div className={styles.downloadButton}>
                  <TFButton
                    size={ButtonSize.MEDIUM}
                    onClick={() => {
                      this.setState({ modalVisible: true });
                    }}
                    disabled={disableDownload}
                    data-test="csvButton"
                  >
                    <img src={generateIcon} alt="generate icon" className={styles.downloadIcon} />
                    {formatMessage({ id: 'form.button.generateCSV' })}
                  </TFButton>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={modalVisible} handleClose={this.handleClose}>
            <div className={styles.modalTitle}>Select date range for your export</div>
            <div className={styles.dateRangeWrapper}>
              <div className={styles.startMonthPicker}>
                <div className={styles.monthHeader}>From beginning of</div>
                <MonthPicker
                  onChange={(val) => this.exportMonth(val, 'startMonth')}
                  value={startMonth}
                  format={monthFormat}
                  allowClear={false}
                  data-test="monthPicker"
                />
              </div>
              <div>
                <div className={styles.monthHeader}>To end of</div>
                <MonthPicker
                  onChange={(val) => this.exportMonth(val, 'endMonth')}
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore('1902-12-31') ||
                    d.isAfter(moment().endOf('month')) ||
                    d.isAfter(moment(startMonth).add(12, 'month'))
                  }
                  value={endMonth}
                  format={monthFormat}
                  allowClear={false}
                  data-test="monthPicker"
                />
              </div>
            </div>
            <div className={styles.helperText}>Max number of months exported at one time is 12</div>
            <div className={styles.modalButtonWrapper}>
              <div className={styles.generateButtonWrapper}>
                <TFButton
                  size={ButtonSize.MEDIUM}
                  data-test="secondCSVButton"
                  onClick={this.generateCSV}
                  disabled={endMonth.diff(startMonth, 'months') > 12}
                >
                  <img src={generateIcon} alt="generate icon" className={styles.downloadIcon} />
                  {formatMessage({ id: 'form.button.generateCSV' })}
                </TFButton>
              </div>
              <TFButton primary={false} size={ButtonSize.MEDIUM} onClick={this.handleClose}>
                Cancel
              </TFButton>
            </div>
          </Modal>

          <div className={styles.tableList}>
            <Loading loading={loading || userSettingsLoading || changeMonthLoading} contain />
            <TableWrappedWithInstance
              dataTestId="logbookTable"
              columns={
                selectedAircraft.id ? this.generateColumnsBasedOnData(selectedAircraft.aircraft_type.engine_count) : []
              }
              data={
                selectedAircraft.id ? this.generateDataBasedOnData(selectedAircraft.aircraft_type.engine_count) : []
              }
              onPaginationChange={this.onPaginationChange}
              page={page}
              limit={limit}
              resetToOne={resetToOne}
            />
          </div>
        </TFCard>
      </InnerMenuLayout>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    (
      { aircraft, aircraftLogbook, userSettings },
      {
        match: {
          params: { id },
        },
      },
    ) => ({
      aircraft,
      aircraftLogbook,
      selectedAircraft: aircraft.aircraftMap.get(id) || {},
      userSettings,
    }),
  ),
)(Monthly);
