import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import closeIconMobile from './assets/close-icon-small.svg';
import closeIcon from './assets/esc.svg';
import styles from './stylesheet.module.less';

const Header = (props) => {
  const { handleModalVisible, headerIcon, headerTitle, headerSubtitle, uploading, intl } = props;
  return (
    <>
      <div className={styles.modalHeaderContainer}>
        <div className={styles.headerWrapper}>
          <div className={styles.headerContextContainer}>
            <div className={styles.headerTitleContainer}>
              <div className={headerIcon ? styles.headerIconContainer : ''}>
                {headerIcon ? <img src={headerIcon} alt="headerIcon" /> : null}
              </div>
              <div className={styles.headerTextContainer}>
                <h4 data-test="headerTitle" className={styles.titleText}>
                  {headerTitle}
                </h4>
              </div>
            </div>
            <div className={styles.headerSubtitleContainer}>
              <span data-test="headerSubtitle" className={styles.subtitleText}>
                {headerSubtitle}
              </span>
            </div>
          </div>
          <Button
            type="link"
            className={styles.headerCloseIconContainer}
            onClick={() => {
              if (!uploading) {
                handleModalVisible(false);
              } else {
                message.warning(intl.formatMessage({ id: 'message.pleaseWaitTillFinish' }));
              }
            }}
          >
            <div className={styles.headerCloseIconInnerContainer}>
              <picture className={styles.desktopCloseImage}>
                <source media="(max-width: 768px)" srcSet={closeIconMobile} />
                <source media="(min-width: 768px)" srcSet={closeIcon} />
                <img src={closeIcon} alt="closeIcon" />
              </picture>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  handleModalVisible: PropTypes.func,
  headerIcon: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string.isRequired,
  uploading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

Header.defaultProps = {
  handleModalVisible: undefined,
  headerIcon: undefined,
  uploading: false,
};

export default injectIntl(Header);
