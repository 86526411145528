import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { FRAT } from '../../models/frats';
import TFInput from '../../components/TFInput/TFInput';
import TFRadioInput from '../../components/TFRadioInput/TFRadioInput';
import { getAllAircraft } from '../../models/aircraft/actions';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { changeDrawerVisibility } from '../../models/drawer';
import useGetIndividualFRATData from './useGetIndividualFRATData';
import useUpdateFRAT from './useUpdateFRAT';

interface MainFRATDrawerProps {
  selectedOperator: { title: string; value: string | null };
  selectedFRAT?: FRAT;
}

const defaultProps = {
  selectedFRAT: null,
};

const Wrapper = styled.div`
  padding: 32px 48px;
  min-height: 500px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const DrawerTitle = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  display: flex;
  align-items: baseline;
  span {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 20px;
`;

const ButtonSection = styled.div`
  margin-top: 20px;
  display: flex;
`;

const AddNewWrapper = styled.div`
  margin-right: 16px;
`;

const MainFRATDrawer: React.FC<MainFRATDrawerProps> = ({ selectedOperator, selectedFRAT }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { formatMessage } = useIntl();

  const queryClient = useQueryClient();

  const [FRATData, setFRATData] = useState(null);
  // const [operatorAircraft, setOperatorAircraft] = useState([]);
  // const [selectedAircraft, setSelectedAircraft] = useState([]);
  const [allAircraftRequested, setAllAircraftRequested] = useState(false);

  const dispatch = useDispatch();

  const { data: individualFRAT } = useGetIndividualFRATData({ drawerId: selectedFRAT?.id });

  const handleNameChange = (value: string): void => {
    const newData = _.cloneDeep(FRATData) || {};
    newData.name = value;
    setFRATData(newData);
  };

  const handleDefaultChange = (value: string): void => {
    const newData = _.cloneDeep(FRATData) || {};
    newData.default = value === 'Yes';
    setFRATData(newData);
  };

  const handeUpdateFRATOnSuccess = (): void => {
    dispatch(changeDrawerVisibility({ payload: false }));
    queryClient.invalidateQueries(['FRAT', selectedOperator?.value]);
    dispatch(
      addToast({
        payload: {
          title: 'FRAT Updated',
          message: 'FRAT updated successfully',
          type: ToastTypes.SUCCESS,
          category: ToastCategories.FLASH,
        },
      }),
    );
  };

  const handeUpdateFRATOnError = (): void => {};

  const updateFRAT = useUpdateFRAT({ handeUpdateFRATOnSuccess, handeUpdateFRATOnError });

  // const handleAircraftChange = (value: { title: string; value: string }): void => {
  //   const newData = _.cloneDeep(selectedAircraft);
  //   const index = selectedAircraft.findIndex((item) => item.value === value?.value);
  //   if (index === -1) {
  //     newData.push(value);
  //   } else {
  //     newData.splice(index, 1);
  //   }
  //   setSelectedAircraft(newData);
  // };

  const handleSaveClick = (): void => {
    updateFRAT.mutate({ ...FRATData, id: selectedFRAT?.id });
  };

  const handleCancelClick = (): void => {
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  // useEffect(() => {
  //   if (selectedOperator) {
  //     const aircraftFromOperator = [];
  //     for (const [key, value] of aircraftMap) {
  //       if (value.operator_id === selectedOperator.value) {
  //         aircraftFromOperator.push({ title: value.registration, value: key });
  //       }
  //     }
  //     setOperatorAircraft(aircraftFromOperator);
  //   }
  // }, [aircraftMap, selectedOperator]);

  useEffect(() => {
    if (selectedFRAT) {
      setFRATData(selectedFRAT);
    }
  }, [selectedFRAT]);

  // awaiting response from CORE as to if this is going to be included
  // useEffect(() => {
  //   if (selectedFRAT && operatorAircraft?.length) {
  //     const newSelectedAircraft = operatorAircraft.filter((ac) => {
  //       return selectedFRAT?.aircraft?.includes(ac.value);
  //     });
  //     setSelectedAircraft(newSelectedAircraft);
  //   }
  // }, [operatorAircraft, selectedFRAT]);

  useEffect(() => {
    if (aircraftMap.size === 0 && !allAircraftRequested) {
      dispatch(getAllAircraft());
      setAllAircraftRequested(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraftMap.size, allAircraftRequested]);

  return (
    <Wrapper data-testid="MainFRATDrawer--Wrapper">
      <DrawerTitle data-testid="MainFRATDrawer--DrawerTitle">
        {mode === 'add' ? 'Add new FRAT' : `Edit FRAT: ${FRATData?.name}`}
      </DrawerTitle>
      <InputWrapper data-testid="MainFRATDrawer--InputWrapper">
        <TFInput
          id="MainFRATDrawer--name"
          label="Name"
          value={FRATData?.name}
          onChange={(e): void => handleNameChange(e.target.value)}
        />
        {!selectedFRAT?.default && (
          <TFRadioInput
            options={['Yes', 'No']}
            onChange={(value): void => handleDefaultChange(value)}
            value={FRATData?.default ? 'Yes' : 'No'}
            label="Default"
            id="MainFRATDrawer--default"
            disabled={individualFRAT?.flight_risk_assessment_question_categories?.length === 0}
            tooltip={
              individualFRAT?.flight_risk_assessment_question_categories?.length === 0
                ? 'FRAT must not be empty to be set as default'
                : ''
            }
          />
        )}
        {/* <TFAddRemoveDropdown
          options={operatorAircraft}
          onSelect={(value): void => handleAircraftChange(value)}
          initialValues={selectedAircraft}
          id="MainFRATDrawer--aircraftSelect"
          label="Select aircraft"
        /> */}
      </InputWrapper>
      {mode !== 'view' ? (
        <ButtonSection data-testid="MainFRATDrawer--ButtonSection">
          <AddNewWrapper data-testid="MainFRATDrawer--AddNewWrapper">
            <Button height="32px" onClick={handleSaveClick}>
              {formatMessage({ id: 'text.save' })}
            </Button>
          </AddNewWrapper>
          <Button height="32px" onClick={handleCancelClick} primary={false}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </ButtonSection>
      ) : null}
    </Wrapper>
  );
};

MainFRATDrawer.defaultProps = defaultProps;

export default MainFRATDrawer;
