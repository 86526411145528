import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import searchicon from '../../assets/search.svg';
import cancel from '../../assets/icon-clear.svg';

export type SearchProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear: () => void;
  placeholder?: string;
  reset?: boolean;
  additionalButton?: React.ReactNode;
};

export const SearchWrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 120px;
  border-radius: 16px;
  border: solid 1px ${({ theme }): string => theme.colors.black20Alpha};
  padding-left: 35px;
  padding-right: 32px;
  color: ${({ theme }): string => theme.colors.black60Alpha};
  font-size: 14px;
  &:hover {
    border-color: ${({ theme }): string => theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border-color: ${({ theme }): string => theme.colors.brandBlue};
  }
  ::placeholder {
    color: ${({ theme }): string => theme.colors.black40Alpha};
  }
`;

export const SearchIcon = styled.img`
  height: 16px;
  position: absolute;
  left: 13px;
  margin-top: 7px;
`;

export const ClearSearchButton = styled.img<{
  buffer: boolean;
}>`
  height: 16px;
  position: absolute;
  left: ${({ buffer }): string => (buffer ? '142px' : '160px')};
  margin-top: 7px;
  &:hover {
    cursor: pointer;
  }
`;

const DefectSearch: React.FC<SearchProps> = ({
  onChange,
  placeholder = 'Search...',
  onClear,
  reset,
  additionalButton,
}) => {
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (reset && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [reset]);

  return (
    <SearchWrapper data-testid="searchWrapper">
      <SearchInput
        onChange={(event) => {
          if (!showClearButton && event.target.value.length > 0) {
            setShowClearButton(true);
          } else if (showClearButton && event.target.value.length === 0) {
            setShowClearButton(false);
          }
          onChange(event);
        }}
        placeholder={placeholder}
        ref={searchRef}
        data-testid="searchInput"
      />
      <SearchIcon src={searchicon} data-testid="searchIcon" />
      {showClearButton ? (
        <ClearSearchButton
          buffer={!!additionalButton}
          data-testid="clearSearchButton"
          src={cancel}
          onClick={(): void => {
            if (searchRef.current) {
              searchRef.current.value = '';
            }
            setShowClearButton(false);
            onClear();
          }}
        />
      ) : null}
      {additionalButton && additionalButton}
    </SearchWrapper>
  );
};

export default DefectSearch;
