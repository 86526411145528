import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import TFDropdownMenu from '../../components/TFDropdownMenu/TFDropdownMenu';
import { Label, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { FRATQuestion } from '../../models/frats';
import FRATDrawer from './FRATDrawer';
import useDeleteFRATQuestion from './useDeleteFRATQuestion';

interface FRATExpandedItemViewProps {
  item: FRATQuestion;
  fratId: string;
  editable: boolean;
}

const Wrapper = styled.div`
  padding: 0 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  display: ${({ flex }): string => (flex ? 'flex' : 'block')};
  border-top: ${({ topBorder, theme }): string => (topBorder ? `1px solid ${theme.colours.black05Alpha}` : 'none')};
  padding-top: ${({ topBorder }): string => (topBorder ? '20px' : '0')};
  width: 100%;
`;

const OptionsHeader = styled.div`
  margin-bottom: 10px;
  font-weight: 500;
  color: #313745;
`;

// const AddedByWrapper = styled.div`
//   margin-right: 20px;
// `;

const OptionWrapper = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;

const StyledText = styled(Text)`
  color: #646464;
  font-weight: 500;
`;

const ManageButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const FRATExpandedItemView: React.FC<FRATExpandedItemViewProps> = ({ item, fratId, editable }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const queryClient = useQueryClient();

  const handleDeleteQuestionOnSuccess = (): void => {
    queryClient.invalidateQueries(['individualFRAT', fratId]);
  };

  const handleDeleteQuestionOnError = (): void => {
    dispatch(
      addToast({
        payload: {
          title: 'Error deleting FRAT question',
          message: '',
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const deleteQuestion = useDeleteFRATQuestion({ handleDeleteQuestionOnSuccess, handleDeleteQuestionOnError });

  const handleEditQuestionClick = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'edit' }));
    dispatch(changeDrawerContent({ payload: { content: <FRATDrawer id={item?.id} /> } }));
    dispatch(setDrawerId({ payload: fratId }));
  };

  const handleDeleteQuestionClick = (): void => {
    deleteQuestion.mutate({ id: item?.id });
  };

  return (
    <Wrapper data-testid="FRATExpandedItemView--Wrapper">
      <div>
        <SectionWrapper topBorder data-testid="FRATExpandedItemView--SectionWrapper-Title">
          <Label>{formatMessage({ id: 'text.name' })}</Label>
          <StyledText>{item?.question}</StyledText>
        </SectionWrapper>

        {/* add back in if we add in added by and modified by
        <SectionWrapper flex data-testid="FRATExpandedItemView--SectionWrapper-People">
          <AddedByWrapper>
            <Label>{formatMessage({ id: 'text.addedBy' })}</Label>
            <StyledText>{item?.created_at || '-'}</StyledText>
          </AddedByWrapper>
          <div>
            <Label>{formatMessage({ id: 'text.modifiedBy' })}</Label>
            <StyledText>{item?.updated_at || '-'}</StyledText>
          </div>
        </SectionWrapper> */}
        <OptionsHeader data-testid="FRATExpandedItemView--SectionWrapper-Options">
          {formatMessage({ id: 'text.options' })} ({item?.flight_risk_assessment_options?.length || '0'})
        </OptionsHeader>
        {item?.flight_risk_assessment_options?.length &&
          item?.flight_risk_assessment_options?.map((option, index) => (
            <div key={option?.id}>
              <OptionWrapper data-testid={`FRATExpandedItemView--Option-${index}`}>
                <div>
                  <Label>{`Option ${index + 1}`}</Label>
                  <StyledText>{option?.option || '-'}</StyledText>
                </div>
                <div>
                  <Label>{formatMessage({ id: 'text.weighting' })}</Label>
                  <StyledText>{option?.weight || '-'}</StyledText>
                </div>
              </OptionWrapper>
            </div>
          ))}
      </div>
      {editable && (
        <ManageButtonWrapper>
          <TFDropdownMenu
            buttonText={<span>{formatMessage({ id: 'text.manageQuestion' })}</span>}
            options={[
              {
                title: <span>{formatMessage({ id: 'text.edit' })}</span>,
                value: item?.id,
                onClick: handleEditQuestionClick,
                id: 'edit',
              },
              {
                title: <span>{formatMessage({ id: 'text.delete' })}</span>,
                value: item?.id,
                onClick: handleDeleteQuestionClick,
                deleteStyle: true,
                id: 'delete',
              },
            ]}
          />
        </ManageButtonWrapper>
      )}
    </Wrapper>
  );
};

export default FRATExpandedItemView;
