const config = {
  403: {
    img: '/images/antd/image403.svg',
    title: '403',
    desc: 'Something went wrong',
  },
  404: {
    img: '/images/antd/image404.svg',
    title: '404',
    desc: 'This URL does not exist',
  },
  500: {
    img: '/images/antd/image500.svg',
    title: '500',
    desc: 'Something went wrong',
  },
};

export default config;
