import { useMutation } from '@tanstack/react-query';
import { createNewFRAT } from '../../services/api';

const useAddNewFRAT = ({ handleAddFRATOnSuccess, handleAddFRATOnError }) => {
  const addNewFRAT = useMutation({
    mutationFn: (payload: { name: string; default: boolean; operator_id: string }) => {
      return createNewFRAT(payload);
    },
    onSuccess: (data) => {
      handleAddFRATOnSuccess(data);
    },
    onError: (err: any) => handleAddFRATOnError(err),
  });

  return addNewFRAT;
};

export default useAddNewFRAT;
