import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import FiltersGroup from './FiltersGroup';

const FiltersSidebarDraftModal = ({ aircraft, allItems, activeFilters, onFilterChange, onFiltersClear }) => {
  const { formatMessage } = useIntl();
  const availableFilters = () => {
    const statusFilters = [
      {
        title: formatMessage({ id: 'text.itemStatus' }),
        key: 'itemStatus',
        filters: [
          {
            title: formatMessage({ id: 'title.new' }),
            key: 'new',
            type: 'keyValue',
            number: allItems.filter((item) => item.draft && item.camp_diffs && item.camp_diffs.length === 0).length,
            value: activeFilters.itemStatus.includes('new'),
          },
          {
            title: formatMessage({ id: 'text.updatedItems' }),
            key: 'update',
            type: 'keyValue',
            number: allItems.filter((item) => !(item.draft && item.camp_diffs && item.camp_diffs.length === 0)).length,
            value: activeFilters.itemStatus.includes('update'),
          },
          {
            title: formatMessage({ id: 'text.removedItems' }),
            key: 'removed',
            type: 'keyValue',
            // Currently fixed to 0 as CORE doesn't support (yet) removed requirements
            number: 0,
            value: activeFilters.itemStatus.includes('removed'),
          },
        ],
      },
    ];
    const typeFilters = [
      {
        title: formatMessage({ id: 'text.itemType' }),
        key: 'itemType',
        filters: [
          {
            title: formatMessage({ id: 'text.package' }),
            key: 'pkg',
            type: 'keyValue',
            number: allItems.filter((item) => item.package).length,
            value: activeFilters.itemType.includes('pkg'),
          },
          {
            title: formatMessage({ id: 'tab.scheduled' }),
            key: 'scheduled',
            type: 'keyValue',
            number: allItems.filter((item) => item.mx_type && item.mx_type.includes('scheduled') && !item.package)
              .length,
            value: activeFilters.itemType.includes('scheduled'),
          },
          {
            title: formatMessage({ id: 'tab.outOfPhase' }),
            key: 'oop',
            type: 'keyValue',
            number: allItems.filter((item) => item.mx_type && item.mx_type.includes('oop') && !item.package).length,
            value: activeFilters.itemType.includes('oop'),
          },
          {
            title: formatMessage({ id: 'title.lifeLimitedParts' }),
            key: 'llp',
            type: 'keyValue',
            number: allItems.filter((item) => item.mx_type && item.mx_type.includes('llp') && !item.package).length,
            value: activeFilters.itemType.includes('llp'),
          },
          {
            title: formatMessage({ id: 'title.defect' }),
            key: 'def',
            type: 'keyValue',
            number: 0, // CORE doesn't return (yet) defects for MX List
            value: activeFilters.itemType.includes('defect'),
          },
        ],
      },
    ];

    const apuInstalled = aircraft ? aircraft.apu_installed : null;
    const engineCount = aircraft ? aircraft.aircraft_type.engine_count : null;
    const engineType = aircraft ? aircraft.aircraft_type.engine_type : null;
    const areaFilters = {
      title: formatMessage({ id: 'title.area' }),
      key: 'area',
      filters: [
        {
          title: formatMessage({ id: 'text.airframe' }),
          key: 'airframe',
          type: 'keyValue',
          value: activeFilters.area.includes('airframe'),
        },
        {
          title: formatMessage({ id: 'text.engine' }),
          type: 'parent',
          children: [],
        },
        {
          title: formatMessage({ id: 'form.button.propeller' }),
          type: 'parent',
          children: [],
        },
        {
          title: formatMessage({ id: 'text.apu' }),
          key: 'apu',
          type: 'keyValue',
          value: activeFilters.area.includes('apu'),
        },
      ],
    };

    if (!apuInstalled) {
      areaFilters.filters.splice(3, 1);
    }

    if (engineCount > 0) {
      for (let i = 1; i <= engineCount; i += 1) {
        areaFilters.filters[1].children.push({
          title: `${formatMessage({ id: 'text.engine' })} #${i}`,
          key: `engine_${i}`,
          type: 'keyValue',
          value: activeFilters.area.includes(`engine_${i}`),
        });
      }
    }

    if ((engineType === 'piston' || engineType === 'turboprop') && engineCount > 0) {
      for (let i = 1; i <= engineCount; i += 1) {
        areaFilters.filters[2].children.push({
          title: `${formatMessage({ id: 'form.button.propeller' })} #${i}`,
          key: `prop_${i}`,
          type: 'keyValue',
          value: activeFilters.area.includes(`prop_${i}`),
        });
      }
    }

    if (engineType === 'jet') {
      areaFilters.filters.splice(2, 1);
    }

    return [statusFilters, typeFilters, areaFilters].reduce((acc, val) => acc.concat(val), []);
  };
  return (
    <>
      {availableFilters().map((group) => (
        <FiltersGroup
          key={`group${group.key}`}
          group={group}
          onFilterChange={(filterKey, groupKey) => onFilterChange(filterKey, groupKey)}
          onFiltersClear={(groupName) => onFiltersClear(groupName)}
          data-test="filtersGroup"
        />
      ))}
    </>
  );
};

FiltersSidebarDraftModal.propTypes = {
  aircraft: PropTypes.object.isRequired,
  allItems: PropTypes.array.isRequired,
  activeFilters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
};

export default FiltersSidebarDraftModal;
