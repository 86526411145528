import {
  addMaintenance,
  approveMaintenance,
  queryDraftMaintenance,
  queryMaintenance,
  queryMaintenanceForWorkpacks,
  removeMaintenance,
  updateMultipleMxItemTolerance,
} from '../../services/api';
import { MaintenanceActionTypes } from '.';

export const getAllMxItems =
  ({ payload, skipDeleteAll, forceReplace }) =>
  async (dispatch): Promise<any> => {
    if (!skipDeleteAll) {
      dispatch({
        type: MaintenanceActionTypes.DELETE_ALL,
        payload: null,
      });
    }
    const response = await queryMaintenance(payload);
    if (forceReplace) {
      dispatch({
        type: MaintenanceActionTypes.REPLACE_ALL,
        payload: response.scheduled_mx_items,
      });
      dispatch({
        type: MaintenanceActionTypes.REPLACE_ALL_ACTIVE,
        payload: response.scheduled_mx_items,
      });
      dispatch({
        type: MaintenanceActionTypes.REPLACE_ALL_DRAFT,
        payload: response.draft_items,
      });
    } else {
      dispatch({
        type: MaintenanceActionTypes.SAVE_ALL,
        payload: response.scheduled_mx_items,
      });
      dispatch({
        type: MaintenanceActionTypes.SAVE_ALL_ACTIVE,
        payload: response.scheduled_mx_items,
      });
      return response;
    }
    return null;
  };

export const getAllMxItemsWorkpacks =
  ({ payload, skipDeleteAll }) =>
  async (dispatch): Promise<any> => {
    if (!skipDeleteAll) {
      dispatch({
        type: MaintenanceActionTypes.DELETE_ALL,
        payload: null,
      });
    }
    const response = await queryMaintenanceForWorkpacks(payload);
    if (response) {
      dispatch({
        type: MaintenanceActionTypes.SAVE_ALL,
        payload: response.body,
      });
      // dispatch({
      //   type: MaintenanceActionTypes.SAVE_ALL_ACTIVE,
      //   payload: response.active_items,
      // });
    }
    return null;
  };

export const getAllDraftMxItems =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryDraftMaintenance(payload);
    dispatch({
      type: MaintenanceActionTypes.SAVE_ALL_DRAFT,
      payload: response,
    });
  };

export const addMxItem =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await addMaintenance(payload);
    dispatch({
      type: MaintenanceActionTypes.SAVE,
      payload: response,
    });
    return null;
  };
export const addMxItemSkipAPI =
  ({ payload }) =>
  async (dispatch: (arg0: { type: string; payload: object }) => void): Promise<string> => {
    dispatch({
      type: MaintenanceActionTypes.SAVE,
      payload,
    });
    return null;
  };

export const removeMxItem =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await removeMaintenance(payload);
    await queryMaintenance(payload);
    dispatch({
      type: MaintenanceActionTypes.DELETE,
      payload,
    });
    return response;
  };

export const updateMxItem =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryMaintenance(payload);
    // the api currently returns all aircraft mx items for an update
    // we should change this
    const updatedRecord = response.find((i: { id: number }) => i.id === payload.id);
    dispatch({
      type: MaintenanceActionTypes.SAVE,
      payload: updatedRecord,
    });
    return null;
  };

export const updateMxItemSkipAPI =
  ({ payload }) =>
  async (dispatch: (arg0: { type: string; payload: object }) => void): Promise<string> => {
    dispatch({
      type: MaintenanceActionTypes.UPDATE_SINGLE_ACTIVE,
      payload,
    });
    return null;
  };

export const approveDraftMxItem =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    await approveMaintenance(payload);
    const response = await queryMaintenance(payload);
    dispatch({
      type: MaintenanceActionTypes.SAVE_ALL,
      payload: response,
    });
    return null;
  };
export const approveDraftMxItemSkipAPI =
  ({ payload }) =>
  async (dispatch) => {
    const response = await queryMaintenance(payload);
    dispatch({
      type: MaintenanceActionTypes.SAVE_ALL,
      payload: response,
    });
  };

export const updateScheduledMxItemsTolerances =
  (payload: { items: { id: string; attributes: { [key: string]: unknown } }[]; aircraft_id: string }) =>
  async (dispatch) => {
    const response = await updateMultipleMxItemTolerance(payload);
    dispatch({
      type: MaintenanceActionTypes.UPDATE_MULTIPLE_TOLERANCE,
      payload: response,
    });
  };
