import React from 'react';
import styled from 'styled-components';
import { Loading } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { IndividualFRAT } from '../../models/frats';
import TFDragAndDrop from '../../components/TFDragAndDrop/TFDragAndDrop';
import { Label } from '../../components/CommonStyledComponents/CommonStyledComponents';
import FRATExpandableTable from './FRATExpandableTable';

interface FRATTableProps {
  draggable: boolean;
  editable: boolean;
  searchValue: string;
  setItemOrder: (items: any[]) => void;
  data: IndividualFRAT;
  isLoading: boolean;
}

const HeaderRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ draggable }): string =>
    draggable ? '1fr 4fr 2fr 2fr 2fr 2fr 2fr' : '2fr 1fr 1fr 1fr 1fr 1fr'};
  padding: ${({ draggable }): string => (draggable ? '0 40px 8px' : '0 20px 8px')};
`;

const StyledLabel = styled(Label)`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.33px;
`;

const FRATTable: React.FC<FRATTableProps> = ({ draggable, editable, setItemOrder, searchValue, data, isLoading }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <HeaderRow data-testid="FRATTable--HeaderRow" draggable={draggable}>
        {draggable ? <div /> : null}
        <StyledLabel data-testid="FRATTable--ItemTitle">{formatMessage({ id: 'text.name' })}</StyledLabel>
        <StyledLabel data-testid="FRATTable--Options">{formatMessage({ id: 'text.options' })}</StyledLabel>
        <StyledLabel data-testid="FRATTable--Category">{formatMessage({ id: 'text.category' })}</StyledLabel>
        <StyledLabel data-testid="FRATTable--DateAdded">{formatMessage({ id: 'text.dateAdded' })}</StyledLabel>
        <StyledLabel data-testid="FRATTable--DateModified">{formatMessage({ id: 'text.dateModified' })}</StyledLabel>
      </HeaderRow>
      {isLoading ? (
        <Loading loading={isLoading} contain />
      ) : (
        <>
          {draggable ? (
            <TFDragAndDrop
              items={data?.flight_risk_assessment_question_categories}
              component="SubItemComponent"
              setItemOrder={setItemOrder}
            />
          ) : (
            <FRATExpandableTable
              editable={editable}
              items={data?.flight_risk_assessment_question_categories}
              searchValue={searchValue}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FRATTable;
