import config, { ConfigKey } from './config';

export default {
  get api(): string {
    return config.get(ConfigKey.API);
  },
  get cookieDomain(): string {
    return config.get(ConfigKey.COOKIEDOMAIN);
  },
  get googleApiKey(): string {
    return config.get(ConfigKey.GOOGLEAPIKEY);
  },
  get hashVersion(): string {
    return config.get(ConfigKey.HASHVERSION);
  },
  get pub(): string {
    return config.get(ConfigKey.PUB);
  },
  get secret(): string {
    return config.get(ConfigKey.SECRET);
  },
  get uid(): string {
    return config.get(ConfigKey.UID);
  },
};
