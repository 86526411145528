import { Button, DatePicker, Form, Input, message, Select, Upload } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Button as TFButton } from '@arcflight/tf-component-library';
import { connect } from 'react-redux';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import { fetchDocumentTypes } from '../../../models/documentTypes/actions';
import { add as addOrganisationDocument } from '../../../models/organisationDocuments/actions';
import { add as addAircraftDocument } from '../../../models/aircraftDocuments/actions';
import defaults from '../../../utils/defaults';
import deleteIcon from '../common/assets/delete.svg';
import editIcon from '../common/assets/edit.svg';
import NonStyledButton from '../../NonStyledButton/NonStyledButton';
import emptyStateUpload from '../../../assets/emptyState/empty-state-upload-file.svg';
import uploadCloudIcon from '../common/assets/upload-doc-icon.svg';
import { formatOptionText } from '../common/utilities';
import styles from './stylesheet.module.less';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Dragger } = Upload;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DocumentUploadModal extends Component {
  static propTypes = {
    documentTypes: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    aircraft: PropTypes.object,
    handleModalVisible: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['organisation', 'aircraft']).isRequired,
    intl: PropTypes.object.isRequired,
    operators: PropTypes.array.isRequired,
  };

  static defaultProps = {
    aircraft: null,
  };

  constructor(props) {
    super(props);
    const { operators } = this.props;
    this.state = {
      initialised: false,
      fileSource: null,
      fileReady: false,
      showDeleteButton: false,
      newFileName: null,
      acceptedFileString: '.jpg,.jpeg,.png,.pdf',
      documentType: ['.pdf', 'application/pdf'],
      operatorList: operators,
    };
  }

  componentDidMount() {
    this.configureModal();
  }

  componentDidUpdate = () => {
    if (this.props.documentTypes.documentTypes.length > 0 && !this.state.initialised) {
      this.onLoaded();
    }
  };

  onLoaded = () => {
    this.setState({ initialised: true });
  };

  getOperatorField() {
    const {
      aircraft,
      form: { getFieldDecorator },
      intl: { formatMessage },
    } = this.props;
    const { operatorList } = this.state;
    const dropdownField = (
      <div className={styles.inputContainerWrapper}>
        <Form.Item
          required={false}
          label={
            <div>
              <div>{formatMessage({ id: 'form.labels.operator' })}</div>
            </div>
          }
        >
          {getFieldDecorator('organisation_id', {
            rules: [
              {
                required: true,
              },
            ],
            initialValue: operatorList[0].id,
          })(
            <Select>
              {operatorList?.map((op) => (
                <Option key={op.id} value={op.id} data-test={`OperatorOption_${op.name}`}>
                  {formatOptionText(op.name)}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </div>
    );

    const fixedField = (operatorName) => (
      <div className={styles.inputContainerWrapper}>
        <div className={styles.operatorName} data-test="operatorName">
          <span>{formatMessage({ id: 'form.labels.operator' })}: </span>
          {operatorName}
        </div>
      </div>
    );
    if (operatorList.length === 1) {
      return fixedField(operatorList[0].name);
    }

    if (operatorList.length > 1) {
      if (aircraft) {
        const operatorName = operatorList.filter((operator) => operator.id === aircraft.operator_id);
        return fixedField(operatorName[0].name);
      }
      return dropdownField;
    }
    return null;
  }

  getModalForm = () => {
    const {
      intl: { formatMessage },
      form: { getFieldDecorator },
      documentTypes: { documentTypes },
      type,
      handleModalVisible,
    } = this.props;
    const { fileList, fileReady, acceptedFileString, documentType, uploading } = this.state;
    const dateFormat = this.props.userSettings ? this.props.userSettings.dateFormat : defaults.defaultDateFormat;

    const checkFileSize = (file) => Math.round(file.size / 1024 / 1024) > 20;

    return (
      <>
        <div className={styles.headerWrapper}>
          <div className={styles.title}>
            {type === 'organisation'
              ? formatMessage({ id: 'title.uploadOrganisationDocument' })
              : formatMessage({ id: 'title.uploadAircraftDocument' })}
          </div>
          <div className={styles.subTitle}>
            {fileReady ? formatMessage({ id: 'text.fileReadyInfo' }) : formatMessage({ id: 'text.dragAndDrop' })}
          </div>
        </div>
        <Form layout="vertical" colon={false} hideRequiredMark>
          <div className={styles.modalBodyWrapper}>
            <div className={styles.modalDraggerContainerWrapper}>
              <Form.Item required={false}>
                {getFieldDecorator('attachment', {
                  rules: [
                    {
                      required: true,
                      type: 'array',
                      transform: () => {
                        return fileList;
                      },
                      message: formatMessage({ id: 'form.labels.attachmentIsRequired' }),
                    },
                  ],
                })(
                  fileReady ? (
                    this.showFilePreview()
                  ) : (
                    <Dragger
                      accept={acceptedFileString}
                      onChange={this.handleFileChange}
                      multiple={false}
                      fileList={fileList}
                      listType={null}
                      beforeUpload={(file, list) => {
                        if (checkFileSize(file)) {
                          message.error(formatMessage({ id: 'message.melFileTooLarge' }));
                          return false;
                        }
                        if (file.type === documentType) {
                          this.setState({ fileSource: file });
                        } else {
                          this.getBase64(file);
                          this.setState({ fileList: list });
                        }
                        return false;
                      }}
                      showUploadList={false}
                    >
                      <div className={styles.flexibleDraggerWrapper}>{this.showFileUploader()}</div>
                    </Dragger>
                  ),
                )}
              </Form.Item>
            </div>
            <div className={styles.modalInputContainerWrapper}>
              <div className={styles.nonInputContainerWrapper}>
                <span>{formatMessage({ id: 'form.labels.documentInfoText' })}</span>
              </div>
              {this.getOperatorField()}
              <div className={styles.inputContainerWrapper}>
                <Form.Item
                  label={
                    <div>
                      <div>
                        {formatMessage({ id: 'form.labels.category' })}
                        <span className={styles.optionalText}> {formatMessage({ id: 'form.labels.optional' })}</span>
                      </div>
                    </div>
                  }
                >
                  {getFieldDecorator('category', {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                    initialValue: null,
                  })(
                    <Select className={styles.optionsDropdown} data-test="documents">
                      <Option key="notSelected" value={null}>
                        {formatMessage({ id: 'text.noCategory' })}
                      </Option>
                      {documentTypes?.map((category) => {
                        return (
                          <Option key={category} value={category}>
                            {formatOptionText(category)}
                          </Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              <div className={styles.inputContainerWrapper}>
                <Form.Item
                  label={
                    <div>
                      {formatMessage({ id: 'form.labels.validRange' })}
                      <span className={styles.optionalText}> {formatMessage({ id: 'form.labels.optional' })}</span>
                    </div>
                  }
                >
                  {getFieldDecorator('validRange', {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(<RangePicker format={dateFormat} separator="-" />)}
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
        <div className={styles.footerWrapper}>
          <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={this.handleFormValidation} disabled={uploading}>
            {formatMessage({ id: 'form.button.complete' })}
          </TFButton>
          <div className={styles.cancelButton}>
            <TFButton
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={() => handleModalVisible(false)}
            >
              {formatMessage({ id: 'text.cancel' })}
            </TFButton>
          </div>
        </div>
      </>
    );
  };

  getBase64 = (file) => {
    const {
      intl: { formatMessage },
    } = this.props;
    if (file.size === 0) {
      return message.error(formatMessage({ id: 'message.fileIsEmpty' }));
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(this.setState({ fileReady: true, fileSource: reader.result }));
      reader.onerror = (error) => reject(error);
    });
  };

  setEditFileName = (e, fileName) => {
    const {
      intl: { formatMessage },
    } = this.props;
    if (e) {
      const splitName = fileName.split('.');
      if (splitName.length > 1) {
        const fileExtension = splitName[splitName.length - 1];
        const tempFileName = splitName.filter((n, index) => {
          if (index < splitName.length - 1) {
            return n;
          }
          return false;
        });
        const jointName = tempFileName.join('.');
        this.setState({ editFileName: e, newFileName: jointName, fileExtension });
      } else {
        const { fileList } = this.state;
        message.warning(formatMessage({ id: 'message.unableToChangeFileName' }));
        this.setState({ editFileName: false, newFileName: fileList[0].name });
      }
    } else {
      const { fileList, newFileName, fileExtension } = this.state;
      const joinNameExt = newFileName.concat('.', fileExtension);
      this.setState({ editFileName: e, newFileName: joinNameExt || fileList[0].name });
    }
  };

  configureModal = () => {
    const { dispatch } = this.props;
    dispatch(fetchDocumentTypes());
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  handleFileNameChange = (value) => {
    this.setState({ newFileName: value });
  };

  handleRemoveFile = () => {
    const {
      form: { resetFields },
    } = this.props;
    this.setState(
      {
        fileList: [],
        showDeleteButton: false,
        fileReady: false,
        fileSource: null,
        editFileName: false,
        newFileName: null,
      },
      () => {
        resetFields();
      },
    );
  };

  showFilePreview = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { uploading, documentType, fileSource, editFileName, newFileName, fileList, showDeleteButton } = this.state;
    return (
      <>
        <div className={styles.filePreviewWrapper}>
          <div
            className={styles.filePreviewContainer}
            onMouseEnter={() => {
              this.setState({ showDeleteButton: true });
            }}
            onMouseLeave={() => {
              this.setState({ showDeleteButton: false });
            }}
          >
            <div className={styles.filePreviewContainerInner}>
              {showDeleteButton ? (
                <div className={styles.deleteDocumentContainer}>
                  <Button
                    onClick={() => {
                      if (!uploading) {
                        this.handleRemoveFile();
                      }
                    }}
                    className={styles.buttonItem}
                    type="ghost"
                  >
                    <div className={styles.buttonTextContainer}>
                      <img className={styles.deleteIcon} src={deleteIcon} alt="delete icon" />
                      <div className={styles.deleteText}>{formatMessage({ id: 'form.button.deleteDocument' })}</div>
                    </div>
                  </Button>
                </div>
              ) : null}
              <div className={styles.viewerContainer}>
                <NonStyledButton
                  className={styles.imageContainer}
                  onClick={() => {
                    this.handleRemoveFile();
                  }}
                >
                  {documentType.includes(fileList[0].type) ? (
                    <Document renderMode="svg" className={styles.pdfImage} file={fileSource}>
                      <Page width={252} pageNumber={1} />
                    </Document>
                  ) : (
                    <img className={styles.fileImage} src={fileSource} alt="uploadedFile" />
                  )}
                </NonStyledButton>
              </div>
            </div>
          </div>
          <div className={styles.fileNameWrapper}>
            <div className={styles.fileNameContainer}>
              {editFileName ? (
                <Input
                  autoFocus
                  onChange={(e) => {
                    this.handleFileNameChange(e.target.value);
                  }}
                  onBlur={() => {
                    this.setEditFileName(false);
                  }}
                  value={newFileName}
                  spellCheck="false"
                />
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    this.setEditFileName(true, newFileName || fileList[0].name);
                  }}
                  className={styles.fileNameNonEdit}
                >
                  <div>
                    <img className={styles.editIcon} src={editIcon} alt="edit icon" />
                  </div>
                  <div className={styles.editText}>{newFileName || fileList[0].name}</div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  handleFileToBlob = () => {
    const { fileSource, fileList } = this.state;
    const removedBase64 = fileSource.split('base64,');
    const byteCharacters = atob(removedBase64[1]);
    const byteArr = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteArr[i] = byteCharacters.charCodeAt(i);
    }
    const byteUint8Array = new Uint8Array(byteArr);
    const blob = new Blob([byteUint8Array], { type: fileList[0].type });
    return blob;
  };

  showFileUploader = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <div className={styles.draggerContent}>
          <div className={styles.schematicsImg}>
            <img src={emptyStateUpload} alt="schematicExamples" />
          </div>
        </div>
        <div className={styles.draggerContent}>
          <div className={styles.uploadImg}>
            <img src={uploadCloudIcon} alt="uploadCloudIcon" />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.textContent}>
              <span className={styles.fileTypes}>.PDF .JPG .PNG</span>
            </div>
            <div className={styles.textContent}>
              <span className={styles.uploadText}>{formatMessage({ id: 'text.youCanAlsoUpload' })}</span>
            </div>
            <div>
              <div className={styles.textContent}>
                <span className={styles.clickText}>{formatMessage({ id: 'text.clickingHere' })}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  handleFormValidation = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ uploading: true }, () => {
          this.handleFileUpload({
            ...values,
            attachment: this.state.fileList,
          });
        });
      }
    });
  };

  handleFileUpload = (values) => {
    const { fileList, newFileName, operatorList } = this.state;
    const { dispatch, aircraft, type } = this.props;
    const formData = new FormData();
    if (type === 'organisation' && operatorList.length === 1) {
      formData.append('organisation_id', operatorList[0].id);
    } else if (type === 'organisation') {
      formData.append('organisation_id', values.organisation_id);
    } else if (type === 'aircraft') {
      formData.append('aircraft_id', aircraft.id);
    }
    if (values.attachment && !newFileName) {
      const blob = this.handleFileToBlob();
      formData.append('attachment', blob, fileList[0].name);
    } else if (values.attachment && newFileName) {
      const blob = this.handleFileToBlob();
      formData.append('attachment', blob, newFileName);
    }
    if (values.category) {
      formData.append('category', values.category);
    }
    if (values.validRange) {
      const dateFormat = 'YYYY-MM-DD HH:mm:ss Z';
      formData.append('valid_from', moment.utc(values.validRange[0]).format(dateFormat));
      formData.append('valid_to', moment.utc(values.validRange[1]).format(dateFormat));
    }
    // commented out as initial line below trigger unexpected constant as conditional
    const action = type === 'organisation' ? addOrganisationDocument : addAircraftDocument;
    // const action = addOrganisationDocument;
    dispatch(
      action({
        payload: {
          form: formData,
        },
      }),
    );
  };

  render() {
    const { handleModalVisible, visible } = this.props;

    return (
      <>
        <Modal
          isOpen={visible}
          handleClose={() => {
            handleModalVisible(false);
          }}
        >
          <div className={styles.modalBody}>{this.getModalForm()}</div>
        </Modal>
      </>
    );
  }
}

const DocumentUploadModalWithForm = Form.create()(DocumentUploadModal);

const documentUploadModalWithRedux = connect(({ userSettings, documentTypes }) => ({
  documentTypes,
  userSettings,
  operators: userSettings?.details?.people?.map((person) => ({
    name: person.organisation.name,
    id: person.organisation.id,
  })),
}))(DocumentUploadModalWithForm);

export default injectIntl(documentUploadModalWithRedux);
