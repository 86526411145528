import React, { useEffect, useState } from 'react';
import { Button, Loading, Modal, Search } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import plusIcon from '../../assets/plus.svg';
import fratIcon from '../../assets/mainNav/icon-nav-frat.svg';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import TFDropdown from '../../components/TFDropdown/TFDropdown';
import TFDropdownMenu from '../../components/TFDropdownMenu/TFDropdownMenu';
import TFInput from '../../components/TFInput/TFInput';
import PageFooterActions from '../../components/PageFooterActions/PageFooterActions';
import backArrow from '../../assets/icon-back.svg';
import { DashboardState } from '../../models';
import { TechLogModule } from '../../models/userSettings';
import { IndividualFRAT } from '../../models/frats';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import AllFRATTable from './AllFRATTable';
import FRATTable from './FRATTable';
import FRATDrawer from './FRATDrawer';
import useGetFRATData from './useGetFRATData';
import useAddNewFRAT from './useAddNewFRAT';
import useGetIndividualFRATData from './useGetIndividualFRATData';
import useCreateFRATCategory from './useCreateFRATCategory';
import handleReorderingOfItems from './handleReorderingOfItems';
import useReorderFRAT from './useReorderFRAT';

const Wrapper = styled.div`
  border-radius: 2px;
  box-shadow: ${({ theme }): string => `0 0 16px 0 ${theme.colours.whiteShadow}`};
  border: ${({ theme }): string => `solid 2px ${theme.colours.white}`};
  background-color: ${({ theme }): string => theme.colors.zircon};
  padding: 28px;
  display: flex;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  margin-right: 12px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4px;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const OperatorSelectWrapper = styled.div`
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  span {
    margin-right: 4px;
  }
`;

const ReorderWrapper = styled.div`
  margin-right: 12px;
`;

const FooterActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-right: 20px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 4px;
`;

const ModalButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 20px;
`;

const OperatorFRAT: React.FC = () => {
  const {
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();

  const [availableOperators, setAvailableOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedFRAT, setSelectedFRAT] = useState<string>(null);
  const [editFRATLayout, setEditFRATLayout] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newFRATName, setNewFRATName] = useState('');
  const [modalVisible, setModalVisible] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [itemsOrder, setItemsOrder] = useState([]);
  const [initialData, setInitialData] = useState<IndividualFRAT | null>();

  const queryClient = useQueryClient();

  const handleAddFRATOnSuccess = (data) => {
    queryClient.invalidateQueries(['FRAT', selectedOperator?.value]);
    setModalVisible(null);
    setSelectedFRAT(data?.id);
  };

  const handleAddFRATOnError = (err: any) => {
    dispatch(
      addToast({
        payload: {
          title: 'FRAT creation error',
          message: err.message,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const addNewFRAT = useAddNewFRAT({ handleAddFRATOnSuccess, handleAddFRATOnError });

  const {
    FRATData,
    isLoading,
    names: currentFRATNames,
  } = useGetFRATData({
    selectedOperatorId: selectedOperator?.value,
    searchValue,
  });

  const { data: IndividualFRATData, categories: currentCategories } = useGetIndividualFRATData({
    drawerId: selectedFRAT,
  });

  const handleAddCategoryOnSuccess = (data) => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'edit' }));
    dispatch(setDrawerId({ payload: selectedFRAT }));
    dispatch(changeDrawerContent({ payload: { content: <FRATDrawer categoryId={data?.id} /> } }));
  };

  const handleAddCategoryOnError = (err: any) => {
    dispatch(
      addToast({
        payload: {
          title: 'FRAT category creation error',
          message: err.message,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const addFRATCategory = useCreateFRATCategory({ handleAddCategoryOnSuccess, handleAddCategoryOnError });

  const handleReorderOnSuccess = (data) => {
    queryClient.invalidateQueries(['individualFRAT', selectedFRAT]);
    setSelectedFRAT(data.id);
  };

  const handleReorderOnError = (err: any) => {
    dispatch(
      addToast({
        payload: {
          title: 'FRAT reorder error',
          message: err.message,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const reorderFrat = useReorderFRAT({ handleReorderOnSuccess, handleReorderOnError });

  const { formatMessage } = useIntl();

  const handleAddButtonClick = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'add' }));
    dispatch(setDrawerId({ payload: selectedFRAT }));
    if (selectedFRAT) {
      dispatch(changeDrawerContent({ payload: { content: <FRATDrawer /> } }));
    } else {
      setModalVisible('frat');
    }
  };

  const handleOperatorSelect = async (option: { title: string; value: string | null }): Promise<void> => {
    setSelectedOperator(option);
  };

  const handleFooterSaveClick = (): void => {
    const newOrder = handleReorderingOfItems({
      manipulatedCategories: itemsOrder,
      reoderKey: 'sequence_number',
      innerKey: 'flight_risk_assessment_questions',
      initialData,
    });
    const formattedOrder = newOrder.map((item) => {
      return {
        ...item,
        flight_risk_assessment_questions_attributes: item.flight_risk_assessment_questions,
      };
    });
    const payload = {
      flight_risk_assessment_question_categories_attributes: formattedOrder,
      id: selectedFRAT,
    };
    reorderFrat.mutate(payload);
    setEditFRATLayout(false);
  };

  const handleFooterCancelClick = (): void => {
    setEditFRATLayout(false);
  };

  const handleBackClick = (): void => {
    setSelectedFRAT(null);
    setEditFRATLayout(false);
  };

  const handleAddCategory = (): void => {
    if (!newCategoryName) {
      return;
    }
    let newSequenceNumber = 0;
    if (IndividualFRATData?.flight_risk_assessment_question_categories.length) {
      newSequenceNumber = IndividualFRATData?.flight_risk_assessment_question_categories.length + 1;
    }
    const payload = {
      category: newCategoryName,
      sequence_number: newSequenceNumber,
      id: IndividualFRATData?.id,
    };
    addFRATCategory.mutate(payload);
    setModalVisible(null);
    setNewCategoryName('');
  };

  const handleAddNewFRAT = (): void => {
    const payload = {
      name: newFRATName,
      default: false,
      operator_id: selectedOperator?.value,
    };
    addNewFRAT.mutate(payload);
    setModalVisible(null);
    setNewFRATName('');
  };

  const handleAddItem = (type: string): void => {
    if (type === 'question') {
      handleAddButtonClick();
    }
    if (type === 'category') {
      setModalVisible('category');
    }
  };

  const handleReorderClick = () => {
    setEditFRATLayout(true);
  };

  const footerActions = (
    <FooterActionsWrapper>
      <ButtonWrapper>
        <Button height="30px" onClick={handleFooterSaveClick}>
          {formatMessage({ id: 'text.save' })}
        </Button>
      </ButtonWrapper>
      <Button height="30px" onClick={handleFooterCancelClick} primary={false}>
        {formatMessage({ id: 'text.cancel' })}
      </Button>
    </FooterActionsWrapper>
  );

  useEffect(() => {
    if (operators) {
      const newOps = operators
        .filter((operator) =>
          operator.enabled_tech_log_modules.some((module) => module.module_name.toLowerCase() === TechLogModule.FRAT),
        )
        .map((operator) => {
          return {
            title: operator?.name,
            value: operator?.id,
          };
        });
      setAvailableOperators(newOps);
      setSelectedOperator(newOps[0]);
    }
  }, [operators]);

  useEffect(() => {
    if (!initialData) setInitialData(IndividualFRATData);
  }, [IndividualFRATData, initialData]);

  useEffect(() => {
    if (modalVisible === 'frat') {
      if (newFRATName && currentFRATNames?.includes(newFRATName)) {
        setErrorMessage('FRAT name already exists');
      } else {
        setErrorMessage('');
      }
    }
    if (modalVisible === 'category') {
      if (newCategoryName && currentCategories?.includes(newCategoryName)) {
        setErrorMessage('FRAT category name already exists');
      } else {
        setErrorMessage('');
      }
    }
  }, [currentCategories, currentFRATNames, modalVisible, newCategoryName, newFRATName]);
  let filteredData = FRATData;
  if (searchValue) {
    filteredData = FRATData.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
  }
  return (
    <PageHeaderWrapper icon={fratIcon} title={formatMessage({ id: 'text.flightRiskAssessmentTool' })}>
      <Wrapper>
        <TableWrapper>
          <PageHeader data-testid="OperatorFRAT--PageHeader">
            <TitleWrapper>
              {selectedFRAT && !editFRATLayout ? (
                <>
                  <StyledButton onClick={handleBackClick}>
                    <img src={backArrow} alt="back arrow" />
                  </StyledButton>
                  <span data-testid="OperatorFRAT--SelectedFRAT-Title">
                    {FRATData?.find((item) => item.id === selectedFRAT)?.name}
                  </span>
                </>
              ) : null}
              {!selectedFRAT && availableOperators.length > 1 && (
                <OperatorSelectWrapper data-testid="OperatorFRAT--OperatorSelectWrapper">
                  <span>{formatMessage({ id: 'text.operator' })}: </span>
                  <TFDropdown
                    options={availableOperators}
                    onSelect={handleOperatorSelect}
                    initialValue={selectedOperator}
                    id="OperatorDropdown"
                    label=""
                  />
                </OperatorSelectWrapper>
              )}
            </TitleWrapper>
            <HeaderActions>
              {!editFRATLayout && (
                <SearchWrapper data-testid="OperatorFRAT--SearchWrapper">
                  <Search
                    onChange={(e): void => setSearchValue(e.target.value)}
                    onClear={(): void => setSearchValue('')}
                  />
                </SearchWrapper>
              )}
              {selectedFRAT && IndividualFRATData?.editable && !editFRATLayout ? (
                <ReorderWrapper>
                  <Button height="30px" onClick={(): void => handleReorderClick()}>
                    {formatMessage({ id: 'text.reorder' })}
                  </Button>
                </ReorderWrapper>
              ) : null}
              {selectedFRAT && IndividualFRATData?.editable && !editFRATLayout ? (
                <TFDropdownMenu
                  buttonText={<span>{formatMessage({ id: 'text.addNewItem' })}</span>}
                  buttonPrimary
                  options={[
                    {
                      title: <span>{formatMessage({ id: 'text.question' })}</span>,
                      value: 'question',
                      onClick: (): void => handleAddItem('question'),
                      id: 'question',
                    },
                    {
                      title: <span>{formatMessage({ id: 'text.category' })}</span>,
                      value: 'category',
                      onClick: (): void => handleAddItem('category'),
                      id: 'category',
                    },
                  ]}
                />
              ) : null}
              {!selectedFRAT && (
                <Button height="30px" onClick={() => setModalVisible('frat')}>
                  <StyledIcon src={plusIcon} alt="plus" />
                  {formatMessage({ id: 'text.addNewFRAT' })}
                </Button>
              )}
            </HeaderActions>
          </PageHeader>
          {isLoading ? (
            <Loading loading={isLoading || addNewFRAT.isLoading} contain />
          ) : (
            <>
              {selectedFRAT && IndividualFRATData ? (
                <FRATTable
                  draggable={editFRATLayout}
                  editable={IndividualFRATData?.editable}
                  searchValue={searchValue}
                  setItemOrder={setItemsOrder}
                  data={IndividualFRATData}
                  isLoading={isLoading}
                />
              ) : (
                <AllFRATTable
                  setSelectedFRAT={setSelectedFRAT}
                  selectedOperator={selectedOperator}
                  FRATData={filteredData}
                />
              )}
            </>
          )}
        </TableWrapper>
      </Wrapper>
      {editFRATLayout ? <PageFooterActions>{footerActions}</PageFooterActions> : null}
      <Modal
        width={420}
        isOpen={!!modalVisible}
        handleClose={(): void => {
          setModalVisible(null);
          setNewFRATName('');
          setNewCategoryName('');
        }}
      >
        <ModalWrapper>
          <Title>
            {modalVisible === 'category'
              ? formatMessage({ id: 'text.newCategoryName' })
              : formatMessage({ id: 'text.addNewFRATName' })}
          </Title>
          <TFInput
            id="modalInput"
            onChange={(e): void =>
              modalVisible === 'category' ? setNewCategoryName(e.target.value) : setNewFRATName(e.target.value)
            }
            value={modalVisible === 'category' ? newCategoryName : newFRATName}
            status={errorMessage ? 'error' : undefined}
            statusMessage={errorMessage}
            autofocus
          />
          <ModalButtonWrapper>
            <SubmitButtonWrapper>
              <Button
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                disabled={errorMessage.length > 0}
                onClick={modalVisible === 'category' ? handleAddCategory : handleAddNewFRAT}
              >
                {formatMessage({ id: 'text.save' })}
              </Button>
            </SubmitButtonWrapper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => {
                setNewFRATName('');
                setNewCategoryName('');
                setModalVisible(null);
              }}
            >
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>
    </PageHeaderWrapper>
  );
};

export default OperatorFRAT;
