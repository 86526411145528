import { Accordion, Button } from '@arcflight/tf-component-library';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import airframeIcon from '../../assets/icon-card-aircraft.svg';
import minusIcon from '../../assets/minus.svg';
import TimeInput from '../Inputs/TimeInput/TimeInput';

interface TripUpdateAirframeSectionProps {
  trip: any;
  originalTripUpdateData: any;
  updateTripUpdateData: (changes: { value: any; key: string }[]) => void;
}

export const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1.33;
  font-size: 14px;
  text-transform: capitalize;
  img {
    margin-right: 8px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
`;

export const PartsDiv = styled.div`
  margin-right: 20px;
  font-size: 12px;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, columns }): string => {
    let cols = '1fr 1fr';
    if (columns) cols = columns;
    if (isMobile) cols = '1fr';
    return cols;
  }};
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
  padding: ${({ isMobile, padding }): string => {
    let newPadding = '16px 0 20px 56px';
    if (padding) newPadding = padding;
    if (isMobile) newPadding = '16px 0';
    return newPadding;
  }};
  border: ${({ noBorder }): string => (noBorder ? '' : 'solid 2px #fff')};
  background-color: rgba(255, 255, 255, 0);
  @media (max-width: 451px) {
    padding-left: ${({ paddingLeft }): string => (paddingLeft ? `${paddingLeft}px` : '')};
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
`;

export const ItemWrapper = styled.div`
  margin-right: 20px;
  min-width: 120px;
`;

export const Header = styled.div`
  color: ${({ editable }): string => (editable ? '#242d41' : 'rgba(36, 45, 65, 0.7)')};
  letter-spacing: ${({ editable }): string => (editable ? 'normal' : '0.4px')};
  font-size: ${({ editable }): string => (editable ? '14px' : '12px')};
  text-transform: ${({ editable }): string => (editable ? 'capitalize' : 'uppercase')};
  font-weight: ${({ editable }): string => (editable ? '500' : 'normal')};
  margin-bottom: 4px;
`;

export const DisplayText = styled.div`
  color: rgba(36, 45, 65, 0.9);
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: ${({ cap }): string => (cap ? 'capitalize' : 'none')};
`;

export const StyledInput = styled.input`
  width: ${({ width }): string => (width ? `${width}` : '120px')};
  height: 40px;
  background-color: #f6f8fb;
  border-radius: 2px;
  border: 1px solid rgba(36, 45, 65, 0.05);
  padding: 0 8px;
`;

export const UnitWrapper = styled.div`
  display: flex;
  align-items: ${({ flexStart }): string => (flexStart ? 'flex-start' : 'center')};
`;

export const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

export const UnderInputText = styled.div`
  font-size: 12px;
  color: ${({ view }): string => (view ? 'rgba(36, 45, 65, 0.5)' : 'rgba(36, 45, 65, 0.7)')};
  margin-top: 4px;
`;

export const MinusWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

export const Divider = styled.div`
  grid-column: span 4;
  height: 1px;
  width: calc(100% - 20px);
  background-color: rgba(36, 45, 65, 0.05);
  @media (max-width: 451px) {
    grid-column: span 1;
  }
`;

export const ClearButtonWrapper = styled.div``;

export const formatTime = (input: number): string => {
  if (input) {
    const hours = Math.floor(input / 3600);
    let mins = Math.floor((input % 3600) / 60).toString();
    if (mins.length === 1) {
      mins = `0${mins}`;
    }
    return `${hours}:${mins}`;
  }
  return '0';
};

const TripUpdateAirframeSection: React.FC<TripUpdateAirframeSectionProps> = ({
  trip,
  updateTripUpdateData,
  originalTripUpdateData,
}) => {
  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const [amendedOverrideKeys, setAmendedOverrideKeys] = useState([]);
  const [expand, setExpand] = useState(false);

  const isMobile = window.innerWidth < 451;

  const handleInputChange = (value: any, key: string): void => {
    const newValue = {
      ...trip?.utilisation,
      [`${key}`]: value,
    };
    updateTripUpdateData([{ value: newValue, key: 'utilisation' }]);
  };

  const handleCarriedInputChange = (value: any, key: string): void => {
    const newValue = {
      ...trip?.carried_forwards,
      [`${key}`]: value,
    };
    amendedOverrideKeys.push(key);
    setAmendedOverrideKeys(amendedOverrideKeys);
    updateTripUpdateData([
      { value: newValue, key: 'carried_forwards_override' },
      { value: newValue, key: 'carried_forwards' },
    ]);
  };

  const handleClearOverrideClick = (key: string): void => {
    let newValue = {
      ...trip?.carried_forwards_override,
      [`${key}`]: null,
    };
    const index = amendedOverrideKeys.indexOf(key);
    amendedOverrideKeys.splice(index, 1);
    setAmendedOverrideKeys(amendedOverrideKeys);
    if (
      Object.keys(originalTripUpdateData?.carried_forwards_override).length === 0 &&
      amendedOverrideKeys.length === 0
    ) {
      newValue = {};
    }
    const newCarriedValue = {
      ...trip?.carried_forwards,
      [`${key}`]: originalTripUpdateData?.carried_forwards[`${key}`],
    };
    updateTripUpdateData([
      { value: newValue, key: 'carried_forwards_override' },
      { value: newCarriedValue, key: 'carried_forwards' },
    ]);
  };

  let displayHours = trip?.carried_forwards?.airframe_seconds;
  let calculatedHours = trip?.brought_forwards?.airframe_seconds + trip?.utilisation?.airframe_seconds;
  if (originalTripUpdateData?.utilisation?.airframe_seconds !== trip?.utilisation?.airframe_seconds) {
    calculatedHours =
      trip?.utilisation?.airframe_seconds -
      originalTripUpdateData?.utilisation?.airframe_seconds +
      trip?.carried_forwards?.airframe_seconds;
    displayHours = calculatedHours;
  }
  if (trip?.carried_forwards_override?.airframe_seconds) {
    displayHours = trip?.carried_forwards_override?.airframe_seconds;
  }

  let displayLandings = trip?.carried_forwards?.airframe_landings;
  let calculatedLandings = trip?.brought_forwards?.airframe_landings + trip?.utilisation?.airframe_landings;
  if (originalTripUpdateData?.utilisation?.airframe_landings !== trip?.utilisation?.airframe_landings) {
    calculatedLandings =
      trip?.utilisation?.airframe_landings -
      originalTripUpdateData?.utilisation?.airframe_landings +
      trip?.carried_forwards?.airframe_landings;
    displayLandings = calculatedLandings;
  }
  if (trip?.carried_forwards_override?.airframe_landings) {
    displayLandings = trip?.carried_forwards_override?.airframe_landings;
  }

  let displayCycles = trip?.carried_forwards?.airframe_cycles;
  let calculatedCycles = trip?.brought_forwards?.airframe_cycles + trip?.utilisation?.airframe_cycles;
  if (originalTripUpdateData?.utilisation?.airframe_cycles !== trip?.utilisation?.airframe_cycles) {
    calculatedCycles =
      trip?.utilisation?.airframe_cycles -
      originalTripUpdateData?.utilisation?.airframe_cycles +
      trip?.carried_forwards?.airframe_cycles;
    displayCycles = calculatedCycles;
  }
  if (trip?.carried_forwards_override?.airframe_cycles) {
    displayCycles = trip?.carried_forwards_override?.airframe_cycles;
  }

  return (
    <Accordion
      isExpanded={mode === 'add' || mode === 'edit' || mode === 'override' || (mode === 'view' && expand)}
      headerContent={
        <RowHeader data-testid="TripUpdateAirframeSection-AccordionHeader">
          <TitleWrapper>
            <img src={airframeIcon} alt="airframe icon" />
            Airframe
          </TitleWrapper>
          <PartsDiv>6 parts</PartsDiv>
        </RowHeader>
      }
      dash
      onExpansionChange={(e): void => {
        if (e) {
          setExpand(true);
        } else {
          setExpand(false);
        }
      }}
      data-testid="TripUpdateAirframeSection-Accordion"
    >
      <ContentWrapper isMobile={isMobile} paddingLeft={20}>
        <InnerWrapper>
          <ItemWrapper data-testid="TripUpdateAirframeSection-FlightHours">
            <Header editable={mode !== 'view'}>Flight hours</Header>
            <UnitWrapper flexStart={mode === 'edit'}>
              {mode === 'view' || mode === 'override' ? (
                <DisplayText>{formatTime(trip?.utilisation?.airframe_seconds)}</DisplayText>
              ) : (
                <div>
                  <TimeInput
                    input={trip?.utilisation?.airframe_seconds ? trip?.utilisation?.airframe_seconds : undefined}
                    onChange={(value): void => handleInputChange(value, 'airframe_seconds')}
                    tripUpdate
                  />
                  <UnderInputText>
                    {`Initial: ${formatTime(originalTripUpdateData?.utilisation?.airframe_seconds)}`}
                  </UnderInputText>
                </div>
              )}
              <StyledUnit edit={mode === 'edit' || mode === 'add'}>hrs</StyledUnit>
            </UnitWrapper>
          </ItemWrapper>
          <div>
            <Header editable={mode === 'override'}>Carried Forward</Header>
            <UnitWrapper flexStart={trip?.carried_forwards_override?.airframe_seconds !== calculatedHours}>
              {mode !== 'override' ? (
                <div>
                  <DisplayText>{formatTime(displayHours)}</DisplayText>
                  {trip?.carried_forwards_override?.airframe_seconds !== undefined &&
                  trip?.carried_forwards_override?.airframe_seconds !== calculatedHours ? (
                    <UnderInputText view>{`Calculated: ${formatTime(calculatedHours)}`}</UnderInputText>
                  ) : null}
                </div>
              ) : (
                <div>
                  <TimeInput
                    input={displayHours || undefined}
                    onChange={(value): void => handleCarriedInputChange(value, 'airframe_seconds')}
                    tripUpdate
                  />
                  <UnderInputText>
                    {`Calculated: ${formatTime(Math.round((calculatedHours / 3600) * 100) / 100 || 0)}`}
                  </UnderInputText>
                </div>
              )}
              <StyledUnit edit={mode === 'override'}>hrs</StyledUnit>
            </UnitWrapper>
          </div>
        </InnerWrapper>
        {mode === 'override' ? (
          <>
            {amendedOverrideKeys.includes('airframe_seconds') ? (
              <ClearButtonWrapper>
                <Button
                  height="24px"
                  primary={false}
                  onClick={(): void => handleClearOverrideClick('airframe_seconds')}
                >
                  <MinusWrapper>
                    <img src={minusIcon} alt="minus" /> Clear override
                  </MinusWrapper>
                </Button>
              </ClearButtonWrapper>
            ) : null}
          </>
        ) : null}
        {mode === 'override' ? <Divider /> : null}
        <InnerWrapper>
          <ItemWrapper>
            <Header editable={mode !== 'view'}>No. of Landings</Header>
            {mode === 'view' || mode === 'override' ? (
              <DisplayText>{trip?.utilisation?.airframe_landings}</DisplayText>
            ) : (
              <div>
                <StyledInput
                  placeholder="-"
                  value={trip?.utilisation?.airframe_landings || undefined}
                  onChange={(e): void => handleInputChange(parseInt(e.target.value, 10), 'airframe_landings')}
                  type="number"
                />
                <UnderInputText>
                  {`Initial: ${originalTripUpdateData?.utilisation?.airframe_landings || 0}`}
                </UnderInputText>
              </div>
            )}
          </ItemWrapper>
          <div>
            <Header editable={mode === 'override'}>Carried Forward</Header>
            {mode !== 'override' ? (
              <DisplayText>{displayLandings}</DisplayText>
            ) : (
              <div>
                <StyledInput
                  placeholder="-"
                  value={displayLandings || undefined}
                  onChange={(e): void => handleCarriedInputChange(parseInt(e.target.value, 10), 'airframe_landings')}
                  type="number"
                />
                <UnderInputText>{`Calculated: ${calculatedLandings || 0}`}</UnderInputText>
              </div>
            )}
          </div>
        </InnerWrapper>
        {mode === 'override' ? (
          <>
            {amendedOverrideKeys.includes('airframe_landings') ? (
              <ClearButtonWrapper>
                <Button
                  height="24px"
                  primary={false}
                  onClick={(): void => handleClearOverrideClick('airframe_landings')}
                >
                  <MinusWrapper>
                    <img src={minusIcon} alt="minus" /> Clear override
                  </MinusWrapper>
                </Button>
              </ClearButtonWrapper>
            ) : (
              <div />
            )}
          </>
        ) : null}
        {mode === 'override' ? <Divider /> : null}
        <InnerWrapper>
          <ItemWrapper>
            <Header editable={mode !== 'view'}>Flight Cycles</Header>
            {mode === 'view' || mode === 'override' ? (
              <DisplayText>{trip?.utilisation?.airframe_cycles}</DisplayText>
            ) : (
              <div>
                <StyledInput
                  placeholder="-"
                  value={trip?.utilisation?.airframe_cycles || undefined}
                  onChange={(e): void => handleInputChange(parseInt(e.target.value, 10), 'airframe_cycles')}
                  type="number"
                />
                <UnderInputText>
                  {`Initial: ${originalTripUpdateData?.utilisation?.airframe_cycles || 0}`}
                </UnderInputText>
              </div>
            )}
          </ItemWrapper>
          <div>
            <Header editable={mode === 'override'}>Carried Forward</Header>
            {mode !== 'override' ? (
              <DisplayText>{displayCycles}</DisplayText>
            ) : (
              <div>
                <StyledInput
                  placeholder="-"
                  value={displayCycles || undefined}
                  onChange={(e): void => handleCarriedInputChange(parseInt(e.target.value, 10), 'airframe_cycles')}
                  type="number"
                />
                <UnderInputText>{`Calculated: ${calculatedCycles || 0}`}</UnderInputText>
              </div>
            )}
          </div>
        </InnerWrapper>
        {mode === 'override' ? (
          <>
            {amendedOverrideKeys.includes('airframe_cycles') ? (
              <ClearButtonWrapper>
                <Button height="24px" primary={false} onClick={(): void => handleClearOverrideClick('airframe_cycles')}>
                  <MinusWrapper>
                    <img src={minusIcon} alt="minus" /> Clear override
                  </MinusWrapper>
                </Button>
              </ClearButtonWrapper>
            ) : (
              <div />
            )}
          </>
        ) : null}
      </ContentWrapper>
    </Accordion>
  );
};

export default TripUpdateAirframeSection;
