import React, { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Badge } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import greenTick from '../../../assets/complete-icon.svg';
import shareIcon from '../../../assets/link-share.svg';
import StatusBadge from '../../Status/StatusBadge';
import styles from '../workpackDrawer.module.less';
import TFPopover from '../../TFPopover/TFPopover';
import { DashboardState } from '../../../models';
import { AircraftAuthenticationWrapper } from '../../_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  @media (max-width: 451px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const ItemWrapper = styled.div`
  margin-right: ${({ noMargin }): string => (noMargin ? '' : '20px')};
  display: flex;
  flex-direction: column;
  @media (max-width: 451px) {
    margin-bottom: 16px;
    margin-right: 32px;
  }
`;

const Heading = styled.span`
  color: rgba(36, 45, 65, 0.4);
  font-size: 12px;
  text-transform: uppercase;
`;

const StatusDiv = styled.span`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
  color: #646464;
  span {
    margin-left: 4px;
  }
`;

const WorkpackInfo = ({ dateCreated, status, dateCompleted, publicLink }): JSX.Element => {
  const [popover, setPopover] = useState(false);

  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();

  const {
    userSettings: {
      dateFormat,
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const handlePopupClick = (): void => {
    setPopover(true);
    const url = publicLink;
    const el = document.createElement('textarea');
    el.value = url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handlePopoverClose = (): void => {
    setPopover(false);
  };

  let badgeValue = '';
  if (status === 'draft') badgeValue = 'grey';
  if (status === 'active') badgeValue = 'blue';
  if (status === 'pending') badgeValue = 'amber';

  return (
    <>
      <TitleWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.crsAcceptance' }, { field: operatorSettings?.crs_short_name })}</Heading>
          <StatusDiv>
            <StatusBadge status={status} /> {status}
          </StatusDiv>
        </ItemWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.engineeringWork' })}</Heading>
          <StatusDiv>
            <Badge color={status === 'pending' ? '' : badgeValue} />{' '}
            <span>{status === 'pending' ? 'completed' : status}</span>
          </StatusDiv>
        </ItemWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.dateCreated' })}</Heading>
          <StatusDiv>{moment(dateCreated, 'YYYY-MM-DD').format(dateFormat)}</StatusDiv>
        </ItemWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.dateCompleted' })}</Heading>
          <StatusDiv>
            {(dateCompleted && moment(dateCompleted.replace(/T.*/gm, ''), 'YYYY-MM-DD').format(dateFormat)) || '-'}
          </StatusDiv>
        </ItemWrapper>
        {status !== 'draft' ? (
          <AircraftAuthenticationWrapper
            aircraftId={id}
            requiredResource={AircraftResource.WORKPACK}
            requiredPermissionLevel={AircraftPermission.CREATE}
          >
            <ItemWrapper>
              <button type="button" onClick={handlePopupClick} id="publicLink" className={styles.publicLinkWrapper}>
                <img src={shareIcon} alt="" /> {formatMessage({ id: 'text.publicShareLink' })}
              </button>
            </ItemWrapper>
          </AircraftAuthenticationWrapper>
        ) : null}
      </TitleWrapper>
      {popover ? (
        <TFPopover right="36px" top="143px" onClick={handlePopoverClose}>
          <div className={styles.popoverContainer}>
            <div className={styles.imageContainer}>
              <img src={greenTick} alt="Green Tick" />
            </div>
            <div className={styles.clipboardText}>{formatMessage({ id: 'text.copiedToClipboard' })}</div>
            <div className={styles.linkText}>{publicLink}</div>
            <div className={styles.popoverText}>{formatMessage({ id: 'text.validFor30daysWorkpack' })}</div>
          </div>
        </TFPopover>
      ) : null}
    </>
  );
};

export default WorkpackInfo;
