import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import TFInput, { Status, StatusIconMap } from '../TFInput/TFInput';
import TFFormLabel from '../TFFormLabel/TFFormLabel';
import chevron from '../../assets/icon-dropdown-arrow-down.svg';
import SearchIcon from '../../assets/icon-search.svg';
import closeIcon from '../../assets/cancel-blue.svg';

const DropdownInputWrapper = styled.div`
  height: auto;
  padding: 8px 32px 8px 8px;
  border-radius: 2px;
  border: ${({ theme }): string => `solid 1px ${theme.colours.black10Alpha}`};
  background-color: ${({ theme }): string => theme.colours.softGray};
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.4px;
  color: ${({ theme }): string => theme.colours.black90Alpha};
  outline: none;
  width: 100%;
  &:hover {
    border-color: ${({ theme }): string => theme.colours.brandBlue40Alpha};
  }
  &:focus {
    border-color: ${({ theme }): string => theme.colours.blue};
  }
  &.error {
    border-color: ${({ theme }): string => theme.colours.red};
    background-color: ${({ theme }): string => theme.colours.red02Alpha};
    padding-right: 52px;
    &:hover {
      background-color: ${({ theme }): string => theme.colours.red07Alpha};
    }
  }
  &.valid {
    border-color: ${({ theme }): string => theme.colours.black50Alpha};
    padding-right: 52px;
  }
  &.warning {
    border-color: ${({ theme }): string => theme.colours.orange};
    background-color: ${({ theme }): string => theme.colours.orange02Alpha};
    padding-right: 52px;
    &:hover {
      background-color: ${({ theme }): string => theme.colours.orange07Alpha};
    }
  }
  /* Additional styles for disabled prop */
  ${({ disabled, theme }: { disabled?: boolean; theme: any }): string =>
    disabled &&
    `
    cursor: not-allowed;
    background-color: ${theme.colours.black05Alpha};
    color: ${theme.colours.black70Alpha};
    border-color: ${theme.colours.black20Alpha};
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
  /* Additional styles for readOnly prop */
  ${({ readOnly, theme }: { readOnly?: boolean; theme: any }): string =>
    readOnly &&
    `
    border: none;
    background-color: unset;
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
    &:focus {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
`;

const DropdownWrapper = styled.div`
  flex: 1;
`;

const DropdownOptions = styled.div`
  position: absolute;
  top: ${({ height }): string => (height ? `${height}` : '40px')};
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  background-color: ${({ theme }): string => theme.colours.snow};
  border: ${({ theme }): string => `solid 2px ${theme.colours.white}`};
  padding: 2px;
  border-radius: 4px;
  box-shadow: ${({ theme }): string => `0 0 10px 0 ${theme.colours.black10Alpha}`};
`;

const OptionsWrapper = styled.div`
  min-height: 24px;
  width: 100%;
  border-radius: 2px;
  background-color: #f3f7fc;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 4px;
`;

const DropdownOption = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }): string => theme.colours.softGray};
    color: ${({ theme }): string => theme.colours.blue};
  }
  ${({ selected, theme }: { selected?: boolean; theme: any }): string =>
    selected &&
    `
    background-color: ${theme.colours.blue};
    color: ${theme.colours.white};
  `}
`;

const InputFeedback = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: -0.4px;
  &.error {
    color: ${({ theme }): string => theme.colours.red};
  }
  &.valid {
    color: ${({ theme }): string => theme.colours.green};
  }
  &.warning {
    color: ${({ theme }): string => theme.colours.orange};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 36px;
  transform: translateY(-50%);
`;

const ChevronContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 8px;
  transform: translateY(-50%);
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

const ChevronIcon = styled.img`
  transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : '')};
`;

const SelectedOption = styled.div`
  width: fit-content;
  border: 1px solid rgba(36, 45, 65, 0.1);
  margin-right: 4px;
  background-color: #fff;
  flex-shrink: 0;
  padding: 4px;
  display: flex;
`;

const CloseButton = styled.button`
  border: none;
  padding: 0;
  padding-left: 8px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 12px;
  }
`;

const AddOptionWrapper = styled.div`
  padding: 8px;
`;

const AddOptionButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }): string => theme.colours.blue};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

interface TFAddRemoveDropdownProps {
  options: { title: string; value: string }[];
  onSelect: (value: { title: string; value: string | null }) => void;
  id: string;
  label: string;
  initialValues?: { title: string; value: string }[];
  disabled?: boolean;
  readOnly?: boolean;
  status?: Status;
  statusMessage?: string;
  optional?: boolean;
  onSearch?: (value) => void;
  placeholder?: string;
  tooltip?: string;
  onClose?: () => void;
  onAddRemove?: (value: { title: string; value: string | null }) => void;
}

const TFAddRemoveDropdown: React.FC<TFAddRemoveDropdownProps> = ({
  options,
  onSelect,
  id,
  label,
  initialValues,
  disabled,
  readOnly,
  status,
  statusMessage,
  optional,
  onSearch,
  placeholder,
  tooltip,
  onClose,
  onAddRemove,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputHeight, setInputHeight] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState<{ title: string; value: string | null }[]>(
    initialValues?.length
      ? initialValues
      : [
          {
            title: placeholder || 'None',
            value: null,
          },
        ],
  );

  const { formatMessage } = useIntl();

  function useOutsideAlerter(ref): void {
    useEffect(() => {
      function handleClickOutside(event): void {
        if (ref.current && !ref.current.contains(event.target)) {
          // Below code checks if users click is on the same dropdown container
          // In this case we dont want to close it or else the onClick function
          // which is called at the same time would set it back to open.
          // If click is anywhere else on screen then setIsOpen to false to close.
          const clickedTestId = event.target.dataset.testid;
          if (clickedTestId === `TFAddRemoveDropdown-Container-${id}`) {
            return;
          }
          if (onSearch) {
            onClose();
          }
          setSearchTerm('');
          setIsOpen(false);
          setFilteredOptions(options);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return (): void => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleSelectOption = (option: { title: string; value: string | null }): void => {
    onSelect(option);
  };

  const displaySelectedOptions = (passedOptions): ReactElement => {
    return passedOptions?.map((option) => {
      return (
        <SelectedOption key={option.value} data-testid={`TFAddRemoveDropdown-Selected-Option-${option.value}-${id}`}>
          {option.title}
          <CloseButton
            onClick={(e): void => {
              e.stopPropagation();
              onSelect(option);
            }}
            data-testid={`TFAddRemoveDropdown-Remove-Selected-Option-${option.value}-${id}`}
          >
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </SelectedOption>
      );
    });
  };

  useEffect(() => {
    if (options) setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (initialValues) {
      setSelectedOptions(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (inputRef.current) {
      const { height } = inputRef.current.getBoundingClientRect();
      const adjustedHeight = height;
      setInputHeight(`${adjustedHeight}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, selectedOptions]);

  return (
    <DropdownWrapper>
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <InputContainer>
        <DropdownInputWrapper
          data-testid={`TFAddRemoveDropdown-Input-Wrapper-${id}`}
          readOnly={readOnly}
          disabled={disabled}
          className={status}
          ref={inputRef}
          role="button"
          tabIndex={0}
          onClick={
            disabled || readOnly
              ? undefined
              : (): void => {
                  setIsOpen(!isOpen);
                  setSearchTerm('');
                  setFilteredOptions(options);
                }
          }
          onKeyDown={(event): void => {
            if (event.key === 'Enter') {
              setIsOpen(!isOpen);
              setSearchTerm('');
              setFilteredOptions(options);
            }
          }}
        >
          <OptionsWrapper data-testid={`TFAddRemoveDropdown-Container-${id}`} className={status}>
            {selectedOptions?.length ? displaySelectedOptions(selectedOptions) : 'None'}
          </OptionsWrapper>
          {status && (
            <IconContainer>
              <img data-testid={`TFAddRemoveDropdown-Icon-${id}`} src={StatusIconMap[status]} alt="info icon" />
            </IconContainer>
          )}
          {((!selectedOptions.length && !readOnly) || (!readOnly && selectedOptions.length)) && (
            <ChevronContainer>
              <ChevronIcon
                data-testid={`TFAddRemoveDropdown-Chevron-${id}`}
                src={chevron}
                isOpen={isOpen}
                alt="dropdown icon"
              />
            </ChevronContainer>
          )}
        </DropdownInputWrapper>
        {isOpen && (
          <DropdownOptions ref={wrapperRef} height={inputHeight} data-testid={`TFAddRemoveDropdown-Options-${id}`}>
            <DropdownOption>
              <TFInput
                id={id}
                placeholder="Search cases..."
                onChange={(e): void => {
                  setSearchTerm(e.target.value);
                  if (onSearch) {
                    onSearch(e.target.value);
                  } else {
                    setFilteredOptions(
                      options.filter((option) => option.title.toLowerCase().includes(e.target.value.toLowerCase())),
                    );
                  }
                }}
                value={searchTerm}
                leftIcon={SearchIcon}
                rounded
              />
            </DropdownOption>
            {filteredOptions.map((option) => (
              <DropdownOption
                key={option.value}
                data-testid={`TFAddRemoveDropdown-Option-${option.value}-${id}`}
                selected={selectedOptions?.some((op) => op.value === option.value)}
                onClick={(): void => handleSelectOption(option)}
              >
                {option.title}
              </DropdownOption>
            ))}
            {searchTerm && filteredOptions.length === 0 && (
              <AddOptionWrapper data-testid={`TFAddRemoveDropdown-AddOption-${id}`}>
                <span>
                  {`"${searchTerm}" does not exist.`}{' '}
                  {onAddRemove ? (
                    <AddOptionButton
                      onClick={(): void => {
                        onAddRemove({ title: searchTerm, value: searchTerm });
                        setSearchTerm('');
                      }}
                      data-testid={`TFAddRemoveDropdown-AddOptionButton-${id}`}
                    >
                      {formatMessage({ id: 'text.clickToAdd' })}
                    </AddOptionButton>
                  ) : null}
                </span>
              </AddOptionWrapper>
            )}
          </DropdownOptions>
        )}
      </InputContainer>

      {!isOpen && statusMessage && (
        <InputFeedback data-testid={`TFAddRemoveDropdown-Feedback-${id}`} className={status}>
          {statusMessage}
        </InputFeedback>
      )}
    </DropdownWrapper>
  );
};

export default TFAddRemoveDropdown;
