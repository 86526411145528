import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import TFNumberInput from '../TFNumberInput/TFNumberInput';
import timeUtils from '../../utils/timeUtils';

interface TFTimePickerProps {
  initial: number;
  setValue: (value: number) => void;
  removeHoursRestriction?: boolean;
  id?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  span {
    margin: 0 8px 0 4px;
  }
`;

const TFTimePicker: React.FC<TFTimePickerProps> = ({ initial, setValue, removeHoursRestriction, id = '' }) => {
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);

  const { formatMessage } = useIntl();

  const handleHoursChange = (value: number): void => {
    if (!removeHoursRestriction && (value > 23 || value < 0)) {
      return null;
    }
    setHours(value);
    const newHours = value || 0;
    const newMinutes = minutes || 0;
    const newTime = timeUtils.getTotalSecondsFromHoursAndMinutes(newHours, newMinutes);
    setValue(newTime);
    return null;
  };

  const handleMinutesChange = (value: number): void => {
    if (value > 59 || value < 0) {
      return null;
    }
    setMinutes(value);
    const newHours = hours || 0;
    const newMinutes = value || 0;
    const newTime = timeUtils.getTotalSecondsFromHoursAndMinutes(newHours, newMinutes);
    setValue(newTime);
    return null;
  };

  useEffect(() => {
    if (initial) {
      const newHours = timeUtils.getOnlyHoursFromSeconds(initial);
      const newMinutes = timeUtils.getOnlyMinutesFromSeconds(initial);
      setHours(newHours);
      setMinutes(newMinutes);
    }
  }, [initial]);

  return (
    <Wrapper data-testid={`TFTimePicker--Wrapper-${id}`}>
      <TFNumberInput min={0} value={hours} onChange={handleHoursChange} width="100px" id={`${id}-hours`} />
      <span> {formatMessage({ id: 'period.hrs' })} </span>
      <TFNumberInput min={0} value={minutes} onChange={handleMinutesChange} width="100px" id={`${id}-minutes`} />
    </Wrapper>
  );
};

export default TFTimePicker;
