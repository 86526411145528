/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import FRATItemDraggable from '../../pages/OperatorFRAT/FRATItemDraggable';
import { FRATQuestion } from '../../models/frats';

interface SubItemComponentProps {
  subItems: FRATQuestion[];
  type: string;
  category: string;
}

const SubItemComponent: React.FC<SubItemComponentProps> = ({ subItems, type, category }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '6px 20px',
    margin: '0 0 8px 0',
    boxShadow: '0 0 10px 0 rgba(219, 227, 237, 0.41)',
    // change background colour if dragging
    background: isDragging ? '#f3f4f6' : '#fcfdff',
    border: '1px solid #fff',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = () => ({
    background: 'transparent',
    width: '100%',
    minHeight: '50px',
  });

  const getDisplayItem = (item) => {
    return <FRATItemDraggable item={item} category={category} />;
  };

  return (
    <Droppable droppableId={type?.toString()} type="droppableSubItem">
      {(provided) => (
        <div ref={provided.innerRef} style={getListStyle()}>
          {subItems.map((item, index) => (
            <Draggable key={item.id} draggableId={item?.id?.toString()} index={index}>
              {(providedInner, snapshotInner) => (
                <div
                  ref={providedInner.innerRef}
                  {...providedInner.draggableProps}
                  {...providedInner.dragHandleProps}
                  style={getItemStyle(snapshotInner.isDragging, providedInner.draggableProps.style)}
                >
                  {getDisplayItem(item)}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default SubItemComponent;
