import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Maintenance } from '../../../models/maintenance';
import ToleranceCounter from './ToleranceCounter';

interface TypedToleranceCounterProps {
  itemTolerance?: number;
  itemVisibleTolerance?: number;
  nextDue?: string | number;
  remaining?: number;
  currentItem: Maintenance;
  unitSingular: string;
  unitPlural: string;
  type?: string;
  onChange: (value: number) => void;
  dateFormat?: string;
}

type ToleranceCounterProps = TypedToleranceCounterProps & WrappedComponentProps<'intl'>;

const ConnectedToleranceCounter = connect()(ToleranceCounter);

const TypedToleranceCounter: React.FC<ToleranceCounterProps> = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ConnectedToleranceCounter {...props} />;
};

export default injectIntl(TypedToleranceCounter);
