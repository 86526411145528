import React from 'react';
import styled from 'styled-components';
import tick from '../../assets/icon-tick-white.svg';

interface PlainCheckboxProps {
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ checked }): string => (checked ? '#126fd6' : '#fff')};
  border: ${({ checked }): string => (checked ? '1px solid #126fd6' : '1px solid #d9d9d9')};
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    border-color: #126fd6;
  }
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    border-color: #126fd6;
  }
`;

const StyledTick = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PlainCheckbox: React.FC<PlainCheckboxProps> = ({ checked, onChange }) => {
  return (
    <Checkbox checked={checked} data-testid="PlainCheckbox-Checkbox">
      <HiddenInput type="checkbox" checked={checked} onChange={onChange} data-testid="PlainCheckbox-HiddenInput" />
      {checked ? <StyledTick src={tick} alt="tick" data-testid="PlainCheckbox-StyledTick" /> : null}
    </Checkbox>
  );
};

export default PlainCheckbox;
