import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import AircraftAdvancedSettings from '../../components/AircraftSettings/AircraftAdvancedSettings';
import AircraftCRSResolutionFields from '../../components/AircraftSettings/AircraftCRSResolutionFields';
import AircraftDefectStandardFields from '../../components/AircraftSettings/AircraftDefectStandardFields';
import AircraftGeneralSettings from '../../components/AircraftSettings/AircraftGeneralSettings';
import AircraftIPadPermissions from '../../components/AircraftSettings/AircraftIPadPermissions';
import AircraftMaintenanceSettings from '../../components/AircraftSettings/AircraftMaintenanceSettings';
import AircraftMetrics from '../../components/AircraftSettings/AircraftMetrics';
import AircraftTripStandardFields from '../../components/AircraftSettings/AircraftTripStandardFields';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { DashboardState } from '../../models';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { getSingleAircraft, update } from '../../models/aircraft/actions';
import AircraftDocumentConfiguration from '../../components/AircraftSettings/AircraftDocumentConfiguration';
import AircraftFluidTracking from '../../components/AircraftSettings/AircraftFluidTracking';
import AircraftStatusSettings from '../../components/AircraftSettings/AircraftStatusSettings';
import AircraftCampSettings from '../../components/AircraftSettings/AircraftCampSettings';
import AircraftCabinLog from '../../components/AircraftSettings/AircraftCabinLog';
import updateLocalDataObject from '../../utils/updateLocalDataObject';

const Wrapper = styled.div`
  padding: 32px 28px;
  background-color: #fcfdff;
  border: 2px solid #fff;
  box-shadow: 0 0 16px 0 #dbe3ed;
  @media (max-width: 451px) {
    padding: 20px 10px;
  }
`;

const RegWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const ItemWrapper = styled.div`
  margin-right: 24px;
  width: 170px;
  @media (max-width: 451px) {
    width: calc(50% - 20px);
  }
`;

const Header = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: 24px;
`;

const Label = styled.div`
  font-size: 12px;
  color: rgba(36, 45, 65, 0.4);
`;

const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
`;

const AircraftSettings: React.FC = () => {
  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { people },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [selectedAircraft, setSelectedAircraft] = useState<Aircraft | BaseAircraft>(null);
  const [originalAircraftData, setOriginalAircraftData] = useState<Aircraft | BaseAircraft>(null);

  const user = people.find((person) => person.organisation.id === aircraftMap.get(id)?.operator_id);

  const userIsAdmin = user?.position === 'Admin';
  const hasEditAircraftPermission = user?.permission_groups?.[1]?.permissions.find(
    (per) => per.resource_type === 'Aircraft',
  )?.to_update;

  // dummy feature flag until camp settings are set up in core
  const campFeatureFlag = false;

  const updateAircraftData = (changes: { value: any; key: string }[]): void => {
    const newSelectedAircraft = selectedAircraft || {};
    const newAircraftData = updateLocalDataObject(changes, newSelectedAircraft);
    setSelectedAircraft(newAircraftData);
  };

  const handleCancelClick = (): void => {
    setSelectedAircraft(originalAircraftData);
  };

  const payloadFormatter = (): any => {
    const newPayload = selectedAircraft as any;
    for (const [key, value] of Object.entries(selectedAircraft.app_settings)) {
      newPayload[`${key}`] = value;
    }
    return newPayload;
  };

  const validPayload = (payload): boolean => {
    if (payload?.max_weight < 0) {
      message.error('Max weight cannot be negative');
      return false;
    }
    return true;
  };

  const handleSaveClick = (): void => {
    const aircraftPayload = payloadFormatter();
    if (validPayload(aircraftPayload)) {
      const payload = {
        aircraftId: aircraftPayload?.id,
        aircraft: aircraftPayload,
      } as any;
      dispatch(update({ payload }));
    }
  };

  useEffect(() => {
    if (aircraftMap.get(id)) {
      const separatedAircraft = _.cloneDeep(aircraftMap.get(id));
      setSelectedAircraft(aircraftMap.get(id));
      setOriginalAircraftData(separatedAircraft);
    }
  }, [aircraftMap, id]);

  useEffect(() => {
    dispatch(getSingleAircraft({ payload: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InnerMenuLayout>
      <Wrapper>
        <Header data-testid="AircraftSettingsNew--PageTitle">Aircraft Settings</Header>
        <RegWrapper>
          <ItemWrapper data-testid="AircraftSettingsNew--Registration">
            <Label>REGISTRATION</Label>
            <Text>{selectedAircraft?.registration}</Text>
          </ItemWrapper>
          <div data-testid="AircraftSettingsNew--Type">
            <Label>AIRCRAFT TYPE</Label>
            <Text>{`${selectedAircraft?.aircraft_type?.model} (${selectedAircraft?.aircraft_type?.designator})`}</Text>
          </div>
        </RegWrapper>
        <AircraftGeneralSettings
          aircraft={selectedAircraft}
          originalAircraftData={originalAircraftData}
          updateAircraftData={updateAircraftData}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
          isAdmin={userIsAdmin}
          operatorSettings={user?.organisation?.operator_setting}
        />
        {userIsAdmin && selectedAircraft?.billing_status !== 'Archived' ? (
          <AircraftStatusSettings aircraft={selectedAircraft} isAdmin={userIsAdmin} />
        ) : null}
        <AircraftDocumentConfiguration
          aircraft={selectedAircraft}
          updateAircraftData={updateAircraftData}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
          isAdmin={userIsAdmin}
        />
        <AircraftMaintenanceSettings
          aircraft={selectedAircraft}
          updateAircraftData={updateAircraftData}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
          isAdmin={userIsAdmin}
          operatorSettings={user?.organisation?.operator_setting}
        />
        <AircraftIPadPermissions
          aircraft={selectedAircraft}
          updateAircraftData={updateAircraftData}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
          isAdmin={userIsAdmin}
        />
        <AircraftMetrics
          aircraft={selectedAircraft}
          updateAircraftData={updateAircraftData}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
          isAdmin={hasEditAircraftPermission}
        />
        {userIsAdmin ? (
          <AircraftAdvancedSettings
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
        {userIsAdmin ? <Header data-testid="AircraftSettingsNew--StandardFieldsTitle">Standard fields</Header> : null}
        {userIsAdmin ? (
          <AircraftDefectStandardFields
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
        {userIsAdmin ? (
          <AircraftCRSResolutionFields
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
        {userIsAdmin ? (
          <AircraftTripStandardFields
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
        {userIsAdmin ? (
          <AircraftFluidTracking
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
        {userIsAdmin ? (
          <AircraftCabinLog
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
        {userIsAdmin && campFeatureFlag ? (
          <AircraftCampSettings
            aircraft={selectedAircraft}
            updateAircraftData={updateAircraftData}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            isAdmin={userIsAdmin}
          />
        ) : null}
      </Wrapper>
    </InnerMenuLayout>
  );
};

export default AircraftSettings;
