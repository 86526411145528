/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { FRATOption, FRATQuestionCategory } from '../../models/frats';
import FRATQuestionDraggable from '../../pages/OperatorFRAT/FRATQuestionDraggable';
import FRATItemDraggable from '../../pages/OperatorFRAT/FRATItemDraggable';
import SubItemComponent from './SubItemComponent';

interface DraggableComponentProps {
  item: FRATQuestionCategory | FRATOption;
  index: number;
  component: string;
}

const CategoryWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  span {
    font-weight: bold;
  }
`;

const DraggableComponent: React.FC<DraggableComponentProps> = ({ item, index, component }) => {
  const [displayItem, setDisplayItem] = useState(null);

  const { formatMessage } = useIntl();
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: component === 'FRATQuestion' ? '10px' : '6px 20px 16px',
    margin: '0 0 8px 0',
    boxShadow: '0 0 10px 0 rgba(219, 227, 237, 0.41)',
    // change background colour if dragging
    background: isDragging ? '#f3f4f6' : '#fcfdff',
    border: '1px solid #fff',
    minHeight: component === 'FRATQuestion' ? '40px' : '80px',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const isOption = (checkItem: FRATQuestionCategory | FRATOption): checkItem is FRATOption => {
    return (checkItem as FRATOption).option !== undefined;
  };

  useEffect(() => {
    if (item) {
      if (component === 'FRATItemDraggable' && !isOption(item)) {
        const newItem = <FRATItemDraggable item={item} />;
        setDisplayItem(newItem);
      }
      if (component === 'SubItemComponent' && !isOption(item)) {
        const newItem = (
          <SubItemComponent subItems={item.flight_risk_assessment_questions} type={item.id} category={item.category} />
        );
        setDisplayItem(newItem);
      }
      if (component === 'FRATQuestion' && isOption(item)) {
        const newItem = <FRATQuestionDraggable item={item} index={index} />;
        setDisplayItem(newItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, item]);

  return (
    <Draggable key={item.id} draggableId={item?.id?.toString()} isDragDisabled={false} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {component === 'SubItemComponent' && !isOption(item) ? (
            <div>
              <CategoryWrapper>
                {formatMessage({ id: 'text.category' })}: <span>{item.category}</span>
              </CategoryWrapper>
              {displayItem}
            </div>
          ) : (
            <div>{displayItem}</div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableComponent;
