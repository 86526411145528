/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import latestTz from 'moment-timezone/data/packed/latest.json';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import getOperatorSetting from '../../utils/getOperatorSetting';
import { DashboardState } from '../../models';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import { Flight } from '../../models/flights';
import { Trip } from '../../models/trips';
import DurationFormat from '../DurationFormat';
import warningIcon from '../../assets/login-error-amber.svg';
import timeUtils from '../../utils/timeUtils';
import errorIcon from '../../assets/login-error.svg';
import { DisplayText, Header } from './FlightDrawer';
import 'moment-timezone';
import HoursMinsInput from './HoursMinsInput';

moment.tz.load(latestTz);

interface SectorTimesSectionProps {
  flight?: Flight;
  flightDate: string;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
  arrivalAirport: any;
  departAirport: any;
  trip: Trip;
}

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile && !edit) return '1fr 1fr';
    if (isMobile) return '1fr';
    if (edit) return '1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
`;

const WarningDiv = styled.div`
  color: ${({ error }): string => (error ? '#f5222d' : '#faad14')};
  grid-column: span 2;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const AirportText = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

const SectorTimesSection: React.FC<SectorTimesSectionProps> = ({
  flight,
  flightDate,
  updateFlightData,
  arrivalAirport,
  departAirport,
  trip,
}) => {
  const [blocksOff, setBlocksOff] = useState('');
  const [blocksOn, setBlocksOn] = useState('');
  const [takeoff, setTakeOff] = useState('');
  const [landing, setLanding] = useState('');
  const [blocksOffError, setBlocksOffError] = useState(false);
  const [blocksOffPreviousError, setBlockOffPreviousError] = useState(false);
  const [blocksOnError, setBlocksOnError] = useState(false);
  const [prevBlocksTime, setPrevBlocksTime] = useState(null);
  const [airborneSecondsError, setAirborneSecondsError] = useState(false);
  const [airborneTime, setAirborneTime] = useState(null);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const {
    drawer: { mode },
    userSettings: {
      dateFormat,
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const displayBlockTimes = aircraftMap.get(id)?.standard_fields?.blocks_times?.enabled;
  const airborneSecondsEnabled = aircraftMap.get(id)?.standard_fields?.airborne_seconds?.enabled;
  const airborneSecondsRename =
    aircraftMap.get(id)?.standard_fields?.airborne_seconds?.name_override || 'Airborne Time';
  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const sectorsNameOverride = getOperatorSetting('sector_name_plural', id) || formatMessage({ id: 'text.sector' });

  const flightTime = moment(landing).diff(moment(takeoff), 's');

  const blocksTime = moment(blocksOn).diff(moment(blocksOff), 's');

  const isMobile = window.innerWidth < 451;

  const formatTime = (input: string): string => {
    if (input) {
      return moment(input).utc().format('HH:mm');
    }
    return '-';
  };

  const displayTime = (input: number): string => {
    const hours = timeUtils.getOnlyHoursFromSeconds(input);
    const minutes = timeUtils.getOnlyMinutesFromSeconds(input);
    if (!hours) return `0:${minutes > 9 ? minutes : `0${minutes}`}`;
    if (!minutes) return `${hours}:00`;
    const newTime = `${hours}:${minutes > 9 ? minutes : `0${minutes}`}`;
    return newTime;
  };

  const displayDate = (date: string): string => {
    const tripDate = moment(flightDate).utc().format(dateFormat);
    const passedDate = moment(date).utc().format(dateFormat);
    if (tripDate !== passedDate) return passedDate;
    return null;
  };

  const handleDateTimeChange = (value: string, type: string): void => {
    if (type === 'blocksOff') {
      setBlocksOff(value);
      if (moment.utc(takeoff).diff(moment.utc(value), 'm') < 0 || !takeoff) {
        setLanding(value);
        setBlocksOn(value);
        setTakeOff(value);
        updateFlightData([
          { value, key: 'time_offblocks' },
          { value, key: 'time_takeoff' },
          { value, key: 'time_landing' },
          { value, key: 'time_onblocks' },
        ]);
      } else {
        updateFlightData([{ value, key: 'time_offblocks' }]);
      }
    }
    if (type === 'takeoff') {
      setTakeOff(value);
      if (moment.utc(landing).diff(moment.utc(value), 'm') < 0 || (!landing && displayBlockTimes === false)) {
        setLanding(value);
        setBlocksOn(value);
        if (displayBlockTimes === false) {
          updateFlightData([
            { value, key: 'time_takeoff' },
            { value, key: 'time_landing' },
          ]);
        } else {
          updateFlightData([
            { value, key: 'time_takeoff' },
            { value, key: 'time_landing' },
            { value, key: 'time_onblocks' },
          ]);
        }
      } else {
        updateFlightData([{ value, key: 'time_takeoff' }]);
      }
    }
    if (type === 'landing') {
      setLanding(value);
      if (moment.utc(blocksOn).diff(moment.utc(value), 'm') < 0) {
        setBlocksOn(value);
        if (displayBlockTimes === false) {
          updateFlightData([{ value, key: 'time_landing' }]);
        } else {
          updateFlightData([
            { value, key: 'time_onblocks' },
            { value, key: 'time_landing' },
          ]);
        }
      } else {
        updateFlightData([{ value, key: 'time_landing' }]);
      }
    }
    if (type === 'blocksOn') {
      setBlocksOn(value);
      updateFlightData([{ value, key: 'time_onblocks' }]);
    }
  };

  const LocalAirportTime = (airport, time): ReactElement => {
    const timezone = airport?.timezone;
    const localTime = timezone && time && moment(time).tz(timezone.format('HH:mm z'));
    if (timezone && moment.tz.zone(timezone))
      return (
        <AirportText>
          Airport:
          {localTime}
        </AirportText>
      );
    return null;
  };

  const changeAirborneSeconds = (value: string): void => {
    const newValue = parseInt(value, 10);
    if (newValue && newValue < 0) {
      setAirborneSecondsError(true);
    } else {
      setAirborneSecondsError(false);
    }
    setAirborneTime(value);
  };

  const handleAirborneSecondsFinish = (): void => {
    const stringArray = airborneTime?.split('.');
    if (stringArray) {
      const hoursAsSeconds = timeUtils.hoursToSeconds(stringArray[0]);
      const minutesAsSeconds = timeUtils.minutesToSeconds(stringArray[1]);
      const totalSeconds = hoursAsSeconds + minutesAsSeconds;
      updateFlightData([{ value: totalSeconds, key: 'airborne_seconds' }]);
    }
  };

  useEffect(() => {
    if (flightDate && blocksOff && displayBlockTimes !== false) {
      if (moment.utc(blocksOff).diff(moment.utc(flightDate)) < 0) {
        setBlockOffPreviousError(true);
      } else {
        setBlockOffPreviousError(false);
      }
    }
  }, [flightDate, blocksOff]);

  useEffect(() => {
    if (displayBlockTimes !== false) {
      if (moment.utc(takeoff).diff(moment.utc(blocksOff), 'm') > 30) {
        setBlocksOffError(true);
      } else {
        setBlocksOffError(false);
      }
      if (moment.utc(blocksOn).diff(moment.utc(landing), 'm') > 30) {
        setBlocksOnError(true);
      } else {
        setBlocksOnError(false);
      }
    }
  }, [blocksOff, blocksOn, landing, takeoff]);

  useEffect(() => {
    if (flight) {
      if (flight?.time_offblocks) {
        setBlocksOff(moment.utc(flight?.time_offblocks).toISOString());
      } else {
        setBlocksOff('');
      }
      if (flight?.time_onblocks) {
        setBlocksOn(moment.utc(flight?.time_onblocks).toISOString());
      } else {
        setBlocksOn('');
      }
      if (flight?.time_takeoff) {
        setTakeOff(moment.utc(flight?.time_takeoff).toISOString());
      } else {
        setTakeOff('');
      }
      if (flight?.time_landing) {
        setLanding(moment.utc(flight?.time_landing).toISOString());
      } else {
        setLanding('');
      }
      if (flight?.airborne_seconds) {
        const h = timeUtils.getOnlyHoursFromSeconds(flight?.airborne_seconds);
        const m = timeUtils.getOnlyMinutesFromSeconds(flight?.airborne_seconds);
        const formattedHours = `${h}.${m}`;
        setAirborneTime(formattedHours);
      } else {
        setAirborneTime(null);
      }
    } else if (flightDate) {
      const timeNow = moment().utc().format('HH:mm');
      const dateFormatted = moment(flightDate).utc().format(dateFormat);
      setBlocksOff(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      setBlocksOn(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      setTakeOff(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      setLanding(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      updateFlightData([
        { value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(), key: 'time_landing' },
        {
          value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(),
          key: 'time_offblocks',
        },
        { value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(), key: 'time_takeoff' },
        { value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(), key: 'time_onblocks' },
      ]);
    }
  }, [flight, flightDate]);

  useEffect(() => {
    if (trip && trip?.flights?.length) {
      const lastFlight = trip.flights[trip.flights.length - 1];
      const blocksOnTime = lastFlight.time_onblocks;
      if (blocksOnTime) {
        setBlocksOff(blocksOnTime);
        setPrevBlocksTime(blocksOnTime);
        setBlocksOn(blocksOnTime);
        setLanding(blocksOnTime);
        setTakeOff(blocksOnTime);
      }
    }
  }, [trip]);

  return (
    <SectionWrapper borderTop isMobile={isMobile} edit={mode !== 'view'}>
      {displayBlockTimes === false ? null : (
        <div data-testid="FlightDetailsSection-BlocksOff">
          {mode === 'view' ? <Header>{operatorSettings?.blocks_off}</Header> : null}
          {mode === 'view' ? (
            <DisplayText>
              {flight?.time_offblocks ? displayDate(flight?.time_offblocks) : '-'}{' '}
              {flight?.time_offblocks ? formatTime(flight?.time_offblocks) : null}z
            </DisplayText>
          ) : (
            <DateTimePicker
              headerDate={`${operatorSettings?.blocks_off} Date`}
              headerTime={`${operatorSettings?.blocks_off} Time`}
              dateTime={blocksOff}
              handleDateTimeChange={(value): void => handleDateTimeChange(value, 'blocksOff')}
              timeWidth="150px"
            />
          )}
          <LocalAirportTime airport={departAirport} time={blocksOff} />
        </div>
      )}
      <div data-testid="FlightDetailsSection-Takeoff">
        {mode === 'view' ? <Header>{operatorSettings?.takeoff}</Header> : null}
        {mode === 'view' ? (
          <DisplayText>
            {flight?.time_takeoff ? displayDate(flight?.time_takeoff) : '-'}{' '}
            {flight?.time_takeoff ? formatTime(flight?.time_takeoff) : null}z
          </DisplayText>
        ) : (
          <DateTimePicker
            headerDate={`${operatorSettings?.takeoff} Date`}
            headerTime={`${operatorSettings?.takeoff} Time`}
            dateTime={takeoff}
            handleDateTimeChange={(value): void => handleDateTimeChange(value, 'takeoff')}
            timeWidth="150px"
          />
        )}
        <LocalAirportTime airport={departAirport} time={takeoff} />
      </div>
      {blocksOffPreviousError && mode !== 'view' ? (
        <WarningDiv error>
          <img src={errorIcon} alt="warning icon" />
          <span>
            {`Time of ${
              operatorSettings?.blocks_off
            } is before previous ${sectorsNameOverride.toLowerCase()} blocks on ${prevBlocksTime}`}
          </span>
        </WarningDiv>
      ) : null}
      {blocksOffError && !blocksOffPreviousError && mode !== 'view' ? (
        <WarningDiv>
          <img src={warningIcon} alt="warning icon" />
          {`Time between ${operatorSettings?.blocks_off} and ${operatorSettings?.takeoff} is more than 30 minutes`}
        </WarningDiv>
      ) : null}
      <div data-testid="FlightDetailsSection-Landing">
        {mode === 'view' ? <Header>{operatorSettings?.landing}</Header> : null}
        {mode === 'view' ? (
          <DisplayText>
            {flight?.time_landing ? displayDate(flight?.time_landing) : '-'}{' '}
            {flight?.time_landing ? formatTime(flight?.time_landing) : null}z
          </DisplayText>
        ) : (
          <DateTimePicker
            headerDate={`${operatorSettings?.landing} Date`}
            headerTime={`${operatorSettings?.landing} Time`}
            dateTime={landing}
            handleDateTimeChange={(value): void => handleDateTimeChange(value, 'landing')}
            timeWidth="150px"
          />
        )}
        <LocalAirportTime airport={arrivalAirport} time={landing} />
      </div>
      {displayBlockTimes === false ? null : (
        <div data-testid="FlightDetailsSection-BlocksOn">
          {mode === 'view' ? <Header>{operatorSettings?.blocks_on}</Header> : null}
          {mode === 'view' ? (
            <DisplayText>
              {flight?.time_onblocks ? displayDate(flight?.time_onblocks) : '-'}{' '}
              {flight?.time_onblocks ? formatTime(flight?.time_onblocks) : null}z
            </DisplayText>
          ) : (
            <DateTimePicker
              headerDate={`${operatorSettings?.blocks_on} Date`}
              headerTime={`${operatorSettings?.blocks_on} Time`}
              dateTime={blocksOn}
              handleDateTimeChange={(value): void => handleDateTimeChange(value, 'blocksOn')}
              timeWidth="150px"
            />
          )}
          <LocalAirportTime airport={arrivalAirport} time={blocksOff} />
        </div>
      )}
      {blocksOnError && mode !== 'view' ? (
        <WarningDiv>
          <img src={warningIcon} alt="warning icon" />
          {`Time between ${operatorSettings?.landing} and ${operatorSettings?.blocks_on} is more than 30 minutes`}
        </WarningDiv>
      ) : null}
      {displayBlockTimes === false ? null : (
        <div data-testid="FlightDetailsSection-BlocksOn">
          <Header>{operatorSettings?.blocks_time}</Header>
          <DisplayText>
            <DurationFormat time={blocksTime} />
          </DisplayText>
        </div>
      )}
      <div data-testid="FlightDetailsSection-FlightTime">
        <Header>{operatorSettings?.flight_time}</Header>
        <DisplayText>
          <DurationFormat time={flightTime} />
        </DisplayText>
      </div>
      {airborneSecondsEnabled ? (
        <>
          <div data-testid="FlightDetailsSection-AirborneSeconds">
            <Header editable={mode !== 'view'}>{airborneSecondsRename}</Header>
            {mode === 'view' ? (
              <DisplayText>{displayTime(flight?.airborne_seconds)}</DisplayText>
            ) : (
              <HoursMinsInput
                value={airborneTime}
                onChange={changeAirborneSeconds}
                onBlur={handleAirborneSecondsFinish}
              />
            )}
          </div>
          {airborneSecondsError && mode !== 'view' ? (
            <WarningDiv error>
              <img src={errorIcon} alt="error icon" />
              {`${airborneSecondsRename} must be a non-negative number`}
            </WarningDiv>
          ) : null}
        </>
      ) : null}
    </SectionWrapper>
  );
};

export default SectorTimesSection;
