/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import {
  addDefect,
  addDefectAttachment,
  getDefect,
  queryDefects,
  queryV3Defects,
  updateDefect,
  removeDefect,
  getACDefects,
  updateRelease,
  queryDefectsForWorkpacks,
} from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { getPublicDefect, updatePublicDefect } from '../../services/external';
import { WorkpacksActionTypes } from '../workpacks';
import { DefectsActionTypes } from '.';

export const getAllDefects =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryDefects(payload);
    if (response) {
      const { res, list } = response;
      if (res && res.status > 199 && res.status < 399) {
        dispatch({
          type: DefectsActionTypes.SAVE_ALL,
          payload: { list },
        });
      }
    }
  };

export const getAllDefectsForWorkpacks =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryDefectsForWorkpacks(payload);
    if (response) {
      const { res, list } = response;
      if (res && res.status > 199 && res.status < 399) {
        dispatch({
          type: DefectsActionTypes.SAVE_ALL,
          payload: { list },
        });
      }
    }
  };

export const getV3Defects =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryV3Defects(payload);
    if (response && response.res.status > 199 && response.res.status < 399) {
      dispatch({
        type: DefectsActionTypes.SAVE_ALL,
        payload: response,
      });
    }
  };

export const getAircraftsDefects =
  (payload) =>
  async (dispatch): Promise<any> => {
    const response = await getACDefects(payload);

    if (response && response.res.status > 199 && response.res.status < 399) {
      dispatch({
        type: DefectsActionTypes.SAVE_ALL,
        payload: response,
      });
    }
  };

export const getDefectForDrawer =
  (payload) =>
  async (dispatch): Promise<any> => {
    const response = await getDefect(payload);
    if (response && response.res.status > 199 && response.res.status < 399) {
      dispatch({
        type: DefectsActionTypes.SAVE_DEFECTS_DRAWER,
        payload: response.data,
      });
    }
  };

export const clearDefectDrawer =
  () =>
  (dispatch): any => {
    dispatch({
      type: DefectsActionTypes.SAVE_DEFECTS_DRAWER,
      payload: [null],
    });
  };

export const getSingleDefect =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getDefect(payload);
    if (response && response.res.status > 199 && response.res.status < 399) {
      dispatch({
        type: DefectsActionTypes.SAVE,
        payload: response.data,
      });
    }
  };
export const update =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updateDefect(payload);
    if (response?.statusCode > 199 && response?.statusCode < 399) {
      dispatch({
        type: DefectsActionTypes.UPDATE_DRAWER_DEFECT,
        payload: [response?.body],
      });
      dispatch({
        type: DefectsActionTypes.SAVE,
        payload: [response?.body],
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.defectUpdated' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.defectUpdateFail' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
    return response;
  };
export const remove =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await removeDefect(payload);
    if (response && response.statusCode === 204) {
      dispatch({
        type: DefectsActionTypes.REMOVE,
        payload: response
          ? {
              removeId: payload,
            }
          : {},
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.defectDeleted' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.approveItemsError' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };
export const submitDefect =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    // @TODO: attachments as part of main request after core update
    const { attachments } = payload.body;
    const response = await addDefect(payload);
    if (attachments) {
      const files = attachments.filter((attachment) => attachment.originFileObj);
      await Promise.all(
        files.map((attachment) => {
          return addDefectAttachment({
            id: response.id,
            attachment: attachment.originFileObj,
          });
        }),
      );
    }
    dispatch({
      type: DefectsActionTypes.RESET_EPHEMERAL_DEFECT,
    });
    dispatch({
      type: DefectsActionTypes.SAVE,
      payload: [response],
    });
    return response;
  };

export const updateReleaseAttachments =
  ({ payload }) =>
  async (): Promise<void> => {
    updateRelease(payload);
  };

export const getPublicDefectDetails =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getPublicDefect(payload);
    // Despite being a defect API endpoint and it returns a workpack so we need to save
    // the against the workpack model/store.
    dispatch({
      type: WorkpacksActionTypes.SAVE_PUBLIC,
      payload: response,
    });
  };

export const updatePublicDefectDetails =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updatePublicDefect(payload);
    dispatch({
      type: DefectsActionTypes.SAVE_PUBLIC_DEFECT,
      payload: response,
    });
  };

export const saveFilters =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: DefectsActionTypes.SAVE_FILTERS,
      payload,
    });
  };

export const saveParams =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: DefectsActionTypes.SAVE_PARAMS,
      payload,
    });
  };

export const updateEphemeralDefect =
  ({ payload }) =>
  (dispatch): void => {
    dispatch({
      type: DefectsActionTypes.UPDATE_EPHEMERAL_DEFECT,
      payload,
    });
  };

export const resetEphemeralDefect =
  () =>
  (dispatch): void =>
    dispatch({
      type: DefectsActionTypes.RESET_EPHEMERAL_DEFECT,
    });

export const handleCrsClicked =
  ({ payload }) =>
  (dispatch): void => {
    dispatch({
      type: DefectsActionTypes.HANDLE_CRS_CLICKED,
      payload,
    });
  };
