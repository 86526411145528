/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import getOperatorSetting from '../../utils/getOperatorSetting';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { Trip } from '../../models/trips';
import { getAirport } from '../../services/api';
import SelectAirport from '../SelectAirport';
import errorIcon from '../../assets/login-error.svg';
import TFButton from '../TFButton/TFButton';
import TFDropdown from '../TFDropdown/TFDropdown';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';
import SectorTimesSection from './SectorTimesSection';

interface FlightDetailsSectionProps {
  flight?: Flight;
  flightDate: string;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
  trip?: Trip;
  renderSIC?: boolean;
  abandoned?: boolean;
}

const LocalSelectAirport = SelectAirport as any;

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile && !edit) return '1fr 1fr';
    if (isMobile) return '1fr';
    if (edit) return '1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: 1fr;
  row-gap: 12px;
  margin-top: ${({ borderTop, marginTop }): string => (borderTop || marginTop ? '20px' : null)};
  padding-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
  border-top: ${({ borderTop }): string => (borderTop ? '1px solid rgba(36, 45, 65, 0.05)' : null)};
`;

const PeopleWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }): string => {
    if (isMobile) return '1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  gap: 12px;
  margin-bottom: 12px;
`;

const WarningDiv = styled.div`
  color: ${({ error }): string => (error ? '#f5222d' : '#faad14')};
  grid-column: span 2;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const StyledInput = styled.input`
  width: 110px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const AddActualButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 285px;
`;

const ArrivalWrapper = styled.div`
  grid-column: ${({ span, edit }): string => (span || edit ? 'span 1' : 'span 2')};
`;

const FlightDetailsSection: React.FC<FlightDetailsSectionProps> = ({
  flight,
  flightDate,
  updateFlightData,
  trip,
  renderSIC,
  abandoned,
}) => {
  const { formatMessage } = useIntl();
  const [pilots, setPilots] = useState([]);
  const [pilotFlying, setPilotFlying] = useState(null);
  const [pilotMonitoring, setPilotMonitoring] = useState(null);
  const [PIC, setPIC] = useState(null);
  const [SIC, setSIC] = useState(null);
  const [performingTakeOff, setPerformingTakeOff] = useState(null);
  const [performingLanding, setPerformingLanding] = useState(null);
  const [departureAirportId, setDepartureAirportId] = useState(undefined);
  const [departAirport, setDepartAirport] = useState(null);
  const [arrivalAirportId, setArrivalAirportId] = useState(undefined);
  const [arrivalAirport, setArrivalAirport] = useState(null);
  const [actualArrivalAirportId, setActualArrivalAirportId] = useState(undefined);
  const [previousAirportId, setPreviousAirportId] = useState(null);
  const [previousAirport, setPreviousAirport] = useState(null);
  const [airportError, setAirportError] = useState(false);
  const [hobbsArrival, setHobbsArrival] = useState(null);
  const [hobbsDeparture, setHobbsDeparture] = useState(null);
  const [showActualArrivalAirport, setShowActualArrivalAirport] = useState(false);
  const [rolesThatCanFly, setRolesThatCanFly] = useState([]);

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const hobbs = aircraftMap.get(id)?.standard_fields?.hobbs;
  const actualArrivalAirportEnabled = aircraftMap.get(id)?.standard_fields?.actual_arrival_airport?.enabled;
  const actualArrivalAirportOverride = aircraftMap.get(id)?.standard_fields?.actual_arrival_airport?.name_override;
  const showPilotPerformingTakeOffAndLanding = getOperatorSetting('pilot_performing_takeoff_on_postflight_enabled', id);
  const showPilotFlyingAndMonitoring = getOperatorSetting('flight_crew_on_preflight_enabled', id);
  const sectorNameOverride = getOperatorSetting('sector_name', id) || formatMessage({ id: 'text.sector' });
  const displayHobbs = hobbs?.enabled;
  const hobbsRename = hobbs?.name_override;

  const isMobile = window.innerWidth < 451;

  const handleCrewChange = (option: { title: string; value: string }, key: string): void => {
    const additionalChanges = [];
    const coreKeyMaps = {
      pic: { stateUpdate: setPIC, coreKey: 'pic_id' },
      sic: { stateUpdate: setSIC, coreKey: 'sic_id' },
      pilotFlying: { stateUpdate: setPilotFlying, coreKey: 'pilot_flying_id' },
      pilotMonitoring: { stateUpdate: setPilotMonitoring, coreKey: 'pilot_monitoring_id' },
      pilotPerformingTakeOff: { stateUpdate: setPerformingTakeOff, coreKey: 'pilot_performing_takeoff_id' },
      pilotPerformingLanding: { stateUpdate: setPerformingLanding, coreKey: 'pilot_performing_landing_id' },
    };
    if (key === 'pic' && showPilotFlyingAndMonitoring) {
      additionalChanges.push({ value: option.value, key: 'pilot_flying_id' });
      setPilotFlying(option);
    }
    if (key === 'sic' && showPilotFlyingAndMonitoring) {
      additionalChanges.push({ value: option.value, key: 'pilot_monitoring_id' });
      setPilotMonitoring(option);
    }
    if (key === 'pic' && showPilotPerformingTakeOffAndLanding) {
      additionalChanges.push({ value: option.value, key: 'pilot_performing_takeoff_id' });
      additionalChanges.push({ value: option.value, key: 'pilot_performing_landing_id' });
      setPerformingTakeOff(option);
      setPerformingLanding(option);
    }
    coreKeyMaps[key].stateUpdate(option);
    updateFlightData([{ value: option.value, key: coreKeyMaps[key].coreKey }, ...additionalChanges]);
  };

  const handleAirportChange = (airportId: string, type: string): void => {
    if (type === 'departure') {
      if (airportId && previousAirportId && airportId !== previousAirportId) {
        setAirportError(true);
      } else {
        setAirportError(false);
      }
      setDepartureAirportId(airportId);
      updateFlightData([{ value: airportId, key: 'departure_airport_id' }]);
    }
    if (type === 'arrival') {
      setArrivalAirportId(airportId);
      updateFlightData([{ value: airportId, key: 'arrival_airport_id' }]);
    }
    if (type === 'actualArrival') {
      setActualArrivalAirportId(airportId);
      updateFlightData([{ value: airportId, key: 'actual_arrival_airport_id' }]);
    }
  };

  const handleHobbsChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (type === 'dep') {
      setHobbsDeparture(newValue);
      updateFlightData([{ value: newValue, key: 'hobbs_departure' }]);
    }
    if (type === 'arr') {
      setHobbsArrival(newValue);
      updateFlightData([{ value: newValue, key: 'hobbs_arrival' }]);
    }
  };

  useEffect(() => {
    if (previousAirportId) {
      const getPrevAirport = async (): Promise<any> => {
        const airport = await getAirport(previousAirportId);
        Promise.all([airport]).then((value) => {
          setPreviousAirport(value[0]);
        });
      };
      getPrevAirport();
    }
  }, [previousAirportId]);

  useEffect(() => {
    if (flight && !flight?.arrival_airport_id && flight?.trip?.flights?.length) {
      const lastFlight = flight?.trip.flights[flight?.trip.flights.length - 1];
      const depAirportId = lastFlight.departure_airport_id;
      if (departureAirportId && depAirportId && departureAirportId !== depAirportId) {
        setDepartureAirportId(depAirportId);
        updateFlightData([{ value: depAirportId, key: 'departure_airport_id' }]);
        setPreviousAirportId(depAirportId);
      }
    }
  }, [departureAirportId, flight, updateFlightData]);

  useEffect(() => {
    if (operators) {
      const personRoles = operators?.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.person_roles;
      const rolesAllowedToFly =
        personRoles?.filter((role) => role?.crew_type === 'Flight').map((role) => role?.name) || [];
      setRolesThatCanFly(rolesAllowedToFly);
    }
  }, [aircraftMap, id, operators]);

  useEffect(() => {
    if (mode === 'add' && trip) {
      let captain = null;
      let firstOfficer = null;
      const newPilots = [];
      if (trip?.captain?.id) {
        captain = {
          title: `${trip?.captain?.first_name} ${trip?.captain?.last_name}`,
          value: trip?.captain?.id,
        };
      }
      if (trip?.first_officer?.id) {
        firstOfficer = {
          title: `${trip?.first_officer?.first_name} ${trip?.first_officer?.last_name}`,
          value: trip?.first_officer?.id,
        };
      }
      if (captain) {
        setPIC(captain);
        setPilotFlying(captain);
        setPerformingTakeOff(captain);
        setPerformingLanding(captain);
        newPilots.push(captain);
      }
      if (firstOfficer) {
        setSIC(firstOfficer);
        setPilotMonitoring(firstOfficer);
        newPilots.push(firstOfficer);
      }
      const additionalCrew = trip?.additional_crews_attributes || trip?.additional_crews;
      const additionalPeople =
        additionalCrew
          ?.filter((person) => rolesThatCanFly?.includes(person?.position))
          ?.map((person) => {
            return {
              title: `${person?.first_name} ${person.last_name}`,
              value: person?.person_id,
            };
          }) || [];
      newPilots.push(...additionalPeople);
      setPilots(newPilots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesThatCanFly]);

  useEffect(() => {
    if (mode !== 'add' && flight) {
      let pilotFly = null;
      let pilotMonitor = null;
      let newPIC = null;
      let newSIC = null;
      let tripCaptain = null;
      let tripFirstOfficer = null;
      let takeOff = null;
      let landing = null;
      const newPilots = [];
      if (flight?.pilot_flying?.id) {
        pilotFly = {
          title: `${flight?.pilot_flying?.first_name} ${flight?.pilot_flying?.last_name}`,
          value: flight?.pilot_flying?.id,
        };
      }
      if (flight?.pilot_monitoring?.id) {
        pilotMonitor = {
          title: `${flight?.pilot_monitoring?.first_name} ${flight?.pilot_monitoring?.last_name}`,
          value: flight?.pilot_monitoring?.id,
        };
      }
      if (flight?.pic?.id) {
        newPIC = {
          title: `${flight?.pic?.first_name} ${flight?.pic?.last_name}`,
          value: flight?.pic?.id,
        };
      }
      if (flight?.sic?.id) {
        newSIC = {
          title: `${flight?.sic?.first_name} ${flight?.sic?.last_name}`,
          value: flight?.sic?.id,
        };
      }
      if (flight?.trip?.captain?.id) {
        tripCaptain = {
          title: `${flight?.trip?.captain?.first_name} ${flight?.trip?.captain?.last_name}`,
          value: flight?.trip?.captain?.id,
        };
      }
      if (flight?.trip?.first_officer?.id) {
        tripFirstOfficer = {
          title: `${flight?.trip?.first_officer?.first_name} ${flight?.trip?.first_officer?.last_name}`,
          value: flight?.trip?.first_officer?.id,
        };
      }
      if (flight?.pilot_performing_takeoff?.id) {
        takeOff = {
          title: `${flight?.pilot_performing_takeoff?.first_name} ${flight?.pilot_performing_takeoff?.last_name}`,
          value: flight?.pilot_performing_takeoff?.id,
        };
      }
      if (flight?.pilot_performing_landing?.id) {
        landing = {
          title: `${flight?.pilot_performing_landing?.first_name} ${flight?.pilot_performing_landing?.last_name}`,
          value: flight?.pilot_performing_landing?.id,
        };
      }
      if (pilotFly) {
        setPilotFlying(pilotFly);
        if (newPilots.findIndex((pilot) => pilot?.value === pilotFly?.value) === -1) newPilots.push(pilotFly);
      }
      if (pilotMonitor) {
        setPilotMonitoring(pilotMonitor);
        if (newPilots.findIndex((pilot) => pilot?.value === pilotMonitor?.value) === -1) newPilots.push(pilotMonitor);
      }
      if (newPIC) {
        setPIC(newPIC);
        if (newPilots.findIndex((pilot) => pilot?.value === newPIC?.value) === -1) newPilots.push(newPIC);
      }
      if (newSIC) {
        setSIC(newSIC);
        if (newPilots.findIndex((pilot) => pilot?.value === newSIC?.value) === -1) newPilots.push(newSIC);
      }
      if (tripCaptain) {
        if (newPilots.findIndex((pilot) => pilot?.value === tripCaptain?.value) === -1) newPilots.push(tripCaptain);
      }
      if (tripFirstOfficer) {
        if (newPilots.findIndex((pilot) => pilot?.value === tripFirstOfficer?.value) === -1)
          newPilots.push(tripFirstOfficer);
      }
      if (flight.pilot_performing_landing_id) {
        const landingPilot = newPilots.find((pilot) => pilot.value === flight.pilot_performing_landing_id);
        landing = {
          title: landingPilot?.title,
          value: landingPilot.value,
        };
      }
      if (flight.pilot_performing_takeoff_id) {
        const takeoffPilot = newPilots.find((pilot) => pilot.value === flight.pilot_performing_takeoff_id);
        takeOff = {
          title: takeoffPilot?.title,
          value: takeoffPilot.value,
        };
      }
      if (takeOff) {
        setPerformingTakeOff(takeOff);
      }
      if (landing) {
        setPerformingLanding(landing);
      }
      const additionalCrew =
        flight?.additional_crews
          ?.filter(
            (person) =>
              rolesThatCanFly?.includes(person?.position) &&
              newPilots.findIndex((pilot) => pilot?.value === person?.person_id) === -1,
          )
          ?.map((person) => {
            return {
              title: `${person?.first_name} ${person.last_name}`,
              value: person?.person_id,
            };
          }) || [];
      newPilots.push(...additionalCrew);
      setPilots(newPilots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesThatCanFly]);

  useEffect(() => {
    if (pilots && pilots.findIndex((pilot) => pilot.value === null) === -1) {
      const nullPilot = {
        title: 'None',
        value: null,
      };
      setPilots([nullPilot, ...pilots]);
    }
  }, [pilots]);

  useEffect(() => {
    if (flight) {
      if (flight?.arrival_airport_id) {
        setArrivalAirportId(flight?.arrival_airport_id);
      } else {
        setArrivalAirport(undefined);
      }
      if (flight?.departure_airport_id) {
        setDepartureAirportId(flight?.departure_airport_id);
      } else {
        setDepartureAirportId(undefined);
      }
      if (flight?.actual_arrival_airport_id) {
        setActualArrivalAirportId(flight?.actual_arrival_airport_id);
        setShowActualArrivalAirport(true);
      } else {
        setActualArrivalAirportId(undefined);
      }
      if (flight?.hobbs_arrival) {
        setHobbsArrival(flight.hobbs_arrival);
      } else {
        setHobbsArrival('');
      }
      if (flight?.hobbs_departure) {
        setHobbsDeparture(flight.hobbs_departure);
      } else {
        setHobbsDeparture('');
      }
    }
  }, [flight]);

  let arrivalAirportText = formatMessage({ id: 'text.arrival' });
  if (actualArrivalAirportEnabled) arrivalAirportText = formatMessage({ id: 'text.planned' });

  const hobbsSection = (
    <SectionWrapper borderTop isMobile={isMobile}>
      <div>
        <Header editable={mode !== 'view'}>{`${hobbsRename || 'Hobbs'} Departure`}</Header>
        <UnitWrapper>
          {mode === 'view' ? (
            <DisplayText>{hobbsDeparture || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              value={hobbsDeparture || undefined}
              onChange={(e): void => handleHobbsChange(e.target.value, 'dep')}
              type="number"
            />
          )}
          <StyledUnit edit={mode !== 'view'}>hrs</StyledUnit>
        </UnitWrapper>
      </div>
      <div>
        <Header editable={mode !== 'view'}>{`${hobbsRename || 'Hobbs'} Arrival`}</Header>
        <UnitWrapper>
          {mode === 'view' ? (
            <DisplayText>{hobbsArrival || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              value={hobbsArrival || undefined}
              onChange={(e): void => handleHobbsChange(e.target.value, 'arr')}
              type="number"
            />
          )}
          <StyledUnit edit={mode !== 'view'}>hrs</StyledUnit>
        </UnitWrapper>
      </div>
      {flight?.hobbs_departure && flight?.hobbs_arrival && mode === 'view' ? (
        <div>
          <Header>{`${hobbsRename || 'Hobbs'} Total`}</Header>
          <UnitWrapper>
            <DisplayText>{Math.round((flight?.hobbs_arrival - flight?.hobbs_departure) * 10) / 10}</DisplayText>
            <StyledUnit>hrs</StyledUnit>
          </UnitWrapper>
        </div>
      ) : null}
    </SectionWrapper>
  );

  let displayHobbsSection = true;
  if (!flight?.hobbs_arrival && !flight?.hobbs_departure && mode === 'view') displayHobbsSection = false;
  if (displayHobbs === false) displayHobbsSection = false;

  const crewDropdowns = [
    {
      id: 'pic',
      options: pilots.filter((pilot) => pilot.value !== SIC?.value),
      onSelect: handleCrewChange,
      initialValue: PIC,
    },
  ];

  const SICDropdown = {
    id: 'sic',
    label: 'SIC',
    options: pilots.filter((pilot) => pilot.value !== PIC?.value),
    onSelect: handleCrewChange,
    initialValue: SIC,
  };
  const pilotFlyingDropdown = {
    id: 'pilotFlying',
    options: pilots.filter((pilot) => pilot.value !== pilotMonitoring?.value),
    onSelect: handleCrewChange,
    initialValue: pilotFlying,
  };
  const pilotMonitoringDropdown = {
    id: 'pilotMonitoring',
    options: pilots.filter((pilot) => pilot.value !== pilotFlying?.value),
    onSelect: handleCrewChange,
    initialValue: pilotMonitoring,
  };
  const performingTakeOffDropdown = {
    id: 'pilotPerformingTakeOff',
    options: pilots,
    onSelect: handleCrewChange,
    initialValue: performingTakeOff,
  };
  const performingLandingDropdown = {
    id: 'pilotPerformingLanding',
    options: pilots,
    onSelect: handleCrewChange,
    initialValue: performingLanding,
  };
  if (renderSIC) crewDropdowns.push(SICDropdown);
  if (showPilotFlyingAndMonitoring) {
    crewDropdowns.push(pilotFlyingDropdown);
    if (renderSIC) crewDropdowns.push(pilotMonitoringDropdown);
  }
  if (showPilotPerformingTakeOffAndLanding) {
    crewDropdowns.push(performingTakeOffDropdown);
    crewDropdowns.push(performingLandingDropdown);
  }

  return (
    <>
      <SectionHeader data-testid="FlightDetailsSection-Header">{`${sectorNameOverride} Details`}</SectionHeader>
      <PeopleWrapper isMobile={isMobile}>
        {crewDropdowns.map((dropdown) => (
          <TFDropdown
            key={dropdown.id}
            id={dropdown.id}
            label={formatMessage({ id: `text.${dropdown.id}` })}
            options={dropdown.options}
            onSelect={(e) => dropdown.onSelect(e, dropdown.id)}
            readOnly={mode === 'view'}
            initialValue={dropdown.initialValue}
          />
        ))}
      </PeopleWrapper>
      <SectionWrapper isMobile={isMobile} edit={mode !== 'view'}>
        <div data-testid="FlightDetailsSection-From">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.departure' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{flight?.departure_airport || '-'}</DisplayText>
          ) : (
            <LocalSelectAirport
              value={departureAirportId}
              onChange={(airportId): void => handleAirportChange(airportId, 'departure')}
              onChangeObj={(airport): void => setDepartAirport(airport)}
              width="285px"
            />
          )}
        </div>
        <ArrivalWrapper span={actualArrivalAirportEnabled} edit={mode !== 'view'} data-testid="FlightDetailsSection-To">
          <Header editable={mode !== 'view'}>{arrivalAirportText}</Header>
          {mode === 'view' ? (
            <DisplayText>{flight?.arrival_airport || '-'}</DisplayText>
          ) : (
            <>
              <LocalSelectAirport
                value={arrivalAirportId}
                onChange={(airportId): void => handleAirportChange(airportId, 'arrival')}
                onChangeObj={(airport): void => setArrivalAirport(airport)}
                width="285px"
              />
              {!showActualArrivalAirport && actualArrivalAirportEnabled ? (
                <AddActualButtonWrapper>
                  <TFButton padding="0" clear handleClick={(): void => setShowActualArrivalAirport(true)}>
                    {`+ Add ${actualArrivalAirportOverride || formatMessage({ id: 'text.actualArrival' })}`}
                  </TFButton>
                </AddActualButtonWrapper>
              ) : null}
            </>
          )}
        </ArrivalWrapper>
        {mode === 'view' && actualArrivalAirportEnabled ? (
          <div>
            <Header editable={mode !== 'view'}>
              {actualArrivalAirportOverride || formatMessage({ id: 'text.actualArrival' })}
            </Header>
            <DisplayText>{flight?.actual_arrival_airport || '-'}</DisplayText>
          </div>
        ) : null}
        {airportError && !actualArrivalAirportEnabled ? (
          <WarningDiv error>
            <img src={errorIcon} alt="warning icon" />
            <span>
              {`Previous arrival destination ${previousAirport?.ident}
               doesn't match selected departure airport ${departAirport?.ident}`}
            </span>
          </WarningDiv>
        ) : null}
        {!airportError && actualArrivalAirportEnabled ? <div /> : null}
        {actualArrivalAirportEnabled && mode !== 'view' ? (
          <>
            {showActualArrivalAirport ? (
              <div data-testid="FlightDetailsSection-To">
                <Header editable={mode !== 'view'}>
                  {actualArrivalAirportOverride || formatMessage({ id: 'text.actualArrival' })}
                </Header>
                <LocalSelectAirport
                  value={actualArrivalAirportId}
                  onChange={(airportId): void => handleAirportChange(airportId, 'actualArrival')}
                  width="285px"
                />
                <AddActualButtonWrapper>
                  <TFButton
                    padding="0"
                    clear
                    handleClick={(): void => {
                      setShowActualArrivalAirport(false);
                      updateFlightData([
                        { value: null, key: 'actual_arrival_airport' },
                        { value: null, key: 'actual_arrival_airport_id' },
                      ]);
                    }}
                  >
                    {`- Remove ${actualArrivalAirportOverride}`}
                  </TFButton>
                </AddActualButtonWrapper>
              </div>
            ) : null}
          </>
        ) : null}
      </SectionWrapper>
      {!abandoned ? (
        <SectorTimesSection
          flight={flight}
          flightDate={flightDate}
          updateFlightData={updateFlightData}
          arrivalAirport={arrivalAirport}
          departAirport={departAirport}
          trip={trip}
        />
      ) : null}
      {displayHobbsSection ? hobbsSection : null}
    </>
  );
};

export default FlightDetailsSection;
