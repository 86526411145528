/**
 * Requests a URL, returning a promise.
 * @export
 * @param {{ statusCode: number }} { statusCode }
 */
export default function error({ statusCode }: { statusCode: number }): void {
  if (process.env.NODE_ENV === 'development') {
    console.error('This redirection should be updated');
  }
  if (statusCode >= 404 && statusCode < 500) {
    window.location.pathname = '/public/exception/404';
  } else {
    window.location.pathname = '/public/exception/500';
  }
}
