import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { deleteFRAT } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';

const useDeleteFRAT = (operatorId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const setDefault = useMutation({
    mutationFn: (payload: { id: string }) => deleteFRAT(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['FRAT', operatorId] });
      dispatch(
        addToast({
          payload: {
            title: 'FRAT Removed',
            message: 'Flight Risk Assessment has been deleted successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
  });

  return setDefault;
};

export default useDeleteFRAT;
