import React from 'react';
import styled from 'styled-components';
import TFCloseableBackground from '../TFCloseableBackground/index';
import TFButton from '../TFButton/TFButton';

interface TFModalProps {
  handleCancelClick: () => void;
  handleContinueClick: () => void;
  title: string;
  text: string;
  cancelButtonText: string;
  confirmButtonText: string;
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 35%;
  left: 20%;
  right: 20%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border: 2px solid #ffffff;
  padding: 15px;
  border-radius: 4px;
  z-index: 10;
`;

const Title = styled.span`
  color: #242d41;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
`;

const Text = styled.span`
  color: rgba(36, 45, 65, 0.9);
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TFModal: React.FC<TFModalProps> = ({
  handleCancelClick,
  handleContinueClick,
  title,
  text,
  cancelButtonText,
  confirmButtonText,
}) => {
  return (
    <TFCloseableBackground handleClose={null} opaqueBackground>
      <ModalWrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <ButtonWrapper>
          <TFButton clear handleClick={handleCancelClick}>
            {cancelButtonText}
          </TFButton>
          <TFButton handleClick={handleContinueClick}>{confirmButtonText}</TFButton>
        </ButtonWrapper>
      </ModalWrapper>
    </TFCloseableBackground>
  );
};

export default TFModal;
