/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Loading } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Trip } from '../../models/trips';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import { DisplayText, Header, StyledInput } from '../FlightDrawer/FlightDrawer';
import { DashboardState } from '../../models';
import { fetchGeneratedPdfDocument } from '../../services/api';
import convertPdfResponseToBlob from '../../utils/pdf';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';

interface SRPSectionProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any) => void;
}

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const DetailsWrapper = styled.div`
  margin-right: 40px;
  width: 100px;
  @media (max-width: 450px) {
    margin-right: 10px;
  }
`;

const StyledIcon = styled.img`
  width: 16px;
`;

const StyledLink = styled.a`
  color: #126fd6;
  font-size: 12px;
  letter-spacing: -0.08px;
  &:hover {
    color: #1e5bb5;
  }
`;

const SRPSection: React.FC<SRPSectionProps> = ({ trip, editable, updateTripData }) => {
  const [tripNo, setTripNo] = useState(trip?.number);
  const [SRPNumber, setSRPNumber] = useState(trip?.srp_number);
  const [fetchingSrpDocument, setFetchingSrpDocument] = useState(false);
  const [fetchingJlDocument, setFetchingJlDocument] = useState(false);

  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const operatorSettings = operators?.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const handleTripNumberUpdate = (value: string): void => {
    updateTripData('number', value);
  };

  const handleSRPUpdate = (value: string): void => {
    updateTripData('srp_number', value);
  };

  const handleDocClick = async (docId, docType): Promise<void> => {
    try {
      if (docType === 'jl') setFetchingJlDocument(true);
      if (docType === 'srp') setFetchingSrpDocument(true);
      const res = await fetchGeneratedPdfDocument(docId, docType);
      const blob = convertPdfResponseToBlob(res);
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');

      setFetchingSrpDocument(false);
      setFetchingJlDocument(false);
    } catch (e) {
      const jsonObject = JSON.parse(atob(e.error));
      dispatch(
        addToast({
          payload: {
            title: jsonObject.error || formatMessage({ id: 'message.generateDocumentError' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
      setFetchingSrpDocument(false);
      setFetchingJlDocument(false);
    }
  };

  useEffect(() => {
    if (trip) {
      setTripNo(trip?.number);
      setSRPNumber(trip?.srp_number);
    }
  }, [trip]);

  return (
    <RowWrapper>
      <DetailsWrapper data-testid="SRPSection-Header">
        <Header editable={editable}>Trip No.</Header>
        {editable ? (
          <StyledInput width={100} value={tripNo} onChange={(e): void => handleTripNumberUpdate(e.target.value)} />
        ) : (
          <DisplayText>{trip?.number || '-'}</DisplayText>
        )}
      </DetailsWrapper>
      {trip?.status !== 'planned' ? (
        <DetailsWrapper data-testid="SRPSection-SRP">
          <Header editable={editable}>{`Trip ${operatorSettings?.srp}`}</Header>
          {editable ? (
            <StyledInput width={100} value={SRPNumber} onChange={(e): void => handleSRPUpdate(e.target.value)} />
          ) : (
            <DisplayText>
              {fetchingSrpDocument ? (
                <Loading contain size={30} />
              ) : (
                <StyledLink
                  target="_blank"
                  rel="noopener noreferrer"
                  download="srp"
                  onClick={(): Promise<void> => handleDocClick(trip?.id, 'srp')}
                >
                  {`${operatorSettings?.srp}`} <StyledIcon src={pdfIcon} alt="pdf icon" /> {`${trip?.srp_number}`}
                </StyledLink>
              )}
            </DisplayText>
          )}
        </DetailsWrapper>
      ) : null}
      {trip?.status !== 'planned' ? (
        <DetailsWrapper data-testid="SRPSection-JourneyLog">
          <Header>{`${operatorSettings?.journey_log}`}</Header>
          <DisplayText>
            {fetchingJlDocument ? (
              <Loading contain size={30} />
            ) : (
              <StyledLink
                target="_blank"
                rel="noopener noreferrer"
                download="journey_log"
                onClick={(): Promise<void> => handleDocClick(trip?.id, 'jl')}
              >
                View <StyledIcon src={pdfIcon} alt="pdf icon" />
              </StyledLink>
            )}
          </DisplayText>
        </DetailsWrapper>
      ) : null}
    </RowWrapper>
  );
};

export default SRPSection;
