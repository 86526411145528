const ata: {
  key: number;
  name: string;
  nameFull: string;
}[] = [
  {
    key: 0,
    name: 'General',
    nameFull: '00 - General',
  },
  {
    key: 1,
    name: 'Maintenance Policy',
    nameFull: '01 - Maintenance Policy',
  },
  {
    key: 2,
    name: 'Opertaions',
    nameFull: '02 - Operations',
  },
  {
    key: 3,
    name: 'Support',
    nameFull: '03 - Support',
  },
  {
    key: 4,
    name: 'Airworthiness Limitations',
    nameFull: '04 - Airworthiness Limitations',
  },
  {
    key: 5,
    name: 'Time Limits/Maintenance Checks',
    nameFull: '05 - Time Limits/Maintenance Checks',
  },
  {
    key: 6,
    name: 'Dimensions and Areas',
    nameFull: '06 - Dimensions and Areas',
  },
  {
    key: 7,
    name: 'Lifting and Sorting',
    nameFull: '07 - Lifting and Sorting',
  },
  {
    key: 8,
    name: 'Levelling & Weighing',
    nameFull: '08 - Levelling & Weighing',
  },
  {
    key: 9,
    name: 'Towing & Taxing',
    nameFull: '09 - Towing & Taxing',
  },
  {
    key: 10,
    name: 'Parking, Mooring, Storage & Return To Service',
    nameFull: '10 - Parking, Mooring, Storage & Return To Service',
  },
  {
    key: 11,
    name: 'Placards & Markings',
    nameFull: '11 - Placards & Markings',
  },
  {
    key: 12,
    name: 'Servicing',
    nameFull: '12 - Servicing',
  },
  {
    key: 15,
    name: 'Aircrew Information',
    nameFull: '15 - Aircrew Information',
  },
  {
    key: 16,
    name: 'Change of Role',
    nameFull: '16 - Change of Role',
  },
  {
    key: 18,
    name: 'Vibration and Noise Analysis (Helicopters Only)',
    nameFull: '18 - Vibration and Noise Analysis (Helicopters Only)',
  },
  {
    key: 21,
    name: 'Air Conditioning',
    nameFull: '21 - Air Conditioning',
  },
  {
    key: 22,
    name: 'Auto Flight',
    nameFull: '22 - Auto Flight',
  },
  {
    key: 23,
    name: 'Communications',
    nameFull: '23 - Communications',
  },
  {
    key: 24,
    name: 'Electrical Power',
    nameFull: '24 - Electrical Power',
  },
  {
    key: 25,
    name: 'Equipment/Furnishings',
    nameFull: '25 - Equipment/Furnishings',
  },
  {
    key: 26,
    name: 'Fire Protection',
    nameFull: '26 - Fire Protection',
  },
  {
    key: 27,
    name: 'Flight Controls',
    nameFull: '27 - Flight Controls',
  },
  {
    key: 28,
    name: 'Fuel',
    nameFull: '28 - Fuel',
  },
  {
    key: 29,
    name: 'Hydraulic Power',
    nameFull: '29 - Hydraulic Power',
  },
  {
    key: 30,
    name: 'Ice and Rain Protection',
    nameFull: '30 - Ice and Rain Protection',
  },
  {
    key: 31,
    name: 'Indicating/Recording Systems',
    nameFull: '31 - Indicating/Recording Systems',
  },
  {
    key: 32,
    name: 'Landing Gear',
    nameFull: '32 - Landing Gear',
  },
  {
    key: 33,
    name: 'Lights',
    nameFull: '33 - Lights',
  },
  {
    key: 34,
    name: 'Navigation',
    nameFull: '34 - Navigation',
  },
  {
    key: 35,
    name: 'Oxygen',
    nameFull: '35 - Oxygen',
  },
  {
    key: 36,
    name: 'Pneumatic',
    nameFull: '36 - Pneumatic',
  },
  {
    key: 37,
    name: 'Vacuum',
    nameFull: '37 - Vacuum',
  },
  {
    key: 38,
    name: 'Water/Waste',
    nameFull: '38 - Water/Waste',
  },
  {
    key: 41,
    name: 'Water Ballast',
    nameFull: '41 - Water Ballast',
  },
  {
    key: 44,
    name: 'Cabin Systems',
    nameFull: '44 - Cabin Systems',
  },
  {
    key: 45,
    name: 'Central Maintenance System (CMS)',
    nameFull: '45 - Central Maintenance System (CMS)',
  },
  {
    key: 46,
    name: 'Information Systems',
    nameFull: '46 - Information Systems',
  },
  {
    key: 47,
    name: 'Nitrogen Generation System',
    nameFull: '47 - Nitrogen Generation System',
  },
  {
    key: 49,
    name: 'Airborne Auxiliary Power',
    nameFull: '49 - Airborne Auxiliary Power',
  },
  {
    key: 50,
    name: 'Cargo and Accessory Compartments',
    nameFull: '50 - Cargo and Accessory Compartments',
  },
  {
    key: 52,
    name: 'Doors',
    nameFull: '52 - Doors',
  },
  {
    key: 53,
    name: 'Fuselage',
    nameFull: '53 - Fuselage',
  },
  {
    key: 54,
    name: 'Nacelles/Pylons',
    nameFull: '54 - Nacelles/Pylons',
  },
  {
    key: 55,
    name: 'Stabilizers',
    nameFull: '55 - Stabilizers',
  },
  {
    key: 56,
    name: 'Windows',
    nameFull: '56 - Windows',
  },
  {
    key: 57,
    name: 'Wings',
    nameFull: '57 - Wings',
  },
  {
    key: 61,
    name: 'Propellers/Propulsion',
    nameFull: '61 - Propellers/Propulsion',
  },
  {
    key: 62,
    name: 'Rotor(s)',
    nameFull: '62 - Rotor(s)',
  },
  {
    key: 63,
    name: 'Rotor Drive(s)',
    nameFull: '63 - Rotor Drive(s)',
  },
  {
    key: 64,
    name: 'Tail Rotor',
    nameFull: '64 - Tail Rotor',
  },
  {
    key: 65,
    name: 'Tail Rotor Drive',
    nameFull: '65 - Tail Rotor Drive',
  },
  {
    key: 66,
    name: 'Folding Blades/Pylon',
    nameFull: '66 - Folding Blades/Pylon',
  },
  {
    key: 67,
    name: 'Rotors Flight Control',
    nameFull: '67 - Rotors Flight Control',
  },
  {
    key: 71,
    name: 'Power Plant',
    nameFull: '71 - Power Plant',
  },
  {
    key: 72,
    name: 'Engine Turbine/Turbo Prop Ducted Fan/Unducted Fan',
    nameFull: '72 - Engine Turbine/Turbo Prop Ducted Fan/Unducted Fan',
  },
  {
    key: 73,
    name: 'Engine Fuel and Control',
    nameFull: '73 - Engine Fuel and Control',
  },
  {
    key: 74,
    name: 'Ignition',
    nameFull: '74 - Ignition',
  },
  {
    key: 75,
    name: 'Air',
    nameFull: '75 - Air',
  },
  {
    key: 76,
    name: 'Engine Controls',
    nameFull: '76 - Engine Controls',
  },
  {
    key: 77,
    name: 'Engine Indicating',
    nameFull: '77 - Engine Indicating',
  },
  {
    key: 78,
    name: 'Exhaust',
    nameFull: '78 - Exhaust',
  },
  {
    key: 79,
    name: 'Oil',
    nameFull: '79 - Oil',
  },
  {
    key: 80,
    name: 'Starting',
    nameFull: '80 - Starting',
  },
  {
    key: 81,
    name: 'Turbines',
    nameFull: '81 - Turbines',
  },
  {
    key: 82,
    name: 'Water Injection',
    nameFull: '82 - Water Injection',
  },
  {
    key: 83,
    name: 'Accessory Gear-Boxes',
    nameFull: '83 - Accessory Gear-Boxes',
  },
  {
    key: 84,
    name: 'Propulsion Augmentation',
    nameFull: '84 - Propulsion Augmentation',
  },
  {
    key: 85,
    name: 'Reciprocating Engine',
    nameFull: '85 - Reciprocating Engine',
  },
];

export default ata;
