import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import icon from '../../assets/icon-card-crs-signoff.svg';
import AircraftSettingsCard from './AircraftSettingsCard';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  StyledInput,
  Text,
} from './AircraftAdvancedSettings';
import ShowHideToggle from './ShowHideToggle';

interface AircraftCRSResolutionFieldsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const Header = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #242d41;
  margin: 0 0 16px 16px;
`;

const Divider = styled.div`
  margin: 0 16px 16px;
  background-color: rgba(36, 45, 65, 0.05);
  height: 1px;
  width: -webkit-fill-available;
`;

const AircraftCRSResolutionFields: React.FC<AircraftCRSResolutionFieldsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const { formatMessage } = useIntl();

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleNameChange = (value: string, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], name_override: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={icon}
            title="Resolution workflow configuration"
            buttonText="Edit workflow configuration"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
            subTitle="Including part replacement fields"
          />
        }
      >
        <Header>{formatMessage({ id: 'text.partReplacements' })}</Header>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>{formatMessage({ id: 'text.partReplacementPositionField' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.part_info_position?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.part_info_position?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'part_info_position')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.part_info_position?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>{formatMessage({ id: 'text.nameOverride' })}</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.part_info_position?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.part_info_position?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'part_info_position')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>{formatMessage({ id: 'text.partReplacementDescriptionField' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.part_info_description?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.part_info_description?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'part_info_description')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.part_info_description?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>{formatMessage({ id: 'text.nameOverride' })}</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.part_info_description?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.part_info_description?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'part_info_description')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>{formatMessage({ id: 'text.partReplacementTraceOffField' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.part_info_batch_off?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.part_info_batch_off?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'part_info_batch_off')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.part_info_batch_off?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>{formatMessage({ id: 'text.nameOverride' })}</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.part_info_batch_off?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.part_info_batch_off?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'part_info_batch_off')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>{formatMessage({ id: 'text.partReplacementBatchOnField' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.part_info_batch?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.part_info_batch?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'part_info_batch')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.part_info_batch?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>{formatMessage({ id: 'text.nameOverride' })}</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.part_info_batch?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.part_info_batch?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'part_info_batch')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <Divider />
        <Header>{formatMessage({ id: 'text.crsSignoff' })}</Header>
        <StyledGrid>
          <ItemWrapper span="2">
            <Label edit={editMode}>{formatMessage({ id: 'text.titleForEngineerLicenceAuthorisationField' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.crs_engineer_licence?.name_override || '-'}</Text>
            ) : (
              <StyledInput
                value={aircraft?.standard_fields?.crs_engineer_licence?.name_override}
                type="text"
                onChange={(e): void => handleNameChange(e.target.value, 'crs_engineer_licence')}
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                {formatMessage({ id: 'text.save' })}
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftCRSResolutionFields;
