import React, { PureComponent } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { injectIntl } from 'react-intl';
import { Button as TFButton, Search, Modal } from '@arcflight/tf-component-library';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyStateIntermittent from '../../assets/emptyState/empty-state-intermittent-faults.svg';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { getIntermittentFaults, deleteIntermittentFault } from '../../services/api';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { saveIntermittentFaults } from '../../models/intermittentFaults/actions';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { AircraftPermission, AircraftResource } from '../../models/aircraft';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import plusIcon from '../../assets/plus.svg';
import IntermittentFaultsTable from './IntermittentFaultsTable';
import styles from './IntermittentFaults.module.less';
import IntermittentFaultsDrawer from './IntermittentFaultsDrawer';
import IntermittentFaultOccurrenceDrawer from './IntermittentFaultOccurrenceDrawer';

class IntermittentFaults extends PureComponent {
  static propTypes = {
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
    aircraftId: PropTypes.string.isRequired,
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    menu: PropTypes.object.isRequired,
    changeDrawerContentDispatch: PropTypes.func.isRequired,
    changeDrawerModeDispatch: PropTypes.func.isRequired,
    changeDrawerVisiblityDispatch: PropTypes.func.isRequired,
    saveIF: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      intermittentFaults: [],
      searchValue: '',
      page: 1,
      limit: 10,
      sortBy: [],
      intermittentFaultsCount: 0,
      resetToOne: false,
      searchVisible: false,
      reset: false,
      loading: false,
      modalVisible: false,
      deleteModalId: null,
      originalList: [],
    };
  }

  componentDidMount() {
    this.getIntermittentFaults(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, searchValue, page, limit, sortBy } = this.state;
    const firstSortBy = sortBy.length === 0 && prevState.sortBy.length === 0;
    if (!loading && (page !== prevState.page || limit !== prevState.limit)) {
      this.getIntermittentFaults();
    }
    if (!loading && !firstSortBy && sortBy !== prevState.sortBy) {
      this.handleFilterData();
    }
    if (!loading && searchValue !== prevState.searchValue) {
      this.getDebounceIntermittentFaults();
    }
  }

  getDebounceIntermittentFaults = debounce(() => {
    this.setState({ resetToOne: true, page: 1 }, () => this.getIntermittentFaults());
  }, 300);

  handleFilterData = () => {
    this.setState({ resetToOne: true, page: 1 }, () => this.getIntermittentFaults());
  };

  getIntermittentFaultName = (singular) => {
    const { userSettings, aircraftId, aircraftMap } = this.props;
    let name = 'Intermittent Faults';
    const currentOrgId = aircraftMap.get(aircraftId)?.operator_id;
    if (userSettings.details) {
      const currentOrg = userSettings.details.operators.find((o) => o.id === currentOrgId);
      if (currentOrg) {
        name = singular
          ? currentOrg.operator_setting?.intermittent_fault_name_singular
          : currentOrg.operator_setting?.intermittent_fault_name_plural;
      }
    }
    return name;
  };

  getIntermittentFaults = async (first) => {
    const { match, saveIF } = this.props;
    const { page, limit, sortBy, searchValue } = this.state;
    this.setState({ loading: true });
    const sort_by = sortBy.reduce((obj, item) => ({ ...obj, [item.id]: item.desc ? 'desc' : 'asc' }), {});
    const payload = {
      aircraft_id: match.params.id,
      page,
      limit,
      sort_by,
    };
    if (searchValue) payload.search = searchValue;
    const data = await getIntermittentFaults(payload);

    if (first && data) {
      saveIF(data?.intermittent_faults);
      this.setState({ originalList: data?.intermittent_faults });
    }
    this.setState({
      loading: false,
      intermittentFaults: data.intermittent_faults,
      intermittentFaultsCount: data.count,
      resetToOne: false,
    });
  };

  onSortChange = (sortBy) => {
    this.setState({ sortBy });
  };

  handleAddNewFault = () => {
    const {
      changeDrawerVisiblityDispatch,
      changeDrawerContentDispatch,
      changeDrawerModeDispatch,
      aircraftId,
      aircraftMap,
      userSettings,
    } = this.props;
    const { loading } = this.state;
    const currentOrgId = aircraftMap.get(aircraftId)?.operator_id;
    const loggedInUser = userSettings?.details?.people.find((p) => p.organisation.id === currentOrgId);
    const emptyFault = {
      reported_at: moment().utc().format(),
      reported_by_id: loggedInUser.id,
    };
    changeDrawerVisiblityDispatch(true);
    changeDrawerModeDispatch('add');
    changeDrawerContentDispatch({
      content: (
        <IntermittentFaultsDrawer
          fault={emptyFault}
          onUpdatedFault={() => this.getIntermittentFaults(true)}
          intermittentFaultName={this.getIntermittentFaultName(true)}
          loading={loading}
          sectorFault={false}
          handleDeleteIntermittentFault={this.handleDeleteIntermittentFault}
        />
      ),
    });
  };

  handleDeleteIntermittentFault = (id) => {
    this.setState({ modalVisible: true, deleteModalId: id });
  };

  handleModalClose = () => {
    this.setState({ modalVisible: false });
  };

  handleRowClick = (row, type) => {
    const { changeDrawerVisiblityDispatch, changeDrawerContentDispatch } = this.props;
    const { loading } = this.state;
    changeDrawerVisiblityDispatch(true);
    if (type === 'add') {
      changeDrawerContentDispatch({
        content: <IntermittentFaultOccurrenceDrawer faultId={row.id} onNewFault={() => this.getIntermittentFaults()} />,
        backButton: true,
      });
    } else {
      changeDrawerContentDispatch({
        content: (
          <IntermittentFaultsDrawer
            fault={row}
            onUpdatedFault={() => this.getIntermittentFaults(true)}
            intermittentFaultName={this.getIntermittentFaultName(true)}
            loading={loading}
            convertable={type === 'convert' || false}
            handleDeleteIntermittentFault={this.handleDeleteIntermittentFault}
          />
        ),
      });
    }
  };

  handleEditDrawer = (row) => {
    const { changeDrawerVisiblityDispatch, changeDrawerContentDispatch, changeDrawerModeDispatch } = this.props;
    const { loading } = this.state;
    changeDrawerVisiblityDispatch(true);
    changeDrawerContentDispatch({
      content: (
        <IntermittentFaultsDrawer
          fault={row}
          onUpdatedFault={() => this.getIntermittentFaults(true)}
          intermittentFaultName={this.getIntermittentFaultName(true)}
          loading={loading}
          handleDeleteIntermittentFault={this.handleDeleteIntermittentFault}
        />
      ),
    });
    changeDrawerModeDispatch('edit');
  };

  confirmDeleteIntermittentFault = () => {
    const { deleteModalId } = this.state;
    const { changeDrawerVisiblityDispatch } = this.props;
    new Promise((res) => res(deleteIntermittentFault(deleteModalId))).then(() => {
      this.getIntermittentFaults();
      changeDrawerVisiblityDispatch(false);
    });
    this.setState({ modalVisible: false, deleteModalId: null });
  };

  resetFilters = () => {
    this.setState({
      reset: true,
      sortBy: [],
      searchValue: '',
      page: 1,
    });
  };

  setSearchValue = (value) => {
    this.setState({ searchValue: value, reset: false });
  };

  showSearchInput = () => {
    const { searchVisible, searchValue } = this.state;
    if (searchVisible && searchValue) {
      this.setSearchValue(null);
    }
    this.setState({
      searchVisible: !searchVisible,
    });
  };

  render() {
    const {
      userSettings,
      intl: { formatMessage },
      match,
      menu,
    } = this.props;

    const {
      intermittentFaults,
      searchValue,
      page,
      limit,
      intermittentFaultsCount,
      resetToOne,
      modalVisible,
      reset,
      originalList,
    } = this.state;
    const orgFaultNameSingular = this.getIntermittentFaultName(true);
    const orgFaultNamePlural = this.getIntermittentFaultName();
    let buttonText = `Add ${orgFaultNameSingular.toLocaleLowerCase()}`;
    if (window.innerWidth < 1100) buttonText = 'Add';
    if (!menu.collapsed && window.innerWidth < 1250) buttonText = 'Add';
    return (
      <>
        <InnerMenuLayout>
          <Card className={styles.intermittentFaultsCard}>
            <div className={styles.tableHeader}>
              <span className={styles.tableTitle}>{`${orgFaultNamePlural} (${intermittentFaultsCount})`}</span>
              <div className={styles.tableActions}>
                <div id="searchWrapper" className={styles.searchWrapper}>
                  <Search
                    onChange={(e) => this.setSearchValue(e.currentTarget.value)}
                    onClear={() => this.setSearchValue('')}
                    reset={reset}
                  />
                </div>
                <AircraftAuthenticationWrapper
                  aircraftId={match.params.id}
                  requiredResource={AircraftResource.DEFECT}
                  requiredPermissionLevel={AircraftPermission.CREATE}
                >
                  <TFButton size={ButtonSize.MEDIUM} onClick={this.handleAddNewFault}>
                    <span className={styles.buttonText}>
                      <img src={plusIcon} alt="plus icon" /> {buttonText}
                    </span>
                  </TFButton>
                </AircraftAuthenticationWrapper>
              </div>
            </div>
            {intermittentFaults && intermittentFaults.length > 0 ? (
              <IntermittentFaultsTable
                data={intermittentFaults}
                userSettings={userSettings}
                globalFilter={searchValue}
                match={match}
                dateFormat={userSettings?.dateFormat}
                loading={false}
                handleDeleteIntermittentFault={this.handleDeleteIntermittentFault}
                onPaginationChange={(currentPage, numberOfItems) => {
                  if (numberOfItems !== limit) {
                    this.setState({ page: 1, limit: numberOfItems });
                  } else if (currentPage !== page) {
                    this.setState({ page: currentPage });
                  }
                }}
                onSortChange={(sort) => this.onSortChange(sort)}
                total={intermittentFaultsCount}
                pageSize={limit}
                pageIndex={page - 1}
                resetToOne={resetToOne}
                onRowClick={(row, type) => this.handleRowClick(row, type)}
                handleEditDrawer={this.handleEditDrawer}
              />
            ) : (
              <EmptyState
                image={EmptyStateIntermittent}
                text={
                  originalList.length === 0
                    ? `No ${orgFaultNamePlural.toLowerCase()}`
                    : `We couldn't find any matching ${orgFaultNamePlural.toLowerCase()}`
                }
                subText={
                  originalList.length === 0
                    ? `You can add your first ${orgFaultNameSingular.toLowerCase()} item now.`
                    : 'Try adjusting your filters or searching with another term.'
                }
                button={originalList.length === 0 ? `Add ${orgFaultNameSingular.toLowerCase()}` : 'Clear all'}
                buttonAction={originalList.length === 0 ? this.handleAddNewFault : this.resetFilters}
              />
            )}
          </Card>
        </InnerMenuLayout>
        <Modal isOpen={modalVisible} width={420} handleClose={() => this.setState({ modalVisible: false })}>
          <div className={styles.modalContentWrapper}>
            <div className={styles.modalTitle}>{formatMessage({ id: 'title.deleteItem' })}</div>
            <div className={styles.modalMessage}>
              {`${formatMessage({
                id: 'form.question.areYouSureDeleteFault',
              })} ${formatMessage({
                id: 'form.labels.cannotBeUndone',
              })}`}
            </div>
            <div className={styles.modalButtonWrapper}>
              <div className={styles.submitButton}>
                <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={this.confirmDeleteIntermittentFault}>
                  Delete
                </TFButton>
              </div>
              <TFButton
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                primary={false}
                onClick={() => this.setState({ modalVisible: false })}
              >
                Cancel
              </TFButton>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(
  injectIntl(
    connect(
      ({ userSettings, aircraft, menu }, { match }) => ({
        userSettings,
        aircraftId: match.params.id,
        aircraftMap: aircraft.aircraftMap,
        menu,
      }),
      (dispatch) => ({
        changeDrawerVisiblityDispatch: (value) => {
          return dispatch(changeDrawerVisibility({ payload: value }));
        },
        changeDrawerModeDispatch: (value) => {
          dispatch(changeDrawerMode({ payload: value }));
        },
        changeDrawerContentDispatch: (value) => {
          dispatch(changeDrawerContent({ payload: value }));
        },
        saveIF: (value) => {
          dispatch(saveIntermittentFaults({ payload: value }));
        },
      }),
    )(IntermittentFaults),
  ),
);
