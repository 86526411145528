import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { removeMxItem } from '../../models/maintenance/actions';
import { changeModalContent, changeModalVisibility } from '../../models/drawer';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import globalStyles from '../../utils/globalStyles.module.less';
import { postWorkpack, putWorkpack } from '../../services/api';
import styles from './index.module.less';

const DeleteButton = styled.button`
  border: none;
  background: #d01e27;
  color: #fff;
  border-radius: 16px;
  font-weight: 500;
  padding: 0 16px;
  height: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  min-width: 190px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const WorkpackContent = ({
  items,
  onSuccess,
  intl: { formatMessage },
  workpacks,
  aircraft,
  poIntl,
  handleApplyTolerance,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [workpackID, setWorkpackID] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const {
    userSettings: {
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state) => state);

  const { id: aircraftId } = useParams();

  const hideWorkpackContent = operators?.find((op) => op.id === aircraftMap.get(aircraftId)?.operator_id)
    ?.operator_setting?.hide_add_workpack;

  const dispatch = useDispatch();

  const handleDelete = async () => {
    const dispatchPromises = items.map((id) => {
      return dispatch(removeMxItem({ payload: { id } }));
    });
    await Promise.all(dispatchPromises);
    dispatch(changeModalVisibility({ payload: false }));
  };

  const handleDeleteClick = () => {
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title:
            items?.length === 1
              ? formatMessage({ id: 'title.deleteItem' })
              : formatMessage({ id: 'title.deleteItems' }),
          text:
            items?.length === 1
              ? formatMessage({ id: 'text.deleteConfirm' })
              : formatMessage({ id: 'text.deleteConfirmMulti' }),
          saveButtonText: formatMessage({ id: 'text.delete' }),
          saveAction: handleDelete,
        },
      }),
    );
  };

  const handleSubmit = async () => {
    if (workpackID) {
      // add to existing workpack
      setSubmitting(true);
      try {
        const response = await putWorkpack({
          id: workpackID,
          body: {
            workpack_items: items.map((item) => ({ mx_item_id: item })),
          },
        });
        if (response && response.statusCode === 200) {
          dispatch(
            addToast({
              payload: {
                title: formatMessage({ id: 'message.workpackUpdateSuccess' }),
                type: ToastTypes.SUCCESS,
                category: ToastCategories.FLASH,
                message: '',
              },
            }),
          );
          onSuccess();
        } else {
          if (response && response.body && response.body.workpack_items) {
            dispatch(
              addToast({
                payload: {
                  title: response.body.workpack_items,
                  type: ToastTypes.ERROR,
                  category: ToastCategories.FLAG,
                  message: '',
                },
              }),
            );
          } else if (response?.body?.error === "Validation failed: Workpack items MX Items aren't unique") {
            dispatch(
              addToast({
                payload: {
                  type: ToastTypes.ERROR,
                  title: formatMessage({ id: 'message.addToWorkpackError' }),
                  message: formatMessage({ id: 'text.atleast1ItemAppearsInWorkpack' }),
                  category: ToastCategories.FLAG,
                },
              }),
            );
          } else {
            dispatch(
              addToast({
                payload: {
                  type: ToastTypes.ERROR,
                  title: formatMessage({ id: 'message.workpackUpdateError' }),
                  message: '',
                  category: ToastCategories.FLAG,
                },
              }),
            );
          }
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        dispatch(
          addToast({
            payload: {
              type: ToastTypes.ERROR,
              title: formatMessage({ id: 'message.workpackUpdateError' }),
              message: '',
              category: ToastCategories.FLAG,
            },
          }),
        );
      }
    } else if (searchValue) {
      // create new workpack
      setSubmitting(true);
      try {
        const response = await postWorkpack({
          po_number: searchValue,
          date: moment().format('YYYY-MM-DD'),
          status: 'draft',
          workpack_items: items.map((item) => ({ mx_item_id: item })),
          aircraft_id: aircraft.id,
        });

        if (response && response.statusCode === 201) {
          dispatch(
            addToast({
              payload: {
                type: ToastTypes.SUCCESS,
                title: formatMessage({ id: 'message.workpackCreateSuccess' }),
                message: '',
                category: ToastCategories.FLASH,
              },
            }),
          );
          onSuccess();
        } else {
          dispatch(
            addToast({
              payload: {
                type: ToastTypes.ERROR,
                title: formatMessage({ id: 'message.workpackUpdateError' }),
                message: '',
                category: ToastCategories.FLAG,
              },
            }),
          );
          setSubmitting(false);
        }
      } catch (error) {
        dispatch(
          addToast({
            payload: {
              type: ToastTypes.ERROR,
              title: formatMessage({ id: 'message.workpackUpdateError' }),
              message: '',
              category: ToastCategories.FLAG,
            },
          }),
        );
        setSubmitting(false);
      }
    } else {
      dispatch(
        addToast({
          payload: {
            type: ToastTypes.ERROR,
            title: formatMessage({ id: 'message.poRequired' }, { po: poIntl.po_short_name, suffix: poIntl.po_suffix }),
            message: '',
            category: ToastCategories.FLAG,
          },
        }),
      );
    }
  };

  return (
    <ContentWrapper>
      {hideWorkpackContent ? null : (
        <div id="pageOverlay" className={styles.inputWrapper}>
          <span className={styles.workpackText}>
            {workpacks && workpacks.length > 0
              ? formatMessage({ id: 'text.addToExistingWorkpack' })
              : formatMessage({ id: 'text.createNewWorkpack' })}
            :
          </span>
          <SearchDropdown
            options={workpacks}
            handleSelect={setWorkpackID}
            handleSearch={setSearchValue}
            valueKey="id"
            textKey="po_number"
            placement="bottom"
            placeholder={formatMessage(
              { id: 'text.enterPONumber' },
              { po: poIntl.po_short_name, suffix: poIntl.po_suffix },
            )}
          />
        </div>
      )}
      <ButtonWrapper>
        {hideWorkpackContent ? null : (
          <Button
            size={ButtonSize.MEDIUM}
            className={`${globalStyles.addNewButton} ${globalStyles.buttonPreserveText} ${styles.addWorkpackButton}`}
            onClick={() => handleSubmit()}
            loading={submitting}
            data-test="approveButton"
          >
            <span className={globalStyles.buttonText}>
              {workpackID
                ? formatMessage({ id: 'text.addToWorkpack' })
                : formatMessage({ id: 'text.createNewWorkpack' })}
            </span>
          </Button>
        )}
        <Button size={ButtonSize.MEDIUM} onClick={() => handleApplyTolerance()} data-test="toleranceButton">
          {formatMessage({ id: 'text.applyTolerance' })}
        </Button>
        <DeleteButton onClick={handleDeleteClick}>Delete</DeleteButton>
      </ButtonWrapper>
    </ContentWrapper>
  );
};

WorkpackContent.propTypes = {
  items: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  workpacks: PropTypes.array.isRequired,
  aircraft: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  poIntl: { po_short_name: PropTypes.string, po_long_name: PropTypes.string, po_suffix: PropTypes.string }.isRequired,
};

export default injectIntl(WorkpackContent);
