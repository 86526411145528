import { Accordion, Button } from '@arcflight/tf-component-library';
import React, { useState } from 'react';
import styled from 'styled-components';
import metricsIcon from '../../assets/icon-card-metrics.svg';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { AccordionWrapper } from './AircraftAdvancedSettings';
import AircraftSettingsCard from './AircraftSettingsCard';

interface AircraftMetricsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  padding: 0 16px 16px 16px;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '600' : '400')};
  line-height: ${({ edit }): string => (edit ? '1.33' : 'normal')};
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
  min-height: 18px;
`;

const ItemWrapper = styled.div`
  grid-column: ${({ span }): string => (span ? `span ${span}` : 'auto')};
  padding-right: 24px;
  @media (max-width: 1970px) {
    grid-column: span 2;
  }
  @media (max-width: 1540px) {
    grid-column: auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 16px 0 16px 16px;
`;

const SaveWrapper = styled.div`
  margin-right: 16px;
`;

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

const AircraftMetrics: React.FC<AircraftMetricsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleInputChange = (value: any, key: string): void => {
    const newAppSettings = aircraft?.app_settings;
    newAppSettings[`${key}`] = value;
    updateAircraftData([{ value: newAppSettings, key: 'app_settings' }]);
  };

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={metricsIcon}
            title="Metrics"
            buttonText="Edit metrics"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
            subTitle="When enabled, a warning line will show on the oil metric graphs
      if the oil consumption exceeds the value set."
          />
        }
      >
        <StyledGrid>
          <ItemWrapper data-testid="AircraftMetrics--EngineOil">
            <Label edit={editMode}>{`Engine oil consumption threshold (${aircraft?.oil_unit}/hr)`}</Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.engine_oil_consumption_threshold || '-'}</Text>
            ) : (
              <StyledInput
                value={aircraft?.app_settings?.engine_oil_consumption_threshold}
                type="text"
                onChange={(e): void => handleInputChange(e.target.value, 'engine_oil_consumption_threshold')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper data-testid="AircraftMetrics--APUOil">
            <Label edit={editMode}>{`APU oil consumption threshold (${aircraft?.oil_unit}/hr)`}</Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.apu_oil_consumption_threshold || '-'}</Text>
            ) : (
              <StyledInput
                value={aircraft?.app_settings?.apu_oil_consumption_threshold}
                type="text"
                onChange={(e): void => handleInputChange(e.target.value, 'apu_oil_consumption_threshold')}
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftMetrics;
