import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { getAircraftTripEntries } from '../../models/trips/actions';
import TripsTable from '../../components/TripsTable';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import TFCard from '../../components/TFCard/TFCard';
import { getSingleAircraft } from '../../models/aircraft/actions';

class Trips extends PureComponent {
  static propTypes = {
    fetchAircraft: PropTypes.func.isRequired,
    getAircraftTrips: PropTypes.func.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    ttl: PropTypes.number.isRequired,
  };

  state = {
    loading: true,
    isEditTripDrawerVisible: false,
  };

  componentDidMount() {
    const { selectedAircraft, ttl, fetchAircraft, getAircraftTrips } = this.props;
    if (!selectedAircraft.id || Date.now() - selectedAircraft.lastFetched > ttl) {
      getAircraftTrips();
      fetchAircraft().then(() => {
        this.setState({ loading: false });
      });
    } else if (selectedAircraft.id) {
      this.setState({ loading: false });
    }
  }

  handleEditClick = async () => {
    const { isEditTripDrawerVisible } = this.state;
    await new Promise((resolve) =>
      isEditTripDrawerVisible
        ? this.setState({
            isEditTripDrawerVisible: !isEditTripDrawerVisible,
          })
        : this.setState(
            {
              isEditTripDrawerVisible: !isEditTripDrawerVisible,
            },
            resolve,
          ),
    );
  };

  render() {
    const { selectedAircraft } = this.props;
    const { loading } = this.state;

    return (
      <InnerMenuLayout loading={loading}>
        <TFCard>
          <TripsTable
            showSearchAdd
            aircraft={selectedAircraft}
            data-test="tripsTable"
            handleEditClick={this.handleEditClick}
          />
        </TFCard>
      </InnerMenuLayout>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    (
      { aircraft, userSettings, trips, menu },
      {
        match: {
          params: { id },
        },
      },
    ) => {
      const currentAircraft = aircraft.aircraftMap.get(id) || {};
      const tripsArr = Array.from(trips.tripsMap.values())
        .filter((tripEntry) => currentAircraft && tripEntry.aircraft_id === currentAircraft.id)
        .sort((a, b) => moment(b.date).diff(moment(a.date)));
      return {
        selectedAircraft: aircraft.aircraftMap.get(id) || {},
        ttl: aircraft.ttl,
        userSettings,
        tripsArr,
        menu,
      };
    },
    (
      dispatch,
      {
        match: {
          params: { id },
        },
      },
    ) => ({
      fetchAircraft: () => {
        return dispatch(
          getSingleAircraft({
            payload: id,
          }),
        );
      },
      getAircraftTrips: () => {
        return dispatch(
          getAircraftTripEntries({
            payload: { id },
          }),
        );
      },
    }),
  ),
)(Trips);
