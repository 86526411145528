import { useMutation } from '@tanstack/react-query';
import { deleteFRATQuestion } from '../../services/api';

const useDeleteFRATQuestion = ({ handleDeleteQuestionOnSuccess, handleDeleteQuestionOnError }) => {
  const deleteQuestion = useMutation({
    mutationFn: (payload: { id: string }) => {
      return deleteFRATQuestion(payload);
    },
    onSuccess: (data) => {
      handleDeleteQuestionOnSuccess(data);
    },
    onError: (err: any) => handleDeleteQuestionOnError(err),
  });

  return deleteQuestion;
};

export default useDeleteFRATQuestion;
