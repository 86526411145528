import { useMutation } from '@tanstack/react-query';
import { updateFRATQuestion } from '../../services/api';

const useUpdateFRATQuestion = ({ handleUpdateQuestionOnSuccess, handleUpdateQuestionOnError }) => {
  const updateQuestion = useMutation({
    mutationFn: (payload: {
      question: string;
      flight_risk_assessment_options_attributes: {
        id?: string;
        _destroy?: boolean;
        option: string;
        sequence_number: number;
        weight: number;
      }[];
    }) => {
      return updateFRATQuestion(payload);
    },
    onSuccess: (data) => {
      handleUpdateQuestionOnSuccess(data);
    },
    onError: (err: any) => handleUpdateQuestionOnError(err),
  });

  return updateQuestion;
};

export default useUpdateFRATQuestion;
