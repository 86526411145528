/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ata from '../../../utils/ata';
import globalStyles from '../../../utils/globalStyles.module.less';
import { ItemWrapper, Label } from '../../CommonStyledComponents/CommonStyledComponents';
import Dropdown from '../../TFDropdown/TFDropdown';
import TFInput from '../../TFInput/TFInput';
import BaseModalFields from './BaseModalFields';

const { TextArea } = Input;

const StyledLabel = styled.span`
  color: ${({ theme }) => theme.colours.black80Alpha};
`;

const MXModalOOPContents = ({ updateItemData, item }) => {
  const [ataOptions, setATAOptions] = useState([]);
  const [foundAta, setFoundAta] = useState(null);

  const { formatMessage } = useIntl();

  const getAllAtaOptions = () => {
    const options = ata.map((ataItem) => {
      return { title: ataItem.nameFull, value: ataItem.key };
    });
    return options;
  };

  useEffect(() => {
    if (ata) {
      const options = getAllAtaOptions();
      setATAOptions(options);
    }
  }, []);

  useEffect(() => {
    if (item?.ata_number) {
      const found = ataOptions.find((ataItem) => ataItem.value === item.ata_number);
      setFoundAta(found);
    }
  }, [ataOptions, item]);

  return (
    <>
      <ItemWrapper marginBottom="16px" data-testid="MXModalOOPContents-ATA">
        <Dropdown
          options={ataOptions}
          label={formatMessage({ id: 'form.labels.ataChapter' })}
          initialValue={foundAta || null}
          onSelect={(option) => updateItemData([{ value: option.value, key: 'ata_chapter' }])}
          searchable
          placeholder={formatMessage({ id: 'form.placeholder.searchATA' })}
        />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalOOPContents-TaskDescription">
        <Label edit>{formatMessage({ id: 'form.labels.taskDescription' })}</Label>
        <TFInput id="" value={item?.name} onChange={(e) => updateItemData([{ value: e.target.value, key: 'name' }])} />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalOOPContents-Notes">
        <Label edit>
          {`${formatMessage({ id: 'form.labels.notes' })}`}{' '}
          <StyledLabel>
            {`${formatMessage({
              id: 'form.labels.optional',
            })}`}
          </StyledLabel>
        </Label>
        <TextArea
          className={globalStyles.formTextArea}
          spellCheck="false"
          rows={2}
          data-test="formTextAreaNotes"
          value={item?.repeat_remarks}
          onChange={(e) => updateItemData([{ value: e.target.value, key: 'repeat_remarks' }])}
        />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalOOPContents-TaskReference">
        <Label edit>
          {formatMessage({ id: 'form.labels.taskRef' })}
          <StyledLabel>
            {formatMessage({
              id: 'form.labels.optional',
            })}
          </StyledLabel>
        </Label>
        <TFInput
          id=""
          value={item?.reference}
          onChange={(e) => updateItemData([{ value: e.target.value, key: 'reference' }])}
        />
      </ItemWrapper>
      <BaseModalFields item={item} updateItemData={updateItemData} />
    </>
  );
};

MXModalOOPContents.propTypes = {
  item: PropTypes.object,
  updateItemData: PropTypes.func.isRequired,
};

MXModalOOPContents.defaultProps = {
  item: null,
};

export default MXModalOOPContents;
