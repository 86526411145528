import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import styles from './MXTimeDetails.module.less';

const MXEstimatedDetails = ({ title, item, layout }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {title && (
        <div className={styles.itemTitle} data-test="itemTitle">
          {title}
        </div>
      )}
      <div className={`${styles.itemDetails} ${layout === 'inline' ? styles.itemDetailsInline : undefined}`}>
        <p>
          <span className={styles.columnTitle} data-test="itemDue">
            {formatMessage({ id: 'title.due' })}:
          </span>
          <span className={styles.columnSpan}>
            <span className={`${styles.inlineSpan} ${styles[`span${item.due_status}`]}`} data-test="dueValue">
              {item.due || formatMessage({ id: 'title.notAvailableShort' })}
            </span>
          </span>
        </p>
        {/* Temporarily removed as CORE don't return (yet) info if item is in package or not */}
        {/* <p>
          <span className={styles.columnTitle} data-test="itemPackage">
            {formatMessage({ id: 'text.package' })}:
          </span>
          <span className={styles.columnSpan}>
            <span className={`${styles.inlineSpan} ${styles[`span${item.package_status}`]}`} data-test="packageValue">
              {item.package || formatMessage({ id: 'title.notAvailableShort' })}
            </span>
          </span>
        </p> */}
      </div>
    </div>
  );
};

MXEstimatedDetails.defaultProps = {
  title: '',
  layout: null,
};

MXEstimatedDetails.propTypes = {
  title: PropTypes.string,
  item: PropTypes.object.isRequired,
  layout: PropTypes.string,
};

export default MXEstimatedDetails;
