import { Person } from '../people';

export const initialState: UserSettingsState = {
  details: undefined,
  id: null,
  avatar: 'init', // for cache busting
  dateFormat: 'YYYY-MM-DD',
  selectedView: 'table',
  tableAnimationClass: 'defaultTable',
  listAnimationClass: 'defaultList',
  lastFetched: Date.now(),
  logbook_columns: [],
  ui: {
    colorWeak: false,
    navTheme: 'dark',
    primaryColor: '#126fd6',
    layout: 'sidemenu',
    contentWidth: 'Fluid',
    fixedHeader: false,
    autoHideHeader: false,
    fixSiderbar: false,
  },
  displayName: '',
  currentOrg: undefined,
};

export enum UserSettingsActionTypes {
  SAVE = 'userSettings/save',
  SET_SELECTED_VIEW = 'userSettings/setSelectedView',
  UPDATE_DETAILS = 'userSettings/updateDetails',
  SAVE_SINGLE = 'userSettings/saveSingle',
  UPDATE_OPERATOR_SETTINGS = 'userSettings/updateOperatorSettings',
  SET_FLEET_STYLE = 'userSettings/setFleetStyle',
  ADD_FAVOURITE_AIRCRAFT_ID = 'userSettings/addFavouriteAircraftId',
  REMOVE_FAVOURITE_AIRCRAFT_ID = 'userSettings/removeFavouriteAircraftId',
}

interface SaveUserSettingsActionType {
  type: UserSettingsActionTypes.SAVE;
  payload: UserSettings;
  details: User;
}

interface SaveSingleUserSettingsActionType {
  type: UserSettingsActionTypes.SAVE_SINGLE;
  payload: UserSettings;
  details: User;
}

interface SetSelectedViewUserSettingsActionType {
  type: UserSettingsActionTypes.SET_SELECTED_VIEW;
  payload: UserSettings;
}

interface UpdateDetailsUserSettingsActionType {
  type: UserSettingsActionTypes.UPDATE_DETAILS;
  payload: UserSettings;
  details: User;
}

interface UpdateOperatorSettingsActionType {
  type: UserSettingsActionTypes.UPDATE_OPERATOR_SETTINGS;
  payload: Operator;
}

interface SetFleetStyleUserSettingsActionType {
  type: UserSettingsActionTypes.SET_FLEET_STYLE;
  fleetStyle: string;
}

interface AddFavouriteAircraftIdUserSettingsActionType {
  type: UserSettingsActionTypes.ADD_FAVOURITE_AIRCRAFT_ID;
  aircraftId: string;
}

interface RemoveFavouriteAircraftIdUserSettingsActionType {
  type: UserSettingsActionTypes.REMOVE_FAVOURITE_AIRCRAFT_ID;
  aircraftId: string;
}

type UserSettingsAction =
  | SaveUserSettingsActionType
  | SetSelectedViewUserSettingsActionType
  | UpdateDetailsUserSettingsActionType
  | UpdateOperatorSettingsActionType
  | SaveSingleUserSettingsActionType
  | SetFleetStyleUserSettingsActionType
  | AddFavouriteAircraftIdUserSettingsActionType
  | RemoveFavouriteAircraftIdUserSettingsActionType;

export default function (state = initialState, action: UserSettingsAction): typeof initialState {
  switch (action.type) {
    case UserSettingsActionTypes.SAVE: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
        id: action.payload.id,
        avatar: action.payload.avatar || state.avatar,
        dateFormat: action.payload.date_format,
        logbook_columns: action.payload?.logbook_columns,
        lastFetched: Date.now(),
        displayName: `${action.payload.first_name} ${action.payload.last_name}`,
      };
    }
    // case UserSettingsActionTypes.SAVE_SINGLE: {
    //   return {
    //     currentOrg: {
    //       id: action.payload.id,
    //       avatar_url: action.payload.avatar || state.avatar,
    //       displayName: `${action.payload.first_name} ${action.payload.last_name}`,
    //     },
    //   };
    // }
    case UserSettingsActionTypes.SET_SELECTED_VIEW: {
      return {
        ...state,
        selectedView: action.payload.selectedView,
        tableAnimationClass: 'defaultTable',
        listAnimationClass: 'defaultList',
      };
    }
    case UserSettingsActionTypes.SET_FLEET_STYLE: {
      return {
        ...state,
        details: {
          ...state.details,
          dash_preferences: {
            ...state.details.dash_preferences,
            fleet_style: action.fleetStyle,
          },
        },
      };
    }
    case UserSettingsActionTypes.ADD_FAVOURITE_AIRCRAFT_ID: {
      return {
        ...state,
        details: {
          ...state.details,
          dash_preferences: {
            ...state.details.dash_preferences,
            favourite_aircraft_ids: [...state.details.dash_preferences.favourite_aircraft_ids, action.aircraftId],
          },
        },
      };
    }
    case UserSettingsActionTypes.REMOVE_FAVOURITE_AIRCRAFT_ID: {
      return {
        ...state,
        details: {
          ...state.details,
          dash_preferences: {
            ...state.details.dash_preferences,
            favourite_aircraft_ids: state.details.dash_preferences.favourite_aircraft_ids.filter(
              (id) => id !== action.aircraftId,
            ),
          },
        },
      };
    }
    case UserSettingsActionTypes.UPDATE_DETAILS:
      return {
        ...state,
        details: action.details,
        dateFormat: action.details.date_format,
        logbook_columns: action.details?.logbook_columns,
      };
    case UserSettingsActionTypes.UPDATE_OPERATOR_SETTINGS: {
      const newDetails = state.details;
      const opIndex = newDetails.operators.findIndex((op) => op.id === action.payload.id);
      const foundOperator = newDetails.operators[opIndex];
      const updatedOperator = {
        ...foundOperator,
        ...action.payload,
      };
      newDetails.operators[opIndex] = updatedOperator;
      return {
        ...state,
        details: newDetails,
      };
    }
    default:
      return state;
  }
}

export interface Operator {
  id: string;
  name: string;
  address: string;
  aoc_number: string;
  date_format: string;
  ddl_template: null;
  logo_url: null;
  dark_logo_url: null;
  aircraft_count: string;
  feature_flags: any;
  operator_setting: OperatorSetting;
  person_roles: any;
  phone_number?: string;
  journey_log_split_threshold?: number;
  journey_log_split_multiplier?: number;
  enabled_tech_log_modules?: EnabledModule[];
  billing_status?: string;
}

export interface EnabledModule {
  id: string;
  modulable_id: string;
  modulable_type: string;
  module_name: string;
  tech_log_module_id: string;
}

export interface OperatorSetting {
  advisory_on_ddl: boolean;
  defect_type_on_ddl: string[];
  intermittent_fault_name_plural: string;
  intermittent_fault_name_singular: string;
  only_deferred_on_ddl: boolean;
  requires_camp_mx_approval: boolean;
  crs_short_name: string;
  crs_long_name: string;
  cabin_issue_name_plural: string;
  cabin_issue_name_singular: string;
  cabin_issue_short_name: string;
  engineer: string;
  part_145_name: string;
  part_145_approval_number: string;
  engineer_licence_number: string;
  flight_time: string;
  journey_log: string;
  landing: string;
  srp: string;
  takeoff: string;
  trip_update: string;
  blocks_off: string;
  blocks_on: string;
  blocks_time: string;
  inspector_signoff: string;
  employee_number: string;
  deferral_employee_number: string;
  survey_complete_reshow_days: number;
  survey_dismissed_reshow_days: number;
  mx_checks_impact_serviceability: boolean;
  allow_missing_mx_check_when_only_requiring_erops: boolean;
  add_manual_trip_updates_on_ipad: boolean;
  po_suffix: string;
  po_short_name: string;
  po_long_name: string;
  dent_and_buckle: string;
  erops: string;
  engine_1: string;
  engine_2: string;
  engine_3: string;
  engine_4: string;
  defect_reported_by_type?: boolean;
  defect_short_name?: string;
  defect_name?: string;
  defect_name_plural?: string;
  mx_check_short_name?: string;
  mx_check_name?: string;
  mx_check_name_plural?: string;
  stage_of_flight_text?: string;
  stage_of_flight_enabled?: boolean;
  stage_of_flight_required?: boolean;
  stage_of_flight_options?: string[];
  inspector_licence_number_required?: boolean;
  show_person_employee_number?: boolean;
  show_person_amos_user_sign?: boolean;
  defer_using_default?: string;
  crs_signoff_crewdetails?: string;
  max_weight_for_flight_enabled?: boolean;
  max_weight_for_flight_name?: string;
  trip_default_sort_order?: string;
  ddl_numbering?: string;
  fuel_burn_required?: boolean;
  minimum_password_length?: number;
  notes_to_crew_name?: string;
  ddl_include_resolved_defects?: boolean;
  ddl_short_name?: string;
  aircraft_weight_name?: string;
  bowser_name?: string;
  pilot_performing_takeoff_on_postflight_enabled?: boolean;
  flight_crew_on_preflight_enabled?: boolean;
  fuel_burn_on_trips_enabled?: boolean;
  fuel_burn_on_mx_enabled?: boolean;
  hide_add_new_sector?: boolean;
  sector_name?: string;
  sector_name_plural?: string;
  hide_add_workpack?: boolean;
  hide_report_by_cabin_on_defects?: boolean;
  restrict_release_signee_type?: boolean;
  deferral_licence_number_required?: boolean;
  hide_part_145_email?: boolean;
  pin_user_must_have_employee_number?: boolean;
}

export enum UserRoles {
  ADMIN = 'admin',
  HELPDESK = 'helpdesk',
  PUBLIC = 'public',
}

export enum DashboardResource {
  AIRCRAFT = 'Aircraft',
  DASHBOARD = 'Dashboard',
  DEFECT = 'Defect',
  DOCUMENT = 'Document',
  INTEGRATION = 'Integration',
  MEL = 'Mel',
  PEOPLE = 'People',
  RECEIPT = 'Receipt',
  MX_ITEM = 'ScheduledMxItem',
  TRIP = 'Trip',
}

export enum UserPermission {
  CREATE = 'to_create',
  READ = 'to_read',
  UPDATE = 'to_update',
  DELETE = 'to_delete',
}

export enum TechLogModule {
  FRAT = 'frat',
}

export enum FeatureFlag {
  SRPEDIT = 'srp-pdf-api-generation',
  SRPVIEW = 'srp-pdf-api-editing',
  METRICS = 'metrics',
  INTERMITTENT = 'intermittent-faults',
  SAF = 'saf_percentage',
  FUELTYPE = 'fuel-type-selection',
  MAINTENANCE = 'maintenance-checks-with-release',
  STANDALONERELEASE = 'add-standalone-release',
  NOMXTASKSIGNOFF = 'no-mx-task-signoff',
  ACCESSSRP = 'access-srp-for-crs',
  DENTANDBUCKLE = 'damage-reports',
  CYCLESWIDGET = 'cycles-widget',
  DOCUMENTS = 'documents',
  NOTES = 'notes',
  MELINTEGRATION = 'mel-manager-integration-beta',
  CUSTOMFIELDS = 'custom-fields',
  FRAT = 'frat',
  FAVOURITEAIRCRAFT = 'favourite-aircraft',
}

export interface DashboardPreferences {
  fleet_style: string;
  favourite_aircraft_ids: string[];
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  date_format: string;
  theme: string;
  menu_position: string;
  taskcards_theme: string;
  taskcards_role: string;
  avatar_url: string;
  dash_preferences: DashboardPreferences;
  dashboard_permissions: UserRoles[];
  people: PeoplePermissions[];
  operators: Operator[];
  logbook_columns?: string[];
  survey_submitted_date: string;
}

export interface ResourcePermissions {
  id?: string;
  permission_group_id?: string;
  resource_type: string;
  to_create: boolean;
  to_read: boolean;
  to_update: boolean;
  to_delete: boolean;
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;
}
export interface AircraftPermission {
  id: string;
  permission_group_id: string;
  aircraft_id: string;
  aircraft_registration: string;
}

export interface PermissionGroup {
  id: string;
  global: boolean;
  permissions: ResourcePermissions[];
  aircraft: string[];
  aircraft_permission_groups: AircraftPermission[];
}
export interface PeoplePermissions extends Person {
  permission_groups: PermissionGroup[];
}

export interface UserSettingsState {
  details?: User;
  id: string;
  avatar: string;
  dateFormat: string;
  selectedView: string;
  tableAnimationClass: string;
  listAnimationClass: string;
  lastFetched: number;
  ui: AntUISettings;
  displayName: string;
  currentOrg?: User;
  logbook_columns: string[];
}

export interface UserSettings extends User {
  avatar: string;
  selectedView: string;
}

export interface AntUISettings {
  colorWeak: boolean;
  /**
   * Theme for the nav menu
   * @type {string}
   * @memberof IAntUI
   */
  navTheme: string;
  /**
   * Primary color of ant design
   * @type {string}
   * @memberof IAntUI
   */
  primaryColor: string;
  /**
   * Nav menu position: sidemenu or topmenu
   * @type {string}
   * @memberof IAntUI
   */
  layout: string;
  /**
   * Layout of content: Fluid or Fixed, only works when layout is topmenu
   * @type {('Fluid' | 'Fixed')}
   * @memberof IAntUI
   */
  contentWidth: 'Fluid' | 'Fixed';
  /**
   * Sticky header
   * @type {boolean}
   * @memberof IAntUI
   */
  fixedHeader: boolean;
  /**
   * Auto hide header
   * @type {boolean}
   * @memberof IAntUI
   */
  autoHideHeader: boolean;
  /**
   * Sticky siderbar
   * @type {boolean}
   * @memberof IAntUI
   */
  fixSiderbar: boolean;
}
