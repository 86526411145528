/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { IndividualFRAT } from '../../models/frats';
import documentIcon from '../../assets/icon-card-document-configuration.svg';
import TFSearchableSelect from '../TFSearchableSelect/TFSearchableSelect';
import ToolTip from '../NewTooltip/NewTooltip';
import { fetchSrpTemplates } from '../../services/apiNew';
import { usePrevious } from '../../utils/utils';
import TFNumberInput from '../TFNumberInput/TFNumberInput';
import useGetFRATData from '../../pages/OperatorFRAT/useGetFRATData';
import AircraftSettingsCard from './AircraftSettingsCard';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  Text,
} from './AircraftAdvancedSettings';
import ShowHideToggle from './ShowHideToggle';

interface AircraftDocumentConfigurationProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const AircraftDocumentConfiguration: React.FC<AircraftDocumentConfigurationProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [templates, setTemplates] = useState(null);

  const { formatMessage } = useIntl();

  const prevOpId = usePrevious(aircraft?.operator_id);

  const { FRATData } = useGetFRATData({
    selectedOperatorId: aircraft?.operator_id,
    searchValue: '',
  });

  const FRATOptions = FRATData?.map((item: IndividualFRAT) => ({
    title: item.name,
    value: item.id,
  }));

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleRequiredChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { required: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleInputChange = (value: any, key: string): void => {
    updateAircraftData([{ value, key }]);
  };

  const handleAppSettingInputChange = (value: any, key: string): void => {
    const newAppSettings = aircraft?.app_settings;
    const formattedValue = parseInt(value, 10);
    newAppSettings[`${key}`] = formattedValue;
    updateAircraftData([{ value: newAppSettings, key: 'app_settings' }]);
  };

  useEffect(() => {
    const getTemplates = async (): Promise<void> => {
      const res = await fetchSrpTemplates(aircraft?.operator_id);
      if (res.status === 200) {
        const formattedData = res?.data?.map((item) => ({
          ...item,
          title: item.name,
        }));
        setTemplates(formattedData);
      }
    };

    if (aircraft?.operator_id !== prevOpId) {
      getTemplates();
    }
  }, [aircraft, prevOpId]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={documentIcon}
            title="Document configuration"
            buttonText="Edit document configuration"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <StyledGrid>
          <ItemWrapper data-testid="GeneralSettingsGrid--SRPTemplate">
            <Label edit={editMode}>SRP template</Label>
            {!editMode ? (
              <Text>
                {templates?.find((temp) => temp?.id === aircraft?.srp_template_id)?.title || 'Operator default'}
              </Text>
            ) : (
              <TFSearchableSelect
                options={templates ? templates?.filter((temp) => temp.type === 'srp') : []}
                handleSelectChange={(option: any): void => handleInputChange(option?.id, 'srp_template_id')}
                initial={templates?.find((temp) => temp?.id === aircraft?.srp_template_id)}
                allowNull
              />
            )}
          </ItemWrapper>
          <ItemWrapper data-testid="GeneralSettingsGrid--JLTemplate">
            <Label edit={editMode}>Journey log template</Label>
            {!editMode ? (
              <Text>
                {templates?.find((temp) => temp?.id === aircraft?.jl_template_id)?.title || 'Operator default'}
              </Text>
            ) : (
              <TFSearchableSelect
                options={templates ? templates?.filter((temp) => temp.type === 'jl') : []}
                handleSelectChange={(option: any): void => handleInputChange(option?.id, 'jl_template_id')}
                initial={templates?.find((temp) => temp?.id === aircraft?.jl_template_id)}
                allowNull
              />
            )}
          </ItemWrapper>
          <ItemWrapper data-testid="GeneralSettingsGrid--FRAT">
            <Label edit={editMode}>Flight Risk Assessment</Label>
            {!editMode ? (
              <Text>
                {FRATData?.find((frat) => frat?.id === aircraft.flight_risk_assessment_id)?.name || 'Operator default'}
              </Text>
            ) : (
              <TFSearchableSelect
                options={FRATOptions}
                handleSelectChange={(option: any): void =>
                  handleInputChange(option?.value, 'flight_risk_assessment_id')
                }
                initial={FRATOptions?.find((frat) => frat?.value === aircraft?.flight_risk_assessment_id)}
                allowNull
              />
            )}
          </ItemWrapper>
          <ItemWrapper data-testid="GeneralSettingsGrid--DDLStartPage">
            <Label edit={editMode}>
              ADD start page
              <ToolTip text="When exporting the ADD/DDL, this will be the page number shown on the first page. This should be left at 1 for aircraft starting from new on Tech Log, but may be configured to line up with your existing paperwork for aircraft transitioning to paperless" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.start_ddl_page || '-'}</Text>
            ) : (
              <TFNumberInput
                value={aircraft?.app_settings?.start_ddl_page}
                onChange={(value): void => handleAppSettingInputChange(value, 'start_ddl_page')}
                min={0}
              />
            )}
          </ItemWrapper>
          <ItemWrapper data-testid="GeneralSettingsGrid--DDLStartNumber">
            <Label edit={editMode}>
              ADD start number
              <ToolTip text="When exporting the ADD/DDL, this will be the number assigned to the first defect, allowing you to line up previous paper-based systems with Tech Log." />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.app_settings?.start_ddl_number || '-'}</Text>
            ) : (
              <TFNumberInput
                value={aircraft?.app_settings?.start_ddl_number}
                onChange={(value): void => handleAppSettingInputChange(value, 'start_ddl_number')}
                min={0}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>
              Show PART 145 fields in CRS
              <ToolTip text="Show the PART 145 company and approval number fields on the CRS page" />
            </Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.show_part_145_fields?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.show_part_145_fields?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'show_part_145_fields')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper data-testid="AircraftDocumentConfiguration--PORequiredSection">
            <Label edit={editMode} data-testid="AircraftDocumentConfiguration--PORequiredLabel">
              {formatMessage({ id: 'text.showPOAndReference' })}
              <ToolTip text={formatMessage({ id: 'text.showPOAndReferenceTooltip' })} />
            </Label>
            {!editMode ? (
              <Text data-testid="AircraftDocumentConfiguration--PORequiredText">
                {aircraft?.standard_fields?.crs_reference_or_po_number?.required !== false ? 'Required' : 'Optional'}
              </Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.crs_reference_or_po_number?.required !== false}
                handleChange={(value: boolean): void => handleRequiredChange(value, 'crs_reference_or_po_number')}
                dataTestId="PORequiredToggle"
                showOverride="Required"
                hideOverride="Optional"
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper data-testid="AircraftDocumentConfiguration--SaveButton">
              <Button height="32px" onClick={handleLocalSaveClick}>
                {formatMessage({ id: 'text.save' })}
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftDocumentConfiguration;
