import moment from 'moment';
import { stringify } from 'qs';
import rp from 'request-promise';
import Cookies from 'universal-cookie';
import { intl } from '../providers/IntlProvider';
import config, { ConfigKey } from '../utils/config';
import error from '../utils/requestError';
import { core422ErrorMessageFormatter } from '../utils/coreErrorMessageFormatter';

const cookies = new Cookies();

export async function getDefectUrl(params) {
  return fetch(`${config.get(ConfigKey.API)}/defects/${params.id}/public_url`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    signal: params.signal,
  })
    .then(async (res) => {
      const data = await res.json();
      const { url } = data;
      const response = {
        url,
        res,
      };
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function updateMxEventsDefect(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/mx_events/${params.id}`,
    json: true,
    body: params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updateUserSettings(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: false,
    body: {
      params,
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function resetPassword(email) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/forgotten_password`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: email,
    json: true,
  }).catch((err) => err);
}

export async function requestResetPin(email) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/reset_pin`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: email,
    json: true,
  }).catch((err) => err);
}

export async function getUser(userId, params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/people/${userId}`,
    resolveWithFullResponse: true,
    qs: params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getFlight(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/${params}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTrip(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/trips/${params}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export const getACDefects = async ({ aircraftId }) => {
  return fetch(`${config.get(ConfigKey.API)}/aircraft/${aircraftId}/defects/all`, {
    method: `GET`,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then(async (res) => {
      const data = await res.json();
      return {
        list: data.map((defect, i) => ({
          ...defect,
          key: i,
          aircraft_ids: defect.aircraft.registration,
        })),
        pagination: {
          total: parseInt(res.headers['total-records'], 10),
        },
        res,
      };
    })
    .catch((err) => console.error(err));
};

export async function getDefect(params) {
  return fetch(`${config.get(ConfigKey.API)}/defects/${params.id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
    signal: params.signal,
  })
    .then(async (res) => {
      const resData = await res.json();
      const data = [resData];
      const response = {
        data,
        res,
      };
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function updateDefect(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/defects/${params.id}`,
    resolveWithFullResponse: true,
    body: params.body,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
    json: true,
  });
}

export async function removeDefect(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/defects/${params}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function getRoutes(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return body.map(({ arrival, departure }) => ({
        departure,
        arrival,
        startLat: Number(departure.latitude),
        startLng: Number(departure.longitude),
        endLat: Number(arrival.latitude),
        endLng: Number(arrival.longitude),
      }));
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/routes?${params.from ? `from_date=${params.from}&` : ''}to_date=${
      params.to
    }`,
    qs: {
      aircraft_ids: params.aircraft,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftLogs(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const data = [];

      const { totals, entries } = body;
      const firstObj = totals?.brought_forward || {};
      const lastObj = totals?.carried_forward || {};

      if (body.columns.includes('apu_hours')) {
        firstObj.apu_hours = totals.brought_forward.apu_hours;
        firstObj.apu_cycles = totals.brought_forward.apu_cycles;
        lastObj.apu_hours = totals.carried_forward.apu_hours;
        lastObj.apu_cycles = totals.carried_forward.apu_cycles;
      }

      let engine1OilTotal = 0;
      let engine2OilTotal = 0;
      let engine3OilTotal = 0;
      let engine4OilTotal = 0;

      if (!params.noTotals) data.push(firstObj);

      const { columns } = body;

      for (let i = 0; i < entries.length; i += 1) {
        const entry = entries[i];
        if (columns.includes('engine_1_hours')) {
          engine1OilTotal += entry.engine_1_oil_uplift;
        }

        if (columns.includes('engine_2_hours')) {
          engine2OilTotal += entry.engine_2_oil_uplift;
        }

        if (columns.includes('engine_3_hours')) {
          engine3OilTotal += entry.engine_3_oil_uplift;
        }

        if (columns.includes('engine_4_hours')) {
          engine4OilTotal += entry.engine_4_oil_uplift;
        }

        entry.date = entries[i].trip_date ? moment(entries[i].trip_date) : null;
        entry.from = entries[i].departure_airport;
        entry.to = entries[i].arrival_airport;
        entry.flight_time = moment.duration(entries[i].flight_time).as('seconds');
        entry.airframe_hours_original = entries[i].airframe_hours;

        entry.srp = entries[i].srp_number;
        entry.key = i + 1;
        data[i + 1] = entry;
      }

      if (body.columns.includes('engine_1_hours')) {
        lastObj.engine_1_oil = engine1OilTotal;
      }
      if (body.columns.includes('engine_2_hours')) {
        lastObj.engine_2_oil = engine2OilTotal;
      }
      if (body.columns.includes('engine_3_hours')) {
        lastObj.engine_3_oil = engine3OilTotal;
      }
      if (body.columns.includes('engine_4_hours')) {
        lastObj.engine_4_oil = engine4OilTotal;
      }

      lastObj.key = data.length;
      if (!params.noTotals) data.push(lastObj);
      return { data, selectableColumns: body.selectable_columns, chosenColumns: body.columns };
    }
    return null;
  }

  let uri = `${config.get(ConfigKey.API)}/fleet_logbook`;

  if (params?.aircraft) {
    uri = `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/flights?from_date=${params.from}&to_date=${
      params.to
    }&columns=${params.columns}`;
  } else if (params?.aircraftIds) {
    let idParams = '';
    params.aircraftIds.forEach((id, index) => {
      if (index === params?.aircraftIds?.length - 1) {
        idParams += `aircraft_ids[]=${id}`;
      } else {
        idParams += `aircraft_ids[]=${id}&`;
      }
    });
    uri = `${config.get(ConfigKey.API)}/fleet_logbook?${idParams}&from_date=${params.from}&to_date=${
      params.to
    }&columns=${params.columns}`;
  }

  return rp({
    method: 'GET',
    // eslint-disable-next-line max-len
    uri,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMonthlySummary(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const monthly = [];

      for (let i = 0; i < body.length; i += 1) {
        monthly[i] = {};
        monthly[i].period = moment(`${body[i].year}-${body[i].month}`, 'YYYY-MM');
        monthly[i].periodFormatted = moment(monthly[i].period).format('MMM YY');
        if (body[i].total_flights === null) {
          monthly[i].totalFlights = 0;
        } else {
          monthly[i].totalFlights = body[i].total_flights;
        }
        if (body[i].total_fuel_used_kg === null) {
          monthly[i].totalFuel = 0;
        } else {
          monthly[i].totalFuel = Number(Number(body[i].total_fuel_used_kg).toFixed(0));
        }
        if (body[i].total_fuel_uplifted_kg === null) {
          monthly[i].totalFuelUplifted = 0;
        } else {
          monthly[i].totalFuelUplifted = Number(Number(body[i].total_fuel_uplifted_kg).toFixed(0));
        }
        if (body[i].average_fuel_per_hour_kg === null) {
          monthly[i].fuelHour = 0;
        } else {
          monthly[i].fuelHour = Number(Number(body[i].average_fuel_per_hour_kg).toFixed(1));
        }
        if (body[i].total_duration === null) {
          monthly[i].totalHours = 0;
        } else {
          monthly[i].totalHours = Number((body[i].total_duration / 3600).toFixed(0));
        }
        if (body[i].average_hours_per_landing === null) {
          monthly[i].cycleHour = 0;
        } else {
          monthly[i].cycleHour = Number(body[i].average_hours_per_landing.toFixed(2));
        }
        if (i === 0) {
          monthly[i].fuelDeficit = monthly[i].totalFuel - monthly[i].totalFuelUplifted;
        } else {
          monthly[i].fuelDeficit = monthly[i].totalFuel - monthly[i].totalFuelUplifted + monthly[i - 1].fuelDeficit;
        }
      }

      monthly.sort((a, b) => {
        return a.period - b.period;
      });

      return monthly;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/monthly_summary`,
    resolveWithFullResponse: false,
    qs: {
      aircraft_ids: params.aircraft,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTopAirports(params) {
  function autoParse(body, res) {
    if (res.statusCode === 200) {
      const response = [];
      for (let i = 0; i < body.length; i += 1) {
        response[i] = {};
        response[i].x = body[i].ident;
        response[i].y = body[i].count;
      }
      return response.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/airports?from_date=${params.from}&to_date=${params.to}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getFigures(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/summary?from_date=${params.from}&to_date=${params.to}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTopAircraft(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      let hours = [];
      let flights = [];
      for (let i = 0; i < body.length; i += 1) {
        hours[i] = {};
        flights[i] = {};
        hours[i].x = body[i].registration;
        hours[i].y = body[i].total_duration / 3600;
        flights[i].x = body[i].registration;
        flights[i].y = body[i].flights_count;
      }
      hours = hours.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
      flights = flights.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
      return [flights, hours];
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/aircraft?from_date=${params.from}&to_date=${params.to}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftType(id) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft_types/${id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftTypes(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft_types/search?${stringify({ search: params })}&limit=20`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAirports(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/airports/search?${stringify({ search: params })}&limit=20`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
    return 'fail';
  });
}

export async function addTrip(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.id}/trips`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      trip: params.trip,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTrip(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/trips/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      trip: params.trip,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addFlight(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/trips/${params.id}/flights`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.flight,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addDefect(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.id}/defects`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
    body: params.body,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryTrips(params) {
  function autoParse(body) {
    const list = body.data;
    for (let i = 0; i < list.length; i += 1) {
      list[i].key = i + 1;
      if (list[i].cycles === null) {
        list[i].cycles = 0;
      }
      if (list[i].number === null) {
        list[i].number = '';
      }
      if (list[i].srp_number === null) {
        list[i].srp_number = '';
      }
      if (list[i].date === null) {
        list[i].date = '';
      }
      if (list[i].fuel_delta === null) {
        list[i].fuel_delta = 0;
      }
      if (list[i].flight_seconds === null) {
        list[i].flight_seconds = 0;
      }
      list[i].tripData = {
        flight_seconds: list[i].flight_seconds,
        landings: list[i].landings,
        fuel_delta: list[i].fuel_delta,
      };
      list[i].srpData = {
        srp_number: list[i].srp_number,
        srp_pages: list[i].srp_pages,
        srp_reference: list[i].srp_reference,
      };
      if (list[i].start_airport && list[i].end_airport) {
        list[i].route = `${list[i].start_airport} - ${list[i].end_airport}`;
      } else {
        list[i].route = '';
      }
    }
    return { data: list, total: body.count };
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params?.id}/trip_entries`,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryTripsOnly(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params}/trips?sort=desc`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTripUpdate(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 4,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTripUpdate(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params.payload.tripId}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.payload.body.newTripData,
    json: true,
  });
}

export async function queryFlightsOnly(params) {
  let url = `${config.get(ConfigKey.API)}/flights?sort_order=desc&limit=100000&sort_column=trip_number`;
  if (params.id) url += `&aircraft_id[]=${params.id}`;
  return fetch(url, {
    method: 'GET',
    signal: params.signal,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then(async (res) => {
      const resData = await res.json();
      const response = {
        data: resData,
        res,
      };
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function addSubscription(params) {
  // function autoParse(body, response) {
  //   const data = {};
  //   data.list = [body];
  //   if (response.statusCode === 201) {
  //     data.list.statusCode = 201;
  //     return data;
  //   } else if (response.statusCode === 422) {
  //     data.list.statusCode = 422;
  //     return data;
  //   }
  // }

  return rp({
    simple: false,
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      model: params.model,
      event: params.event,
      enabled: params.enabled,
      filter_params: {
        aircraft_id: params.filters,
      },
      operator_id: params.operator,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateSubscription(params) {
  function autoParse(body, response) {
    return response;
  }

  return rp({
    simple: false,
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      model: params.model,
      event: params.event,
      enabled: params.enabled,
      filter_params: {
        aircraft_id: params.filters || params.filter_params.aircraft_id,
      },
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function removeSubscription(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscription(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscriptionEvents() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const bodyParsed = JSON.parse(body);
      const data = {};
      data.list = bodyParsed;
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/events`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscriptionEventModel() {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return {};
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/events`,
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscriptions() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions`,
    resolveWithFullResponse: true,
    json: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryAircraft(params) {
  function autoParse(body, response) {
    if (response.statusCode >= 200 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params}`,
    transform: autoParse,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 3,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftList() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft`,
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryAircrafts() {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const aircraft = JSON.parse(body);
      for (let i = 0; i < aircraft.length; i += 1) {
        aircraft[i].key = i;
        aircraft[i].type = aircraft[i].aircraft_type.designator;
        // eslint-disable-next-line no-self-assign
        aircraft[i].year = aircraft[i].year;
        aircraft[i].serial = aircraft[i].serial_number;
      }
      const data = { list: aircraft };
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updateAircraft(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 3,
    },
    body: params.aircraft,
    json: true,
  });
}

export async function updateTripEntry(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteTripUpdate(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteTripEntry(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/trip_entries/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteFlight(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/flights/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTripDetails(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_entries/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTripNumber(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_entries/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateAircraftMaintenance(params) {
  return rp({
    method: 'PUT',
    // End point from aircraft obj for mx id
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_item/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { aircraft: params.aircraft },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraftTotals(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}/trip_updates`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraftMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}/scheduled_mx_items`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.item,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraft(body) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body,
    json: true,
  }).catch((err) => {
    return err;
  });
}

export async function addAircraftTrip(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/trip_update`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      trip_update: {
        number: params.srp || '',
        srp_number: params.srp_number || '',
        flight_seconds: params.acStartHours * 3600 + params.acStartMins * 60,
        apu_seconds: params.apuStartHours * 3600 + params.apuStartMins * 60,
        cycles: params.acStartCycles,
        landings: params.acStartLandings,
        aircraft_id: params.id,
      },
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updatePeople(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    qs: { with_aircraft: true },
    body: {
      first_name: params.first_name,
      last_name: params.last_name,
      email: params.email,
      type: params.type, // pilot, ops, camo, admin [tbd]
      permission_groups_attributes: params.permission_groups,
      login_attributes: params.login_attributes,
      employee_number: params.employee_number,
      amos_user_sign: params.amos_user_sign,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateFuel(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/fuel_uplifts/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      paid: params.paid,
      price_value: params.price_value,
      price_currency: params.price_currency,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function removePeople(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: true,
    qs: params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addPeople(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/organisations/${params.organisation_id}/people`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params,
    json: true,
  }).catch((err) => {
    return err;
  });
}

export async function download(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/${params}`,
    resolveWithFullResponse: true,
    encoding: null,
    headers: {
      'Content-type': 'application/pdf',
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function downloadCsv(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/${params}`,
    resolveWithFullResponse: true,
    encoding: null,
    headers: {
      'Content-type': 'application/json',
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryFuel(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      if (body.data.length > 0) {
        const fuel = body.data.map((d, i) => {
          const data = {};
          if (d.truck_actual_uplift && d.truck_unit) {
            data.truck_actual_uplift = d.truck_actual_uplift;
            data.truck_unit = d.truck_unit;
            data.formattedValue = `${parseInt(d.truck_actual_uplift, 10)} ${d.truck_unit.toUpperCase()}`;
          } else {
            data.formattedValue = '';
          }
          data.key = i;
          data.id = d.id;
          data.aircraft = d.flight.aircraft.registration;
          if (d.flight.trip.number) {
            data.tripSector = `${d.flight.trip.number}-${d.flight.sector_number}`;
          } else {
            data.tripSector = `${intl.formatMessage({ id: 'text.newCaps' })}-${d.flight.sector_number}`;
          }
          data.date = d.flight.trip.date;
          data.location = d.flight.departure_airport;
          if (d.attachments.length > 0) {
            data.attachment = d.attachments[0].pdf_url;
          } else {
            data.attachment = null;
          }
          data.aircraft_actual_uplift = d.aircraft_actual_uplift;
          data.aircraft_planned_departure = d.aircraft_planned_departure;
          data.aircraft_unit = d.aircraft_unit ? d.aircraft_unit : null;
          data.density = d.density ? d.density : null;
          data.flight = d.flight;
          data.paid = d.paid;
          data.price_currency = d.price_currency ? d.price_currency : null;
          data.price_value = d.price_value ? d.price_value : null;
          data.price_quote = d.price_quote ? d.price_quote : null;
          return data;
        });
        const total = body?.count || 0;
        return { fuel, total };
      }
      return [];
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/fuel_uplifts`,
    resolveWithFullResponse: true,
    transform: autoParse,
    qs: {
      page: params?.page || '1',
      limit: params?.limit || '10',
      paid: params?.paid,
      aircraft_id: params?.aircraft,
      price_currency: params?.currency,
      sort_column: params?.sort_column,
      sort_order: params?.sort_order,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function getFuelUpliftsTotals() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/fuel_uplifts/totals`,
    resolveWithFullResponse: true,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryPeople(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return response.body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/people`,
    resolveWithFullResponse: true,
    qs: params,
    json: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryMaintenanceForWorkpacks(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/scheduled_mx_for_workpack_drawer`,
    qs: {
      aircraft_id: params.aircraft_id,
      from_date: params.from_date,
      to_date: params.to_date,
      mx_type: params.mx_type,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: true,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 4,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryMaintenanceV4(params) {
  function autoParse(maintenance, response) {
    const data = maintenance;
    if (response.statusCode > 199 && response.statusCode < 400) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].key = i;
          for (let j = 0; j < data[i].mx_tasks.length; j += 1) {
            data[i].mx_tasks[j].key = j;
          }
        }
      }
      return maintenance;
    }
    return [];
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items`,
    qs: {
      aircraft_id: params.aircraft_id,
      from_date: params.from_date,
      to_date: params.to_date,
      mx_type: params.mx_type,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 4,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryMaintenance(params) {
  function autoParse(maintenance, response) {
    const data = maintenance;
    if (response.statusCode > 199 && response.statusCode < 400) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].key = i;
          for (let j = 0; j < data[i].mx_tasks.length; j += 1) {
            data[i].mx_tasks[j].key = j;
          }
        }
      }
      return maintenance;
    }
    return [];
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items`,
    qs: {
      aircraft_id: params.aircraft_id,
      from_date: params.from_date,
      to_date: params.to_date,
      item_type: params.item_type,
      page: params.page,
      per: params.per,
      search_term: params.search_term,
      date: params.date,
      area: params.area,
      status: params.status,
      source: params.source,
      limits: params.limits,
      planning: params.planning,
      tolerance: params.tolerance,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 5,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryDraftMaintenance(params) {
  const res = await rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/drafts`,
    qs: {
      aircraft_id: params.aircraft_id,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: true,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 5,
    },
  }).catch((err) => {
    error(err);
  });
  return res.body;
}

export async function updateMaintenance(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function approveMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/approve`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      ids: params.ids,
    },
    json: true,
  });
}

export async function rejectMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/reject`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      ids: params.ids,
    },
    json: true,
  });
}

// this might not be necessary but left it in just in case
export async function commitMaintenanceDiffs(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}/commit_diffs`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      commits: params.commits,
    },
    json: true,
  });
}

export async function removeMaintenance(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/scheduled_mx_items`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addDamageMap(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/damage_maps`;
  const formData = params.form;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  }).catch((err) => {
    error(err);
  });
}

export async function fetchIntegrations(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function addRocketRoute(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations/rocketroute`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function addTraxxall(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations/traxxall`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function removeRocketRoute(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations/rocketroute`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function queryAllFlights(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const total = parseInt(response.headers['total-records'], 10);
      const bodyParsed = JSON.parse(body);
      const flights = bodyParsed;
      for (let i = 0; i < flights.length; i += 1) {
        flights[i].key = i;
        if (flights[i].trip.number === null || flights[i].trip.number === '') {
          flights[i].trip.number = 'NEW';
        }
        flights[i].tripNo = `${flights[i].trip.number}-${flights[i].sector_number}`;
        flights[i].aircraft_ids = flights[i].aircraft_registration;
        flights[i].date = flights[i].trip.date;
        if (flights[i].time_landing != null) {
          flights[i].arrTime = moment(flights[i].time_landing).utc().format('HH:mm');
        } else {
          flights[i].arrTime = '';
        }
        if (flights[i].time_takeoff != null) {
          flights[i].depTime = moment(flights[i].time_takeoff).utc().format('HH:mm');
        } else {
          flights[i].depTime = '';
        }
      }
      const data = {};
      data.list = flights;
      data.pagination = { total };
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/`,
    resolveWithFullResponse: true,
    transform: autoParse,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryV3Defects(params) {
  const url = `${config.get(ConfigKey.API)}/defects/all`;
  return fetch(url, {
    method: 'GET',
    signal: params.signal,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then(async (res) => {
      const data = await res.json();
      return {
        list: data.defects.map((defect, i) => ({
          ...defect,
          key: i,
          aircraft_ids: defect.aircraft.registration,
        })),
        total: data.count,
        res,
      };
    })
    .catch((err) => {
      error(err);
    });
}

export async function queryCabinIssues(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/cabin_issues`;
  return rp(url, {
    method: 'GET',
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryCabinIssuesTotals(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params}/cabin_issues/totals`;
  return rp(url, {
    method: 'GET',
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function querySingleCabinIssue(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/cabin_issues/${params.id}`;
  return rp(url, {
    method: 'GET',
    // qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function createCabinIssue(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/cabin_issues`;
  return rp(url, {
    method: 'POST',
    // qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateCabinIssue(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/cabin_issues/${params.id}`;
  return rp(url, {
    method: 'PUT',
    // qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteCabinIssue(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/cabin_issues/${params.id}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function getCabinIssueSVG(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/aircraft_drawings/${params?.id}`;
  return rp(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addToFavourites(aircraftId) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${aircraftId}/add_to_favourites`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function removeFromFavourites(aircraftId) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${aircraftId}/remove_from_favourites`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryDefects(params) {
  const url = `${config.get(ConfigKey.API)}/defects`;
  return fetch(url, {
    method: 'GET',
    signal: params.signal,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
  })
    .then(async (res) => {
      const data = await res.json();
      return {
        list: data.map((defect, i) => ({
          ...defect,
          key: i,
          aircraft_ids: defect.aircraft.registration,
        })),
        pagination: {
          total: parseInt(res.headers.get('total-records'), 10),
        },
        res,
      };
    })
    .catch((err) => {
      error(err);
    });
}

export async function queryDefectsForWorkpacks(params) {
  let url = `${config.get(ConfigKey.API)}/defects/defects_for_workpack_drawer?limit=1000`;
  if (params.aircraft_id) url += `&aircraft_id=${params.aircraft_id}`;
  return fetch(url, {
    method: 'GET',
    signal: params.signal,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
  })
    .then(async (res) => {
      const data = await res.json();
      return {
        list: data.map((defect, i) => ({
          ...defect,
          key: i,
          aircraft_ids: defect.aircraft.registration,
        })),
        pagination: {
          total: parseInt(res.headers.get('total-records'), 10),
        },
        res,
      };
    })
    .catch((err) => {
      error(err);
    });
}

export async function setUserDetails(params) {
  const url = `${config.get(ConfigKey.API)}/me`;
  const formData = params.form;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    body: formData,
  })
    .then((r) => {
      const response = r;
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function updateFleetStyle(params) {
  const url = `${config.get(ConfigKey.API)}/me`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  const jsonRes = await response.json();
  return jsonRes;
}

export async function updateOperator(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/operators/${params.operator.id}`,
    resolveWithFullResponse: true,
    json: true,
    body: params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      if (response.statusCode !== 200) {
        return null;
      }
      return response.body;
    })
    .catch((err) => {
      error(err);
    });
}

export async function getUserAvatar() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/logins/${cookies.get('userId')}/avatar`,
    resolveWithFullResponse: true,
    simple: false,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      if (response.statusCode !== 200) {
        return null;
      }
      return response.body;
    })
    .catch((err) => {
      error(err);
    });
}

export async function getUserDetails() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/me`,
    resolveWithFullResponse: true,
    simple: false,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      if (response.statusCode === 200) {
        return response.body;
      }
      if (response.statusCode === 401) {
        return window.location.href === '/login';
      }
      if (response.statusCode === 500) {
        return window.location.href === '/exception/500';
      }
      return null;
    })
    .catch((err) => {
      error(err);
    });
}

export async function queryAircraftDocuments(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/documents`;
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const bodyData = body;
      for (let i = 0; i < bodyData.length; i += 1) {
        bodyData[i].key = i;
      }
      return bodyData;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryDocuments() {
  const url = `${config.get(ConfigKey.API)}/documents`;
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const bodyData = body;
      for (let i = 0; i < bodyData.length; i += 1) {
        bodyData[i].key = i;
      }
      return bodyData;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addDocument(params) {
  const url = `${config.get(ConfigKey.API)}/documents`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.form,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function updateDocument(params) {
  const url = `${config.get(ConfigKey.API)}/documents/${params.id}`;
  const formData = params.form;

  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function removeDocument(params) {
  const url = `${config.get(ConfigKey.API)}/documents/${params.id}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function getDocumentTypes() {
  const url = `${config.get(ConfigKey.API)}/documents/types`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function queryTickets(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets?limit=${params.limit}&page=${params.page}&group=${params.group}&state=${
      params.state
    }&aircraft=${params.aircraft}&search=${params.searchTerm}&sort_order=${params.sort_order}&sort_column=${
      params.sort_column
    }&my_tickets=${params.myTickets}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryTicketGroups() {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/groups`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryAttachment(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/attachments/${params.ticket_id}/${params.article_id}/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryTicketArticles(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/articles/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryTicket(params) {
  function autoParse(body, response) {
    if (response.statusCode >= 200 && response.statusCode < 400) {
      return body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function searchTicket(params) {
  function autoParse(body, response) {
    if (response.statusCode >= 200 && response.statusCode < 400) {
      return body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/search/${params.globalFilter}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addTicket(params) {
  const attachments = [];
  params.attachments.forEach((a, index) => {
    const attachment = {
      filename: a.name,
      data: params.base64s[index],
      'mime-type': a.type,
    };
    attachments.push(attachment);
  });
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/tickets`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      title: params.title,
      group: params.group,
      aircraft: params.aircraft,
      article: {
        subject: 'some subject',
        body: params.note,
        type: 'note',
        internal: false,
        attachments,
      },
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addArticle(params) {
  const attachments = [];
  params.attachments.forEach((a, index) => {
    const attachment = {
      filename: a.name,
      data: params.base64s[index],
      'mime-type': a.type,
    };
    attachments.push(attachment);
  });
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/tickets/article`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      ticket_id: params.ticket_id,
      to: params.to,
      cc: params.cc,
      subject: params.subject,
      body: params.body,
      content_type: params.content_type,
      type: params.type,
      internal: params.internal,
      time_unit: params.time_unit,
      attachments,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTicket(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/tickets/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      state: params.state,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function removeTicket(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/tickets/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraft(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function addDefectAttachment(params) {
  const url = `${config.get(ConfigKey.API)}/defects/${params.id}/upload`;
  const formData = new FormData();
  formData.append('attachment', params.attachment);
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getAirport(params) {
  const url = `${config.get(ConfigKey.API)}/airports/${params}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getAttachmentFile(url) {
  return fetch(url.startsWith('http') ? url : `${config.get(ConfigKey.API)}/${url}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.blob())
    .catch((err) => {
      error(err);
    });
}

export async function addFlightAttachment(params) {
  const url = `${config.get(ConfigKey.API)}/flights/${params.id}/upload `;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.formData,
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function addTripAttachment(params) {
  const url = `${config.get(ConfigKey.API)}/trip_entries/${params.id}/upload`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.formData,
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function deleteAttachment(id) {
  const url = `${config.get(ConfigKey.API)}/attachments/${id}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updateFlight(params) {
  const url = `${config.get(ConfigKey.API)}/flights/${params.id}`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params.flight),
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getWorkpacks(params) {
  const url = `${config.get(ConfigKey.API)}/workpacks`;
  return rp(url, {
    method: 'GET',
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getIntermittentFaults(params) {
  const url = `${config.get(ConfigKey.API)}/intermittent_faults`;
  return rp(url, {
    method: 'GET',
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addIntermittentFault(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/intermittent_faults?aircraft_id=${params.aircraft_id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { ...params },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateIntermittentFault(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/intermittent_faults/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { ...params },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getIntermittentFaultOccurrences(id) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/intermittent_fault_occurrences?intermittent_fault_id=${id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getNotes(params) {
  const url = `${config.get(ConfigKey.API)}/notes`;
  return rp(url, {
    method: 'GET',
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets',
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addNote(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/notes?aircraft_id=${params.aircraft_id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { ...params },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addIntermittentFaultOccurrence(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/intermittent_fault_occurrences`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { ...params },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function editNote(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/notes/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { ...params },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteNote(id) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/notes/${id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getWorkpack(payload) {
  const url = `${config.get(ConfigKey.API)}/workpacks/${payload}`;
  return rp(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function postWorkpack(params) {
  const url = `${config.get(ConfigKey.API)}/workpacks`;
  return rp(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: params,
    json: true,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function putWorkpack(params) {
  const url = `${config.get(ConfigKey.API)}/workpacks/${params.id}`;
  return rp(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: params.body,
    json: true,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function addAttachmentWorkpack(params) {
  const url = `${config.get(ConfigKey.API)}/workpacks/${params.id}/upload`;
  const formData = new FormData();

  params.attachments.forEach((attachment) => {
    formData.append('attachments[]', attachment);
  });

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: formData,
    json: true,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.response;
    });
}

export async function deleteWorkpack(id) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/workpacks/${id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteIntermittentFault(id) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/intermittent_faults/${id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function completeWorkpack(params) {
  const url = `${config.get(ConfigKey.API)}/releases/${params.id}`;
  return rp(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: params.body,
    json: true,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function updateRelease(params) {
  const url = `${config.get(ConfigKey.API)}/releases/${params.id}`;
  return rp(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: params.body,
    json: true,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function getPublicWorkpackUrl(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/workpacks/${params}/public_url`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getDamageMaps(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/damage_maps`;
  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getDamageReports(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/damage_reports`;
  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMELs() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/mels`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMEL(id, chapterNumber) {
  let qs = {};
  if (chapterNumber) {
    qs = {
      chapter_number: chapterNumber,
    };
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/mels/${id}`,
    qs,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'API-Version': '2',
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMELItems(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/mel_items`;
  return fetch(url, {
    method: 'GET',
    signal: params.signal,
    qs: {
      chapter_number: params.chapterNumber,
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'API-Version': '2',
    },
  })
    .then(async (r) => {
      const data = await r.json();
      const response = {
        data,
        r,
      };
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function getMELChapters(id) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/mels/${id}/chapters`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function postMEL(body) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/mels`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
    body,
  }).catch((err) => {
    error(err);
  });
}

export async function putMEL(payload) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/mels/${payload.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': '2',
    },
    json: true,
    body: payload,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteMEL(id) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/mels/${id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getWorkpackUrl(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/workpacks/${params}/public_url`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export const expiringUrl = async (attachmentId) => {
  return fetch(`${config.get(ConfigKey.API)}/attachments/original/${attachmentId}/expiring_url`, {
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).then((expiringUrlResponse) => {
    return expiringUrlResponse.json();
  });
};

// Fetch the file's expiring url from core and then fetch the file from the expiring url
// CORS is enabled on the buckets in each environment so we must include the Origin header
export const fetchImage = async (attachmentId) => {
  return expiringUrl(attachmentId)
    .then((expiringUrlJson) => {
      return fetch(expiringUrlJson.url, {
        headers: {
          Origin: ConfigKey.PUB,
        },
      }).then((presignedUrlResponse) => {
        return presignedUrlResponse.blob();
      });
    })
    .catch((err) => {
      error(err);
    });
};

export const getLastSRPUrl = async (id) => {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft/${id}/last_srp_url`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
};

export const fetchGeneratedPdfDocument = async (id, docType) => {
  const prefix = docType === 'srp' ? 'srp' : 'srp/journey_log';
  const res = await rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/${prefix}/${id}.pdf`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Origin: ConfigKey.PUB,
      'content-type': 'application/pdf',
    },
    encoding: 'base64',
  });
  return res;
};

export const sendCustomFieldMessageToChatbot = async (params) => {
  return fetch('https://p01--tl-custom-field-server--kswmhxpkztzl.code.run/handle_request', {
    method: 'POST',
    resolveWithFullResponse: true,
    body: JSON.stringify(params),
    json: true,
    headers: {
      'content-type': 'application/json',
    },
  }).then((res) => {
    return res.json();
  });
};

export const getAllFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/operators/${params.operator_id}/flight_risk_assessments`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((r) => {
      if (!r.ok) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
    });
};

export const getIndividualFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessments/${params.id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((r) => {
      if (!r.ok) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
    });
};

export const createNewFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/operators/${params.operator_id}/flight_risk_assessments`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      if (r.status === 422) {
        const res = await r.json();
        throw new Error(res?.errors?.name[0]);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const updateFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessments/${params.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      if (r.status === 422) {
        const res = await r.json();
        throw new Error(res?.errors?.name[0]);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const createFRATCategory = (params) => {
  return fetch(
    `${config.get(ConfigKey.API)}/flight_risk_assessments/${params.id}/flight_risk_assessment_question_categories`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  )
    .then(async (r) => {
      if (r.status === 422) {
        const res = await r.json();
        const formattedError = core422ErrorMessageFormatter(res?.errors);
        throw new Error(formattedError);
      }
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const createFRATQuestion = (params) => {
  return fetch(
    // eslint-disable-next-line max-len
    `${config.get(ConfigKey.API)}/flight_risk_assessment_question_categories/${
      params.flight_risk_assessment_question_category_id
    }/flight_risk_assessment_questions`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  )
    .then(async (r) => {
      if (r.status === 422) {
        const res = await r.json();
        throw new Error(res?.errors?.question[0]);
      }
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const updateFRATQuestion = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessment_questions/${params.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(async (r) => {
      if (r.status === 422) {
        const res = await r.json();
        throw new Error(res?.errors?.question[0]);
      }
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const setDefaultFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessments/${params.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ flight_risk_assessment: { default: true } }),
  })
    .then(async (r) => {
      if (r.status === 422) {
        const res = await r.json();
        throw new Error(res?.errors);
      }
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const deleteFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessments/${params.id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const deleteFRATQuestion = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessment_questions/${params.id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const deleteFRATCategory = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessment_question_categories/${params.id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const renameFRATCategory = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessment_question_categories/${params.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const reorderFRAT = (params) => {
  return fetch(`${config.get(ConfigKey.API)}/flight_risk_assessments/${params.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(async (r) => {
      if (r.status === 500) {
        throw new Error(r.statusText);
      }
      return r.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};

export const updateMultipleMxItemTolerance = (params) => {
  return fetch(
    `
    ${config.get(ConfigKey.API)}/aircraft/${params?.aircraft_id}/scheduled_mx_items/apply_multiple_tolerances`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
      json: true,
    },
  )
    .then(async (r) => {
      if (r?.status === 500) {
        throw new Error(r.statusText);
      }
      return r?.json();
    })
    .catch((err) => {
      error(err);
      throw err;
    });
};
