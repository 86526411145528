import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { FRATQuestion } from '../../models/frats';

interface FRATItemRowProps {
  item: FRATQuestion;
  category: string;
}

const ItemWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
`;

const ItemTitleSpan = styled.span`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSpan = styled.span`
  color: #646464;
`;

const FRATItemRow: React.FC<FRATItemRowProps> = ({ item, category }) => {
  const {
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);
  return (
    <ItemWrapper data-testid={`FRATItemRow--Wrapper-${item?.id}`}>
      <ItemTitleSpan data-testid={`FRATItemRow--ItemTitle-${item?.id}`}>{item?.question}</ItemTitleSpan>
      <StyledSpan data-testid={`FRATItemRow--Options-${item?.id}`}>
        {item?.flight_risk_assessment_options?.length}
      </StyledSpan>
      <StyledSpan data-testid={`FRATItemRow--Category-${item?.id}`}>{category}</StyledSpan>
      <StyledSpan data-testid={`FRATItemRow--DateAdded-${item.id}`}>
        {item?.created_at ? moment(item?.created_at).format(dateFormat) : '-'}
      </StyledSpan>
      <StyledSpan data-testid={`FRATItemRow--DateModified-${item.id}`}>
        {item?.updated_at ? moment(item?.updated_at).format(dateFormat) : '-'}
      </StyledSpan>
    </ItemWrapper>
  );
};

export default FRATItemRow;
