import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';
import EmptyState from '../EmptyState/EmptyState';
import EmptyStateAirports from '../../assets/emptyState/empty-state-airport.svg';
import styles from './MostVisitedAirports.module.less';

const MostVisitedAirports = ({ airports, formatMessage }) => {
  const [visible, setVisible] = useState(false);
  const [showButton, setShowButton] = useState(false);
  let largestNumVisits = 0;
  airports.forEach((airport) => {
    if (airport.y > largestNumVisits) {
      largestNumVisits = airport.y;
    }
  });
  useEffect(() => {
    if (airports.length < 6) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [airports]);
  let airportBars;
  if (!visible) {
    airportBars = airports
      .filter((airport, index) => index < 6)
      .map((airport, index) => {
        const percentage = (airport.y / largestNumVisits) * 100;
        const displayingAirports = (
          <div className={styles.progressWrapper} key={airport.x}>
            <span>{`${index + 1}. ${airport.x}`}</span>
            <Progress percent={percentage} showInfo={false} strokeColor="#35bb6e" />
          </div>
        );
        return displayingAirports;
      });
  } else {
    airportBars = airports.map((airport, index) => {
      const percentage = (airport.y / largestNumVisits) * 100;
      const displayingAirports = (
        <div className={styles.progressWrapper} key={airport.x}>
          <span>{`${index + 1}. ${airport.x}`}</span>
          <Progress percent={percentage} showInfo={false} strokeColor="#35bb6e" />
        </div>
      );
      return displayingAirports;
    });
  }

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      {airports.length > 0 ? (
        <div data-testid="MostVisitedAirports--ProgressWrapper">
          {airportBars}
          {showButton ? (
            <button
              className={styles.showMoreButton}
              data-testid="MostVisitedAirports--MoreButton"
              type="button"
              onClick={() => handleButtonClick()}
            >
              {visible ? formatMessage({ id: 'text.showLess' }) : 'Show more'}
            </button>
          ) : null}
        </div>
      ) : (
        <EmptyState
          image={EmptyStateAirports}
          text="No most visited airports data"
          subText="Most visited airports will be shown here when data has been added."
        />
      )}
    </>
  );
};

MostVisitedAirports.propTypes = {
  airports: PropTypes.array,
  formatMessage: PropTypes.func.isRequired,
};

MostVisitedAirports.defaultProps = {
  airports: [],
};

export default MostVisitedAirports;
