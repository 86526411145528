import { useSelector } from 'react-redux';
import { DashboardState } from '../models';

export default function (key: string, id: string) {
  const {
    userSettings: {
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const operator = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id);
  const foundEnabledModule = operator?.enabled_tech_log_modules?.some((mod) => mod.module_name === key);
  return foundEnabledModule;
}
