import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { FilterSideBar, Loading, Search } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource, CustomField } from '../../models/aircraft';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import isFeatureFlagActive from '../../utils/isFeatureFlagActive';
import { getSingleAircraft } from '../../models/aircraft/actions';
import TFDropdownMenu from '../../components/TFDropdownMenu/TFDropdownMenu';
import { DashboardState } from '../../models';
import { usePrevious } from '../../utils/utils';
import plusIcon from '../../assets/plus.svg';
import AIIcon from '../../assets/icon-ai.svg';
import customFieldFilterModel from './customFieldFilterModel';
import CustomFieldsTable from './CustomFieldTable';
import CustomFieldDrawer from './CustomFieldDrawer';

const Wrapper = styled.div`
  border-radius: 2px;
  box-shadow: ${({ theme }): string => `0 0 16px 0 ${theme.colours.whiteShadow}`};
  border: ${({ theme }): string => `solid 2px ${theme.colours.white}`};
  background-color: ${({ theme }): string => theme.colors.zircon};
  padding: 28px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 220px;
  grid-gap: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Count = styled.div`
  margin-left: 8px;
`;

const TableColumn = styled.div``;

const FilterWrapper = styled.div``;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ActionWrapper = styled.div`
  display: flex;
`;

const SearchWrapper = styled.div`
  margin-right: 12px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const AddButtonText = styled.div`
  color: #fff;
  font-weight: 500;
  align-items: center;
  display: flex;
  img {
    margin-right: 8px;
    margin-top: 2px;
  }
`;

const BlackText = styled.span`
  color: ${({ theme }): string => theme.colours.graphite};
`;

const UseAIWrapper = styled.span`
  color: ${({ theme }): string => theme.colours.purple};
  display: flex;
  img {
    width: 14px;
  }
`;

const CustomFields: React.FC = () => {
  const [customFieldsData, setCustomFieldsData] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const foundAircraftCustomFields = aircraftMap.get(id)?.custom_fields;
  const previousAircraftCustomFields = usePrevious(foundAircraftCustomFields);
  const chatBotFlagEnabled = isFeatureFlagActive('custom-field-chatbot', 'operator', aircraftMap.get(id)?.operator_id);

  const handleAddCustomField = (useAi = false): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({ payload: { content: <CustomFieldDrawer useAi={useAi} /> } }));
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  const addOptions = (): any[] => {
    const options = [
      {
        title: <BlackText>Default method</BlackText>,
        value: '',
        onClick: handleAddCustomField,
        id: 'defaultMethod',
        aiStyle: false,
      },
    ];
    if (chatBotFlagEnabled) {
      options.push({
        title: (
          <UseAIWrapper>
            Using AI chatbot
            <img src={AIIcon} alt="AI icon" />
          </UseAIWrapper>
        ),
        value: '',
        onClick: (): void => handleAddCustomField(true),
        id: 'aiMethod',
        aiStyle: true,
      });
    }
    return options;
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getSingleAircraft({ payload: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const checkIfFiltersActive = (): boolean => {
      let active = false;
      if (filters && Object.values(filters).some((item: string[]) => item?.length > 0)) {
        active = true;
      }
      return active;
    };
    let newData = [];
    const originalData: CustomField[] = aircraftMap.get(id)?.custom_fields
      ? Object.values(aircraftMap.get(id).custom_fields)
      : [];

    if (checkIfFiltersActive()) {
      // pull out the active filter types from the filter object
      const filterKeys = Object.keys(filters);

      // filter data so that it returns data only that matches all active filters
      // but contains at least oneactive filter values
      const filterData = originalData?.filter((customField) => {
        return filterKeys.every((key) => filters[key]?.includes(customField[key]?.toString()));
      });
      newData = filterData;
    } else {
      newData = originalData;
    }
    if (searchValue) {
      newData = newData.filter((object) => {
        return Object.values(object).some((value) =>
          value?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase()),
        );
      });
    }
    setCustomFieldsData([...newData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraftMap, filters, id, searchValue]);

  useEffect(() => {
    if (id) {
      const foundAircraft = aircraftMap.get(id);
      if (foundAircraft?.custom_fields) {
        const formattedData = Object.values(foundAircraft.custom_fields);
        setCustomFieldsData(formattedData);
        setLoading(false);
      } else {
        setCustomFieldsData(null);
      }
    }
  }, [aircraftMap, id]);

  useEffect(() => {
    if (!_.isEqual(foundAircraftCustomFields, previousAircraftCustomFields)) {
      setLoading(false);
    }
  }, [foundAircraftCustomFields, previousAircraftCustomFields]);

  return (
    <InnerMenuLayout>
      <Wrapper data-testid="CustomFields--Wrapper">
        <GridWrapper>
          <TableColumn>
            <TableHeader data-testid="CustomFields--TableHeader">
              <Header data-testid="CustomFields--Header">
                {formatMessage({ id: 'text.customFields' })}
                <Count>{`(${customFieldsData?.length || '0'})`}</Count>
              </Header>
              <ActionWrapper>
                <SearchWrapper data-testid="CustomFields--SearchWrapper">
                  <Search
                    onChange={(e): void => setSearchValue(e.target.value)}
                    onClear={(): void => setSearchValue('')}
                  />
                </SearchWrapper>
                <AircraftAuthenticationWrapper
                  aircraftId={id}
                  requiredResource={AircraftResource.AIRCRAFT}
                  requiredPermissionLevel={AircraftPermission.CREATE}
                >
                  <TFDropdownMenu
                    buttonText={
                      <AddButtonText data-testid="CustomFields--AddButtonText">
                        <img src={plusIcon} alt="plus icon" />
                        {window.innerWidth < 451 ? formatMessage({ id: 'text.add' }) : 'Add new field'}
                      </AddButtonText>
                    }
                    options={addOptions()}
                    buttonPrimary
                  />
                </AircraftAuthenticationWrapper>
              </ActionWrapper>
            </TableHeader>
            {loading ? (
              <Loading loading contain />
            ) : (
              <CustomFieldsTable
                data={customFieldsData || []}
                total={customFieldsData?.length}
                pageSize={pageSize}
                pageIndex={pageIndex - 1}
                setLoading={setLoading}
                onPaginationChange={(currentPage, numberOfItems): void => {
                  if (numberOfItems !== pageSize) {
                    setPageIndex(1);
                    setPageSize(numberOfItems);
                  } else if (currentPage !== pageIndex) {
                    setPageIndex(currentPage);
                  }
                }}
              />
            )}
          </TableColumn>
          <FilterWrapper>
            <FilterSideBar
              data={aircraftMap.get(id)?.custom_fields ? Object.values(aircraftMap.get(id).custom_fields) : []}
              filterGroups={customFieldFilterModel()}
              reset={reset}
              onChange={(e): void => {
                if (e.length === 0) {
                  setFilters({ required: [], type: [], view: [] });
                  setReset(false);
                } else {
                  const newFilters = e.reduce((newObject, object) => {
                    const workingObject = newObject;
                    if (!workingObject[object.key]) {
                      workingObject[object.key] = [object.value];
                    } else {
                      workingObject[object.key].push(object.value);
                    }
                    return workingObject;
                  }, {});
                  setFilters(newFilters);
                  setReset(false);
                }
              }}
            />
          </FilterWrapper>
        </GridWrapper>
      </Wrapper>
    </InnerMenuLayout>
  );
};

export default CustomFields;
