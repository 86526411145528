import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface AircraftLimitationProps {
  defect: Defect | null;
  editDefect: boolean;
  updateDefectData?: (changes: any[]) => void;
  ignoreEROPS?: boolean;
  limitations?: string;
  eropsTitle?: string;
  sectorsNameOverride?: string;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const WarningText = styled.p`
  color: #f79833;
`;

const AircraftLimitations: React.FC<AircraftLimitationProps> = ({
  defect,
  editDefect,
  updateDefectData,
  ignoreEROPS,
  limitations,
  eropsTitle,
  sectorsNameOverride,
}) => {
  const [isErops, setIsErops] = useState(false);
  const [limitation, setLimitation] = useState(null);

  const { formatMessage } = useIntl();

  const isMobile = window.innerWidth < 451;
  const noLimitations = limitations === undefined || defect.limitations === null;

  useEffect(() => {
    if (defect) {
      if (defect?.limitations) {
        setLimitation(defect.limitations);
      }
      if (defect?.erops_prevented) {
        setIsErops(true);
      } else {
        setIsErops(false);
      }
    }
  }, [defect]);

  return (
    <Wrapper>
      <FlexWrapper column={editDefect} marginTop={editDefect ? 0 : 30} marginBottom={20}>
        <Label marginBottom={editDefect ? 20 : 0} fontWeight={600} marginRight={5}>
          {formatMessage({ id: 'form.labels.aircraftLimitations' })}
        </Label>
        <Label marginBottom={10} fontWeight={500}>
          {formatMessage({ id: 'text.areThereAnyLimitations' })}
        </Label>
        <FlexWrapper>
          <RadioInputButton
            text="Yes"
            active={noLimitations === false}
            marginRight="8px"
            onClick={(): void => {
              updateDefectData([{ value: '', key: 'limitations' }]);
            }}
          />
          <RadioInputButton
            text="No"
            active={noLimitations === true}
            marginRight="8px"
            onClick={(): void => {
              setLimitation(null);
              updateDefectData([{ value: null, key: 'limitations' }]);
            }}
          />
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper column={editDefect}>
        {!noLimitations ? (
          <FlexWrapper column marginBottom={20} marginRight={!editDefect && 100}>
            <Label marginBottom={5} fontWeight={500}>
              {formatMessage({ id: 'title.limitations' })}
            </Label>
            <StyledInput
              id="limitations"
              type="text"
              width={isMobile ? 300 : 400}
              value={limitation}
              onChange={(e): void => {
                setLimitation(e.target.value);
              }}
              onBlur={(e): void => {
                updateDefectData([{ value: e.target.value, key: 'limitations' }]);
              }}
            />
          </FlexWrapper>
        ) : null}
      </FlexWrapper>
      {!noLimitations && !ignoreEROPS ? (
        <>
          <FlexWrapper column={editDefect} marginTop={editDefect ? 0 : 30} marginBottom={20}>
            <Label marginBottom={10} fontWeight={500}>
              {formatMessage({ id: 'text.exceptEROPS' }, { field: eropsTitle, sectors: sectorsNameOverride })}
            </Label>
            <FlexWrapper>
              <RadioInputButton
                text="Yes"
                active={isErops}
                marginRight="8px"
                onClick={(): void => {
                  setIsErops(true);
                  updateDefectData([{ value: true, key: 'erops_prevented' }]);
                }}
              />
              <RadioInputButton
                text="No"
                active={isErops === false}
                marginRight="8px"
                onClick={(): void => {
                  setIsErops(false);
                  updateDefectData([{ value: false, key: 'erops_prevented' }]);
                }}
              />
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper column={editDefect}>
            {isErops && !ignoreEROPS ? (
              <FlexWrapper column marginRight={!editDefect && 100}>
                <WarningText>{formatMessage({ id: 'text.eropsWarning' }, { field: eropsTitle })}</WarningText>
              </FlexWrapper>
            ) : null}
          </FlexWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default AircraftLimitations;
