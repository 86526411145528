import { Button, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.less';
import KeyValueFilter from './KeyValueFilter';

class ParentFilter extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    valueKey: PropTypes.string,
    children: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    valueKey: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      activeChildrenKeys: [],
    };
  }

  toggleChildrenVisibility = (key, children) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { activeChildrenKeys: activeChildren } = this.state;
    const activeChildrenKeys = [...activeChildren];
    if (activeChildrenKeys.includes(key)) {
      // Item is in array -> it's opened
      if (children.some((child) => child.value === true)) {
        // If children are active, prevent from closing
        message.warning(formatMessage({ id: 'message.deselectItems' }));
      } else {
        const index = activeChildrenKeys.indexOf(key);
        activeChildrenKeys.splice(index, 1);
      }
    } else {
      // Item is not in array -> it's closed -> add it to array to open it
      activeChildrenKeys.push(key);
    }
    this.setState({
      activeChildrenKeys,
    });
  };

  render() {
    const { title, valueKey, children, onChange } = this.props;
    const { activeChildrenKeys } = this.state;
    return (
      <>
        <Button
          type="link"
          className={`${styles.singleFilter}`}
          onClick={() => this.toggleChildrenVisibility(valueKey, children)}
          data-test="parentFilter"
        >
          <div className={styles.parentTitleHolder}>
            {title}
            {children.length > 0 && activeChildrenKeys.includes(valueKey) ? (
              <Icon type="minus" className={styles.plusIcon} data-test="minusIcon" />
            ) : (
              <Icon type="plus" className={styles.plusIcon} data-test="plusIcon" />
            )}
          </div>
        </Button>
        {children.length > 0 &&
          activeChildrenKeys.includes(valueKey) &&
          children.map((child) => {
            return (
              <KeyValueFilter
                title={child.title}
                additionalClass={styles.singleFilterChild}
                key={child.key}
                value={child.value}
                onChange={() => onChange(child.key)}
              />
            );
          })}
      </>
    );
  }
}

export default injectIntl(ParentFilter);
