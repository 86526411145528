import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import BooleanFilter from './BooleanFilter';
import styles from './index.module.less';
import KeyValueFilter from './KeyValueFilter';
import ParentFilter from './ParentFilter';

const FiltersGroup = ({ group, onFilterChange, onFiltersClear }) => {
  const { formatMessage } = useIntl();
  const componentToTypeMap = new Map([
    ['boolean', BooleanFilter],
    ['keyValue', KeyValueFilter],
    ['parent', ParentFilter],
  ]);

  const checkGroupFilters = () =>
    group.filters.some(
      (filter) => filter.value || (filter.type === 'parent' && filter.children.some((child) => child.value)),
    );

  return (
    <>
      <div className={styles.groupTitle} data-test="groupTitle">
        {group.title}
        {checkGroupFilters() && (
          <Button
            type="link"
            className={styles.clearFilter}
            onClick={() => onFiltersClear(group.key)}
            data-test="clearFilter"
          >
            {formatMessage({ id: 'text.clearAll' })}
          </Button>
        )}
      </div>
      {group.filters.map((filter) => {
        const FilterComponent = componentToTypeMap.get(filter.type);
        return (
          <FilterComponent
            key={`${group.key}_${filter.type}_${filter.key}_${filter.title}`}
            valueKey={filter.key}
            value={filter.value}
            title={filter.title}
            number={filter.number}
            onChange={(key) => onFilterChange(key, group.key)}
          >
            {filter.children}
          </FilterComponent>
        );
      })}
    </>
  );
};

FiltersGroup.propTypes = {
  group: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
};

export default FiltersGroup;
