/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import React, { useState, useEffect, useRef } from 'react';
import servers from '../../utils/servers';
import { DownloadButton, DeleteButton, Next, Prev, Close } from './carouselButtons';
import styles from './fileViewer.module.less';

const CurrentAttachment = ({ attachments, currIndex, haveAttachments }): JSX.Element => {
  const currAttachment = attachments[currIndex];
  let currFileUrl = currAttachment?.attachment || (currAttachment?.url && `${servers.api}/${currAttachment?.url}`);

  if (haveAttachments && currFileUrl) {
    const attachmentIsPdf =
      currAttachment?.attachment_file_name?.includes('pdf') || currAttachment?.content_type?.includes('pdf');

    if (attachmentIsPdf) {
      return (
        <div className={styles.pdfWrapper} id="fileViewImage">
          <embed
            src={currAttachment?.attachment || `${servers.api}/${currAttachment?.pdf_url}`}
            width="100%"
            height="100%"
            type="application/pdf"
          />
        </div>
      );
    }
  }

  if (currFileUrl) {
    if (currFileUrl.indexOf('?') !== -1 && !currFileUrl.startsWith('data:image/png')) {
      currFileUrl = `${currFileUrl.split('?')[0]}jpeg`;
    }
    if (currFileUrl && !currFileUrl.endsWith('jpg' || 'png' || 'jpeg') && !currFileUrl.startsWith('data:image')) {
      currFileUrl += 'jpeg';
    }

    return (
      <div className={styles.imageWrapper} id="fileViewImage">
        <img className={styles.carouselImage} src={currFileUrl} alt="file preview" />
      </div>
    );
  }

  return <span className={styles.imageWrapper}>Image not available</span>;
};

const ImageCarousel = ({ attachments, toggleShowFile, chosenIndex, handleDeleteClick, allowDelete }): JSX.Element => {
  const imgFadeRef = useRef<HTMLDivElement>();
  const modalFadeRef = useRef<HTMLDivElement>();

  const [currentIndex, updateCurrentIndex] = useState(null);

  // Fade in img when carousel moves.
  useEffect(() => {
    imgFadeRef.current.classList.add(styles.fadeIn);
  }, [currentIndex, chosenIndex]);

  // Fade in the carousel modal.
  useEffect(() => {
    modalFadeRef.current.classList.add(styles.fadeIn);
  }, []);

  const closeFileView = (): void => {
    modalFadeRef.current.classList.remove(styles.fadeIn);
    setTimeout(() => {
      toggleShowFile({ visible: false, index: 0 });
    }, 1000);
  };

  const nextSlide = (): void => {
    if (currentIndex < attachments.length - 1) {
      imgFadeRef.current.classList.remove(styles.fadeIn);
      setTimeout(() => {
        updateCurrentIndex((index) => (index += 1));
      }, 400);
    }
  };

  const prevSlide = (): void => {
    if (currentIndex > 0) {
      imgFadeRef.current.classList.remove(styles.fadeIn);
      setTimeout(() => {
        updateCurrentIndex((index) => (index -= 1));
      }, 400);
    }
  };

  const currAttachment = attachments[currentIndex];
  const haveAttachments = attachments.length > 0;
  const currFileName = haveAttachments && (currAttachment?.file_name || currAttachment?.attachment_file_name);

  if (!haveAttachments) {
    closeFileView();
  }

  useEffect(() => {
    if (chosenIndex !== null) {
      updateCurrentIndex(chosenIndex);
    }
  }, [chosenIndex]);

  return (
    <div ref={modalFadeRef} className={styles.fileViewerBackground}>
      <div className={styles.fileViewContentWrapper}>
        <div className={styles.fileViewCol}>
          <div className={styles.imgTitle}>{currFileName}</div>
          <div className={styles.downloadAndDeleteRow}>
            {currAttachment?.url && (
              <DownloadButton
                downloadLink={haveAttachments && `${servers.api}/${currAttachment?.url}`}
                fileName={currFileName}
              />
            )}
            <DeleteButton
              id={haveAttachments && currAttachment?.id}
              handleDeleteClick={handleDeleteClick}
              allowDelete={allowDelete}
              name={currFileName}
            />
          </div>
          {currentIndex > 0 ? <Prev prevSlide={prevSlide} /> : null}
        </div>
        <div ref={imgFadeRef} className={`${styles.fileViewCol} ${styles.hideMe}`}>
          <CurrentAttachment
            attachments={attachments.filter((item) => !item._destroy)}
            currIndex={currentIndex}
            haveAttachments={haveAttachments}
          />
        </div>
        <div className={styles.fileViewCol}>
          <Close closeFileView={closeFileView} />
          {currentIndex < attachments.length - 1 ? <Next nextSlide={nextSlide} /> : null}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
