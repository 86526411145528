import styled from 'styled-components';

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  padding: 0 16px 16px 16px;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

export const ItemWrapper = styled.div`
  grid-column: ${({ span }): string => (span ? `span ${span}` : 'auto')};
  padding-right: 24px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
  @media (max-width: 1720px) {
    grid-column: ${({ span, apu }): string => {
      if (span && apu) return 'span 2';
      if (span) return `span ${span}`;
      return 'auto';
    }};
  }
  @media (max-width: 451px) {
    grid-column: 1;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '600' : '400')};
  line-height: ${({ edit }): string => (edit ? '1.33' : 'normal')};
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

export const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
  min-height: 18px;
  text-transform: ${({ capitalize }): string => (capitalize ? 'capitalize' : 'none')};
`;

export const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

export const UnitWrapper = styled.div`
  display: flex;
`;

export const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

export const DrawerWrapper = styled.div`
  width: 100%;
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '24px')};
  text-transform: capitalize;
  display: flex;
  height: 32px;
  align-items: center;
  img {
    margin: 0 4px 0 8px;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    height: auto;
    max-width: 96%;
  }
`;

export const Header = styled.div`
  color: ${({ editable }): string => (editable ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  letter-spacing: ${({ editable }): string => (editable ? 'normal' : '0.4px')};
  font-size: 12px;
  text-transform: ${({ editable }): string => (editable ? 'capitalize' : 'uppercase')};
  font-weight: ${({ editable }): string => (editable ? '500' : 'normal')};
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }): string => theme.colours.black10Alpha};
  margin: 20px 0;
`;
