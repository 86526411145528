export function formatOptionText(e) {
  const removeUnderscore = e.split('_');
  const capitalise = [];
  removeUnderscore.forEach((s) => {
    capitalise.push(s.charAt(0).toUpperCase() + s.slice(1));
  });
  return capitalise.join(' ');
}

export function snakifyOptionText(e) {
  const removeSpaces = e.split(' ');
  const smallChars = [];
  removeSpaces.forEach((s) => {
    smallChars.push(s.charAt(0).toLowerCase() + s.slice(1));
  });
  return smallChars.join('_');
}

export function getAircraftName(aircraftList, id) {
  const aircraftData = aircraftList.filter((f) => {
    return f.id === id;
  });
  if (aircraftData.length > 0) {
    return aircraftData[0].registration;
  }
  return null;
}
