import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import AIIconPurple from '../../assets/icon-ai-purple.svg';
import AIIconGreen from '../../assets/icon-ai-green.svg';

const AIButtonWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  margin-top: 3px;
  left: 162px;
`;

const StyledAIButton = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
`;

const AITooltip = styled.div`
  width: 180px;
  height: 42px;
  background-color: white;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 0 8px 3px #dbe3ed;
  right: -78px;
  margin-top: 8px;
  p {
    line-height: 1.4;
  }
  span {
    font-weight: 600;
  }
`;

export type AIButtonProps = {
  isActive: boolean;
  onClick: () => void;
};

const AIButton: React.FC<AIButtonProps> = ({ isActive, onClick }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  return (
    <AIButtonWrapper>
      <StyledAIButton
        src={isActive ? AIIconGreen : AIIconPurple}
        active={isActive}
        onClick={onClick}
        onMouseEnter={(): void => {
          setShowTooltip(true);
        }}
        onMouseLeave={(): void => {
          setShowTooltip(false);
        }}
        data-testid="aiButton"
      />
      {showTooltip && (
        <AITooltip data-testid="aiTooltip">
          <p>
            {isActive
              ? `${formatMessage({ id: 'text.aiSearchingUsing' })} `
              : `${formatMessage({ id: 'text.aiEnhanceYourSearch' })} `}
            <span>{formatMessage({ id: 'text.aiBeta' })}</span>
          </p>
        </AITooltip>
      )}
    </AIButtonWrapper>
  );
};

export default AIButton;
