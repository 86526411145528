import React from 'react';
import { Button, Icon, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import planeIcon from '../../../../assets/plane-icon.svg';
import styles from './MXDraftQueue.module.less';

const ModalHeader = ({
  registration,
  individualMode,
  individualItem,
  individualCurrent,
  individualTotal,
  onPrevItem,
  onNextItem,
  onDisableIndividual,
  intl: { formatMessage },
}) => {
  let modalTitle = (
    <>
      {formatMessage({ id: 'text.draftQueueSummaryFor' })} {registration}
    </>
  );
  const getMXItemType = (item) => {
    if (item && item.mx_type) {
      if (item.package) {
        return formatMessage({ id: 'text.package' });
      }
      let standardItemType = item.mx_type.substring(item.mx_type.lastIndexOf('_') + 1, item.mx_type.length);
      if (standardItemType === 'scheduled') {
        standardItemType = formatMessage({ id: 'tab.scheduled' });
      } else if (standardItemType === 'llp') {
        standardItemType = formatMessage({ id: 'text.llp' });
      } else {
        standardItemType = formatMessage({ id: 'text.oop' });
      }
      return standardItemType;
    }
    return null;
  };
  const getItemType = (item) => {
    if (item.camp_diffs && item.camp_diffs.length > 0) {
      return formatMessage({ id: 'text.anUpdated' });
    }
    return formatMessage({ id: 'text.aNew' });
  };

  if (individualMode && individualItem) {
    modalTitle = `${formatMessage({ id: 'text.reviewing' })} ${getItemType(individualItem)} ${getMXItemType(
      individualItem,
    )} ${formatMessage({ id: 'text.requirement' })}`;
  }

  return (
    <Row className={`${styles.modalHeaderWrapper} ${styles.rowContent}`}>
      <Col md={24} className={styles.modalHeader}>
        <div className={styles.headerLeft}>
          <img src={planeIcon} alt="plane" data-test="aircraftIcon" />
          <div className={styles.aircraftRegistration} data-test="aircraftRegistration">
            {registration}
          </div>
          <div className={styles.aircraftDescription} data-test="aircraftMessage">
            {modalTitle}
          </div>
        </div>
        {individualMode && window.innerWidth > 991 && (
          <div className={styles.headerRight}>
            <div className={styles.buttonBackWrapper}>
              <Button type="link" className={styles.buttonBack} onClick={() => onDisableIndividual()}>
                <Icon type="arrow-left" />
                {formatMessage({ id: 'text.backToAllItems' })}
              </Button>
            </div>
            <div className={styles.itemSwitcher}>
              <Button
                type="link"
                className={styles.leftArrow}
                onClick={() => onPrevItem()}
                disabled={individualCurrent === 1}
              >
                <Icon type="left" />
              </Button>
              <div className={styles.arrowTextHolder}>
                <span>{individualCurrent}</span> {formatMessage({ id: 'text.of' })} {individualTotal}
              </div>
              <Button
                type="link"
                className={styles.rightArrow}
                onClick={() => onNextItem()}
                disabled={individualCurrent === individualTotal}
              >
                <Icon type="right" />
              </Button>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

ModalHeader.propTypes = {
  registration: PropTypes.string.isRequired,
  individualItem: PropTypes.object,
  individualCurrent: PropTypes.number,
  individualTotal: PropTypes.number,
  individualMode: PropTypes.bool,
  onPrevItem: PropTypes.func,
  onNextItem: PropTypes.func,
  onDisableIndividual: PropTypes.func,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

ModalHeader.defaultProps = {
  individualMode: false,
  individualItem: {},
  individualCurrent: null,
  individualTotal: null,
  onPrevItem: null,
  onNextItem: null,
  onDisableIndividual: null,
};

export default injectIntl(ModalHeader);
