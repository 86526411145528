/* eslint-disable @typescript-eslint/no-explicit-any */
import { queryAllFlights, queryFlightsOnly, getFlight, deleteFlight } from '../../services/api';
import { intl } from '../../providers/IntlProvider';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { FlightsActionTypes } from '.';

export const getAllFlights =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryAllFlights(payload);
    dispatch({
      type: FlightsActionTypes.SAVE_ALL,
      payload: response,
    });
  };
export const getFlightsForAircraft =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryFlightsOnly(payload);
    if (response && response.res && response.res.status > 199 && response.res.status < 399) {
      dispatch({
        type: FlightsActionTypes.SAVE_FLIGHTS_ONLY,
        payload: { flights: response.data, aircraftId: payload.id },
      });
    }
  };
export const getFlightDetails =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getFlight(payload);
    dispatch({
      type: FlightsActionTypes.SAVE_FLIGHT_DETAILS,
      payload: response,
    });
  };
export const storeNewFlight =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: FlightsActionTypes.SAVE_FLIGHTS_ONLY,
      payload,
    });
  };
export const saveParams =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: FlightsActionTypes.SET_PARAMS,
      payload,
    });
  };
export const saveFilters =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: FlightsActionTypes.SET_FILTERS,
      payload,
    });
  };
export const setFlightsDirty =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: FlightsActionTypes.SET_DIRTY,
      payload,
    });
  };
export const deleteFlightEntry =
  ({ payload, sectorNameOverride }, callBack = null) =>
  async (dispatch): Promise<any> => {
    const response = await deleteFlight(payload);
    if (response && response.statusCode > 199 && response.statusCode < 400) {
      if (callBack) {
        callBack();
      }
      dispatch({
        type: FlightsActionTypes.DELETE_FLIGHT,
        payload,
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.sectorRemoved' }, { sector: sectorNameOverride }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage(
              { id: 'message.sectorRemoveFailed' },
              { sector: sectorNameOverride.toLowerCase() },
            ),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };
