import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Badge } from 'antd';
import NonStyledButton from '../../components/NonStyledButton/NonStyledButton';
import styles from './DamageMaps.module.less';

class DisplayThumbnails extends Component {
  static propTypes = {
    handleThumbnailClick: PropTypes.func.isRequired,
    newThumbnails: PropTypes.array,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    newThumbnails: [],
  };

  render() {
    const {
      handleThumbnailClick,
      newThumbnails,
      intl: { formatMessage },
    } = this.props;
    return newThumbnails
      .filter((thumbnail) => thumbnail[0])
      .map((thumbnail, index) => {
        const complete = thumbnail[2] && thumbnail[1] !== formatMessage({ id: 'text.categoryNotSet' });
        let thumbnailText = thumbnail[1];
        if (thumbnail[1] === 'general') {
          thumbnailText = 'all';
        } else if (thumbnail[1] === 'propellor') {
          thumbnailText = 'propeller';
        }
        return (
          <div key={thumbnail.id}>
            <Badge
              status={complete ? 'success' : 'default'}
              count={1}
              offset={[-120, 13]}
              style={{ width: 12, height: 12 }}
              className={styles.statusBadge}
              key={`${thumbnail.id} badge`}
            >
              <NonStyledButton onClick={() => handleThumbnailClick(index)}>
                <div className={styles.thumbnailContainer} style={{ backgroundImage: `url(${thumbnail[0]})` }} />
              </NonStyledButton>
            </Badge>
            <div className={styles.thumbnailTitleDiv}>
              <span className={styles.thumbnailTitle}>{thumbnail[2]}</span>
            </div>
            <div className={styles.thumbnailCategoryDiv}>
              <span className={styles.thumbnailCategory}>
                {formatMessage({
                  id: `form.button.${thumbnailText}`,
                })}
              </span>
            </div>
          </div>
        );
      });
  }
}

export default injectIntl(DisplayThumbnails);
