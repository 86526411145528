import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { Aircraft } from '../../models/aircraft';
import RadioInputButton from '../../components/RadioInputButton/RadioInputButton';
import { changeDrawerVisibility } from '../../models/drawer';
import { add } from '../../models/userSettings/actions';

interface LogbookDrawerProps {
  selectedAircraft: Aircraft[];
  setSelectedAircraft: (aircraft: Aircraft[]) => void;
  checkedColumns: string[];
  setCheckedColumns: (columns: string[]) => void;
}

const fixedColumns = ['trip_date', 'departure_airport', 'arrival_airport'];

const columnOrder = [
  'trip_date',
  'departure_airport',
  'arrival_airport',
  'trip_number',
  'ac_registration',
  'blocks_off',
  'takeoff',
  'landing',
  'blocks_on',
  'flight_time',
  'blocks_time',
  'captain',
  'first_officer',
  'landings',
  'airframe_hours',
  'engine_1_hours',
  'engine_2_hours',
  'engine_3_hours',
  'engine_4_hours',
  'engine_1_cycles',
  'engine_2_cycles',
  'engine_3_cycles',
  'engine_4_cycles',
  'apu_hours',
  'apu_cycles',
  'prop_1_hours',
  'prop_2_hours',
  'prop_3_hours',
  'prop_4_hours',
  'airframe_landings',
  'airframe_cycles',
  'hobbs_arrival',
  'hobbs_departure',
  'flight_hobbs',
  'passengers_male',
  'passengers_female',
  'passengers_children',
  'passengers_infants',
  'total_passengers',
  'engine_1_oil_uplift',
  'engine_2_oil_uplift',
  'engine_3_oil_uplift',
  'engine_4_oil_uplift',
  'initial_fuel',
  'adjusted_initial_fuel',
  'total_fuel_truck_uplift',
  'total_aircraft_uplift',
  'fuel_adjustment',
  'fuel_adjustment_justification',
  'departure_fuel',
  'arrival_fuel',
  'planned_fuel_burn',
  'actual_fuel_burn',
  'srp_number',
  'flight_source',
];

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
  margin: 16px 0;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '0')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const SaveButton = styled.div`
  margin-right: 12px;
`;

const LogbookDrawer: React.FC<LogbookDrawerProps> = ({
  selectedAircraft,
  setSelectedAircraft,
  checkedColumns,
  setCheckedColumns,
}) => {
  const {
    aircraft: { aircraftMap },
    userSettings: { logbook_columns },
    aircraftLogbook: { selectableColumns },
  } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();

  const [adaptedColumns, setAdaptedColumns] = useState<any[]>([]);
  const [localAircraft, setLocalAircraft] = useState<Aircraft[]>([]);
  const [localCheckedColumns, setLocalCheckedColumns] = useState<string[]>([]);
  const handleAircraftClick = (aircraft): void => {
    if (localAircraft.includes(aircraft)) {
      setLocalAircraft(localAircraft.filter((ac) => ac.id !== aircraft.id));
    } else {
      setLocalAircraft([...localAircraft, aircraft]);
    }
  };
  const handleColumnSelection = (title: string): void => {
    if (localCheckedColumns.includes(title)) {
      setLocalCheckedColumns(localCheckedColumns.filter((col) => col !== title));
    } else {
      setLocalCheckedColumns([...localCheckedColumns, title]);
    }
  };

  const alphabeticalSort = (a, b): number => {
    if (Array.isArray(a)) {
      const aReg = a[1]?.registration;
      const bReg = b[1]?.registration;
      if (aReg === bReg) {
        return 0;
      }
      return aReg < bReg ? -1 : 1;
    }
    if (a?.title === b?.title) {
      return 0;
    }
    return a?.title < b?.title ? -1 : 1;
  };

  const buildAircraft = (): ReactElement[] => {
    return Array.from(aircraftMap)
      .sort(alphabeticalSort)
      .map(([key, aircraft]) => {
        return (
          <RadioInputButton
            key={key}
            text={aircraft.registration}
            active={localAircraft.filter((ac) => ac.id === aircraft.id).length > 0}
            checkbox
            marginRight="8px"
            marginBottom="8px"
            onClick={(): void => handleAircraftClick(aircraft)}
          />
        );
      });
  };

  const buildColumns = (): ReactElement[] => {
    const columnOptions = adaptedColumns
      ?.filter((item) => {
        if (fixedColumns?.includes(item.title)) return null;
        return item;
      })
      .sort(alphabeticalSort)
      .map((column) => {
        let newTitle = column.title.replace(/_/g, ' ');
        if (column.title === 'airframe_hours_original') newTitle = 'airframe hours';
        if (column.title === 'airframe_landings') newTitle = 'total landings';
        if (column.title === 'ac_registration') newTitle = 'registration';
        if (column.title.endsWith('uplift')) newTitle = newTitle.replace(' uplift', '');
        newTitle = newTitle.charAt(0).toUpperCase() + newTitle.slice(1);
        return (
          <RadioInputButton
            key={column.title}
            text={newTitle}
            active={localCheckedColumns.includes(column.title)}
            checkbox
            marginRight="8px"
            marginBottom="8px"
            onClick={(): void => handleColumnSelection(column.title)}
          />
        );
      });
    return columnOptions;
  };

  const handleSave = (): void => {
    setCheckedColumns(localCheckedColumns);
    setSelectedAircraft(localAircraft);
    if (!_.isEqual(checkedColumns, localCheckedColumns)) {
      const formData = new FormData();
      formData.append('logbook_columns', JSON.stringify(localCheckedColumns));
      dispatch(add({ payload: { form: formData } }));
    }
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const closeDrawer = (): void => {
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  useEffect(() => {
    if (selectableColumns) {
      const newColumns = selectableColumns
        .filter((col) => columnOrder.indexOf(col) !== -1)
        .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
        .map((item) => {
          const isChecked = logbook_columns.includes(item);
          return { title: item, checked: isChecked };
        });
      setAdaptedColumns(newColumns);
    }
  }, [logbook_columns, selectableColumns]);

  useEffect(() => {
    if (selectedAircraft.length > 0) {
      setLocalAircraft(selectedAircraft);
    }
  }, [selectedAircraft]);

  useEffect(() => {
    if (checkedColumns.length > 0) {
      setLocalCheckedColumns(checkedColumns);
    }
  }, [checkedColumns]);

  return (
    <DrawerWrapper>
      <Header>Aircraft</Header>
      <OptionsWrapper>{buildAircraft()}</OptionsWrapper>
      <Header>Columns</Header>
      <OptionsWrapper>{buildColumns()}</OptionsWrapper>
      <ButtonWrapper>
        <SaveButton>
          <Button height="32px" onClick={(): void => handleSave()}>
            Save
          </Button>
        </SaveButton>
        <Button primary={false} height="32px" onClick={closeDrawer}>
          Cancel
        </Button>
      </ButtonWrapper>
    </DrawerWrapper>
  );
};

export default LogbookDrawer;
