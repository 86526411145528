/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { OperatorSetting } from '../../models/userSettings';
import ToolTip from '../NewTooltip/NewTooltip';
import TFNumberInput from '../TFNumberInput/TFNumberInput';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import ShowHideToggle from './ShowHideToggle';

interface GeneralSettingsGridProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  editMode: boolean;
  operatorSettings: OperatorSetting;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  padding: 0 16px 16px 16px;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1310px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const ItemWrapper = styled.div`
  grid-column: ${({ span }): string => (span ? `span ${span}` : 'auto')};
  padding-right: 24px;
  @media (max-width: 1720px) {
    grid-column: ${({ span, apu }): string => {
      if (span && apu) return 'span 2';
      if (span) return `span ${span}`;
      return 'auto';
    }};
  }
  @media (max-width: 451px) {
    grid-column: 1;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '600' : '400')};
  line-height: ${({ edit }): string => (edit ? '1.33' : 'normal')};
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

const Text = styled.div`
  line-height: 1.43;
  color: rgba(36, 54, 65, 0.7);
  min-height: 18px;
  text-transform: ${({ capitalize }): string => (capitalize ? 'capitalize' : 'none')};
`;

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const GeneralSettingsGrid: React.FC<GeneralSettingsGridProps> = ({
  aircraft,
  updateAircraftData,
  editMode,
  operatorSettings,
}) => {
  const handleInputChange = (value: any, key: string): void => {
    updateAircraftData([{ value, key }]);
  };

  const handleAppSettingInputChange = (value: any, key: string): void => {
    const newAppSettings = aircraft?.app_settings;
    if (key === 'specific_gravity' && value) {
      newAppSettings[`${key}`] = parseFloat(value);
    } else {
      newAppSettings[`${key}`] = value;
    }
    updateAircraftData([{ value: newAppSettings, key: 'app_settings' }]);
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleAPUFormatClick = (type: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    const newAPUfields = newStandardFields?.apu || {};
    newAPUfields.format = type;
    newStandardFields.apu = newAPUfields;
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  return (
    <StyledGrid>
      <ItemWrapper data-testid="GeneralSettingsGrid--FleetNumber">
        <Label edit={editMode}>
          Fleet no.
          <ToolTip text="Enter a fleet number if you refer to aircraft by a system other than registration in your organisation. This will be used to identify the aircraft throughout the Tech Log Application and Web Dashboard" />
        </Label>
        {!editMode ? (
          <Text>{aircraft?.fleet_number || '-'}</Text>
        ) : (
          <StyledInput
            data-testid="GeneralSettingsGrid--FleetNumberInput"
            value={aircraft?.fleet_number}
            type="text"
            onChange={(e): void => handleInputChange(e.target.value, 'fleet_number')}
          />
        )}
      </ItemWrapper>
      {aircraft?.standard_fields?.trip_callsign?.enabled === false ? null : (
        <ItemWrapper data-testid="GeneralSettingsGrid--DefaultCallsign">
          <Label edit={editMode}>
            Default callsign
            <ToolTip text="A default callsign will be pre-filled into the callsign field when starting a trip" />
          </Label>
          {!editMode ? (
            <Text>{aircraft?.app_settings?.default_callsign || '-'}</Text>
          ) : (
            <StyledInput
              value={aircraft?.app_settings?.default_callsign}
              type="text"
              onChange={(e): void => handleAppSettingInputChange(e.target.value, 'default_callsign')}
            />
          )}
        </ItemWrapper>
      )}
      <ItemWrapper data-testid="GeneralSettingsGrid--SerialNumber">
        <Label edit={editMode}>Serial no.</Label>
        {!editMode ? (
          <Text>{aircraft?.serial_number || '-'}</Text>
        ) : (
          <StyledInput
            value={aircraft?.serial_number}
            type="text"
            onChange={(e): void => handleInputChange(e.target.value, 'serial_number')}
          />
        )}
      </ItemWrapper>
      <ItemWrapper data-testid="GeneralSettingsGrid--Year">
        <Label edit={editMode}>Year of manufacture</Label>
        {!editMode ? (
          <Text>{aircraft?.year || '-'}</Text>
        ) : (
          <StyledInput
            value={aircraft?.year}
            type="text"
            onChange={(e): void => handleInputChange(e.target.value, 'year')}
          />
        )}
      </ItemWrapper>
      <ItemWrapper span={editMode ? '2' : null} data-testid="GeneralSettingsGrid--FuelUnit">
        <Label edit={editMode}>Fuel unit</Label>
        {!editMode ? (
          <Text capitalize>{aircraft?.fuel_unit}</Text>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="lb"
              active={aircraft?.fuel_unit === 'lb'}
              marginRight="8px"
              onClick={(): void => handleInputChange('lb', 'fuel_unit')}
            />
            <RadioInputButton
              text="kg"
              active={aircraft?.fuel_unit === 'kg'}
              marginRight="8px"
              onClick={(): void => handleInputChange('kg', 'fuel_unit')}
            />
            <RadioInputButton
              text="ltr"
              active={aircraft?.fuel_unit === 'l'}
              marginRight="8px"
              onClick={(): void => handleInputChange('l', 'fuel_unit')}
            />
            <RadioInputButton
              text="usg"
              active={aircraft?.fuel_unit === 'usg'}
              marginRight="8px"
              onClick={(): void => handleInputChange('usg', 'fuel_unit')}
            />
          </RadioWrapper>
        )}
      </ItemWrapper>
      <ItemWrapper data-testid="GeneralSettingsGrid--FuelCapacity">
        <Label edit={editMode}>{`Max fuel capacity (${aircraft?.fuel_unit})`}</Label>
        {!editMode ? (
          <Text>{aircraft?.max_fuel_value || '-'}</Text>
        ) : (
          <StyledInput
            value={aircraft?.max_fuel_value}
            type="text"
            onChange={(e): void => handleInputChange(e.target.value, 'max_fuel_value')}
          />
        )}
      </ItemWrapper>
      <ItemWrapper span={editMode ? '2' : null} data-testid="GeneralSettingsGrid--OilUnit">
        <Label edit={editMode}>Oil unit</Label>
        {!editMode ? (
          <Text>{aircraft?.oil_unit}</Text>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="ml"
              active={aircraft?.oil_unit === 'ml'}
              marginRight="8px"
              onClick={(): void => handleInputChange('ml', 'oil_unit')}
            />
            <RadioInputButton
              text="qt"
              active={aircraft?.oil_unit === 'qt'}
              marginRight="8px"
              onClick={(): void => handleInputChange('qt', 'oil_unit')}
            />
            <RadioInputButton
              text="usg"
              active={aircraft?.oil_unit === 'usg'}
              marginRight="8px"
              onClick={(): void => handleInputChange('usg', 'oil_unit')}
            />
          </RadioWrapper>
        )}
      </ItemWrapper>
      <ItemWrapper span={editMode ? '4' : null} apu data-testid="GeneralSettingsGrid--APUMode">
        <Label edit={editMode}>
          APU mode
          <ToolTip text="The Tech Log supports tracking APU hours either by entering readings from the aircraft (totalled mode) or by entering utilisation against each sector and calculating the running total (cumulative mode)" />
        </Label>
        {!editMode ? (
          <Text capitalize>{aircraft?.apu_mode?.replace('_', ' ')}</Text>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="Not installed"
              active={aircraft?.apu_mode === 'not_installed'}
              marginRight="8px"
              onClick={(): void => handleInputChange('not_installed', 'apu_mode')}
            />
            <RadioInputButton
              text="Totalled"
              active={aircraft?.apu_mode === 'totalled'}
              marginRight="8px"
              onClick={(): void => handleInputChange('totalled', 'apu_mode')}
            />
            <RadioInputButton
              text="Cumulative"
              active={aircraft?.apu_mode === 'cumulative'}
              marginRight="8px"
              onClick={(): void => handleInputChange('cumulative', 'apu_mode')}
            />
          </RadioWrapper>
        )}
      </ItemWrapper>
      {aircraft?.standard_fields?.apu_mode !== 'not_installed' ? (
        <ItemWrapper>
          <Label edit={editMode}>APU format</Label>
          {!editMode ? (
            <Text capitalize>{aircraft?.standard_fields?.apu?.format || 'Decimal'}</Text>
          ) : (
            <RadioWrapper>
              <RadioInputButton
                text="Decimal"
                active={!aircraft?.standard_fields?.apu?.format || aircraft?.standard_fields?.apu?.format === 'decimal'}
                marginRight="8px"
                onClick={(): void => handleAPUFormatClick('decimal')}
              />
              <RadioInputButton
                text="Hours"
                active={aircraft?.standard_fields?.apu?.format === 'hours'}
                marginRight="8px"
                onClick={(): void => handleAPUFormatClick('hours')}
              />
            </RadioWrapper>
          )}
        </ItemWrapper>
      ) : null}
      <ItemWrapper data-testid="GeneralSettingsGrid--Gravity">
        <Label edit={editMode}>
          Default specific gravity
          <ToolTip text="This value will be used by default when converting fuel volume to mass" />
        </Label>
        {!editMode ? (
          <Text>{aircraft?.app_settings?.specific_gravity || '-'}</Text>
        ) : (
          <StyledInput
            value={aircraft?.app_settings?.specific_gravity}
            type="number"
            onChange={(e): void => handleAppSettingInputChange(e.target.value, 'specific_gravity')}
            data-testid="GeneralSettingsGrid--SpecificGravityInput"
          />
        )}
      </ItemWrapper>
      <ItemWrapper span={editMode ? '2' : null} data-testid="GeneralSettingsGrid--EngineCycles">
        <Label edit={editMode}>
          Engine cycles on postflight
          <ToolTip text="If turned On, crew will be able to customise the number of cycles assigned to each engine from the sector" />
        </Label>
        {!editMode ? (
          <Text>{!aircraft?.app_settings?.hide_engine_cycles_fields ? 'Show' : 'Off'}</Text>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="Show"
              active={!aircraft?.app_settings?.hide_engine_cycles_fields}
              marginRight="8px"
              onClick={(): void => handleAppSettingInputChange(false, 'hide_engine_cycles_fields')}
            />
            <RadioInputButton
              text="Hide"
              active={aircraft?.app_settings?.hide_engine_cycles_fields}
              marginRight="8px"
              onClick={(): void => handleAppSettingInputChange(true, 'hide_engine_cycles_fields')}
            />
          </RadioWrapper>
        )}
      </ItemWrapper>
      <ItemWrapper span={editMode ? '2' : null} data-testid="GeneralSettingsGrid--AirframeCycles">
        <Label edit={editMode}>
          Airframe cycles on postflight
          <ToolTip text="If turned On, crew will be able to assign a different number of airframe cycles than landings as the result of a sector" />
        </Label>
        {!editMode ? (
          <Text>{!aircraft?.app_settings?.hide_airframe_cycles_fields ? 'Show' : 'Off'}</Text>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="Show"
              active={!aircraft?.app_settings?.hide_airframe_cycles_fields}
              marginRight="8px"
              onClick={(): void => handleAppSettingInputChange(false, 'hide_airframe_cycles_fields')}
            />
            <RadioInputButton
              text="Hide"
              active={aircraft?.app_settings?.hide_airframe_cycles_fields}
              marginRight="8px"
              onClick={(): void => handleAppSettingInputChange(true, 'hide_airframe_cycles_fields')}
            />
          </RadioWrapper>
        )}
      </ItemWrapper>
      <ItemWrapper span={editMode ? '2' : null} data-testid="GeneralSettingsGrid--MaintenanceColour">
        <Label edit={editMode}>
          Maintenance coloured header
          <ToolTip
            showLeft={window.innerWidth >= 1310}
            text="If enabled, colours the header bar of the iPad Application match the aircraft serviceability status (i.e. Red/Green/Amber/Grey)."
          />
        </Label>
        {!editMode ? (
          <Text>{aircraft?.app_settings?.maintenance_header ? 'Show' : 'Off'}</Text>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="Show"
              active={aircraft?.app_settings?.maintenance_header}
              marginRight="8px"
              onClick={(): void => handleAppSettingInputChange(true, 'maintenance_header')}
            />
            <RadioInputButton
              text="Hide"
              active={!aircraft?.app_settings?.maintenance_header}
              marginRight="8px"
              onClick={(): void => handleAppSettingInputChange(false, 'maintenance_header')}
            />
          </RadioWrapper>
        )}
      </ItemWrapper>
      <ItemWrapper data-testid="GeneralSettingsGrid--EROPS">
        <Label edit={editMode}>{`${operatorSettings?.erops} capable`}</Label>
        {!editMode ? (
          <Text>{aircraft?.standard_fields?.non_erops?.enabled ? 'Yes' : 'No'}</Text>
        ) : (
          <ShowHideToggle
            value={aircraft?.standard_fields?.non_erops?.enabled}
            handleChange={(value: boolean): void => handleToggleChange(value, 'non_erops')}
            showOverride="Yes"
            hideOverride="No"
          />
        )}
      </ItemWrapper>
      {operatorSettings?.max_weight_for_flight_enabled ? (
        <ItemWrapper data-testid="GeneralSettingsGrid--MaxWeight">
          <Label edit={editMode}>Max weight</Label>
          <UnitWrapper>
            {!editMode ? (
              <Text>{aircraft?.max_weight || '-'}</Text>
            ) : (
              <TFNumberInput
                value={aircraft?.max_weight}
                min={1}
                onChange={(value): void => handleInputChange(value, 'max_weight')}
              />
            )}
            <StyledUnit edit={editMode}>kg</StyledUnit>
          </UnitWrapper>
        </ItemWrapper>
      ) : null}
    </StyledGrid>
  );
};

export default GeneralSettingsGrid;
