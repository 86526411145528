/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import WarningRow from '../../WarningRow';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledRadioButton from './StyledRadioButton';

interface RectificationIntervalExtensionProps {
  defect: Defect | null;
  onChange: Function;
  updateDefectData: (changes: { key: string; value: any }[]) => void;
}

const RectificationIntervalExtension: React.FC<RectificationIntervalExtensionProps> = ({
  defect,
  onChange,
  updateDefectData,
}) => {
  const { formatMessage } = useIntl();
  const [isUsingRectificationExtension, setIsUsingRectificationExtension] = useState(false);
  const [extensionDate, setExtensionDate] = useState(null);
  const [extensionDateDue, setExtensionDateDue] = useState<undefined | Moment>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!defect || loaded) {
      return;
    }

    const rie = defect.rectification_interval_extension;
    setLoaded(true);
    setIsUsingRectificationExtension(!!rie);

    if (rie) {
      const dateDue = rie.extension_date_due ? moment.utc(rie.extension_date_due) : null;
      setExtensionDate(moment.utc(rie.extension_date));
      setExtensionDateDue(dateDue);
      updateDefectData([
        { value: moment.utc(rie.extension_date), key: 'rectificationExtenstionExtendedOn' },
        { value: dateDue, key: 'rectificationExtensionDateDue' },
        { value: 'Yes', key: 'hasRectificationExtension' },
      ]);
    }
  }, [defect, loaded, updateDefectData]);

  const handleExtensionDateChange = (date: Moment): void => {
    setExtensionDate(date);
    onChange();
    if (extensionDateDue && extensionDateDue.isBefore(date)) {
      setExtensionDateDue(date);
      updateDefectData([
        { value: date, key: 'rectificationExtenstionExtendedOn' },
        { value: date, key: 'rectificationExtensionDateDue' },
      ]);
    } else {
      updateDefectData([{ value: date, key: 'rectificationExtenstionExtendedOn' }]);
    }
  };

  const handleExtensionDateDueChange = (date: Moment): void => {
    setExtensionDateDue(date);
    updateDefectData([{ value: date, key: 'rectificationExtensionDateDue' }]);
    onChange();
  };

  const disableDatesBefore = (date): boolean => {
    return date.isBefore(moment.utc(defect?.raised_at).subtract(1, 'd'));
  };

  const disableDatesBeforeExtension = (date): boolean => {
    return date.isBefore(moment.utc(extensionDate));
  };

  const rectificationExtensionFields = (): ReactElement => {
    const extensionDateDueProps: { defaultValue?: Moment } = {};

    if (extensionDateDue) {
      extensionDateDueProps.defaultValue = extensionDateDue;
    }

    return (
      <>
        <div>
          <Label marginTop={20} marginBottom={5} fontWeight={500}>
            {formatMessage({ id: 'form.labels.defectExtendedOn' })}
          </Label>
          <DatePicker
            value={extensionDate}
            allowClear={false}
            onChange={handleExtensionDateChange}
            name="rectificationExtenstionExtendedOn"
            disabledDate={disableDatesBefore}
          />
        </div>
        <div>
          <Label marginTop={20} marginBottom={5} fontWeight={500}>
            {formatMessage({ id: 'form.labels.defectExtensionDateDue' })}
          </Label>
          <DatePicker
            {...extensionDateDueProps}
            value={extensionDateDue}
            onChange={handleExtensionDateDueChange}
            name="rectificationExtensionDateDue"
            disabledDate={disableDatesBeforeExtension}
          />
        </div>
        {!extensionDateDue ? <WarningRow message={formatMessage({ id: 'text.defectExtensionNoDateDue' })} /> : null}
      </>
    );
  };

  return (
    <FlexWrapper column>
      <Label marginBottom={20} fontWeight={600}>
        {formatMessage({ id: 'form.labels.rectificationIntervalExtension' })}
      </Label>
      <Label marginBottom={10} fontWeight={500}>
        {formatMessage({ id: 'text.applyDefectExtension' })}
      </Label>
      <FlexWrapper>
        <StyledRadioButton
          id="yesExtension"
          group="hasRectificationExtension"
          value="Yes"
          marginRight={5}
          checked={isUsingRectificationExtension}
          onClick={(): void => {
            setIsUsingRectificationExtension(true);
            updateDefectData([{ value: 'Yes', key: 'hasRectificationExtension' }]);
          }}
        />
        <StyledRadioButton
          id="noExtension"
          group="hasRectificationExtension"
          value="No"
          checked={!isUsingRectificationExtension}
          onClick={(): void => {
            setIsUsingRectificationExtension(false);
            updateDefectData([{ value: 'No', key: 'hasRectificationExtension' }]);
          }}
        />
      </FlexWrapper>
      {isUsingRectificationExtension ? rectificationExtensionFields() : null}
    </FlexWrapper>
  );
};

export default RectificationIntervalExtension;
