import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import maintenanceIcon from '../../assets/icon-card-maintenance-warnings.svg';
import { update } from '../../models/aircraft/actions';
import { changeModalContent, changeModalVisibility } from '../../models/drawer';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { AccordionWrapper, Label, Text } from './AircraftAdvancedSettings';
import AircraftSettingsCard from './AircraftSettingsCard';

interface AircraftMaintenanceSettingsProps {
  aircraft: Aircraft | BaseAircraft;
  isAdmin: boolean;
}

const Wrapper = styled.div`
  padding: 0 16px 16px 16px;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: ${({ just }): string => just || 'normal'};
`;

const Divider = styled.div`
  margin: 16px 0;
  background-color: rgba(36, 45, 65, 0.05);
  height: 1px;
  width: -webkit-fill-available;
`;

const AircraftStatusSettings: React.FC<AircraftMaintenanceSettingsProps> = ({ aircraft, isAdmin }) => {
  const [expanded, setExpanded] = useState(true);
  const [locked, setLocked] = useState(false);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const expansionChange = (expansion: boolean): void => {
    setExpanded(expansion);
  };

  const lockSaveButton = (): void => {
    // lock aircraft
    const payload = {
      aircraftId: aircraft.id,
      aircraft: {
        locked: true,
      },
    };
    dispatch(update({ payload }));
    dispatch(changeModalVisibility({ payload: false }));
  };

  const lockCancelButton = (): void => {
    setLocked(false);
    dispatch(changeModalVisibility({ payload: false }));
  };

  const unlockSaveButton = (): void => {
    // unlock aircraft
    const payload = {
      aircraftId: aircraft.id,
      aircraft: {
        locked: false,
      },
    };
    dispatch(update({ payload }));
    dispatch(changeModalVisibility({ payload: false }));
  };

  const unlockCancelButton = (): void => {
    setLocked(true);
    dispatch(changeModalVisibility({ payload: false }));
  };

  const handleLockAircraft = (): void => {
    setLocked(true);
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.lockAircraft' }),
          text: formatMessage({ id: 'text.lockAircraftAreYouSure' }),
          saveButtonText: formatMessage({ id: 'text.lockAircraft' }),
          saveAction: lockSaveButton,
          cancelAction: lockCancelButton,
        },
      }),
    );
  };

  const handleUnlockAircraft = (): void => {
    setLocked(false);
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.unlockAircraft' }),
          text: formatMessage({ id: 'text.aircraftUnlockText' }),
          saveButtonText: formatMessage({ id: 'text.unlockAircraft' }),
          saveAction: unlockSaveButton,
          cancelAction: unlockCancelButton,
        },
      }),
    );
  };

  const archiveSaveButton = (): void => {
    // archive
    const payload = {
      aircraftId: aircraft.id,
      aircraft: {
        billing_status: 'Archived',
      },
    };
    dispatch(update({ payload }));
    dispatch(changeModalVisibility({ payload: false }));
  };

  const handleArchiveAircraft = (): void => {
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.archive' }),
          text: `This aircraft will be archived, still being available on your account but
           will be removed from you active fleet. This action can not be undone.
           Do you wish to continue?`,
          saveButtonText: formatMessage({ id: 'text.archive' }),
          saveAction: archiveSaveButton,
        },
      }),
    );
  };

  const activeSaveButton = (): void => {
    // active
    const payload = {
      aircraftId: aircraft.id,
      aircraft: {
        billing_status: 'Active',
      },
    };
    dispatch(update({ payload }));
    dispatch(changeModalVisibility({ payload: false }));
  };

  const handleActivateAircraft = (): void => {
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.makeActive' }),
          text: `This will make the aircraft active and available on the Tech Log Application
           and make the aircraft's Billing Start Date as of today. Do you wish to continue?`,
          saveButtonText: formatMessage({ id: 'text.makeActive' }),
          saveAction: activeSaveButton,
        },
      }),
    );
  };

  const deleteSaveButton = (): void => {
    // delete
  };

  const handleDeleteAircraft = (): void => {
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.delete' }),
          text: `Deleting this aircraft will permanently remove it from your
          active fleet along with all associated data.
          This can only be done on demo aircraft. This action cannot be undone. Do you with to continue?`,
          saveButtonText: formatMessage({ id: 'text.delete' }),
          saveAction: deleteSaveButton,
        },
      }),
    );
  };

  useEffect(() => {
    if (aircraft) {
      setLocked(aircraft.locked);
    }
  }, [aircraft]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={maintenanceIcon}
            title="Aircraft status"
            subTitle=""
            buttonText=""
            isAdmin={isAdmin}
          />
        }
      >
        <Wrapper>
          <FlexDiv just="space-between">
            <Label edit>{formatMessage({ id: 'text.lockAircraft' })}</Label>
            <FlexDiv>
              <RadioInputButton text="Unlocked" active={!locked} onClick={handleUnlockAircraft} marginRight="8px" />
              <RadioInputButton text="Locked" active={locked} onClick={handleLockAircraft} />
            </FlexDiv>
          </FlexDiv>
          <Text maxWidth="75%">{formatMessage({ id: 'text.lockingAircraft' })}</Text>
          {!(locked || aircraft?.billing_status === 'Archived') ? (
            <>
              <Divider />
              <FlexDiv just="space-between">
                <Label edit>{formatMessage({ id: 'text.archive' })}</Label>
                <Button height="40px" primary={false} onClick={handleArchiveAircraft}>
                  {formatMessage({ id: 'text.archive' })}
                </Button>
              </FlexDiv>
              <Text maxWidth="75%">{formatMessage({ id: 'text.archivingAircraft' })}</Text>
            </>
          ) : null}
          {aircraft?.billing_status === 'Setup' && !locked ? (
            <>
              <Divider />
              <FlexDiv just="space-between">
                <Label edit>{formatMessage({ id: 'text.aircraftInSetupMode' })}</Label>
                <Button height="40px" primary={false} onClick={handleActivateAircraft}>
                  {formatMessage({ id: 'text.makeActive' })}
                </Button>
              </FlexDiv>
              <Text maxWidth="75%">{formatMessage({ id: 'text.makeAircraftActiveFromSetup' })}</Text>
            </>
          ) : null}
          {!locked ? (
            <>
              <Divider />
              <FlexDiv just="space-between">
                <Label edit>{formatMessage({ id: 'text.deleteAircraft' })}</Label>
                <Button height="40px" primary={false} onClick={handleDeleteAircraft}>
                  {formatMessage({ id: 'text.delete' })}
                </Button>
              </FlexDiv>
              <Text maxWidth="75%">{formatMessage({ id: 'text.deletingAircraft' })}</Text>
            </>
          ) : null}
        </Wrapper>
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftStatusSettings;
