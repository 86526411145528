import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import errorIcon from '../../assets/login-error.svg';
import fuelIcon from '../../assets/icon-card-fuel.svg';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import TFSelectDropdown from '../TFSelectDropdown/TFSelectDropdown';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  StyledInput,
  Text,
} from './AircraftAdvancedSettings';
import AircraftSettingsCard from './AircraftSettingsCard';
import ShowHideToggle from './ShowHideToggle';
import { RadioWrapper } from './AircraftTripStandardFields';

interface AircraftFluidTrackingProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.08px;
  color: #181818;
  margin: 0 16px;
  padding-top: 16px;
  border-top: solid 1px rgba(36, 45, 65, 0.05);
  margin-bottom: 16px;
`;

const ErrorWrapper = styled.div`
  font-size: ${({ error }): string => (error ? '16px' : '12px')};
  color: ${({ error }): string => (error ? '#ac1717' : 'rgba(36, 45, 65, 0.7)')};
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const fuelTypeOptions = ['Standard', 'Jet A-1', 'Jet-A', 'SAF (100%)', 'SAF (%)', 'SAF'];
const fuelPercentageOptions = ['SAF (%)'];

const AircraftFluidTracking: React.FC<AircraftFluidTrackingProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedFuelType, setSelectedFuelType] = useState([]);
  const [selectedPercentageType, setSelectedPercentageType] = useState([]);
  const [fuelBurnError, setFuelBurnError] = useState(false);
  const [fuelAdjustmentError, setFuelAdjustmentError] = useState(false);
  const [adjustedFuelPercentageOptions, setAdjustedFuelPercentageOptions] = useState([]);

  const { formatMessage } = useIntl();

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const updateStandardFields = (array: string[] | { [key: string]: string }, type: string): Promise<void> => {
    return new Promise((resolve) => {
      const newStandardFields = aircraft?.standard_fields || {};
      newStandardFields.fuel_uplift = {
        allowed_fuel_types: type === 'fuel' ? array : selectedFuelType,
        mixed_fuels: type === 'percentage' ? array : selectedPercentageType,
      };
      updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);

      resolve();
    });
  };

  const handleSelectFuelChange = (option): void => {
    const optionSelectedIndex = selectedFuelType.findIndex((obj) => obj === option);
    const newSelected = [...selectedFuelType];
    if (optionSelectedIndex !== -1) {
      newSelected.splice(optionSelectedIndex, 1);
    } else {
      newSelected.push(option);
    }
    setSelectedFuelType(newSelected);
    updateStandardFields(newSelected, 'fuel');
  };

  const handleFuelRemoveClick = (option): void => {
    const optionSelectedIndex = selectedFuelType.findIndex((obj) => obj === option);
    const newSelected = [...selectedFuelType];
    newSelected.splice(optionSelectedIndex, 1);
    setSelectedFuelType(newSelected);
    updateStandardFields(newSelected, 'fuel');
  };

  const handleSelectPercentageChange = (option): void => {
    const optionSelectedIndex = selectedPercentageType.findIndex((obj) => obj === option);
    const newSelected = [...selectedPercentageType];
    if (optionSelectedIndex !== -1) {
      newSelected.splice(optionSelectedIndex, 1);
    } else {
      newSelected.push(option);
    }
    setSelectedPercentageType(newSelected);
    const newSelectedObject = newSelected.reduce((obj, item) => {
      const newObj = obj;
      const stringOnly = item.replace(' (%)', '');
      newObj[`${item}`] = stringOnly;
      return newObj;
    }, {});
    updateStandardFields(newSelectedObject, 'percentage').then(() => {
      if (!selectedFuelType.includes(option) && newSelected.includes(option)) handleSelectFuelChange(option);
      if (selectedFuelType.includes(option) && !newSelected.includes(option)) handleSelectFuelChange(option);
    });
  };

  const handlePercentageRemoveClick = (option): void => {
    const optionSelectedIndex = selectedPercentageType.findIndex((obj) => obj === option);
    const newSelected = [...selectedPercentageType];
    newSelected.splice(optionSelectedIndex, 1);
    setSelectedPercentageType(newSelected);
    updateStandardFields(newSelected, 'percentage').then(() => {
      if (selectedFuelType.includes(option)) handleFuelRemoveClick(option);
    });
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    if (key === 'oil_uplifts' || key === 'hydraulic_uplifts') {
      newStandardFields[key] = { enabled: value, locations: [] };
    } else {
      newStandardFields[`${key}`] = { enabled: value };
    }
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleToggleRequiredChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { required: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleNameChange = (value: string, key: string, secondKey: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], [`${secondKey}`]: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleOilUpliftLocationClick = (value: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    let location = newStandardFields?.oil_uplifts?.locations;
    if (!location) location = [];
    if (location.indexOf(value) !== -1) {
      location.splice(location.indexOf(value), 1);
    } else {
      location.push(value);
    }
    if (!newStandardFields.oil_uplifts) newStandardFields.oil_uplifts = {};
    newStandardFields.oil_uplifts.locations = location;
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleInputChange = (value: string, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    setTimeout(() => {
      const newValue = parseInt(value, 10);
      if (newValue > 100 || newValue < 0) {
        if (key === 'fuel_adjustment') {
          setFuelAdjustmentError(true);
        } else {
          setFuelBurnError(true);
        }
      } else {
        setFuelAdjustmentError(false);
        newStandardFields[`${key}`] = { error_threshold: newValue / 100 };
        updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
      }
    }, 300);
  };
  const handleHydraulicUpliftLocationClick = (value: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    let location = newStandardFields?.hydraulic_uplifts?.locations;
    if (!location) location = [];
    if (location.indexOf(value) !== -1) {
      location.splice(location.indexOf(value), 1);
    } else {
      location.push(value);
    }
    if (!newStandardFields.hydraulic_uplifts) newStandardFields.hydraulic_uplifts = {};
    newStandardFields.hydraulic_uplifts.locations = location;
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const displayUpliftLocations = (array): string => {
    if (!array) return 'Preflight';
    if (array.length === 0) return '-';
    return array.join(', ');
  };

  const displayArrayValues = (values: string[]): string => {
    if (!values || values.length === 0) return null;
    return values.join(', ');
  };

  useEffect(() => {
    if (aircraft?.standard_fields?.fuel_uplift) {
      setSelectedFuelType(aircraft?.standard_fields?.fuel_uplift?.allowed_fuel_types || []);
      setAdjustedFuelPercentageOptions(fuelPercentageOptions);
      if (
        aircraft?.standard_fields?.fuel_uplift?.mixed_fuels &&
        !Array.isArray(aircraft?.standard_fields?.fuel_uplift?.mixed_fuels)
      ) {
        const keys = Object.keys(aircraft?.standard_fields?.fuel_uplift?.mixed_fuels);
        setSelectedPercentageType(keys);
        keys.forEach((key) => {
          if (fuelPercentageOptions.indexOf(key) === -1) {
            fuelPercentageOptions.push(key);
          }
        });
        setAdjustedFuelPercentageOptions(fuelPercentageOptions);
      }
    }
  }, [aircraft]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={fuelIcon}
            title="Fluid tracking configuration"
            buttonText="Edit fluid configuration"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <Title>Fuel</Title>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Fuel tracking</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.hide_all_fuel?.enabled ? 'Hide' : 'Show'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.hide_all_fuel?.enabled !== true}
                handleChange={(value: boolean): void => handleToggleChange(!value, 'hide_all_fuel')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Fuel burn</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.show_fuel_burn?.enabled ? 'Show' : 'Hide'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.show_fuel_burn?.enabled}
                  handleChange={(value: boolean): void => handleToggleChange(value, 'show_fuel_burn')}
                />
              )}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Fuel uplifts and adjustments</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.hide_fuel_uplifts_and_adjustments?.enabled ? 'Hide' : 'Show'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.hide_fuel_uplifts_and_adjustments?.enabled !== true}
                  handleChange={(value: boolean): void =>
                    handleToggleChange(!value, 'hide_fuel_uplifts_and_adjustments')
                  }
                />
              )}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>{formatMessage({ id: 'text.fuelUpliftAttachmentsRequired' })}</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.fuel_uplifts_attachments?.required ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.fuel_uplifts_attachments?.required}
                  handleChange={(value: boolean): void => handleToggleRequiredChange(value, 'fuel_uplifts_attachments')}
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_fuel_uplifts_and_adjustments?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Fuel uplift price</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.fuel_uplift_price?.enabled ? 'Show' : 'Hide'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.fuel_uplift_price?.enabled}
                  handleChange={(value: boolean): void => handleToggleChange(value, 'fuel_uplift_price')}
                />
              )}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Fuel types</Label>
              {!editMode ? (
                <Text>{displayArrayValues(aircraft?.standard_fields?.fuel_uplift?.allowed_fuel_types) || '-'}</Text>
              ) : (
                <TFSelectDropdown
                  selected={selectedFuelType}
                  options={fuelTypeOptions}
                  handleSelectChange={handleSelectFuelChange}
                  handleRemoveClick={handleFuelRemoveClick}
                />
              )}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Show % Select On</Label>
              {!editMode ? (
                <Text>
                  {(aircraft?.standard_fields?.fuel_uplift?.mixed_fuels &&
                    displayArrayValues(Object.values(aircraft?.standard_fields?.fuel_uplift?.mixed_fuels))) ||
                    '-'}
                </Text>
              ) : (
                <TFSelectDropdown
                  selected={selectedPercentageType}
                  options={adjustedFuelPercentageOptions}
                  handleSelectChange={handleSelectPercentageChange}
                  handleRemoveClick={handlePercentageRemoveClick}
                />
              )}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Fuel burn percent (%)</Label>
              {!editMode ? (
                <Text>
                  {aircraft?.standard_fields?.fuel_burn_percentage?.error_threshold
                    ? Math.round(aircraft?.standard_fields?.fuel_burn_percentage?.error_threshold * 100)
                    : '-'}
                </Text>
              ) : (
                <StyledInput
                  value={
                    aircraft?.standard_fields?.fuel_burn_percentage?.error_threshold
                      ? Math.round(aircraft?.standard_fields?.fuel_burn_percentage?.error_threshold * 100)
                      : null
                  }
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'fuel_burn_percentage')}
                />
              )}
              {editMode ? (
                <ErrorWrapper error={fuelBurnError}>
                  {fuelBurnError ? <img src={errorIcon} alt="error" /> : null}
                  Value must be between 0 and 100
                </ErrorWrapper>
              ) : null}
            </ItemWrapper>
          )}
          {aircraft?.standard_fields?.hide_all_fuel?.enabled ? null : (
            <ItemWrapper>
              <Label edit={editMode}>Fuel adjustment percent (%)</Label>
              {!editMode ? (
                <Text>
                  {aircraft?.standard_fields?.fuel_adjustment?.error_threshold
                    ? Math.round(aircraft?.standard_fields?.fuel_adjustment?.error_threshold * 100)
                    : '-'}
                </Text>
              ) : (
                <StyledInput
                  value={
                    aircraft?.standard_fields?.fuel_adjustment?.error_threshold
                      ? Math.round(aircraft?.standard_fields?.fuel_adjustment?.error_threshold * 100)
                      : null
                  }
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'fuel_adjustment')}
                />
              )}
              {editMode ? (
                <ErrorWrapper error={fuelAdjustmentError}>
                  {fuelAdjustmentError ? <img src={errorIcon} alt="error" /> : null}
                  Value must be between 0 and 100
                </ErrorWrapper>
              ) : null}
            </ItemWrapper>
          )}
        </StyledGrid>
        <Title>Oil uplifts</Title>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Oil uplifts</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.oil_uplifts?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.oil_uplifts?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'oil_uplifts')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.oil_uplifts?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Required</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.oil_uplifts?.required ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.oil_uplifts?.required}
                  handleChange={(value: boolean): void => handleToggleRequiredChange(value, 'oil_uplifts')}
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.oil_uplifts?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.oil_uplifts?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.oil_uplifts?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'oil_uplifts', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.oil_uplifts?.enabled !== false ? (
            <ItemWrapper span={editMode ? '2' : ''}>
              <Label edit={editMode}>Locations</Label>
              {!editMode ? (
                <Text cap>{displayUpliftLocations(aircraft?.standard_fields?.oil_uplifts?.locations)}</Text>
              ) : (
                <RadioWrapper>
                  <RadioInputButton
                    text="Preflight"
                    active={
                      !aircraft?.standard_fields?.oil_uplifts?.locations ||
                      aircraft?.standard_fields?.oil_uplifts?.locations?.includes('preflight')
                    }
                    marginRight="8px"
                    onClick={(): void => handleOilUpliftLocationClick('preflight')}
                    checkbox
                  />
                  <RadioInputButton
                    text="Postflight"
                    active={aircraft?.standard_fields?.oil_uplifts?.locations?.includes('postflight')}
                    marginRight="8px"
                    onClick={(): void => handleOilUpliftLocationClick('postflight')}
                    checkbox
                  />
                </RadioWrapper>
              )}
            </ItemWrapper>
          ) : null}
          <ItemWrapper>
            <Label edit={editMode}>Oil initial/departure fields on flights</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.oil_flight?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.oil_flight?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'oil_flight')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>Oil Initial/departure fields on MX checks</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.oil_mx_check?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.oil_mx_check?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'oil_mx_check')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>Oil initial/departure fields on add uplift</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.oil_uplift?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.oil_uplift?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'oil_uplift')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>APU Oil Uplifts</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.apu_oil?.enabled === false ? 'Hide' : 'Show'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.apu_oil?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'apu_oil')}
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        <Title>Hydraulic uplifts</Title>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Hydraulic uplifts</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.hydraulic_uplifts?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.hydraulic_uplifts?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'hydraulic_uplifts')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.hydraulic_uplifts?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Required</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.hydraulic_uplifts?.required ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.hydraulic_uplifts?.required}
                  handleChange={(value: boolean): void => handleToggleRequiredChange(value, 'hydraulic_uplifts')}
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.hydraulic_uplifts?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.hydraulic_uplifts?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.hydraulic_uplifts?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, 'hydraulic_uplifts', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.hydraulic_uplifts?.enabled !== false ? (
            <ItemWrapper span={editMode ? '2' : ''}>
              <Label edit={editMode}>Locations</Label>
              {!editMode ? (
                <Text cap>{displayUpliftLocations(aircraft?.standard_fields?.hydraulic_uplifts?.locations)}</Text>
              ) : (
                <RadioWrapper>
                  <RadioInputButton
                    text="Preflight"
                    active={
                      !aircraft?.standard_fields?.hydraulic_uplifts?.locations ||
                      aircraft?.standard_fields?.hydraulic_uplifts?.locations?.includes('preflight')
                    }
                    marginRight="8px"
                    onClick={(): void => handleHydraulicUpliftLocationClick('preflight')}
                    checkbox
                  />
                  <RadioInputButton
                    text="Postflight"
                    active={aircraft?.standard_fields?.hydraulic_uplifts?.locations?.includes('postflight')}
                    marginRight="8px"
                    onClick={(): void => handleHydraulicUpliftLocationClick('postflight')}
                    checkbox
                  />
                </RadioWrapper>
              )}
            </ItemWrapper>
          ) : null}
          <ItemWrapper>
            <Label edit={editMode}>Hydraulic initial/departure fields on flights</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.hydraulic_flight?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.hydraulic_flight?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'hydraulic_flight')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>Hydraulic Initial/departure fields on MX checks</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.hydraulic_mx_check?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.hydraulic_mx_check?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'hydraulic_mx_check')}
              />
            )}
          </ItemWrapper>
          <ItemWrapper>
            <Label edit={editMode}>Hydraulic initial/departure fields on add uplift</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.hydraulic_uplift?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.hydraulic_uplift?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'hydraulic_uplift')}
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftFluidTracking;
