import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import defaultIcon from './assets/info.svg';
import styles from './index.module.less';

const PageMessage = ({ type, typeIcon, text, actionText, actionDescription, actionFunction }) => {
  const callAction = () => {
    if (actionFunction) actionFunction();
  };

  return (
    <div className={`${styles.pageMessageParent} ${type ? styles[type] : undefined}`} data-test="pageMessage">
      <div className={styles.messageIcon}>
        <img src={typeIcon || defaultIcon} alt="icon" data-test="messageIcon" />
      </div>
      <div className={styles.messageContentParent}>
        <div className={styles.messageContent}>
          {text && (
            <p className={styles.messageContent} data-test="messageText">
              {text}
            </p>
          )}
          {actionDescription && (
            <p className={styles.messageAction} data-test="messageAction">
              {actionDescription}
            </p>
          )}
        </div>
        {actionText && (
          <Button type="link" onClick={callAction} className={styles.actionButton} data-test="messageButton">
            {actionText} <Icon type="right" />
          </Button>
        )}
      </div>
    </div>
  );
};

PageMessage.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'success', 'info']), // can be "warning", "error" or "success"
  typeIcon: PropTypes.string,
  text: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  actionDescription: PropTypes.node,
  actionFunction: PropTypes.func,
};

PageMessage.defaultProps = {
  type: null,
  typeIcon: '',
  actionText: '',
  actionDescription: '',
  actionFunction: () => {
    // Does nothing;
  },
};

export default PageMessage;
