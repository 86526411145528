import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import styles from './GraphRadioButton.module.less';

const GraphRadioButton = ({ buttons, initialSelection, handleRadioSelection }) => {
  if (!buttons) return null;
  const buttonArray = buttons.map((item) => {
    return (
      <Radio.Button
        value={item}
        key={item}
        data-testid="GraphRadioButtons--RadioButton"
        onClick={(e) => handleRadioSelection(e.target.value)}
      >
        {item}
      </Radio.Button>
    );
  });

  return (
    <Radio.Group className={styles.radioGroup} defaultValue={initialSelection}>
      {buttonArray}
    </Radio.Group>
  );
};

GraphRadioButton.propTypes = {
  buttons: PropTypes.array,
  initialSelection: PropTypes.string.isRequired,
  handleRadioSelection: PropTypes.func.isRequired,
};

GraphRadioButton.defaultProps = {
  buttons: [],
};

export default GraphRadioButton;
