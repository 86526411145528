/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addAircraftTotals,
  deleteTripEntry,
  deleteTripUpdate,
  getTrip,
  queryTrips,
  updateTripEntry,
  updateTripDetails,
  updateTripNumber,
  updateTripUpdate,
  addTripAttachment,
  addTrip,
} from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { intl } from '../../providers/IntlProvider';
import { TripsActionTypes } from '.';

export const getAircraftTripEntries =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryTrips(payload); // trip_entries
    dispatch({
      type: TripsActionTypes.SAVE_ALL,
      payload: response,
    });
  };

export const getTripDetails =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getTrip(payload); // trip_entries
    dispatch({
      type: TripsActionTypes.UPDATE,
      payload: response,
    });
  };

export const saveTripEntry =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await addTrip(payload);
    dispatch({
      type: TripsActionTypes.SAVE,
      payload: response,
    });
    return response;
  };

export const storeEphemeralTrip =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({ type: TripsActionTypes.SAVE_EPHEMERAL_ENTRY, payload });
  };

export const setTripsTableSource =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({ type: TripsActionTypes.SAVE_SOURCE, payload });
  };

export const addUpdate =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    // @TODO determine how these influence the tripsMap and implement reducers for them.
    const response = await addAircraftTotals(payload);
    dispatch({
      type: TripsActionTypes.SAVE,
      payload: response.body,
    });
    return response.body;
  };

export const updateEntry =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    // @TODO determine how these influence the tripsMap and implement reducers for them.
    const response = await updateTripEntry(payload);
    dispatch({
      type: TripsActionTypes.UPDATE,
      payload: response.body,
    });
  };

export const amendTripUpdate =
  (payload) =>
  async (dispatch): Promise<any> => {
    const response = await updateTripUpdate(payload);
    dispatch({
      type: TripsActionTypes.SAVE,
      payload: response.body,
    });
  };

export const updateTripFromDrawer =
  (payload) =>
  async (dispatch): Promise<any> => {
    const response = await updateTripDetails(payload);
    dispatch({
      type: TripsActionTypes.UPDATE,
      payload: response.body,
    });
    return response.body;
  };

export const updateTrip =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    // @TODO determine how these influence the tripsMap and implement reducers for them.
    let response;
    if (payload.srp_number) {
      response = await updateTripNumber(payload);
    } else {
      response = await updateTripDetails(payload);
    }
    dispatch({
      type: TripsActionTypes.UPDATE,
      payload: response.body,
    });
  };

export const removeNewTrip =
  () =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: TripsActionTypes.UPDATE,
    });
  };

export const updateTripAttachments =
  ({ payload }) =>
  async (dispatch): Promise<void> => {
    const res = await addTripAttachment(payload);
    dispatch({
      type: TripsActionTypes.UPDATE,
      payload: res,
    });
  };

export const deleteEntry =
  ({ payload }, callBack = null) =>
  async (dispatch): Promise<any> => {
    // @TODO determine how these influence the tripsMap and implement reducers for them.
    const response = await deleteTripEntry(payload);

    if (response && response.statusCode > 199 && response.statusCode < 400) {
      if (callBack) {
        callBack();
      }
      dispatch({
        type: TripsActionTypes.DELETE_TRIP,
        payload,
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.tripRemoved' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.tripRemovalFailed' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };

export const deleteUpdate =
  ({ payload }, callBack = null) =>
  async (dispatch): Promise<any> => {
    // @TODO determine how these influence the tripsMap and implement reducers for them.
    const response = await deleteTripUpdate(payload);
    if (response && response.statusCode > 199 && response.statusCode < 400) {
      if (callBack) {
        callBack();
      }
      dispatch({
        type: TripsActionTypes.DELETE_TRIP,
        payload,
      });

      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.tripUpdateRemoved' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.tripUpdateRemovalFailed' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };
