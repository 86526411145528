/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { FRATQuestionCategory } from '../../models/frats';
import DroppableComponent from './DroppableComponent';

interface TFDragAndDropProps {
  items: any[];
  component: string;
  setItemOrder: (items: any) => void;
}

const TFDragAndDrop: React.FC<TFDragAndDropProps> = ({ items, component, setItemOrder }) => {
  const [localItems, setLocalItems] = useState<FRATQuestionCategory[]>(items);

  const reorder = (list, startIndex, endIndex): any[] => {
    const result = _.cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.sequence_number = index;
    });
    return result;
  };

  const onDragEnd = (result): void => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === 'droppableItem') {
      const newReorderedItems = reorder(localItems, sourceIndex, destIndex);

      setLocalItems(newReorderedItems);
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = localItems.reduce((acc, item) => {
        acc[item.id] = item.flight_risk_assessment_questions;
        return acc;
      }, {});

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newLocalItems = _.cloneDeep(localItems);

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);
        newLocalItems = newLocalItems.map((item) => {
          const newItem = item;
          if (item.id === sourceParentId) {
            newItem.flight_risk_assessment_questions = reorderedSubItems;
          }
          return newItem;
        });
        setLocalItems(newLocalItems);
      } else {
        const newSourceSubItems = _.cloneDeep(sourceSubItems);
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        const destinationCategory = localItems.find((item) => item.id === destParentId).category;
        const destinationId = localItems.find((item) => item.id === destParentId).id;
        draggedItem.category = destinationCategory;
        draggedItem.flight_risk_assessment_question_category_id = destinationId;
        const newDestSubItems = _.cloneDeep(destSubItems);
        newDestSubItems.splice(destIndex, 0, draggedItem);

        newLocalItems = newLocalItems.map((item) => {
          const newItem = item;
          if (item.id === sourceParentId) {
            newItem.flight_risk_assessment_questions = newSourceSubItems;
          } else if (item.id === destParentId) {
            newItem.flight_risk_assessment_questions = newDestSubItems;
          }
          return newItem;
        });
        setLocalItems(newLocalItems);
      }
    }
  };

  useEffect(() => {
    if (localItems) {
      setItemOrder(localItems);
    }
  }, [localItems, setItemOrder]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DroppableComponent items={localItems} component={component} />
    </DragDropContext>
  );
};

export default TFDragAndDrop;
