import moment from 'moment';

export default function estDueTimeOfAircraft(inputDate: string): string | number {
  const estimatedDueInHours = moment().diff(moment(inputDate), 'hours');
  let estimatedDueInDays: number | string = moment(inputDate).diff(moment(), 'days') + 1;
  if (estimatedDueInHours > 0 && estimatedDueInHours < 24) {
    estimatedDueInDays = '<1';
  }
  if (estimatedDueInDays < 0) estimatedDueInDays = 0;
  return estimatedDueInDays;
}
