import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { Label, Text } from '../../components/AircraftSettings/AircraftAdvancedSettings';
import TFSelect from '../../components/TFSelect/TFSelect';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import TFDropdown from '../../components/TFDropdown/TFDropdown';
import FileViewer from '../../components/TFFileViewer/fileViewer';

interface CabinIssueDetailsProps {
  cabinIssue: any;
  updateCabinIssue: (changes: { value: any; key: string }[]) => void;
}

const StyledCard = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: #fcfcfc;
  padding: ${({ padding }): string => (padding ? `${padding}px` : '20px')};
  margin-bottom: 24px;
  color: #242d41;
`;

const Header = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  column-gap: 24px;
  padding: 0 16px 16px 16px;
  margin-bottom: 20px;
`;

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
  background-color: #f3f7fc;
  width: 290px;
`;

const CabinIssueDetails: React.FC<CabinIssueDetailsProps> = ({ cabinIssue, updateCabinIssue }) => {
  const {
    drawer: { mode, drawerId },
    aircraft: { aircraftMap },
    cabinIssues: { selectedIssueLocation, cabinIssueSVG },
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [issueTypes, setIssueTypes] = useState([]);
  const [currentIssueType, setCurrentIssueType] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [seatNameOptions, setSeatNameOptions] = useState([]);
  const [currentSeat, setCurrentSeat] = useState(null);

  const customSort = (a, b) => {
    const passedATitle = a?.title || a?.name;
    const passedBTitle = b?.title || b?.name;
    const numericA = parseInt(passedATitle, 10);
    const numericB = parseInt(passedBTitle, 10);

    if (numericA < numericB) {
      return -1;
    }
    if (numericA > numericB) {
      return 1;
    }
    const alphaA = passedATitle?.replace(/[0-9]/g, '');
    const alphaB = passedBTitle?.replace(/[0-9]/g, '');
    return alphaA.localeCompare(alphaB);
  };

  const getExistingLocationIssues = () => {
    let existingLocationIssues =
      cabinIssue?.aircraft_location_issues_attributes || cabinIssue?.aircraft_location_issues || [];
    if (existingLocationIssues.length === 0) {
      existingLocationIssues = [{}];
    }
    return existingLocationIssues;
  };

  const handleSeatChange = (option: { title: string; value: string }): void => {
    const existingLocationIssues = getExistingLocationIssues();
    existingLocationIssues[0].aircraft_location_id = option?.value;
    updateCabinIssue([{ value: existingLocationIssues, key: 'aircraft_location_issues_attributes' }]);
  };

  const handleIssueTypeChange = (issue): void => {
    const foundIssue = aircraftMap
      .get(id)
      .issue_types.filter((iType) => iType.type === 'cabin_item')
      .find((issueType) => issueType.id === issue?.value);
    const existingLocationIssues = getExistingLocationIssues();
    existingLocationIssues[0].issue_type_id = foundIssue?.id;
    updateCabinIssue([{ value: existingLocationIssues, key: 'aircraft_location_issues_attributes' }]);
  };

  const handleLocationChange = (option): void => {
    setCurrentLocation(option);
    const selectedLocation = cabinIssueSVG.aircraft_location_groups.find((location) => location.id === option?.value);
    const newFirstLocation = selectedLocation?.aircraft_locations?.sort(customSort)?.[0];
    const existingLocationIssues = getExistingLocationIssues();
    existingLocationIssues[0].aircraft_location_id = newFirstLocation?.id;
    updateCabinIssue([{ value: existingLocationIssues, key: 'aircraft_location_issues_attributes' }]);
  };

  const handleDateChange = (newDate): void => {
    updateCabinIssue([{ value: newDate, key: 'raised_at' }]);
  };

  useEffect(() => {
    // useeffect to build status options
    if (aircraftMap.get(id)) {
      const foundIssueTypes = aircraftMap
        .get(id)
        .issue_types.sort((a, b) => a.name.localeCompare(b.name))
        .filter((issue) => issue.type === 'cabin_item');
      const selectTypes = foundIssueTypes.map((issue) => {
        let issueColour = 'rgba(18, 111, 214, 0.3)';
        if (issue?.status === 'red') issueColour = '#ac1717';
        if (issue?.status === 'amber') issueColour = '#f2a650';
        return {
          title: issue.name,
          value: issue.id,
          status: issue.status,
          colour: issueColour,
        };
      });
      selectTypes.unshift({ title: 'None', value: undefined, status: 'normal', colour: 'rgba(18, 111, 214, 0.3)' });
      setIssueTypes(selectTypes);
    }
  }, [aircraftMap, id]);

  useEffect(() => {
    // useeffect to build location options
    if (cabinIssueSVG) {
      const newLocationOptions = cabinIssueSVG.aircraft_location_groups.map((loc) => {
        return { title: loc.name, value: loc.id };
      });
      setLocationOptions(newLocationOptions);
      if (!selectedIssueLocation) updateCabinIssue([{ value: newLocationOptions[0]?.title, key: 'location' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinIssueSVG]);

  useEffect(() => {
    if (currentLocation) {
      const selectedLocation = cabinIssueSVG.aircraft_location_groups.find(
        (location) => location.id === currentLocation?.value,
      );
      if (selectedLocation) {
        const nameOptions = selectedLocation?.aircraft_locations?.map((location) => {
          return { title: location.name, value: location.id };
        });
        const sortedNameOptions = nameOptions.sort(customSort);
        setSeatNameOptions(sortedNameOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation]);

  useEffect(() => {
    // useeffect to set current location, seat and issue type
    const locationGroups = cabinIssueSVG?.aircraft_location_groups;

    const findLocationGroup = (identifier, key) => {
      let foundLocation = null;
      locationGroups.forEach((group) => {
        group.aircraft_locations.forEach((location) => {
          if (location[`${key}`] === identifier) {
            foundLocation = group;
          }
        });
      });
      return foundLocation;
    };
    if (selectedIssueLocation && locationGroups) {
      const { state } = selectedIssueLocation;
      const foundIssueType = issueTypes?.find((issue) => issue.status === state);
      setCurrentIssueType(foundIssueType);

      const foundLocation = findLocationGroup(selectedIssueLocation?.id, 'element_identifier');
      const foundLocalLocation = locationOptions.find((loc) => loc?.value === foundLocation?.id);
      setCurrentLocation(foundLocalLocation);

      const foundSeat = locationGroups
        .flatMap((outer) => outer.aircraft_locations)
        ?.find((location) => location?.element_identifier === selectedIssueLocation?.id);
      const localFoundSeat = seatNameOptions?.find((seat) => seat?.value === foundSeat?.id);
      setCurrentSeat(localFoundSeat);
    } else if (cabinIssue?.aircraft_location_issues_attributes?.length) {
      const attributes = cabinIssue?.aircraft_location_issues_attributes[0];

      const foundIssueType = issueTypes.find((issue) => issue?.value === attributes?.issue_type_id);
      setCurrentIssueType(foundIssueType);

      const foundLocation = findLocationGroup(attributes?.aircraft_location_id, 'id');
      const foundLocalLocation = locationOptions.find((loc) => loc?.value === foundLocation?.id);
      setCurrentLocation(foundLocalLocation);

      const foundSeat = seatNameOptions?.find((seat) => seat?.value === attributes?.aircraft_location_id);
      setCurrentSeat(foundSeat);
    } else if (
      cabinIssue?.aircraft_location_issues_attributes &&
      typeof cabinIssue?.aircraft_location_issues_attributes === 'object'
    ) {
      const attributes = cabinIssue?.aircraft_location_issues_attributes;

      const foundIssueType = issueTypes.find((issue) => issue?.value === attributes?.issue_type_id);
      setCurrentIssueType(foundIssueType);

      const foundLocation = findLocationGroup(attributes?.aircraft_location_id, 'id');
      const foundLocalLocation = locationOptions.find((loc) => loc?.value === foundLocation?.id);
      setCurrentLocation(foundLocalLocation);

      const foundSeat = seatNameOptions?.find((seat) => seat?.value === attributes?.aircraft_location_id);
      setCurrentSeat(foundSeat);
    } else if (cabinIssue?.aircraft_location_issues?.length) {
      const attributes = cabinIssue?.aircraft_location_issues[0];

      const foundIssueType = issueTypes.find((issue) => issue?.value === attributes?.issue_type_id);
      setCurrentIssueType(foundIssueType);

      const foundSeat = seatNameOptions?.find((seat) => seat?.value === attributes?.aircraft_location_id);
      setCurrentSeat(foundSeat);

      const foundLocation = findLocationGroup(attributes?.aircraft_location_id, 'id');
      const foundLocalLocation = locationOptions.find((loc) => loc?.value === foundLocation?.id);
      setCurrentLocation(foundLocalLocation);
    } else if (cabinIssue?.aircraft_location_issues && typeof cabinIssue?.aircraft_location_issues === 'object') {
      const attributes = cabinIssue?.aircraft_location_issues;

      const foundIssueType = issueTypes.find((issue) => issue?.value === attributes?.issue_type_id);
      setCurrentIssueType(foundIssueType);

      const foundSeat = seatNameOptions?.find((seat) => seat?.value === attributes?.aircraft_location_id);
      setCurrentSeat(foundSeat);

      const foundLocation = locationOptions.find((loc) => loc?.value === attributes?.associated_record_id);
      const foundLocalLocation = locationOptions.find((loc) => loc?.value === foundLocation?.id);
      setCurrentLocation(foundLocalLocation);
    } else {
      setCurrentIssueType(issueTypes?.[0]);
      setCurrentLocation(locationOptions?.[0]);
      setCurrentSeat(seatNameOptions?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinIssue, cabinIssueSVG, selectedIssueLocation, locationOptions, seatNameOptions]);

  useEffect(() => {
    if (!cabinIssue?.raised_at) {
      const newDate = moment.utc().toISOString();
      updateCabinIssue([{ value: newDate, key: 'raised_at' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinIssue]);

  let displaySeatRow = true;
  if (selectedIssueLocation?.x) displaySeatRow = false;
  if (
    cabinIssue?.aircraft_location_issues_attributes &&
    Array.isArray(cabinIssue?.aircraft_location_issues_attributes) &&
    !cabinIssue?.aircraft_location_issues_attributes?.some((iss) => iss?.aircraft_location_id)
  )
    displaySeatRow = false;
  if (
    cabinIssue?.aircraft_location_issues &&
    Array.isArray(cabinIssue?.aircraft_location_issues) &&
    !cabinIssue?.aircraft_location_issues?.some((iss) => iss?.aircraft_location_id)
  )
    displaySeatRow = false;

  return (
    <StyledCard>
      <Header>{formatMessage({ id: 'text.cabinIssueDetails' })}</Header>
      {mode !== 'view' && !selectedIssueLocation && !drawerId ? (
        <StyledGrid>
          <div>
            <Label edit={mode !== 'view'}>{formatMessage({ id: 'text.location' })}</Label>
            {!displaySeatRow ? (
              <span>{formatMessage({ id: 'text.customLocation' })}</span>
            ) : (
              <TFDropdown
                options={locationOptions}
                onSelect={handleLocationChange}
                initialValue={currentLocation}
                id="location"
                label=""
              />
            )}
          </div>
        </StyledGrid>
      ) : null}
      {displaySeatRow ? (
        <StyledGrid>
          <div>
            <Label edit={mode !== 'view'}>{formatMessage({ id: 'text.seat' })}</Label>
            {mode === 'view' ? (
              <Text>{currentSeat?.title}</Text>
            ) : (
              <TFDropdown
                options={seatNameOptions}
                onSelect={handleSeatChange}
                initialValue={currentSeat}
                id="seat"
                label=""
              />
            )}
          </div>
        </StyledGrid>
      ) : null}
      <StyledGrid>
        <div>
          <Label edit={mode !== 'view'}>{formatMessage({ id: 'text.details' })}</Label>
          {mode === 'view' ? (
            <Text>{cabinIssue?.details}</Text>
          ) : (
            <StyledTextarea
              id="details"
              name="details"
              rows={5}
              onChange={(e): void => updateCabinIssue([{ value: e.target.value, key: 'details' }])}
              value={cabinIssue?.details}
            />
          )}
        </div>
        <div>
          <Label edit={mode !== 'view'}>{formatMessage({ id: 'text.issueStatus' })}</Label>
          {mode === 'view' ? (
            <Text>{currentIssueType?.title}</Text>
          ) : (
            <TFSelect
              options={issueTypes}
              handleSelectChange={handleIssueTypeChange}
              initial={currentIssueType}
              allowEmpty
            />
          )}
        </div>
      </StyledGrid>
      <StyledGrid>
        <div>
          {mode === 'view' ? (
            <>
              <Label>{formatMessage({ id: 'text.dateRecorded' })}</Label>
              <Text>{cabinIssue?.raised_at ? moment(cabinIssue?.raised_at).format(`${dateFormat} HH:mm`) : '-'}</Text>
            </>
          ) : (
            <DateTimePicker
              headerDate="Date Recorded"
              headerTime="Time Recorded"
              handleDateTimeChange={handleDateChange}
              dateTime={cabinIssue?.raised_at}
              noFuture
            />
          )}
        </div>
        <div>
          {mode === 'view' ? (
            <>
              <Label>{formatMessage({ id: 'text.attachments' })}</Label>
              <FileViewer attachments={cabinIssue?.attachments} uploaderVisible={false} />
            </>
          ) : null}
        </div>
      </StyledGrid>
    </StyledCard>
  );
};

export default CabinIssueDetails;
