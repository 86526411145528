/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import errorIcon from '../../assets/login-error.svg';
import { DisplayText, Header, StyledInput } from '../FlightDrawer/FlightDrawer';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import TFSelect from '../TFSelect/TFSelect';
import { CustomField } from '../../models/aircraft';
import checkPrecisionOfNumber from '../../utils/checkPrecisionOfNumber';

interface CustomFieldSectionProps {
  customData: any;
  saveKey: string;
  updateData: (changes: { value: any; key: string }[]) => void;
  customFieldKey: string;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ edit }): string => (edit ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr 1fr')};
  gap: 20px;
  margin-top: 20px;
`;

const RadioWrapper = styled.div`
  display: flex;
`;

const ItemWrapper = styled.div`
  grid-column: ${({ col }): string => (col ? `span ${col}` : 'auto')};
`;

const SubHeader = styled.div`
  color: ${({ editable }): string => (editable ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  letter-spacing: ${({ editable }): string => (editable ? 'normal' : '0.4px')};
  font-size: 10px;
  text-transform: ${({ editable }): string => (editable ? 'capitalize' : 'uppercase')};
  font-weight: ${({ editable }): string => (editable ? '500' : 'normal')};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 48px;
  img {
    margin-right: 8px;
  }
`;

const ErrorText = styled.span`
  line-height: 1.43;
  color: #ac1717;
`;

const CustomFieldSection: React.FC<CustomFieldSectionProps> = ({ customData, saveKey, updateData, customFieldKey }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [localFields, setLocalFields] = useState<{ [key: string]: CustomField }>(null);
  const [localData, setLocalData] = useState(null);
  const [numberPrecisionError, setNumberPrecisionError] = useState(null);
  const [minMaxError, setMinMaxError] = useState(null);
  const [booleanIds, setBooleanIds] = useState([]);

  const handleBooleanClick = useCallback(
    (value: boolean, key: string): void => {
      const newLocalData = { ...localData };
      newLocalData[`${key}`] = value;
      setLocalData(newLocalData);
    },
    [localData],
  );

  const handleSelectChange = useCallback(
    (option: { title: string; colour?: string; value?: string }, key: string): void => {
      const newLocalData = { ...localData };
      newLocalData[`${key}`] = option?.value;
      setLocalData(newLocalData);
    },
    [localData],
  );

  const getMinMaxErrorText = (data): string => {
    let newText = '';
    if (
      data?.min !== undefined &&
      typeof data.min === 'number' &&
      data?.max !== undefined &&
      typeof data.min === 'number'
    ) {
      newText = `greater than ${data.min} and less than ${data.max}`;
    } else if (data?.min !== undefined && typeof data.min === 'number') {
      newText = `greater than ${data.min}`;
    } else if (data?.max !== undefined && typeof data.max === 'number') {
      newText = `less than ${data.max}`;
    }
    return newText;
  };

  const handleInputChange = (value: string | number, data: any): void => {
    const newLocalData = { ...localData };
    if (!value) {
      newLocalData[`${data?.key}`] = null;
      setLocalData(newLocalData);
      return;
    }
    let newValue = value;
    if (data?.type === 'number') {
      newValue = Number(newValue);
      if (data?.precision && !checkPrecisionOfNumber(newValue, data?.precision)) {
        setNumberPrecisionError(data?.title);
      } else {
        setNumberPrecisionError(null);
      }
      if (data?.min && data?.max && !(newValue < data?.max && newValue > data?.min)) {
        setMinMaxError(data.title);
      } else if (data?.min !== undefined && typeof data.min === 'number' && !data?.max && newValue < data?.min) {
        setMinMaxError(data.title);
      } else if (data?.max !== undefined && typeof data.max === 'number' && !data?.min && newValue > data?.max) {
        setMinMaxError(data.title);
      } else {
        setMinMaxError(null);
      }
      newLocalData[`${data?.key}`] = newValue;
      setLocalData(newLocalData);
    }
    if (data?.type === 'string' || data?.type === 'text') {
      if (typeof newValue === 'string') {
        if (
          data?.min !== undefined &&
          typeof data.min === 'number' &&
          data?.max !== undefined &&
          typeof data.max === 'number' &&
          !(newValue.length <= data?.max && newValue.length >= data?.min)
        ) {
          setMinMaxError(data.title);
        } else if (
          data?.min !== undefined &&
          typeof data.min === 'number' &&
          !data?.max &&
          newValue.length <= data?.min
        ) {
          setMinMaxError(data.title);
        } else if (
          data?.max !== undefined &&
          typeof data.max === 'number' &&
          !data?.min &&
          newValue.length >= data?.max
        ) {
          setMinMaxError(data.title);
        } else {
          setMinMaxError(null);
        }
      }
      newLocalData[`${data?.key}`] = newValue;
      setLocalData(newLocalData);
    } else {
      newLocalData[`${data?.key}`] = newValue;
      setLocalData(newLocalData);
    }
  };
  // Possibly add back in when attachments are dealt with in custom fields
  // const handleAttachmentDeleteClick = (fileId: string, fileName: string, key: string): void => {
  //   const newLocalData = { ...localData };
  //   const filteredData = newLocalData?.attachments?.filter(
  //     (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
  //   );
  //   if (fileId) filteredData.push({ id: fileId, attachment_file_name: fileName });
  //   newLocalData[key] = filteredData;
  //   setLocalData(newLocalData);
  // };

  // const convertToImgFile = (file): Promise<any> => {
  //   return new Promise((res, rej) => {
  //     const reader = new FileReader();

  //     reader.onload = (e): void => {
  //       res({ attachment: e?.target?.result, attachment_file_name: file.name });
  //     };

  //     reader.onerror = (): void => {
  //       reader.abort();
  //       rej(console.error('Failed to convert image'));
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };

  // const addAttachment = async (files, key): Promise<void> => {
  //   if (files) {
  //     const attachmentPromises = [];
  //     const newLocalData = localData ? { ...localData } : {};
  //     attachmentPromises.push(convertToImgFile(files[0]));
  //     const base64Attachments = await Promise.all(attachmentPromises);
  //     setLocalData({ ...newLocalData, attachments: newLocalData[`${key}`]?.concat(...base64Attachments) });
  //   }
  // };

  useEffect(() => {
    if (id) {
      const allCustomFields = aircraftMap.get(id)?.custom_fields;
      const customFields = Object.entries(allCustomFields).reduce((acc, [key, value]: any) => {
        const newAcc = acc;
        if (value?.view === customFieldKey) {
          if (value.type === 'boolean') {
            if (!booleanIds.includes(key)) {
              booleanIds.push(key);
              setBooleanIds(booleanIds);
            }
          }
          newAcc[key] = value;
        }
        return newAcc;
      }, {});
      setLocalFields(customFields);
    }
  }, [aircraftMap, id]);

  useEffect(() => {
    if (customData) {
      setLocalData(customData);
    }
  }, [customData]);

  useEffect(() => {
    if (!_.isEqual(localData, customData)) {
      updateData([{ value: localData, key: saveKey }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localData]);

  useEffect(() => {
    if (booleanIds && customData) {
      const newLocalData = _.cloneDeep(customData);
      booleanIds.forEach((key) => {
        if (newLocalData[key] === null || newLocalData[key] === undefined) {
          newLocalData[key] = false;
        }
      });
      setLocalData(newLocalData);
    }
  }, [booleanIds, customData]);

  return (
    <StyledGrid edit={mode !== 'view'}>
      {localFields &&
        Object.entries(localFields)
          .sort((a, b) => a[1].order - b[1].order)
          .map((cField) => {
            const [key, data] = cField;
            if (data?.type === 'boolean') {
              return (
                <ItemWrapper id={key} col={mode !== 'view' ? '2' : ''}>
                  <Header editable={mode !== 'view'}>
                    {data?.heading ? data?.heading : data?.title}
                    {data?.heading ? <SubHeader>{` (${data?.title})`}</SubHeader> : null}
                  </Header>
                  {mode === 'view' ? (
                    <DisplayText>{localData && localData[`${key}`] ? 'Yes' : 'No'}</DisplayText>
                  ) : (
                    <RadioWrapper>
                      <RadioInputButton
                        text="Yes"
                        active={localData && localData[`${key}`]}
                        onClick={(): void => handleBooleanClick(true, data?.key)}
                        marginRight="8px"
                      />
                      <RadioInputButton
                        text="No"
                        active={(localData && !localData[`${key}`]) || !localData}
                        onClick={(): void => handleBooleanClick(false, data?.key)}
                      />
                    </RadioWrapper>
                  )}
                </ItemWrapper>
              );
            }
            if (data?.type === 'selector') {
              const options = Object.entries(data?.options).map(([optionKey, value]) => {
                return { title: value, value: optionKey };
              });
              options.unshift({ title: 'None', value: null });
              return (
                <ItemWrapper id={key} col={mode !== 'view' ? '2' : ''}>
                  <Header editable={mode !== 'view'}>
                    {data?.heading ? data?.heading : data?.title}
                    {data?.heading ? <SubHeader>{` (${data?.title})`}</SubHeader> : null}
                  </Header>
                  {mode === 'view' ? (
                    <DisplayText>
                      {(localData && options?.find((item) => item.value === localData[`${key}`])?.title) || '-'}
                    </DisplayText>
                  ) : (
                    <TFSelect
                      options={options || []}
                      initial={
                        localData && localData[`${key}`]
                          ? options?.find((item) => item.value === localData[`${key}`])
                          : options[0]
                      }
                      width={180}
                      handleSelectChange={(option): void => handleSelectChange(option, data?.key)}
                      allowEmpty
                    />
                  )}
                </ItemWrapper>
              );
            }
            if (data?.type === 'number' || data?.type === 'string' || data?.type === 'text') {
              return (
                <div id={key} data-testid="CustomFieldSection--StringInputWrapper">
                  <Header editable={mode !== 'view'}>
                    {data?.heading ? data?.heading : data?.title}
                    {data?.heading ? <SubHeader>{` (${data?.title})`}</SubHeader> : null}
                  </Header>
                  {mode === 'view' ? (
                    <DisplayText>{(localData && localData[`${key}`]) || '-'}</DisplayText>
                  ) : (
                    <>
                      <StyledInput
                        placeholder="-"
                        type={data?.type}
                        value={localData && localData[`${key}`]}
                        onChange={(e): void => handleInputChange(e.target.value, data)}
                      />
                      {numberPrecisionError &&
                      minMaxError &&
                      numberPrecisionError === data?.title &&
                      minMaxError === data?.title ? (
                        <ErrorWrapper>
                          <img src={errorIcon} alt="error icon" />
                          <ErrorText>
                            {`Number must be to ${data.precision} decimal ${
                              data.precision === 1 ? 'place' : 'places'
                            } and ${getMinMaxErrorText(data)}`}
                          </ErrorText>
                        </ErrorWrapper>
                      ) : null}
                      {numberPrecisionError && !minMaxError && numberPrecisionError === data?.title ? (
                        <ErrorWrapper>
                          <img src={errorIcon} alt="error icon" />
                          <ErrorText>
                            {`Number must be to ${data.precision} decimal ${data.precision === 1 ? 'place' : 'places'}`}
                          </ErrorText>
                        </ErrorWrapper>
                      ) : null}
                      {minMaxError && !numberPrecisionError && minMaxError === data?.title ? (
                        <ErrorWrapper>
                          <img src={errorIcon} alt="error icon" />
                          <ErrorText>
                            {data?.type === 'number'
                              ? `Number must be ${getMinMaxErrorText(data)}`
                              : `Input must be ${getMinMaxErrorText(data)} characters in length`}
                          </ErrorText>
                        </ErrorWrapper>
                      ) : null}
                    </>
                  )}
                </div>
              );
            }
            // hidden until can work out how to have multiple file viewers on the same page
            // if (data?.type === 'attachment') {
            //   return (
            //     <div>
            //       <Header editable={mode !== 'view'}>
            //         {data?.heading ? data?.heading : data?.title}
            //         {data?.heading ? <SubHeader>{` (${data?.title})`}</SubHeader> : null}
            //       </Header>
            //       <FileViewer
            //         attachments={localData && localData[`${key}`]}
            //         handleDeleteClick={({ fileId, fileName }): void =>
            //           handleAttachmentDeleteClick(fileId, fileName, key)
            //         }
            //         addAttachment={(files): Promise<void> => addAttachment(files, key)}
            //         uploaderVisible={mode !== 'view'}
            //         allowDelete={mode !== 'view'}
            //       />
            //     </div>
            //   );
            // }
            return null;
          })}
    </StyledGrid>
  );
};

export default CustomFieldSection;
