import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import TablePopUp from './TablePopUp';

interface HistoryTableProps {
  data: any;
  noJustification?: boolean;
}

const TableWrapper = styled.div`
  padding: 0 1px;
  div,
  img,
  svg,
  button,
  input {
    box-sizing: revert !important;
    line-height: normal;
  }
`;

const HistoryTable: React.FC<HistoryTableProps> = ({ data, noJustification }) => {
  const { formatMessage } = useIntl();

  const [popupVisible, setPopVisible] = useState(false);
  const [rowData, setRowData] = useState(null);

  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const handleRowClick = (row): void => {
    if (row) {
      const originalRowData = row?.original;
      if (originalRowData) setRowData(originalRowData);
    }
    setPopVisible(!popupVisible);
  };

  const handleClose = (): void => {
    setPopVisible(false);
  };

  const columns = useMemo(() => {
    let newColumns = [
      {
        Header: formatMessage({ id: 'title.event' }),
        accessor: 'event',
        Cell: ({ value }): string => {
          const formattedValue = typeof value === 'string' ? value?.replace(/_/g, ' ') : '';
          return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1) || '-';
        },
      },
      {
        Header: formatMessage({ id: 'title.justification' }),
        accessor: 'justification',
        width: 300,
        Cell: ({ value }): string => value || '-',
      },
      {
        Header: formatMessage({ id: 'title.timestamp' }),
        accessor: 'timestamp',
        Cell: ({ value }): string => moment.utc(value).format(`${dateFormat} HH:mmz`) || '-',
      },
      {
        Header: formatMessage({ id: 'title.user' }),
        accessor: 'changed_by_name',
        Cell: ({ value }): string => value || '-',
      },
    ];

    if (noJustification) {
      newColumns = newColumns.filter((column) => column.accessor !== 'justification');
    }

    return newColumns;
  }, [dateFormat, formatMessage, noJustification]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );

  return (
    <TableWrapper>
      <TablePopUp visible={popupVisible} data={rowData} handleClose={handleClose} />
      <Table tableInstance={tableInstance} handleRowClick={handleRowClick} />
    </TableWrapper>
  );
};

export default HistoryTable;
