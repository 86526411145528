import { BaseModel } from '..';

const initialState: OrganisationDocumentsState = {
  documentMap: new Map(),
};

export enum OrganisationDocumentsActionTypes {
  SET_DOCUMENTS = 'organisationDocuments/setDocuments',
  SAVE = 'organisationDocuments/save',
  REMOVE_DOCUMENT = 'organisationDocuments/removeDocument',
}

interface SetDocumentsOrganisationDocumentsActionType {
  type: OrganisationDocumentsActionTypes.SET_DOCUMENTS;
  payload: OrganisationDocument[];
}

interface SaveOrganisationDocumentsActionType {
  type: OrganisationDocumentsActionTypes.SAVE;
  payload: OrganisationDocument;
}

interface RemoveDocumentsOrganisationDocumentsActionType {
  type: OrganisationDocumentsActionTypes.REMOVE_DOCUMENT;
  payload: { removeId: string };
}

type OrganisationDocumentsAction =
  | SetDocumentsOrganisationDocumentsActionType
  | SaveOrganisationDocumentsActionType
  | RemoveDocumentsOrganisationDocumentsActionType;

export default function (state = initialState, action: OrganisationDocumentsAction): OrganisationDocumentsState {
  switch (action.type) {
    case OrganisationDocumentsActionTypes.SET_DOCUMENTS: {
      const documentMap = new Map(state.documentMap.entries());
      if (Array.isArray(action.payload)) {
        // @TODO: reverse should be dropped in favour of server side ordering
        action.payload.reverse().forEach((document) => {
          documentMap.set(document.id, {
            ...documentMap.get(document.id),
            ...document,
          });
        });
      }
      return {
        ...state,
        documentMap,
      };
    }
    case OrganisationDocumentsActionTypes.SAVE: {
      const documentMap = new Map(state.documentMap.entries());
      if (action.payload.id) {
        documentMap.set(action.payload.id, {
          ...documentMap.get(action.payload.id),
          ...action.payload,
        });
      }
      return {
        ...state,
        documentMap,
      };
    }
    case OrganisationDocumentsActionTypes.REMOVE_DOCUMENT: {
      const documentMap = new Map(state.documentMap.entries());
      if (action.payload.removeId) {
        documentMap.delete(action.payload.removeId);
      }
      return {
        ...state,
        documentMap,
      };
    }
    default:
      return state;
  }
}

export interface OrganisationDocumentsState {
  documentMap: Map<string, OrganisationDocument>;
}

export interface OrganisationDocument extends BaseModel {
  id: string;
}
