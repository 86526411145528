/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { DashboardState } from '../../../models/index';
import TFCloseableBackground from '../../TFCloseableBackground';
import TFSelect from '../../TFSelect/TFSelect';
import styles from '../workpackDrawer.module.less';
import useQueryMaintenanceForWorkpacks from '../QueryCalls/useQueryMaintenanceForWorkpacks';
import useQueryDefectsForWorkpacks from '../QueryCalls/useQueryDefectsForWorkpacks';
import useQueryCabinIssues from '../QueryCalls/useQueryCabinIssues';
import TFLoading from '../../TFLoading';

const ResultsList = ({ results, addWpItem, resetResults, setShowResults, operatorSettings }): JSX.Element =>
  results.map((item) => {
    let mxType;
    let boxColour = 'rgba(36,45,65,0.10)';

    if (item.defect_type || item.defect_type === null) {
      boxColour = '#1e589f';
      mxType = 'defect';
    } else if (item.type === 'cabin issue' || item.type === operatorSettings?.cabin_issue_name_singular) {
      boxColour = '#11b146';
      mxType = 'cabin_issue';
    } else if (item.mx_type && item.mx_type.includes && item.mx_type.includes('scheduled')) {
      boxColour = '#470cfe';
      mxType = 'scheduled_mx_item';
    } else if (item.mx_type && item.mx_type.includes && item.mx_type.includes('oop')) {
      boxColour = '#794efc';
    } else if (item.mx_type && item.mx_type.includes && item.mx_type.includes('llp')) {
      boxColour = '#baa3ff';
    }
    return (
      <button
        type="button"
        key={item.id}
        onClick={(): void => {
          addWpItem(item, mxType);
          resetResults();
          setShowResults(false);
        }}
        className={styles.resultItem}
      >
        <div className={styles.itemTitleWrapper}>
          <div className={styles.colourSquare} style={{ backgroundColor: boxColour }} />
          <span className={styles.itemTitle}>{item.name || item.details}</span>
        </div>
        {item.days_remaining && <span className={styles.estDue}>{`Est. Due: In ${item.days_remaining} days`}</span>}
      </button>
    );
  });

interface SortableByDaysRemaining extends Record<string, unknown> {
  days_remaining: number | null;
}

const MXSelector = ({ addWpItem, wpItems = [] }): JSX.Element => {
  const [searchResults, updateSearchResults] = useState([]);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [listHeight, setListHeight] = useState(null);
  const [defectsArray, setDefectsArray] = useState([]);
  const [cabinIssuesArray, setCabinIssuesArray] = useState([]);
  const [mxArray, setMxArray] = useState([]);

  const inputRef = useRef(null);

  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const operatorSettings = operators.find((op) => aircraftMap.get(id)?.operator_id === op?.id)?.operator_setting;

  const selectOptions = [
    { title: 'all types', colour: 'rgba(36,45,65,0.10)' },
    { title: 'scheduled', colour: '#470cfe' },
    { title: 'oop', colour: '#794efc' },
    { title: 'llp', colour: '#baa3ff' },
    { title: operatorSettings?.defect_name || 'defect', colour: '#1e589f' },
    { title: operatorSettings?.cabin_issue_name_singular || 'cabin issue', colour: '#11b146' },
    { title: 'extra', colour: 'rgba(36,45,65,0.50)' },
  ];

  const { data: maintenanceData, isLoading: maintenanceLoading } = useQueryMaintenanceForWorkpacks({
    id,
  });

  const { data: defectsData, isLoading: defectsLoading } = useQueryDefectsForWorkpacks({
    id,
  });

  const { data: cabinIssuesData, isLoading: cabinIssuesLoading } = useQueryCabinIssues({
    id,
  });

  const resetResults = (): void => {
    updateSearchResults([]);
  };

  const filterArray = (array): any[] => {
    const wpItemIdsAlreadyIn = wpItems.map((item) => {
      return item.mx_item_id;
    });
    const filteredArray = array.filter((item) => {
      return !wpItemIdsAlreadyIn.includes(item.id);
    });
    return filteredArray;
  };

  const handleSearch = (e): void => {
    let results;
    let searchString = '';
    if (e && e.target && e.target.value && e.target.value !== '') searchString = e.target.value.toLowerCase();
    const mxType = selectedTypeOption.title === 'extra' ? null : selectedTypeOption.title;
    if (mxType === 'all types') {
      const defectResults = defectsArray.filter((item: any) => {
        return item && item.details && item.details.toLowerCase().includes(searchString);
      });
      const mxResults = mxArray.filter((item: any) => {
        return item && item.name && item.name.toLowerCase().includes(searchString);
      });
      const cabinResults = cabinIssuesArray.filter((issue) => issue?.details.toLowerCase().includes(searchString));
      const cabinResultsArray = cabinResults.map((issue) => {
        return { ...issue, type: operatorSettings?.cabin_issue_name_singular || 'cabin issue' };
      });
      results = defectResults.concat(mxResults).concat(cabinResultsArray);
    } else if (mxType === 'defect' || mxType === operatorSettings?.defect_name) {
      results = defectsArray.filter((defect: any) => defect && defect.details.toLowerCase().includes(searchString));
    } else if (mxType === operatorSettings?.cabin_issue_name_singular || mxType === 'cabin issue') {
      results = cabinIssuesArray
        .filter((issue: any) => issue?.details.toLowerCase().includes(searchString))
        .map((issue) => {
          return { ...issue, type: operatorSettings?.cabin_issue_name_singular || 'cabin issue' };
        });
    } else {
      results = mxArray.filter(
        (mxItem: any) => mxItem && mxItem.mx_type.includes(mxType) && mxItem.name.toLowerCase().includes(searchString),
      );
    }
    const filteredArray = filterArray(results);

    const sortedResults = filteredArray.sort((a: SortableByDaysRemaining, b: SortableByDaysRemaining) => {
      if (b.days_remaining === null) {
        return -1;
      }
      if (a.days_remaining === null) {
        return 1;
      }
      return a.days_remaining - b.days_remaining;
    });

    updateSearchResults(sortedResults);
  };

  const handleSelectChange = async (option: { title: string; colour?: string }): Promise<void> => {
    setSelectedTypeOption(option);
  };

  const handleInputClick = (): void => {
    if (!showResults) {
      setShowResults(true);
      if (selectedTypeOption !== null) {
        const event = { target: { value: '' } };
        event.target.value = inputRef.current.value;
        handleSearch(event);
      }
    }
  };

  const handleSelectClose = (): void => {
    resetResults();
    setShowResults(false);
  };

  useEffect(() => {
    if (defectsData?.list) setDefectsArray(defectsData?.list);
    if (cabinIssuesData?.cabin_issues) {
      const newArray = cabinIssuesData?.cabin_issues.map((issue) => {
        return {
          ...issue,
          type: operatorSettings?.cabin_issue_name_singular || 'cabin issue',
        };
      });
      setCabinIssuesArray(newArray);
    }
    if (maintenanceData?.body) setMxArray(maintenanceData?.body);
  }, [defectsData?.list, cabinIssuesData, maintenanceData]);

  useEffect(() => {
    const newDefectsArray = defectsArray.filter((defect) => {
      if (wpItems.find((item) => item.mx_item_id === defect.id)) return false;
      return true;
    });
    const newCabinIssuesArray = cabinIssuesArray.filter((issue) => {
      if (wpItems.find((item) => item.mx_item_id === issue.id)) return false;
      return true;
    });
    const newMxArray = mxArray.filter((item) => {
      if (wpItems.find((wpItem) => wpItem.mx_item_id === item.id)) return false;
      return true;
    });
    const newArray = [...newDefectsArray, ...newMxArray, ...newCabinIssuesArray];
    updateSearchResults(newArray);
  }, [defectsArray, mxArray, cabinIssuesArray]);

  useEffect(() => {
    setSelectedTypeOption(selectOptions[0]);
  }, []);

  useEffect(() => {
    if (selectedTypeOption !== null) {
      const event = { target: { value: '' } };
      event.target.value = inputRef.current.value;
      handleSearch(event);
    }
  }, [selectedTypeOption]);

  useEffect(() => {
    if (showResults) document.getElementById('workpackDrawerWrapper').setAttribute('style', 'overflow: hidden');
    if (!showResults) document.getElementById('workpackDrawerWrapper').setAttribute('style', 'overflow: scroll');
  }, [showResults]);

  useEffect(() => {
    const list = document.getElementById('resultListWrapper');
    if (list) {
      const rect = list.getBoundingClientRect();
      const { height } = rect;
      const newHeight = height * -1 - 10;
      setListHeight(newHeight);
    }
  }, [showResults, searchResults, maintenanceLoading, defectsLoading, cabinIssuesLoading]);

  return (
    <div className={styles.addItemsWrapper}>
      <div className={styles.formLabel}>{formatMessage({ id: 'text.wouldYouLikeToAddAdditionalItems' })}</div>
      <div className={styles.selectWrapper}>
        <TFSelect
          options={selectOptions}
          handleSelectChange={handleSelectChange}
          initial={selectedTypeOption}
          display="above"
          width={200}
        />
        <div className={styles.resultListContainer}>
          <input
            type="text"
            onChange={(e): void => handleSearch(e)}
            onClick={(): void => showResults === false && handleInputClick()}
            className={styles.searchInput}
            ref={inputRef}
          />
          {showResults && searchResults.length > 0 && (
            <TFCloseableBackground handleClose={handleSelectClose}>
              <div id="resultListWrapper" style={{ top: listHeight }} className={styles.resultListWrapper}>
                <TFLoading loading={maintenanceLoading || defectsLoading || cabinIssuesLoading} contain />
                <ResultsList
                  results={searchResults}
                  addWpItem={addWpItem}
                  resetResults={resetResults}
                  setShowResults={setShowResults}
                  operatorSettings={operatorSettings}
                />
              </div>
            </TFCloseableBackground>
          )}
        </div>
      </div>
    </div>
  );
};

const AddItemsToWorkpack = ({ addWpItem, wpItems }): JSX.Element => {
  return (
    <div className={styles.addItemsToWorkpack}>
      <MXSelector addWpItem={addWpItem} wpItems={wpItems} />
    </div>
  );
};

export default AddItemsToWorkpack;
