import { Accordion, Button, Modal } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { changeModalContent, changeModalVisibility } from '../../models/drawer';
import { FRATQuestionCategory } from '../../models/frats';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import TFInput from '../../components/TFInput/TFInput';
import TFDropdownMenu from '../../components/TFDropdownMenu/TFDropdownMenu';
import FRATItemRow from './FRATItemRow';
import FRATExpandedItemView from './FRATExpandedItemView';
import useDeleteFRATCategory from './useDeleteFRATCategory';
import useUpdateFRATCategory from './useUpdateFRATCategory';

interface FRATExpandableTableProps {
  editable: boolean;
  items: FRATQuestionCategory[];
  searchValue: string;
}

const AccordionWrapper = styled.div`
  margin: 0 0 8px 0;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CategoryWrapper = styled.div`
  min-height: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
`;

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 4px;
`;

const ModalButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 20px;
`;

const ButtonWrapper = styled.div`
  display: ${({ visible }): string => (visible ? 'flex' : 'none')};
`;

const FRATExpandableTable: React.FC<FRATExpandableTableProps> = ({ editable, items, searchValue }) => {
  const [localItems, setLocalItems] = useState([]);
  const [deleteButtonVisible, setDeleteButtonVisible] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [modalVisible, setModalVisible] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const queryClient = useQueryClient();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleDeleteCategoryOnSuccess = (): void => {
    dispatch(changeModalVisibility({ payload: false }));
    queryClient.invalidateQueries(['individualFRAT', items[0]?.flight_risk_assessment_id]);
  };

  const handleDeleteCategoryOnError = (): void => {
    dispatch(changeModalVisibility({ payload: false }));
  };

  const deleteCategory = useDeleteFRATCategory({ handleDeleteCategoryOnSuccess, handleDeleteCategoryOnError });

  const handeUpdateFRATCategoryOnSuccess = (): void => {
    dispatch(changeModalVisibility({ payload: false }));
    queryClient.invalidateQueries(['individualFRAT', items[0]?.flight_risk_assessment_id]);
  };

  const handeUpdateFRATCategoryOnError = () => {
    dispatch(
      addToast({
        payload: {
          title: 'Error updating category',
          message: '',
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const updateCateogry = useUpdateFRATCategory({ handeUpdateFRATCategoryOnSuccess, handeUpdateFRATCategoryOnError });

  const handleExpansionChange = (item): void => {
    const foundItem = localItems.find((i) => i.id === item.id);
    if (foundItem) {
      foundItem.expanded = foundItem?.expanded ? !foundItem.expanded : true;
      localItems.splice(localItems.indexOf(foundItem), 1, foundItem);
      setLocalItems([...localItems]);
    }
  };

  const handleRenameClick = (item: FRATQuestionCategory): void => {
    setModalVisible(item.id);
  };

  const handleAddCategory = (): void => {
    if (!newCategoryName) {
      return;
    }
    const payload = {
      id: modalVisible,
      flight_risk_assessment_question_category: {
        category: newCategoryName,
      },
    };
    updateCateogry.mutate(payload);
    setModalVisible(null);
    setNewCategoryName('');
  };

  const handleDeleteCategory = (passedId: string): void => {
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.deleteCategory' }),
          text: formatMessage({ id: 'text.deleteCategoryAreYouSure' }),
          saveButtonText: formatMessage({ id: 'text.delete' }),
          saveAction: (): ((dispatch: (args: any) => any) => void) => {
            dispatch(changeModalVisibility({ payload: false }));
            deleteCategory.mutate({ id: passedId });
            return null;
          },
        },
      }),
    );
  };

  const handleMouseHover = (value: string): void => {
    setDeleteButtonVisible(value);
  };

  useEffect(() => {
    if (items?.length !== localItems?.length) setLocalItems(items);
  }, [items, localItems]);

  useEffect(() => {
    const currentCategories = localItems?.map((i) => i.category);
    if (newCategoryName && currentCategories?.includes(newCategoryName)) {
      setErrorMessage('FRAT category name already exists');
    } else {
      setErrorMessage('');
    }
  }, [localItems, newCategoryName]);

  const filteredItems = items
    ?.map((item) => ({
      ...item,
      flight_risk_assessment_questions: item.flight_risk_assessment_questions.filter((question) => {
        return question.question.toLowerCase().includes(searchValue.toLowerCase());
      }),
    }))
    .filter((item) => item.flight_risk_assessment_questions.length > 0);
  return (
    <div>
      {filteredItems?.map((item) => {
        return (
          <div
            key={item.id}
            onMouseEnter={(): void => handleMouseHover(item.id)}
            onMouseLeave={(): void => handleMouseHover(null)}
          >
            <CategoryWrapper data-testid={`FRATExpandableTable--Category-${item.category}`}>
              <div>
                {formatMessage({ id: 'text.category' })}: <span>{item.category}</span>
              </div>
              {editable && deleteButtonVisible ? (
                <ButtonWrapper visible={deleteButtonVisible === item.id}>
                  <TFDropdownMenu
                    options={[
                      {
                        title: <span>Rename</span>,
                        value: 'rename',
                        onClick: () => handleRenameClick(item),
                      },
                      {
                        title: <span>Delete</span>,
                        value: 'delete',
                        onClick: (): void => handleDeleteCategory(item.id),
                        deleteStyle: true,
                      },
                    ]}
                    buttonText={<span>Manage category</span>}
                    buttonPrimary={false}
                  />
                </ButtonWrapper>
              ) : null}
            </CategoryWrapper>
            {item?.flight_risk_assessment_questions?.map((question) => {
              return (
                <AccordionWrapper key={question.id} data-testid={`FRATExpandableTable--Accordion-${question.id}`}>
                  <Accordion
                    dash
                    isExpanded={question?.expanded}
                    onExpansionChange={handleExpansionChange}
                    headerContent={
                      <ItemWrapper>
                        <FRATItemRow item={question} category={item.category} />
                      </ItemWrapper>
                    }
                  >
                    <FRATExpandedItemView
                      item={question}
                      fratId={item?.flight_risk_assessment_id}
                      editable={editable}
                    />
                  </Accordion>
                </AccordionWrapper>
              );
            })}
          </div>
        );
      })}

      <Modal
        width={420}
        isOpen={!!modalVisible}
        handleClose={(): void => {
          setModalVisible(null);
          setNewCategoryName('');
        }}
      >
        <ModalWrapper>
          <Title>Rename category</Title>
          <TFInput
            id="modalInput"
            onChange={(e): void => setNewCategoryName(e.target.value)}
            value={newCategoryName}
            status={errorMessage ? 'error' : undefined}
            statusMessage={errorMessage}
          />
          <ModalButtonWrapper>
            <SubmitButtonWrapper>
              <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={handleAddCategory}>
                {formatMessage({ id: 'text.save' })}
              </Button>
            </SubmitButtonWrapper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => {
                setNewCategoryName('');
                setModalVisible(null);
              }}
            >
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

export default FRATExpandableTable;
