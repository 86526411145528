// Default data in case it's not loaded from server or props/state
export default {
  defaultDateFormat: 'YYYY-MM-DD',
  defaultMXThresholds: {
    days: 30,
    cycles: 30,
    flights: 30,
    landings: 30,
    apu_seconds: 108000,
    flight_seconds: 108000,
  },
};
