import React, { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useIntl } from 'react-intl';
import { Table } from '@arcflight/tf-component-library';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { stringToTitleCase } from '../../utils/utils';
import { AircraftResource, CustomField } from '../../models/aircraft';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import { DashboardState } from '../../models';
import { update } from '../../models/aircraft/actions';
import CustomFieldDrawer from './CustomFieldDrawer';

interface CustomFieldTableProps {
  data: CustomField[];
  total: number;
  pageSize: number;
  pageIndex: number;
  setLoading: (value: boolean) => void;
  onPaginationChange: (currentPage, numberOfItems) => void;
}

const CustomFieldsTable: React.FC<CustomFieldTableProps> = ({
  data,
  total,
  pageSize,
  pageIndex,
  setLoading,
  onPaginationChange,
}) => {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onRowClick = (passedId): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(setDrawerId({ payload: passedId }));
    dispatch(changeDrawerContent({ payload: { content: <CustomFieldDrawer /> } }));
  };

  const columns = useMemo(
    () => [
      {
        Header: formatMessage({ id: 'text.title' }),
        accessor: 'title',
      },
      {
        Header: formatMessage({ id: 'text.type' }),
        accessor: 'type',
        Cell: ({ value }): string => {
          return stringToTitleCase(value);
        },
      },
      {
        Header: formatMessage({ id: 'text.location' }),
        accessor: 'view',
        Cell: ({ value }): string => {
          return stringToTitleCase(value);
        },
      },
      {
        Header: formatMessage({ id: 'text.heading' }),
        accessor: 'heading',
      },
      {
        Header: formatMessage({ id: 'text.order' }),
        accessor: 'order',
      },
      {
        Header: formatMessage({ id: 'text.required' }),
        accessor: 'required',
        Cell: ({ value }): ReactNode => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        Header: '',
        id: '3dot',
        width: 20,
        Cell: ({ row }): ReactNode => {
          const handleViewClick = (): void => {
            dispatch(changeDrawerVisibility({ payload: true }));
            dispatch(setDrawerId({ payload: row.original.key }));
            dispatch(changeDrawerContent({ payload: { content: <CustomFieldDrawer /> } }));
          };

          const handleEditClick = (): void => {
            dispatch(changeDrawerVisibility({ payload: true }));
            dispatch(setDrawerId({ payload: row.original.key }));
            dispatch(changeDrawerContent({ payload: { content: <CustomFieldDrawer /> } }));
            dispatch(changeDrawerMode({ payload: 'edit' }));
          };

          const handleDeleteClick = (): void => {
            const currentCustomFields = aircraftMap.get(id)?.custom_fields || {};
            delete currentCustomFields[row.original.key];
            const payload = {
              aircraftId: id,
              aircraft: {
                custom_fields: currentCustomFields,
              },
            };
            dispatch(update({ payload }));
            setLoading(true);
          };

          return (
            <>
              <AuthDropdownMenu
                handleDelete={handleDeleteClick}
                resource={AircraftResource.AIRCRAFT}
                aircraftId={id}
                options={{ read: true, update: true, delete: true }}
                forTable
                editCallback={handleEditClick}
                viewCallback={handleViewClick}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manuaalSortBy: true,
      initialState: { pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );

  return (
    <Table
      tableInstance={tableInstance}
      total={total}
      handlePaginationChange={(currentPage, numberOfItems): void => onPaginationChange(currentPage, numberOfItems)}
      passedPageSize={pageSize}
      handleRowClick={(row): void => onRowClick(row.original.key)}
    />
  );
};

export default CustomFieldsTable;
