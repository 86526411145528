/* eslint-disable no-underscore-dangle */
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { FeatureFlag } from '../../models/userSettings';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import getOperatorSetting from '../../utils/getOperatorSetting';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import StyledRadioButton from '../DefectDrawer/components/StyledRadioButton';
import { calculateActualUplift, checkDensity, fuelUnitConversion } from '../../utils/fuelCalculations';
import DeleteIcon from '../../assets/delete.svg';
import errorIcon from '../../assets/login-error.svg';
import plusIcon from '../../assets/plus-blue.svg';
import TFSelect from '../TFSelect/TFSelect';
import TFCheckBox from '../TFCheckBoxes/TFCheckBox';
import { DisplayText, Header } from './FlightDrawer';

interface FuelUpliftsProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  margin-bottom: 20px;
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  row-gap: 20px;
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '20px')};
  grid-template-rows: auto;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '0')};
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: '1fr 1fr';
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '16px')};
  grid-template-rows: auto;
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const UpliftNum = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.black};
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const HiddenGravDiv = styled.div`
  height: 52px;
`;

const StyledInput = styled.input`
  width: 100px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const UnitChangeWrapper = styled.div`
  grid-column: span 2;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const LineBreak = styled.div`
  height: 1px;
  margin: 20px 0 20px;
  background-color: ${({ theme }): string => theme.colors.black05Alpha};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
`;

const BowserHelperText = styled.div`
  color: ${({ transparent }): string => (transparent ? 'transparent' : 'rgba(36, 45, 65, 0.7)')};
  font-size: 10px;
  margin-top: 4px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 0;
  img {
    margin-right: 8px;
  }
`;

const ErrorText = styled.span`
  line-height: 1.43;
  color: #ac1717;
`;

const FuelUplifts: React.FC<FuelUpliftsProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const isMobile = window.innerWidth < 451;

  const displayExtraFields = aircraftMap.get(id)?.standard_fields?.fuel_uplift_price?.enabled;
  const fuelTypeFeatureFlag = aircraftMap
    .get(id)
    ?.feature_flags?.some((flag) => flag.feature_name === FeatureFlag.FUELTYPE);

  const currentAircraft = aircraftMap.get(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let standardFuelUpliftsTypes = [];
  let showPercentageType = [];
  let percentageTitle = [];
  if (currentAircraft) {
    standardFuelUpliftsTypes = currentAircraft?.standard_fields?.fuel_uplift?.allowed_fuel_types || [];
    showPercentageType = currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels
      ? Object.keys(currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels)
      : [];
    percentageTitle = currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels
      ? Object.values(currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels)
      : [];
  }

  const bowserRename = getOperatorSetting('bowser_name', id);

  const { formatMessage } = useIntl();

  const [fuelUplifts, setFuelUplifts] = useState([]);
  const [plannedError, setPlannedError] = useState(false);
  const [bowserError, setBowserError] = useState(false);

  const addUplift = (): void => {
    const newArray = fuelUplifts;
    newArray.push({
      aircraft_actual_uplift: null,
      aircraft_planned_departure: null,
      density: null,
      truck_actual_uplift: null,
      truck_unit: 'l', // Another bit of work needs doing to allow to select which unit the truck uplift is in
      saf_percentage: 0,
      fuel_type: standardFuelUpliftsTypes[0],
      aircraft_unit: currentAircraft.fuel_unit,
    });
    setFuelUplifts([...newArray]);
  };

  const removeUplift = (index): void => {
    const newArray = fuelUplifts;
    const isStoredInCore = typeof newArray[index].id === 'string';
    if (isStoredInCore) {
      // eslint-disable-next-line no-underscore-dangle
      newArray[index]._destroy = true;
    } else {
      newArray.splice(index, 1);
    }
    setFuelUplifts([...newArray]);
  };

  const handleInputChange = (value: string, index: number, key: string): void => {
    if (key === 'saf_percentage' && (parseInt(value, 10) > 100 || parseInt(value, 10) < 1)) {
      message.error('SAF Percentage must be between 0 and 100');
      return;
    }
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    if ((key === 'aircraft_planned_departure' || key === 'truck_actual_uplift') && !fuelUplifts[index]?.density) {
      newArray[index].density = 0.8;
    }
    const intValue = parseInt(value, 10);
    if (key === 'aircraft_planned_departure') {
      if (value && (Number.isNaN(intValue) || intValue.toString() !== value)) {
        setPlannedError(true);
      } else {
        setPlannedError(false);
      }
    }
    if (key === 'truck_actual_uplift') {
      if (value && (Number.isNaN(intValue) || intValue.toString() !== value)) {
        setBowserError(true);
      } else {
        setBowserError(false);
      }
    }
    if (key === 'density') {
      newArray[index][key] = parseFloat(value);
    } else if (key === 'price_value') {
      newArray[index][key] = Math.ceil(parseFloat(value) * 100) / 100;
    } else {
      newArray[index][key] = intValue;
    }
    setFuelUplifts([...newArray]);
    updateFlightData([{ value: [...newArray], key: 'fuel_uplifts_attributes' }]);
  };

  const handleFuelTypeChange = (value, index): void => {
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    newArray[index].fuel_type = value;
    setFuelUplifts([...newArray]);
    updateFlightData([{ value: [...newArray], key: 'fuel_uplifts_attributes' }]);
  };

  const buildFuelTypeSelectors = (uplift, index): ReactNode => {
    return standardFuelUpliftsTypes.map((type) => {
      let value = type;
      if (type === 'saf') value = 'SAF';
      if (type === 'standard') value = 'Standard';
      return (
        <StyledRadioButton
          checked={uplift?.fuel_type === type}
          id={type}
          group="fuel_type"
          width="75"
          value={value}
          onClick={(): void => handleFuelTypeChange(type, index)}
          marginRight={8}
        />
      );
    });
  };

  const handleCurrencyChange = (option, index): void => {
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    if (option) {
      newArray[index].price_currency = option.value;
      setFuelUplifts([...newArray]);
      updateFlightData([{ value: [...newArray], key: 'fuel_uplift_attributes' }]);
    }
  };

  const handleUpliftPaid = (value, index): void => {
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    if (value) {
      newArray[index].paid = true;
      setFuelUplifts([...newArray]);
      updateFlightData([{ value: [...newArray], key: 'fuel_uplift_attributes' }]);
    } else {
      newArray[index].paid = false;
      setFuelUplifts([...newArray]);
      updateFlightData([{ value: [...newArray], key: 'fuel_uplift_attributes' }]);
    }
  };

  const handleUnitChange = (value, index): void => {
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    newArray[index].truck_unit = value;
    if (value === 'usg') newArray[index].density = '6.676';
    if (value === 'l') newArray[index].density = '0.8';
    setFuelUplifts([...newArray]);
    updateFlightData([{ value: [...newArray], key: 'fuel_uplifts_attributes' }]);
  };

  const calculateBowserSuggestion = (uplift): number => {
    const initialAmount = flight?.departure_fuel_pre_uplifts || 0;
    const plannedAmount = uplift?.aircraft_planned_departure ? parseInt(uplift?.aircraft_planned_departure, 10) : 0;
    const plannedMinusInital = plannedAmount - initialAmount;
    const truckUnit = uplift?.truck_unit;
    let density = uplift?.density || 0.8;
    if (!uplift?.density && truckUnit === 'usg') density = 6.676;
    const aircraftUnit = aircraftMap.get(id)?.fuel_unit;
    let suggestedAmount = plannedMinusInital;
    if (aircraftUnit !== truckUnit) {
      suggestedAmount = Math.ceil(
        plannedMinusInital *
          ((1 / (checkDensity(truckUnit, aircraftUnit) ? density : 1)) * fuelUnitConversion(truckUnit, aircraftUnit)),
      );
    }
    return suggestedAmount;
  };

  useEffect(() => {
    if (!fuelUplifts.length) {
      const uplift = [
        {
          aircraft_actual_uplift: null,
          aircraft_planned_departure: null,
          density: null,
          truck_actual_uplift: null,
          saf_percentage: 0,
          fuel_type: standardFuelUpliftsTypes[0],
          aircraft_unit: currentAircraft.fuel_unit,
        },
      ];
      setFuelUplifts(uplift);
    }
  }, [fuelUplifts, mode, standardFuelUpliftsTypes, currentAircraft]);

  useEffect(() => {
    if (flight && flight?.fuel_uplifts_attributes) {
      setFuelUplifts(flight?.fuel_uplifts_attributes);
    } else if (flight && flight?.fuel_uplifts?.length) {
      setFuelUplifts(flight?.fuel_uplifts);
    } else {
      setFuelUplifts([]);
    }
  }, [flight]);

  const buildFuelUplifts = (): ReactNode => {
    return fuelUplifts.map((uplift, index) => {
      if (uplift._destroy) {
        return null;
      }
      // eslint-disable-next-line no-param-reassign
      if (!uplift?.truck_unit) uplift.truck_unit = 'l';
      let displayFuelType = 'SAF';
      if (uplift?.fuel_type === 'standard') displayFuelType = 'Standard';
      if (uplift?.fuel_type === 'saf') displayFuelType = 'SAF';

      const options = [
        { title: 'CAD', value: 'CAD' },
        { title: 'EUR', value: 'EUR' },
        { title: 'GBP', value: 'GBP' },
        { title: 'USD', value: 'USD' },
      ];

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${uplift?.id}-${index}`}>
          {fuelUplifts.length > 0 && (
            <HeaderWrapper>
              <UpliftNum>{`Uplift ${index + 1}`}</UpliftNum>
              <ButtonWrapper>
                {mode !== 'view' && fuelUplifts.length > 1 && (
                  <DeleteButton
                    type="button"
                    onClick={(): void => {
                      removeUplift(index);
                    }}
                  >
                    <img src={DeleteIcon} alt="delete button" />
                  </DeleteButton>
                )}
              </ButtonWrapper>
            </HeaderWrapper>
          )}
          {fuelTypeFeatureFlag ? (
            <>
              <DetailsWrapper edit={mode !== 'view'} isMobile={isMobile}>
                {standardFuelUpliftsTypes.length ? (
                  <UnitChangeWrapper>
                    <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.fuelType' })}</Header>
                    {mode === 'view' ? (
                      <DisplayText>{displayFuelType || '-'}</DisplayText>
                    ) : (
                      <RadioButtonWrapper>{buildFuelTypeSelectors(uplift, index)}</RadioButtonWrapper>
                    )}
                  </UnitChangeWrapper>
                ) : null}
                {showPercentageType && showPercentageType?.includes(uplift?.fuel_type) ? (
                  <div>
                    <Header editable={mode !== 'view'}>
                      <span>
                        {percentageTitle[showPercentageType.findIndex((item) => item === uplift?.fuel_type)]}{' '}
                      </span>
                      {formatMessage({
                        id: 'text.percentage',
                      })}
                    </Header>
                    <UnitWrapper>
                      {mode === 'view' ? (
                        <DisplayText>{uplift?.saf_percentage || '-'}</DisplayText>
                      ) : (
                        <StyledInput
                          type="number"
                          step="0.01"
                          placeholder="-"
                          value={uplift?.saf_percentage}
                          onChange={(e): void => handleInputChange(e.target.value, index, 'saf_percentage')}
                        />
                      )}
                      <StyledUnit edit={mode !== 'view'}>%</StyledUnit>
                    </UnitWrapper>
                  </div>
                ) : null}
              </DetailsWrapper>
            </>
          ) : null}
          <DetailsWrapper
            key={uplift?.id}
            edit={mode !== 'view'}
            noMargin={fuelUplifts.length === index + 1}
            isMobile={isMobile}
          >
            {Object.keys(uplift).includes('aircraft_planned_departure') ? (
              <div>
                <Header editable={mode !== 'view'}>Planned fuel after uplift</Header>
                <UnitWrapper>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.aircraft_planned_departure || '-'}</DisplayText>
                  ) : (
                    <StyledInput
                      type="number"
                      step="0.1"
                      placeholder="-"
                      value={uplift?.aircraft_planned_departure || ''}
                      onChange={(e): void => handleInputChange(e.target.value, index, 'aircraft_planned_departure')}
                    />
                  )}
                  <StyledUnit edit={mode !== 'view'}>{currentAircraft?.fuel_unit || ''}</StyledUnit>
                </UnitWrapper>
                {plannedError ? (
                  <ErrorWrapper>
                    <img src={errorIcon} alt="error icon" />
                    <ErrorText>Value entered must be an integer</ErrorText>
                  </ErrorWrapper>
                ) : null}
              </div>
            ) : (
              <div />
            )}
            {Object.keys(uplift).includes('truck_actual_uplift') ? (
              <div>
                <Header editable={mode !== 'view'}>
                  {formatMessage({ id: 'text.bowserUplift' }, { field: bowserRename })}
                </Header>
                <UnitWrapper>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.truck_actual_uplift || '-'}</DisplayText>
                  ) : (
                    <StyledInput
                      type="number"
                      step="0.1"
                      placeholder="-"
                      value={uplift?.truck_actual_uplift || ''}
                      onChange={(e): void => handleInputChange(e.target.value, index, 'truck_actual_uplift')}
                    />
                  )}
                  <StyledUnit edit={mode !== 'view'}>{uplift?.truck_unit || 'l'}</StyledUnit>
                </UnitWrapper>
                {mode !== 'view' && uplift?.aircraft_planned_departure ? (
                  <BowserHelperText>
                    {`Required = ${calculateBowserSuggestion(uplift)} ${uplift?.truck_unit || 'l'}`}
                  </BowserHelperText>
                ) : (
                  <BowserHelperText transparent>Req</BowserHelperText>
                )}
                {bowserError ? (
                  <ErrorWrapper>
                    <img src={errorIcon} alt="error icon" />
                    <ErrorText>Value entered must be an integer</ErrorText>
                  </ErrorWrapper>
                ) : null}
              </div>
            ) : (
              <div />
            )}
            {mode !== 'view' ? (
              <UnitChangeWrapper>
                <Header editable>Uplift unit</Header>
                <RadioButtonWrapper>
                  <RadioInputButton
                    text="l"
                    active={uplift?.truck_unit === 'l'}
                    onClick={(): void => handleUnitChange('l', index)}
                    marginRight="8px"
                  />
                  <RadioInputButton
                    text="usg"
                    active={uplift?.truck_unit === 'usg'}
                    onClick={(): void => handleUnitChange('usg', index)}
                  />
                </RadioButtonWrapper>
              </UnitChangeWrapper>
            ) : null}
            {Object.keys(uplift).includes('density') &&
            checkDensity(uplift?.truck_unit, aircraftMap.get(id)?.fuel_unit) ? (
              <div>
                <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.specificGravity' })}</Header>
                {mode === 'view' ? (
                  <DisplayText>{uplift?.density || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    type="number"
                    step="0.001"
                    value={uplift?.density}
                    onChange={(e): void => handleInputChange(e.target.value, index, 'density')}
                  />
                )}
              </div>
            ) : (
              <HiddenGravDiv />
            )}
            <div>
              <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.actualUplift' })}</Header>
              <UnitWrapper>
                <DisplayText>
                  {calculateActualUplift(uplift, aircraftMap.get(id)?.fuel_unit)
                    ? Math.ceil(calculateActualUplift(uplift, aircraftMap.get(id)?.fuel_unit))
                    : null}
                </DisplayText>
                <StyledUnit>{currentAircraft?.fuel_unit || ''}</StyledUnit>
              </UnitWrapper>
            </div>
          </DetailsWrapper>
          <DetailsWrapper
            edit={mode !== 'view'}
            noMargin={fuelUplifts.length === index + 1}
            marginTop="20px"
            isMobile={isMobile}
          >
            {displayExtraFields ? (
              <>
                <div>
                  <Header editable={mode !== 'view'}>Price</Header>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.price_value || '-'}</DisplayText>
                  ) : (
                    <StyledInput
                      type="number"
                      step="0.01"
                      placeholder="0"
                      value={uplift?.price_value || '-'}
                      onChange={(e): void => handleInputChange(e.target.value, index, 'price_value')}
                    />
                  )}
                </div>
                <div>
                  <Header editable={mode !== 'view'}>Currency</Header>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.price_currency || '-'}</DisplayText>
                  ) : (
                    <TFSelect
                      options={options}
                      initial={{ value: uplift?.price_currency, title: uplift?.price_currency } || options[0]}
                      handleSelectChange={(value): void => handleCurrencyChange(value, index)}
                      width={100}
                      allowEmpty
                    />
                  )}
                </div>
                <UnitChangeWrapper>
                  <Header editable={mode !== 'view'}>Paid</Header>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.paid ? 'Paid' : 'Unpaid'}</DisplayText>
                  ) : (
                    <CheckBoxWrapper>
                      <TFCheckBox
                        value
                        checked={uplift?.paid === true}
                        title="Yes"
                        marginRight={8}
                        onChange={(value): void => handleUpliftPaid(value, index)}
                      />
                      <TFCheckBox
                        value={false}
                        checked={uplift?.paid === false}
                        title="No"
                        onChange={(value): void => handleUpliftPaid(value, index)}
                      />
                    </CheckBoxWrapper>
                  )}
                </UnitChangeWrapper>
              </>
            ) : null}
          </DetailsWrapper>
          {index + 1 < fuelUplifts.length && <LineBreak />}
        </div>
      );
    });
  };
  return (
    <SectionWrapper>
      <HeaderInner>
        <div />
        <ButtonWrapper>
          {mode !== 'view' ? (
            <Button primary={false} height="24px" onClick={addUplift}>
              <AddTextWrapper>
                <PlusIcon src={plusIcon} alt="plus icon" /> Add uplift
              </AddTextWrapper>
            </Button>
          ) : null}
        </ButtonWrapper>
      </HeaderInner>
      {fuelUplifts.length ? buildFuelUplifts() : null}
    </SectionWrapper>
  );
};

export default FuelUplifts;
