/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface DeferUntilProps {
  defect: Defect | null;
  rectificationCategory?: { title: string; colour?: string };
  nonMEL?: boolean;
}

const DeferUntil: React.FC<DeferUntilProps> = ({ defect, rectificationCategory, nonMEL }) => {
  const {
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);

  return (
    <FlexWrapper marginRight={20}>
      {rectificationCategory?.title && rectificationCategory?.title === 'Advisory only' ? null : (
        <>
          <FlexWrapper column marginRight={20}>
            <Label color="rgba(36, 45, 65, 0.4)" fontWeight={400} marginBottom={nonMEL ? 5 : 0} width="120px">
              DEFERRED UNTIL
            </Label>
            <Label height="30px">{defect?.date_due ? moment.utc(defect.date_due).format(dateFormat) : '-'}</Label>
          </FlexWrapper>
        </>
      )}
    </FlexWrapper>
  );
};

export default DeferUntil;
