import { addNote, deleteNote, editNote, getNotes } from '../../services/api';
import { NotesActionTypes } from '.';

export const getAircraftNotes =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getNotes(payload);
    dispatch({
      type: NotesActionTypes.SAVEALL,
      payload: response,
    });
  };

export const addAircraftNote =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await addNote(payload);
    dispatch({
      type: NotesActionTypes.SAVE,
      payload: response,
    });
    return response;
  };

export const editAircraftNote =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await editNote(payload);
    dispatch({
      type: NotesActionTypes.SAVE,
      payload: response,
    });
    return response;
  };

export const deleteAircraftNote =
  ({ id }) =>
  async (dispatch): Promise<any> => {
    await deleteNote(id);
    dispatch({
      type: NotesActionTypes.DELETE,
      id,
    });
  };
