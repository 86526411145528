import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DefectsIcon from '../../assets/Defects.svg';
import DentAndBuckleIcon from '../../assets/DentAndBuckle.svg';
import DocumentsIcon from '../../assets/Documents.svg';
import LimitationsIcon from '../../assets/Limitations.svg';
import MaintenanceIcon from '../../assets/Maintenance.svg';
import MXChecksIcon from '../../assets/MXChecks.svg';
import NotesIcon from '../../assets/icon-navigation-notes-to-crew.svg';
import AircraftStatusTooltip from '../AircraftStatusTooltip/AircraftStatusTooltip';
import styles from './AircraftStatusTile.module.less';

const { Panel } = Collapse;

const AircraftStatusTile = ({ tile, id, ingoreEROPSWarnings, dentAndBuckleTitle, eropsTitle }) => {
  const {
    userSettings: {
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state) => state);

  const defectsNameOverride = operators.find((op) => op?.id === aircraftMap.get(id)?.operator_id)?.operator_setting
    ?.defect_name_plural;
  const maintenanceChecksNameOverride = operators.find((op) => op?.id === aircraftMap.get(id)?.operator_id)
    ?.operator_setting?.mx_check_name_plural;

  let icon = DefectsIcon;
  let title = '';
  let link = '';

  switch (tile[0]) {
    case 'mx_checks':
      icon = MXChecksIcon;
      title = maintenanceChecksNameOverride?.toUpperCase() || 'MAINTENANCE CHECKS';
      link = null;
      break;
    case 'defects':
      icon = DefectsIcon;
      title = defectsNameOverride?.toUpperCase() || 'DEFECTS';
      link = `/aircraft/${id}/defects`;
      break;
    case 'limitations':
      icon = LimitationsIcon;
      title = 'LIMITATIONS';
      link = null;
      break;
    case 'dent_and_buckle':
      icon = DentAndBuckleIcon;
      title = dentAndBuckleTitle?.toUpperCase();
      link = `/aircraft/${id}/damage_maps/list_view`;
      break;
    case 'maintenance':
      icon = MaintenanceIcon;
      title = 'MAINTENANCE';
      link = `/aircraft/${id}/maintenance`;
      break;
    case 'documents':
      icon = DocumentsIcon;
      title = 'DOCUMENTS';
      link = `/aircraft/${id}/documents`;
      break;
    case 'notes':
      icon = NotesIcon;
      title = 'NOTES TO CREW';
      link = `/aircraft/${id}/notes`;
      break;
    default:
      break;
  }

  let colour = '';
  let textColour = '';

  switch (tile[1].colour) {
    case 'red':
      colour = 'linear-gradient(to bottom, #ff7474, #ff4040)';
      textColour = '#ff7474';
      break;
    case 'amber':
      colour = 'linear-gradient(to bottom, #fad288, #f2a650)';
      textColour = '#fad288';
      break;
    case 'green':
      colour = 'linear-gradient(to bottom, #7bc88d, #4aa91c)';
      textColour = '#7bc88d';
      break;
    case 'grey':
      colour = 'linear-gradient(to bottom, #d2d2d2, #e0e0e0)';
      textColour = '#d2d2d2';
      break;
    default:
      break;
  }

  const panelHeader = (
    <div className={styles.tileWrapper}>
      <div>
        <img src={icon} alt="icon" className={styles.icon} data-testid="AircraftStatusTile--Icon" />
        <span className={styles.title} data-testid="AircraftStatusTile--Title">
          {title}
        </span>
      </div>
      <div className={styles.boxBody}>
        <div className={styles.squareCounterWrapper} style={{ background: colour, color: textColour }}>
          <div className={styles.squareCounter}>
            <span data-testid="AircraftStatusTile--Count">{tile[1].count}</span>
          </div>
        </div>
      </div>
    </div>
  );

  let isEropsPrevented = false;

  const limitationList =
    tile[1].limitations &&
    tile[1].limitations.length > 0 &&
    tile[1].limitations.map((limitation) => {
      if (Array.isArray(limitation)) {
        if (limitation[2] === true) {
          isEropsPrevented = true;
        }
        if (limitation[0]) {
          return (
            <li key={limitation} className={styles.limitationText}>{`Defect ${limitation[0]}: ${limitation[1]}`}</li>
          );
        }
      } else if (limitation) {
        return (
          <li key={limitation} className={styles.limitationText}>
            {limitation}
          </li>
        );
      }
      return null;
    });

  if (isEropsPrevented && !ingoreEROPSWarnings) {
    limitationList.push(<li className={styles.limitationText}>{`${eropsTitle} prevented`}</li>);
  }

  const collapseContent = <AircraftStatusTooltip content={tile[1].checks} eropsTitle={eropsTitle} />;

  return (
    <div className={styles.collapseWrapper}>
      {title === 'MAINTENANCE CHECKS' ||
      title === maintenanceChecksNameOverride?.toUpperCase() ||
      (title === 'LIMITATIONS' && tile[1].limitations.length > 0) ? (
        <div data-testid={`AircraftStatusTile--${tile[0]}`}>
          <Collapse>
            <Panel header={panelHeader} showArrow={false}>
              {title === 'LIMITATIONS' ? (
                <ul key="limitation-list" className={styles.limitationList}>
                  {limitationList}
                </ul>
              ) : (
                collapseContent
              )}
            </Panel>
          </Collapse>
        </div>
      ) : (
        <Link to={link || undefined}>
          <div className={styles.tileWrapper} data-testid={`AircraftStatusTile--${tile[0]}`}>
            <div>
              <img src={icon} alt="icon" className={styles.icon} data-testid="AircraftStatusTile--Icon" />
              <span className={styles.title} data-testid="AircraftStatusTile--Title">
                {title}
              </span>
            </div>
            <div className={styles.boxBody}>
              <div className={styles.squareCounterWrapper} style={{ background: colour, color: textColour }}>
                <div className={styles.squareCounter}>
                  <span data-testid="AircraftStatusTile--Count">{tile[1].count}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

AircraftStatusTile.propTypes = {
  tile: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  ingoreEROPSWarnings: PropTypes.bool.isRequired,
  dentAndBuckleTitle: PropTypes.string.isRequired,
  eropsTitle: PropTypes.string.isRequired,
};

export default AircraftStatusTile;
