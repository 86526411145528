import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import PaginatedDefectsTable from '../../components/PaginatedDefectsTable';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { getSingleAircraft } from '../../models/aircraft/actions';
import styles from './DefectsList.module.less';

class AircraftDefectsList extends PureComponent {
  static propTypes = {
    fetchAircraft: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    selectedAircraft: PropTypes.object,
    ttl: PropTypes.number.isRequired,
  };

  static defaultProps = {
    selectedAircraft: undefined,
  };

  state = { loading: true };

  componentDidMount() {
    const { selectedAircraft, ttl, fetchAircraft } = this.props;
    if (!selectedAircraft || Date.now() - selectedAircraft.lastFetched > ttl) {
      fetchAircraft().then(() => {
        this.setState({ loading: false });
      });
    } else if (selectedAircraft.id) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { history, selectedAircraft } = this.props;
    const { loading } = this.state;
    return (
      <InnerMenuLayout loading={loading}>
        <div className={styles.card}>
          <div className={styles.tableList}>
            {selectedAircraft?.id ? <PaginatedDefectsTable history={history} aircraft={selectedAircraft} /> : null}
          </div>
        </div>
      </InnerMenuLayout>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    (
      { aircraft },
      {
        match: {
          params: { id },
        },
      },
    ) => ({
      selectedAircraft: aircraft.aircraftMap.get(id),
      ttl: aircraft.ttl,
    }),
    (
      dispatch,
      {
        match: {
          params: { id },
        },
      },
    ) => ({
      fetchAircraft: () => {
        return dispatch(
          getSingleAircraft({
            payload: id,
          }),
        );
      },
    }),
  ),
)(AircraftDefectsList);
