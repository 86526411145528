import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, useIntl } from 'react-intl';
import Exception from '../components/Exception';

const Exception404: FC = () => {
  const { formatMessage } = useIntl();
  return <Exception type="404" desc={formatMessage({ id: 'app.exception.description.404' })} linkElement={Link} />;
};

export default injectIntl(Exception404);
