import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { DashboardState } from '../../models';
import { Aircraft, SimpleAircraft } from '../../models/aircraft';
import AircraftCard from './index'; // Assuming AircraftCard component exists

// Define the props type for TypedAircraftCard component
interface TypedAircraftCardProps {
  aircraftId?: string;
  aircraft?: SimpleAircraft | Aircraft | undefined;
  preAircraft: SimpleAircraft | Aircraft | undefined; // Adjust type as needed
  clickable: boolean;
  'data-test': string;
  'data-testid': string;
  handleFavouriteButtonClick: (isFavourited: boolean, aircraft: SimpleAircraft) => void;
  isFavourite?: boolean;
}

// Define the props type for the AircraftCard component
type AircraftCardProps = TypedAircraftCardProps & WrappedComponentProps<'intl'>;

// Define mapStateToProps function
const mapStateToProps = (state: DashboardState) => ({
  aircraftMap: state.aircraft.fleetAircraftMap,
  airportsMap: state.airports.airportsMap,
  operators: state.userSettings?.details?.operators,
});

// Create connected AircraftCard component
const ConnectedAircraftCard = connect(mapStateToProps)(AircraftCard);

// Wrap connected AircraftCard with injectIntl HOC and export it
const TypedAircraftCard: React.FC<AircraftCardProps> = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ConnectedAircraftCard {...props} />;
};

export default injectIntl(TypedAircraftCard);
