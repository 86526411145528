import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Toast, ToastCategories, removeToast } from '../../models/toasts';
import BasicToast from './BasicToast';

export interface FlashToastProps {
  toast: Toast;
  index: number;
  total: number;
}

const ToastWrapper = styled.div`
  background-color: ${({ theme }): string => theme.colours.snow};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: ${({ closing, moving }): string => {
    if (closing || moving) return 'transform 0.3s ease-out, opacity 0.3s ease-out';
    return null;
  }};
  transform: translateY(${({ closing, moving }): string => (closing || moving ? '100%' : '0')});
  opacity: ${({ closing }): string => (closing ? '0' : '1')};
  cursor: pointer;
  box-shadow: ${({ theme }): string => `3px 3px 15px 0 ${theme.colors.black20Alpha}`};
`;

const FlashToast: React.FC<FlashToastProps> = ({ toast, index, total }) => {
  const [closingToastIndex, setClosingToastIndex] = useState<number | null>(null);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [elaspsedTime, setElapsedTime] = useState(0);

  const dispatch = useDispatch();

  const isIndexGreaterThanClosingToastIndex = (): boolean => {
    return closingToastIndex !== null && index > closingToastIndex;
  };

  const handleMouseEnter = (): void => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = (): void => {
    setIsMouseOver(false);
    setElapsedTime(0);
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (toast?.category === ToastCategories.FLASH && !isMouseOver) {
      timerId = setTimeout(() => {
        setClosingToastIndex(index);
        setTimeout(() => {
          dispatch(removeToast({ payload: toast }));
          setClosingToastIndex(null);
        }, 300);
      }, 6000 - elaspsedTime);
    }

    return (): void => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast, isMouseOver, elaspsedTime]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (toast?.category === ToastCategories.FLASH && isMouseOver) {
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1000);
      }, 1000);
    }

    return (): void => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseOver]);

  return (
    <ToastWrapper
      index={index}
      closing={index === closingToastIndex}
      moving={isIndexGreaterThanClosingToastIndex()}
      data-testid="FlashToast--ToastWrapper"
      onMouseEnter={toast?.category === ToastCategories.FLASH ? handleMouseEnter : null}
      onMouseLeave={toast?.category === ToastCategories.FLASH ? handleMouseLeave : null}
    >
      <BasicToast toast={toast} index={index} total={total} setClosingToastIndex={setClosingToastIndex} />
    </ToastWrapper>
  );
};

export default FlashToast;
