import React, { useState } from 'react';
import { ResponsiveContainer, AreaChart, CartesianGrid, Area, XAxis, YAxis, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import EmptyStateGraph from '../../assets/emptyState/empty-state-metrics.svg';
import EmptyState from '../EmptyState/EmptyState';
import styles from './FuelUsage.module.less';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#b0bac9" transform="rotate(0)">
        {payload.value}
      </text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
};

const Totals = ({ monthly, formatMessage }) => {
  const [totalHoursVisible, setTotalHoursVisible] = useState(true);
  const [totalFuelVisible, setTotalFuelVisible] = useState(true);

  const handleButtonClick = (button) => {
    switch (button) {
      case 'totalHours':
        setTotalHoursVisible(!totalHoursVisible);
        break;
      case 'totalFuel':
        setTotalFuelVisible(!totalFuelVisible);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        {monthly.length > 0 ? (
          <AreaChart width={600} height={300} data={monthly} margin={{ top: 10, right: 10, left: 10, bottom: 20 }}>
            <CartesianGrid stroke="#dbe3ed" strokeDasharray="2 2" />
            <XAxis dataKey="periodFormatted" stroke="#b0bac9" tick={<CustomizedAxisTick />} />
            <YAxis
              type="number"
              stroke="#012140"
              unit=" kg"
              domain={['auto', 'auto']}
              padding={{ bottom: 20, top: 20 }}
              yAxisId="left"
            />
            <YAxis
              type="number"
              stroke="#1790FF"
              domain={['auto', 'auto']}
              padding={{ bottom: 20, top: 20 }}
              yAxisId="right"
              orientation="right"
              unit=" hrs"
            />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#739CD8" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
              </linearGradient>
            </defs>
            <Tooltip />
            {totalFuelVisible ? (
              <Area
                yAxisId="right"
                type="linear"
                strokeWidth="2"
                dataKey="totalHours"
                stroke="#1790FF"
                dot="#1790FF"
                fill="url(#splitColor)"
                name={formatMessage({ id: 'units.totalHoursFlown' })}
              />
            ) : null}
            {totalHoursVisible ? (
              <Area
                yAxisId="left"
                type="linear"
                strokeWidth="2"
                dataKey="totalFuel"
                stroke="#012140"
                dot="#012140"
                fill="url(#splitColor)"
                name={formatMessage({ id: 'units.totalFuelUsed' })}
              />
            ) : null}
          </AreaChart>
        ) : (
          <EmptyState
            image={EmptyStateGraph}
            text="No fuel usage data"
            subText="Fuel usage will be shown here when data has been added."
          />
        )}
      </ResponsiveContainer>
      <div
        className={monthly.length > 0 ? styles.toggleButtonsTotals : styles.hideToggleButtons}
        data-testid="Totals--ToggleButtons"
      >
        <button
          className={totalHoursVisible ? styles.buttonToggle : styles.buttonToggleOff}
          type="button"
          onClick={() => handleButtonClick('totalHours')}
        >
          <div className={styles.badge1} />
          <span>{formatMessage({ id: 'text.totalFuelUsed' })}</span>
        </button>
        <button
          className={totalFuelVisible ? styles.buttonToggle : styles.buttonToggleOff}
          type="button"
          onClick={() => handleButtonClick('totalFuel')}
        >
          <div className={styles.badge2} />
          <span>{formatMessage({ id: 'text.totalHoursFlown' })}</span>
        </button>
      </div>
    </>
  );
};

Totals.propTypes = {
  monthly: PropTypes.array,
  formatMessage: PropTypes.func.isRequired,
};

Totals.defaultProps = {
  monthly: [],
};

export default Totals;
