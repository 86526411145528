import React from 'react';

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const fillColor = (): GradientType => {
    if (status === 'processing') return { from: '#f2a650', to: '#fad288' };
    if (status === 'pending') return { from: '#f2a650', to: '#fad288' };
    if (status === 'open') return { from: '#f2a650', to: '#fad288' };
    if (status === 'active') return { from: '#57aaff', to: '#81bfff' };
    if (status === 'draft') return { from: '#d2d2d2', to: '#e0e0e0' };
    if (status === 'overdue') return { from: '#f5222d', to: '#f5222d' };
    if (status === 'deferral_pending') return { from: '#f5222d', to: '#f5222d' };
    return { from: '#4aa91c', to: '#7bc88d' };
  };

  const gradient = fillColor();

  return (
    <svg height="20" width="15">
      <defs>
        <linearGradient id={`${gradient.from}Gradient`} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={`${gradient.from}`} />
          <stop offset="100%" stopColor={`${gradient.to}`} />
        </linearGradient>
      </defs>
      <circle fill={`url(#${gradient.from}Gradient)`} cx="5.5" cy="55%" r="5.5" />
    </svg>
  );
};

type StatusBadgeProps = {
  status: string;
};

type GradientType = {
  from: string;
  to: string;
};

export default StatusBadge;
