/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import servers from '../../utils/servers';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import { Trip } from '../../models/trips';
import { deleteFlightEntry, getFlightDetails } from '../../models/flights/actions';
import { getAircraftTripEntries } from '../../models/trips/actions';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  setDrawerId,
  updateDrawerContent,
  revertChanges as reduxRevertChanges,
  setPreviousDrawerContent,
  setPassedData,
} from '../../models/drawer';
import { addFlight, updateFlight } from '../../services/api';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import completeIcon from '../../assets/icon-status-complete.svg';
import incompleteIcon from '../../assets/icon-status-incomplete.svg';
import draftIcon from '../../assets/icon-status-unset.svg';
import backArrow from '../../assets/icon-back.svg';
import iIcon from '../../assets/i.svg';
import chevron from '../../assets/go-arrow.svg';
import TFSelect from '../TFSelect/TFSelect';
import errorIcon from '../../assets/login-error.svg';
import checkPrecisionOfNumber from '../../utils/checkPrecisionOfNumber';
import getOperatorSetting from '../../utils/getOperatorSetting';
import { checkNumberIsTwoDecimalPlaces } from '../../utils/utils';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource, SUPPORTED_CUSTOM_FIELDS } from '../../models/aircraft';
import HistorySection from '../HistoryTable/HistorySection';
import FlightDetailsSection from './FlightDetailsSection';
import FuelSection from './FuelSection';
import DefectsSection from './DefectsSection';
import FlightAttachements from './FlightAttachments';
import PreFlightCustomDataSection from './PreFlightCustomDataSection';
import PostFlightCustomDataSection from './PostFlightCustomDataSection';
import UpliftsSection from './UpliftsSection';
import IntermittentFaultsSection from './IntermittentFaultsSection';

interface FlightDrawerProps {
  trip?: Trip;
  scroll?: string;
}

interface FlightWithAttachments extends Flight {
  attachments_attributes?: [];
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  height: 32px;
  align-items: center;
  img {
    margin: 0 4px 0 8px;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    height: auto;
    max-width: 96%;
  }
`;

const HeavyTitle = styled.span`
  font-size: 18px;
  color: #242d41;
  font-weight: 500;
  margin: 0 4px;
`;

const StatusDivWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ mode }): string => (mode === 'view' ? 'column' : 'row')};
  align-items: ${({ mode }): string => (mode === 'view' ? 'flex-start' : 'center')};
  margin-bottom: 24px;
  span {
    font-size: 12px;
    color: #126fd6;
    letter-spacing: -0.08px;
    font-weight: 500;
  }
  img {
    margin-left: 4px;
    height: 16px;
    width: 16px;
  }
`;

export const SectionHeader = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: ${({ reducedBottomMargin }): string => (reducedBottomMargin ? '10px' : '20px')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}` : '0')};
`;

export const Header = styled.div`
  color: ${({ editable }): string => (editable ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  letter-spacing: ${({ editable }): string => (editable ? 'normal' : '0.4px')};
  font-size: 12px;
  text-transform: ${({ editable }): string => (editable ? 'capitalize' : 'uppercase')};
  font-weight: ${({ editable }): string => (editable ? '500' : 'normal')};
`;

export const DisplayText = styled.div`
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: ${({ cap }): string => (cap ? 'capitalize' : 'none')};
`;

export const StyledInput = styled.input`
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  height: 40px;
  border-radius: 2px;
  border: 1px solid rgba(36, 45, 65, 0.2);
  padding: 0 8px;
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: ${({ noMargin }): string => (noMargin ? '0' : '0 0 32px')};
  padding: 20px;
`;

const NoShadowWrapper = styled.div`
  margin-bottom: 32px;
`;

const LoadingWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 24px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const ModalContentWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: #242d41;
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
  max-width: 350px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubminButtonWraper = styled.div`
  margin-right: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CancelWrapper = styled.div`
  margin-left: 16px;
`;

const AbandonmentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    display: flex;
    color: rgba(36, 45, 65, 0.9);
    align-items: center;
  }
  span {
    font-size: 14px;
    color: rgba(36, 45, 65, 0.9);
    letter-spacing: -0.08px;
    font-weight: 500;
  }
`;

const AbandonmentBanner = styled.div`
  background: #fbfcfd;
  border-radius: 2px;
  border: #57aaff 1px solid;
  height: ${({ isMobile }): string => (isMobile ? 'auto' : '48px')};
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const AbandonmentTitle = styled.div`
  margin-bottom: 4px;
  color: rgba(36, 45, 65, 0.9) !important;
`;

const EditAbandonWrapper = styled.div`
  img {
    height: 12px;
    width: 12px;
  }
  span {
    font-size: 14px;
    letter-spacing: -0.08px;
    color: rgba(36, 45, 65, 0.9) !important;
    font-weight: 400;
  }
  &AbandonmentTitle {
    font-size: 14px;
    color: rgba(36, 45, 65, 0.9) !important;
    margin-bottom: 4px;
  }
`;

const IconWrapper = styled.div`
  background-image: linear-gradient(to bottom, #126fd6, #57aaff);
  width: 40px;
  height: ${({ isMobile }): string => (isMobile ? '63px' : '48px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StyledContinueButton = styled.button`
  border: none;
  background-color: transparent;
  color: #126fd6;
  padding: 0 20px;
  cursor: pointer;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 48px;
  img {
    margin-right: 8px;
  }
`;

const ErrorText = styled.span`
  line-height: 1.43;
  color: #ac1717;
`;

const FlightDrawer: React.FC<FlightDrawerProps> = ({ trip, scroll }) => {
  const [flightData, setFlightData] = useState({
    defects: [],
    intermittent_faults: [],
    abandonment_reason: null,
  } as FlightWithAttachments);
  const [originalFlightData, setOriginalFlightData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [flightDate, setFlightDate] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [flightId, setFlightId] = useState(null);
  const [displayPreFlightSection, setDisplayPreFlightSection] = useState(true);
  const [displayAbandonmentDropdown, setDisplayAbandonmentDropdown] = useState(false);
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [noDefectsError, setNoDefectsError] = useState(false);
  const [defectsChecked, setDefectsChecked] = useState(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const {
    flights: { flightsMap },
    userSettings: {
      dateFormat,
      details: { operators },
    },
    drawer: { drawerHistory, mode, revertChanges, drawerId, passedData, previousDrawerContent },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const displayPreFlight = aircraftMap.get(id)?.standard_fields?.journey_log?.enabled;
  const displayBlockTimes = aircraftMap.get(id)?.standard_fields?.blocks_times?.enabled;
  const defectCheckboxDisabled = aircraftMap.get(id)?.standard_fields?.defects_reported?.enabled === false;
  const hideAllFuel = aircraftMap.get(id)?.standard_fields?.hide_all_fuel?.enabled;
  const deicingFieldsRequired = aircraftMap.get(id)?.standard_fields?.additional_deicing_fields?.required;
  const IDGRequired = aircraftMap.get(id)?.standard_fields?.oil_uplifts?.required;
  const oilUpliftRequired = aircraftMap.get(id)?.standard_fields?.oil_uplifts?.required;
  const IDGRename = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.name_override;
  const IDGCount = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.count;
  const BUGRequired = aircraftMap.get(id)?.standard_fields?.oil_uplifts?.required;
  const BUGRename = aircraftMap.get(id)?.standard_fields?.back_up_generator?.name_override;
  const BUGCount = aircraftMap.get(id)?.standard_fields?.back_up_generator?.count;
  const airborneSecondsEnabled = aircraftMap.get(id)?.standard_fields?.airborne_seconds?.enabled;

  const hideEngineCyclesFields = aircraftMap.get(id)?.app_settings?.hide_engine_cycles_fields;
  const hideAirframeCyclesFields = aircraftMap.get(id)?.app_settings?.hide_airframe_cycles_fields;

  const engineCount = aircraftMap.get(id)?.aircraft_type?.engine_count;
  const aircraftMaxWeight = aircraftMap.get(id)?.max_weight;

  const aircraft = aircraftMap.get(id);
  const operatorSettings = operators.find((op) => op.id === aircraft.operator_id)?.operator_setting;
  const bowserRename = getOperatorSetting('bowser_name', id);
  const showPilotPerformingTakeOffAndLanding = getOperatorSetting('pilot_performing_takeoff_on_postflight_enabled', id);
  const sectorNameOverride = getOperatorSetting('sector_name', id) || formatMessage({ id: 'text.sector' });

  let renderSIC = true;
  if (
    (aircraftMap.get(aircraft?.id) &&
      Object.keys(aircraftMap.get(aircraft?.id)).includes('multipilot_rated') &&
      !aircraftMap.get(aircraft?.id)?.multipilot_rated) ||
    !trip?.first_officer
  ) {
    renderSIC = false;
  }

  let statusIcon = completeIcon;
  if (flightData?.status === 'partial') statusIcon = incompleteIcon;
  if (flightData?.status === 'planned') statusIcon = draftIcon;
  if (flightData?.status === 'in_progress') statusIcon = incompleteIcon;

  let statusText = flightData?.status;
  if (flightData?.status === 'in_progress') statusText = 'in progress';

  const abandonmentOptions = [
    { title: 'Technical Failure', value: 'technical_failure' },
    { title: 'Weather', value: 'weather' },
    { title: 'Operational Decision', value: 'operational_decision' },
    { title: 'Created in Error', value: 'created_in_error' },
    { title: 'None', value: null },
  ];

  const dispatchErrorToast = ({ title, message }): void => {
    dispatch(
      addToast({
        payload: {
          title,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
          message,
        },
      }),
    );
  };

  const handleBackClick = (): void => {
    dispatch(updateDrawerContent({ payload: true }));
    dispatch(handleBackButtonClick());
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleCancelClick = (): void => {
    if (drawerHistory.length && mode === 'add') {
      handleBackClick();
    } else {
      dispatch(changeDrawerMode({ payload: 'view' }));
      setFlightData(originalFlightData);
    }
  };

  const deleteSector = (): void => {
    dispatch(
      deleteFlightEntry({ payload: { id: flightData.id, aircraftId: id }, sectorNameOverride }, () =>
        dispatch(getAircraftTripEntries({ payload: { id } })),
      ),
    );
    if (drawerHistory.length) {
      handleBackClick();
    } else {
      dispatch(changeDrawerVisibility({ payload: false }));
    }
  };

  const getRequiredFields = (fields): any[] => {
    const entries = Object.entries(fields);
    const required = entries
      .filter((entry: any) => SUPPORTED_CUSTOM_FIELDS.includes(entry[1].type) && entry[1].required)
      .map(([key, data]: any) => {
        return { key: data?.key, view: data?.view, title: data?.heading || data?.title };
      });
    return required;
  };

  const getNumberFields = (fields): any[] => {
    const entries = Object.entries(fields);
    const required = entries
      .filter((entry: any) => entry[1].type === 'number')
      .map(([key, data]: any) => {
        return { key: data?.key, view: data?.view, title: data?.heading || data?.title, precision: data?.precision };
      });
    return required;
  };

  const validateCustomData = (): boolean => {
    const requiredFields = getRequiredFields(aircraftMap.get(id)?.custom_fields);
    const numberFields = getNumberFields(aircraftMap.get(id)?.custom_fields);
    let valid = true;
    requiredFields.forEach((field) => {
      if (field?.view === 'preflight') {
        if (
          flightData.preflight_custom_data &&
          (flightData.preflight_custom_data[`${field.key}`] === undefined ||
            flightData.preflight_custom_data[`${field.key}`] === null)
        ) {
          dispatchErrorToast({
            title: 'Custom data error',
            message: `Preflight custom data ${field?.title.toLowerCase()} must be completed`,
          });
          valid = false;
        }
      }
      if (field?.view === 'postflight') {
        if (
          flightData.postflight_custom_data &&
          (flightData.postflight_custom_data[`${field.key}`] === undefined ||
            flightData.postflight_custom_data[`${field.key}`] === null)
        ) {
          dispatchErrorToast({
            title: 'Custom data error',
            message: `Postflight custom data ${field?.title.toLowerCase()} must be completed`,
          });
          valid = false;
        }
      }
    });
    numberFields.forEach((field) => {
      if (field?.view === 'preflight') {
        if (
          flightData.preflight_custom_data &&
          flightData.preflight_custom_data[`${field.key}`] &&
          field?.precision &&
          !checkPrecisionOfNumber(flightData.preflight_custom_data[`${field.key}`], field?.precision)
        ) {
          dispatchErrorToast({
            title: 'Custom data error',
            message: `Preflight custom data ${field?.title.toLowerCase()} must be to ${
              field?.precision
            } decimal places`,
          });
          valid = false;
        }
      }
      if (field?.view === 'postflight') {
        if (
          flightData.postflight_custom_data &&
          flightData.postflight_custom_data[`${field.key}`] &&
          field?.precision &&
          !checkPrecisionOfNumber(flightData.postflight_custom_data[`${field.key}`], field?.precision)
        ) {
          dispatchErrorToast({
            title: 'Custom data error',
            message: `Postflight custom data ${field?.title.toLowerCase()} must be to ${
              field?.precision
            } decimal places`,
          });
          valid = false;
        }
      }
    });
    return valid;
  };

  const validateDeicingRequiredFields = (): boolean => {
    let valid = true;
    if (deicingFieldsRequired) {
      // eslint-disable-next-line no-unused-expressions
      flightData?.deice_events_attributes?.forEach((event: any) => {
        if (!event?.manufacturer) {
          dispatchErrorToast({
            title: 'A deicing manufacturer must be completed',
            message: '',
          });
          valid = false;
        }
        if (!event?.result) {
          dispatchErrorToast({
            title: 'A deicing result must be completed',
            message: '',
          });
          valid = false;
        }
      });
    }
    return valid;
  };

  const validateUplifts = (): boolean => {
    const uplfitErrors = [];
    if (flightData?.fluid_uplifts_attributes) {
      flightData.fluid_uplifts_attributes.forEach((uplift) => {
        let fluidType = uplift?.fluid_type;
        const fluidDestination = uplift?.fluid_destination;
        let displayType = 'Oil';
        if (fluidType === 'oil' && fluidDestination === 'idg') displayType = 'IDG';
        if (fluidType === 'oil' && fluidDestination === 'bug') displayType = 'BUG';
        const oilUplifts = flightData?.fluid_uplifts_attributes?.filter(
          (item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'engine',
        );
        const idgUplifts = flightData?.fluid_uplifts_attributes?.filter(
          (item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'idg',
        );
        const bugUplifts = flightData?.fluid_uplifts_attributes?.filter(
          (item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'bug',
        );
        let foundIndex = 0;
        if (fluidType === 'oil' && fluidDestination === 'engine')
          foundIndex = oilUplifts?.findIndex(
            (item) => item?.fluid_destination_number === uplift?.fluid_destination_number,
          );
        if (fluidType === 'oil' && fluidDestination === 'idg')
          foundIndex = idgUplifts?.findIndex(
            (item) => item?.fluid_destination_number === uplift?.fluid_destination_number,
          );
        if (fluidType === 'oil' && fluidDestination === 'bug')
          foundIndex = bugUplifts?.findIndex(
            (item) => item?.fluid_destination_number === uplift?.fluid_destination_number,
          );
        if (!checkNumberIsTwoDecimalPlaces(uplift?.uplift)) {
          uplfitErrors.push(`${displayType} uplift number ${foundIndex + 1}`);
        }
        if (fluidType === 'oil' && fluidDestination === 'idg' && IDGRename) fluidType = IDGRename;
        if (fluidType === 'oil' && fluidDestination === 'bug' && BUGRename) fluidType = BUGRename;
        if (!uplift?.uplift_unit) {
          uplfitErrors.push(`${displayType} uplift number ${foundIndex + 1}, unit is required`);
        }
        if (oilUpliftRequired && uplift?.fluid_type === 'oil' && fluidDestination === 'engine') {
          if (!uplift?.uplift) {
            uplfitErrors.push(`${displayType} uplift number ${foundIndex + 1}, uplift is required`);
          }
        }
        if (IDGRequired && uplift?.fluid_type === 'oil' && uplift?.fluid_destination === 'idg') {
          if (!uplift?.uplift) {
            uplfitErrors.push(`${displayType} uplift number ${foundIndex + 1}, uplift is required`);
          }
        }
        if (BUGRequired && uplift?.fluid_type === 'oil' && uplift?.fluid_destination === 'bug') {
          if (!uplift?.uplift) {
            uplfitErrors.push(`${displayType} uplift number ${foundIndex + 1}, uplift is required`);
          }
        }
      });
    }
    if (
      oilUpliftRequired &&
      (!flightData ||
        !flightData?.fluid_uplifts_attributes ||
        !flightData?.fluid_uplifts_attributes?.filter(
          (item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'engine',
        )?.length) &&
      !flightData?.fluid_uplifts?.filter((item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'engine')
        ?.length
    ) {
      uplfitErrors.push('Oil uplift is required');
    }
    if (
      IDGRequired &&
      IDGCount &&
      IDGCount > 0 &&
      (!flightData ||
        !flightData?.fluid_uplifts_attributes ||
        !flightData?.fluid_uplifts_attributes?.filter(
          (item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'idg',
        )?.length) &&
      !flightData?.fluid_uplifts?.filter((item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'idg')
        ?.length
    ) {
      uplfitErrors.push(`${IDGRename || 'IDG'} uplift is required`);
    }
    if (
      BUGRequired &&
      BUGCount &&
      BUGCount > 0 &&
      (!flightData ||
        !flightData?.fluid_uplifts_attributes ||
        !flightData?.fluid_uplifts_attributes?.filter(
          (item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'bug',
        )?.length) &&
      !flightData?.fluid_uplifts?.filter((item) => item?.fluid_type === 'oil' && item?.fluid_destination === 'bug')
        ?.length
    ) {
      uplfitErrors.push(`${BUGRename || 'BUG'} uplift is required`);
    }
    if (uplfitErrors.length) {
      uplfitErrors.forEach((error) => {
        dispatchErrorToast({
          title: 'Uplift Error',
          message: error,
        });
      });
      return false;
    }
    return true;
  };

  const validateFlightData = (): boolean => {
    const errors = [];
    if (flightData && !flightData.pilot_flying?.id) {
      errors.push('Pilot flying must be completed');
    }
    if (flightData && !flightData.pic?.id) {
      errors.push('PIC must be completed');
    }
    if (flightData && !flightData.pilot_performing_landing_id && showPilotPerformingTakeOffAndLanding) {
      errors.push('Pilot performing landing must be completed');
    }
    if (flightData && !flightData.pilot_performing_takeoff_id && showPilotPerformingTakeOffAndLanding) {
      errors.push('Pilot performing takeoff must be completed');
    }
    if (flightData && !flightData.departure_airport_id) {
      errors.push('Departure airport must be completed');
    }
    if (flightData && !flightData.arrival_airport_id) {
      errors.push('Arrival airport must be completed');
    }
    if (flightData && !flightData.time_offblocks && displayBlockTimes !== false && !flightData.abandonment_reason) {
      errors.push(`${operatorSettings?.blocks_off} time must be completed`);
    }
    if (flightData && !flightData.time_onblocks && displayBlockTimes !== false && !flightData.abandonment_reason) {
      errors.push(`${operatorSettings?.blocks_on} time must be completed`);
    }
    if (flightData && !flightData.time_landing && !flightData.abandonment_reason) {
      errors.push(`${operatorSettings?.landing} time must be completed`);
    }
    if (flightData && !flightData.time_takeoff && !flightData.abandonment_reason) {
      errors.push(`${operatorSettings?.takeoff} time must be completed`);
    }
    if (flightData && !flightData.landings_count && !flightData.abandonment_reason) {
      errors.push(`${operatorSettings?.landing}s must be completed`);
    }
    if (
      operatorSettings?.max_weight_for_flight_enabled &&
      flightData &&
      flightData?.preflight_weight > aircraftMaxWeight
    ) {
      errors.push(`Weight must be less than aircraft ${operatorSettings?.max_weight_for_flight_name}`);
    }
    if (checkboxClicked === false && !defectCheckboxDisabled) {
      errors.push('You must add at least one defect or select no to continue');
    }
    if (
      checkboxClicked &&
      !defectCheckboxDisabled &&
      flightData &&
      ((flightData?.defects?.length === 0 && defectsChecked) || (flightData?.defects?.length && !defectsChecked))
    ) {
      setNoDefectsError(true);
      return false;
    }
    if (flightData && !hideAirframeCyclesFields && flightData?.cycles?.airframe < 1 && !flightData.abandonment_reason) {
      errors.push('Airframe cannot be less than 1');
    }
    if (
      flightData &&
      !hideEngineCyclesFields &&
      (flightData?.cycles?.engine_1 < 1 ||
        (flightData?.cycles?.engine_2 < 1 && engineCount > 1) ||
        (flightData?.cycles?.engine_3 < 1 && engineCount > 2) ||
        (flightData?.cycles?.engine_4 < 1 && engineCount > 3)) &&
      !flightData.abandonment_reason
    ) {
      errors.push('Engine cannot be less than 1');
    }
    if (
      flightData &&
      mode === 'add' &&
      (flightData?.attachments_attributes === undefined ||
        // eslint-disable-next-line no-underscore-dangle
        flightData?.attachments_attributes.filter((item: any) => !item._destroy).length === 0) &&
      (flightData?.sector_attachments === undefined ||
        // eslint-disable-next-line no-underscore-dangle
        flightData?.sector_attachments.filter((item: any) => !item._destroy).length === 0)
    ) {
      errors.push(`${sectorNameOverride} must have an attachment`);
    }
    if (flightData && flightData?.fuel_uplifts_attributes && flightData?.fuel_uplifts_attributes.length) {
      flightData.fuel_uplifts_attributes.forEach((uplift: any) => {
        const pattern = /^\d+(\.\d{1,3})?$/;
        if (
          uplift?.aircraft_planned_departure && typeof uplift?.aircraft_planned_departure === 'string'
            ? Number.isNaN(parseInt(uplift?.aircraft_planned_departure, 10)) ||
              parseInt(uplift?.aircraft_planned_departure, 10).toString() !== uplift?.aircraft_planned_departure
            : Number.isNaN(uplift?.aircraft_planned_departure)
        ) {
          errors.push('Planned fuel uplift must be an interger');
        }
        if (
          uplift?.truck_actual_uplift && typeof uplift?.truck_actual_uplift === 'string'
            ? Number.isNaN(parseInt(uplift?.truck_actual_uplift, 10)) ||
              parseInt(uplift?.truck_actual_uplift, 10).toString() !== uplift?.truck_actual_uplift
            : Number.isNaN(uplift?.truck_actual_uplift)
        ) {
          errors.push(`${bowserRename} uplift must be numerical and no more than 3 digits`);
        }
        if (uplift?.density && !pattern.test(uplift?.density) && !uplift?._destroy) {
          errors.push('Specific gravity must be numerical and no more than 3 digits');
        }
        if (!uplift?.truck_actual_uplift && !uplift?._destroy) {
          errors.push(`${bowserRename} uplift must be completed`);
        }
      });
    }
    if (
      flightData?.departure_fuel_pre_uplifts &&
      (Number.isNaN(flightData?.departure_fuel_pre_uplifts) ||
        /^\d+$/.test(flightData?.departure_fuel_pre_uplifts.toString()) === false)
    ) {
      errors.push('Initial fuel must be an interger');
    }
    if (
      flightData?.departure_fuel_adjustment &&
      (Number.isNaN(flightData.departure_fuel_adjustment) || !Number.isInteger(flightData.departure_fuel_adjustment))
    ) {
      errors.push('Adjustment fuel must be an interger');
    }
    if (
      flightData?.arrival_fuel &&
      (Number.isNaN(flightData?.arrival_fuel) || /^\d+$/.test(flightData?.arrival_fuel.toString()) === false)
    ) {
      errors.push('Arrival fuel must be an interger');
    }
    if (!validateCustomData()) {
      return false;
    }
    if (!validateDeicingRequiredFields()) {
      return false;
    }
    const upliftsValid = validateUplifts();
    if (airborneSecondsEnabled && !flightData?.airborne_seconds) {
      errors.push('Airborne seconds must be completed');
    }
    if (airborneSecondsEnabled && flightData?.airborne_seconds < 0) {
      errors.push('Airborne seconds must be a non-negative number');
    }
    if (errors.length) {
      errors.forEach((error) => {
        dispatchErrorToast({
          title: error,
          message: '',
        });
      });
      return false;
    }
    if (!upliftsValid) return false;
    return true;
  };

  const buildCyclesObject = (abandon = false): Record<string, any> => {
    const apuInstalled = aircraft?.apu_installed;
    const cyclesObject = {} as any;
    if (abandon) {
      cyclesObject.airframe = 0;
      if (apuInstalled) cyclesObject.apu = 0;
      for (let i = 1; i < engineCount; i += 1) {
        cyclesObject[`engine_${i}`] = 0;
      }
    } else {
      cyclesObject.airframe = 1;
      if (apuInstalled) cyclesObject.apu = 1;
      for (let i = 1; i < engineCount; i += 1) {
        cyclesObject[`engine_${i}`] = 1;
      }
    }
    return cyclesObject;
  };

  const handleSaveClick = async (): Promise<void> => {
    if (validateFlightData()) {
      const newPayload: any = flightData;
      newPayload.pilot_flying_id = flightData?.pilot_flying?.id;
      newPayload.pic_id = flightData?.pic?.id;
      newPayload.pilot_monitoring_id = flightData?.pilot_monitoring?.id;
      newPayload.sic_id = flightData?.sic?.id;
      newPayload.attachments_attributes = flightData?.sector_attachments;
      if (hideAirframeCyclesFields || hideEngineCyclesFields) {
        if (!newPayload?.cycles) newPayload.cycles = {};
        if (hideAirframeCyclesFields) newPayload.cycles.airframe = flightData?.landings_count;
        if (hideEngineCyclesFields) {
          for (let i = 1; i <= engineCount; i += 1) {
            newPayload.cycles[`engine_${i}`] = flightData?.landings_count;
          }
        }
      }
      setLoading(true);
      if (drawerId) {
        await updateFlight({ id: drawerId, flight: newPayload });
        dispatch(getFlightDetails({ payload: drawerId }));
        dispatch(changeDrawerMode({ payload: 'view' }));
        setLoading(false);
        const drawer = document.getElementById('drawerTitle');
        if (drawer) drawer.scrollIntoView();
      } else if (trip) {
        const newFlight = await addFlight({ id: trip?.id, flight: newPayload });
        dispatch(getFlightDetails({ payload: newFlight?.id }));
        setFlightId(newFlight?.id);
        if (drawerHistory.length) {
          handleBackClick();
          dispatch(changeDrawerMode({ payload: 'view' }));
          dispatch(setDrawerId({ payload: trip?.id }));
        } else {
          dispatch(changeDrawerMode({ payload: 'view' }));
        }
        setLoading(false);
        const drawer = document.getElementById('drawerTitle');
        if (drawer) drawer.scrollIntoView();
      }
      dispatch(updateDrawerContent({ payload: true }));
    }
  };

  useEffect(() => {
    if (
      !Object.keys(flightData).length &&
      trip &&
      trip?.flights &&
      trip?.flights[trip?.flights.length - 1] &&
      !previousDrawerContent
    ) {
      const lastFlight = trip.flights[trip.flights.length - 1];
      const departureAirportId = lastFlight.arrival_airport_id;
      setFlightData({ ...flightData, departure_airport_id: departureAirportId });
      setOriginalFlightData({ departure_airport_id: departureAirportId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFlightData = useCallback(
    (changes: { value: any; key: string }[]): void => {
      const newData = flightData ? JSON.parse(JSON.stringify(flightData)) : {};
      changes.forEach(({ value, key }) => {
        if (key === 'pilot_flying_id') {
          if (newData?.pilot_flying) {
            newData.pilot_flying.id = value;
          } else {
            newData.pilot_flying = {};
            newData.pilot_flying.id = value;
          }
        } else if (key === 'pilot_monitoring_id') {
          if (newData?.pilot_monitoring) {
            newData.pilot_monitoring.id = value;
          } else {
            newData.pilot_monitoring = {};
            newData.pilot_monitoring.id = value;
          }
        } else if (key === 'pic_id') {
          if (newData?.pic) {
            newData.pic.id = value;
          } else {
            newData.pic = {};
            newData.pic.id = value;
          }
        } else if (key === 'sic_id') {
          if (newData?.sic) {
            newData.sic.id = value;
          } else {
            newData.sic = {};
            newData.sic.id = value;
          }
        } else if (key) {
          newData[`${key}`] = value;
        }
      });
      setFlightData(newData);
    },
    [flightData],
  );

  const handleAbandonmentChange = (option): void => {
    const cycles = buildCyclesObject(true);
    updateFlightData([
      { value: option.value, key: 'abandonment_reason' },
      { value: flightData.departure_airport_id, key: 'arrival_airport_id' },
      { value: null, key: 'time_takeoff' },
      { value: null, key: 'time_landing' },
      { value: null, key: 'time_onblocks' },
      { value: null, key: 'time_offblocks' },
      { value: null, key: 'max_flight_level' },
      { value: 0, key: 'landings_count' },
      { value: cycles, key: 'cycles' },
    ]);
  };

  const handleContinueFlightClick = (): void => {
    const cycles = buildCyclesObject();
    updateFlightData([
      { value: null, key: 'abandonment_reason' },
      { value: '', key: 'arrival_airport_id' },
      { value: '', key: 'time_takeoff' },
      { value: '', key: 'time_landing' },
      { value: '', key: 'time_onblocks' },
      { value: '', key: 'time_offblocks' },
      { value: '', key: 'max_flight_level' },
      { value: 1, key: 'landings_count' },
      { value: cycles, key: 'cycles' },
    ]);
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const formatText = (text: string): string => {
    let newText = text;
    if (text) {
      newText = newText.replace(/_/g, ' ');
      newText = newText.charAt(0).toUpperCase() + newText.slice(1);
    }
    return newText;
  };

  useEffect(() => {
    if (drawerId) {
      setLoading(true);
      dispatch(getFlightDetails({ payload: drawerId }));
    }
  }, [dispatch, drawerId]);

  useEffect(() => {
    if (flightId) {
      if (flightsMap.get(flightId)) {
        setLoading(false);
        setFlightData({ ...flightsMap.get(flightId) });
        setOriginalFlightData({ ...flightsMap.get(flightId) });
      }
    }
  }, [flightId, flightsMap]);

  useEffect(() => {
    if (drawerId) setFlightId(drawerId);
  }, [drawerId]);

  useEffect(() => {
    if (flightData?.defects?.length) {
      setNoDefectsError(false);
    }
  }, [flightData]);

  useEffect(() => {
    if (Object.entries(aircraftMap.get(id)?.custom_fields).length) {
      const booleanFields = Object.entries(aircraftMap.get(id)?.custom_fields)
        .filter(([key, value]: any) => value.type === 'boolean')
        .map(([key, value]: any) => {
          return {
            view: value?.view,
            key: value?.key,
          };
        });
      const newPreflight = { ...flightData?.preflight_custom_data };
      const newPostflight = { ...flightData?.postflight_custom_data };
      booleanFields.forEach(({ view, key }) => {
        if (view === 'preflight') {
          newPreflight[key] = false;
        }
        if (view === 'postflight') {
          newPostflight[key] = false;
        }
      });
      if (booleanFields.filter((val) => val?.view === 'preflight').length > 0) {
        booleanFields
          .filter((val) => val?.view === 'preflight')
          .forEach(({ key }) => {
            if (flightData?.preflight_custom_data && !flightData?.preflight_custom_data[`${key}`]) {
              newPreflight[`${key}`] = false;
            }
          });
      }
      if (booleanFields.filter((val) => val?.view === 'postflight').length > 0) {
        booleanFields
          .filter((val) => val?.view === 'postflight')
          .forEach(({ key }) => {
            if (flightData?.postflight_custom_data && !flightData?.postflight_custom_data[`${key}`]) {
              newPostflight[`${key}`] = false;
            }
          });
      }
      if (
        !_.isEqual(flightData?.preflight_custom_data, newPreflight) ||
        !_.isEqual(flightData?.postflight_custom_data, newPostflight)
      ) {
        const mergedPreflight = {
          ...newPreflight,
          ...flightData?.preflight_custom_data,
        };
        const mergedPostflight = {
          ...newPostflight,
          ...flightData?.postflight_custom_data,
        };
        updateFlightData([
          { value: mergedPreflight, key: 'preflight_custom_data' },
          { value: mergedPostflight, key: 'postflight_custom_data' },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraftMap, id]);

  useEffect(() => {
    setNoDefectsError(false);
  }, [defectsChecked]);

  useEffect(() => {
    if (flightData?.trip?.date) {
      setFlightDate(flightData?.trip?.date);
    } else if (
      trip &&
      trip?.flights &&
      trip?.flights[trip?.flights.length - 1] &&
      trip?.flights[trip?.flights.length - 1].time_onblocks
    ) {
      setFlightDate(trip?.flights[trip?.flights.length - 1].time_onblocks);
      if (!flightData) {
        setFlightData({
          ...flightData,
          departure_airport_id: trip?.flights[trip?.flights.length - 1].arrival_airport_id,
        });
        setOriginalFlightData({ departure_airport_id: trip?.flights[trip?.flights.length - 1].arrival_airport_id });
      }
    } else if (trip && trip?.date) {
      setFlightDate(trip.date);
    }
  }, [flightData, trip]);

  useEffect(() => {
    if (mode === 'add' && trip) {
      let previousData;
      if (previousDrawerContent?.length) {
        previousData = previousDrawerContent.pop();
        dispatch(setPreviousDrawerContent({ payload: previousDrawerContent }));
        if (passedData?.type === 'defect') {
          const newDefect = passedData.content;
          const newArray = previousData?.defects ? [...previousData.defects] : [];
          newArray.push(newDefect);
          previousData.defects_attributes = newArray;
          previousData.defects = newArray;
          dispatch(setPassedData({ payload: null }));
        }

        if (passedData?.type === 'fault') {
          const newIntermittentFault: any = passedData.content;
          const newArray = flightData?.intermittent_faults ? [...flightData.intermittent_faults] : [];
          newArray.push(newIntermittentFault);
          previousData.intermittent_faults_attributes = newArray;
          previousData.intermittent_faults = newArray;
          dispatch(setPassedData({ payload: null }));
        }

        setFlightData(previousData);
        setOriginalFlightData(previousData);
      } else {
        const lastFlight = trip?.flights[trip.flights.length - 1];
        const departAirportId = lastFlight?.arrival_airport_id || '';
        const blocksOnTime = lastFlight?.time_onblocks || '';
        updateFlightData([
          { value: trip?.captain?.id, key: 'pilot_flying_id' },
          { value: trip?.captain?.id, key: 'pilot_performing_takeoff_id' },
          { value: trip?.captain?.id, key: 'pilot_performing_landing_id' },
          { value: trip?.captain?.id, key: 'pic_id' },
          { value: trip?.first_officer?.id, key: 'pilot_monitoring_id' },
          { value: trip?.first_officer?.id, key: 'sic_id' },
          { value: departAirportId, key: 'departure_airport_id' },
          { value: blocksOnTime, key: 'time_offblocks' },
          { value: blocksOnTime, key: 'time_onblocks' },
          { value: blocksOnTime, key: 'time_landing' },
          { value: blocksOnTime, key: 'time_takeoff' },
          { value: [], key: 'defects' },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, trip, previousDrawerContent]);

  useEffect(() => {
    if (mode === 'edit') {
      if (previousDrawerContent?.length) {
        const previousData = previousDrawerContent.pop();
        dispatch(setPreviousDrawerContent({ payload: previousDrawerContent }));
        setFlightData(previousData);
        setOriginalFlightData(previousData);
      } else {
        let newPic = {};
        let newSic = {};
        if (flightData?.pic?.id) newPic = flightData.pic;
        if (!flightData?.pic?.id && flightData?.pilot_flying?.id) {
          newPic = flightData?.pilot_flying;
        }
        if (flightData?.sic?.id) newSic = flightData?.sic;
        if (!flightData?.sic?.id && flightData?.pilot_monitoring?.id) {
          newSic = flightData?.pilot_monitoring;
        }
        updateFlightData([
          { value: newPic, key: 'pic' },
          { value: newSic, key: 'sic' },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (!_.isEqual(flightData, originalFlightData)) {
      dispatch(setDrawerChanges({ payload: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightData]);

  useEffect(() => {
    if (revertChanges) {
      setFlightData(originalFlightData);
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revertChanges]);

  useEffect(() => {
    if (scroll) {
      const element = document.getElementById('attachments');
      if (element) element.scrollIntoView();
    }
  }, [scroll, loading]);

  useEffect(() => {
    if (flightData?.abandonment_reason) {
      setDisplayAbandonmentDropdown(true);
    }
  }, [flightData]);

  let sectorNumber = null;
  if (drawerId && flightData) sectorNumber = flightData?.sector_number;
  let tripNumber = '';
  if (flightData?.trip?.number) {
    tripNumber = flightData?.trip?.number.toString();
  }
  if (trip?.number) {
    tripNumber = trip?.number.toString();
  }

  useEffect(() => {
    let preflightCustomFields = [];
    if (Object.entries(aircraftMap.get(id)?.custom_fields)?.length) {
      preflightCustomFields = Object.entries(aircraftMap.get(id)?.custom_fields)?.reduce((acc, [key, value]: any) => {
        const newAcc = acc;
        if (value?.view === 'preflight') {
          newAcc[key] = value;
        }
        return newAcc;
      });
    }
    if (
      displayPreFlight === false &&
      !flightData?.preflight_custom_data &&
      !Object.entries(preflightCustomFields).length
    ) {
      setDisplayPreFlightSection(false);
    }
  }, [aircraftMap, displayPreFlight, flightData, id]);

  return (
    <DrawerWrapper id="drawerWrapper" data-testid="FlightDrawer-DrawerWrapper">
      {loading ? (
        <LoadingWrapper>
          <Loading loading={loading} contain />
        </LoadingWrapper>
      ) : (
        <>
          <Title id="drawerTitle" data-testid="FlightDrawer-Title">
            {drawerHistory?.length && flightData?.trip?.flights?.length ? (
              <StyledButton onClick={handleBackClick}>
                <img src={backArrow} alt="back arrow" />
              </StyledButton>
            ) : null}
            {`${sectorNameOverride}: `}
            <HeavyTitle data-testid="FlightDrawer-FlightNumber">
              {`${sectorNumber || '-'} (Trip #${tripNumber} - ${moment.utc(flightDate || '').format(dateFormat)})`}
            </HeavyTitle>{' '}
            {mode === 'view' ? (
              <>
                -
                <img src={statusIcon} alt="status icon" /> {statusText}
              </>
            ) : null}
            <div>
              {mode === 'view' ? (
                <AuthDropdownMenu
                  options={{
                    read: false,
                    update: true,
                    delete: true,
                  }}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
                  resource={AircraftResource.TRIP}
                  aircraftId={flightData?.aircraft?.id}
                  editCallback={(): void => handleEditClick()}
                  handleDelete={(): void => deleteSector()}
                />
              ) : null}
            </div>
          </Title>
          <StatusDivWrapper data-testid="FlightDrawer-OpenButton" mode={mode}>
            {mode === 'view' && flightData?.abandonment_reason ? (
              <AbandonmentWrapper>
                <AbandonmentBanner isMobile={window.innerWidth < 451}>
                  <div>
                    <IconWrapper isMobile={window.innerWidth < 451}>
                      <img src={iIcon} alt="info icon" />
                    </IconWrapper>
                    This flight has been abandoned: {formatText(flightData?.abandonment_reason)}
                  </div>
                  <StyledContinueButton type="button" onClick={(): void => handleContinueFlightClick()}>
                    Continue Flight
                    <img src={chevron} alt="chevron" />
                  </StyledContinueButton>
                </AbandonmentBanner>
              </AbandonmentWrapper>
            ) : null}
            {mode !== 'add' ? (
              <div>
                <Header>{operatorSettings?.srp}</Header>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${servers.api}/srp/${flightData?.trip?.id}.pdf`}
                  download={formatMessage({ id: 'text.srp' })}
                >
                  <span>{operatorSettings?.srp}</span>
                  <img src={pdfIcon} alt="download icon" />
                </a>
              </div>
            ) : null}
            {mode === 'edit' ? (
              <EditAbandonWrapper>
                {displayAbandonmentDropdown ? (
                  <>
                    <AbandonmentTitle>Abandonment Reason</AbandonmentTitle>
                    <TFSelect
                      width={200}
                      options={abandonmentOptions}
                      initial={
                        abandonmentOptions.find((item) => item.value === flightData?.abandonment_reason) ||
                        abandonmentOptions[0]
                      }
                      handleSelectChange={handleAbandonmentChange}
                    />
                  </>
                ) : (
                  <Button height="24px" primary={false} onClick={(): void => setDisplayAbandonmentDropdown(true)}>
                    {`Abandon ${sectorNameOverride.toLowerCase()}`}
                  </Button>
                )}
              </EditAbandonWrapper>
            ) : null}
          </StatusDivWrapper>
          <InfoDivWrapper data-testid="FlightDrawer-DetailsSection">
            <FlightDetailsSection
              flight={flightData}
              updateFlightData={updateFlightData}
              flightDate={flightDate}
              trip={trip}
              renderSIC={renderSIC}
              abandoned={flightData?.abandonment_reason !== null}
            />
          </InfoDivWrapper>
          {displayPreFlightSection ? (
            <InfoDivWrapper data-testid="FlightDrawer-PreFlightSection">
              <PreFlightCustomDataSection flight={flightData} updateFlightData={updateFlightData} />
            </InfoDivWrapper>
          ) : null}
          <InfoDivWrapper data-testid="FlightDrawer-PostFlightSection">
            <PostFlightCustomDataSection flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          {!hideAllFuel ? (
            <InfoDivWrapper data-testid="FlightDrawer-FuelSection">
              <FuelSection flight={flightData} updateFlightData={updateFlightData} />
            </InfoDivWrapper>
          ) : null}
          <NoShadowWrapper>
            <UpliftsSection flight={flightData} updateFlightData={updateFlightData} />
          </NoShadowWrapper>
          {mode === 'view' || defectCheckboxDisabled ? (
            <DefectsSection flight={flightData} setCheckboxClicked={setCheckboxClicked} />
          ) : (
            <InfoDivWrapper noMargin={noDefectsError}>
              <DefectsSection
                flight={flightData}
                setCheckboxClicked={setCheckboxClicked}
                defectsChecked={defectsChecked}
                setDefectsChecked={setDefectsChecked}
              />
            </InfoDivWrapper>
          )}
          {noDefectsError ? (
            <ErrorWrapper>
              <img src={errorIcon} alt="error icon" />
              <ErrorText>You must add at least one defect or select no to continue</ErrorText>
            </ErrorWrapper>
          ) : null}
          <NoShadowWrapper>
            <IntermittentFaultsSection flight={flightData} aircraft={aircraft} />
          </NoShadowWrapper>
          {mode === 'view' ? (
            <NoShadowWrapper>
              <HistorySection versions={flightData?.versions} />
            </NoShadowWrapper>
          ) : null}
          <InfoDivWrapper id="attachments" data-testid="FlightDrawer-AttachmentsSection">
            <FlightAttachements flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          {mode !== 'view' ? (
            <ButtonWrapper>
              <Button size={ButtonSize.MEDIUM} onClick={handleSaveClick}>
                Save
              </Button>
              <CancelWrapper>
                <Button primary={false} size={ButtonSize.MEDIUM} onClick={handleCancelClick}>
                  Cancel
                </Button>
              </CancelWrapper>
            </ButtonWrapper>
          ) : null}
        </>
      )}
      <Modal width={420} isOpen={modalVisible} handleClose={(): void => setModalVisible(false)}>
        <ModalContentWrapper>
          <ModalTitle>{formatMessage({ id: 'title.deleteItem' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureDeleteSector',
            })} ${formatMessage({
              id: 'form.labels.cannotBeUndone',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubminButtonWraper>
              <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={(): void => deleteSector()}>
                Delete
              </Button>
            </SubminButtonWraper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setModalVisible(false)}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalContentWrapper>
      </Modal>
    </DrawerWrapper>
  );
};

export default FlightDrawer;
