import React from 'react';
import styled from 'styled-components';
import RadioInputButton from '../RadioInputButton/RadioInputButton';

interface ShowHideToggleProps {
  showOverride?: string;
  hideOverride?: string;
  value: boolean;
  handleChange: (value: boolean) => void;
  dataTestId?: string;
}

const ButtonWrapper = styled.div`
  display: flex;
`;

const ShowHideToggle: React.FC<ShowHideToggleProps> = ({
  value,
  handleChange,
  showOverride,
  hideOverride,
  dataTestId,
}) => {
  return (
    <ButtonWrapper>
      <RadioInputButton
        text={showOverride || 'Show'}
        active={value}
        marginRight="8px"
        onClick={(): void => handleChange(true)}
        dataTestId={`RadioInputButtonTrue-${dataTestId}`}
      />
      <RadioInputButton
        text={hideOverride || 'Hide'}
        active={!value}
        onClick={(): void => handleChange(false)}
        dataTestId={`RadioInputButtonFalse-${dataTestId}`}
      />
    </ButtonWrapper>
  );
};

export default ShowHideToggle;
