import React from 'react';
import styled from 'styled-components';

interface StyledRadioInputProps {
  handleClick?: (input?: string | number) => void;
  id: string;
  name: string;
  value: string;
  checked: boolean;
  marginTop?: number;
  identifier?: string;
}

const RadioInput = styled.input`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: ${({ marginTop }): string => `${marginTop}px` || '0'};
  cursor: pointer;
  outline: none;
`;

const StyledRadioInput: React.FC<StyledRadioInputProps> = ({
  handleClick,
  id,
  checked,
  marginTop,
  identifier,
  name,
  value,
}) => {
  return (
    <RadioInput
      type="radio"
      onClick={handleClick}
      onChange={handleClick}
      id={id}
      name={name}
      value={value}
      checked={checked}
      marginTop={marginTop}
      data-testid={`StyledRadioInput--${identifier}`}
    />
  );
};

export default StyledRadioInput;
