import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { updateMxEventsDefect, updateRelease } from '../../../services/api';
import TFFilerViewer from '../../TFFileViewer/fileViewer';
import errorIcon from '../../../assets/login-error.svg';
import pencilIcon from '../../../assets/icon-edit.svg';
import styles from '../workpackDrawer.module.less';
import { DashboardState } from '../../../models';
import { AircraftAuthenticationWrapper } from '../../_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';

interface ResolutionDetailsProps {
  release: any;
  poNum: string;
  noCard?: boolean;
  handleResolutionSave?: () => void;
  fromWorkpack?: boolean;
}

const defaultProps = {
  noCard: false,
  handleResolutionSave: () => {},
  fromWorkpack: false,
};

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 16px;
`;

const ModalHeader = styled.div`
  color: #313745;
  font-weight: 500;
  font-size: 18px;
`;

const Header = styled.span`
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-size: 12px;
  text-transform: ${({ edit }): string => (edit ? 'capitalize' : 'uppercase')};
  font-weight: ${({ edit }): string => (edit ? '500' : '400')};
  ${({ noCard }): FlattenSimpleInterpolation => {
    if (noCard) {
      return css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 160px;
      `;
    }
    return null;
  }}
  @media (max-width: 787px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 160px;
  }
`;

const ModalText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  margin-bottom: 16px;
`;

const StyledTextArea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
`;

const WarningText = styled.span`
  color: #f5222d;
  img {
    margin-right: 4px;
  }
`;

const StyledInput = styled.input`
  width: 150px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
`;

const SaveButtonWrapper = styled.div`
  margin-right: 16px;
`;

const StyledIcon = styled.img`
  margin-right: 4px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }): string => columns || '1fr 1fr 1fr'};
  row-gap: 20px;
  margin-bottom: 20px;
`;

const TextBox = styled.textarea`
  width: ${({ width }): string => width || 'auto'};
  height: ${({ height }): string => height || '80px'};
  margin-right: 24px;
  border-radius: 2px;
  border-color: rgba(36, 45, 65, 0.05);
  background-color: #f6f8fb;
  padding-left: 8px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ResolutionDetails: React.FC<ResolutionDetailsProps> = ({
  release,
  poNum,
  noCard,
  handleResolutionSave,
  fromWorkpack,
}) => {
  const [justificationRequired, setJustificationRequired] = useState(false);
  const [edit, setEdit] = useState(false);
  const [warning, setWarning] = useState(false);

  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [licenseNumber, setLicenceNumber] = useState(null);
  const [organisation, setOrganisation] = useState('');
  const [approvalNumber, setApprovalNumber] = useState(null);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [originalAttachments, setOriginalAttachments] = useState([]);
  const [justification, setJustification] = useState('');
  const [reporterFirstName, setReporterFirstName] = useState('');
  const [reporterLasttName, setReporterLasttName] = useState('');
  const [signatureData, setSignatureData] = useState('');
  const [signatureStatement, setSignatureStatement] = useState('');
  const [loading, setLoading] = useState(false);
  const [localReleaseDate, setLocalReleaseDate] = useState(null);
  const [localLimitations, setLocalLimitations] = useState(false);
  const [resolutionType, setResolutionType] = useState('');
  const [notes, setNotes] = useState('');
  const [partChanges, setPartChanges] = useState(null);

  const {
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const operatorId = aircraftMap.get(aircraftId)?.operator_id;
  const operatorSettings = userSettings?.details?.operators.find((op) => op.id === operatorId)?.operator_setting;
  const hidePart145Fields = aircraftMap.get(aircraftId)?.standard_fields?.show_part_145_fields?.enabled === false;

  const handleSaveResolution = async (): Promise<void> => {
    const releaseID = fromWorkpack ? release?.release?.id : release?.release?.release_id;
    const params = {
      id: releaseID,
      body: {
        first_name: firstName,
        last_name: lastName,
        licence_number: licenseNumber,
        company_name: organisation,
        approval_number: approvalNumber,
        attachments_attributes: localAttachments,
        version_justification: justification,
        date: localReleaseDate,
      },
    };
    setLoading(true);
    if (!fromWorkpack) {
      let formattedWorkType = 'inspected';
      if (resolutionType === 'Part Replaced') formattedWorkType = 'replaced';
      if (resolutionType === 'Other') formattedWorkType = 'other';
      const mxEventParams = {
        first_name: firstName,
        last_name: lastName,
        id: release?.release?.id,
        limitations: localLimitations,
        date: localReleaseDate,
        work_type: formattedWorkType,
        description: notes,
        part_changes: [partChanges],
      };
      await updateMxEventsDefect(mxEventParams);
    }
    await updateRelease(params);
    setLoading(false);
    if (handleResolutionSave) handleResolutionSave();
    setEdit(false);
  };

  const handleNameChange = (value: string): void => {
    setName(value);
    const splitName = value.split(' ');
    const last = splitName.pop();
    setFirstName(splitName.join(' '));
    setLastName(last);
  };

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const addAttachment = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const newAttachments = localAttachments?.concat(...base64Attachments);
      setLocalAttachments(newAttachments);
    }
  };

  const handleDeleteClick = ({ fileName, fileId }): void => {
    const newAttachmentsList = localAttachments.filter(
      (item) =>
        item?.id !== fileId ||
        (item?.attachment_file_name && item?.attachment_file_name !== fileName) ||
        // eslint-disable-next-line no-underscore-dangle
        item?._destroy,
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    setLocalAttachments(newAttachmentsList);
  };

  const handleJustification = (e): void => {
    setJustification(e.target.value);
    setWarning(false);
  };

  const handleResolutionTypeChange = (value: string) => {
    setResolutionType(value);
    if (value !== 'Part Replaced') setPartChanges([]);
  };

  const handlePartChange = (value, key: string): void => {
    const newPartChanges = _.cloneDeep(partChanges) || {};
    newPartChanges[key] = value;
    setPartChanges(newPartChanges);
  };

  const buildPartChangesSection = () => {
    const partOptions = ['part_number', 'part_number_on', 'serial_off', 'serial_on', 'batch'];
    return partOptions.map((item) => (
      <div className={styles.rowItem}>
        <Header edit={edit}>{item === 'part_number' ? 'Part Number Off' : item?.replace(/_/g, ' ')}</Header>
        {edit ? (
          <StyledInput value={partChanges?.[item]} onChange={(e): void => handlePartChange(e.target.value, item)} />
        ) : (
          <span>{partChanges?.[item]}</span>
        )}
      </div>
    ));
  };

  useEffect(() => {
    if (release?.release?.attachments?.length) {
      setLocalAttachments(release.release.attachments);
      setOriginalAttachments(release.release.attachments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (release) {
      setName(
        `${release.first_name || release.release.first_name} ${release?.last_name || release.release?.last_name}`,
      );
      setFirstName(release.first_name || release.release.first_name);
      setLastName(release.last_name || release.release.last_name);
      setLicenceNumber(release?.licence_number || release.release?.licence_number);
      setOrganisation(release?.company_name || release.release?.company_name);
      setApprovalNumber(release?.approval_number || release.release?.approval_number);
      setReporterFirstName(release?.reporter_first_name || release.release?.reporter_first_name);
      setReporterLasttName(release?.reporter_last_name || release.release?.reporter_last_name);
      setSignatureData(release?.signature_data || release.release?.signature_data);
      setSignatureStatement(release?.signature_statement || release.release?.signature_statement);
      setLocalAttachments(release?.attachments || release.release?.attachments);
      setLocalReleaseDate(release?.date ? moment.utc(release?.date) : moment.utc(release.release?.date));
      if (!fromWorkpack) {
        let displayWorkType = '-';
        if (release?.release?.work_type === 'inspected') displayWorkType = 'Inspected';
        if (release?.release?.work_type === 'replaced') displayWorkType = 'Part Replaced';
        if (release?.release?.work_type === 'other') displayWorkType = 'Other';
        setLocalLimitations(release?.release?.limitations);
        setNotes(release?.release?.description);
        setResolutionType(displayWorkType);
        setPartChanges(release?.release?.part_changes[0]);
      }
    }
  }, [fromWorkpack, release, userSettings]);

  return (
    <div className={noCard ? styles.noCard : styles.workpackWhiteBoxPad}>
      <Modal isOpen={justificationRequired} handleClose={(): void => setJustificationRequired(false)} width={420}>
        <div>
          <ModalHeader>{formatMessage({ id: 'text.justificationRequired' })}</ModalHeader>
          <ModalText>
            To make any edits, you must provide a justification for modifying the existing resolution.
          </ModalText>
        </div>
        <StyledTextArea type="text" onChange={handleJustification} cols={40} rows={5} />
        {warning ? (
          <div>
            <img src={errorIcon} alt="error icon" />
            <WarningText>{formatMessage({ id: 'text.justificationRequired' })}</WarningText>
          </div>
        ) : null}
        <ButtonWrapper>
          <SubmitButtonWrapper>
            <Button
              height="30px"
              onClick={(): void => {
                if (justification) {
                  setEdit(true);
                  setJustificationRequired(false);
                } else {
                  setWarning(true);
                }
              }}
            >
              {formatMessage({ id: 'text.save' })}
            </Button>
          </SubmitButtonWrapper>
          <Button height="30px" primary={false} onClick={(): void => setJustificationRequired(false)}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </ButtonWrapper>
      </Modal>
      {loading ? (
        <Loading loading={loading} contain />
      ) : (
        <div className={styles.columnNoMarg}>
          <div className={styles.resolutionHeaderWrapper}>
            <div className={styles.minorHeadingPad}> {formatMessage({ id: 'title.resolutionDetails' })}</div>
            {edit ? null : (
              <AircraftAuthenticationWrapper
                aircraftId={aircraftId}
                requiredResource={AircraftResource.DEFECT}
                requiredPermissionLevel={AircraftPermission.UPDATE}
              >
                <Button
                  primary={false}
                  onClick={(): void => setJustificationRequired(true)}
                  height="24px"
                  padding="0 12px"
                >
                  <StyledIcon src={pencilIcon} alt="pencil" />
                  {formatMessage({ id: 'form.button.editDetails' })}
                </Button>
              </AircraftAuthenticationWrapper>
            )}
          </div>
          <StyledGrid>
            <div className={styles.rowItem}>
              {edit ? (
                <DateTimePicker
                  dateTime={release?.date || release?.release?.date}
                  headerDate="Date"
                  headerTime="Time"
                  handleDateTimeChange={(value) => {
                    setLocalReleaseDate(value);
                  }}
                  noFuture
                />
              ) : (
                <>
                  <Header noCard edit={edit}>
                    Date Completed
                  </Header>
                  <span>{localReleaseDate ? moment.utc(localReleaseDate).format(userSettings?.dateFormat) : '-'}</span>
                </>
              )}
            </div>
            {!edit && (
              <div className={styles.rowItem}>
                <Header noCard edit={edit}>
                  Time Completed
                </Header>
                <span>{localReleaseDate ? moment.utc(localReleaseDate).format('HH:mm') : '-'}</span>
              </div>
            )}
            <div className={styles.rowItem}>
              <Header noCard edit={edit}>
                {operatorSettings?.engineer}
              </Header>
              {edit ? (
                <StyledInput value={name} type="string" onChange={(e): void => handleNameChange(e.target.value)} />
              ) : (
                <span>{name}</span>
              )}
            </div>
            <div className={styles.rowItem}>
              <Header noCard edit={edit}>
                {operatorSettings?.engineer_licence_number}
              </Header>
              {edit ? (
                <StyledInput
                  value={licenseNumber}
                  type="string"
                  onChange={(e): void => setLicenceNumber(e.target.value)}
                />
              ) : (
                <span>{licenseNumber || '-'}</span>
              )}
            </div>
            {hidePart145Fields ? null : (
              <>
                <div className={styles.rowItem}>
                  <Header noCard edit={edit}>
                    {`${operatorSettings?.part_145_name} Organisation`}
                  </Header>
                  {edit ? (
                    <StyledInput
                      value={organisation}
                      type="string"
                      onChange={(e): void => setOrganisation(e.target.value)}
                    />
                  ) : (
                    <span>{organisation || '-'}</span>
                  )}
                </div>
                <div className={styles.rowItem}>
                  <Header noCard edit={edit}>
                    {operatorSettings?.part_145_approval_number}
                  </Header>
                  {edit ? (
                    <StyledInput
                      value={approvalNumber}
                      type="string"
                      onChange={(e): void => setApprovalNumber(e.target.value)}
                    />
                  ) : (
                    <span>{approvalNumber || '-'}</span>
                  )}
                </div>
              </>
            )}
            <div className={styles.rowItem}>
              <Header noCard edit={edit}>
                WORKPACK REFERENCE
              </Header>
              {edit ? null : <span>{poNum || '-'}</span>}
            </div>
          </StyledGrid>
          {!fromWorkpack ? (
            <SectionWrapper>
              <Header noCard edit={edit}>
                {formatMessage({ id: 'title.resolutionType' })}
              </Header>
              {edit ? (
                <TFRadioInput
                  options={['Inspected', 'Part Replaced', 'Other']}
                  onChange={handleResolutionTypeChange}
                  label=""
                  id="ResolutionType"
                  value={resolutionType}
                />
              ) : (
                <span>{resolutionType}</span>
              )}
            </SectionWrapper>
          ) : null}
          {!fromWorkpack && resolutionType === 'Part Replaced' ? (
            <StyledGrid>{buildPartChangesSection()}</StyledGrid>
          ) : null}
          {!fromWorkpack ? (
            <SectionWrapper>
              <Header noCard edit={edit}>
                {formatMessage({ id: 'title.notes' })}
              </Header>
              {edit ? <TextBox value={notes} onChange={(e): void => setNotes(e.target.value)} /> : <span>{notes}</span>}
            </SectionWrapper>
          ) : null}
          <div className={styles.rowGridTwo}>
            {!fromWorkpack ? (
              <div className={styles.rowItem}>
                <Header noCard edit={edit}>
                  LIMITATIONS
                </Header>
                {edit ? (
                  <TextBox
                    value={localLimitations}
                    onChange={(e): void => setLocalLimitations(e.target.value)}
                    data-test="formElementLimitationsDetails"
                  />
                ) : (
                  <span>{localLimitations}</span>
                )}
              </div>
            ) : null}
            <div className={styles.rowItem}>
              <Header noCard edit={edit}>
                ATTACHMENTS
              </Header>
              <TFFilerViewer
                attachments={localAttachments}
                addAttachment={addAttachment}
                uploaderVisible={edit}
                handleDeleteClick={handleDeleteClick}
              />
            </div>
          </div>
          {edit ? (
            <ButtonRow>
              <SaveButtonWrapper>
                <Button height="30px" onClick={handleSaveResolution}>
                  Save
                </Button>
              </SaveButtonWrapper>
              <Button
                primary={false}
                onClick={(): void => {
                  setEdit(false);
                  setLocalAttachments(originalAttachments);
                }}
                height="30px"
              >
                Cancel
              </Button>
            </ButtonRow>
          ) : null}
          <div className={styles.rowUpperMarg}>
            <span>
              Resolved by:{' '}
              <span className={styles.name}>
                {reporterFirstName || firstName || '-'} {reporterLasttName || lastName || '-'}
              </span>
            </span>
            {signatureData ? (
              <img alt="signature" src={`data:image/png;base64, ${signatureData}`} className={styles.signatureImage} />
            ) : null}
          </div>
          {signatureStatement ? (
            <div className={styles.row}>
              <div className={styles.rowUpperMarg}>
                <span>{signatureStatement}.</span>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

ResolutionDetails.defaultProps = defaultProps;

export default ResolutionDetails;
