import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, message } from 'antd';
import { approveMaintenance, rejectMaintenance } from '../../../../services/api';
import ModalFiltersDrawer from './ModalFiltersDrawer';
import styles from './MXDraftQueue.module.less';

const ListTitle = ({
  aircraft,
  mxItems,
  filters,
  title,
  onFilterChange,
  onFiltersClear,
  intl: { formatMessage },
  onSuccess,
  handleLoadingChange,
  submitting,
}) => {
  const ids = mxItems.map((item) => item.id);
  const itemNumber = mxItems.length;
  const acceptAllItem = async () => {
    handleLoadingChange();
    try {
      const response = await approveMaintenance({ ids });

      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.approveMultiItemsSuccess' }, { field: itemNumber }));
        onSuccess();
      } else {
        message.error(formatMessage({ id: 'message.approveItemsError' }));
        handleLoadingChange();
      }
    } catch (error) {
      message.error(formatMessage({ id: 'message.approveItemsError' }));
      handleLoadingChange();
    }
  };
  const rejectAllItem = async () => {
    handleLoadingChange();
    try {
      const response = await rejectMaintenance({ ids });

      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.rejectMultiItemsSuccess' }, { field: itemNumber }));
        onSuccess();
      } else {
        message.error(formatMessage({ id: 'message.rejectItemsError' }));
        handleLoadingChange();
      }
    } catch (error) {
      message.error(formatMessage({ id: 'message.rejectItemsError' }));
      handleLoadingChange();
    }
  };
  return (
    <>
      <div className={styles.listTitle}>
        <div className={styles.title} data-test="title">
          {title}
        </div>
        <div className={styles.buttonWrapper}>
          {mxItems && mxItems.length > 1 ? (
            <div className={styles.allButtons}>
              <Button
                type="link"
                onClick={() => acceptAllItem()}
                className={styles.acceptButton}
                data-test="acceptButton"
                disabled={submitting}
              >
                {formatMessage({ id: 'text.acceptAll' })}
              </Button>
              <Button
                type="link"
                onClick={() => rejectAllItem()}
                className={styles.rejectButton}
                data-test="rejectButton"
                disabled={submitting}
              >
                {formatMessage({ id: 'text.rejectAll' })}
              </Button>
            </div>
          ) : null}
          <div className={styles.filtersDrawerWrapper}>
            <ModalFiltersDrawer
              aircraft={aircraft}
              mxItems={mxItems}
              filters={filters}
              onFilterChange={(filterKey, groupKey) => onFilterChange(filterKey, groupKey)}
              onFiltersClear={(groupKey) => onFiltersClear(groupKey)}
            />
          </div>
        </div>
      </div>
      <div className={styles.listTitleMobile}>
        <div className={styles.titleWrapper}>
          <div className={styles.title} data-test="title">
            {title}
            {` (${mxItems.length})`}
          </div>
          <div className={styles.filtersDrawerWrapper}>
            <ModalFiltersDrawer
              aircraft={aircraft}
              mxItems={mxItems}
              filters={filters}
              onFilterChange={(filterKey, groupKey) => onFilterChange(filterKey, groupKey)}
              onFiltersClear={(groupKey) => onFiltersClear(groupKey)}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.allButtons}>
            <Button
              type="link"
              onClick={() => acceptAllItem()}
              className={styles.acceptButton}
              data-test="acceptButton"
              disabled={submitting}
            >
              {formatMessage({ id: 'text.acceptAll' })}
            </Button>
            <Button
              type="link"
              onClick={() => rejectAllItem()}
              className={styles.rejectButton}
              data-test="rejectButton"
              disabled={submitting}
            >
              {formatMessage({ id: 'text.rejectAll' })}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

ListTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  aircraft: PropTypes.object.isRequired,
  mxItems: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  handleLoadingChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default injectIntl(ListTitle);
