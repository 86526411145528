import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import getOperatorSetting from '../../utils/getOperatorSetting';
import { Flight } from '../../models/flights';
import { SectionHeader } from './FlightDrawer';
import Deicing from './Deicing';
import OilUplifts from './OilUplifts';
import IDGUplifts from './IDGUplifts';
import BUGUplifts from './BUGUplifts';
import HydraulicUplifts from './HydraulicUplifts';

interface UpliftsSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const UpliftsSection: React.FC<UpliftsSectionProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const displayDeIcing = aircraftMap.get(id)?.standard_fields?.de_icing?.enabled;
  const renameDeIcing = aircraftMap.get(id)?.standard_fields?.de_icing?.name_override || 'Deicing';
  const displayIDGFields = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.enabled;
  const displayBUGFields = aircraftMap.get(id)?.standard_fields?.back_up_generator?.enabled;
  const sectorNameOverride = getOperatorSetting('sector_name', id) || formatMessage({ id: 'text.sector' });

  const displayOilUplifts = flight?.fluid_uplifts_attributes
    ? flight?.fluid_uplifts_attributes?.filter((item) => item?.fluid_type === 'oil')?.length
    : flight?.fluid_uplifts?.filter((item) => item?.fluid_type === 'oil')?.length;
  const displayHydraulicUplifts = flight?.fluid_uplifts_attributes
    ? flight?.fluid_uplifts_attributes?.filter((item) => item?.fluid_type === 'hydraulic')?.length
    : flight?.fluid_uplifts?.filter((item) => item?.fluid_type === 'hydraulic')?.length;

  let displayDeicingSection = true;
  if (!flight?.deice_events?.length && mode === 'view') displayDeicingSection = false;
  if (displayDeIcing === false) displayDeicingSection = false;

  return (
    <SectionWrapper data-testid="FlightDrawer-UpliftsSection">
      <HeaderWrapper>
        <SectionHeader marginLeft="20px" reducedBottomMargin data-testid="UpliftsSection-Header">
          {formatMessage({ id: 'text.sectorUplifts' }, { sectorNameOverride })}
        </SectionHeader>
      </HeaderWrapper>
      {displayDeicingSection ? (
        <Deicing flight={flight} updateFlightData={updateFlightData} deicingTitle={renameDeIcing} />
      ) : null}
      {displayOilUplifts || mode !== 'view' ? <OilUplifts flight={flight} updateFlightData={updateFlightData} /> : null}
      {displayIDGFields ? <IDGUplifts flight={flight} updateFlightData={updateFlightData} /> : null}
      {displayBUGFields ? <BUGUplifts flight={flight} updateFlightData={updateFlightData} /> : null}
      {displayHydraulicUplifts || mode !== 'view' ? (
        <HydraulicUplifts flight={flight} updateFlightData={updateFlightData} />
      ) : null}
    </SectionWrapper>
  );
};

export default UpliftsSection;
