/* eslint-disable react/prop-types */
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { add } from '../models/userSettings/actions';

export const UsersnapContext = React.createContext(null);

export const UsersnapProvider = ({ initParams = {}, submitDate, completeDate, dismissDate, userDetails, children }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);
  const dispatch = useDispatch();

  const handleWidgetClose = () => {
    const formData = new FormData();
    formData.append('survey_submitted_date', moment().add(dismissDate || 45, 'days'));
    dispatch(add({ payload: { form: formData, type: 'usersnap' } }));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('survey_submitted_date', moment().add(completeDate || 90, 'days'));
    dispatch(add({ payload: { form: formData, type: 'usersnap' } }));
  };

  useEffect(() => {
    let localUsersnapApi = null;
    // eslint-disable-next-line func-names
    window.onUsersnapCXLoad = function (api) {
      api.init({
        ...initParams,
        custom: {
          userId: userDetails?.userId,
          name: userDetails?.name,
        },
        user: {
          userId: userDetails?.userId,
          email: userDetails?.email,
        },
      });
      if ((submitDate && moment().isAfter(moment(submitDate))) || !submitDate) {
        api.logEvent('load_nps_survey');
      }

      api.on('submit', handleSubmit);
      api.on('close', handleWidgetClose);
      localUsersnapApi = api;
      setUsersnapApi(api);
    };
    const script = document.createElement('script');
    script.defer = 1;
    script.src = `https://widget.usersnap.com/global/load/fa414699-b816-4514-9501-48de676e31a7?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      if (localUsersnapApi) {
        localUsersnapApi.destroy();
      }
      script.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (completeDate && dismissDate) {
    return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>;
  }
  return <div>{children}</div>;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
