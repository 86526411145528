import React from 'react';
import styled, { css } from 'styled-components';

interface TFTagProps {
  colour?: string;
  margin?: string;
  dataTest?: string;
  dataTestId?: string;
  type?: string;
}

const Wrapper = styled.div`
  padding: 0 7px;
  width: fit-content;
  height: fit-content;
  margin: ${({ margin }): string => (margin ? `${margin}` : '0')};
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  line-height: 20px;
  ${(props): any => {
    if (props.colour === 'blue')
      return css`
        color: ${props.theme.colours.blue};
        background: ${props.theme.colours.lightBlueLight};
        border: solid 1px ${props.theme.colours.lightBlue};
      `;
    if (props.colour === 'green')
      return css`
        color: ${props.theme.colours.greenTag};
        background: ${props.theme.colours.greenTagBackground};
        border: solid 1px ${props.theme.colours.greenTagBorder};
      `;
    if (props.colour === 'red')
      return css`
        color: ${props.theme.colours.redTag};
        background: ${props.theme.colours.redBackgroundTag};
        border: solid 1px ${props.theme.colours.redBorderTag};
      `;
    if (props.colour === 'amber')
      return css`
        color: ${props.theme.colours.amberTag};
        background: ${props.theme.colours.amberTagBackground};
        border: solid 1px ${props.theme.colours.amberTagBorder};
      `;
    if (props.colour === 'cyan')
      return css`
        color: ${props.theme.colours.cyanTag};
        background: ${props.theme.colours.cyanTagBackground};
        border: solid 1px ${props.theme.colours.cyanTagBoarder};
      `;

    // colours based on type
    if (props.type === 'DEF')
      return css`
        color: ${props.theme.colours.white};
        background: ${props.theme.colours.defectBlue};
        border-radius: 12px;
        line-height: 1.5;
        padding: 0 8px;
        font-weight: 600;
        font-size: 11px;
        margin-top: 4px;
      `;
    if (props.type === 'SCH')
      return css`
        color: ${props.theme.colours.white};
        background: ${props.theme.colours.scheduledBlue};
        border-radius: 12px;
        line-height: 1.5;
        padding: 0 8px;
        font-weight: 600;
        font-size: 11px;
        margin-top: 4px;
      `;
    if (props.type === 'OOP')
      return css`
        color: ${props.theme.colours.white};
        background: ${props.theme.colours.oopBlue};
        border-radius: 12px;
        line-height: 1.5;
        padding: 0 8px;
        font-weight: 600;
        font-size: 11px;
        margin-top: 4px;
      `;
    if (props.type === 'LLP')
      return css`
        color: ${props.theme.colours.white};
        background: ${props.theme.colours.llpBlue};
        border-radius: 12px;
        line-height: 1.5;
        padding: 0 8px;
        font-weight: 600;
        font-size: 11px;
        margin-top: 4px;
      `;
    if (props.type === 'Package')
      return css`
        color: ${props.theme.colours.white};
        background: ${props.theme.colours.pkgBlue};
        border-radius: 12px;
        line-height: 1.5;
        padding: 0 8px;
        font-weight: 600;
        font-size: 11px;
        margin-top: 4px;
      `;
    // if (props.colour === 'grey')
    //   return css`
    //     color: ${props.theme.colours.blue};
    //     background: ${props.theme.colours.lightBlueLight};
    //     border: solid 1px ${props.theme.colours.lightBlue};
    //   `;
    return css`
      color: ${props.theme.colours.gray};
      background: ${props.theme.colours.blackExtraLight};
      border: solid 1px ${props.theme.colours.borderGray};
    `;
  }}
`;

const TFTag: React.FC<TFTagProps> = ({ colour, margin, dataTest, dataTestId, type, children }) => {
  return (
    <Wrapper colour={colour} margin={margin} data-test={dataTest} data-testid={dataTestId} type={type}>
      {children}
    </Wrapper>
  );
};

export default TFTag;
