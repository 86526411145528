import { Table } from '@arcflight/tf-component-library';
import React, { ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTable, useSortBy, usePagination } from 'react-table';
import { DashboardState } from '../../models';
import { BaseAircraft } from '../../models/aircraft';
import { changeDrawerContent } from '../../models/drawer';
import { Flight } from '../../models/flights';
import { IntermittentFault } from '../../models/intermittentFaults';
import IntermittentFaultsDrawer from '../../pages/IntermittentFaults/IntermittentFaultsDrawer';
import arrowIcon from '../../assets/icon-arrow.svg';

interface FlightIntermittentFaultsDrawerProps {
  intermittentFaults: IntermittentFault[];
  aircraft: BaseAircraft;
  flight: Flight;
}

const TableWrapper = styled.div`
  padding: 0 1px;
`;

const ArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FlightIntermittentFaultsDrawer: React.FC<FlightIntermittentFaultsDrawerProps> = ({
  intermittentFaults,
  aircraft,
  flight,
}) => {
  const dispatch = useDispatch();
  const { userSettings } = useSelector((state: DashboardState) => state);

  const getIntermittentFaultName = (singular): string => {
    let name = 'Intermittent Faults';
    const currentOrgId = aircraft?.operator_id;
    if (userSettings.details) {
      const currentOrg = userSettings.details.operators.find((o) => o.id === currentOrgId);
      if (currentOrg) {
        name = singular
          ? currentOrg.operator_setting?.intermittent_fault_name_singular
          : currentOrg.operator_setting?.intermittent_fault_name_plural;
      }
    }
    return name;
  };

  const data = useMemo(() => intermittentFaults, [intermittentFaults]);

  const columns = useMemo(
    () => [
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({ value }): string => value || '-',
      },
      {
        width: 50,
        id: 'arrow',
        Cell: (): ReactNode => (
          <ArrowWrapper>
            <img src={arrowIcon} alt="arrow icon" />
          </ArrowWrapper>
        ),
      },
    ],
    [],
  );

  const handleRowClick = (fault): void => {
    dispatch(
      changeDrawerContent({
        payload: {
          content: (
            <IntermittentFaultsDrawer
              fault={fault}
              intermittentFaultName={getIntermittentFaultName(true)}
              sectorFault
              flightId={flight?.id}
              convertable={false}
            />
          ),
          backButton: true,
        },
      }),
    );
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );
  return (
    <TableWrapper>
      <Table tableInstance={tableInstance} handleRowClick={(row): void => handleRowClick(row.original)} />
    </TableWrapper>
  );
};

export default FlightIntermittentFaultsDrawer;
