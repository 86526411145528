import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import TFTable from '../../components/TFTable/TFTable';
import { AircraftResource } from '../../models/aircraft';
import { DashboardState } from '../../models';
import { FRAT } from '../../models/frats';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import MainFRATDrawer from './MainFRATDrawer';
import useSetFRATDefault from './useSetFRATDefault';
import useDeleteFRAT from './useDeleteFRAT';

interface AllFRATTableProps {
  setSelectedFRAT: (value: string) => void;
  selectedOperator: { title: string; value: string | null };
  FRATData: FRAT[];
}

const AllFRATTable: React.FC<AllFRATTableProps> = ({ setSelectedFRAT, selectedOperator, FRATData }) => {
  const { userSettings } = useSelector((state: DashboardState) => state);
  const dispatch = useDispatch();

  const setDefault = useSetFRATDefault();

  const deleteFRAT = useDeleteFRAT(selectedOperator?.value);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Default',
        accessor: 'default',
        id: 'default',
        Cell: ({ value }): string => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Owner',
        accessor: 'editable',
        id: 'editable',
        Cell: ({ value }): string => {
          return value ? selectedOperator?.title : 'Trustflight';
        },
      },
      {
        Header: 'No. of aircraft',
        accessor: 'aircraft_count',
      },
      {
        Header: 'Last edited',
        accessor: 'updated_at',
        Cell: ({ value }): string => {
          return value ? moment.utc(value).format(userSettings.dateFormat) : '-';
        },
      },
      {
        Header: '',
        accessor: 'id',
        id: 'options',
        Cell: ({ value, row }): ReactElement => {
          const handleViewClick = (): void => {
            setSelectedFRAT(value);
          };

          const handleEditClick = (): void => {
            dispatch(changeDrawerVisibility({ payload: true }));
            dispatch(changeDrawerMode({ payload: 'edit' }));
            dispatch(
              changeDrawerContent({
                payload: {
                  content: <MainFRATDrawer selectedOperator={selectedOperator} selectedFRAT={row.original} />,
                },
              }),
            );
          };

          // categories not available without a new network call so disabled for now
          // const hasCategories = row.original.categories && row.original.categories.length > 0;
          return (
            <AuthDropdownMenu
              options={{
                read: true,
                update: row.original.editable,
                delete: !row.original.default && row.original.editable,
                custom: !row.original.default,
              }}
              forTable
              handleDelete={() => deleteFRAT.mutate({ id: row.original.id })}
              viewCallback={handleViewClick}
              editCallback={handleEditClick}
              resource={AircraftResource.AIRCRAFT}
              customCallback={() => setDefault.mutate({ id: row.original.id })}
              customText="Make default"
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOperator],
  );

  return (
    <TFTable
      columns={columns}
      data={FRATData || []}
      handleRowClick={(id): void => setSelectedFRAT(id)}
      total={10}
      pageIndex={0}
      pageSize={10}
      resetToOne={false}
      onPaginationChange={(): void => null}
      onSortChange={(): void => null}
      hiddenColumns={[]}
    />
  );
};

export default AllFRATTable;
