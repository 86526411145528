import React from 'react';
import styled from 'styled-components';

interface GridWrapperProps {
  columns?: string;
  rows?: string;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  columnGap?: number;
  rowGap?: number;
}

const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }): string => (columns ? `${columns}` : 'none')};
  grid-template-rows: ${({ rows }): string => (rows ? `${rows}` : 'none')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}px` : '0')};
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}px` : '0')};
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}px` : '0')};
  column-gap: ${({ columnGap }): string => (columnGap ? `${columnGap}px` : 'none')};
  row-gap: ${({ rowGap }): string => (rowGap ? `${rowGap}px` : 'none')};
  @media (max-width: 451px) {
    row-gap: 20px;
  }
`;

const GridWrapper: React.FC<GridWrapperProps> = ({
  children,
  columns,
  rows,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  columnGap,
  rowGap,
}) => {
  return (
    <StyledGridWrapper
      columns={columns}
      rows={rows}
      marginRight={marginRight}
      merginLeft={marginLeft}
      marginTop={marginTop}
      marginBottom={marginBottom}
      columnGap={columnGap}
      rowGap={rowGap}
    >
      {children}
    </StyledGridWrapper>
  );
};

export default GridWrapper;
