import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.less';

export default class ImageFile extends PureComponent {
  state = {
    url: '',
  };

  static propTypes = {
    alt: PropTypes.string,
    displayCoordinates: PropTypes.object,
    file: PropTypes.object.isRequired,
    onLoad: PropTypes.func,
    width: PropTypes.number.isRequired,
  };

  static defaultProps = {
    onLoad: () => {
      // placeholder function
    },
    alt: '',
    displayCoordinates: undefined,
  };

  componentDidMount() {
    const { file } = this.props;
    if (file) {
      this.updateURL();
    }
  }

  componentDidUpdate(prevProps) {
    const { file } = this.props;
    const { url } = this.state;
    if (file !== prevProps.file) {
      URL.revokeObjectURL(url);
      this.updateURL();
    }
  }

  componentWillUnmount() {
    const { url } = this.state;
    URL.revokeObjectURL(url);
  }

  updateURL() {
    const { file } = this.props;
    this.setState({ url: URL.createObjectURL(file) });
  }

  render() {
    const { alt, displayCoordinates, onLoad, width } = this.props;
    const { url } = this.state;
    return (
      <div style={displayCoordinates ? { position: 'relative' } : null}>
        <img
          className={styles.imageFile}
          src={url}
          style={displayCoordinates ? { width, position: 'absolute' } : { width }}
          alt={alt}
          onLoad={onLoad}
        />
        {displayCoordinates}
      </div>
    );
  }
}
