/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import closeIcon from '../../assets/new-close-icon-mobile.svg';
import styles from './SlidingDrawer.module.less';

const SlidingDrawer = ({
  toggleDrawer,
  children,
  filterDrawer = false,
  bannerVisible = false,
  unsavedData = false,
  reCloseDrawer = false,
}): JSX.Element => {
  const drawerRef = useRef<HTMLInputElement>();
  const bgRef = useRef<HTMLInputElement>();

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevBannerVisibleState = usePrevious(bannerVisible);

  useEffect(() => {
    const currentRef = drawerRef.current;
    if (currentRef) {
      setTimeout(() => {
        if (drawerRef.current) {
          if (!bannerVisible && prevBannerVisibleState) {
            drawerRef.current.classList.add(styles.drawerAlreadyOut);
          } else if (filterDrawer) {
            drawerRef.current.classList.add(
              bannerVisible ? styles.drawerSlideOutFiltersBanVis : styles.drawerSlideOutFilters,
            );
          } else {
            drawerRef.current.classList.add(bannerVisible ? styles.drawerSlideOutBanVis : styles.drawerSlideOut);
          }
        }
        document.querySelector('body').setAttribute('style', 'overflow: hidden');
      }, 50);
    }
  }, [drawerRef, bannerVisible]);

  const closeDrawer = (): void => {
    if (!unsavedData) {
      if (drawerRef.current) {
        if (filterDrawer) {
          drawerRef.current.classList.remove(
            bannerVisible ? styles.drawerSlideOutFiltersBanVis : styles.drawerSlideOutFilters,
          );
          drawerRef.current.classList.remove(styles.drawerAlreadyOut);
          drawerRef.current.classList.add(
            bannerVisible ? styles.drawerSlideInFiltersBanVis : styles.drawerSlideInFilters,
          );
        } else {
          drawerRef.current.classList.remove(bannerVisible ? styles.drawerSlideOutBanVis : styles.drawerSlideOut);
          drawerRef.current.classList.remove(styles.drawerAlreadyOut);
          drawerRef.current.classList.add(bannerVisible ? styles.drawerSlideInBanVis : styles.drawerSlideIn);
        }
      }
      if (bgRef.current) {
        bgRef.current.classList.add(bannerVisible ? styles.drawerWrapperBanVis : styles.bgFadeOut);
      }
      bgRef.current.classList.add(bannerVisible ? styles.drawerWrapperBanVis : styles.bgFadeOut);
      document.querySelector('body').setAttribute('style', 'overflow: scroll');
    }
    setTimeout(() => {
      toggleDrawer();
    }, 150);
  };

  useEffect(() => {
    if (reCloseDrawer) {
      closeDrawer();
    }
  }, [reCloseDrawer]);

  const pageContainer = document.getElementById('root');

  return ReactDOM.createPortal(
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(): void => null}
      className={bannerVisible ? styles.drawerBgBanVis : styles.drawerBg}
      ref={bgRef}
      onClick={closeDrawer}
    >
      <div className={bannerVisible ? styles.drawerWrapperBanVis : styles.drawerWrapper} ref={drawerRef}>
        <button
          className={filterDrawer ? styles.drawerClosrButtonFilters : styles.drawerCloseButton}
          type="button"
          onClick={(e): void => {
            e.stopPropagation();
            closeDrawer();
          }}
        >
          <img src={closeIcon} alt="close icon" id="drawerCloseButton" className={styles.closeIcon} />
        </button>
        <div
          role="button"
          onClick={(e): void => e.stopPropagation()}
          onKeyDown={(): void => null}
          tabIndex={0}
          className={styles.childrenOuterWrapper}
        >
          <div className={styles.childrenWrapper}>{children}</div>
        </div>
      </div>
    </div>,
    pageContainer,
  );
};

export default SlidingDrawer;
