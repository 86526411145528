import React from 'react';
import styled from 'styled-components';
import tick from '../../assets/icon-tick.svg';

interface TFCheckBoxProps {
  value: boolean;
  title?: string;
  marginRight?: number;
  onChange: (value: boolean) => void;
  checked: boolean;
}

const BoxWrapper = styled.div`
  height: 40px;
  width: fit-content;
  border-radius: 2px;
  border: ${({ checked }): string =>
    checked ? 'solid 1px rgba(18, 111, 214, 0.7)' : 'solid 1px rgba(36, 45, 65, 0.1)'};
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '')};
  background-color: ${({ checked }): string => (checked ? 'rgba(18, 111, 214, 0.1)' : '#fff')};
`;

const Box = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 4px;
  border: ${({ checked }): string => (checked ? 'solid 1px #126fd6' : 'solid 1px rgba(36, 45, 65, 0.2)')};
  background-color: ${({ checked }): string => (checked ? '#126fd6' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:focus {
    outline: none;
  }
`;

const TFCheckBox: React.FC<TFCheckBoxProps> = ({ value, title, marginRight, onChange, checked }) => {
  return (
    <BoxWrapper marginRight={marginRight} checked={checked}>
      <Box checked={checked} onClick={(): void => onChange(value)}>
        {checked ? <img src={tick} alt="tick" /> : null}
      </Box>
      {title || null}
    </BoxWrapper>
  );
};

export default TFCheckBox;
