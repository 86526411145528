import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trip } from '../../models/trips';
import EmptyStateAttachments from '../../assets/emptyState/empty-state-upload-file.svg';
import { SectionHeader } from '../FlightDrawer/FlightDrawer';
import FileViewer from '../TFFileViewer/fileViewer';

interface TripAttachmentsProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any) => void;
  resetAttachments: boolean;
  setResetAttachments: (value: boolean) => void;
}

const EmptyStateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TripAttachments: React.FC<TripAttachmentsProps> = ({
  trip,
  editable,
  updateTripData,
  resetAttachments,
  setResetAttachments,
}) => {
  const [attachments, setAttachments] = useState([]);

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const newAttachments = attachments?.concat(...base64Attachments);
      setAttachments(newAttachments);
      updateTripData('attachments', newAttachments);
    }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList = attachments.filter(
      (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    setAttachments(newAttachmentsList);
    updateTripData('attachments', newAttachmentsList);
  };

  useEffect(() => {
    if (resetAttachments) {
      setAttachments(trip?.attachments);
      setResetAttachments(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAttachments]);

  useEffect(() => {
    if (trip && trip?.attachments) {
      setAttachments(trip.attachments);
    }
  }, [trip]);

  return (
    <>
      <SectionHeader data-testid="TripAttachments-Header">Attachments</SectionHeader>
      <div>
        {editable || attachments.length ? (
          <div>
            <FileViewer
              attachments={attachments}
              handleDeleteClick={handleDelete}
              addAttachment={handleAttachmentUpload}
              uploaderVisible={editable}
              allowDelete={editable}
            />
          </div>
        ) : (
          <EmptyStateWrapper data-testid="TripAttachments-EmptyState">
            <img src={EmptyStateAttachments} alt="no defects" />
            <div>No attachments on this flight</div>
          </EmptyStateWrapper>
        )}
      </div>
    </>
  );
};

export default TripAttachments;
