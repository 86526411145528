import React from 'react';
import styled from 'styled-components';

interface FlexWrapperProps {
  column?: boolean;
  height?: number;
  width?: string;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  justifyContent?: string;
  alignItems?: string;
  position?: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  identifier?: string;
  colSpan?: number;
  flexShrink?: string;
  zIndex?: string;
  padding?: string;
  flexWrap?: string;
}

const StyledFlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ column }): string => (column ? 'column' : 'row')};
  height: ${({ height }): string => (height ? `${height}px` : 'auto')};
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}px` : '0')};
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}px` : '0')};
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}px` : '0')};
  justify-content: ${({ justifyContent }): string => (justifyContent ? `${justifyContent}` : 'normal')};
  align-items: ${({ alignItems }): string => (alignItems ? `${alignItems}` : 'normal')};
  position: ${({ position }): string => (position ? `${position}` : 'relative')};
  top: ${({ top }): string => (top ? `${top}px` : 'unset')};
  bottom: ${({ bottom }): string => (bottom ? `${bottom}px` : 'unset')};
  left: ${({ left }): string => (left ? `${left}px` : 'unset')};
  right: ${({ right }): string => (right ? `${right}px` : 'unset')};
  grid-column: ${({ colSpan }): string => (colSpan ? `span ${colSpan}` : '')};
  flex-shrink: ${({ flexShrink }): number => (flexShrink === '0' ? 0 : 1)};
  flex-wrap: ${({ flexWrap }): string => (flexWrap ? `${flexWrap}` : 'unset')};
  z-index: ${({ zIndex }): string => (zIndex ? `${zIndex}` : 'unset')};
  padding: ${({ padding }): string => (padding ? `${padding}` : 'unset')};
`;

const FlexWrapper: React.FC<FlexWrapperProps> = ({
  children,
  column,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  justifyContent,
  alignItems,
  position,
  top,
  bottom,
  left,
  right,
  identifier,
  height,
  width,
  colSpan,
  flexShrink,
  zIndex,
  padding,
  flexWrap,
}) => {
  return (
    <StyledFlexWrapper
      column={column}
      marginRight={marginRight}
      marginLeft={marginLeft}
      marginTop={marginTop}
      marginBottom={marginBottom}
      justifyContent={justifyContent}
      alignItems={alignItems}
      position={position}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      colSpan={colSpan}
      height={height}
      width={width}
      flexShrink={flexShrink}
      zIndex={zIndex}
      padding={padding}
      flexWrap={flexWrap}
      data-testid={`FlexWrapper--${identifier}`}
    >
      {children}
    </StyledFlexWrapper>
  );
};

export default FlexWrapper;
