const customFieldFilterModel = () => {
  return [
    {
      groupLabel: 'Required',
      filters: [
        {
          label: 'Yes',
          key: 'required',
          value: 'true',
          count: null,
          filterFunction: (item): boolean => item.required === true,
        },
        {
          label: 'No',
          key: 'required',
          value: 'false',
          count: null,
          filterFunction: (item): boolean => item.required === false,
        },
      ],
    },
    {
      groupLabel: 'Type',
      filters: [
        {
          label: 'Number',
          key: 'type',
          value: 'number',
          count: null,
          filterFunction: (item): boolean => item.type === 'number',
        },
        {
          label: 'Text',
          key: 'type',
          value: 'text',
          count: null,
          filterFunction: (item): boolean => item.type === 'text',
        },
        {
          label: 'Counter',
          key: 'type',
          value: 'counter',
          count: null,
          filterFunction: (item): boolean => item.type === 'counter',
        },
        {
          label: 'String',
          key: 'type',
          value: 'string',
          count: null,
          filterFunction: (item): boolean => item.type === 'string',
        },
        {
          label: 'Boolean',
          key: 'type',
          value: 'boolean',
          count: null,
          filterFunction: (item): boolean => item.type === 'boolean',
        },
        {
          label: 'Attachment',
          key: 'type',
          value: 'attachment',
          count: null,
          filterFunction: (item): boolean => item.type === 'attachment',
        },
        {
          label: 'Date',
          key: 'type',
          value: 'date',
          count: null,
          filterFunction: (item): boolean => item.type === 'date',
        },
        {
          label: 'Selector',
          key: 'type',
          value: 'selector',
          count: null,
          filterFunction: (item): boolean => item.type === 'selector',
        },
        {
          label: 'Duration',
          key: 'type',
          value: 'duration',
          count: null,
          filterFunction: (item): boolean => item.type === 'duration',
        },
      ],
    },
    {
      groupLabel: 'Location',
      filters: [
        {
          label: 'Preflight',
          key: 'view',
          value: 'preflight',
          count: null,
          filterFunction: (item): boolean => item.view === 'preflight',
        },
        {
          label: 'Postflight',
          key: 'view',
          value: 'postflight',
          count: null,
          filterFunction: (item): boolean => item.view === 'postflight',
        },
        {
          label: 'Trip',
          key: 'view',
          value: 'trip',
          count: null,
          filterFunction: (item): boolean => item.view === 'trip',
        },
      ],
    },
  ];
};

export default customFieldFilterModel;
