import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import TFSelect from '../TFSelect/TFSelect';
import { DashboardState } from '../../models';
import servers from '../../utils/servers';
import { Flight } from '../../models/flights';
import CustomFieldSection from '../CustomFieldSection/CustomFieldSection';
import HoursMinsInput from './HoursMinsInput';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';

interface PostFlightCustomDataSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  column-gap: 20px;
`;

const StyledInput = styled.input`
  width: ${({ width }): string => (width ? `${width}` : '110px')};
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const SignatureSection = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 20px;
`;

const StyledImg = styled.img`
  width: 240px;
  margin-left: 12px;
`;

const JustificationDiv = styled.div`
  grid-column: ${({ noSpan }): string => (noSpan ? 'span 1' : 'span 2')};
`;

const PostFlightCustomDataSection: React.FC<PostFlightCustomDataSectionProps> = ({ flight, updateFlightData }) => {
  const isMobile = window.innerWidth < 451;
  const { formatMessage } = useIntl();

  const [APUHours, setAPUHours] = useState(null);
  const [landings, setLandings] = useState(null);
  const [maxFL, setMaxFL] = useState(null);
  const [pressurisedCycles, setPressurisedCycles] = useState(false);
  const [APUFormat, setAPUFormat] = useState('hours');
  const [currentAircraft, setCurrentAircraft] = useState(null);
  const [actualFuelBurn, setActualFuelBurn] = useState(null);
  const [fuelBurnJustification, setFuelBurnJustification] = useState(null);
  const [justificationVisible, setJustificationVisible] = useState(false);

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const displayMaxFlightLevel = aircraftMap.get(id)?.standard_fields?.max_fl?.enabled;
  const maxFlightRename = aircraftMap.get(id)?.standard_fields?.max_fl?.name_override;
  const displayPressurisedCycle = aircraftMap.get(id)?.standard_fields?.pressurised_cycle?.enabled;
  const pressurisedCycleRename = aircraftMap.get(id)?.standard_fields?.pressurised_cycle?.name_override;

  const hideEngineCyclesFields = aircraftMap.get(id)?.app_settings?.hide_engine_cycles_fields;
  const hideAirframeCyclesFields = aircraftMap.get(id)?.app_settings?.hide_airframe_cycles_fields;
  const showFuelBurn = aircraftMap.get(id)?.standard_fields?.show_fuel_burn?.enabled;
  const fuelBurnPercentage = aircraftMap.get(id)?.standard_fields?.fuel_burn_percentage || 0.1;
  const showAPUTime = aircraftMap.get(id)?.standard_fields?.apu?.enabled
    ? aircraftMap.get(id)?.standard_fields?.apu.enabled
    : true;

  const engineCount = aircraftMap.get(id)?.aircraft_type?.engine_count;

  const currentOperator = userSettings?.details?.operators?.find((operator) => {
    return operator.id === aircraftMap.get(id)?.operator_id;
  });

  const handleInputChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (parseInt(value, 10) < 0) {
      message.error('Input must be positive');
      newValue = '';
    }
    if (type === 'landings') {
      setLandings(newValue);
      updateFlightData([{ value: parseInt(newValue, 10), key: 'landings_count' }]);
    }
    if (type === 'fl') {
      setMaxFL(newValue);
      updateFlightData([{ value: parseInt(newValue, 10), key: 'max_flight_level' }]);
    }
    if (type === 'actualFuel') {
      setActualFuelBurn(newValue);
      updateFlightData([{ value: parseInt(newValue, 10), key: 'actual_fuel_burn' }]);
    }
  };

  const handleJustificationChange = (value: string): void => {
    setFuelBurnJustification(value);
  };

  const sendJustification = (): void => {
    updateFlightData([{ value: fuelBurnJustification, key: 'fuel_burn_justification' }]);
  };

  const handleAPUHoursChange = (value: string): void => {
    let newValue = value;
    if (value) {
      const hoursValue = value?.split('.')[0];
      const minsValue = value?.split('.')[1];
      if (!hoursValue) {
        newValue = `0.${minsValue}`;
      }
      if (minsValue?.length > 2) {
        message.error('Input must only be to 2 decimal places');
        const newMinsValue = minsValue.slice(0, 2);
        newValue = `${hoursValue}.${newMinsValue}`;
      }
      setAPUHours(newValue);
    }
  };

  const updateAPUHours = (): void => {
    const stringArray = APUHours?.split('.');
    let totalSeconds;
    if (stringArray) {
      if (APUFormat === 'hours') {
        const hours = parseInt(stringArray[0], 10) * 3600;
        const minutes = parseInt(stringArray[1], 10) || 0;
        const adjustedMinutes = minutes * 60;
        totalSeconds = hours + adjustedMinutes;
      } else {
        totalSeconds = APUHours * 3600;
      }
      let newKey = 'apu_seconds';
      if (currentAircraft?.apu_mode === 'totalled') newKey = 'apu_reading';
      updateFlightData([{ value: totalSeconds, key: newKey }]);
    }
  };

  const handleSelectChange = (option): void => {
    const value = option.value === 'true';
    setPressurisedCycles(value);
    updateFlightData([{ value, key: 'pressurised_cycle' }]);
  };

  const handleCyclesChange = (value: number, key: string): void => {
    if (value < 1) {
      message.error('Cycles value cannot be less than 1');
    } else {
      const newObject = {
        ...flight?.cycles,
        [`${key}`]: value,
      };
      updateFlightData([{ value: newObject, key: 'cycles' }]);
    }
  };

  useEffect(() => {
    if (flight) {
      if (flight?.apu_mode === 'totalled' || flight?.apu_mode === 'cumulative') {
        let hours = 0;
        if (flight?.apu_mode === 'totalled') hours = flight?.apu_reading;
        if (flight?.apu_mode === 'cumulative') hours = flight?.apu_seconds;
        if (APUFormat !== 'hours') {
          setAPUHours((Math.round((hours / 3600) * 100) / 100).toFixed(2));
        } else {
          const h = Math.floor(hours / 3600);
          const m = Math.floor((hours % 3600) / 60);
          setAPUHours(`${h}.${m}`);
        }
      }
      if (flight?.landings_count) {
        setLandings(flight?.landings_count);
      } else {
        setLandings('');
      }
      if (flight?.max_flight_level) {
        setMaxFL(flight?.max_flight_level);
      } else {
        setMaxFL('');
      }
      if (flight?.pressurised_cycle) {
        setPressurisedCycles(flight?.pressurised_cycle);
      } else {
        setPressurisedCycles(false);
      }
      if (flight?.actual_fuel_burn) {
        setActualFuelBurn(flight?.actual_fuel_burn);
      } else {
        setActualFuelBurn(null);
      }
      if (flight?.fuel_burn_justification) {
        setFuelBurnJustification(flight?.fuel_burn_justification);
      } else {
        setFuelBurnJustification(null);
      }
    }
  }, [APUFormat, flight]);

  useEffect(() => {
    if (actualFuelBurn && flight?.planned_fuel_burn) {
      const burnPercentage = flight?.planned_fuel_burn * fuelBurnPercentage || 1;
      if (
        fuelBurnPercentage &&
        (flight?.planned_fuel_burn + burnPercentage < actualFuelBurn ||
          flight?.planned_fuel_burn - burnPercentage > actualFuelBurn)
      ) {
        setJustificationVisible(true);
      } else {
        setJustificationVisible(false);
      }
    }
  }, [actualFuelBurn, flight, fuelBurnPercentage]);

  useEffect(() => {
    const selectedAircraft = aircraftMap.get(id);
    if (selectedAircraft) {
      setCurrentAircraft(selectedAircraft);
      if (selectedAircraft?.standard_fields?.apu?.format === 'decimal') {
        setAPUFormat('decimal');
      }
    }
  }, [aircraftMap, id]);

  let dataItems = [];
  if (flight?.postflight_custom_data) {
    dataItems = Object.entries(flight.postflight_custom_data);
  }
  const dataElements = dataItems.map((item, index) => {
    // only for images as new custom data section deals with rest
    const header = item[0].replace(/_/gi, ' ');
    let displayText = <DisplayText>{item[1]}</DisplayText>;
    if (/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(item[1])) {
      displayText = <img src={`${servers.api}/attachments/thumbnail/${item[1]}.png`} alt="attachment" />;
      return (
        <div key={item[1]} data-testid={`PreFlightCustomDataSection-CustomElement${index}`}>
          <Header>{header}</Header>
          {displayText}
        </div>
      );
    }
    return null;
  });

  const selectOptions = [
    {
      title: 'True',
      value: 'true',
    },
    {
      title: 'False',
      value: 'false',
    },
  ];

  const DisplayAPUHoursFormat = (): string => {
    if (!APUHours) return '-';
    const hours = APUHours.split('.')[0];
    const mins = APUHours.split('.')[1];
    return `${hours}:${mins} hrs`;
  };

  const cyclesSection = (): ReactElement[] => {
    let returnElement = null;
    if (flight.cycles) {
      returnElement = Object.entries(flight?.cycles)
        .filter((entry) => {
          if (hideEngineCyclesFields) return entry[0] === 'airframe';
          if (hideAirframeCyclesFields) return entry[0]?.startsWith('engine');
          if (entry[0]?.startsWith('engine')) {
            if (parseInt(entry[0].split('_')[1], 10) <= engineCount) return entry[0];
          }
          return entry[0] === 'airframe';
        })
        .map((entry) => {
          const key = entry[0];
          const value = entry[1];
          const RemoveUnderscores = key.replace(/_/g, ' ');
          const FormattedKey = RemoveUnderscores.charAt(0).toUpperCase() + RemoveUnderscores.slice(1);
          return (
            <div data-testid={`PostFlightCustomDataSection-Cycles${entry[0]}`}>
              <Header editable={mode !== 'view'}>{currentOperator.operator_setting[key] || FormattedKey}</Header>
              {mode === 'view' ? (
                <DisplayText>{value || '-'}</DisplayText>
              ) : (
                <StyledInput
                  placeholder="-"
                  type="number"
                  min={0}
                  value={value}
                  onChange={(e): void => handleCyclesChange(parseInt(e.target.value, 10), key)}
                />
              )}
            </div>
          );
        });
    } else {
      const newCyclesObject = {} as any;
      if (!hideAirframeCyclesFields) {
        newCyclesObject.airframe = 0;
      }
      if (!hideEngineCyclesFields) {
        for (let i = 1; i <= engineCount; i += 1) {
          newCyclesObject[`engine_${i}`] = 0;
        }
      }
      updateFlightData([{ value: newCyclesObject, key: 'cycles' }]);
      returnElement = Object.entries(newCyclesObject).map((entry) => {
        const key = entry[0];
        const value = entry[1];
        const RemoveUnderscores = key.replace(/_/g, ' ');
        const FormattedKey = RemoveUnderscores.charAt(0).toUpperCase() + RemoveUnderscores.slice(1);
        return (
          <div data-testid={`PostFlightCustomDataSection-Cycles${entry[0]}`}>
            <Header editable={mode !== 'view'}>{FormattedKey}</Header>
            {mode === 'view' ? (
              <DisplayText>{value || '-'}</DisplayText>
            ) : (
              <StyledInput
                placeholder="-"
                type="number"
                min={0}
                value={value}
                onChange={(e): void => handleCyclesChange(parseInt(e.target.value, 10), key)}
              />
            )}
          </div>
        );
      });
    }
    return returnElement;
  };

  return (
    <>
      <SectionHeader data-testid="PostFlightCustomDataSection-Header">Postflight</SectionHeader>
      <SectionWrapper edit={mode !== 'view'} isMobile={isMobile}>
        <div data-testid="PostFlightCustomDataSection-Landings">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'period.landings' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{landings || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              type="number"
              min={0}
              value={landings}
              onChange={(e): void => handleInputChange(e.target.value, 'landings')}
            />
          )}
        </div>
        {hideEngineCyclesFields && hideAirframeCyclesFields ? null : cyclesSection()}
        {currentAircraft?.apu_mode !== 'not_installed' && showAPUTime ? (
          <div data-testid="PostFlightCustomDataSection-APUHours">
            <Header editable={mode !== 'view'}>{formatMessage({ id: 'period.APUhours' })}</Header>
            {mode === 'view' ? (
              <DisplayText>{APUFormat === 'hours' ? DisplayAPUHoursFormat() : `${APUHours} hrs` || '-'}</DisplayText>
            ) : (
              <>
                {APUFormat === 'hours' ? (
                  <HoursMinsInput
                    value={APUHours}
                    onChange={(e): void => handleAPUHoursChange(e)}
                    onBlur={updateAPUHours}
                  />
                ) : (
                  <div>
                    <UnitWrapper>
                      <StyledInput
                        placeholder="-"
                        value={APUHours || undefined}
                        onChange={(e): void => handleAPUHoursChange(e.target.value)}
                        onBlur={updateAPUHours}
                        type="number"
                      />
                      <StyledUnit edit={mode !== 'view'}>{APUHours ? 'hrs' : ''}</StyledUnit>
                    </UnitWrapper>
                  </div>
                )}
              </>
            )}
          </div>
        ) : null}
        {displayMaxFlightLevel !== false ? (
          <>
            <div data-testid="PostFlightCustomDataSection-MaxFL">
              <Header editable={mode !== 'view'}>{maxFlightRename || formatMessage({ id: 'period.maxFL' })}</Header>
              {mode === 'view' ? (
                <DisplayText>{maxFL || '-'}</DisplayText>
              ) : (
                <StyledInput
                  placeholder="-"
                  value={maxFL}
                  onChange={(e): void => handleInputChange(e.target.value, 'fl')}
                />
              )}
            </div>
            {displayPressurisedCycle !== false ? (
              <div data-testid="PostFlightCustomDataSection-PressurisedCycles">
                <Header editable={mode !== 'view'}>
                  {pressurisedCycleRename || formatMessage({ id: 'period.pressurisedCycle' })}
                </Header>
                {mode === 'view' ? (
                  <DisplayText>{pressurisedCycles ? 'True' : 'False'}</DisplayText>
                ) : (
                  <TFSelect
                    initial={{ value: pressurisedCycles.toString(), title: pressurisedCycles.toString() }}
                    handleSelectChange={(e): void => handleSelectChange(e)}
                    options={selectOptions}
                    width={110}
                  />
                )}
              </div>
            ) : null}
          </>
        ) : null}
        {dataElements}
        {showFuelBurn ? (
          <>
            <JustificationDiv noSpan={mode === 'view'} data-testid="PostFlightCustomDataSection-ActualFuelBurn">
              <Header editable={mode !== 'view'}>
                {formatMessage({ id: 'text.actualFuelBurn' })} {`(${formatMessage({ id: 'text.incTaxi' })})`}
              </Header>
              {mode === 'view' ? (
                <DisplayText>
                  {actualFuelBurn || '-'}
                  <StyledUnit edit={mode !== 'view'}>{aircraftMap.get(id)?.fuel_unit}</StyledUnit>
                </DisplayText>
              ) : (
                <UnitWrapper>
                  <StyledInput
                    placeholder="-"
                    type="number"
                    min={0}
                    value={actualFuelBurn}
                    onChange={(e): void => handleInputChange(e.target.value, 'actualFuel')}
                  />
                  <StyledUnit edit={mode !== 'view'}>{aircraftMap.get(id)?.fuel_unit}</StyledUnit>
                </UnitWrapper>
              )}
            </JustificationDiv>
            {justificationVisible ? (
              <JustificationDiv data-testid="PostFlightCustomDataSection-FuelBurnJustification">
                <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.fuelBurnJustification' })}</Header>
                {mode === 'view' ? (
                  <DisplayText>{fuelBurnJustification || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    width="270px"
                    placeholder="-"
                    type="text"
                    value={fuelBurnJustification}
                    onChange={(e): void => handleJustificationChange(e.target.value)}
                    onBlur={(): void => sendJustification()}
                  />
                )}
              </JustificationDiv>
            ) : null}
          </>
        ) : null}
      </SectionWrapper>
      <CustomFieldSection
        customData={flight?.postflight_custom_data}
        saveKey="postflight_custom_data"
        updateData={updateFlightData}
        customFieldKey="postflight"
      />
      {flight?.postflight_signature_data ? (
        <SignatureSection data-testid="TripDetailsSection-Signature">
          Signed by:
          <StyledImg alt="Signature" src={`data:image/png;base64, ${flight?.postflight_signature_data}`} />
        </SignatureSection>
      ) : null}
    </>
  );
};

export default PostFlightCustomDataSection;
