/* eslint-disable react/jsx-props-no-spreading */
import React, { createElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import config from './typeConfig';
import styles from './index.module.less';

const Exception = ({ className, linkElement, type, title, desc, img, actions, ...rest }) => {
  const pageType = type in config ? type : '404';
  const clsString = classNames(styles.exception, className);
  return (
    <div className={clsString} {...rest}>
      <div className={styles.imgBlock}>
        <div className={styles.imgEle} style={{ backgroundImage: `url(${img || config[pageType].img})` }} />
      </div>
      <div className={styles.content}>
        <h1>{title || config[pageType].title}</h1>
        <div className={styles.desc}>{desc || config[pageType].desc}</div>
        <div className={styles.actions}>
          {actions ||
            (linkElement
              ? createElement(
                  linkElement,
                  {
                    to: '/',
                    href: '/',
                  },
                  <Button type="primary">Home</Button>,
                )
              : '')}
        </div>
      </div>
    </div>
  );
};

Exception.propTypes = {
  className: PropTypes.string.isRequired,
  linkElement: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

export default Exception;
