/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { MELItem, MELRectification } from '../../../models/mels';
import smoothScroller from '../../../utils/smoothScroller';
import EmptyStateMel from '../../../assets/emptyState/empty-state-mel.svg';
import FlexWrapper from './FlexWrapper';
import StyledRadioInput from './StyledRadioInput';

interface DefectMelItemsProps {
  melItems: Array<MELItem>;
  updateDefectData: (changes: any[]) => void;
  setMelItem: (input: MELItem) => void;
  handleMelItemChange: (melItemId: string, rectId: string) => void;
  defect: Defect | null;
  setRectificationId?: (input: string) => void;
  searchInput?: string;
  setRectificationCategory: (option: { title: string; colour?: string }) => void;
  setATAChapter: (value: string) => void;
  setATASection: (value: number) => void;
  cas: boolean;
  casItems?: Array<MELItem>;
}

const MelItemWrapper = styled.div`
  padding: 35px 20px;
  display: flex;
`;

const FirstColumn = styled.div`
  display: flex;
  align-items: 'center';
  margin-right: 46px;
  span {
    color: rgba(36, 45, 65, 0.8);
  }
`;

const Title = styled.span`
  color: rgba(36, 45, 65, 0.6);
  width: ${({ wrapText }): string => (wrapText ? '75px' : 'max-content')};
  max-width: 500px;
`;

const SmallText = styled.span`
  color: rgba(36, 45, 65, 0.6);
  font-size: 12px;
  max-width: 500px;
  margin-right: ${({ margin }): string => (margin ? '20px' : '0')};
`;

const BlueText = styled.span`
  color: #126fd6;
  font-weight: 300;
  font-size: 12px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyStateImage = styled.img`
  height: 125px;
`;

const EmptyStateText = styled.div`
  font-size: 12px;
  color: #c0c0c0;
`;

const DefectMelItems: React.FC<DefectMelItemsProps> = ({
  melItems,
  setMelItem,
  handleMelItemChange,
  defect,
  updateDefectData,
  searchInput,
  setRectificationId,
  setRectificationCategory,
  setATAChapter,
  setATASection,
  cas,
  casItems,
}) => {
  const [selectedMelItem, setSelectedMelItem] = useState('');
  const [selectedRectificationId, setSelectedRectificationId] = useState('');

  const { formatMessage } = useIntl();

  const IntervalOptions = {
    A: 'As per remarks',
    B: '3 days',
    C: '10 days',
    D: '120 days',
  };

  const getNewDateDue = (interval): string => {
    let date = defect.deferred_at ? moment(defect.deferred_at) : moment();
    if (interval === 'B') {
      date = date.add(3, 'days');
    }
    if (interval === 'C') {
      date = date.add(10, 'days');
    }
    if (interval === 'D') {
      date = date.add(120, 'days');
    }
    return date.format('YYYY-MM-DD');
  };

  const handleMelItemClick = (item: MELItem): void => {
    const newDateDue = getNewDateDue(item.mel_rectifications[0].interval);
    setSelectedMelItem(item.id);
    setMelItem(item);
    setSelectedRectificationId(item.mel_rectifications[0].id);
    setRectificationId(item.mel_rectifications[0].id);
    handleMelItemChange(item.id, item.mel_rectifications[0].id);
    setRectificationCategory({ title: item.mel_rectifications[0].interval });
    setATAChapter(item?.chapter_number?.toString());
    setATASection(item?.section_number);
    updateDefectData([
      { value: item, key: 'mel_item' },
      { value: item.mel_rectifications[0].id, key: 'mel_rectification_id' },
      { value: newDateDue, key: 'date_due' },
    ]);
  };

  const handleRectificationClick = (index: number, passedMelItem: MELItem, rect: MELRectification): void => {
    const newDateDue = getNewDateDue(rect.interval);
    setSelectedMelItem(passedMelItem?.id);
    setMelItem(passedMelItem);
    setSelectedRectificationId(rect.id);
    setRectificationId(rect.id);
    handleMelItemChange(passedMelItem?.id, rect.id);
    setRectificationCategory({ title: rect.interval });
    setATAChapter(passedMelItem?.chapter_number?.toString());
    setATASection(passedMelItem?.section_number);
    updateDefectData([
      { value: passedMelItem, key: 'mel_item' },
      { value: rect.id, key: 'mel_rectification_id' },
      { value: newDateDue, key: 'date_due' },
    ]);
  };

  useEffect(() => {
    if (defect) {
      if (defect?.mel_item) {
        setSelectedMelItem(defect?.mel_item?.id);
        setMelItem(defect?.mel_item);
      }
      if (defect?.mel_rectification_id) {
        setSelectedRectificationId(defect?.mel_rectification_id);
        setRectificationId(defect?.mel_rectification_id);
      }
    }
  }, [defect]);

  useEffect(() => {
    if (selectedMelItem) {
      const wrapper = document.getElementById('MELItemWrapper');
      const item = document.getElementById(`${selectedMelItem}`);
      if (wrapper && item) {
        smoothScroller({ scrollTo: item, scrollContainer: wrapper, scrollOffset: 40 });
      }
    }
  }, [selectedMelItem]);

  const melRectifications = (items: Array<MELRectification>, passedMelItem: MELItem): Array<ReactElement> => {
    return items.map((item: MELRectification, index: number) => {
      return (
        <FlexWrapper marginTop={15} key={item.id} column identifier="MelItemsWrapper">
          <FlexWrapper marginBottom={10} alignItems="center">
            {items.length > 1 ? (
              <StyledRadioInput
                checked={item.id === selectedRectificationId}
                handleClick={(): void => {
                  handleRectificationClick(index, passedMelItem, item);
                }}
                id={`Rectification${item.id}`}
                value={`${item.id}`}
                name="rectificationGroup"
              />
            ) : null}
            <Title>{item.title || '-'}</Title>
          </FlexWrapper>
          <FlexWrapper marginBottom={10}>
            <SmallText margin data-testid="DefectMelItems--IntervalSection">
              {formatMessage({ id: 'title.rectificationInterval' })}:{' '}
              <BoldText data-testid="DefectMelItems--Interval">{item.interval}</BoldText>{' '}
              {IntervalOptions[item.interval]}
            </SmallText>
            {!cas ? (
              <SmallText margin data-testid="DefectMelItems--NumberInstalled">
                {formatMessage({ id: 'title.numberInstalled' })}: {item.number_installed}
              </SmallText>
            ) : null}
            {!cas ? (
              <SmallText data-testid="DefectMelItems--NumberRequired">
                {formatMessage({ id: 'title.numberRequired' })}: {item.number_required}
              </SmallText>
            ) : null}
          </FlexWrapper>
          <div>
            <SmallText data-testid="DefectMelItems--Details">
              {item.operational_procedure ? '(O)' : null}
              {item.maintenance_procedure ? '(M)' : null}
              {item.placard_procedure ? '(*)' : null}
              {item.remarks ? `Remarks: ${item.remarks}` : null}
            </SmallText>
          </div>
        </FlexWrapper>
      );
    });
  };

  let displayItems = melItems;
  if (cas) displayItems = casItems;

  return (
    <>
      {displayItems && displayItems.length > 0 ? (
        displayItems.map((item) => {
          let chapterNumber = item?.chapter_number?.toString();
          if (item?.chapter_number?.toString() && item?.chapter_number?.toString()?.length === 1) {
            chapterNumber = `0${item?.chapter_number}`;
          }
          let sectionNumber = item?.section_number?.toString();
          if (item?.section_number?.toString() && item?.section_number?.toString()?.length === 1) {
            sectionNumber = `0${item?.section_number}`;
          }
          let subSectionNumber = item?.subsection_number?.toString();
          if (item?.subsection_number?.toString() && item?.subsection_number?.toString()?.length === 1) {
            subSectionNumber = `0${item?.subsection_number}`;
          }
          return (
            <MelItemWrapper id={item.id} key={item.id} data-testid="DefectMelItems--MelWrapper">
              <FirstColumn>
                <StyledRadioInput
                  checked={selectedMelItem === item.id}
                  handleClick={(): void => {
                    handleMelItemClick(item);
                  }}
                  marginTop={2}
                  identifier="MelItem"
                  id={`MelItem${item.id}`}
                  value={`${item.id}`}
                  name="melItemGroup"
                />
                <Title wrapText data-testid="DefectMelItems--MelNumber">
                  {chapterNumber}-{sectionNumber}-{subSectionNumber}
                  {item?.subsubsection ? `-${item?.subsubsection}` : null}
                </Title>
              </FirstColumn>
              <div>
                <FlexWrapper marginBottom={5} column>
                  <FlexWrapper>
                    <Title data-testid="DefectMelItems--MelTitle">{item.title}</Title>
                    {cas ? <span>{item?.cas_severity}</span> : null}
                  </FlexWrapper>
                  {item.mel_rectifications && item.mel_rectifications.length > 1 ? (
                    <BlueText data-testid="DefectMelItems--MultipleOptions">
                      {formatMessage({ id: 'text.thisChapterContainsMultipleOptions' })}
                    </BlueText>
                  ) : null}
                </FlexWrapper>
                {item.mel_rectifications && melRectifications(item.mel_rectifications, item)}
              </div>
            </MelItemWrapper>
          );
        })
      ) : (
        <EmptyStateWrapper>
          <EmptyStateImage src={EmptyStateMel} alt="no data" />
          <EmptyStateText>{`We couldn't find any results for '${searchInput}'`}</EmptyStateText>
        </EmptyStateWrapper>
      )}
    </>
  );
};

export default DefectMelItems;
