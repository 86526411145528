import { SUPPORTED_CUSTOM_FIELDS } from '../models/aircraft';

export const getRequiredFields = (fields: any) => {
  if (!fields) return [];
  const entries = Object.entries(fields);
  const required = entries
    .filter((entry: any) => SUPPORTED_CUSTOM_FIELDS.includes(entry[1].type) && entry[1].required)
    .map(([key, data]: any) => {
      return { key, view: data?.view, title: data?.heading || data?.title };
    });
  return required;
};

export const getPrecisionFields = (fields: any) => {
  if (!fields) return [];
  const entries = Object.entries(fields);
  const required = entries
    .filter((entry: any) => entry[1].type === 'number' && entry[1].precision)
    .map(([key, data]: any) => {
      return { key, view: data?.view, title: data?.heading || data?.title, precision: data?.precision };
    });
  return required;
};

export const getMinMaxFields = (fields: any) => {
  if (!fields) return [];
  const entries = Object.entries(fields);
  const required = entries
    .filter(
      (entry: any) =>
        (entry[1].type === 'number' || entry[1].type === 'string' || entry[1].type === 'text') &&
        (entry[1].min || entry[1].max),
    )
    .map(([key, data]: any) => {
      return {
        key,
        view: data?.view,
        title: data?.heading || data?.title,
        min: data?.min,
        max: data?.max,
        type: data?.type,
      };
    });
  return required;
};
