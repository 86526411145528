import qs from 'qs';
import Cookies from 'universal-cookie';
import uuid from 'uuid';
import config, { ConfigKey } from './config';

const cookies = new Cookies();

// eslint-disable-next-line import/prefer-default-export
export function getOauthUrl(redirect: string): string {
  // nonce is cleared on the callback
  // currently just preventing multiple simultaneous 401s from API rewriting the nonce cookie
  const nonce = cookies.get('oauthnonce') || uuid.v4();
  if (cookies && typeof cookies.set === 'function') {
    cookies.set('oauthnonce', nonce, {
      domain: config.get(ConfigKey.COOKIEDOMAIN),
      maxAge: 3600,
      path: '/',
    });
  }

  const buildArgs = (useAuthenticationService: boolean) =>
    useAuthenticationService
      ? {
          grant_type: 'authorization_code',
          client_id: config.get(ConfigKey.ORYCLIENTID),
          response_type: 'code',
          redirect_uri: `${window.location.origin}/auth/callback`,
          state: JSON.stringify({
            nonce,
            redirect,
          }),
        }
      : {
          grant_type: 'authorization_code',
          client_id: config.get(ConfigKey.UID),
          response_type: 'code',
          redirect_uri: `${window.location.origin}/auth/callback`,
          state: JSON.stringify({
            nonce,
            redirect,
          }),
        };

  const buildUrl = (useAuthenticationService: boolean) =>
    useAuthenticationService
      ? `${config.get(ConfigKey.IDPURL)}/oauth2/auth?${qs.stringify(buildArgs(useAuthenticationService))}`
      : `${config.get(ConfigKey.API)}/oauth/authorize?${qs.stringify(buildArgs(useAuthenticationService))}`;

  const { authenticationService } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if (authenticationService) {
    cookies.set('authentication-service', authenticationService, {
      domain: config.get(ConfigKey.COOKIEDOMAIN),
      maxAge: 3600,
      path: '/',
    });
  }

  return buildUrl(!!authenticationService);
}
