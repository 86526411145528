import _ from 'lodash';
import { BaseModel } from '..';

const initialState: MaintenanceState = {
  maintenanceMap: new Map(),
  draftMaintenanceMap: new Map(),
  lastFetched: 0,
  lastAddedId: null,
  fetched: false,
  data: [],
};

export enum MaintenanceActionTypes {
  SAVE = 'maintenance/save',
  SAVE_ALL = 'maintenance/saveAllMxItems',
  SAVE_ALL_ACTIVE = 'maintenance/saveAllActiveMxItems',
  SAVE_ALL_DRAFT = 'maintenance/saveAllDraftMxItems',
  REPLACE_ALL = 'maintenance/replaceAllMxItems',
  REPLACE_ALL_ACTIVE = 'maintenance/replaceAllActiveMxItems',
  REPLACE_ALL_DRAFT = 'maintenance/replaceAllDraftMxItems',
  DELETE_ALL = 'maintenance/deleteAllMxItems',
  DELETE = 'maintenance/deleteMxItem',
  UPDATE_SINGLE_ACTIVE = 'maintenance/updateSingleActiveMxItem',
  UPDATE_MULTIPLE_TOLERANCE = 'maintenance/updateMultipleTolerance',
}

export interface SaveMaintenanceActionType {
  type: MaintenanceActionTypes.SAVE;
  payload: Maintenance;
}

export interface UpdateSingleActiveItemActionType {
  type: MaintenanceActionTypes.UPDATE_SINGLE_ACTIVE;
  payload: Maintenance;
}

export interface SaveAllMaintenanceActionType {
  type: MaintenanceActionTypes.SAVE_ALL;
  payload: Maintenance;
}

export interface SaveActiveMaintenanceActionType {
  type: MaintenanceActionTypes.SAVE_ALL_ACTIVE;
  payload: Maintenance;
}

export interface SaveDraftMaintenanceActionType {
  type: MaintenanceActionTypes.SAVE_ALL_DRAFT;
  payload: Maintenance;
}

export interface ReplaceAllMaintenanceActionType {
  type: MaintenanceActionTypes.REPLACE_ALL;
  payload: Maintenance;
}

export interface ReplaceAllActiveMaintenanceActionType {
  type: MaintenanceActionTypes.REPLACE_ALL_ACTIVE;
  payload: Maintenance;
}

export interface ReplaceAllDraftMaintenanceActionType {
  type: MaintenanceActionTypes.REPLACE_ALL_DRAFT;
  payload: Maintenance;
}

export interface DeleteAllMaintenanceActionType {
  type: MaintenanceActionTypes.DELETE_ALL;
  payload: Maintenance;
}

export interface DeleteMaintenanceActionType {
  type: MaintenanceActionTypes.DELETE;
  payload: Maintenance;
}

export interface UpdateMultipleToleranceActionType {
  type: MaintenanceActionTypes.UPDATE_MULTIPLE_TOLERANCE;
  payload: Maintenance[];
}

type MaintenanceAction =
  | SaveMaintenanceActionType
  | SaveAllMaintenanceActionType
  | SaveActiveMaintenanceActionType
  | SaveDraftMaintenanceActionType
  | ReplaceAllMaintenanceActionType
  | ReplaceAllActiveMaintenanceActionType
  | ReplaceAllDraftMaintenanceActionType
  | DeleteAllMaintenanceActionType
  | UpdateSingleActiveItemActionType
  | DeleteMaintenanceActionType
  | UpdateMultipleToleranceActionType;

export default function (state = initialState, action: MaintenanceAction): MaintenanceState {
  switch (action.type) {
    case MaintenanceActionTypes.SAVE_ALL: {
      const maintenanceMap = new Map(state.maintenanceMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((mxItem) => {
          maintenanceMap.set(mxItem.id, {
            ...maintenanceMap.get(mxItem.id),
            ...mxItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        maintenanceMap,
        fetched: true,
        lastFetched: Date.now(),
      };
    }
    case MaintenanceActionTypes.SAVE_ALL_ACTIVE: {
      const maintenanceMap = new Map(state.maintenanceMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((mxItem) => {
          maintenanceMap.set(mxItem.id, {
            ...maintenanceMap.get(mxItem.id),
            ...mxItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        maintenanceMap,
      };
    }
    case MaintenanceActionTypes.SAVE_ALL_DRAFT: {
      const draftMaintenanceMap = new Map(state.draftMaintenanceMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((mxItem) => {
          draftMaintenanceMap.set(mxItem.id, {
            ...draftMaintenanceMap.get(mxItem.id),
            ...mxItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        draftMaintenanceMap,
      };
    }
    case MaintenanceActionTypes.REPLACE_ALL: {
      const maintenanceMap = new Map();
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((mxItem) => {
          maintenanceMap.set(mxItem.id, {
            ...maintenanceMap.get(mxItem.id),
            ...mxItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        maintenanceMap,
        fetched: true,
        lastFetched: Date.now(),
      };
    }
    case MaintenanceActionTypes.REPLACE_ALL_ACTIVE: {
      const maintenanceMap = new Map();
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((mxItem) => {
          maintenanceMap.set(mxItem.id, {
            ...maintenanceMap.get(mxItem.id),
            ...mxItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        maintenanceMap,
        fetched: true,
        lastFetched: Date.now(),
      };
    }
    case MaintenanceActionTypes.REPLACE_ALL_DRAFT: {
      const draftMaintenanceMap = new Map();
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((mxItem) => {
          draftMaintenanceMap.set(mxItem.id, {
            ...draftMaintenanceMap.get(mxItem.id),
            ...mxItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        draftMaintenanceMap,
        fetched: true,
        lastFetched: Date.now(),
      };
    }
    case MaintenanceActionTypes.DELETE_ALL: {
      const maintenanceMap = new Map();
      const draftMaintenanceMap = new Map();
      return {
        ...state,
        maintenanceMap,
        draftMaintenanceMap,
        fetched: false,
        lastFetched: Date.now(),
      };
    }
    case MaintenanceActionTypes.UPDATE_SINGLE_ACTIVE: {
      const maintenanceMap = new Map(state.maintenanceMap.entries());
      if (action.payload && action.payload.id) {
        maintenanceMap.set(action.payload.id, {
          ...maintenanceMap.get(action.payload.id),
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        maintenanceMap,
        lastFetched: Date.now(),
        lastAddedId: action.payload.id,
      };
    }
    case MaintenanceActionTypes.SAVE: {
      const maintenanceMap = new Map(state.maintenanceMap.entries());
      if (action.payload && action.payload.id) {
        maintenanceMap.set(action.payload.id, {
          ...maintenanceMap.get(action.payload.id),
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        maintenanceMap,
        lastFetched: Date.now(),
        lastAddedId: action.payload.id,
      };
    }
    case MaintenanceActionTypes.DELETE: {
      const maintenanceMap = new Map(state.maintenanceMap.entries());
      if (action.payload && action.payload.id) {
        maintenanceMap.delete(action.payload.id);
      }
      return {
        ...state,
        maintenanceMap,
      };
    }
    case MaintenanceActionTypes.UPDATE_MULTIPLE_TOLERANCE: {
      const maintenanceMap = new Map(state.maintenanceMap.entries());
      if (action.payload) {
        action.payload.forEach((mxItem) => {
          const newItem = _.cloneDeep(mxItem);
          maintenanceMap.set(mxItem.id, {
            ...newItem,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        maintenanceMap,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}

export interface MaintenanceState {
  maintenanceMap: Map<string, Maintenance>;
  draftMaintenanceMap: Map<string, Maintenance>;
  lastFetched: number;
  lastAddedId: string;
  fetched: boolean;
  data: Maintenance[];
}

export interface Maintenance extends BaseModel {
  camp_diffs: Array<{}>;
  id: string;
  draft: boolean;
  status: string;
  mx_type: string;
  last_updated: number;
  name: string;
  reference: unknown;
  ata_chapter: unknown;
  ata_number: unknown;
  assembly: unknown;
  repeat_remarks: unknown;
  date_due: string;
  flight_seconds_due: number;
  flight_seconds_tolerance: number;
  flight_seconds_visible_tolerance: number;
  flight_seconds_repeat: number;
  flight_seconds_threshold: number;
  flight_seconds_remaining: number;
  apu_seconds_due: number;
  apu_seconds_tolerance: number;
  apu_seconds_visible_tolerance: number;
  apu_seconds_repeat: number;
  apu_seconds_threshold: number;
  apu_seconds_remaining: number;
  cycles_due: number;
  cycles_tolerance: number;
  cycles_visible_tolerance: number;
  cycles_repeat: number;
  cycles_threshold: number;
  cycles_remaining: number;
  days_tolerance: number;
  days_visible_tolerance: number;
  days_threshold: number;
  days_remaining: number;
  days_repeat: number;
  months_repeat: number;
  months_tolerance: number;
  months_visible_tolerance: number;
  months_threshold: number;
  part_number: number;
  part_serial: string;
  estimated_date: string;
  unit_of_time: string;
  time_based_repeat_calculation_method: string;
  datetime_due: string;
  calendar_days_amber_limit: number;
  calendar_seconds_amber_limit: number;
  mx_tasks: {
    id: string;
    item_code: string;
    task: string;
    zone: string[];
    mx_procedure_reference: string;
    mx_manual_reference: unknown;
    is_deleted: boolean;
  }[];
  aircraft: {
    id: string;
    serial_number: string;
    registration: string;
    aircraft_type_id: string;
    simulator: boolean;
    year: number;
    fuel_unit: string;
    oil_unit: string;
    apu_installed: boolean;
    max_fuel_value: number;
    custom_fields: {};
    standard_fields: {};
    start_values: {
      start_trip_entry_id: unknown;
      start_scheduled_mx_item_id: string;
      flight_seconds: number;
      apu_seconds: number;
      landings: number;
      cycles: number;
      trip_number: number;
    };
    mel_pdf_url: unknown;
    mel_png_url: unknown;
    mel_json_url: unknown;
    current_values: {
      flight_seconds: number;
      apu_seconds: number;
      landings: number;
      cycles: number;
      trips: number;
      airport_ident: unknown;
      last_flown: string;
    };
    maintenance: {
      flight_seconds_to_next_check: number;
      apu_seconds_to_next_check: unknown;
      cycles_to_next_check: unknown;
      days_to_next_check: unknown;
    };
    last_updated: number;
    aircraft_type: {
      id: string;
      manufacturer: string;
      model: string;
      designator: string;
      category: string;
      engine_count: number;
      engine_type: string;
      weight_category: string;
      aircraft_image_url: string;
    };
    operator: {
      id: string;
      name: string;
      address: string;
      aoc_number: string;
      date_format: string;
      ddl_template: unknown;
      logo_url: unknown;
      dark_logo_url: unknown;
      custom_signoff_text: {};
    };
  };
  show_on_srp: boolean;
}
