import React, { ReactNode, useEffect, useState } from 'react';
import moment from 'moment';
import syncIcon from '../../assets/icon-sync.svg';
import styles from './campButtonStyles.module.less';

interface CampProps {
  camp_last_synced: string;
  synchronising: boolean;
  handleSyncButtonClick(): void;
  formatMessage({ id }): ReactNode;
}

const CampSyncButton: React.FC<CampProps> = ({
  camp_last_synced,
  synchronising,
  handleSyncButtonClick,
  formatMessage,
}) => {
  const [syncTimeDifference, updateSyncTimeDifference] = useState(null);

  useEffect(() => {
    const currentTimeInMins = Math.round(Date.now() / 1000 / 60);
    const lastSyncInMins = Math.round(new Date(camp_last_synced).getTime() / 1000 / 60);
    updateSyncTimeDifference(currentTimeInMins - lastSyncInMins);

    const syncTicker = setInterval(() => {
      // cant use currentTimeInMins var here as it wouldnt be updated when interval ticks
      updateSyncTimeDifference(Math.round(Date.now() / 1000 / 60) - lastSyncInMins);
    }, 60000);

    return (): void => {
      clearInterval(syncTicker);
    };
  }, [camp_last_synced]);

  return (
    (syncTimeDifference !== null && (
      <div className={styles.syncButtonWrapper}>
        <button type="button" className={styles.syncButton} onClick={handleSyncButtonClick}>
          <img
            src={syncIcon}
            alt="sync"
            className={synchronising ? `${styles.syncButtonSyncing} ${styles.syncIcon}` : styles.syncIcon}
          />
        </button>
        <span>
          {synchronising ? (
            `${formatMessage({ id: 'text.syncing' })}`
          ) : (
            <span>
              <span>{formatMessage({ id: 'text.syncd' })} : </span>
              <span className={styles.timeUnit}>
                {' '}
                {moment.duration(-syncTimeDifference - 1, 'minutes').humanize(true)}{' '}
              </span>
            </span>
          )}
        </span>
      </div>
    )) ||
    null
  );
};

export default CampSyncButton;
