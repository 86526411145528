import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import { DashboardResource, UserPermission } from '../../models/userSettings';
import plusIcon from '../../assets/plus.svg';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import filtersIcon from '../../assets/filters.svg';
import { DashboardState } from '../../models';
import DefectSearch from './DefectSearch';
import styles from './DefectTableHeader.module.less';
import AIButton from './AIButton';

// eslint-disable-next-line no-shadow
export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface DefectTableHeaderProps {
  toggleUseEmbeddings: () => void;
  useEmbeddings: boolean;
  defectCount: number;
  aircraftId: string;
  setSearchValue: (value: string) => void;
  reset: boolean;
  toggleFiltersDrawer: () => void;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 10px;
  @media (max-width: 769px) {
    flex-wrap: wrap;
  }
  @media (max-width: 450px) {
    align-items: flex-start;
    flex-direction: column;
    margin: 24px 24px 10px;
  }
`;

const DefectCount = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #242d41;
  span {
    font-weight: 400;
  }
  @media (max-width: 450px) {
    margin-bottom: 12px;
  }
`;

const LinkButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  color: #126fd6;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const LinkSpan = styled.span`
  color: #126fd6;
`;

const AddButtonText = styled.span`
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  &:first-child {
    margin-right: 12px;
  }
  @media (max-width: 450px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const SearchMargin = styled.div`
  margin-right: 20px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  display: flex;
  align-items: center;
`;

const StyledFilterIcon = styled.img`
  margin-left: 0.5em;
  margin-right: 2em;
  height: 1em;
  width: 1em;
`;

const StyledFilterButton = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  margin-left: 0.5em;
  margin-right: 2em;
  height: 1em;
  width: 1em;
  @media (max-width: 767px) {
    display: block;
  }
`;

const DefectTableHeader: React.FC<DefectTableHeaderProps> = ({
  toggleUseEmbeddings,
  useEmbeddings,
  defectCount,
  aircraftId,
  setSearchValue,
  reset,
  toggleFiltersDrawer,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const {
    userSettings: {
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const aircraft = aircraftMap.get(aircraftId);
  const operator = operators.find((op) => op?.id === aircraft?.operator_id);
  const defectsNameOverride = operator?.operator_setting?.defect_name_plural;
  const defectNameOverride = operator?.operator_setting?.defect_name;
  const hideAddWorkpackButton = operator?.operator_setting?.hide_add_workpack;

  const addNewDefect = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'add' }));
    dispatch(changeDrawerContent({ payload: { content: <DefectDrawer defectId="" /> } }));
  };

  const aiButton = <AIButton isActive={useEmbeddings} onClick={toggleUseEmbeddings} />;
  const hasFeatureFlagEnabled =
    aircraft && !!aircraft?.feature_flags?.find((featureFlag) => featureFlag.feature_name === 'ai_defects_search');
  const isKillSwitchEnabled =
    aircraft &&
    !!aircraft?.feature_flags?.find((featureFlag) => featureFlag.feature_name === 'ai_defects_search_killswitch');
  const shouldDisplayAIButton = isKillSwitchEnabled ? false : hasFeatureFlagEnabled;

  const menuOptions = (
    <Menu className={styles.menuOptions}>
      {hideAddWorkpackButton ? null : (
        <Menu.Item>
          <Link
            to={{
              pathname: aircraftId ? `/aircraft/${aircraftId}/workpacks/log` : '',
              state: {
                addWorkpack: true,
              },
            }}
          >
            <LinkSpan>{formatMessage({ id: 'text.workpack' })}</LinkSpan>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item onClick={addNewDefect}>
        <LinkButton type="button" onClick={addNewDefect}>
          {defectNameOverride}
        </LinkButton>
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderWrapper className={styles.headerWrapper}>
      <DefectCount>
        {defectsNameOverride} {defectCount && <span>{`(${defectCount})`}</span>}
      </DefectCount>
      <SearchWrapper id="searchWrapper">
        <SearchMargin>
          <DefectSearch
            onChange={(e): void => setSearchValue(e.currentTarget.value)}
            onClear={(): void => setSearchValue('')}
            reset={reset}
            additionalButton={shouldDisplayAIButton ? aiButton : null}
          />
        </SearchMargin>
        <AircraftAuthenticationWrapper
          aircraftId={aircraftId}
          requiredResource={DashboardResource.DEFECT}
          requiredPermissionLevel={UserPermission.CREATE}
        >
          <Dropdown overlay={menuOptions} trigger={['click']}>
            <div>
              <Button size={ButtonSize.MEDIUM} onClick={null}>
                <AddButtonText>
                  <img src={plusIcon} alt="plus icon" />{' '}
                  {window.innerWidth > 1150
                    ? formatMessage({ id: 'form.button.addNew' })
                    : formatMessage({ id: 'form.button.add' })}
                </AddButtonText>
              </Button>
            </div>
          </Dropdown>
        </AircraftAuthenticationWrapper>
        <StyledFilterButton type="button" onClick={(): void => toggleFiltersDrawer()}>
          <StyledFilterIcon src={filtersIcon} alt="Open filters" />
        </StyledFilterButton>
      </SearchWrapper>
    </HeaderWrapper>
  );
};

export default DefectTableHeader;
