import { useMutation } from '@tanstack/react-query';
import { reorderFRAT } from '../../services/api';

const useReorderFRAT = ({ handleReorderOnSuccess, handleReorderOnError }) => {
  const reorder = useMutation({
    mutationFn: (payload: {
      flight_risk_assessment_question_categories_attributes: {
        id: string;
        sequence_number: number;
        flight_risk_assessment_questions_attributes: {
          id: string;
          sequence_number: number;
          flight_risk_assessment_question_category_id: string;
        }[];
      }[];
    }) => {
      return reorderFRAT(payload);
    },
    onSuccess: (data) => {
      handleReorderOnSuccess(data);
    },
    onError: (err: any) => handleReorderOnError(err),
  });

  return reorder;
};

export default useReorderFRAT;
