/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import moment from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Modal, Button } from '@arcflight/tf-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { getDefectForDrawer, update, submitDefect } from '../../models/defects/actions';
import { DashboardState } from '../../models';
import { IntermittentFault } from '../../models/intermittentFaults';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  revertChanges as reduxRevertChanges,
  setDrawerId,
  handleBackButtonClickWithPayload,
} from '../../models/drawer';
import updateLocalDataObject from '../../utils/updateLocalDataObject';
import getOperatorSetting from '../../utils/getOperatorSetting';
import Loading from '../TFLoading/index';
import HistorySection from '../HistoryTable/HistorySection';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import ResolutionDetails from '../WorkPackDrawer/Components/ResolutionDetails';
import coreErrorMessageFormatter from '../../utils/coreErrorMessageFormatter';
import getAircraftStandardField from '../../utils/getAircraftStandardField';
import AdditionalInformation from './components/AdditionalInformation';
import AircraftLimitations from './components/AircraftLimitations';
import ButtonSection from './components/ButtonSection';
import ConfirmSection from './components/ConfirmSection';
import DefectDetails from './components/DefectDetails';
import DefectHeader from './components/DefectHeader';
import DeferralOptions from './components/DeferralOptions';
import SignatureSection from './components/SignatureSection';
import AddDeferralTile from './components/AddDeferralTile';
import DeferralSignoffDetails from './components/DeferralSignoffDetails';
import RectificationIntervalExtension from './components/RectificationIntervalExtension';
import MELReviewActionMap from './MELReviewActionMap';
import Card from './components/Card';

interface DefectDrawerProps {
  defectId: string;
  passedFlightId?: string;
  deferDefect?: boolean;
  newFlight?: boolean;
}

const DrawerWrapper = styled.div`
  min-height: calc(100vh - 24px);
  height: 100%;
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const BorderLine = styled.div`
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '')};
`;

const ModalContentWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: #242d41;
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
  max-width: 350px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubminButtonWraper = styled.div`
  margin-right: 12px;
`;

const DefectDrawer: React.FC<DefectDrawerProps> = ({ defectId, passedFlightId, deferDefect, newFlight }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let defect = null;
  const {
    defects: { drawerDefect, defectsMap },
    aircraft: { aircraftMap },
    drawer: { mode, drawerHistory, revertChanges, passedData, editResolved },
    userSettings,
    mels: { aircraftMelsMap },
  } = useSelector((state: DashboardState) => state);
  if (defectId) {
    defect = drawerDefect;
  } else {
    defect = null;
  }

  const [signature, setSignature] = useState('');
  const [rectificationCategory, setRectificationCategory] = useState(null);
  const [aircraftId, setAircraftId] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [melItem, setMelItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [melItemsLoading, setMelItemsLoading] = useState(false);
  const [confirmationChecks, setConfirmationChecks] = useState([]);
  const [rectificationId, setRectificationId] = useState('');
  const [melItemId, setMelItemId] = useState('');
  const [defectType, setDefectType] = useState('');
  const [formChanged, setFormChanged] = useState(false);
  const [displayDefect, setDisplayDefect] = useState(null);
  const [defectDeferred, setDefectDeferred] = useState(false);
  const [deferredTime, setDeferredTime] = useState(null);
  const [deferredById, setDeferredById] = useState('');
  const [reference, setReference] = useState(null);
  const [ATAChapter, setATAChapter] = useState(null);
  const [ATASection, setATASection] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [originalDefectData, setOriginalDefectData] = useState(null);
  const [originalDeferredById, setOriginalDeferredById] = useState(null);
  const [aircraftHasMEL, setAircraftHasMEL] = useState(false);
  const [signOffAsEngineer, setSignOffAsEnginner] = useState(false);
  const [isPassedIntermittentFault, setIsPassedIntermittentFault] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const chosenAircraft = aircraftMap.get(aircraftId);
  const standardFields = chosenAircraft && chosenAircraft.standard_fields;
  let eropsTitle = 'EROPS';
  let poIntl = { po_short_name: 'PO', po_long_name: 'Purchase Order', po_suffix: 'Number' };
  if (chosenAircraft) {
    const currentOrg = userSettings?.details?.operators?.find((op) => op.id === chosenAircraft?.operator_id);
    eropsTitle = currentOrg?.operator_setting?.erops;
    poIntl = {
      po_short_name: currentOrg?.operator_setting?.po_short_name,
      po_long_name: currentOrg?.operator_setting?.po_long_name,
      po_suffix: currentOrg?.operator_setting?.po_suffix,
    };
  }
  const ingoreEROPSWarnings = standardFields?.non_erops ? standardFields?.non_erops.enabled : true;

  const melNameOverride = getAircraftStandardField('deferral_type_mel', id)?.name_override;
  const cdlNameOverride = getAircraftStandardField('deferral_type_cdl', id)?.name_override;
  const nefNameOverride = getAircraftStandardField('deferral_type_nef', id)?.name_override;
  const casNameOverride = getAircraftStandardField('deferral_type_cas', id)?.name_override;
  const otherNameOverride = getAircraftStandardField('deferral_type_other', id)?.name_override;

  const reasonToDeferRequired = getAircraftStandardField('reason_to_defer', id)?.required || false;
  const reasonToDeferAsDropdown = getAircraftStandardField('reason_to_defer', id)?.dropdown_enabled || false;
  const referenceRequired = getAircraftStandardField('defect_reference', id)?.required || false;
  const ataSelectionRequired = getAircraftStandardField('ata_selection', id)?.required || false;
  const troubleShootingStepsRequired = getAircraftStandardField('troubleshooting_steps', id)?.required || false;

  const sectorsNameOverride = getOperatorSetting('sector_name_plural', id);

  const opId = aircraftMap.get(id)?.operator_id;
  const isAdmin = userSettings?.details?.people.find((person) => person.organisation.id === opId)?.position === 'Admin';
  const editResolvedLocal = defectData?.status === 'resolved' && isAdmin;

  let list;
  if (aircraftMelsMap && aircraftMelsMap.get(aircraftId || defect?.aircraft?.id)) {
    list = aircraftMelsMap.get(aircraftId || defect.aircraft.id).list;
  }

  const raiseToast = (title: string, message: string, category: ToastCategories): void => {
    dispatch(
      addToast({
        payload: {
          title,
          message,
          type: ToastTypes.ERROR,
          category,
        },
      }),
    );
  };

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefectsMap = usePrevious(defectsMap);

  const usePreviousDefect = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevdisplayDefect = usePreviousDefect(displayDefect);

  const usePreviousDefectDrawer = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDrawerDefect = usePreviousDefectDrawer(drawerDefect);

  const usePreviousMelItemId = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevMelItemId = usePreviousMelItemId(melItemId);

  const handleSignature = (input: string): void => {
    setSignature(input);
  };

  const updateDefectData = (changes: { value: any; key: string }[]): void => {
    if (!defectData) {
      return;
    }

    const newData = updateLocalDataObject(changes, defectData);
    setDefectData(newData);
    setFormChanged(true);
  };

  const resetForm = (): void => {
    setSignature('');
    setRectificationCategory(null);
    setAircraftId(null);
    setAttachments(attachments || []);
    setMelItem(null);
    setDisplayDefect(null);
    setConfirmationChecks([]);
    setRectificationId('');
    setMelItemId('');
    setDefectType('MEL');
    setFormChanged(false);
    if (document.getElementById('tfDrawerWrapper')) document.getElementById('tfDrawerWrapper').scrollTop = 0;
  };

  useEffect(() => {
    if (melItem) {
      const newConfirmations = [];
      let foundRect = melItem?.mel_rectifications[0];
      if (rectificationId) {
        foundRect = melItem?.mel_rectifications.find((rec) => rec.id === rectificationId);
      }
      if (foundRect?.remarks) {
        newConfirmations.push('remarks');
      }
      if (foundRect?.placard_procedure) {
        newConfirmations.push('placards');
      }
      if (foundRect?.operational_procedure) {
        newConfirmations.push('operations');
      }
      if (foundRect?.maintenance_procedure) {
        newConfirmations.push('maintenance');
      }
      setConfirmationChecks(newConfirmations);
    } else if (defect?.mel_item) {
      const newConfirmations = [];
      let foundRect = defect?.mel_item?.mel_rectifications && defect?.mel_item?.mel_rectifications[0];
      if (rectificationId) {
        foundRect = defect?.mel_item?.mel_rectifications.find((rec) => rec.id === rectificationId);
      }
      if (foundRect?.remarks) {
        newConfirmations.push('remarks');
      }

      if (foundRect?.placard_procedure) {
        newConfirmations.push('placards');
      }

      if (foundRect?.operational_procedure) {
        newConfirmations.push('operations');
      }

      if (foundRect?.maintenance_procedure) {
        newConfirmations.push('maintenance');
      }
      setConfirmationChecks(newConfirmations);
    }
  }, [defect, displayDefect, melItem, rectificationId]);

  const checkAllConfirmed = (payloadKeys, payload): boolean => {
    const newPayloadKeys = Object.keys(payload);
    if (editResolved || !payload.deferred) return true;
    let allChecked = true;
    if ((payload?.defect_type === melNameOverride || payload.defect_type === 'MEL') && confirmationChecks.length > 0) {
      const cardsRequiringAction = [];
      MELReviewActionMap.forEach((item): void => {
        if (confirmationChecks.includes(item.check) && !newPayloadKeys.includes(item.card)) {
          raiseToast(
            formatMessage({ id: `text.${item.error}` }),
            formatMessage({ id: `text.${item.message}` }),
            ToastCategories.FLAG,
          );
          cardsRequiringAction.push(item.card);
          allChecked = false;
        }
      });
      if (cardsRequiringAction.length > 0) document.getElementById(cardsRequiringAction[0]).scrollIntoView();
    }
    return allChecked;
  };

  const nullOrInteger = (value: string | null | number): null | number => {
    if (value === null) {
      return null;
    }
    return value === '' ? null : Number(value);
  };

  const hoursToSeconds = (seconds: null | number): null | number => {
    const value = nullOrInteger(seconds);

    return value ? moment.duration(value, 'hours').asSeconds() : value;
  };

  const hasValidCatALimits = (recTitle, payload): boolean => {
    if (payload.defect_type === 'NEF' || (payload.defect_type === 'MEL' && aircraftHasMEL) || !defectDeferred) {
      return true;
    }
    if (recTitle !== 'A') {
      return true;
    }
    if (editResolved) return true;

    const requiredCatALimitFields = [
      'flightHoursLimit',
      'flight_seconds_limit',
      'hoursLimit',
      'apu_seconds_limit',
      'flightDaysLimit',
      'flight_days_limit',
      'seconds_limit',
      'cyclesLimit',
      'cycles_limit',
      'flights_limit',
      'flightLimit',
      'calendarDaysLimit',
      'calendar_days_limit',
      'APUHoursLimit',
      'other_limit',
      'otherLimit',
    ];

    const filledFields = requiredCatALimitFields.filter((field) => payload[field]);

    if (filledFields.length === 0) {
      raiseToast(
        formatMessage({ id: 'text.categoryALimitsError' }),
        formatMessage({ id: 'text.categoryALimits' }),
        ToastCategories.FLAG,
      );
      document.getElementById('rectificationLimits').scrollIntoView();
    }

    return filledFields.length > 0;
  };

  const hasDefectTypeSelected = (payload, recTitle): boolean => {
    if (!defectDeferred || editResolved) return true;
    const type = !!payload?.defect_type;
    let errorHeader = '';
    let errorMessage = '';
    if (!type) {
      errorHeader = 'text.defectTypeError';
      errorMessage = 'text.pleaseSelectDefectType';
    } else if (
      !recTitle &&
      (payload.defect_type === 'CDL' || payload.defect_type === 'Other' || payload.defect_type === 'CAS')
    ) {
      errorHeader = 'text.rectificationCategoryError';
      errorMessage = 'text.pleaseSelectRectificationCategory';
    }
    const deferralOptionsElement = document.getElementById('DeferralOptions');
    if (deferralOptionsElement && errorHeader) {
      raiseToast(formatMessage({ id: errorHeader }), formatMessage({ id: errorMessage }), ToastCategories.FLAG);
      deferralOptionsElement.scrollIntoView();
      return false;
    }
    return type;
  };

  const ataComplete = (payload): boolean => {
    if (
      !ATAChapter &&
      (!payload.deferred ||
        (payload.deferred &&
          (payload?.defect_type === 'CDL' || payload?.defect_type === 'CAS' || payload?.defect_type === 'Other')))
    ) {
      raiseToast(
        formatMessage({ id: 'text.ataError' }),
        formatMessage({ id: 'text.ataCannotBeEmpty' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    return true;
  };

  const checkLimitations = (limitations): boolean => {
    if (editResolved) return true;
    if (limitations === '') {
      raiseToast(
        formatMessage({ id: 'text.limitationsError' }),
        formatMessage({ id: 'text.limitationsPleaseAdd' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    return true;
  };

  const validateLength = (value, elementId: string, length?): boolean => {
    const formattedElementId = elementId.charAt(0).toUpperCase() + elementId.slice(1);
    if (editResolved) return true;
    if (!value || value?.length < (length || 5)) {
      raiseToast(
        formatMessage({ id: `text.${elementId}Error` }),
        formatMessage({ id: `text.pleaseEnter${formattedElementId}` }),
        ToastCategories.FLAG,
      );
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView();
        element.focus();
      }

      return false;
    }
    return true;
  };

  const checkReasonToDeferDropdown = (value): boolean => {
    if (!value) {
      raiseToast(
        formatMessage({ id: `text.reasonToDeferError` }),
        formatMessage({ id: `text.pleaseEnterReasonToDefer` }),
        ToastCategories.FLAG,
      );
      const element = document.getElementById('reasonToDefer');
      if (element) {
        element.scrollIntoView();
        element.focus();
      }
      return false;
    }
    return true;
  };

  const checkRectificationExtensions = (payload): boolean => {
    if (payload.rectificationExtensionDateDue && payload.rectificationExtenstionExtendedOn) {
      return true;
    }
    if (payload.rectificationExtensionDateDue && !payload.rectificationExtenstionExtendedOn) {
      raiseToast(
        formatMessage({ id: 'text.rectificationExtensionError' }),
        formatMessage({ id: 'text.pleaseAddArectificationExtendedOnDate' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    if (!payload.rectificationExtensionDateDue && payload.rectificationExtenstionExtendedOn) {
      raiseToast(
        formatMessage({ id: 'text.rectificationExtensionError' }),
        formatMessage({ id: 'text.pleaseAddARectificationNewDateDue' }),
        ToastCategories.FLAG,
      );
      return false;
    }
    return true;
  };

  const isFormValid = (payload, recTitle, payloadKeys): boolean => {
    const isReferenceNotRequired = payload?.defect_type === 'MEL' && list?.length;
    let valid = true;
    if (!validateLength(payload.details, 'details')) valid = false;
    if (!hasDefectTypeSelected(payload, recTitle)) valid = false;
    if (!(ataSelectionRequired ? ataComplete(payload) : true)) valid = false;
    if (!hasValidCatALimits(recTitle, payload)) valid = false;
    if (!checkAllConfirmed(payloadKeys, payload)) valid = false;
    if (!checkLimitations(payload.limitations)) valid = false;
    if (
      !(troubleShootingStepsRequired && defectDeferred
        ? validateLength(payload?.troubleshooting_steps, 'troubleshootingSteps', 2)
        : true)
    )
      valid = false;
    if (
      !(reasonToDeferRequired && !reasonToDeferAsDropdown && defectDeferred
        ? validateLength(payload?.reason_to_defer, 'reasonToDefer')
        : true)
    )
      valid = false;
    if (!(referenceRequired && !isReferenceNotRequired ? validateLength(payload?.reference, 'reference') : true))
      valid = false;
    if (
      !(reasonToDeferAsDropdown && reasonToDeferRequired && defectDeferred
        ? checkReasonToDeferDropdown(payload?.reason_to_defer)
        : true)
    )
      valid = false;
    if (!checkRectificationExtensions(payload)) valid = false;
    return valid;
  };

  const correctDefectType = (type: string): string => {
    if (type === melNameOverride) return 'MEL';
    if (type === cdlNameOverride) return 'CDL';
    if (type === nefNameOverride) return 'NEF';
    if (type === casNameOverride) return 'CAS';
    if (type === otherNameOverride) return 'Other';
    return type;
  };

  const getRectificationCategory = (deferred, defType, recTitle): null | string => {
    if (!deferred || defType === 'NEF') {
      return null;
    }

    return recTitle;
  };

  const handleFormSubmit = (confirmedIntermittentFaultConvert?: boolean): void => {
    const payload = defectData;
    const payloadKeys = [];
    let recTitle = rectificationCategory?.title || defectData?.display_data?.category;
    if (rectificationCategory?.title === 'Non Deferred' || defectData?.display_data?.category === 'Non Deferred') {
      recTitle = 'NONDEFERRED';
    }
    if (rectificationCategory?.title === 'Advisory only' || defectData?.display_data?.category === 'Advisory only') {
      recTitle = 'ADVISORYONLY';
    }
    payload.flightHoursLimit = hoursToSeconds(payload?.flightHoursLimit);
    payload.hoursLimit = hoursToSeconds(payload?.hoursLimit);
    payload.flightDaysLimit = nullOrInteger(payload?.flightDaysLimit);
    payload.cyclesLimit = nullOrInteger(payload?.cyclesLimit);
    payload.flightLimit = nullOrInteger(payload?.flightLimit);
    payload.calendarDaysLimit = nullOrInteger(payload?.calendarDaysLimit);
    payload.APUHoursLimit = hoursToSeconds(payload?.APUHoursLimit);
    payload.otherLimit = payload?.otherLimit || null;

    const isMel = payload?.defect_type === 'MEL';
    let chosenRectification;
    if (isMel) chosenRectification = melItem?.mel_rectifications.find((item) => item.id === rectificationId);

    const correctedDefectType = correctDefectType(payload?.defect_type);
    if (isFormValid(payload, recTitle, payloadKeys)) {
      const deferred = defectData?.deferred;
      const defectPayload: any = {
        body: {
          ...defectData,
          attachments_attributes: attachments,
          deferred: defectData?.deferred,
          flight_id: defectData?.flight_id,
          defect_type: correctedDefectType,
          ata_chapter: parseInt(ATAChapter, 10),
          ata_section: ATASection,
          reference: reference || payload?.reference,
          rectification_category: getRectificationCategory(deferred, correctedDefectType, recTitle),
          reason_to_defer: payload?.reason_to_defer,
          flight_seconds_limit:
            isMel && chosenRectification ? chosenRectification?.flight_seconds_limit : payload?.flight_seconds_limit,
          seconds_limit: isMel && chosenRectification ? chosenRectification?.seconds_limit : payload?.seconds_limit,
          flight_days_limit:
            isMel && chosenRectification ? chosenRectification?.flight_days_limit : payload?.flight_days_limit,
          cycles_limit: isMel && chosenRectification ? chosenRectification?.cycles_limit : payload?.cycles_limit,
          flights_limit: isMel && chosenRectification ? chosenRectification?.flights_limit : payload?.flights_limit,
          calendar_days_limit:
            isMel && chosenRectification ? chosenRectification?.calendar_days_limit : payload?.calendar_days_limit,
          apu_seconds_limit:
            isMel && chosenRectification ? chosenRectification?.apu_seconds_limit : payload?.apu_seconds_limit,
          other_limit: isMel && chosenRectification ? chosenRectification?.other_limit : payload?.other_limit,
          limitations: payload?.limitations,
        },
      };
      if (deferredTime && defectData?.deferred) {
        defectPayload.body.deferred_at = deferredTime.toISOString();
      }
      if (defectData?.deferred) {
        defectPayload.deferred_by_id = deferredById;
      }
      if (!defectData?.deferred) {
        defectPayload.body.rectification_category = 'NONDEFERRED';
        defectPayload.body.defect_type = null;
        defectPayload.body.limitations = null;
        defectPayload.body.defer_approval_reference = null;
        defectPayload.body.reason_to_defer = null;
      }

      if (mode === 'edit') {
        if (payload.hasRectificationExtension === 'Yes') {
          if (defect.rectification_interval_extension) {
            defectPayload.body.rectification_interval_extension_attributes = {
              id: defect.rectification_interval_extension.id,
              extension_date: payload.rectificationExtenstionExtendedOn,
              extension_date_due: payload.rectificationExtensionDateDue,
            };
          } else {
            defectPayload.body.rectification_interval_extension_attributes = {
              extension_date: payload.rectificationExtenstionExtendedOn,
              extension_date_due: payload.rectificationExtensionDateDue,
            };
          }
        } else if (defect.rectification_interval_extension) {
          defectPayload.body.rectification_interval_extension_attributes = {
            id: defect.rectification_interval_extension.id,
            _destroy: true,
          };
        }
      }

      if (
        (payload?.defect_type === melNameOverride || payload?.defect_type === 'MEL') &&
        melItem &&
        defectData?.deferred
      ) {
        defectPayload.body.mel_item_id = melItem.id;
      } else {
        defectPayload.body.mel_item_id = null;
      }
      if (
        (payload?.defect_type === melNameOverride || payload?.defect_type === 'MEL') &&
        rectificationId &&
        defectData?.deferred
      ) {
        defectPayload.body.mel_rectification_id = rectificationId;
      } else {
        defectPayload.body.mel_rectification_id = null;
      }
      if (defectId) {
        const aircraftOpId = aircraftMap.get(aircraftId || id)?.operator_id;
        const editedById = userSettings?.details?.people.find(
          (person) => person?.organisation?.id === aircraftOpId,
        )?.id;
        defectPayload.id = defectId;
        defectPayload.body.aircraft_id = aircraftId || id;
        defectPayload.body.edited_by_id = editedById;
        defectPayload.body.approval_signature_attributes = { data: signature };
        if (defectData?.deferred && !originalDefectData?.deferred_by?.id) {
          defectPayload.body.deferred_by_id = deferredById;
          defectPayload.body.deferral_signature_attributes = { data: signature };
        }
        setLoading(true);
        const res = dispatch(update({ payload: defectPayload }));
        Promise.all([res])
          .then((result) => {
            if (result === undefined) {
              setLoading(false);
              return;
            }
            resetForm();
            dispatch(
              changeDrawerContent({
                payload: { content: <DefectDrawer defectId={defectId} /> },
              }),
            );
            setFormChanged(false);
            dispatch(changeDrawerMode({ payload: 'view' }));
          })
          .catch((err) => {
            setLoading(false);
            const message = coreErrorMessageFormatter(err);
            dispatch(
              addToast({
                payload: {
                  title: formatMessage({ id: 'message.defectUpdateFail' }),
                  type: ToastTypes.ERROR,
                  category: ToastCategories.FLAG,
                  message,
                },
              }),
            );
          });
      } else {
        defectPayload.id = aircraftId;
        defectPayload.body.reported_by_id = deferredById || userSettings?.details?.people[0]?.id;
        if (defectData?.deferred) {
          defectPayload.body.deferred_by_id = deferredById;
          defectPayload.body.deferral_signature_attributes = { data: signature };
        }
        defectPayload.body.signature_attributes = { data: signature };

        if (newFlight && !passedFlightId) {
          setLoading(true);
          defectPayload.body.aircraft_id = id;
          dispatch(handleBackButtonClickWithPayload({ payload: { type: 'defect', content: defectPayload.body } }));
        } else {
          if (isPassedIntermittentFault) {
            if (!confirmedIntermittentFaultConvert) {
              setModalVisible(true);
              return;
            }
            const newAttachments = defectPayload.body.attachments_attributes.filter((attachment) => !attachment.id);
            defectPayload.body.attachments_attributes = newAttachments;
            defectPayload.body.attachments = newAttachments;
          }
          setLoading(true);
          const res = dispatch(submitDefect({ payload: defectPayload }));

          new Promise((resolve) => resolve(res)).then((response) => {
            const newDefect = response as any;
            if (newDefect) {
              if (passedFlightId) {
                dispatch(handleBackButtonClick());
                dispatch(setDrawerId({ payload: passedFlightId }));
              } else {
                resetForm();
                dispatch(
                  changeDrawerContent({
                    payload: { content: <DefectDrawer defectId={newDefect.id} /> },
                  }),
                );
                setFormChanged(false);
                dispatch(changeDrawerMode({ payload: 'view' }));
              }
            } else {
              raiseToast(
                formatMessage({ id: 'text.creationError' }),
                formatMessage({ id: 'text.errorCreatingDefect' }),
                ToastCategories.FLAG,
              );
            }
          });
        }
      }
    }
  };

  const handleFormChange = (): void => {
    setFormChanged(true);
  };

  const handleMelItemChange = (newMelId: string, rectId: string): void => {
    if (prevMelItemId !== '' && melItemId !== prevMelItemId) {
      setFormChanged(true);
    }
    setMelItemId(newMelId);
    setRectificationId(rectId);
  };

  const cancelClick = (): void => {
    if (formChanged && (mode === 'edit' || mode === 'add')) {
      dispatch(changeModalVisibility({ payload: true }));
    } else {
      if (drawerHistory) {
        dispatch(handleBackButtonClick());
      } else {
        dispatch(changeDrawerVisibility({ payload: false }));
      }
      if (defect?.approval_signature_image_url) {
        setSignature(defect?.approval_signature_image_url);
      } else {
        setSignature('');
      }
      setFormChanged(false);
    }
  };

  const handleDeferDefect = (value: boolean): void => {
    setDefectDeferred(value);
    setDeferredTime(moment.utc());
  };

  useEffect(() => {
    if (!_.isEqual(defectsMap, prevDefectsMap) || !_.isEqual(prevDrawerDefect, drawerDefect)) {
      setLoading(false);
    }
  }, [defectsMap, prevDefectsMap]);

  useEffect(() => {
    if (drawerDefect) {
      setLoading(false);
    }
  }, [drawerDefect]);

  useEffect(() => {
    if (id) setAircraftId(id);
  }, [id, mode]);

  useEffect(() => {
    if (formChanged) {
      setSignature('');
      dispatch(setDrawerChanges({ payload: true }));
    }
  }, [formChanged]);

  useEffect(() => {
    if (defectId && defectId !== defect?.id) {
      const payload = { id: defectId };
      setLoading(true);
      dispatch(getDefectForDrawer(payload));
    }
  }, [defect, defectId, dispatch]);

  useEffect(() => {
    if (defect) {
      if (deferDefect) {
        defect = {
          ...defect,
          deferred: true,
          deferred_at: moment.utc().toISOString(),
        };
      }
      setDefectData(defect);
      setOriginalDefectData(defect);
      setOriginalDeferredById(defect?.deferred_by?.id);
      setSignature(defect?.approval_signature_image_url);
      if (!deferDefect) setDefectDeferred(defect?.deferred);
      if (defect?.deferred) {
        if (defect?.deferred_at) {
          setDeferredTime(moment.utc(defect?.deferred_at));
        } else {
          setDeferredTime(moment.utc());
        }
      }
      setDeferredById(defect?.deferred_by?.id);
      if (defect?.deferral_approval_number || defect?.deferral_licence_number) setSignOffAsEnginner(true);
    } else if (passedFlightId) {
      setDefectData({
        raised_at: moment.utc().toISOString(),
        flight_id: passedFlightId,
      });
      setOriginalDefectData({
        raised_at: moment.utc().toISOString(),
        flight_id: passedFlightId,
      });
    } else {
      setDefectData({
        raised_at: moment.utc().toISOString(),
      });
      setOriginalDefectData({
        raised_at: moment.utc().toISOString(),
      });
    }
  }, [defect]);

  useEffect(() => {
    if (passedFlightId) {
      updateDefectData([{ value: passedFlightId, key: 'flight_id' }]);
    }
  }, [passedFlightId]);

  useEffect(() => {
    if (deferDefect) {
      setDefectDeferred(true);
      updateDefectData([{ value: true, key: 'deferred' }]);
    }
  }, [deferDefect]);

  useEffect(() => {
    if (defectDeferred) {
      const header = document.getElementById('DefectTitle');
      if (header) {
        header.scrollIntoView();
      }
    }
  }, [defectDeferred]);

  useEffect(() => {
    if (revertChanges) {
      resetForm();
      if (defectId) {
        const payload = { id: defectId };
        setLoading(true);
        dispatch(getDefectForDrawer(payload));
        setFormChanged(false);
      } else {
        setDefectData({
          raised_at: moment.utc().toISOString(),
        });
        setOriginalDefectData({
          raised_at: moment.utc().toISOString(),
        });
        setDefectDeferred(false);
        setDefectType('');
        setSignature('');
        setRectificationCategory(null);
        setFormChanged(false);
        setATAChapter(null);
        setATASection(null);
      }
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
  }, [revertChanges]);

  useEffect(() => {
    if (displayDefect && prevdisplayDefect === null && defect === null && mode !== 'add') {
      resetForm();
    }
  }, [prevdisplayDefect, defect]);

  useEffect(() => {
    const tfDrawerWrapper = document.getElementById('tfDrawerWrapper');
    if (tfDrawerWrapper) {
      if (loading) {
        tfDrawerWrapper.setAttribute('style', 'overflow: hidden');
      } else if (window.innerWidth > 450) {
        tfDrawerWrapper.setAttribute('style', 'overflow: scroll');
      }
    }
  }, [loading]);

  useEffect(() => {
    const customiser = (objValue, otherValue, key): boolean => {
      if (key === 'raised_at') {
        if (moment(objValue).format('YYYY-MM-DD HH:mm') === moment(otherValue).format('YYYY-MM-DD HH:mm')) return true;
      }
      if (key === 'versions') return true;
      return undefined;
    };

    if (
      !_.isEqualWith(defectData, originalDefectData, customiser) ||
      !_.isEqualWith(deferredById, originalDeferredById)
    ) {
      setFormChanged(true);
      setSignature('');
    } else {
      setFormChanged(false);
    }
  }, [defectData, originalDefectData, deferredById, originalDeferredById]);

  let buttonDisabled = false;
  if (signature && !formChanged && defect) {
    buttonDisabled = true;
  } else if (!signature) {
    buttonDisabled = true;
  } else if (defectDeferred && defectType === 'MEL' && !melItem?.id && list?.length) {
    buttonDisabled = true;
  }
  const extendableRectificationCategories = ['B', 'C', 'D'];

  const showRectificationExtensionCard = (): boolean => {
    if (!rectificationCategory) {
      return false;
    }

    return mode === 'edit' && defectDeferred && extendableRectificationCategories.includes(rectificationCategory.title);
  };

  useEffect(() => {
    if (passedData && passedData.type === 'fault') {
      setIsPassedIntermittentFault(true);
      const passedFaultData = passedData.content as IntermittentFault;
      const IntermittentFaultID = passedFaultData.id;
      delete passedFaultData.id;
      setDefectData({
        ...passedFaultData,
        raised_at: passedFaultData.reported_at,
        intermittent_fault_id: IntermittentFaultID,
      });
    }
  }, [passedData]);

  const rectificationExtensionCard = (): null | ReactElement => {
    if (!showRectificationExtensionCard()) {
      return null;
    }
    return (
      <Card marginTop={20}>
        <RectificationIntervalExtension
          defect={defect}
          onChange={handleFormChange}
          updateDefectData={updateDefectData}
        />
      </Card>
    );
  };
  return (
    <DrawerWrapper id="DefectDrawerWrapper" data-testid="DefectDrawer--DrawerWrapper">
      <Loading loading={loading} contain />
      <DefectHeader
        defect={defectData}
        acId={aircraftId}
        setAircraftId={setAircraftId}
        updateDefectData={updateDefectData}
        setDefectDeferred={handleDeferDefect}
        disableFlightSelection={newFlight && passedFlightId === undefined}
        editResolvedLocal={editResolvedLocal}
      />
      <DefectDetails
        defect={defectData}
        originalDefectData={originalDefectData}
        updateDefectData={updateDefectData}
        editDefect={mode === 'edit' || mode === 'add'}
        signature={signature}
        attachments={attachments}
        setAttachments={setAttachments}
        defectDeferred={defectDeferred}
        rectificationCategory={rectificationCategory}
        setRectificationCategory={setRectificationCategory}
        apuInstalled={chosenAircraft?.apu_installed}
        reference={reference}
        setReference={setReference}
        ATAChapter={ATAChapter}
        setATAChapter={setATAChapter}
        ATASection={ATASection}
        setATASection={setATASection}
        deferredTime={deferredTime}
        setDeferredTime={setDeferredTime}
        aircraftId={aircraftId}
        eropsTitle={eropsTitle}
        poIntl={poIntl}
      />
      {(mode === 'edit' || mode === 'add') && (
        <>
          <AddDeferralTile
            updateDefectData={updateDefectData}
            defectDeferred={defectDeferred}
            setDefectDeferred={handleDeferDefect}
            setDefectType={setDefectType}
          >
            {defectDeferred ? (
              <>
                <DeferralOptions
                  defect={defectData}
                  originalDefectData={originalDefectData}
                  updateDefectData={updateDefectData}
                  editDefect={mode === 'edit' || mode === 'add'}
                  rectificationCategory={rectificationCategory}
                  setRectificationCategory={setRectificationCategory}
                  aircraftId={aircraftId}
                  setMelItem={setMelItem}
                  setRectificationId={setRectificationId}
                  melItemsLoading={melItemsLoading && !loading}
                  setMelItemsLoading={setMelItemsLoading}
                  handleMelItemChange={handleMelItemChange}
                  defectType={defectType}
                  setDefectType={setDefectType}
                  displayDefect={displayDefect}
                  setDisplayDefect={setDisplayDefect}
                  apuInstalled={chosenAircraft?.apu_installed}
                  deferredTime={deferredTime}
                  defectDeferred={defectDeferred}
                  setDeferredTime={setDeferredTime}
                  reference={reference}
                  setReference={setReference}
                  ATAChapter={ATAChapter}
                  setATAChapter={setATAChapter}
                  ATASection={ATASection}
                  setATASection={setATASection}
                  setAircraftHasMEL={setAircraftHasMEL}
                  poIntl={poIntl}
                />
                <BorderLine />
                <AircraftLimitations
                  defect={defectData}
                  editDefect={mode === 'edit' || mode === 'add'}
                  updateDefectData={updateDefectData}
                  ignoreEROPS={ingoreEROPSWarnings}
                  limitations={defectData.limitations}
                  sectorsNameOverride={sectorsNameOverride}
                  eropsTitle={eropsTitle}
                />
                <BorderLine marginTop="20px" />
                <AdditionalInformation defect={defectData} updateDefectData={updateDefectData} />
                {(defectType === melNameOverride || defectType === 'MEL') && formChanged && (
                  <ConfirmSection
                    defect={defectData}
                    melItem={melItem}
                    rectificationId={rectificationId}
                    updateDefectData={updateDefectData}
                  />
                )}
                <BorderLine marginTop="20px" />
                <DeferralSignoffDetails
                  defect={defectData}
                  operatorID={chosenAircraft?.operator_id}
                  deferredById={deferredById}
                  setDeferredById={setDeferredById}
                  updateDefectData={updateDefectData}
                  signOffAsEngineer={signOffAsEngineer}
                  setSignOffAsEngineer={setSignOffAsEnginner}
                />

                {defect?.mx_events.length > 0 ? (
                  <ResolutionDetails
                    release={defect?.mx_events[defect?.mx_events.length - 1]}
                    poNum={defect?.mx_events[defect?.mx_events.length - 1]?.workpack_reference}
                  />
                ) : null}
              </>
            ) : null}
          </AddDeferralTile>
          {rectificationExtensionCard()}
        </>
      )}
      {mode === 'edit' || mode === 'add' || editResolved ? (
        <>
          {(formChanged || !defect) && (
            <SignatureSection
              handleSignature={handleSignature}
              signature={signature}
              formChanged={formChanged}
              mode={mode}
              revertChanges={revertChanges}
            />
          )}
          <ButtonSection
            handleCancel={cancelClick}
            handleFormSubmit={() => handleFormSubmit(false)}
            loading={melItemsLoading || loading}
            disabled={buttonDisabled}
          />
        </>
      ) : null}
      <Modal width={420} isOpen={modalVisible} handleClose={(): void => setModalVisible(false)}>
        <ModalContentWrapper>
          <ModalTitle>{formatMessage({ id: 'title.convertDefect' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureConvertFault',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubminButtonWraper>
              <Button
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                onClick={(): void => {
                  setModalVisible(false);
                  handleFormSubmit(true);
                  history.push(`/aircraft/${id}/defects/log`);
                }}
              >
                Confirm
              </Button>
            </SubminButtonWraper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => {
                setModalVisible(false);
                setLoading(false);
              }}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalContentWrapper>
      </Modal>
      {mode === 'view' ? <HistorySection title="defect" versions={defectData?.versions} /> : null}
    </DrawerWrapper>
  );
};

export default DefectDrawer;
