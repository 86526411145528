/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Defect } from '../../../models/defects';
import { DashboardState } from '../../../models';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface DefectNonMelItemsProps {
  defectType: string;
  defect: Defect | null;
  originalDefectData: Defect | null;
  rectificationCategory?: { title: string; colour?: string };
  setRectificationCategory?: (option: { title: string; colour?: string }) => void;
  deferred: boolean;
  setDateDue: (input) => void;
  apuInstalled: boolean;
  deferredTime: Moment;
  setDeferredTime: (input: Moment) => void;
  reference: string;
  setReference: (value: string) => void;
  ATAChapter: string;
  setATAChapter: (value: string) => void;
  ATASection: string;
  setATASection: (value: number) => void;
  updateDefectData: (changes: any[]) => void;
  aircraftId?: string;
  poIntl: {
    po_short_name: string;
    po_long_name: string;
    po_suffix: string;
  };
}

const DefectNonMelItems: React.FC<DefectNonMelItemsProps> = ({
  defectType,
  defect,
  originalDefectData,
  rectificationCategory,
  setRectificationCategory,
  deferred,
  setDateDue,
  apuInstalled,
  deferredTime,
  setDeferredTime,
  reference,
  setReference,
  ATAChapter,
  setATAChapter,
  ATASection,
  setATASection,
  updateDefectData,
  aircraftId,
  poIntl,
}) => {
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const cdlNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.name_override;
  const cdlLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.long_name_override;
  const nefNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.name_override;
  const nefLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.long_name_override;
  const casNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.name_override;
  const casLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.long_name_override;
  const otherNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other?.name_override;
  const otherLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other
    ?.long_name_override;

  const handleReferenceChange = (e): void => {
    const value = e?.target?.value;
    setReference(value);
    updateDefectData([{ value, key: 'reference' }]);
  };

  useEffect(() => {
    if (defectType) {
      if (defect?.display_data?.category) {
        let recCat = defect?.display_data?.category;
        if (recCat === 'ADVISORYONLY') recCat = 'Advisory only';
        if (recCat === 'NONDEFERRED') recCat = 'A';
        if (recCat !== defect?.display_data?.category) {
          setRectificationCategory({ title: recCat });
          updateDefectData([
            { value: recCat, key: 'rectification_category' },
            { value: recCat, key: 'display_data.category' },
          ]);
        }
      }
    }
    if (!deferred) {
      setRectificationCategory({ title: 'Non Deferred' });
      updateDefectData([
        { value: 'Non Deferred', key: 'rectification_category' },
        { value: 'Non Deferred', key: 'display_data.category' },
      ]);
    }
  }, [defectType]);

  useEffect(() => {
    if (defect && defect.mel_item) {
      setATAChapter(defect?.display_data?.ata?.split(' ')[0]);
      setATASection(defect?.display_data?.ata_section);
      setReference(defect?.reference);
    }
  }, [defect]);

  let displayOption;
  let displayDefectType = cdlNameOverride
    ? `${cdlNameOverride}${cdlLongNameOverride ? ` - ${cdlLongNameOverride}` : ''}`
    : 'CDL - Configuration Deviation List';
  if (defectType === casNameOverride || defectType === 'CAS')
    displayDefectType = casNameOverride
      ? `${casNameOverride}${casLongNameOverride ? ` - ${casLongNameOverride}` : ''}`
      : 'CAS - Crew Alerting System';
  if (defectType === otherNameOverride || defectType === 'Other')
    displayDefectType = otherNameOverride
      ? `${otherNameOverride}${otherLongNameOverride ? ` - ${otherLongNameOverride}` : ''}`
      : 'Other';
  if (
    defectType === cdlNameOverride ||
    defectType === 'CDL' ||
    defectType === casNameOverride ||
    defectType === 'CAS' ||
    defectType === otherNameOverride ||
    defectType === 'Other'
  )
    displayOption = (
      <>
        <FlexWrapper column>
          <Label marginBottom={15} fontWeight={500}>
            {!deferred ? 'Do Not Defer' : displayDefectType}
          </Label>
          <ManuallyEnterMELDetails
            defect={defect}
            originalDefectData={originalDefectData}
            rectificationCategory={rectificationCategory}
            setRectificationCategory={setRectificationCategory}
            deferred={deferred}
            setDateDue={setDateDue}
            apuInstalled={apuInstalled}
            reference={reference}
            setReference={setReference}
            ATAChapter={ATAChapter}
            setATAChapter={setATAChapter}
            ATASection={ATASection}
            setATASection={setATASection}
            deferredTime={deferredTime}
            setDeferredTime={setDeferredTime}
            updateDefectData={updateDefectData}
            poIntl={poIntl}
          />
        </FlexWrapper>
      </>
    );
  if (defectType === nefNameOverride || defectType === 'NEF')
    displayOption = (
      <FlexWrapper column>
        <Label marginBottom={15} fontWeight={500}>
          {nefNameOverride
            ? `${nefNameOverride}${nefLongNameOverride ? ` - ${nefLongNameOverride}` : ''}`
            : formatMessage({ id: 'text.nefExpanded' })}
        </Label>
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage(
            { id: 'text.pleaseEnterReferenceNumber' },
            { po: poIntl.po_short_name, suffix: poIntl.po_suffix },
          )}
        </Label>
        <Label marginBottom={5} fontWeight={500}>
          {formatMessage({ id: 'title.reference' })}
        </Label>
        <StyledInput id="reference" defaultValue={reference} type="text" width={280} onChange={handleReferenceChange} />
      </FlexWrapper>
    );
  return displayOption;
};

export default DefectNonMelItems;
