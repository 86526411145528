import React from 'react';
import PropTypes from 'prop-types';
import TFTag from '../TFTag/TFTag';
import iconPackage from './assets/icon-package.svg';
import styles from './SmallTag.module.less';

const SmallTag = ({ type, isPackage, packageLength }) => {
  return (
    <TFTag type={type} data-test="itemTag">
      {isPackage ? (
        <>
          <img src={iconPackage} alt="iconPackage" className={styles.iconPkg} data-test="iconPackage" />{' '}
          {packageLength || ''}
        </>
      ) : (
        type
      )}
    </TFTag>
  );
};

SmallTag.propTypes = {
  type: PropTypes.string.isRequired,
  isPackage: PropTypes.bool,
  packageLength: PropTypes.number,
};

SmallTag.defaultProps = {
  isPackage: false,
  packageLength: null,
};

export default SmallTag;
