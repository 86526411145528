import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';

interface CustomFieldsSectionProps {
  icon: string;
  title: string;
  viewComponent: ReactElement;
  editComponent: ReactElement;
}

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  letter-spacing: 0.06px;
  line-height: 1.5;
`;

const StyledIcon = styled.img`
  margin-right: 8px;
  width: 30px;
  height: 30px;
`;

const CustomFieldsSection: React.FC<CustomFieldsSectionProps> = ({ icon, title, viewComponent, editComponent }) => {
  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);
  return (
    <Wrapper>
      <TitleWrapper>
        <StyledIcon src={icon} alt="icon" />
        <Title marginBottom="0">{title}</Title>
      </TitleWrapper>
      {mode === 'view' ? viewComponent : editComponent}
    </Wrapper>
  );
};

export default CustomFieldsSection;
