import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import HeightUpdater from './HeightUpdater';
import ScrollToTop from './ScrollToTop';

interface SwipeableBottomSheetProps {
  bodyStyle?: React.CSSProperties;
  children: React.ReactNode;
  defaultOpen?: boolean;
  fullScreen?: boolean;
  marginTop?: number;
  onChange?: (open: boolean) => void;
  open?: boolean;
  overflowHeight?: number;
  overlay?: boolean;
  overlayStyle?: React.CSSProperties;
  scrollTopAtClose?: boolean;
  shadowTip?: boolean;
  style?: React.CSSProperties;
  topShadow?: boolean;
}

const SwipeableBottomSheet: React.FC<SwipeableBottomSheetProps> = ({
  bodyStyle,
  children,
  defaultOpen = false,
  fullScreen = false,
  marginTop = 0,
  onChange,
  open: controlledOpen,
  overflowHeight = 0,
  overlay = true,
  overlayStyle,
  scrollTopAtClose = true,
  shadowTip = true,
  style,
  topShadow = true,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [height, setHeight] = useState(window.innerHeight);

  const onHeightChange = (newHeight: number) => {
    setHeight(newHeight);
  };

  const onChangeIndex = (index: number) => {
    const newOpen = index === 1;
    if (controlledOpen === undefined) {
      setOpen(newOpen);
    }
    if (onChange !== undefined) {
      onChange(newOpen);
    }
  };

  let bodyElt: HTMLElement | null = null;

  const onTransitionEnd = () => {
    if (overflowHeight === 0) {
      if (bodyElt) bodyElt.scrollTop = 0;
    }
  };

  const hiddenWhenClosed = overflowHeight === 0;
  const isControlled = controlledOpen !== undefined;
  const isOpen = isControlled ? controlledOpen : open;
  const hideShadows = hiddenWhenClosed && !isOpen;
  const index = isOpen ? 1 : 0;
  const maxHeight = height - marginTop;

  const styles: any = {
    root: {
      height: overflowHeight,
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      ...style,
    },
    swiper: {
      root: {
        overflowY: 'initial',
        boxSizing: 'border-box',
      },
      container: {
        boxSizing: 'border-box',
        ...(topShadow &&
          !hideShadows && {
            boxShadow: 'rgba(0, 0, 0, 0.156863) 0px -6px 5px',
          }),
      },
      slide: {
        boxSizing: 'border-box',
        overflow: 'visible',
        marginBottom: -overflowHeight,
      },
      bottomSlide: {
        marginBottom: overflowHeight,
      },
      body: {
        overflow: isOpen ? 'auto' : 'hidden',
        backgroundColor: 'white',
        height: fullScreen ? maxHeight : 'initial',
        maxHeight,
        ...bodyStyle,
      },
    },
    overlay: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      height,
      transition: 'opacity 450ms',
      pointerEvents: 'none',
      backgroundColor: 'black',
      opacity: 0,
      ...(isOpen && {
        opacity: 0.54,
        pointerEvents: 'auto',
      }),
      ...overlayStyle,
    },
    shadowTip: {
      position: 'fixed',
      height: 60,
      width: '200%',
      bottom: -60,
      left: '-50%',
      boxShadow: 'rgba(0, 0, 0, 0.7) 0px 0px 30px',
      transition: 'transform 450ms',
      transform: isOpen ? 'translateY(50px)' : 'translateY(0)',
    },
  };

  return (
    <div style={styles.root}>
      <HeightUpdater height={height} onHeightChange={onHeightChange} />

      {overlay && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div style={styles.overlay} onClick={() => onChangeIndex(0)} />
      )}
      <SwipeableViews
        index={index}
        axis="y"
        enableMouseEvents
        onChangeIndex={onChangeIndex}
        onTransitionEnd={onTransitionEnd}
        style={styles.swiper.root}
        containerStyle={styles.swiper.container}
        slideStyle={styles.swiper.slide}
      >
        <div
          // eslint-disable-next-line no-return-assign
          ref={(elt) => (bodyElt = elt)}
          style={styles.swiper.body}
          className={`ReactSwipeableBottomSheet--${isOpen ? 'open' : 'closed'}`}
        >
          {children}
        </div>
        <div style={styles.swiper.bottomSlide} />
      </SwipeableViews>
      {shadowTip && !hideShadows && <div style={styles.shadowTip} />}
      {!isOpen && scrollTopAtClose && !hiddenWhenClosed && <ScrollToTop element={() => bodyElt} />}
    </div>
  );
};

SwipeableBottomSheet.propTypes = {
  bodyStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  fullScreen: PropTypes.bool,
  marginTop: PropTypes.number,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  overflowHeight: PropTypes.number,
  overlay: PropTypes.bool,
  overlayStyle: PropTypes.object,
  scrollTopAtClose: PropTypes.bool,
  shadowTip: PropTypes.bool,
  style: PropTypes.object,
  topShadow: PropTypes.bool,
};

SwipeableBottomSheet.defaultProps = {
  defaultOpen: false,
  fullScreen: false,
  marginTop: 0,
  overflowHeight: 0,
  overlay: true,
  scrollTopAtClose: true,
  shadowTip: true,
  topShadow: true,
};

export default SwipeableBottomSheet;
