import { useMutation } from '@tanstack/react-query';
import { renameFRATCategory } from '../../services/api';

const useUpdateFRATCategory = ({ handeUpdateFRATCategoryOnSuccess, handeUpdateFRATCategoryOnError }) => {
  const renameCategory = useMutation({
    mutationFn: (payload: { id: string; flight_risk_assessment_question_category: { category: string } }) => {
      return renameFRATCategory(payload);
    },
    onSuccess: (data) => {
      handeUpdateFRATCategoryOnSuccess(data);
    },
    onError: (err: any) => handeUpdateFRATCategoryOnError(err),
  });

  return renameCategory;
};

export default useUpdateFRATCategory;
