import { useQuery } from '@tanstack/react-query';
import { queryDefectsForWorkpacks } from '../../../services/api';

const useQueryDefectsForWorkpacks = ({ id }: { id: string }): { data: any; isLoading: boolean } => {
  const { data, isLoading } = useQuery({
    queryKey: ['queryDefectsForWorkpacks', id],
    queryFn: () => {
      return queryDefectsForWorkpacks({ aircraft_id: id });
    },
    enabled: !!id,
  });

  return { data, isLoading };
};

export default useQueryDefectsForWorkpacks;
