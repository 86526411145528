/**
 * Smooth scrolls to a dom element
 * scrollTo -> The element to scroll to. Pass in either ref.current or a dom node resolved via querySelector.
 * scrollContainer -> The scrollTo element's container. Must have CSS property overflow scroll enabled.
 * useWindowAsSC -> Boolean. Optionally use window.global as the scroll container.
 * scrollOffset ->  Number. Optionally adjust the amount to offset the top scroll position by. Default is 0.
 */

const smoothScroller = ({ scrollTo, scrollContainer, useWindowAsSC, scrollOffset } = {}) => {
  if (useWindowAsSC) {
    window.scrollTo(0, scrollTo.offsetTop - scrollOffset);
  } else {
    scrollContainer.scrollTo({ top: scrollTo.offsetTop - scrollOffset, behavior: 'smooth' });
  }
};

const legacyScroll = ({ scrollTo, scrollContainer, useWindowAsSC, scrollOffset } = {}) => {
  if (useWindowAsSC) {
    window.scrollTop = scrollTo.offsetTop - scrollOffset;
  } else {
    /* eslint-disable no-param-reassign */
    scrollContainer.scrollTop = scrollTo.offsetTop - scrollOffset;
  }
};

// detect support for the behavior property in ScrollOptions
const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

const scrollToElem = ({ scrollTo, scrollContainer, useWindowAsSC = false, scrollOffset = 0 } = {}) => {
  if (supportsNativeSmoothScroll) {
    smoothScroller({ scrollTo, scrollContainer, useWindowAsSC, scrollOffset });
  } else {
    legacyScroll({ scrollTo, scrollContainer, useWindowAsSC, scrollOffset });
  }
};

export default scrollToElem;
