import { Form, Input, InputNumber, Radio, Select } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Modal, Button } from '@arcflight/tf-component-library';
import APUPopconfirm from '../Modals/APUPopconfirm/APUPopconfirm';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import { updateSkipAPI } from '../../models/aircraft/actions';
import { addToast, ToastCategories, ToastTypes } from '../../models/toasts';
import { updateAircraft } from '../../services/api';
import styles from './index.module.less';

const { Option } = Select;
const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const EditAircraftForm = ({ aircraft, onCancel, form }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const operators = useSelector((state) => state.userSettings?.details?.operators);
  const operatorSettings = operators.find((op) => op.id === aircraft?.operator_id)?.operator_setting;
  const maxWeightEnabled = operatorSettings?.max_weight_for_flight_enabled;
  const maxWeightNameOverride = operatorSettings?.max_weight_for_flight_name;
  const labelCol = { span: 7 };
  const wrapperCol = { span: 13 };
  const { validateFields, getFieldDecorator, getFieldValue } = form;

  const handleCancel = () => {
    onCancel();
  };

  const mutation = useMutation({
    mutationFn: updateAircraft,
    onSuccess: (data) => {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'message.aircraftUpdated' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['aircraft'] });
      handleCancel();
      dispatch(
        updateSkipAPI({
          payload: data.body,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'message.editAircraftError' }),
            message: err.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const aircraftValues = values;
        const updatedAircraft = { ...aircraft, ...aircraftValues };
        const id = aircraft.id || '';
        mutation.mutate({
          id,
          aircraftId: id,
          aircraft: updatedAircraft,
          update: true,
        });
      }
    });
  };

  return (
    <Modal isOpen handleClose={handleCancel} width={500}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {formatMessage({ id: 'title.editAircraft' }, { field: aircraft.registration })}
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <FormItem label={formatMessage({ id: 'form.labels.year' })} labelCol={labelCol} wrapperCol={wrapperCol}>
          {getFieldDecorator('year', {
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'message.addYearOfManufacture' }),
              },
            ],
            initialValue: aircraft.year,
          })(
            <InputNumber
              min={1903}
              placeholder={aircraft ? aircraft.year : ''}
              data-test="edit-aircraft--year-input"
            />,
          )}
        </FormItem>
        <FormItem label={formatMessage({ id: 'form.labels.serialNum' })} labelCol={labelCol} wrapperCol={wrapperCol}>
          {getFieldDecorator('serial_number', {
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'message.addSerialNum' }),
              },
            ],
            initialValue: aircraft.serial_number,
          })(
            <Input
              placeholder={aircraft ? aircraft.serial_number : ''}
              data-test="edit-aircraft--serial-number-input"
              spellCheck="false"
            />,
          )}
        </FormItem>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.fuelUnit' })}>
          {getFieldDecorator('fuel_unit', {
            initialValue: aircraft.fuel_unit || 'kg',
            rules: [{ required: true, message: formatMessage({ id: 'message.selectUnit' }) }],
          })(
            <RadioGroup data-test="edit-aircraft--fuel-unit-input">
              <RadioButton value="kg">{formatMessage({ id: 'units.kg' })}</RadioButton>
              <RadioButton value="lb">{formatMessage({ id: 'units.lb' })}</RadioButton>
              <RadioButton value="l">{formatMessage({ id: 'units.ltr' })}</RadioButton>
              <RadioButton value="usg">{formatMessage({ id: 'units.usg' })}</RadioButton>
            </RadioGroup>,
          )}
        </Form.Item>
        <Form.Item
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          label={formatMessage({ id: 'form.labels.fuelCapacity' })}
        >
          {getFieldDecorator('max_fuel_value', {
            initialValue: aircraft.max_fuel_value,
            rules: [{ required: true, message: formatMessage({ id: 'message.maximumFuel' }) }],
          })(<InputNumber placeholder="3500" min={0} data-test="edit-aircraft--max-fuel-input" />)}
          <span className={styles.maxFuelSpan}>{getFieldValue('fuel_unit')}</span>
        </Form.Item>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.oilUnit' })}>
          {getFieldDecorator('oil_unit', {
            initialValue: aircraft.oil_unit || 'qt',
            rules: [{ required: true, message: formatMessage({ id: 'message.oilUnit' }) }],
          })(
            <RadioGroup data-test="edit-aircraft--oil-unit">
              <RadioButton value="qt">qt</RadioButton>
              <RadioButton value="ml">ml</RadioButton>
            </RadioGroup>,
          )}
        </Form.Item>
        <Form.Item
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          label={formatMessage({ id: 'form.labels.apu' })}
          className={styles.apuFormItem}
          data-test="formAPU"
        >
          {getFieldDecorator('apu_mode', {
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'message.hasApu' }),
              },
            ],
            initialValue: aircraft.apu_mode,
          })(
            <Select style={{ width: 130, height: 34 }} data-test="apuSelector">
              <Option value="not_installed">{formatMessage({ id: 'text.notInstalled' })}</Option>
              <Option value="totalled">{formatMessage({ id: 'text.totalled' })}</Option>
              <Option value="cumulative">{formatMessage({ id: 'text.cumulative' })}</Option>
            </Select>,
          )}
        </Form.Item>
        {maxWeightEnabled ? (
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={maxWeightNameOverride}
            data-test="formMaxWeight"
          >
            <div className={styles.unitWrapper}>
              {getFieldDecorator('max_weight', {
                rules: [
                  {
                    required: true,
                    message: 'max weight required',
                  },
                ],
                initialValue: aircraft.max_weight,
              })(
                <Input
                  placeholder={aircraft ? aircraft.max_weight : ''}
                  data-test="edit-aircraft--max-weight-input"
                  spellCheck="false"
                />,
              )}
              <span>kg</span>
            </div>
          </Form.Item>
        ) : null}
        {aircraft?.billing_status === 'Setup' ? (
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'menu.status' })}
            className={styles.apuFormItem}
            data-test="formAPU"
          >
            {getFieldDecorator('status', {
              rules: [],
              initialValue: aircraft.billing_status,
            })(
              <RadioGroup data-test="edit-aircraft--status">
                <RadioButton value="Active">Active</RadioButton>
                <RadioButton value="Setup">Setup</RadioButton>
              </RadioGroup>,
            )}
          </Form.Item>
        ) : null}
      </Form>
      <div className={styles.footerWrapper}>
        <APUPopconfirm loading={mutation.isLoading} aircraft={aircraft} form={form} handleSubmit={handleSubmit} />
        <div className={styles.modalFooterCancelButton}>
          <Button padding="0 28px" primary={false} size={ButtonSize.MEDIUM} onClick={handleCancel}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditAircraftForm.propTypes = {
  aircraft: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default Form.create()(EditAircraftForm);
