import { Button, Search } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import Loading from '../../components/TFLoading/index';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { getAircraftNotes } from '../../models/notes/actions';
import EmptyStateNotes from '../../assets/emptyState/empty-state-crew-notes.svg';
import EmptyState from '../../components/EmptyState/EmptyState';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../models/aircraft';
import { usePrevious } from '../../utils/utils';
import { DashboardState } from '../../models';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import plusIcon from '../../assets/plus.svg';
import NotesTable from './NotesTable';
import NotesDrawer from './NotesDrawer';

const PageCard = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 16px 0 #dbe3ed;
  border: solid 2px #fff;
  background-color: #fcfdff;
  padding: 28px;
  @media (max-width: 450px) {
    padding: 12px 8px;
  }
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  color: #242d41;
  font-weight: 500;
  @media (max-width: 450px) {
    margin-bottom: 12px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  margin-right: 12px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  svg {
    padding-top: 0;
  }
`;

const AddButtonText = styled.div`
  color: #fff;
  font-weight: 500;
  align-items: center;
  display: flex;
  img {
    margin-right: 8px;
    margin-top: 2px;
  }
`;

const TableWrapper = styled.div`
  div,
  img,
  svg,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const Notes: React.FC = () => {
  const [total, setTotal] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [sortBy, setSortBy] = useState([]);
  const [localNotes, setLocalNotes] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const previousPageIndex = usePrevious(pageIndex);
  const previousPageSize = usePrevious(pageSize);
  const previousSortBy = usePrevious(sortBy);
  const previousSearchValue = usePrevious(searchValue);

  const {
    notes: { notesMap, count },
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const notesToCrewOverride = operators.find((op) => op?.id === aircraftMap?.get(id)?.operator_id)?.operator_setting
    ?.notes_to_crew_name;

  const handleAddNote = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'add' }));
    dispatch(changeDrawerContent({ payload: { content: <NotesDrawer /> } }));
  };

  const buttonAction = (): void => {
    if (localNotes.length === 0) {
      handleAddNote();
    } else if (searchValue) {
      setSearchValue('');
    }
  };

  useEffect(() => {
    if (id) {
      const payload = {
        aircraft_id: id,
        page: pageIndex,
        limit: pageSize,
      } as any;
      setLoading(true);
      dispatch(getAircraftNotes({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      (previousPageIndex && previousPageIndex !== pageIndex) ||
      (previousPageSize && previousPageSize !== pageSize) ||
      (previousSortBy && !_.isEqual(sortBy, previousSortBy)) ||
      (previousSearchValue && previousSearchValue !== searchValue)
    ) {
      const sort_by = sortBy.reduce((obj, item) => ({ ...obj, [item.id]: item.desc ? 'desc' : 'asc' }), {});
      const payload = {
        aircraft_id: id,
        page: pageIndex,
        limit: pageSize,
        sort_by,
      } as any;
      if (searchValue) payload.search = searchValue;
      setLoading(true);
      dispatch(getAircraftNotes({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, sortBy, searchValue]);

  useEffect(() => {
    if (notesMap) {
      const notes = Array.from(notesMap.values());
      setLoading(false);
      setLocalNotes(notes);
    }
  }, [notesMap]);

  useEffect(() => {
    if (count) setTotal(count);
  }, [count]);

  return (
    <InnerMenuLayout>
      <Loading loading={loading} contain />
      <PageCard>
        <TableHeader>
          <Title>
            {notesToCrewOverride ? `${notesToCrewOverride} (${total || 0})` : `Notes to Crew (${total || 0})`}
          </Title>
          <ActionWrapper>
            <SearchWrapper>
              <Search onChange={(e): void => setSearchValue(e.target.value)} onClear={(): void => setSearchValue('')} />
            </SearchWrapper>
            <AircraftAuthenticationWrapper
              aircraftId={id}
              requiredResource={AircraftResource.AIRCRAFT}
              requiredPermissionLevel={AircraftPermission.CREATE}
            >
              <Button size={ButtonSize.MEDIUM} onClick={handleAddNote}>
                <AddButtonText>
                  <img src={plusIcon} alt="plus icon" />
                  {window.innerWidth < 451 ? ' Add' : ' Add notes'}
                </AddButtonText>
              </Button>
            </AircraftAuthenticationWrapper>
          </ActionWrapper>
        </TableHeader>
        <TableWrapper>
          {localNotes?.length ? (
            <NotesTable
              data={localNotes}
              total={total}
              pageSize={pageSize}
              pageIndex={pageIndex - 1}
              onPaginationChange={(currentPage, numberOfItems): void => {
                if (numberOfItems !== pageSize) {
                  setPageIndex(1);
                  setPageSize(numberOfItems);
                } else if (currentPage !== pageIndex) {
                  setPageIndex(currentPage);
                }
              }}
              onSortChange={(value): void => setSortBy(value)}
            />
          ) : (
            <EmptyState
              image={EmptyStateNotes}
              text={localNotes.length === 0 && !searchValue ? `No notes` : `We couldn't find any matching notes`}
              subText={
                localNotes.length === 0
                  ? `You can add your first note now.`
                  : 'Try adjusting your filters or searching with another term.'
              }
              button={localNotes.length === 0 ? `Add note` : 'Clear all'}
              buttonAction={buttonAction}
            />
          )}
        </TableWrapper>
      </PageCard>
    </InnerMenuLayout>
  );
};

export default Notes;
