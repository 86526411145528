import { useSelector } from 'react-redux';
import { DashboardState } from '../models';

export default function (key: string, id: string) {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const foundStandardField = aircraftMap.get(id)?.standard_fields?.[key];
  return foundStandardField;
}
