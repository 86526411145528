/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Cookies from 'universal-cookie';
import { message } from 'antd';
import { intl } from '../../providers/IntlProvider';
import DeleteIcon from '../../assets/icon-delete-white.svg';
import CloseIcon from '../../assets/close-icon-mobile-white.svg';
import DownloadIcon from '../../assets/icon-download-white.svg';
import styles from './fileViewer.module.less';

const cookies = new Cookies();

export const DownloadButton = ({ downloadLink, fileName }): JSX.Element => {
  const handleDownload = async (event): Promise<object | null> => {
    event.preventDefault();
    const response = await fetch(downloadLink, {
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
      },
    });
    if (response.status === 200) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      return { success: true };
    }
    message.error(intl.formatMessage({ id: 'message.downloadFail' }));
    return null;
  };

  return (
    <button type="button" className={styles.downloadButton} onClick={handleDownload}>
      <img src={DownloadIcon} alt="download button" />
    </button>
  );
};

export const DeleteButton = ({ handleDeleteClick, allowDelete, id, name }): JSX.Element =>
  (allowDelete && (id || name) && (
    <button
      type="button"
      className={styles.deleteButton}
      onClick={(): void => handleDeleteClick({ fileId: id, fileName: name })}
    >
      <img src={DeleteIcon} alt="delete button" />
    </button>
  )) ||
  null;

export const Next = ({ nextSlide }): JSX.Element => (
  <button onClick={nextSlide} className={styles.fileViewControlButtons} type="button">
    {'>'}
  </button>
);

export const Close = ({ closeFileView }): JSX.Element => (
  <button onClick={closeFileView} className={styles.closeFileViewButton} type="button">
    <img src={CloseIcon} alt="close" className={styles.closeIcon} />
  </button>
);

export const Prev = ({ prevSlide }): JSX.Element => (
  <button className={styles.fileViewControlButtons} onClick={prevSlide} type="button">
    {'<'}
  </button>
);
