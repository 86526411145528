import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components/FlightDrawer/FlightDrawer';
import SelectPilot from '../../components/FlightDrawer/SelectPilot';
import { Person } from '../../models/people';

interface AddOccurrenceProps {
  reportedBy: { title: string; value: string };
  people: Person[];
  handleFurtherDetailsChange: (input: string) => void;
  handleReportedByChange: (input: string) => void;
}

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
  width: 100%;
`;

const SubHeader = styled.h5`
  margin-top: 1.25rem;
`;

const AddOccurrence: React.FC<AddOccurrenceProps> = ({
  handleFurtherDetailsChange,
  reportedBy,
  handleReportedByChange,
  people,
}) => {
  return (
    <div>
      <Header editable>Add Occurrence</Header>
      <SubHeader>Further Details</SubHeader>
      <StyledTextarea
        id="furtherDetails"
        name="furtherDetails"
        data-testid="IntermittentFault--furtherDetails"
        cols={30}
        rows={5}
        defaultValue=""
        onChange={(e) => {
          handleFurtherDetailsChange(e.target.value);
        }}
      />
      <SubHeader>Reported by</SubHeader>
      <SelectPilot
        width={200}
        people={people}
        handlePersonChange={(id): void => handleReportedByChange(id)}
        pilotFlying={reportedBy}
      />
    </div>
  );
};

export default AddOccurrence;
