import { Loading } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import HistorySection from '../../components/HistoryTable/HistorySection';
import { DashboardState } from '../../models';
import { getSingleMxItem } from '../../services/apiNew';

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  height: 32px;
  align-items: center;
  span {
    font-weight: 500;
    margin-left: 4px;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    height: auto;
    max-width: 96%;
  }
`;

const MXItemDrawer: React.FC = () => {
  const {
    drawer: { drawerId },
  } = useSelector((state: DashboardState) => state);
  const { id } = useParams<{ id: string }>();

  const [mxItems, setMxItems] = useState(null);
  const [versions, setVersions] = useState([]);
  const [area, setArea] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const getTitle = (item): void => {
    setTitle(item.name);
    let newArea = '';
    const { mx_type: mxType } = item;
    if (mxType.startsWith('engine')) {
      newArea = `${mxType.split('_')[0]} ${mxType.split('_')[1]}`;
    } else {
      // eslint-disable-next-line prefer-destructuring
      newArea = mxType.split('_')[0];
    }
    setArea(newArea);
  };

  useEffect(() => {
    if (!mxItems) {
      setLoading(true);
      const allItems = getSingleMxItem({ id: drawerId });
      Promise.all([allItems]).then((res) => {
        const scheduledItems = res[0]?.data;
        setMxItems(scheduledItems);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, drawerId]);

  useEffect(() => {
    if (mxItems) {
      const foundItem = mxItems;
      if (foundItem) {
        const foundVersions = foundItem?.versions || [];
        getTitle(foundItem);
        setVersions(foundVersions);
      }
    }
  }, [drawerId, mxItems]);

  return (
    <DrawerWrapper>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <Title>
            {area}:<span>{title}</span>
          </Title>
          <HistorySection versions={versions} title="maintenance item" noJustification />
        </>
      )}
    </DrawerWrapper>
  );
};

export default MXItemDrawer;
