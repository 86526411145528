import React from 'react';
import styled from 'styled-components';

interface TFButtonProps {
  clear?: boolean;
  marginRight?: string;
  padding?: string;
  skinny?: boolean;
  handleClick: () => void;
}

const StyledButton = styled.button`
  background-color: ${({ theme, clear }): string => (clear ? 'transparent' : theme.colours.brandAccentBlue)};
  color: ${({ theme, clear }): string => (clear ? theme.colours.brandAccentBlue : theme.colours.white)};
  height: ${({ skinny }): string => (skinny ? '30px' : '40px')};
  border-radius: 20px;
  padding: ${({ padding }): string => padding || '0 40px'};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
  cursor: pointer;
  border: none;
`;

const TFButton: React.FC<TFButtonProps> = ({ children, clear, marginRight, padding, skinny, handleClick }) => {
  return (
    <StyledButton
      type="button"
      data-testid="StyledButton--Button"
      clear={clear}
      marginRight={marginRight}
      padding={padding}
      onClick={handleClick}
      skinny={skinny}
    >
      {children}
    </StyledButton>
  );
};

export default TFButton;
