import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Layout, Row } from 'antd';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import disableAutoCorrect from '../../../../utils/disableAutoCorrect';
import cancelIcon from '../assets/cancel.svg';
import FiltersSidebarDraftModal from '../../../FiltersSidebar/FiltersSidebarDraftModal';
import PageMessage from '../../../PageMessage';
import Loading from '../../../TFLoading';
import { queryDraftMaintenance } from '../../../../services/api';
import styles from './MXDraftQueue.module.less';
import ModalHeader from './ModalHeader';
import DraftQueueSummary from './DraftQueueSummary';
import ListTitle from './ListTitle';
import ListWrapper from './DraftsList/ListWrapper';
import IndividualWrapper from './IndividualWrapper';

const { Header, Sider, Content } = Layout;

class MXDraftQueue extends Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    aircraft: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  };

  static defaultProps = {
    aircraft: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        show: [],
        itemStatus: [],
        itemType: [],
        area: [],
      },
      individualMode: false,
      individualCurrent: 1,
      loading: true,
      submitting: false,
      draftItems: [],
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateIndividualMode);
    disableAutoCorrect();
  };

  componentDidUpdate(prevProps, prevState) {
    const { visible, aircraft } = this.props;
    const { loading, draftItems } = this.state;
    const shouldFetchMxItems = loading && visible && !prevProps.visible;

    if (shouldFetchMxItems) {
      queryDraftMaintenance({ aircraft_id: aircraft.id })
        .then((returnedDraftItems) => {
          this.setState({ draftItems: returnedDraftItems, loading: false });
        })
        .catch(console.error);
    }

    if (draftItems && draftItems.length !== prevState.draftItems.length) {
      this.handleSubmittingChange(false);
    }

    disableAutoCorrect();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIndividualMode);
  }

  updateIndividualMode = () => {
    const { individualMode } = this.state;
    if (individualMode && window.innerWidth < 991) {
      this.setState({
        individualMode: false,
        individualCurrent: 1,
      });
    }
  };

  onFilterChange = (filterKey, groupKey) => {
    const { filters } = this.state;
    if (filters[groupKey].includes(filterKey)) {
      // Item is in array -> it's active -> remove it from array to switch off
      const index = filters[groupKey].indexOf(filterKey);
      filters[groupKey].splice(index, 1);
    } else {
      // Item is not in array -> it's closed -> add it to array to switch on
      filters[groupKey].push(filterKey);
    }
    this.setState({ filters });
  };

  onFiltersClear = (groupKey) => {
    const { filters } = this.state;
    const clearedFilters = { ...filters };
    clearedFilters[groupKey] = [];
    this.setState({ filters: clearedFilters });
  };

  getFilteredMXItems = () => {
    const { draftItems } = this.state;
    const { filters, individualMode, individualCurrent } = this.state;
    const { itemStatus, itemType, area } = filters;
    const filteredItems = draftItems
      .map((item) => {
        if (itemStatus.length === 0 && itemType.length === 0 && area.length === 0) {
          // No filters
          return item;
        }

        let filteredItem = { ...item };

        if (filteredItem && itemStatus.length > 0) {
          // ITEM STATUS active
          if (!this.checkItemInFilter(itemStatus, filteredItem, 'mx_type')) {
            filteredItem = null;
          }
        }
        if (filteredItem && itemType.length > 0) {
          // ITEM TYPE active
          if (!this.checkItemInFilter(itemType, filteredItem, 'mx_type')) {
            filteredItem = null;
          }
        }
        if (filteredItem && area.length > 0) {
          // AREA filter active
          if (!this.checkItemInFilter(area, filteredItem, 'mx_type')) {
            filteredItem = null;
          }
        }
        return filteredItem;
      })
      .filter((el) => el !== null);

    if (filteredItems.length > 0 && individualMode && individualCurrent > filteredItems.length) {
      this.setState({
        individualCurrent: filteredItems.length,
      });
    }
    if (filteredItems.length === 0 && individualMode) {
      this.setState({
        individualCurrent: 1,
        individualMode: false,
      });
    }
    return filteredItems;
  };

  checkItemInFilter = (filterValues, item, itemKey) => {
    let itemCheck = false;
    filterValues.forEach((val) => {
      if (val === 'new' && item.draft) {
        itemCheck = true;
      } else if (val === 'update' && item.camp_diffs && item.camp_diffs.length > 0) {
        itemCheck = true;
      } else if (val === 'pkg' && item.package) {
        itemCheck = true;
      } else if (item[itemKey] && item[itemKey].includes(val) && !item.package) {
        itemCheck = true;
      }
    });
    return itemCheck;
  };

  skipItem = () => {
    const { individualCurrent } = this.state;
    this.setState({
      individualCurrent: individualCurrent + 1,
    });
  };

  onItemSuccess = (item) => {
    const { onSuccess } = this.props;

    if (item) {
      const { draftItems } = this.state;
      const newDraftItems = [...draftItems];
      const filteredDraftItems = newDraftItems.filter((draftItem) => {
        return item.id !== draftItem.id;
      });

      this.setState({ draftItems: filteredDraftItems });
    } else {
      const { hideModal } = this.props;

      hideModal();
    }

    onSuccess();
  };

  handleLoadingChange = () => {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  };

  handleSubmittingChange = (value) => {
    this.setState({ submitting: value });
  };

  render() {
    const {
      visible,
      hideModal,
      aircraft,
      intl: { formatMessage },
    } = this.props;
    const { filters, individualMode, individualCurrent, loading, submitting, draftItems } = this.state;
    const mxItems = this.getFilteredMXItems();
    return (
      <Modal
        onCancel={() => {
          this.setState({ loading: true, draftItems: [] });
          hideModal();
        }}
        visible={visible}
        className={styles.modal}
        footer={null}
        closable
        closeIcon={<img src={cancelIcon} alt="cancelIcon" data-test="cancelIcon" className={styles.cancelIcon} />}
        destroyOnClose
      >
        <Loading loading={loading} />
        <Layout className={styles.layoutWrapper}>
          <Header className={styles.headerWrapper} data-test="modalHeader">
            <ModalHeader
              registration={aircraft ? aircraft.registration : ''}
              individualMode={individualMode}
              individualCurrent={individualCurrent}
              individualItem={mxItems[individualCurrent - 1]}
              individualTotal={mxItems.length}
              onPrevItem={() => {
                this.setState({ individualCurrent: individualCurrent - 1 });
              }}
              onNextItem={() => {
                this.setState({ individualCurrent: individualCurrent + 1 });
              }}
              onDisableIndividual={() => {
                this.setState({ individualCurrent: 1, individualMode: false });
              }}
              data-test="modalHeaderContent"
            />
          </Header>
          <Layout>
            <Content className={styles.contentWrapper} data-test="modalContent">
              <div className={styles.pageMessageWrapper}>
                <PageMessage type="warning" text={formatMessage({ id: 'message.openComputer' })} />
              </div>
              {individualMode ? (
                <Row className={`${styles.rowContent} ${styles.individualWrapper}`}>
                  <IndividualWrapper
                    onSkip={() => this.skipItem()}
                    hideSkip={individualCurrent === mxItems.length}
                    item={mxItems[individualCurrent - 1]}
                    onSuccess={this.onItemSuccess}
                    handleSubmittingChange={this.handleSubmittingChange}
                    aircraft={aircraft}
                  />
                </Row>
              ) : (
                <>
                  <DraftQueueSummary
                    totalItems={draftItems.length}
                    onIndividualModeSwitch={() => {
                      this.setState({ individualMode: true });
                    }}
                    items={draftItems}
                    data-test="queueSummary"
                  />
                  <Row className={styles.rowContent}>
                    <ListTitle
                      title={
                        mxItems.length === 1
                          ? formatMessage({ id: 'title.draftRequirement' })
                          : formatMessage({ id: 'title.draftRequirements' })
                      }
                      onFilterChange={(filterKey, groupKey) => this.onFilterChange(filterKey, groupKey)}
                      onFiltersClear={(groupKey) => this.onFiltersClear(groupKey)}
                      aircraft={aircraft}
                      mxItems={draftItems}
                      filters={filters}
                      data-test="listTitle"
                      formatMessage={formatMessage}
                      onSuccess={this.onItemSuccess}
                      handleLoadingChange={this.handleLoadingChange}
                      submitting={submitting}
                    />
                  </Row>
                  <Row className={styles.rowContent}>
                    <ListWrapper
                      onSuccess={this.onItemSuccess}
                      items={mxItems}
                      data-test="listWrapper"
                      handleSubmittingChange={this.handleSubmittingChange}
                      aircraft={aircraft}
                    />
                  </Row>
                </>
              )}
            </Content>
            <Sider className={styles.siderWrapper} width={230} data-test="modalSider">
              <FiltersSidebarDraftModal
                aircraft={aircraft}
                allItems={draftItems}
                activeFilters={filters}
                onFilterChange={(filterKey, groupKey) => this.onFilterChange(filterKey, groupKey)}
                onFiltersClear={(groupKey) => this.onFiltersClear(groupKey)}
                data-test="filtersDraftSidebar"
              />
            </Sider>
          </Layout>
        </Layout>
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ userSettings }) => ({ userSettings })),
)(MXDraftQueue);
