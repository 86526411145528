import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import icon from '../../assets/icon-card-trips.svg';
import ToolTip from '../NewTooltip/NewTooltip';
import errorIcon from '../../assets/login-error.svg';
import TFNumberInput from '../TFNumberInput/TFNumberInput';
import AircraftSettingsCard from './AircraftSettingsCard';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  StyledInput,
  Text,
} from './AircraftAdvancedSettings';
import ShowHideToggle from './ShowHideToggle';
import TripStandardFieldsToggles from './TripStandardFieldsToggles';

interface AircraftTripStandardFieldsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

export const Header = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #242d41;
  margin: 0 0 16px 16px;
`;

export const Divider = styled.div`
  margin: 0 16px 16px;
  background-color: rgba(36, 45, 65, 0.05);
  height: 1px;
  width: -webkit-fill-available;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const ErrorWrapper = styled.div`
  font-size: ${({ error }): string => (error ? '16px' : '12px')};
  color: ${({ error }): string => (error ? '#ac1717' : 'rgba(36, 45, 65, 0.7)')};
  margin-top: 4px;
  img {
    margin-right: 4px;
    margin-bottom: 3px;
  }
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const AircraftTripStandardFields: React.FC<AircraftTripStandardFieldsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [offsetWarning, setOffsetWarning] = useState(false);
  const [idgCountError, setIdgCountError] = useState(false);
  const [bugCountError, setBugCountError] = useState(false);

  const { formatMessage } = useIntl();

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    if (
      aircraft?.standard_fields?.integrated_drive_generator?.enabled &&
      (aircraft?.standard_fields?.integrated_drive_generator?.count === null ||
        aircraft?.standard_fields?.integrated_drive_generator?.count === '' ||
        aircraft?.standard_fields?.integrated_drive_generator?.count === 0 ||
        aircraft?.standard_fields?.integrated_drive_generator?.count === '0')
    ) {
      setIdgCountError(true);
    } else if (
      aircraft?.standard_fields?.back_up_generator?.enabled &&
      (aircraft?.standard_fields?.back_up_generator?.count === null ||
        aircraft?.standard_fields?.back_up_generator?.count === '' ||
        aircraft?.standard_fields?.back_up_generator?.count === 0 ||
        aircraft?.standard_fields?.back_up_generator?.count === '0')
    ) {
      setBugCountError(true);
    } else {
      setIdgCountError(false);
      setBugCountError(false);
      handleSaveClick();
      setEditMode(false);
    }
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    if (key === 'additional_deicing_fields') {
      newStandardFields[key] = { enabled: value, required: false };
    } else {
      newStandardFields[`${key}`] = { enabled: value };
    }
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleAppSettingChange = (value: boolean, key: string): void => {
    const newAppSettings = aircraft?.app_settings || {};
    newAppSettings[`${key}`] = value;
    updateAircraftData([{ value: newAppSettings, key: 'app_settings' }]);
  };

  const handleToggleRequiredChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], required: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleTakeoffOffset = (value): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    let newValue = value;
    if (value === '-') {
      newValue = 0;
    }
    const regex = new RegExp('^[\\d\\-]+$');
    if (regex.test(newValue)) {
      setOffsetWarning(false);
    } else {
      setOffsetWarning(true);
    }
    if (parseInt(newValue, 10) > 0) {
      newValue *= -1;
    }
    newStandardFields.duty_log = { ...newStandardFields.duty_log, start_offset: newValue };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleInputChange = (value: string | number, key: string, secondKey: string): void => {
    if (key === 'back_up_generator' && secondKey === 'count') {
      setBugCountError(false);
    }
    if (key === 'integrated_drive_generator' && secondKey === 'count') {
      setIdgCountError(false);
    }
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], [`${secondKey}`]: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={icon}
            title="Trip and sector workflow configuration"
            buttonText="Edit trip workflow"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <Header>Workflow configuration</Header>
        <TripStandardFieldsToggles
          aircraft={aircraft}
          editMode={editMode}
          handleToggleChange={handleToggleChange}
          handleAppSettingChange={handleAppSettingChange}
        />
        <Divider />
        <Header>Workflow rows</Header>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Hobbs</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.hobbs?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.hobbs?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'hobbs')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.hobbs?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.hobbs?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.hobbs?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'hobbs', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Additional crew tracking</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.additional_crew?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.additional_crew?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'additional_crew')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.additional_crew?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.additional_crew?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.additional_crew?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'additional_crew', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Trip category</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.trip_category?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.trip_category?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'trip_category')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.trip_category?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.trip_category?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.trip_category?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'trip_category', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Deicing support</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.de_icing?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.de_icing?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'de_icing')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.de_icing?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.de_icing?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.de_icing?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'de_icing', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.de_icing?.enabled !== false ? (
            <ItemWrapper data-testid="AircraftTripStandardFields--RemoteDeIcing">
              <Label edit={editMode}>{formatMessage({ id: 'text.remoteDeicing' })}</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.remote_de_icing?.enabled !== false ? 'Show' : 'Hide'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.remote_de_icing?.enabled !== false}
                  handleChange={(value: boolean): void => handleToggleChange(value, 'remote_de_icing')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Trip callsign</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.trip_callsign?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.trip_callsign?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'trip_callsign')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.trip_callsign?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.callsign?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.callsign?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'callsign', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>APU Time</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.apu?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.apu?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'apu')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.apu?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.apu?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.apu?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'apu', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Trend monitoring</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.trend_monitoring?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.trend_monitoring?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'trend_monitoring')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.trend_monitoring?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.trend_monitoring?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.trend_monitoring?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'trend_monitoring', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Trip signoff required</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.trip_signoff?.enabled !== false ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.trip_signoff?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'trip_signoff')}
                showOverride="Yes"
                hideOverride="No"
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.trip_signoff?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.trip_signoff?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.trip_signoff?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'trip_signoff', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.trip_signoff?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Message</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.trip_signoff?.message || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.trip_signoff?.message}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'trip_signoff', 'message')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Ground services and PAX</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.journey_log?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.journey_log?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'journey_log')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.journey_log?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.journey_log?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.journey_log?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'journey_log', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Maximum flight level</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.max_fl?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.max_fl?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'max_fl')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.max_fl?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.max_fl?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.max_fl?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'max_fl', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Pressurised cycle</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.pressurised_cycle?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.pressurised_cycle?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'pressurised_cycle')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.pressurised_cycle?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.pressurised_cycle?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.pressurised_cycle?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'pressurised_cycle', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Actual arrival airport</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.actual_arrival_airport?.enabled === true ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.actual_arrival_airport?.enabled === true}
                handleChange={(value: boolean): void => handleToggleChange(value, 'actual_arrival_airport')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.actual_arrival_airport?.enabled === true ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.actual_arrival_airport?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.actual_arrival_airport?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'actual_arrival_airport', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Show additional deicing fields</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.additional_deicing_fields?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.additional_deicing_fields?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'additional_deicing_fields')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.additional_deicing_fields?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Additional deicing fields required</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.additional_deicing_fields?.required ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.additional_deicing_fields?.required}
                  handleChange={(value: boolean): void =>
                    handleToggleRequiredChange(value, 'additional_deicing_fields')
                  }
                  showOverride="Yes"
                  hideOverride="No"
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>IDG fields</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.integrated_drive_generator?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.integrated_drive_generator?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'integrated_drive_generator')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.integrated_drive_generator?.enabled ? (
            <ItemWrapper data-testid="AircraftTripStandardFields--IdgCount">
              <Label edit={editMode}>Count</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.integrated_drive_generator?.count || '-'}</Text>
              ) : (
                <TFNumberInput
                  value={aircraft?.standard_fields?.integrated_drive_generator?.count}
                  onChange={(number): void => handleInputChange(number, 'integrated_drive_generator', 'count')}
                  min={0}
                  max={5}
                />
              )}
              {editMode ? (
                <>
                  {idgCountError ? (
                    <ErrorWrapper error={idgCountError}>
                      <img src={errorIcon} alt="error" />
                      IDG count is required if IDG fields are enabled
                    </ErrorWrapper>
                  ) : null}
                </>
              ) : null}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.integrated_drive_generator?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.integrated_drive_generator?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.integrated_drive_generator?.name_override}
                  type="text"
                  onChange={(e): void =>
                    handleInputChange(e.target.value, 'integrated_drive_generator', 'name_override')
                  }
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>BUG fields</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.back_up_generator?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.back_up_generator?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'back_up_generator')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.back_up_generator?.enabled ? (
            <ItemWrapper data-testid="AircraftTripStandardFields--BugCount">
              <Label edit={editMode}>Count</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.back_up_generator?.count || '-'}</Text>
              ) : (
                <TFNumberInput
                  value={aircraft?.standard_fields?.back_up_generator?.count}
                  onChange={(number): void => handleInputChange(number, 'back_up_generator', 'count')}
                  min={0}
                  max={5}
                />
              )}
              {editMode ? (
                <>
                  {bugCountError ? (
                    <ErrorWrapper error={bugCountError}>
                      <img src={errorIcon} alt="error" />
                      BUG count is required if BUG fields are enabled
                    </ErrorWrapper>
                  ) : null}
                </>
              ) : null}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.back_up_generator?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.back_up_generator?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.back_up_generator?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'back_up_generator', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Airborne seconds</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.airborne_seconds?.enabled ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.airborne_seconds?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, 'airborne_seconds')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.airborne_seconds?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Row title</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.airborne_seconds?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.airborne_seconds?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'airborne_seconds', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        <Divider />
        <Header>Duty log fields</Header>
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Duty logs</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.duty_log?.enabled !== false ? 'Show' : 'Hide'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.duty_log?.enabled !== false}
                handleChange={(value: boolean): void => handleToggleChange(value, 'duty_log')}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.duty_log?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>Name override</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.duty_log?.name_override || '-'}</Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.duty_log?.name_override}
                  type="text"
                  onChange={(e): void => handleInputChange(e.target.value, 'duty_log', 'name_override')}
                />
              )}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.duty_log?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>
                Offset before first takeoff
                <ToolTip text="Default duty start time to this many minutes before the first takeoff on the trip" />
              </Label>
              <UnitWrapper>
                {!editMode ? (
                  <Text>
                    {aircraft?.standard_fields?.duty_log?.start_offset &&
                    aircraft?.standard_fields?.duty_log?.start_offset !== null
                      ? aircraft?.standard_fields?.duty_log?.start_offset / 60
                      : '-'}
                  </Text>
                ) : (
                  <StyledInput
                    value={
                      aircraft?.standard_fields?.duty_log?.start_offset &&
                      aircraft?.standard_fields?.duty_log?.start_offset !== null
                        ? aircraft?.standard_fields?.duty_log?.start_offset / 60
                        : ''
                    }
                    type="text"
                    onChange={(e): void =>
                      handleTakeoffOffset(
                        (e.target.value && e.target.value !== '-' ? parseInt(e.target.value, 10) : 0) * 60,
                      )
                    }
                  />
                )}
                <StyledUnit edit={editMode}>min</StyledUnit>
              </UnitWrapper>
              {editMode ? (
                <>
                  {offsetWarning ? (
                    <ErrorWrapper error={offsetWarning}>
                      {offsetWarning ? <img src={errorIcon} alt="error" /> : null}
                      Only numbers or - are allowed
                    </ErrorWrapper>
                  ) : (
                    <ErrorWrapper>If positive value is added it will be converted to negative</ErrorWrapper>
                  )}
                </>
              ) : null}
            </ItemWrapper>
          ) : null}
          {aircraft?.standard_fields?.duty_log?.enabled !== false ? (
            <ItemWrapper>
              <Label edit={editMode}>
                Offset after last landing
                <ToolTip text="Default duty end time to this many minutes after the last landing on the trip" />
              </Label>
              <UnitWrapper>
                {!editMode ? (
                  <Text>
                    {aircraft?.standard_fields?.duty_log?.end_offset &&
                    aircraft?.standard_fields?.duty_log?.end_offset !== null
                      ? aircraft?.standard_fields?.duty_log?.end_offset / 60
                      : '-'}
                  </Text>
                ) : (
                  <StyledInput
                    value={
                      aircraft?.standard_fields?.duty_log?.end_offset &&
                      aircraft?.standard_fields?.duty_log?.end_offset / 60
                    }
                    type="text"
                    onChange={(e): void =>
                      handleInputChange(
                        (e.target.value && e.target.value !== '-' ? parseInt(e.target.value, 10) : 0) * 60,
                        'duty_log',
                        'end_offset',
                      )
                    }
                  />
                )}
                <StyledUnit edit={editMode}>min</StyledUnit>
              </UnitWrapper>
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button
                data-testid="AircraftTripStandardFields-SaveButton"
                height="32px"
                onClick={handleLocalSaveClick}
                disabled={offsetWarning}
              >
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftTripStandardFields;
