import { Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.less';

const BooleanFilter = ({ title, valueKey, value, onChange }) => {
  return (
    <div className={`${styles.singleFilter} ${styles.singleFilterBoolean}`}>
      <span className={styles.filterTitle} data-test="title">
        {title}
      </span>
      <Switch checked={value} onChange={() => onChange(valueKey)} data-test="switch" />
    </div>
  );
};

BooleanFilter.propTypes = {
  title: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BooleanFilter;
