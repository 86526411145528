import React, { useMemo, useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { pathToRegexp } from 'path-to-regexp';
import { useSelector } from 'react-redux';
import { MenuMode } from 'antd/lib/menu';
import { MenuTheme } from 'antd/lib/menu/MenuContext';
import { DashboardState } from '../../models';
import { checkOperatorSettingNameOverride } from '../../utils/utils';
import support from '../../assets/mainNav/icon-support.svg';
import { urlToList } from '../_utils/pathTools';
import { MenuItem } from '../../utils/menuData';
import { UserRoles } from '../../models/userSettings';
import styles from './index.module.less';

const { SubMenu } = Menu;

interface MenuProps {
  menuData: MenuItem[];
  location: { pathname: string };
  isMobile?: boolean;
  onCollapse?: () => void;
  Authorized?: any;
  style?: React.CSSProperties;
  handleOpenChange?: (keys: string[]) => void;
  mode: MenuMode;
}

export const getMenuMatches = memoizeOne(
  (flatMenuKeys, path) => flatMenuKeys.filter((item) => item && pathToRegexp(item).test(path)),
  isEqual,
);

const getIcon = (icon: string | React.ReactNode) => {
  if (typeof icon === 'string') {
    return <img src={icon} alt="icon" className={styles.icon} />;
  }
  return icon;
};

const BaseMenu: React.FC<MenuProps> = ({
  menuData,
  location,
  isMobile = false,
  onCollapse,
  Authorized,
  mode = '',
  style,
  handleOpenChange,
}) => {
  const { formatMessage } = useIntl();
  const { pathname } = location;

  const {
    userSettings: {
      ui: { navTheme },
      details: { operators },
    },
    menu: { collapsed },
  } = useSelector((state: DashboardState) => state);

  const flatMenuKeys = useMemo(() => {
    const getFlatMenuKeys = (menus: MenuItem[]): string[] => {
      let keys: string[] = [];
      menus.forEach((item) => {
        if (item.children) {
          keys = keys.concat(getFlatMenuKeys(item.children));
        }
        keys.push(item.path);
      });
      return keys;
    };

    let keys: string[] = [];
    menuData.forEach((item) => {
      if (item.children) {
        keys = keys.concat(getFlatMenuKeys(item.children));
      }
      keys.push(item.path);
    });
    return keys;
  }, [menuData]);

  const conversionPath = (path: string) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };

  const getMenuItemPath = (item: MenuItem) => {
    let name = item.locale ? formatMessage({ id: item.locale }) : item.name;
    const itemPath = conversionPath(item.path);
    const icon = getIcon(item.icon);
    const { target } = item;

    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          {icon}
          <span>{name}</span>
        </a>
      );
    }
    const newDefectsName = checkOperatorSettingNameOverride(operators, 'defect_name_plural');
    const sectorOverrideName = checkOperatorSettingNameOverride(operators, 'sector_name_plural');
    if (name === 'Defects') name = newDefectsName;
    if (name === 'Sectors') name = sectorOverrideName;
    return (
      <Link
        to={itemPath}
        target={target}
        replace={itemPath === location.pathname}
        onClick={
          isMobile
            ? () => {
                onCollapse();
              }
            : undefined
        }
        data-testid="BaseMenu--IconLink"
      >
        {icon}
        <span>{name}</span>
      </Link>
    );
  };

  const getSelectedMenuKeys = () => urlToList(pathname).map((itemPath) => getMenuMatches(flatMenuKeys, itemPath).pop());

  const checkPermissionItem = (authority: UserRoles[], ItemDom: React.ReactNode) => {
    if (Authorized && Authorized.check) {
      const { check } = Authorized;
      return check(authority, ItemDom);
    }
    return ItemDom;
  };

  function getSubMenuOrItem(item: MenuItem) {
    if (item.children && item.children.some((child) => child.name)) {
      const name = item.locale ? formatMessage({ id: item.locale }) : item.name;
      return (
        <SubMenu
          title={
            item.icon ? (
              <span>
                {getIcon(item.icon)}
                <span>{name}</span>
              </span>
            ) : (
              name
            )
          }
          key={item.path}
          className="sidebar-menu-item"
          data-testid="BaseMenu--SubMenuIcon"
        >
          {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getNavMenuItems(item.children)
          }
        </SubMenu>
      );
    }
    return (
      <Menu.Item className="sidebar-menu-item" key={item.path}>
        {getMenuItemPath(item)}
      </Menu.Item>
    );
  }

  function getNavMenuItems(menusData: MenuItem[]) {
    if (!menusData) {
      return [];
    }
    return menusData
      .map((item) => {
        const ItemDom = getSubMenuOrItem(item);
        return checkPermissionItem(item.authority, ItemDom);
      })
      .filter((item) => item);
  }

  const [openKeysState, setOpenKeysState] = useState<string[]>([]);

  const selectedKeys = useMemo(getSelectedMenuKeys, [getSelectedMenuKeys]);

  let openKeys: string[] = [];
  const handleOpenChangeWrapper = (keys: string[]) => {
    setOpenKeysState(keys);
    if (typeof handleOpenChange === 'function') {
      handleOpenChange(keys);
    }
  };

  if (openKeysState.length) {
    if (collapsed) {
      openKeys = openKeysState;
    } else {
      openKeys = openKeys.concat(openKeysState);
    }
  } else if (collapsed) {
    openKeys = [];
  }

  return (
    <Menu
      key="Menu"
      mode={mode as MenuMode}
      theme={navTheme as MenuTheme}
      onOpenChange={handleOpenChangeWrapper}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      style={style}
      data-testid="BaseMenu--Menu"
      className={mode === 'horizontal' ? 'top-nav-menu' : ''}
    >
      {getNavMenuItems(menuData)}
      <Menu.Item className="sidebar-menu-item">
        <a href="https://helpdesk.centrik.net/hc/en-gb" target="_blank" rel="noopener noreferrer">
          <img src={support} alt="icon" className={styles.icon} />
          <span>Support</span>
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default BaseMenu;
