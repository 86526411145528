import { useMutation } from '@tanstack/react-query';
import { updateFRAT } from '../../services/api';

const useUpdateFRAT = ({ handeUpdateFRATOnSuccess, handeUpdateFRATOnError }) => {
  const renameCategory = useMutation({
    mutationFn: (payload: { id: string; name: string; default: boolean }) => {
      return updateFRAT(payload);
    },
    onSuccess: (data) => {
      handeUpdateFRATOnSuccess(data);
    },
    onError: (err: any) => handeUpdateFRATOnError(err),
  });

  return renameCategory;
};

export default useUpdateFRAT;
