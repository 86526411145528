import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import Loading from '../../TFLoading/index';
import FlexWrapper from './FlexWrapper';

interface ButtonSectionProps {
  handleCancel: () => void;
  handleFormSubmit: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 30px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
`;

const ButtonSection: React.FC<ButtonSectionProps> = ({ handleCancel, handleFormSubmit, loading, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <FlexWrapper justifyContent="flex-start" marginTop={40} marginBottom={20} padding="0 0 20px 0">
      <InputWrapper>
        <Loading loading={loading} contain width={30} height={30} />
        <Button size={ButtonSize.MEDIUM} onClick={handleFormSubmit} disabled={disabled}>
          {formatMessage({ id: 'form.submit' })}
        </Button>
      </InputWrapper>
      <Button size={ButtonSize.MEDIUM} primary={false} onClick={handleCancel}>
        {formatMessage({ id: 'text.cancel' })}
      </Button>
    </FlexWrapper>
  );
};

export default ButtonSection;
