/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Loading from '../TFLoading/index';
import { putWorkpack, postWorkpack, getWorkpackUrl, completeWorkpack, deleteAttachment } from '../../services/api';
import { AircraftPermission, AircraftResource } from '../../models/aircraft/index';
import { Workpack } from '../../models/workpacks/index';
import { FeatureFlag } from '../../models/userSettings/index';
import { DashboardState } from '../../models';
import { getSingleWorkpack, updateReduxWorkpack } from '../../models/workpacks/actions';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import servers from '../../utils/servers';
import HistorySection from '../HistoryTable/HistorySection';
import Controls from './Components/Controls';
import NotesAndAttachments from './Components/NotesAndAttachments';
import AircraftTotals from './Components/AircraftTotals';
import WorkpackInfo from './Components/WorkpackInfo';
import AddItemsToWorkpack from './Components/AddItemsToWorkpack';
import ViewWorkpacks from './Components/ViewWorkpacks';
import WorkpackTitle from './Components/WorkpackTitle';
import ResolutionDetails from './Components/ResolutionDetails';
import CRSAcceptance from './Components/CRSAcceptance';
import styles from './workpackDrawer.module.less';
import useGetSingleWorkpack from './useGetSingleWorkpack';

// eslint-disable-next-line no-shadow
enum DrawerState {
  EDIT = 'edit',
  VIEW = 'view',
  NEW = 'new',
}

// eslint-disable-next-line no-shadow
enum ItemStatus {
  COMPLETE = 'complete',
  PENDING = 'pending',
  DRAFT = 'draft',
  ACTIVE = 'active',
}

const ViewWorkpack = ({
  wp,
  pendingWps,
  ac,
  initialMode,
  wpId,
  handleDeleteWorkpack,
  toggleDrawer,
  updateWorkpackid,
  setModalVisible,
  handleSetCurrentWorkpack,
  handleUnsavedData,
  handleCRSClicked,
  crsClicked,
}): JSX.Element => {
  const [localWorkpackState, updateLocalWorkpackState] = useState<Workpack>();
  const [workpackUrl, setWorkpackUrl] = useState<string>('');
  const [CRSUrl, setCRSUrl] = useState<string>('');
  const [initialPayload, setInitialPayload] = useState<any>({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
  const [stagedPayload, updateStagedPayload] = useState(initialPayload);
  const [drawerMode, updateDrawerMode] = useState(initialMode);
  const [acceptanceSig, setAcceptanceSig] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [releaseData, setReleaseData] = useState(null);
  const [localVersions, setLocalVersions] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    workpack_items: wpItems,
    status,
    date,
    release,
    po_number,
    description,
    aircraft_id,
    attachments,
    notes,
  } = localWorkpackState || {};

  const Dispatch = useDispatch();

  const {
    aircraft: { aircraftMap },
    workpacks: { workpacksMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();

  const featureFlags = aircraftMap?.get(aircraftId)?.feature_flags?.map((flag) => flag.feature_name) || [];

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(aircraftId)?.operator_id)?.operator_setting;

  const customiser = (objValue, otherValue, key): boolean => {
    if (key === 'time_remaining') return true;
    return undefined;
  };
  const editStagedPayload = (newPayload): void => {
    updateStagedPayload((currentPayload) => ({ ...currentPayload, ...newPayload }));
  };

  const changeMode = (newMode: string): void => {
    updateDrawerMode(newMode);
  };

  const { data: workpackData, isLoading } = useGetSingleWorkpack({
    id: wpId,
  });

  useEffect(() => {
    if (drawerMode === 'new') {
      updateLocalWorkpackState(null);
      setInitialPayload({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
    }
  }, []);

  useEffect(() => {
    if (drawerMode !== DrawerState.NEW) {
      updateLocalWorkpackState(wp);
      const getPublicShareUrl = async (): Promise<void> => {
        try {
          const response = await getWorkpackUrl(wpId);
          setWorkpackUrl(`${servers.pub === 'localhost:8000' ? 'http://' : 'https://'}${servers.pub + response.url}`);
          setCRSUrl(response.url);
        } catch (err) {
          setWorkpackUrl('');
          setCRSUrl('');
        }
      };
      if (wpId) getPublicShareUrl();
    }

    if (wp) {
      setInitialPayload(wp);
      updateStagedPayload(wp);
      setLoading(false);
    }
  }, [wp?.id, wp?.status, wp?.date, wp?.description, wp?.po_number, wp?.workpack_items]);

  useEffect(() => {
    if (workpackData) {
      updateLocalWorkpackState(workpackData);
      updateStagedPayload(workpackData);
      setInitialPayload(workpackData);
    }
  }, [workpackData]);

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (workpacksMap.get(wpId)) {
      setInitialPayload(workpacksMap.get(wpId));
      updateLocalWorkpackState(workpacksMap.get(wpId));
      updateStagedPayload(workpacksMap.get(wpId));
      setLoading(false);
    }
  }, [workpacksMap]);

  useEffect(() => {
    if (localWorkpackState && initialPayload && !_.isEqualWith(localWorkpackState, initialPayload, customiser)) {
      setUnsavedChanges(true);
      handleUnsavedData(true);
    } else {
      setUnsavedChanges(false);
      handleUnsavedData(false);
    }
  }, [localWorkpackState, initialPayload]);

  useEffect(() => {
    window.addEventListener('focus', () => {
      if (crsClicked) Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
    });
  }, [crsClicked]);

  useEffect(() => {
    if (localWorkpackState) {
      const releaseInfo = wpItems && wpItems.find((item) => item?.mx_item?.mx_events?.length !== 0);
      if (releaseInfo) {
        if (releaseInfo?.mx_item?.mx_events) {
          const releaseInfoLatest = {
            ...releaseInfo?.mx_item?.mx_events[releaseInfo?.mx_item?.mx_events?.length - 1],
            date: localWorkpackState.release.date,
          };
          setReleaseData(releaseInfoLatest);
        }
      }
    }
  }, [localWorkpackState]);

  useEffect(() => {
    if (localWorkpackState) {
      const versions = localWorkpackState?.versions || [];
      const workpackVersions = localWorkpackState?.workpack_versions || [];
      const allVersions = versions.concat(workpackVersions);
      setLocalVersions(allVersions);
    }
  }, [localWorkpackState]);

  const removeWPItem = (itemId: string, mxItemId: string): void => {
    const localItemHasNotBeenSaved = (): boolean => itemId === null;
    let updatedLoclWorkpack;
    let updatedStagedPayload;

    if (localItemHasNotBeenSaved()) {
      updatedStagedPayload = wpItems.filter((item) => item.mx_item_id !== mxItemId);
      updatedLoclWorkpack = { ...localWorkpackState, workpack_items: updatedStagedPayload };
    } else {
      const updatedLoclWpItems = wpItems.filter((item) => item.id !== itemId);
      updatedLoclWorkpack = { ...localWorkpackState, workpack_items: updatedLoclWpItems };
      updatedStagedPayload = stagedPayload.workpack_items.concat({ id: itemId, _destroy: true });
    }
    editStagedPayload({ workpack_items: updatedStagedPayload });
    updateLocalWorkpackState(updatedLoclWorkpack);
  };

  const addWpItem = (mx_item, mxType): void => {
    let itemIsDuplicate;
    if (drawerMode !== 'new' && wpItems) itemIsDuplicate = wpItems.find((item) => mx_item.id === item.id);
    if (!itemIsDuplicate) {
      mx_item.type = mxType;
      const localItem = { mx_item, id: null, mx_item_id: mx_item.id };
      let addItemToLocalWorkpackState = [];
      if (drawerMode === 'new') {
        if (!wpItems) {
          addItemToLocalWorkpackState = [localItem];
        } else {
          addItemToLocalWorkpackState = ((wpItems as any[]) || []).concat(localItem);
        }
      } else {
        addItemToLocalWorkpackState = ((wpItems as any[]) || []).concat(localItem);
      }

      updateLocalWorkpackState((currentWorkpackState) => ({
        ...currentWorkpackState,
        workpack_items: addItemToLocalWorkpackState,
      }));
      if (drawerMode === 'new') {
        const addWPIdToPayload =
          stagedPayload?.workpack_items && stagedPayload?.workpack_items?.length > 0
            ? stagedPayload.workpack_items.concat({ mx_item_id: mx_item.id })
            : [{ mx_item_id: mx_item.id }];
        editStagedPayload({ workpack_items: addWPIdToPayload });
      } else {
        const addWPItemToPayload =
          stagedPayload?.workpack_items && stagedPayload?.workpack_items?.length > 0
            ? stagedPayload.workpack_items.concat({ mx_item_id: mx_item.id })
            : [{ mx_item_id: mx_item.id }];
        editStagedPayload({ workpack_items: addWPItemToPayload });
      }
    }
  };

  const resetPayload = (payload = null): void => {
    editStagedPayload(payload || initialPayload);
  };

  const resetDrawer = (): void => {
    changeMode('view');
    resetPayload();
    setInitialPayload(workpacksMap.get(wpId));
    updateLocalWorkpackState(workpacksMap.get(wpId));
    updateStagedPayload(workpacksMap.get(wpId));
  };

  const handleUnsavedItems = (): void => {
    if (!_.isEqualWith(stagedPayload, initialPayload, customiser)) {
      setModalVisible(true);
    } else {
      resetDrawer();
      if (drawerMode === 'new') {
        toggleDrawer();
      }
    }
  };

  const saveWorkpack = async (): Promise<void> => {
    await setLoading(true);
    try {
      if (drawerMode === 'new') {
        delete stagedPayload.id;
        stagedPayload.aircraft_id = ac[0].id;
        const response = await postWorkpack(stagedPayload);
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          const wpRes = await getWorkpackUrl(response.body.id);
          setWorkpackUrl(`${servers.pub === 'localhost:8000' ? 'http://' : 'https://'}${servers.pub + wpRes.url}`);
          setCRSUrl(wpRes.url);
          updateDrawerMode(DrawerState.VIEW);
          handleSetCurrentWorkpack(response.body);
          updateWorkpackid(response.body.id);
          updateLocalWorkpackState(response.body);
          setInitialPayload(response.body);
          resetPayload(response.body);
        } else {
          console.error(response?.statusCode);
        }
      } else {
        const response = await putWorkpack({ body: stagedPayload, id: wpId });
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(updateReduxWorkpack({ payload: response.body }));
          updateDrawerMode(DrawerState.VIEW);
          handleSetCurrentWorkpack(response.body);
          setInitialPayload(response.body);
          updateStagedPayload(response.body);
          updateLocalWorkpackState(response.body);
          resetPayload(response.body);
        } else {
          console.error(response?.statusCode);
        }
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const haveReleaseInfo = wpItems && wpItems.some((item) => item?.mx_item?.mx_events?.length !== 0);

  const completePendingWorkpack = async (): Promise<void> => {
    if (acceptanceSig.length !== 0 && haveReleaseInfo) {
      try {
        const { id } = localWorkpackState?.release;
        const response = await completeWorkpack({ id, body: { approval_signature_data: acceptanceSig } });

        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
          updateDrawerMode(DrawerState.VIEW);
          handleSetCurrentWorkpack(response.body);
          setInitialPayload(response.body);
          updateStagedPayload(response.body);
          resetPayload(response.body);
        } else {
          console.error(response.statusCode);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handlePendingIndexChange = (index: number): void => {
    const activePendingWp = pendingWps[index];
    updateLocalWorkpackState(activePendingWp);
    setAcceptanceSig('');
  };

  const handleDeleteAttachement = async ({ fileId: id }): Promise<void> => {
    if (id) {
      try {
        await deleteAttachment(id);
        Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleResolutionSave = async (): Promise<void> => {
    const response: any = Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
    Promise.all([response]).then((res) => {
      setLocalVersions(res[0]?.workpack_versions);
    });
  };

  let buttonText = `Add ${operatorSettings?.crs_short_name}`;
  if (status === ItemStatus.COMPLETE || status === ItemStatus.PENDING) {
    buttonText = `View ${operatorSettings?.crs_short_name}`;
  }
  if (featureFlags.includes(FeatureFlag.STANDALONERELEASE)) {
    buttonText = 'Submit Resolution';
    if (status === ItemStatus.COMPLETE || status === ItemStatus.PENDING)
      buttonText = `Add ${operatorSettings?.crs_short_name}`;
  }

  const resolutionInfo = wpItems && wpItems.find((item) => item?.mx_item?.mx_events?.length !== 0);
  const numberOfDraftItems = (wpItems && wpItems.filter((item) => item?.mx_item?.status === 'draft')?.length) || 0;
  const hasAnyDraftItems = numberOfDraftItems > 0;

  return (
    <>
      <div id="workpackDrawerWrapper" className={styles.workpackDrawerWrapper}>
        <Loading contain loading={loading && wpId} />
        <WorkpackTitle
          mode={drawerMode}
          description={description}
          changeMode={changeMode}
          date={date}
          po={po_number}
          editStagedPayload={editStagedPayload}
          status={status}
          handleDeleteWorkpack={handleDeleteWorkpack}
          wpId={wpId}
          unsavedChanges={unsavedChanges}
          handleUnsavedItems={handleUnsavedItems}
          aircraftId={aircraft_id}
          CRSUrl={CRSUrl}
          loading={loading}
          numberOfDraftItems={numberOfDraftItems}
          hasAnyDraftItems={hasAnyDraftItems}
        />
        {drawerMode === DrawerState.VIEW && (
          <WorkpackInfo dateCreated={date} status={status} dateCompleted={release?.date} publicLink={workpackUrl} />
        )}
        {false && (
          <NotesAndAttachments
            notes={notes}
            attachments={attachments}
            handleDeleteClick={handleDeleteAttachement}
            editStagedPayload={editStagedPayload}
            id={wpId}
            mode={drawerMode}
          />
        )}
        {ac[0] && ac[0].totals && (
          <AircraftTotals totals={ac[0].totals} showApu={ac[0].apu_installed} operatorSettings={operatorSettings} />
        )}
        {wpItems && wpItems.length > 0 && (
          <ViewWorkpacks
            wpItems={localWorkpackState?.workpack_items || []}
            mode={drawerMode}
            removeWPItem={removeWPItem}
            apuInstalled={ac[0]?.apu_installed}
            wpId={wpId}
          />
        )}
        {drawerMode === DrawerState.VIEW && status !== ItemStatus.DRAFT && wpItems?.length ? (
          <AircraftAuthenticationWrapper
            aircraftId={aircraft_id}
            requiredResource={AircraftResource.WORKPACK}
            requiredPermissionLevel={AircraftPermission.UPDATE}
          >
            <div className={styles.CRSButtonWrapper}>
              <a href={CRSUrl} target="_blank" rel="noopener noreferrer">
                <button type="button" className={styles.addCRSButton} onClick={(): void => handleCRSClicked()}>
                  {buttonText}
                </button>
              </a>
            </div>
          </AircraftAuthenticationWrapper>
        ) : null}
        {(drawerMode === DrawerState.EDIT || drawerMode === DrawerState.NEW) && (
          <AddItemsToWorkpack addWpItem={addWpItem} wpItems={wpItems} />
        )}
        {(status === ItemStatus.COMPLETE || status === ItemStatus.PENDING) &&
          resolutionInfo &&
          drawerMode === DrawerState.VIEW &&
          releaseData && (
            <ResolutionDetails
              release={releaseData}
              poNum={po_number}
              handleResolutionSave={handleResolutionSave}
              fromWorkpack
            />
          )}
        {drawerMode === DrawerState.VIEW ? <HistorySection versions={localVersions} title="workpack" /> : null}
        {status === ItemStatus.PENDING && (
          <CRSAcceptance setAcceptanceSig={setAcceptanceSig} operatorSettings={operatorSettings} />
        )}
        <Controls
          saveWorkpack={saveWorkpack}
          drawerMode={drawerMode}
          status={status}
          pendingWps={pendingWps}
          activeWPId={wpId}
          handlePendingIndexChange={handlePendingIndexChange}
          removeUnsavedItems={handleUnsavedItems}
          acceptanceSig={acceptanceSig}
          completePendingWorkpack={completePendingWorkpack}
        />
      </div>
    </>
  );
};

export default ViewWorkpack;
