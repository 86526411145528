import React from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import infoIcon from '../../assets/info-blue@2x.svg';
import styles from './AircraftStatusCollapseContent.module.less';

const AircraftStatusCollapseContent = ({ collapseContent, popoverContent }) => {
  const content = popoverContent;
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.titleWrapper} data-testid={`AircraftStatusTile--CollapseRowTitle${collapseContent.title}`}>
        {collapseContent && collapseContent.status && collapseContent.status.icon && (
          <img
            src={collapseContent.status.icon}
            alt="icon"
            className={collapseContent.status.title === 'Expired' ? styles.statusIconRed : styles.statusIcon}
          />
        )}
        <span className={styles.checkText}>
          {collapseContent.title} -{' '}
          <span className={collapseContent && collapseContent.status && collapseContent.status.style}>
            {collapseContent && collapseContent.status && collapseContent.status.title}
          </span>
        </span>
        {collapseContent.valid ? (
          <>
            <div
              className={styles.validForMobile}
              data-testid={`AircraftStatusTile--CollapseRowValidMobile${collapseContent.title}`}
            >
              <span>{`(${collapseContent.valid})`}</span>
            </div>
          </>
        ) : null}
      </div>
      {collapseContent.valid ? (
        <>
          <div className={styles.validFor} data-testid={`AircraftStatusTile--CollapseRowValid${collapseContent.title}`}>
            {collapseContent.valid}
          </div>
        </>
      ) : null}
      {collapseContent.valid ? (
        <Popover placement="left" trigger="click" content={content}>
          <img src={infoIcon} alt="icon" className={styles.infoIcon} />
        </Popover>
      ) : null}
    </div>
  );
};

AircraftStatusCollapseContent.propTypes = {
  collapseContent: PropTypes.object,
  popoverContent: PropTypes.object,
};

AircraftStatusCollapseContent.defaultProps = {
  collapseContent: {},
  popoverContent: {},
};

export default AircraftStatusCollapseContent;
