import { IntermittentFaultsActionTypes } from '.';

export const saveIntermittentFaults =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: IntermittentFaultsActionTypes.SAVE_ALL,
      payload,
    });
  };

export const saveIntermittentFault =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: IntermittentFaultsActionTypes.SAVE,
      payload,
    });
  };

export const removeIntermittentFault =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: IntermittentFaultsActionTypes.REMOVE,
      payload,
    });
  };
