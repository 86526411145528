/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useEffect, useState } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@arcflight/tf-component-library';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { fetchAircraftSublocation, updateAircraftSubLocation } from '../../models/cabinIssues/action';
import TFSelect from '../TFSelect/TFSelect';
import pencilIcon from '../../assets/edit@2x.svg';
import { DashboardState } from '../../models';
import { DisplayText, Header } from '../FlightDrawer/FlightDrawer';
import { ButtonWrapper, SaveWrapper, StyledInput } from './AircraftAdvancedSettings';

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-right: 16px;
  text-transform: capitalize;
  display: flex;
  height: 32px;
  align-items: center;
  @media (max-width: 450px) {
    flex-wrap: wrap;
    height: auto;
    max-width: 96%;
  }
`;

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
    width: 8px;
    height: 8px;
  }
`;

const Card = styled.div`
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  margin-bottom: 20px;
`;

const CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const SubHeaderWrapper = styled.div`
  padding: 0;
`;

const CardHeader = styled.div`
  letter-spacing: -0.08px;
  padding: ${({ padding }): string => (padding ? `${padding}` : '16px')};
  color: #181818;
`;

const SectionWrapper = styled.div`
  padding: 0 16px 16px;
`;

const SubLocationItemWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const Bold = styled.span`
  font-weight: bold;
  margin-left: 4px;
`;

const CabinIssueLocationDrawer = () => {
  const {
    drawer: { mode, drawerId },
    cabinIssues: { cabinIssueSubLocations },
  } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const [subLocations, setSubLocations] = useState([]);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [subLocationItems, setSubLocationItems] = useState([]);
  const [originalSubLocationItems, setOriginalSubLocationItems] = useState([]);

  const handleSelectChange = (option: { title: string; colour?: string; value?: string }): void => {
    setSelectedSubLocation(option);
  };

  const handleInputChange = (value, index): void => {
    const newLocations = [...subLocationItems];
    newLocations[index] = { ...newLocations[index], name: value };
    setSubLocationItems(newLocations);
  };

  const handleRemoveSubLocation = (index): void => {
    const foundItem = subLocationItems[index];
    if (foundItem?.id) {
      const newLocationItems = [...subLocationItems];
      newLocationItems[index] = { ...newLocationItems[index], _destroy: true };
      setSubLocationItems(newLocationItems);
    } else {
      const newLocationItems = [...subLocationItems];
      newLocationItems.splice(index, 1);
      setSubLocationItems(newLocationItems);
    }
  };

  const buildSubLocationRows = (): ReactElement[] => {
    let reduceIndexBy = 0;
    return subLocationItems?.map((location, index) => {
      if (location?._destroy === true) {
        reduceIndexBy += 1;
        return null;
      }
      const displayIndex = (index || 0) + 1 - reduceIndexBy;
      return (
        <SubLocationItemWrapper key={location?.id || index}>
          <div>
            <Header>{`Item ${displayIndex}`}</Header>
            {mode === 'view' ? (
              <DisplayText>{location?.name}</DisplayText>
            ) : (
              <StyledInput
                type="text"
                value={location?.name}
                onChange={(e): void => handleInputChange(e.target.value, index)}
              />
            )}
          </div>
          {mode === 'edit' ? (
            <Button height="28px" primary={false} onClick={(): void => handleRemoveSubLocation(index)}>
              - Remove item
            </Button>
          ) : null}
        </SubLocationItemWrapper>
      );
    });
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const checkPreviousSubItem = (): boolean => {
    for (const item of subLocationItems?.filter((location) => location?._destroy !== true)) {
      if (!item?.name) {
        message.error('Add a name to the existing sub location before adding a new one');
        return false;
      }
    }
    return true;
  };

  const handleAddSubLocation = (): void => {
    const newLocations = [...subLocationItems];
    if (checkPreviousSubItem()) {
      newLocations.push({ id: '', name: '' });
      setSubLocationItems(newLocations);
    }
  };

  const checkForDuplicatesInArray = (array): { valid: boolean; name?: string } => {
    const hasDuplicates = (arr, key): string => {
      const seen = new Set();
      for (const item of arr) {
        const value = item[key]?.toLowerCase();
        if (seen.has(value)) {
          return item.name;
        }
        if (key === 'id' && value === '') {
          // id not set as just added so do not add to seen based on id
        } else {
          seen.add(value);
        }
      }
      return null;
    };
    let foundDuplicateName = hasDuplicates(array, 'name');
    if (!foundDuplicateName) foundDuplicateName = hasDuplicates(array, 'id');
    if (foundDuplicateName) {
      return { valid: false, name: foundDuplicateName };
    }
    return { valid: true };
  };

  const validateData = (): boolean => {
    for (const item of subLocationItems?.filter((location) => location?._destroy !== true)) {
      if (!item?.name) {
        message.error('All sub locations must have a name');
        return false;
      }
      const validityResponse = checkForDuplicatesInArray(subLocationItems);
      if (!validityResponse.valid) {
        message.error(`All sub locations must have unique names, please change ${validityResponse.name}`);
        return false;
      }
    }
    return true;
  };

  const handleSaveClick = (): void => {
    if (validateData()) {
      const newSubLocation = cabinIssueSubLocations?.find((location) => location.id === selectedSubLocation.value);
      newSubLocation.aircraft_sub_location_group_list_items_attributes = subLocationItems;
      const updatedLocations = dispatch(
        updateAircraftSubLocation({
          payload: {
            id: newSubLocation.id,
            aircraftId: id,
            aircraft_sub_location_group_list: { aircraft_sub_location_group_list_items_attributes: subLocationItems },
          },
        }),
      );
      Promise.all([updatedLocations]).then((data: any) => {
        if (data[0]) {
          const newLocations = data[0]?.data?.aircraft_sub_location_group_list_items;
          setSubLocationItems(newLocations);
          setOriginalSubLocationItems(newLocations);
        }
      });
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const handleCancelClick = (): void => {
    if (mode === 'edit') {
      dispatch(changeDrawerMode({ payload: 'view' }));
      setSubLocationItems(originalSubLocationItems);
    } else {
      dispatch(changeDrawerVisibility({ payload: false }));
    }
  };

  useEffect(() => {
    // fetch request for sub locations
    if (selectedSubLocation) {
      const payload = {
        aircraftId: id,
        id: selectedSubLocation.value,
      };
      dispatch(fetchAircraftSublocation({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubLocation]);

  useEffect(() => {
    if (cabinIssueSubLocations) {
      const newSubLocations = cabinIssueSubLocations?.map((location) => {
        return {
          value: location.id,
          title: location.name,
        };
      });
      setSubLocations(newSubLocations);
      let newSubLoc = newSubLocations[0];
      if (drawerId) {
        newSubLoc = newSubLocations?.find((location) => location.value === drawerId);
      }
      if (!selectedSubLocation) {
        setSelectedSubLocation(newSubLoc);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinIssueSubLocations, drawerId]);

  useEffect(() => {
    if (selectedSubLocation) {
      const foundLocation = cabinIssueSubLocations?.find((location) => location.id === selectedSubLocation.value);
      const newItems =
        foundLocation?.aircraft_sub_location_group_list_items_attributes ||
        foundLocation?.aircraft_sub_location_group_list_items ||
        [];
      setSubLocationItems(newItems);
      const differentMemoryNewNames = [...newItems];
      setOriginalSubLocationItems(differentMemoryNewNames);
    }
  }, [cabinIssueSubLocations, selectedSubLocation]);

  let drawerTitle = `Cabin group:`;
  if (mode === 'edit') {
    drawerTitle = `Edit cabin group:`;
  }
  if (mode === 'add') {
    drawerTitle = 'New Cabin Group';
  }

  return (
    <DrawerWrapper>
      <TitleWrapper>
        <Title>
          {drawerTitle}
          {mode !== 'add' ? <Bold>{selectedSubLocation?.title}</Bold> : null}
        </Title>
        {mode === 'view' ? (
          <Button height="24px" primary={false} onClick={handleEditClick}>
            <ButtonInner>
              <img src={pencilIcon} alt="pencil" />
              Edit
            </ButtonInner>
          </Button>
        ) : null}
      </TitleWrapper>
      <Card>
        <CardHeader>Main details</CardHeader>
        <SectionWrapper>
          <Header editable={mode !== 'view'}>Group name</Header>
          {mode === 'view' ? (
            <DisplayText>{selectedSubLocation?.title}</DisplayText>
          ) : (
            <TFSelect
              options={subLocations}
              initial={selectedSubLocation}
              handleSelectChange={(option): void => handleSelectChange(option)}
              allowEmpty
            />
          )}
        </SectionWrapper>
      </Card>
      <Card>
        <CardHeaderWrapper>
          <SubHeaderWrapper>
            <CardHeader padding="0">Sub locations</CardHeader>
          </SubHeaderWrapper>
          {mode !== 'view' ? (
            <Button height="24px" primary={false} onClick={handleAddSubLocation}>
              + Add sub location
            </Button>
          ) : null}
        </CardHeaderWrapper>
        {buildSubLocationRows()}
      </Card>
      {mode !== 'view' ? (
        <ButtonWrapper>
          <SaveWrapper>
            <Button height="32px" onClick={handleSaveClick}>
              Save
            </Button>
          </SaveWrapper>
          <Button height="32px" primary={false} onClick={handleCancelClick}>
            Cancel
          </Button>
        </ButtonWrapper>
      ) : null}
    </DrawerWrapper>
  );
};

export default CabinIssueLocationDrawer;
