import { Button } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Portal from '../TFPortal/TFPortal';

interface TFDropdownMenuProps {
  options: {
    title: ReactElement;
    value: string;
    onClick: (value) => void;
    deleteStyle?: boolean;
    id?: string;
    aiStyle?: boolean;
  }[];
  buttonText: ReactElement;
  buttonPrimary?: boolean;
}

const Wrapper = styled.div`
  position: relative;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  background: #fff;
  box-shadow: 0 0 10px 5px #dbe3ed;
  border-radius: 4px;
  border: 1px solid #fff;
`;

const StyledOption = styled.button`
  background: ${({ deleteStyle, aiStyle }): string => {
    if (aiStyle) return 'rgba(144, 0, 255, 0.05)';
    if (deleteStyle) return 'rgba(202, 35, 34, 0.05)';
    return 'transparent';
  }};
  border: none;
  padding: 8px 10px;
  cursor: pointer;
  color: ${({ deleteStyle, aiStyle, theme }): string => {
    if (aiStyle) return `${theme.colours.purple}`;
    if (deleteStyle) return '#d01e27';
    return '#126fd6';
  }};
  text-align: left;
  &:hover {
    background-color: ${({ deleteStyle, aiStyle }): string => {
      if (aiStyle) return 'rgba(144, 0, 255, 0.1)';
      if (deleteStyle) return 'rgba(202, 35, 34, 0.1)';
      return '#e6f7ff';
    }};
  }
`;

const InvisibleBackground = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 501;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const TFDropdownMenu: React.FC<TFDropdownMenuProps> = ({ options, buttonText, buttonPrimary = false }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownStyling, setDropdownStyling] = useState(null);

  const wrapperRef = useRef(null);

  const handleButtonClick = (): void => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const rect = wrapper.getBoundingClientRect();
      setDropdownStyling({
        top: rect.bottom + 4 + window.scrollY,
        left: rect.left + 10 + window.scrollX,
        minWidth: rect.width - 20,
      });
    }
  }, [dropdownVisible]);

  return (
    <Wrapper data-testid="TFDropdownMenu--Wrapper" ref={wrapperRef}>
      <Button data-testid="TFDropdownMenu--Button" height="30px" primary={buttonPrimary} onClick={handleButtonClick}>
        {buttonText}
      </Button>
      {dropdownVisible && (
        <Portal>
          <InvisibleBackground onClick={(): void => setDropdownVisible(false)}>
            <OptionsWrapper style={dropdownStyling} data-testid="TFDropdownMenu--OptionsWrapper">
              {options?.map((opt) => (
                <StyledOption
                  onClick={(): void => {
                    setDropdownVisible(false);
                    opt.onClick(opt.value);
                  }}
                  deleteStyle={opt.deleteStyle}
                  aiStyle={opt.aiStyle}
                  key={opt.id}
                  data-testid={`TFDropdownMenu--Option-${opt.id}`}
                >
                  {opt.title}
                </StyledOption>
              ))}
            </OptionsWrapper>
          </InvisibleBackground>
        </Portal>
      )}
    </Wrapper>
  );
};

export default TFDropdownMenu;
