import { Button } from '@arcflight/tf-component-library';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import editIcon from '../../assets/icon-edit.svg';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../models/aircraft';

interface AircraftSettingsCardProps {
  icon: any;
  title: string;
  setEditMode?: (value: boolean) => void;
  buttonText: string;
  editMode?: boolean;
  subTitle?: string;
  isAdmin: boolean;
}

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 16px 10px 0;
  padding-left: -4px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }
`;

const TextWrapper = styled.div``;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.08px;
  color: #181818;
`;

const SubTitle = styled.div`
  font-size: 10px;
  line-height: 1.6;
  color: rgba(36, 45, 65, 0.8);
`;

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const AircraftSettingsCard: React.FC<AircraftSettingsCardProps> = ({
  icon,
  title,
  setEditMode,
  buttonText,
  editMode,
  subTitle,
  isAdmin,
}) => {
  const { id } = useParams<{ id: string }>();
  const isMobile = window.innerWidth < 451;
  const { formatMessage } = useIntl();

  return (
    <Header>
      <TitleWrapper>
        <img src={icon} alt="maintenance" data-testid="AircraftSettingsCard--TitleIcon" />
        <TextWrapper>
          <Title data-testid="AircraftSettingsCard--Title">{title}</Title>
          {subTitle ? <SubTitle data-testid="AircraftSettingsCard--SubTitle">{subTitle}</SubTitle> : null}
        </TextWrapper>
      </TitleWrapper>
      {isAdmin && !editMode && buttonText ? (
        <AircraftAuthenticationWrapper
          aircraftId={id}
          requiredResource={AircraftResource.AIRCRAFT}
          requiredPermissionLevel={AircraftPermission.UPDATE}
        >
          <Button
            primary={false}
            height="24px"
            onClick={(e): void => {
              e.stopPropagation();
              if (setEditMode) setEditMode(true);
            }}
          >
            <ButtonInner data-testid={`AircraftSettingsCard--EditButton-${buttonText}`}>
              <img src={editIcon} alt="edit" />
              {isMobile ? formatMessage({ id: 'text.edit' }) : buttonText}
            </ButtonInner>
          </Button>
        </AircraftAuthenticationWrapper>
      ) : null}
    </Header>
  );
};

export default AircraftSettingsCard;
