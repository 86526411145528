import React, { useState, ReactNode } from 'react';
import { Search, Button as TFButton, Loading } from '@arcflight/tf-component-library';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useQuery } from '@tanstack/react-query';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import EditAircraftModal from '../../components/EditAircraftForm';
import TFCard from '../../components/TFCard/TFCard';
import TFTable from '../../components/TFTable/TFTable';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { add } from '../../models/userSettings/actions';
import { Aircraft, AircraftResource } from '../../models/aircraft';
import { DashboardState } from '../../models';
import config, { ConfigKey } from '../../utils/config';
import FleetIcon from '../../assets/topNav/title-fleet.svg';
import plusIcon from '../../assets/plus.svg';
import GridIconDefault from '../../assets/icon-grid-view-default.svg';
import GridIconSelected from '../../assets/icon-grid-view-selected.svg';
import TableIconDefault from '../../assets/icon-table-view-default.svg';
import TableIconSelected from '../../assets/icon-table-view-selected.svg';

interface AircraftProps {
  isDashboardPage?: boolean;
}

const cookies = new Cookies();

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SearchWrapper = styled.div`
  display: flex;
  img,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
`;

const GridImg = styled.img`
  cursor: pointer;
`;

const TableImg = styled.img`
  cursor: pointer;
`;

async function getAircraft(): Promise<any> {
  const res = await fetch(`${config.get(ConfigKey.API)}/aircraft?detail_level=light`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  });
  if (!res.ok) {
    const errorResponse = await res.json();
    throw new Error(errorResponse.error);
  }
  return res.json();
}

const AircraftPage: React.FC<AircraftProps> = ({ isDashboardPage }) => {
  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedAircraft, setSelectedAircraft] = useState<Aircraft>();
  const [sortOrders, setSortOrders] = useState([{ id: 'registration', desc: false }]);

  const {
    userSettings: { details },
  } = useSelector((state: DashboardState) => state);

  const { data, isLoading, error } = useQuery({
    queryKey: ['aircraft'],
    queryFn: getAircraft,
    refetchOnWindowFocus: false,
  });

  const handleEditClick = (val): void => {
    setShowModal(true);
    setSelectedAircraft(val.original);
  };

  const handleCancel = (): void => {
    setShowModal(false);
    setSelectedAircraft(null);
  };

  function handleSort(sortColumns, dataArray): Aircraft[] {
    return dataArray.sort((a, b) => {
      for (const sortOrder of sortColumns) {
        const colId = sortOrder.id;
        const { desc } = sortOrder;
        const valA = a[colId];
        const valB = b[colId];

        if (valA === null || valA === undefined) return 1;
        if (valB === null || valB === undefined) return -1;

        if (typeof valA === 'string') {
          const compareResult = valA.localeCompare(valB);
          if (compareResult !== 0) return desc ? -compareResult : compareResult;
        } else if (valA !== valB) return desc ? valB - valA : valA - valB;
      }
      return 0;
    });
  }

  const columns = [
    {
      Header: formatMessage({ id: 'title.operator' }),
      accessor: 'operator_name',
    },
    {
      Header: formatMessage({ id: 'title.registration' }),
      accessor: 'registration',
    },
    {
      Header: formatMessage({ id: 'title.manufacturer' }),
      accessor: 'manufacturer',
    },
    {
      Header: formatMessage({ id: 'title.model' }),
      accessor: 'model',
    },
    {
      Header: formatMessage({ id: 'title.designator' }),
      accessor: 'designator',
    },
    {
      Header: formatMessage({ id: 'title.year' }),
      accessor: 'year',
    },
    {
      Header: formatMessage({ id: 'title.serial' }),
      accessor: 'serial_number',
    },
  ] as { Header: string; accessor?: string; Cell?: ReactNode; id?: string }[];
  if (!isDashboardPage) {
    columns.push({
      Header: '',
      id: 'actions',
      Cell: ({ row }): ReactNode => {
        return (
          <>
            <AuthDropdownMenu
              handleDelete={null}
              resource={AircraftResource.AIRCRAFT}
              aircraftId={id}
              options={{ read: false, update: true, delete: false }}
              editCallback={(): void => handleEditClick(row)}
              forTable
            />
          </>
        );
      },
    });
  }

  const renderAlt = (): ReactNode => {
    if (isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <h4>{formatMessage({ id: 'text.genericErrorMessage' })}</h4>
      </div>
    );
  };

  const getFilteredData = (): Aircraft[] => {
    let filteredData = data;
    const sortedDate = handleSort(sortOrders, filteredData);
    if (searchInput.length === 0) {
      return sortedDate;
    }
    filteredData = data.filter((item) => {
      return (
        item.operator_name.toLowerCase().includes(searchInput) ||
        item.registration.toLowerCase().includes(searchInput) ||
        item.manufacturer.toLowerCase().includes(searchInput) ||
        item.model.toLowerCase().includes(searchInput) ||
        item.designator.toLowerCase().includes(searchInput) ||
        item.year.toString().includes(searchInput) ||
        item.serial_number.toLowerCase().includes(searchInput)
      );
    });
    return filteredData;
  };

  const handleSwitchChange = (style: string): void => {
    const formData = {
      fleet_style: style,
    };
    dispatch(add({ payload: { form: formData, type: 'fleetStyle' } }));
  };

  const handlePaginationChange = (): void => {
    // console.log('pagination change');
  };

  const handleSortChange = (sort): void => {
    setSortOrders(sort);
  };

  const TableStyle = details?.dash_preferences?.fleet_style === 'light';

  return (
    <PageHeaderWrapper icon={FleetIcon} title={formatMessage({ id: 'title.fleet' })}>
      <TFCard border="none">
        <ActionsRow>
          <SearchWrapper>
            <Search
              onClear={(): void => setSearchInput('')}
              onChange={(e): void => setSearchInput(e.target.value.toLowerCase())}
              reset={false}
            />
          </SearchWrapper>
          <SwitchWrapper>
            {isDashboardPage ? (
              <>
                <span>
                  {window.innerWidth < 968
                    ? formatMessage({ id: 'text.streamlined' })
                    : formatMessage({ id: 'text.toggleView' })}
                </span>
                <GridImg
                  onClick={() => handleSwitchChange('full')}
                  src={TableStyle ? GridIconDefault : GridIconSelected}
                />
                <TableImg
                  onClick={() => handleSwitchChange('light')}
                  src={TableStyle ? TableIconSelected : TableIconDefault}
                />
                {/* <Switch initialIsChecked onChange={handleSwitchChange} /> */}
              </>
            ) : (
              <Link to="/add/aircraft">
                <TFButton size={ButtonSize.MEDIUM} onClick={null}>
                  <ButtonText>
                    <img src={plusIcon} alt="plus icon" />{' '}
                    {window.innerWidth < 968
                      ? formatMessage({ id: 'form.button.add' })
                      : formatMessage({ id: 'form.button.addAircraft' })}
                  </ButtonText>
                </TFButton>
              </Link>
            )}
          </SwitchWrapper>
        </ActionsRow>

        {isLoading || error ? (
          renderAlt()
        ) : (
          <TFTable
            columns={columns}
            data={getFilteredData()}
            handleRowClick={(clickedAircraftId): void => history.push(`/aircraft/${clickedAircraftId}/overview`)}
            hiddenColumns={[]}
            onPaginationChange={handlePaginationChange}
            onSortChange={handleSortChange}
            total={data.length}
            pageSize={1000}
            pageIndex={1 - 1}
            resetToOne
          />
        )}
      </TFCard>
      {showModal && selectedAircraft && selectedAircraft.id ? (
        <EditAircraftModal aircraft={selectedAircraft} onCancel={handleCancel} />
      ) : null}
    </PageHeaderWrapper>
  );
};

export default AircraftPage;
