import Cookies from 'universal-cookie';
import servers from './servers';

const cookies = new Cookies();

/**
 * Logs the user out and cleanup their session data.
 */
const logout = (): void => {
  const cookieOptions = { path: '/', domain: servers.cookieDomain };
  cookies.remove('auth', cookieOptions);
  cookies.remove('operators', cookieOptions);
  cookies.remove('org', cookieOptions);
  cookies.remove('user', cookieOptions);
  cookies.remove('userId', cookieOptions);
  cookies.remove('user_authority', cookieOptions);
  cookies.remove('user_id', cookieOptions);
  localStorage.removeItem('aircraft');
  localStorage.removeItem('bannerSeen');
  window.location.href = `${servers.api}/logout`;
};

export default logout;
