import { useQuery } from '@tanstack/react-query';
import { getAllFRAT } from '../../services/api';
import { FRAT } from '../../models/frats';

const useGetFRATData = ({
  selectedOperatorId,
  searchValue,
}: {
  selectedOperatorId: string;
  searchValue: string;
}): { FRATData: FRAT[]; isLoading: boolean; names: string[] } => {
  const { data: FRATData, isLoading } = useQuery({
    queryKey: ['FRAT', selectedOperatorId],
    queryFn: () => (selectedOperatorId ? getAllFRAT({ operator_id: selectedOperatorId, search: searchValue }) : []),
    refetchOnWindowFocus: false,
  });

  const names = FRATData?.map((item: FRAT) => item.name) || [];

  return { FRATData, isLoading, names };
};

export default useGetFRATData;
