/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion } from '@arcflight/tf-component-library';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Trip } from '../../models/trips';
import weatherIcon from '../../assets/icon-weather-conditions.svg';
import trendMonitoringIcon from '../../assets/icon-card-trend-monitoring.svg';
import engineIcon from '../../assets/icon-engine-default.svg';
import { DisplayText, Header, SectionHeader, StyledInput } from '../FlightDrawer/FlightDrawer';
import { DashboardState } from '../../models';
import { Operator } from '../../models/userSettings';

interface TripTrendingSectionProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any) => void;
  currentOp: Operator;
}

const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1.33;
  font-size: 12px;
  text-transform: capitalize;
  img {
    margin-right: 8px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }): string => (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
  padding: 16px 20px;
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
`;

const UnitSpan = styled.span`
  margin-left: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ReasonWrapper = styled.div`
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: none;
  padding: 20px;
`;

const SectionWrapper = styled.div`
  margin-bottom: 32px;
`;

const AccordionWrapper = styled.div`
  margin-bottom: 20px;
`;

const PartsDiv = styled.div`
  margin-right: 20px;
`;

const TripTrendingSection: React.FC<TripTrendingSectionProps> = ({ trip, editable, updateTripData, currentOp }) => {
  let trendMonitoring;
  if (trip?.custom_data?.trend_monitoring) trendMonitoring = trip.custom_data.trend_monitoring;

  const {
    aircraft: { aircraftMap },
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const aircraft = aircraftMap.get(id);
  let engineCount = 1;
  if (aircraft?.aircraft_type?.engine_count) {
    engineCount = aircraft?.aircraft_type?.engine_count;
  }

  const trendRename = aircraft?.standard_fields?.trend_monitoring?.name_override;

  const [pressAlt, setPressAlt] = useState(null);
  const [IOAT, setIOAT] = useState(null);
  const [IAS, setIAS] = useState(null);
  const [engineDataArray, setEngineDataArray] = useState([]);
  const [justification, setJustification] = useState(null);

  const isMobile = window.innerWidth < 451;

  const dataOrder = ['itt', 'ff', 'op', 'ot', 'n1', 'n2', 'tq', 'np', 'ng'];

  const updateTrendMonitoring = (): void => {
    const newCustomData = {
      ...trip.custom_data,
      trend_monitoring: {
        ...trip.custom_data?.trend_monitoring,
        ias: IAS,
        ioat: IOAT,
        press_alt: pressAlt,
        [`${engineDataArray[0][0]}`]: engineDataArray[0][1],
      },
    };
    if (engineDataArray.length > 1) {
      engineDataArray.forEach((engine) => {
        // eslint-disable-next-line prefer-destructuring
        newCustomData.trend_monitoring[engine[0]] = engine[1];
      });
    }
    updateTripData('custom_data', newCustomData);
  };

  const handleConditionsChange = (value: string, key: string): void => {
    if (key === 'press') {
      setPressAlt(value);
      const newCustomData = {
        ...trip.custom_data,
        trend_monitoring: {
          ...trip.custom_data?.trend_monitoring,
          press_alt: value,
        },
      };
      updateTripData('custom_data', newCustomData);
    }
    if (key === 'ias') {
      setIAS(value);
      const newCustomData = {
        ...trip.custom_data,
        trend_monitoring: {
          ...trip.custom_data?.trend_monitoring,
          ias: value,
        },
      };
      updateTripData('custom_data', newCustomData);
    }
    if (key === 'ioat') {
      setIOAT(value);
      const newCustomData = {
        ...trip.custom_data,
        trend_monitoring: {
          ...trip.custom_data?.trend_monitoring,
          ioat: value,
        },
      };
      updateTripData('custom_data', newCustomData);
    }
  };

  const buildNewEngineArray = (): any => {
    const newDataObject = {};
    for (let i = 0; i < engineCount; i += 1) {
      const newObject = {};
      dataOrder.forEach((item) => {
        newObject[`${item}`] = '';
      });
      newDataObject[`engine_${i + 1}`] = newObject;
    }
    return newDataObject;
  };

  const buildEngine = (engine): any => {
    const newObject = {};
    dataOrder.forEach((item) => {
      newObject[`${item}`] = '-';
    });
    const mergedEngine = {
      ...engine,
      ...newObject,
    };
    return mergedEngine;
  };

  useEffect(() => {
    if (trip?.custom_data?.trend_monitoring) {
      const newEngineData = Array.from(
        Object.entries(trendMonitoring).filter((entryArray) => entryArray[0].startsWith('engine')),
      );
      const correctedEngineData = newEngineData.map((engine) => {
        const title = engine[0];
        let newEngine = engine[1];
        if (Object.keys(engine[1]).length === 0) {
          newEngine = buildEngine(engine[1]);
        }
        return [title, newEngine];
      });
      setEngineDataArray(correctedEngineData);
    }
    if (mode !== 'view' && !trip?.custom_data?.trend_monitoring?.engine_1) {
      const newEngineDataArray = buildNewEngineArray();
      setEngineDataArray(Object.entries(newEngineDataArray));
    }
    if (trendMonitoring?.ias) {
      setIAS(trendMonitoring?.ias);
    } else {
      setIAS('');
    }
    if (trendMonitoring?.ioat) {
      setIOAT(trendMonitoring?.ioat);
    } else {
      setIOAT('');
    }
    if (trendMonitoring?.press_alt) {
      setPressAlt(trendMonitoring?.press_alt);
    } else {
      setPressAlt('');
    }
  }, [trendMonitoring, trip, mode]);

  useEffect(() => {
    if (trip?.custom_data?.trend_monitoring?.justifications) {
      const dateArray = Array.from(Object.keys(trip?.custom_data?.trend_monitoring?.justifications));
      // Some dates returning from core were missing a 0 for single digit hour values i.e 2022-07-25T5:54:05.861+0000
      // The below function attempts to fix this by spotting the shorter dates and inserting 0 to avoid invalid dates
      const sanitisedDateArray = dateArray.map((date) => {
        if (date.length === 27) {
          const test = `${date.slice(0, 11)}0${date.slice(11)}`;
          return test;
        }
        return date;
      });
      const mostRecentDate = new Date(
        Math.max.apply(
          null,
          sanitisedDateArray.map((date) => new Date(date)),
        ),
      ).toISOString();
      const dateConverted = mostRecentDate.replace('Z', '+0000');
      const justificationObject = trip?.custom_data?.trend_monitoring?.justifications[`${dateConverted}`];
      setJustification(justificationObject?.trend_monitoring);
    }
  }, [trip]);

  const handleInputChange = (value: string, index: number, key: string): void => {
    const newArray = engineDataArray ? [...engineDataArray] : [];
    newArray[index][1][key] = value;
    setEngineDataArray([...newArray]);
    updateTrendMonitoring();
  };

  const engineSection = (): ReactNode => {
    return engineDataArray.map((engine, index) => {
      return (
        <AccordionWrapper key={engine[0]} data-testid={`TripTrendingSection-Engine${index}`}>
          <Accordion
            isExpanded={mode === 'add'}
            headerContent={
              <RowHeader data-testid={`TripTrendingSection-EngineHeader${index}`}>
                <TitleWrapper>
                  <img src={engineIcon} alt="engine icon" />
                  {currentOp ? currentOp.operator_setting[engine[0]] : engine[0].replace(/_/g, ' ')}
                </TitleWrapper>
                <PartsDiv>6 parts</PartsDiv>
              </RowHeader>
            }
            dash
            add
          >
            <ContentWrapper isMobile={isMobile} marginBottom={engineDataArray.length !== index + 1}>
              {engine[1] &&
                Object.entries(engine[1])
                  .sort((a, b) => dataOrder.indexOf(a[0]) - dataOrder.indexOf(b[0]))
                  .map((entry) => {
                    let displayUnit = '°C';
                    let displayHeader = entry[0];
                    if (entry[0] === 'ff') {
                      displayUnit = 'lb/hr';
                      displayHeader = 'FF';
                    }
                    if (entry[0] === 'itt') displayHeader = 'ITT';
                    if (entry[0] === 'op') {
                      displayUnit = 'psi';
                      displayHeader = 'oil pressure';
                    }
                    if (entry[0] === 'ot') displayHeader = 'oil temp';
                    if (entry[0] === 'n1' || entry[0] === 'n2') displayUnit = '%';
                    if (entry[0] === 'np') displayUnit = 'rpm';
                    if (entry[0] === 'tq') displayUnit = 'ft/lbs';
                    if (entry[0] === 'ng') displayUnit = '%';
                    return (
                      <div
                        key={`${displayHeader}${engine[0]}`}
                        data-testid={`TripTrendingSection-Engine${entry[0]}${index}`}
                      >
                        <Header editable={editable}>{displayHeader}</Header>
                        {editable ? (
                          <>
                            <StyledInput
                              width={74}
                              type="number"
                              placeholder="-"
                              value={entry[1].toString()}
                              onChange={(e): void => handleInputChange(e.target.value, index, entry[0])}
                            />
                            <UnitSpan>{displayUnit}</UnitSpan>
                          </>
                        ) : (
                          <DisplayText>{`${entry[1]} ${displayUnit}`}</DisplayText>
                        )}
                      </div>
                    );
                  })}
            </ContentWrapper>
          </Accordion>
        </AccordionWrapper>
      );
    });
  };

  return (
    <SectionWrapper data-testid="TripDrawer-TripTrendingSection">
      <SectionHeader marginLeft="20px" data-testid="TripTrendingSection-Header">
        {`${trendRename || 'Trend Monitoring'}`}
      </SectionHeader>
      <AccordionWrapper>
        <Accordion
          isExpanded={mode === 'add'}
          headerContent={
            <RowHeader data-testid="TripTrendingSection-ConditionsHeader">
              {!justification ? (
                <>
                  <TitleWrapper>
                    <img src={weatherIcon} alt="weather icon" />
                    Conditions
                  </TitleWrapper>
                  <PartsDiv>3 parts</PartsDiv>
                </>
              ) : (
                <>
                  <TitleWrapper>
                    <img src={trendMonitoringIcon} alt="weather icon" />
                    Justification
                  </TitleWrapper>
                  <PartsDiv>1 part</PartsDiv>
                </>
              )}
            </RowHeader>
          }
          dash
          add
        >
          {!justification ? (
            <ContentWrapper isMobile={isMobile} marginBottom>
              <div data-testid="TripTrendingSection-PressAltSection">
                <Header editable={editable}>Press Alt</Header>
                {editable ? (
                  <>
                    <StyledInput
                      width={74}
                      value={pressAlt}
                      type="number"
                      placeholder="-"
                      onChange={(e): void => handleConditionsChange(e.target.value, 'press')}
                    />
                    <UnitSpan>ft</UnitSpan>
                  </>
                ) : (
                  <DisplayText>{trendMonitoring?.press_alt ? `${trendMonitoring?.press_alt} ft` : '-'}</DisplayText>
                )}
              </div>
              <div data-testid="TripTrendingSection-IOATSection">
                <Header editable={editable}>IOAT</Header>
                {editable ? (
                  <>
                    <StyledInput
                      width={74}
                      value={IOAT}
                      type="number"
                      placeholder="-"
                      onChange={(e): void => handleConditionsChange(e.target.value, 'ioat')}
                    />
                    <UnitSpan>°C</UnitSpan>
                  </>
                ) : (
                  <DisplayText>{trendMonitoring?.ioat ? `${trendMonitoring?.ioat} °C` : '-'}</DisplayText>
                )}
              </div>
              <div data-testid="TripTrendingSection-IASSection">
                <Header editable={editable}>IAS</Header>
                {editable ? (
                  <>
                    <StyledInput
                      width={74}
                      value={IAS}
                      placeholder="-"
                      type="number"
                      onChange={(e): void => handleConditionsChange(e.target.value, 'ias')}
                    />
                    <UnitSpan>kts</UnitSpan>
                  </>
                ) : (
                  <DisplayText>{trendMonitoring?.ias ? `${trendMonitoring?.ias} kts` : '-'}</DisplayText>
                )}
              </div>
            </ContentWrapper>
          ) : (
            <ReasonWrapper>{justification}</ReasonWrapper>
          )}
        </Accordion>
      </AccordionWrapper>
      {engineDataArray.length ? engineSection() : null}
    </SectionWrapper>
  );
};

export default TripTrendingSection;
