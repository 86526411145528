import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { setDefaultImage } from '../../models/damageMaps/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import DamageMapsTabs from './DamageMapsTabs';
import styles from './DamageMaps.module.less';

class DamageMaps extends PureComponent {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    operators: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'list_view',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const aircraft = this.getAircraft();
    if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
      this.getAircraft(true);
    }
    if (location.pathname.includes('schematic_view')) {
      this.updateActiveTab();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, match, location } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      dispatch(
        setDefaultImage({
          payload: '',
        }),
      );
    }
    if (location.pathname.includes('schematic_view')) {
      this.updateActiveTab();
    }
  }

  updateActiveTab = () => {
    this.setState({ activeTab: 'schematic_view' });
  };

  getAircraft = (forceRefetch = false) => {
    const { dispatch, match, aircraftMap } = this.props;
    if (forceRefetch) {
      dispatch(
        getSingleAircraft({
          payload: match.params.id,
        }),
      );
    }
    return aircraftMap.get(match.params.id);
  };

  handleTabChange = (key) => {
    const { match, history } = this.props;
    switch (key.value) {
      case 'list_view':
        this.setState({ activeTab: 'list_view' });
        history.push(`/aircraft/${match.params.id}/damage_maps/list_view`);
        break;
      case 'schematic_view':
        this.setState({ activeTab: 'schematic_view' });
        history.push(`/aircraft/${match.params.id}/damage_maps/schematic_view`);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      children,
      intl: { formatMessage },
      operators,
      aircraftMap,
    } = this.props;
    const { activeTab } = this.state;

    const tabList = [
      {
        value: 'list_view',
        label: formatMessage({ id: 'tab.listView' }),
      },
      {
        value: 'schematic_view',
        label: formatMessage({ id: 'tab.schematicView' }),
      },
    ];
    const aircraft = this.getAircraft();
    const operatorSettings = operators?.find(
      (op) => op.id === aircraftMap.get(aircraft.id)?.operator_id,
    )?.operator_setting;
    return (
      <InnerMenuLayout loading={false}>
        <div className={styles.pageWrapper}>
          <div className={styles.pageTitle}>
            <span>{operatorSettings.dent_and_buckle}</span>
          </div>
          <DamageMapsTabs activeTab={activeTab} tabList={tabList} handleTabChange={this.handleTabChange} />
          {children}
        </div>
      </InnerMenuLayout>
    );
  }
}

const damageMapsWithRedux = connect(({ aircraft, damageMaps, userSettings }) => ({
  aircraftMap: aircraft.aircraftMap,
  damageMaps,
  operators: userSettings?.details.operators,
}))(DamageMaps);
export default withRouter(injectIntl(damageMapsWithRedux));
