import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EmptyStateHistory from '../../assets/emptyState/empty-state-history.svg';
import HistoryTable from './HistoryTable';

interface HistorySectionProps {
  versions: any[];
  title?: string;
  noJustification?: boolean;
}

const SectionWrapper = styled.div`
  margin-top: 32px;
  div,
  img,
  svg,
  button,
  input {
    box-sizing: revert !important;
    line-height: normal;
  }
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 12px 0;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const SectionHeader = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: 20px;
`;

const HistorySection: React.FC<HistorySectionProps> = ({ versions, title, noJustification }) => {
  const [localVersions, setLocalVersions] = useState([]);
  const [filteredVersions, setFilteredVersions] = useState([]);

  useEffect(() => {
    if (versions) setLocalVersions(versions);
  }, [versions]);

  useEffect(() => {
    if (localVersions) {
      const newVersions = localVersions.filter((version) => {
        if (version?.changeset === null) return null;
        if (
          version?.changeset?.length === 1 &&
          version?.changeset?.filter((entry) => entry?.field === 'public_url_keys')?.length > 0
        ) {
          return null;
        }
        if (
          version?.changeset?.every(
            (entry) => entry?.field === 'public_url_keys' || (entry?.new === null && entry?.old === null),
          )
        ) {
          return null;
        }
        return version;
      });
      setFilteredVersions(newVersions);
    }
  }, [localVersions]);

  return (
    <SectionWrapper data-testid="HistorySection--Wrapper">
      <SectionHeader>History</SectionHeader>
      {filteredVersions?.length ? (
        <HistoryTable data={filteredVersions} noJustification={noJustification} />
      ) : (
        <EmptyStateDiv data-testid="HistorySection-EmptyState">
          <EmptyStateWrapper>
            <StyledImage src={EmptyStateHistory} alt="no defects" />
            <div>{`No events recorded on this ${title || 'flight'}`}</div>
          </EmptyStateWrapper>
        </EmptyStateDiv>
      )}
    </SectionWrapper>
  );
};

export default HistorySection;
