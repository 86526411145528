import React, { ChangeEvent, KeyboardEvent, forwardRef, useState } from 'react';
import styled from 'styled-components';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

interface TFTextBoxInputProps {
  value?: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  id: string;
  label?: string;
  disabled?: boolean;
  tooltip?: string;
  optional?: boolean;
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
}

const Wrapper = styled.div`
  flex: 1;
  flex-shrink: 0;
`;

const TextBoxInput = styled.textarea`
  min-height: 40px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${({ theme }): string => theme.colours.black10Alpha};
  background-color: ${({ theme }): string => theme.colours.softGray};
  padding: 8px;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.4px;
  color: ${({ theme }): string => theme.colours.black90Alpha};
  outline: none;
  &:hover {
    border-color: ${({ theme }): string => theme.colours.brandBlue40Alpha};
  }
  &:focus {
    border-color: ${({ theme }): string => theme.colours.blue};
  }
`;

const TFTextBoxInput = forwardRef<HTMLTextAreaElement, TFTextBoxInputProps>(
  ({ value, placeholder, onChange, id, label, tooltip, optional, onKeyPress, disabled }, ref) => {
    const [inputHeight, setInputHeight] = useState<number>(40);

    const adjustInputHeight = (): void => {
      if (ref && typeof ref === 'object' && 'current' in ref && ref.current) {
        setInputHeight(ref.current.scrollHeight);
      }
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      adjustInputHeight();
      if (onChange) {
        onChange(event);
      }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (onKeyPress) {
          onKeyPress(event);
        }
      }
    };

    return (
      <Wrapper data-testid="TFTextBoxInput--Wrapper">
        {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
        <TextBoxInput
          placeholder={placeholder}
          onChange={handleChange}
          data-testid={`TFTextBoxInput--${id}`}
          ref={ref}
          onKeyPress={handleKeyPress}
          disabled={disabled}
          id={id}
          name={id}
          style={{ height: inputHeight }}
          value={value}
        />
      </Wrapper>
    );
  },
);

export default TFTextBoxInput;
