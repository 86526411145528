import React from 'react';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';
import messages from '../locales/en-GB';

const cache = createIntlCache();
const locale = 'en-GB';
export const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);

const IntlProvider: React.FC = ({ children }) => {
  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default IntlProvider;
