import { Accordion, Button } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import engineIcon from '../../assets/icon-engine-default.svg';
import minusIcon from '../../assets/minus.svg';
import { DashboardState } from '../../models';
import TimeInput from '../Inputs/TimeInput/TimeInput';
import {
  ClearButtonWrapper,
  ContentWrapper,
  DisplayText,
  formatTime,
  Header,
  InnerWrapper,
  ItemWrapper,
  MinusWrapper,
  PartsDiv,
  RowHeader,
  StyledInput,
  StyledUnit,
  TitleWrapper,
  UnderInputText,
  UnitWrapper,
} from './TripUpdateAirframeSection';

interface TripUpdateEngineSectionProps {
  trip: any;
  originalTripUpdateData: any;
  updateTripUpdateData: (changes: { value: any; key: string }[]) => void;
}

const OuterWrapper = styled.div`
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  padding: ${({ isMobile }): string => (isMobile ? '16px 20px' : '16px 0 16px 56px')};
`;

const EngineWrapper = styled.div`
  border-bottom: ${({ border }): string => (border ? '1px solid rgba(36, 45, 65, 0.05)' : 'none')};
  margin-bottom: ${({ border }): string => (border ? '20px' : '0')};
  margin-top: ${({ topBorder }): string => (topBorder ? '20px' : '0')};
  padding-top: ${({ topBorder }): string => (topBorder ? '20px' : '0')};
  border-top: ${({ topBorder }): string => (topBorder ? '1px solid rgba(36, 45, 65, 0.05)' : 'none')};
  margin-right: 20px;
  @media (max-width: 451px) {
    margin-right: 0;
  }
`;

const EngineHeader = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #242d41;
`;

const TripUpdateEngineSection: React.FC<TripUpdateEngineSectionProps> = ({
  trip,
  originalTripUpdateData,
  updateTripUpdateData,
}) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const isMobile = window.innerWidth < 451;
  const { id } = useParams<{ id: string }>();

  const [engineCount, setEngineCount] = useState(0);
  const [amendedOverrideKeys, setAmendedOverrideKeys] = useState([]);

  const aircraftEngineCount = aircraftMap.get(id)?.aircraft_type?.engine_count;
  const showAPUTime = aircraftMap.get(id)?.standard_fields?.apu
    ? aircraftMap.get(id)?.standard_fields?.apu.enabled
    : true;
  const apuInstalled = aircraftMap.get(id)?.apu_installed && showAPUTime;

  const handleInputChange = (value: any, key: string): void => {
    const newValue = {
      ...trip?.utilisation,
      [`${key}`]: value,
    };
    updateTripUpdateData([{ value: newValue, key: 'utilisation' }]);
  };

  const handleCarriedInputChange = (value: any, key: string): void => {
    const newValue = {
      ...trip?.carried_forwards,
      [`${key}`]: value,
    };
    amendedOverrideKeys.push(key);
    setAmendedOverrideKeys(amendedOverrideKeys);
    updateTripUpdateData([
      { value: newValue, key: 'carried_forwards_override' },
      { value: newValue, key: 'carried_forwards' },
    ]);
  };

  const handleClearOverrideClick = (key: string): void => {
    let newValue = {
      ...trip?.carried_forwards_override,
      [`${key}`]: null,
    };
    const index = amendedOverrideKeys.indexOf(key);
    amendedOverrideKeys.splice(index, 1);
    setAmendedOverrideKeys(amendedOverrideKeys);
    if (
      Object.keys(originalTripUpdateData?.carried_forwards_override).length === 0 &&
      amendedOverrideKeys.length === 0
    ) {
      newValue = {};
    }
    const newCarriedValue = {
      ...trip?.carried_forwards,
      [`${key}`]: originalTripUpdateData?.carried_forwards[`${key}`],
    };
    updateTripUpdateData([
      { value: newValue, key: 'carried_forwards_override' },
      { value: newCarriedValue, key: 'carried_forwards' },
    ]);
  };

  useEffect(() => {
    if (aircraftEngineCount) {
      setEngineCount(aircraftEngineCount);
    }
  }, [aircraftEngineCount]);

  const buildProps = (): ReactElement[] => {
    const propArray = [];
    const propCount =
      trip?.utilisation && Object.keys(trip?.utilisation)?.filter((item) => item.startsWith('prop')).length;
    if (propCount > 0) {
      for (let i = 1; i <= propCount; i += 1) {
        let displayHours = trip?.carried_forwards[`prop_${i}_seconds`];
        let calculatedHours = trip?.carried_forwards[`prop_${i}_seconds`];

        if (originalTripUpdateData?.utilisation[`prop_${i}_seconds`] !== trip?.utilisation[`prop_${i}_seconds`])
          calculatedHours =
            trip?.utilisation[`prop_${i}_seconds`] -
            originalTripUpdateData?.utilisation[`prop_${i}_seconds`] +
            trip?.carried_forwards[`prop_${i}_seconds`];
        displayHours = calculatedHours;

        if (trip?.carried_forwards_override[`prop_${i}_seconds`])
          displayHours = trip?.carried_forwards_override[`prop_${i}_seconds`];

        const propSection = (
          <EngineWrapper key={`prop${i}`} border={i !== engineCount || apuInstalled} topBorder={i === 1}>
            <EngineHeader>{`Prop ${i}`}</EngineHeader>
            <ContentWrapper
              noBorder
              padding={i === engineCount && !apuInstalled ? '12px 0 0 0 ' : '12px 0 20px 0'}
              isMobile={isMobile}
            >
              <InnerWrapper>
                <ItemWrapper>
                  <Header editable={mode !== 'view'}>Flight hours</Header>
                  <UnitWrapper flexStart={mode === 'edit'}>
                    {mode === 'view' || mode === 'override' ? (
                      <DisplayText>{formatTime(trip?.utilisation[`prop_${i}_seconds`])}</DisplayText>
                    ) : (
                      <div>
                        <TimeInput
                          input={trip?.utilisation[`prop_${i}_seconds`] || undefined}
                          onChange={(value): void => handleInputChange(value, `prop_${i}_seconds`)}
                          tripUpdate
                        />
                        <UnderInputText>
                          {`Initial: ${formatTime(originalTripUpdateData?.utilisation[`prop_${i}_seconds`])} hrs`}
                        </UnderInputText>
                      </div>
                    )}
                    <StyledUnit edit={mode === 'edit' || mode === 'add'}>hrs</StyledUnit>
                  </UnitWrapper>
                </ItemWrapper>
                <div>
                  <Header editable={mode === 'override'}>Carried Forward</Header>
                  <UnitWrapper flexStart={mode === 'override'}>
                    {mode !== 'override' ? (
                      <DisplayText>{formatTime(displayHours)}</DisplayText>
                    ) : (
                      <div>
                        <TimeInput
                          input={displayHours || undefined}
                          onChange={(value): void => handleCarriedInputChange(value, `prop_${i}_seconds`)}
                          tripUpdate
                        />
                        <UnderInputText>{`Calculated: ${formatTime(calculatedHours)} hrs`}</UnderInputText>
                      </div>
                    )}
                    <StyledUnit edit={mode === 'override'}>hrs</StyledUnit>
                  </UnitWrapper>
                </div>
              </InnerWrapper>
              {mode === 'override' ? (
                <>
                  {amendedOverrideKeys.includes(`prop_${i}_seconds`) ? (
                    <ClearButtonWrapper>
                      <Button
                        height="24px"
                        primary={false}
                        onClick={(): void => handleClearOverrideClick(`prop_${i}_seconds`)}
                      >
                        <MinusWrapper>
                          <img src={minusIcon} alt="minus" /> Clear override
                        </MinusWrapper>
                      </Button>
                    </ClearButtonWrapper>
                  ) : (
                    <div />
                  )}
                </>
              ) : null}
            </ContentWrapper>
          </EngineWrapper>
        );
        propArray.push(propSection);
      }
    }
    return propArray;
  };

  const buildEngines = (): ReactElement[] => {
    const engineArray = [];
    for (let i = 1; i <= engineCount; i += 1) {
      let displayHours = trip?.carried_forwards[`engine_${i}_seconds`];
      let calculatedHours = trip?.carried_forwards[`engine_${i}_seconds`];

      if (originalTripUpdateData?.utilisation[`engine_${i}_seconds`] !== trip?.utilisation[`engine_${i}_seconds`])
        calculatedHours =
          trip?.utilisation[`engine_${i}_seconds`] -
          originalTripUpdateData?.utilisation[`engine_${i}_seconds`] +
          trip?.carried_forwards[`engine_${i}_seconds`];
      displayHours = calculatedHours;

      if (trip?.carried_forwards_override[`engine_${i}_seconds`])
        displayHours = trip?.carried_forwards_override[`engine_${i}_seconds`];

      let displayCycles = trip?.carried_forwards[`engine_${i}_cycles`];
      let calculatedCycles = trip?.carried_forwards[`engine_${i}_cycles`];

      if (originalTripUpdateData?.utilisation[`engine_${i}_cycles`] !== trip?.utilisation[`engine_${i}_cycles`])
        calculatedCycles =
          trip?.utilisation[`engine_${i}_cycles`] -
          originalTripUpdateData?.utilisation[`engine_${i}_cycles`] +
          trip?.carried_forwards[`engine_${i}_cycles`];
      displayCycles = calculatedCycles;

      if (trip?.carried_forwards_override[`engine_${i}_cycles`])
        displayCycles = trip?.carried_forwards_override[`engine_${i}_cycles`];

      const engineSection = (
        <EngineWrapper key={`engine${i}`} border={i !== engineCount || apuInstalled}>
          <EngineHeader>{`Engine ${i}`}</EngineHeader>
          <ContentWrapper
            noBorder
            padding={i === engineCount && !apuInstalled ? '12px 0 0 0 ' : '12px 0 20px 0'}
            isMobile={isMobile}
          >
            <InnerWrapper>
              <ItemWrapper>
                <Header editable={mode !== 'view'}>Flight hours</Header>
                <UnitWrapper flexStart={mode === 'edit'}>
                  {mode === 'view' || mode === 'override' ? (
                    <DisplayText>{formatTime(trip?.utilisation[`engine_${i}_seconds`])}</DisplayText>
                  ) : (
                    <div>
                      <TimeInput
                        input={trip?.utilisation[`engine_${i}_seconds`] || undefined}
                        onChange={(value): void => handleInputChange(value, `engine_${i}_seconds`)}
                        tripUpdate
                      />
                      <UnderInputText>
                        {`Initial: ${formatTime(originalTripUpdateData?.utilisation[`engine_${i}_seconds`])} hrs`}
                      </UnderInputText>
                    </div>
                  )}
                  <StyledUnit edit={mode === 'edit' || mode === 'add'}>hrs</StyledUnit>
                </UnitWrapper>
              </ItemWrapper>
              <div>
                <Header editable={mode === 'override'}>Carried Forward</Header>
                <UnitWrapper flexStart={mode === 'override'}>
                  {mode !== 'override' ? (
                    <DisplayText>{formatTime(displayHours)}</DisplayText>
                  ) : (
                    <div>
                      <TimeInput
                        input={displayHours || undefined}
                        onChange={(value): void => handleCarriedInputChange(value, `engine_${i}_seconds`)}
                        tripUpdate
                      />
                      <UnderInputText>{`Calculated: ${formatTime(calculatedHours)} hrs`}</UnderInputText>
                    </div>
                  )}
                  <StyledUnit edit={mode === 'override'}>hrs</StyledUnit>
                </UnitWrapper>
              </div>
            </InnerWrapper>
            {mode === 'override' ? (
              <>
                {amendedOverrideKeys.includes(`engine_${i}_seconds`) ? (
                  <ClearButtonWrapper>
                    <Button
                      height="24px"
                      primary={false}
                      onClick={(): void => handleClearOverrideClick(`engine_${i}_seconds`)}
                    >
                      <MinusWrapper>
                        <img src={minusIcon} alt="minus" /> Clear override
                      </MinusWrapper>
                    </Button>
                  </ClearButtonWrapper>
                ) : (
                  <div />
                )}
              </>
            ) : null}
            <InnerWrapper overridePadding={trip?.carried_forwards_override[`engine_${i}_cycles`]}>
              <ItemWrapper>
                <Header editable={mode !== 'view'}>Cycles</Header>
                {mode === 'view' || mode === 'override' ? (
                  <DisplayText>{trip?.utilisation[`engine_${i}_cycles`] || '-'}</DisplayText>
                ) : (
                  <div>
                    <StyledInput
                      placeholder="-"
                      value={trip?.utilisation[`engine_${i}_cycles`] || undefined}
                      onChange={(e): void => handleInputChange(parseInt(e.target.value, 10), `engine_${i}_cycles`)}
                      type="number"
                    />
                    <UnderInputText>
                      {`Initial: ${originalTripUpdateData?.utilisation[`engine_${i}_cycles`] || 0}`}
                    </UnderInputText>
                  </div>
                )}
              </ItemWrapper>
              <div>
                <Header editable={mode === 'override'}>Carried Forward</Header>
                {mode !== 'override' ? (
                  <DisplayText>{displayCycles || '-'}</DisplayText>
                ) : (
                  <div>
                    <StyledInput
                      placeholder="-"
                      value={displayCycles || undefined}
                      onChange={(e): void =>
                        handleCarriedInputChange(parseInt(e.target.value, 10), `engine_${i}_cycles`)
                      }
                      type="number"
                    />
                    <UnderInputText>{`Calculated: ${calculatedCycles || 0}`}</UnderInputText>
                  </div>
                )}
              </div>
            </InnerWrapper>
            {mode === 'override' ? (
              <>
                {amendedOverrideKeys.includes(`engine_${i}_cycles`) ? (
                  <ClearButtonWrapper>
                    <Button
                      height="24px"
                      primary={false}
                      onClick={(): void => handleClearOverrideClick(`engine_${i}_cycles`)}
                    >
                      <MinusWrapper>
                        <img src={minusIcon} alt="minus" /> Clear override
                      </MinusWrapper>
                    </Button>
                  </ClearButtonWrapper>
                ) : (
                  <div />
                )}
              </>
            ) : null}
          </ContentWrapper>
        </EngineWrapper>
      );
      engineArray.push(engineSection);
    }

    if (apuInstalled) {
      let apuDisplayHours = trip?.carried_forwards?.apu_seconds;
      let apuCalculatedHours = trip?.carried_forwards?.apu_seconds;

      if (originalTripUpdateData?.utilisation?.apu_seconds !== trip?.utilisation?.apu_seconds)
        apuCalculatedHours =
          trip?.utilisation?.apu_seconds -
          originalTripUpdateData?.utilisation?.apu_seconds +
          trip?.carried_forwards?.apu_seconds;
      apuDisplayHours = apuCalculatedHours;

      if (trip?.carried_forwards_override?.apu_seconds) apuDisplayHours = trip?.carried_forwards_override?.apu_seconds;

      let apuDisplayCycles = trip?.carried_forwards?.apu_cycles;
      let apuCalculatedCycles = trip?.carried_forwards?.apu_cycles;

      if (originalTripUpdateData?.utilisation?.apu_cycles !== trip?.utilisation?.apu_cycles)
        apuCalculatedCycles =
          trip?.utilisation?.apu_cycles -
          originalTripUpdateData?.utilisation?.apu_cycles +
          trip?.carried_forwards?.apu_cycles;
      apuDisplayCycles = apuCalculatedCycles;

      if (trip?.carried_forwards_override?.apu_cycles) apuDisplayCycles = trip?.carried_forwards_override?.apu_cycles;

      const apuSection = (
        <EngineWrapper>
          <EngineHeader>APU</EngineHeader>
          <ContentWrapper noBorder padding="12px 0 0 0 " isMobile={isMobile}>
            <InnerWrapper>
              <ItemWrapper>
                <Header editable={mode !== 'view'}>Flight hours</Header>
                <UnitWrapper flexStart={mode === 'edit'}>
                  {mode === 'view' || mode === 'override' ? (
                    <DisplayText>{formatTime(trip?.utilisation?.apu_seconds)}</DisplayText>
                  ) : (
                    <div>
                      <TimeInput
                        input={trip?.utilisation?.apu_seconds || undefined}
                        onChange={(value): void => handleInputChange(value, `apu_seconds`)}
                        tripUpdate
                      />
                      <UnderInputText>
                        {`Initial: ${formatTime(originalTripUpdateData?.utilisation?.apu_seconds)} hrs`}
                      </UnderInputText>
                    </div>
                  )}
                  <StyledUnit edit={mode === 'edit' || mode === 'add'}>hrs</StyledUnit>
                </UnitWrapper>
              </ItemWrapper>
              <div>
                <Header editable={mode === 'override'}>Carried Forward</Header>
                <UnitWrapper flexStart={mode === 'override'}>
                  {mode !== 'override' ? (
                    <DisplayText>{formatTime(apuDisplayHours)}</DisplayText>
                  ) : (
                    <div>
                      <TimeInput
                        input={apuDisplayHours || undefined}
                        onChange={(value): void => handleCarriedInputChange(value, `apu_seconds`)}
                        tripUpdate
                      />
                      <UnderInputText>{`Calculated: ${formatTime(apuCalculatedHours)} hrs`}</UnderInputText>
                    </div>
                  )}
                  <StyledUnit edit={mode === 'override'}>hrs</StyledUnit>
                </UnitWrapper>
              </div>
            </InnerWrapper>
            {mode === 'override' ? (
              <>
                {amendedOverrideKeys.includes(`apu_seconds`) ? (
                  <ClearButtonWrapper>
                    <Button height="24px" primary={false} onClick={(): void => handleClearOverrideClick(`apu_seconds`)}>
                      <MinusWrapper>
                        <img src={minusIcon} alt="minus" /> Clear override
                      </MinusWrapper>
                    </Button>
                  </ClearButtonWrapper>
                ) : (
                  <div />
                )}
              </>
            ) : null}
            <InnerWrapper overridePadding={trip?.carried_forwards_override.apu_cycles}>
              <ItemWrapper>
                <Header editable={mode !== 'view'}>Cycles</Header>
                {mode === 'view' || mode === 'override' ? (
                  <DisplayText>{trip?.utilisation.apu_cycles || '-'}</DisplayText>
                ) : (
                  <div>
                    <StyledInput
                      placeholder="-"
                      value={trip?.utilisation.apu_cycles || undefined}
                      onChange={(e): void => handleInputChange(parseInt(e.target.value, 10), `apu_cycles`)}
                      type="number"
                    />
                    <UnderInputText>{`Initial: ${originalTripUpdateData?.utilisation.apu_cycles || 0}`}</UnderInputText>
                  </div>
                )}
              </ItemWrapper>
              <div>
                <Header editable={mode === 'override'}>Carried Forward</Header>
                {mode !== 'override' ? (
                  <DisplayText>{apuDisplayCycles || '-'}</DisplayText>
                ) : (
                  <div>
                    <StyledInput
                      placeholder="-"
                      value={apuDisplayCycles || undefined}
                      onChange={(e): void => handleCarriedInputChange(parseInt(e.target.value, 10), `apu_cycles`)}
                      type="number"
                    />
                    <UnderInputText>{`Calculated: ${apuCalculatedCycles || 0}`}</UnderInputText>
                  </div>
                )}
              </div>
            </InnerWrapper>
            {mode === 'override' ? (
              <>
                {amendedOverrideKeys.includes(`apu_cycles`) ? (
                  <ClearButtonWrapper>
                    <Button height="24px" primary={false} onClick={(): void => handleClearOverrideClick(`apu_cycles`)}>
                      <MinusWrapper>
                        <img src={minusIcon} alt="minus" /> Clear override
                      </MinusWrapper>
                    </Button>
                  </ClearButtonWrapper>
                ) : (
                  <div />
                )}
              </>
            ) : null}
          </ContentWrapper>
        </EngineWrapper>
      );
      engineArray.push(apuSection);
    }
    return engineArray;
  };

  return (
    <Accordion
      isExpanded={mode === 'add'}
      headerContent={
        <RowHeader>
          <TitleWrapper>
            <img src={engineIcon} alt="airframe icon" />
            Engines
          </TitleWrapper>
          <PartsDiv>{engineCount * 4} parts</PartsDiv>
        </RowHeader>
      }
      dash
    >
      <OuterWrapper isMobile={isMobile}>
        {buildEngines()}
        {buildProps()}
      </OuterWrapper>
    </Accordion>
  );
};

export default TripUpdateEngineSection;
