import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';

interface ScrollToTopProps {
  element: () => Element;
}

const ScrollToTop: FC<ScrollToTopProps> = ({ element }) => {
  useEffect(() => {
    const el = element();
    el.scrollTop = 0;
  }, [element]);

  return null;
};

ScrollToTop.propTypes = {
  element: PropTypes.func.isRequired,
};

export default ScrollToTop;
