import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Carousel } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import { FeatureFlag } from '../../models/userSettings';
import errorIcon from '../../assets/login-error.svg';
import errorIconAmber from '../../assets/login-error-amber.svg';
import styles from './AircraftWidgets.module.less';

const AircraftWidgets = ({ aircraft }) => {
  const {
    userSettings: { dateFormat },
  } = useSelector((state) => state);

  const [calendarHoursUnit, setCalendarHoursUnit] = useState('days');

  const { apu_hours, flight_hours, cycles, days, flights, calendar_hours, flight_days, other } = aircraft.widgets;

  const featureFlags = aircraft.feature_flags?.map((flag) => flag.feature_name);
  let displayCyclesWidget = true;

  if (!featureFlags?.includes(FeatureFlag.CYCLESWIDGET) && (cycles?.remaining === null || cycles?.remaining > 100)) {
    displayCyclesWidget = false;
  }

  const flightsNextDue = flights?.next_due !== null ? flights?.next_due : null;
  const flightsRemaining = flights?.remaining !== null ? flights?.remaining : null;
  const flightDaysRemaining = flight_days?.remaining !== null ? flight_days?.remaining : null;
  const calendarHoursNextDue = calendar_hours?.next_due
    ? moment(calendar_hours?.next_due).utc().format(`${dateFormat} HH:mm`)
    : null;
  let calendarRemaining;
  if (calendar_hours?.next_due) {
    const overdue = moment(calendar_hours?.next_due).isBefore();
    calendarRemaining = overdue ? 'Overdue' : moment(calendar_hours?.next_due).diff(moment(), 'days');
    if (calendarRemaining === 1 && calendarHoursUnit !== 'day') setCalendarHoursUnit('day');
    if (calendarRemaining === 0) {
      calendarRemaining = moment(calendar_hours?.next_due).diff(moment(), 'hours');
      if (calendarHoursUnit !== 'hours') setCalendarHoursUnit('hours');
      if (calendarRemaining === 1 && calendarHoursUnit !== 'hour') setCalendarHoursUnit('hour');
    }
  }

  const otherText = other?.filter((item) => item !== '')?.join('; ');

  const displayFlightsLimitRemaining = flights?.remaining || 'Overdue';
  const displayFlightDays = flightDaysRemaining > 0 ? `${flightDaysRemaining} remaining` : 'Overdue';

  let warningLineStatus = 'amber';
  if (flightsRemaining && flightsRemaining <= 0) warningLineStatus = 'red';
  if (flightDaysRemaining && flightDaysRemaining <= 0) warningLineStatus = 'red';
  if (calendar_hours?.next_due && moment(calendar_hours?.next_due).isBefore()) warningLineStatus = 'red';

  const {
    apu_seconds: apu_threshold,
    flight_seconds: hours_threshold,
    days: days_threshold,
    cycles: cycles_threshold,
  } = aircraft.mx_thresholds;
  const widgetArray = [
    {
      title: 'Days',
      nextDue: days.next_due !== null ? days.next_due : null,
      remaining: days.remaining,
      tolerance: days.tolerance,
      threshold: days_threshold || 30,
      itemId: days.item_id,
      isDefect: days.is_defect,
      unitOfTime: days.unit_of_time,
      status: days.status,
    },
    {
      title: 'Hours',
      total: flight_hours.total,
      nextDue: flight_hours.next_due !== null ? flight_hours.next_due : null,
      remaining: flight_hours.remaining,
      tolerance: flight_hours.tolerance,
      threshold: hours_threshold / 3600 || 30,
      itemId: flight_hours.item_id,
      isDefect: flight_hours.is_defect,
    },
  ];
  if (displayCyclesWidget) {
    widgetArray.unshift({
      title: 'Cycles',
      total: cycles.total,
      nextDue: cycles.next_due !== null ? cycles.next_due : null,
      remaining: cycles.remaining,
      tolerance: cycles.tolerance,
      threshold: cycles_threshold || 30,
      itemId: cycles.item_id,
      isDefect: cycles.is_defect,
    });
  }
  if (apu_hours.installed) {
    widgetArray.push({
      title: 'APU Hours',
      total: apu_hours.total,
      nextDue: apu_hours.next_due !== null ? apu_hours.next_due : null,
      remaining: apu_hours.remaining,
      tolerance: apu_hours.tolerance,
      threshold: apu_threshold / 3600 || 30,
      itemId: apu_hours.item_id,
      isDefect: apu_hours.is_defect,
    });
  }

  return (
    <>
      <Row className={styles.widgetCardWrapper} data-testid="AircraftWidgets--Widgets">
        <div className={widgetArray.length === 2 ? styles.widgetWrapperCenter : styles.widgetWrapper}>
          {widgetArray.map((widget) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              className={widgetArray.length === 2 ? styles.progressCirclePadding : styles.progressCircleWrapper}
              key={widget.title}
            >
              <ProgressCircle widget={widget} widgetNumber={widgetArray.length} aircraftId={aircraft.id} />
            </Col>
          ))}
        </div>
        {flightsNextDue || calendarHoursNextDue || flightDaysRemaining !== null || otherText ? (
          <div className={styles.warningLine}>
            <img
              src={warningLineStatus === 'red' ? errorIcon : errorIconAmber}
              alt="error icon"
              className={styles.warningIcon}
            />
            <span className={styles.warningText}>
              <span data-testid="AircraftWidgets--WarningText">
                {flightsNextDue
                  ? `Flights Limit: ${flightsNextDue} (${displayFlightsLimitRemaining}${
                      displayFlightsLimitRemaining === 'Overdue' ? '' : ' remaining'
                    }); `
                  : null}
                {calendarHoursNextDue
                  ? `Calendar Hours limit: ${calendarHoursNextDue}z (${
                      calendarRemaining === 'Overdue' ? 'Overdue' : `in ${calendarRemaining} ${calendarHoursUnit}`
                    }); `
                  : null}
                {flightDaysRemaining !== null ? `Flight Days: ${displayFlightDays}; ` : null}
                <span className={styles.otherTextSpan}>{otherText}</span>
              </span>
            </span>
          </div>
        ) : null}
      </Row>
      <div className={styles.widgetCarousel}>
        <Carousel>
          <div className={styles.widgetCarouselItem}>
            {widgetArray.slice(0, 2).map((widget) => (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} className={styles.progressCircleWrapper} key={widget.title}>
                <ProgressCircle widget={widget} widgetNumber={widgetArray.length} aircraftId={aircraft.id} />
              </Col>
            ))}
          </div>
          <div className={styles.widgetCarouselItem}>
            {widgetArray.slice(2, 4).map((widget) => (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} className={styles.progressCircleWrapper} key={widget.title}>
                <ProgressCircle widget={widget} widgetNumber={widgetArray.length} aircraftId={aircraft.id} />
              </Col>
            ))}
          </div>
        </Carousel>
      </div>
      {flightsNextDue || calendarHoursNextDue || flightDaysRemaining || otherText ? (
        <div className={styles.warningLineMobile}>
          <img
            src={aircraft?.maintenance_status === 'unserviceable' ? errorIcon : errorIconAmber}
            alt="error icon"
            className={styles.warningIcon}
          />
          <span className={styles.warningText}>
            <span>
              {flightsNextDue ? `Flights Limit: ${flightsNextDue} (${flightsRemaining} remaining); ` : null}
              {calendarHoursNextDue
                ? `Calendar Hours limit: ${calendarHoursNextDue}z (${
                    calendarRemaining === 'Overdue' ? 'Overdue' : `in ${calendarRemaining} ${calendarHoursUnit}`
                  }); `
                : null}
              {flightDaysRemaining ? `Flight Days: ${flightDaysRemaining} remaining; ` : null}
              <span className={styles.otherTextSpan}>{otherText}</span>
            </span>
          </span>
        </div>
      ) : null}
    </>
  );
};

AircraftWidgets.propTypes = {
  aircraft: PropTypes.object.isRequired,
};

export default AircraftWidgets;
