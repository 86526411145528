import React, { useEffect, useState } from 'react';
import chevron from '../../assets/right-chevron-grey.svg';
import styles from './TFPagination.module.less';

const TFPaginator = ({ passedArray = [], activeIndex = 0, handleIndexChange }): JSX.Element => {
  const [array, setArray] = useState([]);
  const [index, setIndex] = useState(0);

  const disableLeft = index === 0;
  const disableRight = index + 1 === array.length;

  const handleLeftClick = (): void => {
    if (index !== 0) {
      setIndex(index - 1);
      handleIndexChange(index - 1);
    }
  };

  const handleRightClick = (): void => {
    if (index + 1 !== array.length) {
      setIndex(index + 1);
      handleIndexChange(index + 1);
    }
  };

  useEffect(() => {
    if (passedArray) setArray(passedArray);
    if (activeIndex) setIndex(activeIndex);
  }, [passedArray, activeIndex]);

  return (
    <div className={styles.paginationWrapper}>
      <button type="button" onClick={handleLeftClick} className={disableLeft ? styles.opacity : styles.normal}>
        <div className={styles.chevronWrapper}>
          <img src={chevron} alt="chevron" className={styles.chevronLeft} />
        </div>
      </button>
      <span className={styles.pageNumber}>
        {index + 1} of
        {array.length}
      </span>
      <button type="button" onClick={handleRightClick} className={disableRight ? styles.opacity : styles.normal}>
        <div className={styles.chevronWrapper}>
          <img src={chevron} alt="chevron" className={styles.chevron} />
        </div>
      </button>
    </div>
  );
};

export default TFPaginator;
