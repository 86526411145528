import React, { ReactElement, useState, useEffect } from 'react';
import { Column, useTable, useSortBy, usePagination } from 'react-table';
import { Table } from '@arcflight/tf-component-library';

const TableWrappedWithInstance = ({
  columns,
  data,
  dataTestId,
  onPaginationChange,
  page,
  limit,
  resetToOne,
}: {
  columns: Column<any>[];
  data: any;
  dataTestId: string;
  onPaginationChange: (currentPage: number, numberOfItems: number) => void;
  page: number;
  limit: number;
  resetToOne: boolean;
}): ReactElement => {
  const total = data.length;
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    const paged = data.slice((page - 1) * limit, page * limit);
    setPaginatedData(paged);
  }, [page, limit, data]);

  // const onPaginationChange = (currentPage, numberOfItems) => {
  //   if (numberOfItems !== limit) {
  //     setPage(1);
  //     setLimit(numberOfItems);
  //   } else if (currentPage !== page) {
  //     if (currentPage === 0) {
  //       // Handle if user types 0 into input box
  //       setPage(currentPage + 1);
  //     } else {
  //       setPage(currentPage);
  //     }
  //   }
  // };

  const tableInstance = useTable<any>(
    {
      columns,
      data: paginatedData,
      initialState: { pageIndex: page - 1 },
      manualPagination: true,
      pageCount: Math.ceil(total / (limit || 10)),
    },
    useSortBy,
    usePagination,
  );
  return (
    <>
      <Table<any>
        handlePaginationChange={(currentPage, numberOfItems) => onPaginationChange(currentPage, numberOfItems)}
        data-testid={dataTestId}
        tableInstance={tableInstance}
        total={total}
        passedPageSize={limit}
        resetToOne={resetToOne}
      />
    </>
  );
};

export default TableWrappedWithInstance;
