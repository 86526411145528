import { Button } from '@arcflight/tf-component-library';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DashboardState } from '../../../models';
import defectIcon from '../../../assets/icon-card-defects.svg';
import plusIcon from '../../../assets/plus-blue.svg';
import minusIcon from '../../../assets/icon-minus-blue.svg';
import Card from './Card';

interface AddDeferralTileProps {
  defectDeferred: boolean;
  setDefectDeferred: (value: boolean) => void;
  updateDefectData: (changes: any[]) => void;
  setDefectType?: (value: string) => void;
}

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardTitle = styled.span`
  margin-left: 8px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
    margin-left: ${({ iconWidth }): string => (iconWidth === 16 ? '-2px' : '')};
    width: ${({ iconWidth }): string => `${iconWidth}px`};
  }
`;

const AddDeferralTile: React.FC<AddDeferralTileProps> = ({
  defectDeferred,
  setDefectDeferred,
  children,
  updateDefectData,
  setDefectType,
}) => {
  const {
    userSettings: {
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const defectNameOverride = operators.find((op) => op?.id === aircraftMap.get(id)?.operator_id)?.operator_setting
    ?.defect_name;
  return (
    <Card padding="20px 20">
      <InnerWrapper>
        <div>
          <img src={defectIcon} alt="defect icon" />
          <CardTitle>{`${defectNameOverride} Deferral`}</CardTitle>
        </div>
        {!defectDeferred ? (
          <Button
            primary={false}
            onClick={(): void => {
              setDefectDeferred(true);
              updateDefectData([{ value: true, key: 'deferred' }]);
            }}
            height="24px"
            padding="0 12px"
          >
            <ButtonContent>
              <img src={plusIcon} alt="plus icon" />
              Add deferral
            </ButtonContent>
          </Button>
        ) : (
          <Button
            primary={false}
            onClick={(): void => {
              setDefectDeferred(false);
              setDefectType(null);
              updateDefectData([
                { value: false, key: 'deferred' },
                { value: null, key: 'defect_type' },
                { value: null, key: 'mel_item' },
                { value: null, key: 'mel_rectification_id' },
              ]);
            }}
            height="24px"
            padding="0 12px"
          >
            <ButtonContent iconWidth={16}>
              <img src={minusIcon} alt="plus icon" />
              Remove deferral
            </ButtonContent>
          </Button>
        )}
      </InnerWrapper>
      {children}
    </Card>
  );
};

export default AddDeferralTile;
