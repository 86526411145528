import React, { ReactNode, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Modal, Table } from '@arcflight/tf-component-library';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { deleteAircraftNote, getAircraftNotes } from '../../models/notes/actions';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { AircraftResource } from '../../models/aircraft';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import NotesDrawer from './NotesDrawer';

interface NotesTableProps {
  data: any;
  total: number;
  pageSize: number;
  pageIndex: number;
  onPaginationChange: (currentPage, numberOfItems) => void;
  onSortChange: (value) => void;
}

const ModalWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: #242d41;
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 16px;
`;

const NotesTable: React.FC<NotesTableProps> = ({
  data,
  total,
  pageIndex,
  pageSize,
  onPaginationChange,
  onSortChange,
}) => {
  const {
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modalId, setModalId] = useState(null);

  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onRowClick = (noteId): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(setDrawerId({ payload: noteId }));
    dispatch(changeDrawerContent({ payload: { content: <NotesDrawer /> } }));
  };

  const handleEditNote = (noteId): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'edit' }));
    dispatch(setDrawerId({ payload: noteId }));
    dispatch(changeDrawerContent({ payload: { content: <NotesDrawer /> } }));
  };

  const handleDeleteNote = (): void => {
    dispatch(deleteAircraftNote({ id: modalId }));
    const getPayload = {
      aircraft_id: id,
      page: 1,
      limit: 10,
      sort_by: [],
    };
    dispatch(getAircraftNotes({ payload: getPayload }));
    setModalId(null);
    setDeleteModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Cell: ({ value }): string => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortable: true,
        Header: 'Title',
        accessor: 'title',
      },
      {
        Cell: ({ value }): string => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortable: true,
        Header: 'Content',
        accessor: 'content',
        width: 400,
      },
      {
        Cell: ({ value }): string => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortable: true,
        Header: 'Reported By',
        accessor: 'created_by_name',
        width: 160,
      },
      {
        Header: 'Date added',
        accessor: 'created_at',
        sortable: true,
        Cell: ({ value }): string => {
          if (value) {
            return moment(value).format(dateFormat);
          }
          return '-';
        },
        width: 80,
      },
      {
        Header: '',
        id: '3dot',
        width: 20,
        Cell: ({ row }): ReactNode => {
          return (
            <>
              <AuthDropdownMenu
                handleDelete={(): void => {
                  setModalId(row.original.id);
                  setDeleteModalVisible(true);
                }}
                resource={AircraftResource.AIRCRAFT}
                editCallback={(): void => handleEditNote(row.original.id)}
                aircraftId={id}
                options={{ read: false, update: true, delete: true }}
                forTable
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );
  return (
    <div>
      <Modal isOpen={deleteModalVisible} width={420} handleClose={(): void => setDeleteModalVisible(false)}>
        <ModalWrapper>
          <ModalTitle>{formatMessage({ id: 'title.deleteItem' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureDeleteNote',
            })} ${formatMessage({
              id: 'form.labels.cannotBeUndone',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubmitButtonWrapper>
              <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={(): void => handleDeleteNote()}>
                Delete
              </Button>
            </SubmitButtonWrapper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setDeleteModalVisible(false)}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>
      <Table
        tableInstance={tableInstance}
        total={total}
        handlePaginationChange={(currentPage, numberOfItems): void => onPaginationChange(currentPage, numberOfItems)}
        passedPageSize={pageSize}
        handleSortChange={(value): void => onSortChange(value)}
        handleRowClick={(row): void => onRowClick(row.original.id)}
      />
    </div>
  );
};

export default NotesTable;
