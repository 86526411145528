import { useMutation } from '@tanstack/react-query';
import { deleteFRATCategory } from '../../services/api';

const useDeleteFRATCategory = ({ handleDeleteCategoryOnSuccess, handleDeleteCategoryOnError }) => {
  const deleteCategory = useMutation({
    mutationFn: (payload: { id: string }) => {
      return deleteFRATCategory(payload);
    },
    onSuccess: (data) => {
      handleDeleteCategoryOnSuccess(data);
    },
    onError: (err: any) => handleDeleteCategoryOnError(err),
  });

  return deleteCategory;
};

export default useDeleteFRATCategory;
