import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 20px 200px;
  background-color: ${({ theme }): string => theme.colours.white};
  border-top: ${({ theme }): string => `1px ${theme.colours.borderGrey} solid`};
  box-shadow: ${({ theme }): string => `-10px 0 15px ${theme.colours.blackExtraLight}`};
`;

const PageFooterActions: React.FC = ({ children }) => {
  return <Wrapper data-testid="PageFooterActions--Wrapper">{children}</Wrapper>;
};

export default PageFooterActions;
