/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAirports as getAirportsAPI } from '../../services/apiNew';
import { AirportsActionTypes } from '.';

// eslint-disable-next-line import/prefer-default-export
export const getAirports =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getAirportsAPI(payload);
    dispatch({ type: AirportsActionTypes.SAVE_ALL, payload: response });
  };
