import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import getOperatorSetting from '../../../utils/getOperatorSetting';

interface ReportedByTypeProps {
  defect: Defect;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  aircraftId: string;
}

const ComponentWrapper = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.div`
  font-size: ${({ edit }): string => (edit ? '14px' : '12px')};
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(35, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '400' : 'unset')};
  margin-bottom: 4px;
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

const Wrapper = styled.div`
  display: flex;
`;

const DisplaySpan = styled.span`
  text-transform: capitalize;
`;

const ReportedByType: React.FC<ReportedByTypeProps> = ({ defect, updateDefectData, aircraftId }) => {
  const [selectedType, setSelectedType] = useState(null);
  const { id } = useParams<{ id: string }>();

  const {
    userSettings: {
      details: { people },
    },
    aircraft: { aircraftMap },
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const { formatMessage } = useIntl();

  const aircraftOperatorId = id ? aircraftMap.get(id).operator_id : aircraftMap.get(aircraftId)?.operator_id;

  const currentUser = people.find((person) => person?.organisation?.id === aircraftOperatorId);

  const hideReportByCabin = getOperatorSetting('hide_report_by_cabin_on_defects', id);

  const handleClick = (value): void => {
    updateDefectData([{ value, key: 'reported_by_type' }]);
    setSelectedType(value);
  };

  useEffect(() => {
    if (defect?.reported_by_type) setSelectedType(defect?.reported_by_type);
  }, [defect]);

  useEffect(() => {
    if (currentUser?.position && mode === 'add' && !defect?.reported_by_type) {
      if (
        currentUser?.position === 'Pilot' ||
        currentUser?.position === 'ReliefPilot' ||
        currentUser?.position === 'Admin'
      ) {
        updateDefectData([{ value: 'pilot', key: 'reported_by_type' }]);
        setSelectedType('pilot');
      }
      if (currentUser?.position === 'Crew' || currentUser?.position === 'FlightAttendant') {
        updateDefectData([{ value: 'cabin', key: 'reported_by_type' }]);
        setSelectedType('cabin');
      }
      if (
        currentUser?.position === 'Camo' ||
        currentUser?.position === 'Engineer' ||
        currentUser?.position === 'OpsController'
      ) {
        updateDefectData([{ value: 'maintenance', key: 'reported_by_type' }]);
        setSelectedType('maintenance');
      }
    }
  }, [currentUser, defect, mode, updateDefectData]);

  return (
    <ComponentWrapper>
      <Header edit={mode !== 'view'}>{formatMessage({ id: 'text.reportedBy' })}</Header>
      {mode === 'view' ? (
        <DisplaySpan>{defect?.reported_by_type}</DisplaySpan>
      ) : (
        <Wrapper>
          <RadioInputButton
            text="Pilot"
            active={selectedType === 'pilot'}
            marginRight="8px"
            onClick={(): void => handleClick('pilot')}
          />
          <RadioInputButton
            text="Maintenance"
            active={selectedType === 'maintenance'}
            marginRight="8px"
            onClick={(): void => handleClick('maintenance')}
          />
          {hideReportByCabin ? null : (
            <RadioInputButton
              text="Cabin"
              active={selectedType === 'cabin'}
              marginRight="8px"
              onClick={(): void => handleClick('cabin')}
            />
          )}
        </Wrapper>
      )}
    </ComponentWrapper>
  );
};

export default ReportedByType;
