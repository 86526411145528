import moment, { Moment } from 'moment';
import { stringify } from 'qs';
import { useEffect, useRef } from 'react';
import { Operator } from '../models/userSettings';

export function fixedZero(val: number): string {
  return val * 1 < 10 ? `0${val}` : val.toString();
}

export const stringToTitleCase = (str: string): string =>
  str
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');

export function truncateName(nameUppercase: string): string {
  const name = nameUppercase.toLowerCase();
  if (name.length <= 14) {
    return stringToTitleCase(name);
  }

  const fullName = name.split(' ');
  let displayName = `${fullName[0]} ${fullName[fullName.length - 1]}`;
  if (displayName.length <= 14) {
    return stringToTitleCase(displayName);
  }
  displayName = `${fullName[0].substring(0, 1)}. ${fullName[fullName.length - 1]}`;
  if (displayName.length <= 14) {
    return stringToTitleCase(displayName);
  }
  return `${fullName[0].substring(0, 1)}.${fullName[fullName.length - 1].substring(0, 1)}.`.toUpperCase();
}

export function formatTime(time: number, round?: boolean): string {
  const hours = Math.floor(time / 3600).toString();
  let mins = round
    ? Math.round((time - Math.floor(time / 3600) * 3600) / 60).toString()
    : Math.floor((time - Math.floor(time / 3600) * 3600) / 60).toString();

  if (mins.length < 2) {
    mins = `0${mins}`;
  }

  return `${hours}:${mins}`;
}

export function unSnakeify(event = '-'): string {
  let formattedString = event.replace(new RegExp('_', 'g'), ' ');
  formattedString = formattedString
    .split(' ')
    .map((item) => item.substring(0, 1).toUpperCase() + item.substring(1))
    .join(' ');
  return formattedString;
}

export function getTimeDistance(type: string): [Moment, Moment] {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();
    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }

  const year = now.getFullYear();
  return [moment('1901-01-01 00:00:00'), moment(`${year}-12-31 23:59:59`)];
}

export function getQueryPath(path = '', query = {}): string {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

const reg =
  // eslint-disable-next-line max-len
  /(((^https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export function isUrl(path: string): boolean {
  return reg.test(path);
}

export function isCompleteTripRecord(record): boolean {
  return (
    record &&
    'attachments' in record &&
    'start_airport' in record &&
    'end_airport' in record &&
    'callsign' in record &&
    'status' in record &&
    'flights' in record &&
    'captain' in record &&
    'first_officer' in record &&
    'versions' in record
  );
}

export function isCompleteFlightRecord(record, includeTripRecordCheck = true): boolean {
  return (
    record &&
    'apu_seconds' in record &&
    'arrival_airport_details' in record &&
    'arrival_airport_id' in record &&
    'arrival_fuel' in record &&
    'attachments' in record &&
    'defects' in record &&
    'deice_events' in record &&
    'departure_airport_details' in record &&
    'departure_airport_id' in record &&
    'departure_fuel_adjustment' in record &&
    'departure_fuel_log' in record &&
    'departure_fuel_pre_uplifts' in record &&
    'fuel_uplifts' in record &&
    'hobbs_arrival' in record &&
    'hobbs_departure' in record &&
    'oil_uplift' in record &&
    'pilot_flying' in record &&
    'pilot_monitoring' in record &&
    'postflight_signature_image_url' in record &&
    'postflight_signature_url' in record &&
    'preflight_signature_image_url' in record &&
    'preflight_signature_url' in record &&
    'prior_corrosion_risk_details' in record &&
    'prior_corrosion_risk_score' in record &&
    'remote_deice' in record &&
    'trip' in record &&
    'versions' in record &&
    'wet_weather_percentage' in record &&
    (!includeTripRecordCheck || isCompleteTripRecord(record.trip))
  );
}

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const checkOperatorSettingNameOverride = (operators: Operator[], setting: string): string => {
  if (operators.length === 0) return '';

  let settingVariance = false;
  let newName = operators[0]?.operator_setting[`${setting}`];
  const settingPossibilities = [newName];
  if (operators?.length > 1) {
    for (let index = 1; index < operators.length; index += 1) {
      if (operators[index]?.operator_setting[`${setting}`] !== newName) {
        settingVariance = true;
        settingPossibilities.push(newName);
      }
    }
  }
  if (settingVariance) {
    const findMostCommonSetting = (strings: string[]): string => {
      let mostCommonString = '';
      let mostCommonCount = 0;
      strings.forEach((string) => {
        const count = settingPossibilities.filter((settingToCheck) => settingToCheck === string).length;
        if (count > mostCommonCount) {
          mostCommonString = string;
          mostCommonCount = count;
        }
      });
      return mostCommonString;
    };
    newName = findMostCommonSetting(settingPossibilities);
  }
  return newName;
};

export const combineFluidUplifts = (newUplifts, existingUplifts = [], hydraulic = false): any[] => {
  const exUplifts = [...existingUplifts];
  for (const newUplift of newUplifts) {
    let existingIndex;
    if (hydraulic) {
      existingIndex = existingUplifts.findIndex(
        (existing) => existing?.hydraulic_circuit_id === newUplift?.hydraulic_circuit_id,
      );
    } else {
      existingIndex = existingUplifts.findIndex(
        (existing) =>
          existing?.fluid_destination === newUplift?.fluid_destination &&
          existing?.fluid_destination_number === newUplift?.fluid_destination_number,
      );
    }

    if (existingIndex !== -1) {
      exUplifts[existingIndex] = newUplift;
    } else {
      exUplifts.push(newUplift);
    }
  }
  return exUplifts;
};

export const checkNumberIsTwoDecimalPlaces = (value: string): boolean => {
  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(value);
};
