import { Icon, InputNumber, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import PaginationDropdown from '../PaginatedDefectsTable/PaginationDropdown';
import styles from './index.module.less';

const ModernPagination = ({ pageSize, current, total, onPageSizeChange, onPageNoChange }) => {
  const { formatMessage } = useIntl();
  const onSizeChange = (pSize, pageNo, totalPages) => {
    if (onPageSizeChange && pSize && !Number.isNaN(pSize) && pSize > 0) onPageSizeChange(parseInt(pSize, 10));
    if (onPageNoChange && pageNo && Number(pageNo) > totalPages) onPageNoChange(parseInt(totalPages, 10));
  };

  const onChange = (pageNo, totalPages) => {
    if (onPageNoChange && pageNo && Number(pageNo) <= totalPages) onPageNoChange(pageNo);
  };

  const totalPages = Math.ceil(total / pageSize);

  return (
    total > 10 && (
      <div className={styles.paginationParent}>
        <div className={styles.pageSizer}>
          <span data-test="itemsPerPageText" className={styles.itemsPerText}>
            {formatMessage({ id: 'text.itemsPerPage' })}:
          </span>
          <PaginationDropdown
            initial={{ title: pageSize }}
            handleChange={(val) => onSizeChange(val, current, totalPages)}
          />
        </div>
        <div className={styles.pagePaginator}>
          <Button
            type="link"
            className={`${styles.prevArrow} ${current === 1 ? styles.disabled : undefined}`}
            onClick={() => onChange(current - 1, totalPages)}
            data-test="backArrow"
          >
            <Icon type="left" />
          </Button>
          <InputNumber
            className={styles.paginationInput}
            value={current}
            onChange={(val) => onChange(val, totalPages)}
            max={totalPages}
            disabled={current === totalPages}
            data-test="currentInput"
          />
          <span data-test="totalPages">
            {formatMessage({ id: 'text.of' })} {totalPages}
          </span>
          <Button
            type="link"
            className={`${styles.nextArrow} ${current === totalPages ? styles.disabled : undefined}`}
            onClick={() => onChange(current + 1, totalPages)}
            data-test="nextArrow"
          >
            <Icon type="right" />
          </Button>
        </div>
      </div>
    )
  );
};

ModernPagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNoChange: PropTypes.func.isRequired,
};

export default ModernPagination;
