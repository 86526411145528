import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import overviewIcon from '../assets/context-menu-icons/overview.svg';
import tripsIcon from '../assets/context-menu-icons/trips.svg';
import logbookIcon from '../assets/context-menu-icons/logbook.svg';
import defectsIcon from '../assets/context-menu-icons/defects.svg';
import intermittentIcon from '../assets/context-menu-icons/intermittent-faults.svg';
import maintenanceIcon from '../assets/context-menu-icons/maintenance.svg';
import workpacksIcon from '../assets/context-menu-icons/workpacks.svg';
import metricsIcon from '../assets/context-menu-icons/metrics.svg';
import documentsIcon from '../assets/context-menu-icons/documents-2.svg';
import damageMapsIcon from '../assets/context-menu-icons/damage-maps.svg';
import settingsIcon from '../assets/context-menu-icons/settings.svg';
import notesIcon from '../assets/context-menu-icons/icon-navigation-notes-to-crew.svg';
import customFieldsIcon from '../assets/context-menu-icons/icon-nav-custom-fields.svg';
import moreIcon from '../assets/more-icon.svg';
import AuthenticationWrapper, { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { DashboardResource, FeatureFlag, UserPermission } from '../../models/userSettings';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import styles from './AircraftMenu.module.less';

const devices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

const deviceIsIOS = () => navigator && devices.includes(navigator.platform);

const fixIOSHover = () => {
  if (deviceIsIOS()) {
    return styles.noHover;
  }
  return null;
};

const hoverClass = fixIOSHover();

const AircraftMenu = ({
  selectedKeys,
  onClick,
  isMobile,
  intl: { formatMessage },
  match,
  intermittentTitle,
  dentAndBuckleTitle,
}) => {
  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state) => state);
  const { id } = match.params;

  const cabinIssueEnabled = aircraftMap?.get(id)?.standard_fields?.cabin_log?.enabled;
  const cabinIssuesOverride = operators.find((op) => aircraftMap.get(id)?.operator_id === op?.id)?.operator_setting
    ?.cabin_issue_name_plural;
  const defectsNameOverride = operators.find((op) => op?.id === aircraftMap?.get(id)?.operator_id)?.operator_setting
    ?.defect_name_plural;
  const notesToCrewOverride = operators.find((op) => op?.id === aircraftMap?.get(id)?.operator_id)?.operator_setting
    ?.notes_to_crew_name;

  return (
    <ul className={`${styles.aircraftMenuWrapper} ${isMobile ? styles.aircraftMobileHeader : undefined}`}>
      <AuthenticationWrapper
        requiredResource={DashboardResource.AIRCRAFT}
        requiredPermissionLevel={UserPermission.READ}
      >
        <li className={selectedKeys[0] === 'overview' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/overview`} className={styles.aircraftMenuLink}>
            <img src={overviewIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Overview' })}
          </Link>
        </li>
      </AuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.TRIP}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'trips' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/trips`}>
            <img src={tripsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Trips' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.LOGBOOK}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'logbook' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/logbook`}>
            <img src={logbookIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Logbook' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DEFECT}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'defects' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/defects`}>
            <img src={defectsIcon} className={styles.menuIcon} alt="" />
            {defectsNameOverride || formatMessage({ id: 'menu.Aircraft.Defects' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DEFECT}
        requiredPermissionLevel={AircraftPermission.READ}
        requiredFeatureFlag={FeatureFlag.INTERMITTENT}
      >
        <li
          className={selectedKeys[0] === 'intermittent_faults' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}
        >
          <Link to={`/aircraft/${id}/intermittent_faults`}>
            <img src={intermittentIcon} className={styles.menuIcon} alt="" />
            {intermittentTitle || formatMessage({ id: 'menu.Aircraft.Intermittent_faults' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      {cabinIssueEnabled ? (
        <AircraftAuthenticationWrapper
          aircraftId={id}
          requiredResource={AircraftResource.DEFECT}
          requiredPermissionLevel={AircraftPermission.READ}
        >
          <li className={selectedKeys[0] === 'cabin_log' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
            <Link to={`/aircraft/${id}/cabin_log`}>
              <img src={intermittentIcon} className={styles.menuIcon} alt="" />
              {cabinIssuesOverride || formatMessage({ id: 'menu.Aircraft.Cabin_log' })}
            </Link>
          </li>
        </AircraftAuthenticationWrapper>
      ) : null}
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.SCHEDULED_MX_ITEM}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'maintenance' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/maintenance`}>
            <img src={maintenanceIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Maintenance' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.WORKPACK}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'workpacks' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/workpacks`}>
            <img src={workpacksIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Workpacks' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DOCUMENT}
        requiredPermissionLevel={AircraftPermission.READ}
        requiredFeatureFlag={FeatureFlag.DOCUMENTS}
      >
        <li className={selectedKeys[0] === 'documents' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/documents`}>
            <img src={documentsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Documents' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DAMAGE_MAP}
        requiredPermissionLevel={AircraftPermission.READ}
        requiredFeatureFlag={FeatureFlag.DENTANDBUCKLE}
      >
        <li className={selectedKeys[0] === 'damage_maps' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/damage_maps/list_view`}>
            <img src={damageMapsIcon} className={styles.menuIcon} alt="" />
            {dentAndBuckleTitle || formatMessage({ id: 'menu.Aircraft.DamageMap' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.TRIP}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'metrics' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/metrics`}>
            <img src={metricsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Metrics' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.AIRCRAFT}
        requiredPermissionLevel={AircraftPermission.READ}
        requiredFeatureFlag={FeatureFlag.NOTES}
      >
        <li className={selectedKeys[0] === 'notes' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/notes`}>
            <img src={notesIcon} className={styles.menuIcon} alt="" />
            {notesToCrewOverride || `Notes to Crew`}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.AIRCRAFT}
        requiredPermissionLevel={AircraftPermission.READ}
        requiredFeatureFlag={FeatureFlag.CUSTOMFIELDS}
        onlyAdmin
      >
        <li className={selectedKeys[0] === 'custom_fields' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/custom_fields`}>
            <img src={customFieldsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'text.customFields' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AuthenticationWrapper
        aircraftId={id}
        requiredResource={DashboardResource.AIRCRAFT}
        requiredPermissionLevel={UserPermission.UPDATE}
      >
        <li className={selectedKeys[0] === 'settings' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/settings`}>
            <img src={settingsIcon} className={styles.menuIcon} alt="" />
            <span>{formatMessage({ id: 'menu.settings' })}</span>
          </Link>
        </li>
      </AuthenticationWrapper>
      {isMobile && (
        <button type="button" onClick={onClick} onKeyDown={onClick}>
          <img src={moreIcon} alt="Open aircraft menu" className={styles.moreIcon} />
        </button>
      )}
    </ul>
  );
};

AircraftMenu.propTypes = {
  selectedKeys: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  isMobile: PropTypes.bool,
  match: PropTypes.object.isRequired,
  intermittentTitle: PropTypes.string,
  dentAndBuckleTitle: PropTypes.string,
};

AircraftMenu.defaultProps = {
  isMobile: false,
  onClick: () => {
    // default
  },
  intermittentTitle: 'Intermittent Faults',
  dentAndBuckleTitle: 'Dent & Buckle',
};

export default withRouter(injectIntl(AircraftMenu));
