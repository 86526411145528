import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, CartesianGrid, Line, XAxis, YAxis, Tooltip, ReferenceLine } from 'recharts';
import EmptyStateOil from '../../assets/emptyState/empty-state-fuel.svg';
import EmptyState from '../EmptyState/EmptyState';
import styles from './OilConsumption.module.less';

const OilConsumption = ({ oilData, checkForAlerts, formatMessage, isNoOilData, isNoOilDataForRange, isMobile }) => {
  const [engine1Visible, setEngine1Visible] = useState(true);
  const [engine2Visible, setEngine2Visible] = useState(true);
  const [engine3Visible, setEngine3Visible] = useState(true);
  const [engine4Visible, setEngine4Visible] = useState(true);
  const [APUVisible, setAPUVisible] = useState(true);
  const [oilDataState, setOilDataState] = useState();

  useEffect(() => {
    setOilDataState(oilData);
  }, [oilData]);
  const onlyDataArray =
    oilDataState &&
    oilDataState.data !== undefined &&
    oilDataState.data.length > 0 &&
    oilDataState.data.filter((item) => item.engine_1);

  const lastEntry = onlyDataArray && onlyDataArray.filter((item, index) => index === onlyDataArray.length - 1);

  const alertCount =
    lastEntry &&
    lastEntry.reduce((totalCounts, row) => {
      if (oilDataState.engine_oil_consumption_threshold === null && oilDataState.apu_oil_consumption_threshold === null)
        return 0;
      let count = totalCounts;
      const { engine_oil_consumption_threshold } = oilData;
      if (engine_oil_consumption_threshold) {
        if (row.engine_1 > engine_oil_consumption_threshold) count += 1;
        if (row.engine_2 > engine_oil_consumption_threshold) count += 1;
        if (row.engine_3 > engine_oil_consumption_threshold) count += 1;
        if (row.engine_4 > engine_oil_consumption_threshold) count += 1;
      }
      const { apu_oil_consumption_threshold } = oilData;
      if (apu_oil_consumption_threshold) {
        if (row.apu && row.apu > apu_oil_consumption_threshold) count += 1;
      }
      return count;
    }, 0);

  checkForAlerts(alertCount);

  let colorBreakPointPercentageE1;
  let colorBreakPointPercentageE2;
  let colorBreakPointPercentageE3;
  let colorBreakPointPercentageE4;
  let colorBreakPointPercentageAPU;

  if (oilDataState) {
    const { minE1, maxE1 } =
      oilDataState &&
      oilDataState.data !== undefined &&
      oilDataState.data.length > 0 &&
      oilDataState.data.reduce(
        (result, dataPoint) => ({
          minE1:
            dataPoint.engine_1 && (dataPoint.engine_1 < result.minE1 || result.minE1 === 0)
              ? dataPoint.engine_1
              : result.minE1,
          maxE1:
            dataPoint.engine_1 && (dataPoint.engine_1 > result.maxE1 || result.maxE1 === 0)
              ? dataPoint.engine_1
              : result.maxE1,
        }),
        { minE1: 0, maxE1: 0 },
      );
    colorBreakPointPercentageE1 = oilDataState.engine_oil_consumption_threshold
      ? `${(1 - (oilDataState.engine_oil_consumption_threshold - minE1) / (maxE1 - minE1)) * 100}%`
      : '0%';
    const { minE2, maxE2 } =
      oilDataState &&
      oilDataState.data !== undefined &&
      oilDataState.data.length > 0 &&
      oilDataState.data.reduce(
        (result, dataPoint) => ({
          minE2:
            dataPoint.engine_2 && (dataPoint.engine_2 < result.minE2 || result.minE2 === 0)
              ? dataPoint.engine_2
              : result.minE2,
          maxE2:
            dataPoint.engine_2 && (dataPoint.engine_2 > result.maxE2 || result.maxE2 === 0)
              ? dataPoint.engine_2
              : result.maxE2,
        }),
        { minE2: 0, maxE2: 0 },
      );
    colorBreakPointPercentageE2 = oilDataState.engine_oil_consumption_threshold
      ? `${(1 - (oilDataState.engine_oil_consumption_threshold - minE2) / (maxE2 - minE2)) * 100}%`
      : '0%';
    const { minE3, maxE3 } =
      oilDataState &&
      oilDataState.data !== undefined &&
      oilDataState.data.length > 0 &&
      oilDataState.data.reduce(
        (result, dataPoint) => ({
          minE3:
            dataPoint.engine_3 && (dataPoint.engine_3 < result.minE3 || result.minE3 === 0)
              ? dataPoint.engine_3
              : result.minE3,
          maxE3:
            dataPoint.engine_3 && (dataPoint.engine_3 > result.maxE3 || result.maxE3 === 0)
              ? dataPoint.engine_3
              : result.maxE3,
        }),
        { minE3: 0, maxE3: 0 },
      );
    colorBreakPointPercentageE3 = oilDataState.engine_oil_consumption_threshold
      ? `${(1 - (oilDataState.engine_oil_consumption_threshold - minE3) / (maxE3 - minE3)) * 100}%`
      : '0%';
    const { minE4, maxE4 } =
      oilDataState &&
      oilDataState.data !== undefined &&
      oilDataState.data.length > 0 &&
      oilDataState.data.reduce(
        (result, dataPoint) => ({
          minE4:
            dataPoint.engine_4 && (dataPoint.engine_4 < result.minE4 || result.minE4 === 0)
              ? dataPoint.engine_4
              : result.minE4,
          maxE4:
            dataPoint.engine_4 && (dataPoint.engine_4 > result.maxE4 || result.maxE4 === 0)
              ? dataPoint.engine_4
              : result.maxE4,
        }),
        { minE4: 0, maxE4: 0 },
      );
    colorBreakPointPercentageE4 = oilDataState.engine_oil_consumption_threshold
      ? `${(1 - (oilDataState.engine_oil_consumption_threshold - minE4) / (maxE4 - minE4)) * 100}%`
      : '0%';
    const { min, max } =
      oilDataState &&
      oilDataState.data !== undefined &&
      oilDataState.data.length > 0 &&
      oilDataState.data.reduce(
        (result, dataPoint) => ({
          min: dataPoint.apu && (dataPoint.apu < result.min || result.min === 0) ? dataPoint.apu : result.min,
          max: dataPoint.apu && (dataPoint.apu > result.max || result.max === 0) ? dataPoint.apu : result.max,
        }),
        { min: 0, max: 0 },
      );
    colorBreakPointPercentageAPU = oilDataState.apu_oil_consumption_threshold
      ? `${(1 - (oilDataState.apu_oil_consumption_threshold - min) / (max - min)) * 100}%`
      : '0%';
  }

  const handleButtonClick = (button) => {
    switch (button) {
      case 'engine_1':
        setEngine1Visible(!engine1Visible);
        break;
      case 'engine_2':
        setEngine2Visible(!engine2Visible);
        break;
      case 'engine_3':
        setEngine3Visible(!engine3Visible);
        break;
      case 'engine_4':
        setEngine4Visible(!engine4Visible);
        break;
      case 'apu':
        setAPUVisible(!APUVisible);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        {!isNoOilDataForRange ? (
          <LineChart
            width={600}
            height={300}
            data={oilDataState && oilDataState.data}
            margin={{ top: 10, bottom: 20, right: 60, left: 10 }}
          >
            <CartesianGrid stroke="#dbe3ed" strokeDasharray="2 2" />
            <XAxis dataKey="date" stroke="#b0bac9" />
            <YAxis
              type="number"
              stroke="#b0bac9"
              domain={[
                'auto',
                (dataMax) =>
                  dataMax > oilDataState.engine_oil_consumption_threshold
                    ? dataMax
                    : oilDataState.engine_oil_consumption_threshold,
              ]}
              unit={oilDataState && oilDataState.oil_unit}
            />
            <Tooltip />
            {oilDataState && oilDataState.engine_oil_consumption_threshold
              ? (engine1Visible || engine2Visible || engine3Visible || engine4Visible) && (
                  <ReferenceLine
                    y={oilDataState && oilDataState.engine_oil_consumption_threshold}
                    stroke="red"
                    strokeDasharray="2 2"
                    label={{
                      value: formatMessage({ id: 'text.engineThreshold' }),
                      position: 'insideBottomRight',
                      fill: '#b0bac9',
                    }}
                    ifOverflow="extendDomain"
                  />
                )
              : null}
            {oilDataState && oilDataState.apu_oil_consumption_threshold
              ? APUVisible && (
                  <ReferenceLine
                    y={oilDataState && oilDataState.apu_oil_consumption_threshold}
                    stroke="#c90000"
                    strokeDasharray="2 2"
                    label={{
                      value: formatMessage({ id: 'text.apuThreshold' }),
                      position: 'insideBottomLeft',
                      fill: '#b0bac9',
                    }}
                    ifOverflow="extendDomain"
                  />
                )
              : null}
            <defs>
              <linearGradient id="colourEngine1" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE1} stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE1} stopColor="#012140" />
                <stop offset="100%" stopColor="#012140" />
              </linearGradient>
              <linearGradient id="colourEngine2" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE2} stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE2} stopColor="#1790FF" />
                <stop offset="100%" stopColor="#1790FF" />
              </linearGradient>
              <linearGradient id="colourEngine3" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE3} stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE3} stopColor="#3451CC" />
                <stop offset="100%" stopColor="#3451CC" />
              </linearGradient>
              <linearGradient id="colourEngine4" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE4} stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageE4} stopColor="#A1D2FF" />
                <stop offset="100%" stopColor="#A1D2FF" />
              </linearGradient>
              <linearGradient id="colourAPU" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageAPU} stopColor="#CD4021" />
                <stop offset={colorBreakPointPercentageAPU} stopColor="#67DDCF" />
                <stop offset="100%" stopColor="#67DDCF" />
              </linearGradient>
            </defs>
            {engine1Visible ? (
              <Line
                type="monotone"
                strokeWidth="2"
                dataKey="engine_1"
                stroke="url(#colourEngine1)"
                dot={{ stroke: '#012140', strokeWidth: 2 }}
                connectNulls
              />
            ) : null}
            {engine2Visible ? (
              <Line
                type="monotone"
                strokeWidth="2"
                dataKey="engine_2"
                stroke="url(#colourEngine2)"
                dot={{ stroke: '#1790FF', strokeWidth: 2 }}
                connectNulls
              />
            ) : null}
            {engine3Visible ? (
              <Line
                type="monotone"
                strokeWidth="2"
                dataKey="engine_3"
                stroke="url(#colourEngine3)"
                dot={{ stroke: '#3451CC', strokeWidth: 2 }}
                connectNulls
              />
            ) : null}
            {engine4Visible ? (
              <Line
                type="monotone"
                strokeWidth="2"
                dataKey="engine_4"
                stroke="url(#colourEngine4)"
                dot={{ stroke: '#A1D2FF', strokeWidth: 2 }}
                connectNulls
              />
            ) : null}
            {APUVisible ? (
              <Line
                type="monotone"
                strokeWidth="2"
                dataKey="apu"
                stroke="url(#colourAPU)"
                dot={{ stroke: '#67DDCF', strokeWidth: 2 }}
                connectNulls
              />
            ) : null}
          </LineChart>
        ) : (
          <EmptyState
            image={EmptyStateOil}
            text="No engine oil consumption data"
            subText="Engine oil consumption will be shown here when data has been added."
          />
        )}
      </ResponsiveContainer>
      <div
        className={!isNoOilData ? styles.toggleButtons : styles.hideToggleButtons}
        data-testid="OilConsumption--ToggleButtons"
      >
        {oilDataState &&
        oilDataState.data !== undefined &&
        oilDataState.data.length > 0 &&
        'engine_1' in oilData.data[0] ? (
          <button
            className={engine1Visible ? styles.buttonToggle : styles.buttonToggleOff}
            type="button"
            onClick={() => handleButtonClick('engine_1')}
          >
            <div className={styles.badge1} />
            <span data-testid="OilConsumption--Engine1Button">
              {formatMessage({ id: isMobile ? 'text.e1' : 'text.engine1' })}
            </span>
          </button>
        ) : null}
        {oilDataState &&
        oilDataState.data !== undefined &&
        oilDataState.data.length > 0 &&
        'engine_2' in oilData.data[0] ? (
          <button
            className={engine2Visible ? styles.buttonToggle : styles.buttonToggleOff}
            type="button"
            onClick={() => handleButtonClick('engine_2')}
          >
            <div className={styles.badge2} />
            <span data-testid="OilConsumption--Engine2Button">
              {formatMessage({ id: isMobile ? 'text.e2' : 'text.engine2' })}
            </span>
          </button>
        ) : null}
        {oilDataState &&
        oilDataState.data !== undefined &&
        oilDataState.data.length > 0 &&
        'engine_3' in oilData.data[0] ? (
          <button
            className={engine3Visible ? styles.buttonToggle : styles.buttonToggleOff}
            type="button"
            onClick={() => handleButtonClick('engine_3')}
          >
            <div className={styles.badge3} />
            <span data-testid="OilConsumption--Engine3Button">
              {formatMessage({ id: isMobile ? 'text.e3' : 'text.engine3' })}
            </span>
          </button>
        ) : null}
        {oilDataState &&
        oilDataState.data !== undefined &&
        oilDataState.data.length > 0 &&
        'engine_4' in oilData.data[0] ? (
          <button
            className={engine4Visible ? styles.buttonToggle : styles.buttonToggleOff}
            type="button"
            onClick={() => handleButtonClick('engine_4')}
          >
            <div className={styles.badge4} />
            <span data-testid="OilConsumption--Engine4Button">
              {formatMessage({ id: isMobile ? 'text.e4' : 'text.engine4' })}
            </span>
          </button>
        ) : null}
        {oilDataState && oilDataState.data !== undefined && oilDataState.data.length > 0 && 'apu' in oilData.data[0] ? (
          <button
            className={APUVisible ? styles.buttonToggle : styles.buttonToggleOff}
            type="button"
            onClick={() => handleButtonClick('apu')}
          >
            <div className={styles.badge5} />
            <span data-testid="OilConsumption--APUButton">{formatMessage({ id: 'text.apu' })}</span>
          </button>
        ) : null}
      </div>
    </>
  );
};

OilConsumption.propTypes = {
  oilData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  checkForAlerts: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  isNoOilData: PropTypes.bool,
  isNoOilDataForRange: PropTypes.bool,
  isMobile: PropTypes.bool,
};

OilConsumption.defaultProps = {
  oilData: [],
  isNoOilData: false,
  isNoOilDataForRange: false,
  isMobile: false,
};

export default OilConsumption;
