import React from 'react';
import styled from 'styled-components';
import iIcon from '../../assets/i.svg';
import tickIcon from '../../assets/icon-tick-white.svg';

const BannerWrapper = styled.div`
  background: #fbfcfd;
  border-radius: 2px;
  border: ${({ crs }): string => (crs ? '#1bc689 1px solid' : '#ffb06e 1px solid')};
  height: ${({ isMobile }): string => (isMobile ? 'auto' : '48px')};
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: ${({ crs }): string => (crs ? '0' : '20px')};
`;

const IconWrapper = styled.div`
  background-image: ${({ crs }): string =>
    crs ? 'linear-gradient(to bottom, #1bc689, #12845b)' : 'linear-gradient(to bottom, #ffb06e, #f79833)'};
  width: 40px;
  height: ${({ isMobile }): string => (isMobile ? '62px' : '48px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0;
`;

export interface DrawerBannerProps {
  message: string;
  crs?: boolean;
}

const DrawerBanner: React.FC<DrawerBannerProps> = ({ message, crs }) => {
  const isMobile = window.innerWidth < 451;
  return (
    <BannerWrapper crs={crs} isMobile={isMobile}>
      <IconWrapper crs={crs} isMobile={isMobile}>
        <img src={crs ? tickIcon : iIcon} alt="info icon" />
      </IconWrapper>
      {message}
    </BannerWrapper>
  );
};

export default DrawerBanner;
