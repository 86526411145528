import React, { useEffect, useState } from 'react';
import { Button, InputNumber } from 'antd';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './MXModal.module.less';

const ToleranceCounter = ({
  itemTolerance,
  itemVisibleTolerance,
  nextDue,
  remaining,
  currentItem,
  unitSingular,
  unitPlural,
  type,
  onChange,
  intl: { formatMessage },
  dateFormat,
}) => {
  const [parsedItemTolerance, setParsedItemTolerance] = useState(0);
  const [parsedItemVisibleTolerance, setParsedItemVisibleTolerance] = useState(0);
  const [parsedNextDue, setParsedNextDue] = useState(0);
  const [parsedRemaining, setParsedRemaining] = useState(0);

  const minusTolerance = () => {
    const newTolerance = parsedItemVisibleTolerance > 0 ? parsedItemVisibleTolerance - 1 : 0;
    setParsedItemVisibleTolerance(newTolerance);
    onChange(type === 'seconds' ? newTolerance * 3600 : newTolerance);
  };

  const addTolerance = () => {
    const newTolerance =
      parsedItemVisibleTolerance < parsedItemTolerance ? parsedItemVisibleTolerance + 1 : parsedItemTolerance;
    setParsedItemVisibleTolerance(newTolerance);
    onChange(type === 'seconds' ? newTolerance * 3600 : newTolerance);
  };

  const onInputChange = (value) => {
    if (value !== null && value >= 0 && value <= parsedItemTolerance) {
      setParsedItemVisibleTolerance(value);
      onChange(type === 'seconds' ? value * 3600 : value);
    }
  };

  let visibleRemaining = parsedRemaining + parsedItemVisibleTolerance;

  if (type === 'months')
    visibleRemaining =
      moment(parsedNextDue).add(parsedItemVisibleTolerance, 'months').diff(parsedNextDue, 'days') + parsedRemaining;

  useEffect(() => {
    if (currentItem) {
      if (type === 'seconds') {
        const newTolerance = Math.round(itemTolerance / 3600);
        setParsedItemTolerance(newTolerance);
        setParsedItemVisibleTolerance(Math.round(itemVisibleTolerance / 3600));
        setParsedNextDue(Math.round(nextDue / 3600));
        setParsedRemaining(Math.round(remaining / 3600));
      } else {
        setParsedItemTolerance(itemTolerance);
        setParsedItemVisibleTolerance(itemVisibleTolerance);
        setParsedNextDue(nextDue);
        setParsedRemaining(remaining);
      }
    }
  }, [currentItem, itemTolerance, itemVisibleTolerance, nextDue, remaining, type]);

  return (
    <>
      {parsedItemTolerance > 0 ? (
        <>
          <div className={styles.counterWrapper}>
            <div className={styles.toleranceValue} data-test="toleranceWrapper">
              <span data-test="toleranceValue">
                <InputNumber
                  value={parsedItemVisibleTolerance || 0}
                  min={0}
                  precision={0}
                  max={parsedItemTolerance}
                  size="small"
                  className={styles.toleranceInput}
                  onChange={(value) => onInputChange(value)}
                />
                /{` ${parsedItemTolerance}`}
              </span>
              {parsedItemTolerance > 1 ? unitPlural : unitSingular}
            </div>
            <Button
              type="default"
              className={styles.counterButton}
              disabled={Number(parsedItemVisibleTolerance) === 0}
              onClick={() => minusTolerance()}
              data-test="minusButton"
            >
              -
            </Button>
            <Button
              type="default"
              className={styles.counterButton}
              disabled={Number(parsedItemVisibleTolerance) === Number(parsedItemTolerance)}
              onClick={() => addTolerance()}
              data-test="plusButton"
            >
              +
            </Button>
          </div>
          <div className={styles.toleranceDescription} data-test="toleranceDescription">
            {parsedNextDue !== null && parsedNextDue !== undefined && (
              <>
                {formatMessage({ id: 'form.labels.nextDue' })}:{' '}
                <span data-test="toleranceNextDue">
                  {type === 'months' &&
                    !!parsedNextDue &&
                    moment(parsedNextDue)
                      .add(parsedItemVisibleTolerance, 'months')
                      .format(dateFormat || 'YYYY-MM-DD')}
                  {type === 'days' &&
                    !!parsedNextDue &&
                    moment(parsedNextDue)
                      .add(parsedItemVisibleTolerance, 'days')
                      .format(dateFormat || 'YYYY-MM-DD')}
                  {type !== 'months' && type !== 'days' && !!parsedNextDue && (
                    <>
                      {parsedNextDue + parsedItemVisibleTolerance} {parsedNextDue > 1 ? unitPlural : unitSingular}
                    </>
                  )}
                </span>
              </>
            )}
            {parsedRemaining !== null && parsedRemaining !== undefined && (
              <>
                (
                <span data-test="toleranceRemaining">
                  {visibleRemaining >= 0 ? visibleRemaining : 0}{' '}
                  {
                    // eslint-disable-next-line no-nested-ternary
                    visibleRemaining === 1
                      ? type === 'months'
                        ? formatMessage({ id: 'units.day' })
                        : unitSingular
                      : type === 'months'
                      ? formatMessage({ id: 'units.days' })
                      : unitPlural
                  }
                </span>
                {formatMessage({ id: 'text.remaining' })})
              </>
            )}
          </div>
        </>
      ) : (
        <div className={styles.toleranceDescription} data-test="toleranceNotAvailable">
          {formatMessage({ id: 'text.toleranceNotAvailable' })}
        </div>
      )}
    </>
  );
};

ToleranceCounter.defaultProps = {
  itemTolerance: null,
  itemVisibleTolerance: null,
  nextDue: null,
  remaining: null,
  type: null,
  dateFormat: null,
};

ToleranceCounter.propTypes = {
  dateFormat: PropTypes.string,
  itemTolerance: PropTypes.number,
  itemVisibleTolerance: PropTypes.number,
  nextDue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remaining: PropTypes.number,
  currentItem: PropTypes.object.isRequired,
  unitSingular: PropTypes.string.isRequired,
  unitPlural: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(ToleranceCounter);
