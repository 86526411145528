import { Popconfirm } from 'antd';
import { useIntl } from 'react-intl';
import React from 'react';
import Styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import { Aircraft } from '../../../models/aircraft';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';

interface APUPopconfirmProps {
  aircraft: Aircraft;
  form: any;
  handleSubmit: () => void;
  loading: boolean;
}

const StyledSaveButton = Styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

const APUPopconfirm: React.FC<APUPopconfirmProps> = ({ aircraft, form, handleSubmit, loading }) => {
  const { formatMessage } = useIntl();

  const oldApuMode = aircraft.apu_mode;
  const newApuMode = form.getFieldValue('apu_mode');

  if (newApuMode && newApuMode !== oldApuMode) {
    return (
      <Popconfirm
        title={
          <div>
            <div>{formatMessage({ id: 'text.areYouSureAPUMode' })}</div>
            <div>{formatMessage({ id: 'text.thisMayCauseHistorical' })}</div>
          </div>
        }
        onConfirm={handleSubmit}
        okText="Yes"
        cancelText="No"
      >
        <StyledSaveButton type="button">{formatMessage({ id: 'form.button.save' })}</StyledSaveButton>
      </Popconfirm>
    );
  }
  return (
    <Button disabled={loading} padding="0 28px" size={ButtonSize.MEDIUM} onClick={handleSubmit}>
      {formatMessage({ id: 'form.button.save' })}
    </Button>
  );
};

export default APUPopconfirm;
