import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import errorIcon from '../../../assets/login-error.svg';

interface TimeInputProps {
  input: number;
  onChange: (value: number) => void;
  sectorDrawer?: boolean;
  tripUpdate?: boolean;
  onBlur?: () => void;
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: ${({ mins }): string => (mins ? '43px' : '55px')};
  height: ${({ sectorDrawer }): string => (sectorDrawer ? '34px' : '40px')};
  border-radius: ${({ tripUpdate }): string => (tripUpdate ? '2px' : '4px')};
  border: ${({ tripUpdate }): string => (tripUpdate ? 'solid 1px rgba(36, 45, 65, 0.05)' : 'solid 1px #d9d9d9')};
  background-color: ${({ tripUpdate }): string => (tripUpdate ? '#f6f8fb' : 'transparent')};
  padding: 11px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  width: 100px;
  img {
    margin-right: 8px;
  }
  span {
    white-space: nowrap;
    overflow: visible;
    color: #ac1717;
  }
`;

const TimeInput: React.FC<TimeInputProps> = ({ input, onChange, sectorDrawer, tripUpdate, onBlur }): ReactElement => {
  const [hours, setHours] = useState(undefined);
  const [mins, setMins] = useState(undefined);
  const [minsError, setMinsError] = useState(false);

  useEffect(() => {
    if (input) {
      setHours(Math.floor(input / 3600));
      setMins(Math.floor(input / 60) % 60);
    }
  }, [input]);

  const calculateTime = (hrs: number, minutes: number): number => {
    const newTime = (hrs || 0) * 3600 + (minutes || 0) * 60;
    return newTime;
  };

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setHours(event.target.value);
    onChange(calculateTime(parseInt(event.target.value, 10), mins));
  };

  const handleMinsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (parseInt(event.target.value, 10) > 59) {
      setMinsError(true);
    } else {
      setMins(event.target.value);
      setMinsError(false);
      onChange(calculateTime(hours, parseInt(event.target.value, 10)));
    }
  };
  return (
    <>
      <InputWrapper>
        <StyledInput
          sectorDrawer={sectorDrawer}
          tripUpdate={tripUpdate}
          type="number"
          value={hours}
          onChange={handleHoursChange}
          min={0}
          onBlur={onBlur}
        />
        {sectorDrawer ? '.' : ':'}
        <StyledInput
          mins
          sectorDrawer={sectorDrawer}
          tripUpdate={tripUpdate}
          type="number"
          value={mins}
          onChange={handleMinsChange}
          min={0}
          onBlur={onBlur}
          max={59}
        />
      </InputWrapper>
      {minsError ? (
        <ErrorWrapper>
          <img src={errorIcon} alt="error icon" />
          <span>Minutes cannot be more than 59</span>
        </ErrorWrapper>
      ) : null}
    </>
  );
};

export default TimeInput;
