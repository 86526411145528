import { Accordion, Button } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import fuelIcon from '../../assets/icon-card-fuel.svg';
import plusIcon from '../../assets/plus-blue.svg';
import errorIcon from '../../assets/login-error.svg';
import deleteIcon from '../../assets/delete-red.svg';
import { DashboardState } from '../../models';
import getOperatorSetting from '../../utils/getOperatorSetting';
import {
  ContentWrapper,
  DisplayText,
  Header,
  MinusWrapper,
  PartsDiv,
  RowHeader,
  StyledInput,
  StyledUnit,
  TitleWrapper,
  UnitWrapper,
} from './TripUpdateAirframeSection';

interface TripUpdateFuelSectionProps {
  trip: any;
  updateTripUpdateData: (changes: { value: any; key: string }[]) => void;
}

const FuelLogWrapper = styled.div`
  border-bottom: ${({ noBorder }): string => (noBorder ? '' : '1px solid rgba(36, 45, 65, 0.05)')};
  margin: 0 20px 20px 56px;
  span {
    font-size: 12px;
    font-weight: 500;
    color: #242d41;
  }
  @media (max-width: 451px) {
    margin-left: 0;
  }
`;

const OuterWrapper = styled.div`
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  padding: ${({ isMobile }): string => (isMobile ? '16px 20px' : '16px 0 20px')};
`;

const JustificationDiv = styled.div`
  grid-column: ${({ isMobile }): string => (isMobile ? 'span 2' : 'span 4')};
  margin-right: ${({ isMobile }): string => (isMobile ? '0' : '20px')};
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ isMobile }): string => (isMobile ? 'flex-start' : 'flex-end')};
  align-items: ${({ isMobile }): string => (isMobile ? 'flex-start' : 'flex-end')};
  padding-right: 20px;
  grid-column: ${({ isMobile }): string => (isMobile ? 'span 2' : 'span 2')};
  img {
    margin-right: 4px;
  }
`;

const UpliftWrapper = styled.div`
  border-top: ${({ edit }): string => (edit ? 'solid 1px rgba(36, 45, 65, 0.05)' : 'none')};
  padding-top: ${({ edit }): string => (edit ? '20px' : '0')};
  grid-column: ${({ isMobile }): string => (isMobile ? 'span 2' : 'span 4')};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledDeleteButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  &:focus {
    outline: none;
  }
`;

const FuelUpliftErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgba(36, 45, 65, 0.7);
  grid-column: ${({ isMobile }): string => (isMobile ? 'span 2' : 'span 4')};
  line-height: 1.43;
  img {
    margin-right: 8px;
  }
`;

const FuelBurnWrapper = styled.div`
  border-top: solid 1px rgba(36, 45, 65, 0.05);
  margin: 20px 20px 20px 56px;
  padding-top: 20px;
`;

const TripUpdateFuelSection: React.FC<TripUpdateFuelSectionProps> = ({ trip, updateTripUpdateData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [fuelLogs, setFuelLogs] = useState(null);
  const [numberOfParts, setNumberOfParts] = useState(2);
  const [fuelUpliftError, setFuelUpliftError] = useState(null);

  const isMobile = window.innerWidth < 451;
  const displayFuelBurn = getOperatorSetting('fuel_burn_on_trips_enabled', id);

  const handleInputChange = (value: number, key: string, index: number): void => {
    const newArray = fuelLogs;
    newArray[`${index}`][`${key}`] = value;
    updateTripUpdateData([{ value: newArray, key: 'fuel_logs' }]);
  };

  const handleFuelBurnChange = (value: number): void => {
    updateTripUpdateData([{ value, key: 'fuel_burn' }]);
  };

  const handleAddFuelUplift = (upliftIndex): void => {
    const newUplift = {
      aircraft_actual_uplift: '0.0',
    };
    const oldFuelUplifts = fuelLogs[upliftIndex].fuel_uplifts?.filter(
      (uplift) => !Array.from(Object.keys(uplift)).includes('_destroy'),
    );

    if (
      (oldFuelUplifts && oldFuelUplifts[oldFuelUplifts.length - 1]?.aircraft_actual_uplift !== '0.0') ||
      !oldFuelUplifts
    ) {
      oldFuelUplifts.push(newUplift);
      const newFuelLogArray = fuelLogs;
      newFuelLogArray[`${upliftIndex}`].fuel_uplifts = oldFuelUplifts;
      updateTripUpdateData([{ value: newFuelLogArray, key: 'fuel_logs' }]);
    } else {
      setFuelUpliftError(upliftIndex);
    }
  };

  const buildUpliftsSection = (upliftArray, index, unit): ReactElement[] => {
    if (upliftArray.length === 0) return null;

    const handleUpliftChange = (value, localIndex): void => {
      const newUpliftArray = upliftArray;
      newUpliftArray[`${localIndex}`].aircraft_actual_uplift = value;
      handleInputChange(newUpliftArray, 'fuel_uplifts', index);
      setFuelUpliftError(null);
    };

    const handleDeleteFuelUplift = (localIndex): void => {
      const newUpliftArray = upliftArray;
      if (upliftArray[localIndex].id) {
        // eslint-disable-next-line no-underscore-dangle
        newUpliftArray[`${localIndex}`]._destroy = true;
      } else {
        newUpliftArray.splice(localIndex, 1);
      }
      setFuelUpliftError(null);
      handleInputChange(newUpliftArray, 'fuel_uplifts', index);
    };

    if (upliftArray) {
      const newUpliftArray = upliftArray
        ?.filter((uplift) => !Array.from(Object.keys(uplift)).includes('_destroy'))
        ?.map((uplift, localIndex) => {
          return (
            <UpliftWrapper
              isMobile={isMobile}
              edit={mode !== 'view'}
              data-testid={`TripUpdateFuelSection--UpliftWrapper-${localIndex}`}
            >
              <div>
                <Header editable={mode !== 'view'}>{`Uplift ${localIndex + 1}`}</Header>
                <UnitWrapper>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.aircraft_actual_uplift || '-'}</DisplayText>
                  ) : (
                    <StyledInput
                      placeholder="-"
                      width="96px"
                      value={uplift?.aircraft_actual_uplift || undefined}
                      onChange={(e): void => handleUpliftChange(parseFloat(e.target.value), localIndex)}
                      type="number"
                      data-testid={`TripUpdateFuelSection--UpliftInput-${localIndex}`}
                    />
                  )}
                  <StyledUnit edit={mode !== 'view'}>{unit || uplift?.aircraft_unit}</StyledUnit>
                </UnitWrapper>
              </div>
              {mode !== 'view' ? (
                <StyledDeleteButton type="button" onClick={(): void => handleDeleteFuelUplift(localIndex)}>
                  <img src={deleteIcon} alt="delete" />
                </StyledDeleteButton>
              ) : null}
            </UpliftWrapper>
          );
        });
      return newUpliftArray;
    }
    return null;
  };

  useEffect(() => {
    if (trip) {
      setFuelLogs(trip.fuel_logs);
      let partCount = trip?.fuel_logs?.length * 3;
      for (let i = 0; i < trip?.fuel_logs.length; i += 1) {
        const upliftCount = trip?.fuel_logs[i]?.fuel_uplifts?.length;
        partCount += upliftCount;
      }
      setNumberOfParts(partCount);
    }
  }, [trip]);

  const buildFuelLogs = (): ReactElement[] => {
    const fuelLogArray = fuelLogs?.map((log, i) => {
      return (
        <FuelLogWrapper
          key={log.id}
          noBorder={i + 1 === fuelLogs.length}
          data-testid="TripUpdateFuelSection--FuelLogWrapper"
        >
          <span>{log?.fuel_tank_name}</span>
          <ContentWrapper
            columns="1fr 1fr 1fr 1fr"
            noBorder
            padding={i + 1 === fuelLogs.length ? '20px 0 0 0' : '20px 0 20px 0'}
            isMobile={isMobile}
            gridColumns="1fr 1fr 3fr"
          >
            <div>
              <Header editable={mode !== 'view'}>Initial</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.pre_uplift_value || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.pre_uplift_value || undefined}
                    onChange={(e): void => handleInputChange(parseInt(e.target.value, 10), 'pre_uplift_value', i)}
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
            </div>
            <div>
              <Header editable={mode !== 'view'}>Carried Forward</Header>
              <UnitWrapper>
                {mode === 'view' ? (
                  <DisplayText>{log?.carried_forwards || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    placeholder="-"
                    width="96px"
                    value={log?.carried_forwards || undefined}
                    onChange={(e): void => handleInputChange(parseInt(e.target.value, 10), 'carried_forwards', i)}
                    type="number"
                  />
                )}
                <StyledUnit edit={mode !== 'view'}>{log?.uplift_unit}</StyledUnit>
              </UnitWrapper>
            </div>
            {mode !== 'view' ? (
              <AddButtonWrapper isMobile={isMobile} data-testid="TripUpdateFuelSection--AddUpliftButton">
                <Button height="24px" primary={false} onClick={(): void => handleAddFuelUplift(i)}>
                  <MinusWrapper>
                    <img src={plusIcon} alt="add uplift" />
                    {formatMessage({ id: 'text.addUplift' })}
                  </MinusWrapper>
                </Button>
              </AddButtonWrapper>
            ) : null}
            {buildUpliftsSection(log?.fuel_uplifts, i, log?.uplift_unit)}
            {fuelUpliftError === i ? (
              <FuelUpliftErrorWrapper isMobile={isMobile}>
                <img src={errorIcon} alt="info icon" />
                You must complete this uplift before adding another
              </FuelUpliftErrorWrapper>
            ) : null}
            <JustificationDiv isMobile={isMobile} edit={mode !== 'view'}>
              <Header editable={mode !== 'view'}>Justification for fuel change</Header>
              {mode === 'view' ? (
                <DisplayText>{log?.justification || '-'}</DisplayText>
              ) : (
                <StyledInput
                  placeholder="-"
                  width="100%"
                  value={log?.justification || undefined}
                  onChange={(e): void => handleInputChange(e.target.value, 'justification', i)}
                  type="text"
                />
              )}
            </JustificationDiv>
          </ContentWrapper>
        </FuelLogWrapper>
      );
    });
    return fuelLogArray;
  };

  return (
    <Accordion
      isExpanded={mode === 'add'}
      headerContent={
        <RowHeader>
          <TitleWrapper>
            <img src={fuelIcon} alt="airframe icon" />
            Fuel
          </TitleWrapper>
          <PartsDiv>{`${numberOfParts} parts`}</PartsDiv>
        </RowHeader>
      }
      dash
    >
      <OuterWrapper isMobile={isMobile} data-testid="TripUpdateFuelSection--OuterWrapper">
        {buildFuelLogs()}
        {displayFuelBurn ? (
          <FuelBurnWrapper>
            <Header editable={mode !== 'view'}>Fuel Burn</Header>
            <UnitWrapper>
              {mode === 'view' ? (
                <DisplayText>{trip?.fuel_burn || '-'}</DisplayText>
              ) : (
                <StyledInput
                  placeholder="-"
                  width="96px"
                  value={trip?.fuel_burn || undefined}
                  onChange={(e): void => handleFuelBurnChange(parseInt(e.target.value, 10))}
                  type="number"
                />
              )}
              <StyledUnit edit={mode !== 'view'}>{aircraftMap.get(id)?.fuel_unit}</StyledUnit>
            </UnitWrapper>
          </FuelBurnWrapper>
        ) : null}
      </OuterWrapper>
    </Accordion>
  );
};

export default TripUpdateFuelSection;
