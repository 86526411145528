import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { Label, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import ToolTip from '../../components/NewTooltip/NewTooltip';

interface CustomFieldsViewFormattingProps {
  fieldDisplayArray: string[];
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const CustomFieldsViewFormatting: React.FC<CustomFieldsViewFormattingProps> = ({ fieldDisplayArray }) => {
  const {
    aircraft: { aircraftMap },
    drawer: { drawerId },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [displayOptions, setDisplayOptions] = useState(null);

  const foundAircraft = aircraftMap.get(id);
  const customFieldsArray: any[] = foundAircraft ? Object.values(foundAircraft?.custom_fields) : [];

  const foundCustomField = customFieldsArray?.find((field) => field?.key === drawerId);

  useEffect(() => {
    if (foundCustomField?.options) {
      const items = Object.values(foundCustomField?.options).join(', ');
      setDisplayOptions(items);
    }
  }, [foundCustomField, drawerId]);

  return (
    <Wrapper data-testid="CustomFieldsViewFormatting--Wrapper">
      {fieldDisplayArray?.includes('precision') && (
        <div data-testid="CustomFieldsViewFormatting--Precision">
          <Label>
            {formatMessage({ id: 'text.precision' })}
            <ToolTip text={formatMessage({ id: 'text.numberOfDecimalPlacesRecorded' })} />
          </Label>
          <Text>{foundCustomField?.precision}</Text>
        </div>
      )}
      {fieldDisplayArray?.includes('options') && (
        <div data-testid="CustomFieldsViewFormatting--Options">
          <Label>{formatMessage({ id: 'text.options' })}</Label>
          <Text>{displayOptions}</Text>
        </div>
      )}
      {fieldDisplayArray?.includes('max') && (
        <div data-testid="CustomFieldsViewFormatting--Max">
          <Label>
            {formatMessage({ id: 'text.maximumCharacters' })}
            <ToolTip text={formatMessage({ id: 'text.smallestNumberForTheField' })} />
          </Label>
          <Text>{foundCustomField?.max}</Text>
        </div>
      )}
      {fieldDisplayArray?.includes('min') && (
        <div data-testid="CustomFieldsViewFormatting--Min">
          <Label>
            {formatMessage({ id: 'text.minimumCharacters' })}
            <ToolTip text={formatMessage({ id: 'text.largestNumberForTheField' })} />
          </Label>
          <Text>{foundCustomField?.min}</Text>
        </div>
      )}
    </Wrapper>
  );
};

export default CustomFieldsViewFormatting;
