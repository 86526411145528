import React, { useState } from 'react';
import { Accordion, Button } from '@arcflight/tf-component-library';
import fuelIcon from '../../assets/icon-card-fuel.svg';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  Text,
} from './AircraftAdvancedSettings';
import AircraftSettingsCard from './AircraftSettingsCard';
import ShowHideToggle from './ShowHideToggle';

interface AircraftCampSettingsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: any; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const AircraftCampSettings: React.FC<AircraftCampSettingsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleOverrideChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { override: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AircraftSettingsCard
            icon={fuelIcon}
            title="CAMP settings"
            buttonText="Edit CAMP settings"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
          />
        }
      >
        <StyledGrid>
          <ItemWrapper>
            <Label edit={editMode}>Import non-deferred defects</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.import_non_deferred_defects?.enabled ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.import_non_deferred_defects?.enabled !== true}
                handleChange={(value: boolean): void => handleToggleChange(!value, 'import_non_deferred_defects')}
                hideOverride="No"
                showOverride="Yes"
              />
            )}
          </ItemWrapper>
          {!aircraft?.standard_fields?.import_non_deferred_defects?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Override for workpacks</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.import_non_deferred_defects?.override ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.import_non_deferred_defects?.override !== true}
                  handleChange={(value: boolean): void => handleOverrideChange(!value, 'import_non_deferred_defects')}
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          ) : null}
          <ItemWrapper>
            <Label edit={editMode}>Import deferred/watchlist defects</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.import_deferred_watchlist_defects?.enabled ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.import_deferred_watchlist_defects?.enabled !== true}
                handleChange={(value: boolean): void => handleToggleChange(!value, 'import_deferred_watchlist_defects')}
                hideOverride="No"
                showOverride="Yes"
              />
            )}
          </ItemWrapper>
          {!aircraft?.standard_fields?.import_deferred_watchlist_defects?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Override for workpacks</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.import_deferred_watchlist_defects?.override ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.import_deferred_watchlist_defects?.override !== true}
                  handleChange={
                    (value: boolean): void => handleOverrideChange(!value, 'import_deferred_watchlist_defects')
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          ) : null}
          <ItemWrapper>
            <Label edit={editMode}>Import MEL defects</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.import_mel_defects?.enabled ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.import_mel_defects?.enabled !== true}
                handleChange={(value: boolean): void => handleToggleChange(!value, 'import_mel_defects')}
                hideOverride="No"
                showOverride="Yes"
              />
            )}
          </ItemWrapper>
          {!aircraft?.standard_fields?.import_mel_defects?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Override for workpacks</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.import_mel_defects?.override ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.import_mel_defects?.override !== true}
                  handleChange={(value: boolean): void => handleOverrideChange(!value, 'import_mel_defects')}
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          ) : null}
          <ItemWrapper>
            <Label edit={editMode}>Import all other defects</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.import_all_other_defects?.enabled ? 'Yes' : 'No'}</Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.import_all_other_defects?.enabled !== true}
                handleChange={(value: boolean): void => handleToggleChange(!value, 'import_all_other_defects')}
                hideOverride="No"
                showOverride="Yes"
              />
            )}
          </ItemWrapper>
          {!aircraft?.standard_fields?.import_all_other_defects?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>Override for workpacks</Label>
              {!editMode ? (
                <Text>{aircraft?.standard_fields?.import_all_other_defects?.override ? 'Yes' : 'No'}</Text>
              ) : (
                <ShowHideToggle
                  value={aircraft?.standard_fields?.import_all_other_defects?.override !== true}
                  handleChange={(value: boolean): void => handleOverrideChange(!value, 'import_all_other_defects')}
                  hideOverride="No"
                  showOverride="Yes"
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                Save
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              Cancel
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftCampSettings;
