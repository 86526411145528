import React from 'react';
import closeIcon from '../../../assets/new-close-icon-mobile.svg';
import styles from '../workpackDrawer.module.less';

interface UnsavedConfirmationButtonProps {
  unsavedChanges: boolean;
  handleClick: () => void;
}

const UnsavedConfirmationButton: React.FC<UnsavedConfirmationButtonProps> = ({ unsavedChanges, handleClick }) => {
  return (
    <>
      {unsavedChanges ? (
        <button
          className={styles.drawerCloseButton}
          type="button"
          onClick={(): void => {
            handleClick();
          }}
        >
          <img src={closeIcon} alt="close icon" className={styles.closeIcon} />
        </button>
      ) : null}
    </>
  );
};

export default UnsavedConfirmationButton;
