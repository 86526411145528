const produceFilterModel = (totals = null) => {
  return [
    {
      groupLabel: 'Status',
      filters: [
        {
          label: 'Open',
          key: 'status',
          value: 'open',
          badge: 'amber',
          count: totals ? totals?.status?.open : null,
          filterFunction: (item): boolean => item.status === 'open',
        },
        {
          label: 'Resolved',
          key: 'status',
          value: 'resolved',
          badge: 'green',
          count: totals ? totals?.status?.resolved : null,
          filterFunction: (item): boolean => item.status === 'resolved',
        },
      ],
    },
  ];
};

export default produceFilterModel;
