export default [
  { check: 'remarks', card: 'remarksCard', error: 'remarksError', message: 'remarksChecked' },
  { check: 'placards', card: 'placardsCard', error: 'placardsError', message: 'placardsChecked' },
  {
    check: 'operations',
    card: 'opsCard',
    error: 'operationalProceduresError',
    message: 'operationalProceduresChecked',
  },
  {
    check: 'maintenance',
    card: 'maintenanceCard',
    error: 'maintenanceProceduresError',
    message: 'maintenanceProceduresChecked',
  },
];
