import { Button } from '@arcflight/tf-component-library';
import React from 'react';
import styled from 'styled-components';
import plusIcon from '../../assets/plus-blue.svg';

interface DrawerIconCardProps {
  icon: string;
  cardText: string;
  buttonText?: string;
  showAdd?: boolean;
  handleAddClick?: () => void;
}

const defaultProps = {};

const DrawerIconCardDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
`;

const DrawerIcon = styled.img`
  margin-right: 0.5rem;
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardText = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: rgba(36, 45, 65, 0.7);
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const DrawerIconCard: React.FC<DrawerIconCardProps & typeof defaultProps> = ({
  icon,
  showAdd,
  handleAddClick,
  cardText,
  buttonText,
  children,
}) => {
  return (
    <DrawerIconCardDiv>
      <HeaderRow>
        <div>
          <DrawerIcon src={icon} alt="drawer-card-icon" />
          <CardText>{cardText}</CardText>
        </div>
        {showAdd && (
          <Button height="24px" onClick={handleAddClick} primary={false}>
            <AddTextWrapper>
              <PlusIcon src={plusIcon} alt="plus icon" /> {buttonText}
            </AddTextWrapper>
          </Button>
        )}
      </HeaderRow>
      {children}
    </DrawerIconCardDiv>
  );
};

export default DrawerIconCard;
