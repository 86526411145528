import React from 'react';
import warningIcon from '../assets/login-error-amber.svg';
import styles from './WarningRow.module.less';

export interface WarningRowProps {
  message: string;
}

const WarningRow: React.FC<WarningRowProps> = (props) => {
  const { message } = props;

  return (
    <div className={styles.rowWrapper}>
      <img src={warningIcon} alt="warning icon" />
      <span>{message}</span>
    </div>
  );
};

export default WarningRow;
