const findObjectById = (obj, targetId) => {
  // Base case: If the current object is null or undefined, return null
  if (!obj) {
    return null;
  }

  if (!targetId) {
    return null;
  }

  // Check if the current object has the target ID
  if (obj.id === targetId) {
    return obj;
  }

  // Check if the current object has an array property
  if (Array.isArray(obj)) {
    // Iterate through each element of the array
    for (let i = 0; i < obj.length; i += 1) {
      // Recursively call findObjectById for each element
      const result = findObjectById(obj[i], targetId);
      // If the result is not null, return it
      if (result !== null) {
        return result;
      }
    }
  } else if (typeof obj === 'object') {
    // If the current object is an object (not an array), recursively call findObjectById on its properties
    for (const key of Object.keys(obj)) {
      const result = findObjectById(obj[key], targetId);
      if (result !== null) {
        return result;
      }
    }
  }

  // If the target ID is not found at this level or any deeper level, return null
  return null;
};

export default findObjectById;
