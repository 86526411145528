import React from 'react';
import PropTypes from 'prop-types';
import styles from './NonStyledButton.module.less';

const NonStyledButton = ({ className, onClick, children }) => {
  return (
    <div className={styles.buttonWrapper}>
      <button
        type="button"
        className={`${styles.button} ${className}`}
        onClick={onClick}
        data-testid="NonStyledButton--div"
        data-test="NonStyledButton"
      >
        {children}
      </button>
    </div>
  );
};

NonStyledButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

NonStyledButton.defaultProps = {
  className: '',
  onClick: () => {
    // default func
  },
};

export default NonStyledButton;
