import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import chevron from '../../assets/icon-chevron-blue.svg';
import tick from '../../assets/icon-tick.svg';

interface TFFilterButtonProps {
  id: string;
  label: string;
  handleClick: (val: { [x: number]: boolean }) => void;
  options: { title: string; count: number; selected?: boolean; value?: string }[];
  handleClear: () => void;
  width?: string;
}

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: ${({ theme }): string => theme.colours.brandAccentBlue};
  height: 30px;
  border-radius: 20px;
  padding: 0 12px;
  cursor: pointer;
  border: 1px solid ${({ theme }): string => theme.colours.brandAccentBlue};
`;

const ChevronIcon = styled.img`
  transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : '')};
  height: 10px;
  margin-left: 6px;
`;

const DropdownOptions = styled.div`
  position: absolute;
  right: ${({ showLeft }): string => (showLeft ? '0' : 'auto')};
  top: 40px;
  width: ${({ width }): string => width || '200px'};
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  background-color: ${({ theme }): string => theme.colours.snow};
  border: ${({ theme }): string => `solid 2px ${theme.colours.white}`};
  padding: 10px 12px;
  border-radius: 4px;
  box-shadow: ${({ theme }): string => `0 0 10px 0 ${theme.colours.black10Alpha}`};
  @media screen and (max-width: 451px) {
    max-width: 250px;
  }
`;

const Box = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 4px;
  border: ${({ checked }): string => (checked ? 'solid 1px #126fd6' : 'solid 1px rgba(36, 45, 65, 0.2)')};
  background-color: ${({ checked }): string => (checked ? '#126fd6' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-shrink: 0;
  &:focus {
    outline: none;
  }
`;

const OptionText = styled.span`
  @media screen and (max-width: 451px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }): string => theme.colours.softGray};
    color: ${({ theme }): string => theme.colours.blue};
  }
  ${({ selected, theme }: { selected?: boolean; theme: any }): string =>
    selected &&
    `
    background-color: ${theme.colours.blue};
    color: ${theme.colours.white};
  `}
`;

const Line = styled.div`
  height: 1px;
  background-color: #e8e8e8;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const TFFilterButton: React.FC<TFFilterButtonProps> = ({ id, label, options, handleClick, width, handleClear }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const { formatMessage } = useIntl();
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);
  let showLeft = false;
  const rect = buttonRef?.current?.getBoundingClientRect();
  if (rect) {
    const { right } = rect;
    if (window.innerWidth / 2 < right) showLeft = true;
  }

  useEffect(() => {
    if (options) setFilteredOptions(options);
  }, [options]);

  function useOutsideAlerter(wrapper, button): void {
    useEffect(() => {
      function handleClickOutside(event): void {
        if (
          wrapper.current &&
          !wrapper.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      }

      function handleEscapeKey(event): void {
        if (event.keyCode === 27) {
          setIsOpen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);

      return (): void => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }, [wrapper, button]);
  }

  useOutsideAlerter(wrapperRef, buttonRef);

  const handleSelectOption = (option): void => {
    handleClick({ [option.value]: !option.selected });
  };

  return (
    <ButtonContainer ref={buttonRef}>
      <StyledButton type="button" data-testid={`StyledButton--Button-${id}`} onClick={(): void => setIsOpen(!isOpen)}>
        {label}
        <ChevronIcon src={chevron} isOpen={isOpen} alt="dropdown icon" />
      </StyledButton>
      {isOpen && (
        <DropdownOptions width={width} ref={wrapperRef} showLeft={showLeft} data-testid={`TFDropdown-Options-${id}`}>
          {filteredOptions.map((option) => (
            <DropdownOption
              key={option.title}
              data-testid={`TFDropdown-Option-${option.title}-${id}`}
              onClick={(): void => handleSelectOption(option)}
            >
              <Box checked={option.selected}>{option.selected ? <img src={tick} alt="tick" /> : null}</Box>
              <OptionText>{`${option.title} (${option.count})`}</OptionText>
            </DropdownOption>
          ))}
          <Line />
          <ActionWrapper>
            <Button
              primary={false}
              onClick={(): void => {
                handleClear();
              }}
            >
              {formatMessage({ id: 'form.button.clear' })}
            </Button>
          </ActionWrapper>
        </DropdownOptions>
      )}
    </ButtonContainer>
  );
};

export default TFFilterButton;
