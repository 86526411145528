import React from 'react';
import styled from 'styled-components';
import ToolTip from '../NewTooltip/NewTooltip';

const Label = styled.label`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${({ theme }): string => theme.colours.graphite};
  align-items: center;
  margin-bottom: 4px;
`;

interface TFFormLabelProps {
  id: string;
  label: string;
  optional?: boolean;
  tooltip?: string;
}

const TFFormLabel: React.FC<TFFormLabelProps> = ({ id, label, optional, tooltip }) => {
  return (
    <Label data-testid={`TFFormLabel-${id}`} htmlFor={id}>
      {`${label}`} {optional ? ' (optional)' : ''}
      {tooltip && <ToolTip text={tooltip} />}
    </Label>
  );
};

export default TFFormLabel;
