import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface HoursMinsInputProps {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HoursWrapper = styled.div`
  display: flex;
  margin-right: 2px;
  align-items: center;
`;

const StyledInput = styled.input`
  width: ${({ mins }): string => (mins ? '43px' : '55px')};
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  padding: 11px;
  margin-right: 2px;
`;

const HoursMinsInput: React.FC<HoursMinsInputProps> = ({ value, onChange, onBlur }) => {
  const [hours, setHours] = useState('');
  const [mins, setMins] = useState('');

  const handleHoursChange = (hoursValue: string): void => {
    setHours(hoursValue);
    if (hoursValue) {
      const newTime = `${hoursValue}.${mins}`;
      onChange(newTime);
    }
  };

  const handleMinsChange = (minsValue: string): void => {
    setMins(minsValue);
    if (minsValue) {
      const newTime = `${hours}.${minsValue}`;
      onChange(newTime);
    }
  };

  useEffect(() => {
    if (value) {
      const hoursValue = value.split('.')[0];
      const minsValue = value.split('.')[1];
      setHours(hoursValue);
      setMins(minsValue);
    }
  }, [value]);

  return (
    <InputWrapper data-testid="HoursMinsInput--InputWrapper">
      <HoursWrapper data-testid="HoursMinsInput--HoursWrapper">
        <StyledInput
          type="number"
          value={hours}
          onChange={(e): void => handleHoursChange(e.target.value)}
          min={0}
          onBlur={onBlur}
          data-testid="HoursMinsInput--HoursInput"
        />
        <span>hrs</span>
      </HoursWrapper>
      <StyledInput
        mins
        type="number"
        value={mins}
        onChange={(e): void => handleMinsChange(e.target.value)}
        min={0}
        onBlur={onBlur}
        data-testid="HoursMinsInput--MinsInput"
      />
      <span>mins</span>
    </InputWrapper>
  );
};

export default HoursMinsInput;
