/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node, Text } from 'slate';

type HTMLStuff = {
  rows: any;
  cols: any;
  style: any;
  width: any;
};

const slateColours = {
  blue: 'var(--rich-text-blue)',
  black: 'var(--rich-text-black)',
  yellow: 'var(--rich-text-yellow)',
};

type ExtraStuff = HTMLStuff & {
  id?: string;
  type: string;
  text?: string;
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  textColor?: string;
  children: Array<MyNode>;
  fileName: string;
};
export type MyNode = Node & ExtraStuff;

const getAttributes = (node: MyNode): string => {
  let attrs = '';
  attrs += node.id ? ` id="${node.id}"` : '';
  attrs += node.rows ? ` rows="${node.rows}"` : '';
  attrs += node.cols ? ` cols="${node.cols}"` : '';
  attrs += node.style ? ` style="${node.style}"` : '';
  attrs += node.width ? ` width="${node.width}"` : '';
  return attrs;
};
function nodeText2String(node: MyNode): string {
  let buff: string = node.text as string;
  if (node?.bold) {
    buff = `<strong>${buff}</strong>`;
  }
  if (node?.underline) {
    buff = `<u>${buff}</u>`;
  }
  if (node?.italic) {
    buff = `<em>${buff}</em>`;
  }

  if (node?.textColor) {
    let color = 'black';
    switch (node?.textColor) {
      case slateColours.blue:
        color = `color: blue`;
        buff = `<span style="${color}">${buff}</span>`;
        break;
      case slateColours.yellow:
        color = 'color: yellow';
        buff = `<span style="${color}">${buff}</span>`;
        break;
      case slateColours.black:
        color = 'color: black';
        buff = `<span style="${color}">${buff}</span>`;
        break;
      default:
        return buff === '\n' ? '' : buff;
    }
  } else if (
    node &&
    Object.keys(node).filter((key) => key.startsWith('textColour') || key.startsWith('highlightColour'))?.length
  ) {
    const keys = Object.keys(node);
    const colourText = keys.filter((key) => key.startsWith('textColour'));
    const highlightColour = keys.filter((key) => key.startsWith('highlightColour'));
    const colour = colourText[0]?.split('Colour')[1];
    const highlight = highlightColour[0]?.split('Colour')[1];
    const color = `color: ${colour}`;
    let background = `background-color: #fff`;
    if (highlight) background = `background-color: ${highlight}`;
    buff = `<span style="${color}; ${background}">${buff}</span>`;
    return buff === '\n' ? '' : buff;
  }

  return buff === '\n' ? '' : buff;
}

const serialize = (node: MyNode): string => {
  if (Text.isText(node)) return nodeText2String(node);

  const strChildren = node.children.map(serialize).join('');
  const attrs = getAttributes(node);

  switch (node.type) {
    case 'insert':
      return `<ins${attrs}>${strChildren}</ins>`;
    case 'delete':
      return `<del${attrs}>${strChildren}</del>`;
    case 'paragraph':
      return `<p${attrs}>${strChildren}</p>`;
    case 'p':
      return `<p${attrs}>${strChildren}</p>`;
    case 'bulleted-list':
      return `<ul${attrs}>${strChildren}</ul>`;
    case 'numbered-list':
      return `<ol${attrs}>${strChildren}</ol>`;
    case 'list-item':
      return `<li${attrs}>${strChildren}</li>`;
    case 'table':
      return `<table${attrs} cols=${node.cols} rows=${node.rows}><tbody>${strChildren}</tbody></table>`;
    case 'table-row':
      return `<tr${attrs}>${strChildren}</tr>`;
    case 'table-cell':
      return `<td${attrs}>${strChildren}</td>`;
    case 'indentation':
      return `<div class="indentation">${strChildren}</div>`;
    case 'image-reference':
      return `<img src="${node.fileName}" alt="${node?.description || ''}">`;
    default:
      return `${strChildren}`;
  }
};

export default serialize;
