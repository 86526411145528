/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { PureComponent, forwardRef } from 'react';
import PCancelable from 'p-cancelable';
import { Select, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getAirport, getAirports } from '../../services/api';
import styles from './index.module.less';

const { Option } = Select;

class SelectAirport extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onChangeObj: PropTypes.func.isRequired,
    searchDelay: PropTypes.number,
    value: PropTypes.number,
    intl: PropTypes.object.isRequired,
    forwardedRef: PropTypes.func.isRequired,
    width: PropTypes.string,
  };

  static defaultProps = {
    searchDelay: 500,
    value: undefined,
    width: '',
  };

  state = {
    isFetchingAirport: false,
    loading: !!this.props.value,
    options: [],
    value: { key: '' },
  };

  componentDidMount() {
    this.updateAirportValue();
  }

  componentDidUpdate() {
    this.updateAirportValue();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    if (this.queryAirports) this.queryAirports.cancel();
    if (this.getAirport) this.getAirport.cancel();
  }

  onChange = (value) => {
    this.setState({ value });
    const id = parseInt(value.key, 10);
    if (this.props.onChange) {
      this.props.onChange(id);
    }
    if (this.props.onChangeObj) {
      this.props.onChangeObj(this.state.options.find((ap) => ap.id === id));
    }
  };

  onSearch(e) {
    const { searchDelay } = this.props;
    const query = e.trim();
    if (query === '') return;

    // Debounce searching
    clearTimeout(this.timeout);
    if (this.queryAirports) this.queryAirports.cancel();
    this.timeout = setTimeout(() => {
      this.setState({ loading: true });
      this.queryAirports = PCancelable.fn(getAirports)(query);
      this.queryAirports
        .then((airports) => {
          // If airport not found respond error
          if (airports === 'fail') {
            this.setState({ loading: false, value: '' });
            message.error('No airports matching your search criteria were found, please try again');
          }
          // Set state if request is successful
          if (!Array.isArray(airports)) return;
          this.setState({ options: airports, loading: false });
        })
        .catch(() => {
          // do nothing
        });
    }, searchDelay);
  }

  formatAirport = (ap = {}) => `${ap.ident} - ${ap.name}`;

  // Update state value in accordance with prop value
  updateAirportValue = async () => {
    if (this.props.value !== undefined && !this.state.isFetchingAirport) {
      if (this.state.value.key !== this.props.value) {
        await new Promise((resolve) => {
          this.setState(
            {
              isFetchingAirport: true,
              loading: true,
            },
            resolve,
          );
        });
        this.getAirport = PCancelable.fn(getAirport)(this.props.value);
        const ap = await this.getAirport.catch(() => {
          /* do nothing */
        });
        this.setState({
          value: {
            label: this.formatAirport(ap),
            key: this.props.value,
          },
          loading: false,
          isFetchingAirport: false,
        });
        if (this.props.onChangeObj) {
          this.props.onChangeObj(ap);
        }
      }
    } else if (this.state.value.key !== '') {
      this.setState({ value: { key: '' } });
    }
  };

  render() {
    const {
      intl: { formatMessage },
      forwardedRef,
      width,
    } = this.props;
    const { options, loading, value } = this.state;
    const displayOptions = options.map((ap) => <Option key={ap.id}>{this.formatAirport(ap)}</Option>);
    return (
      <Select
        ref={forwardedRef}
        showSearch
        className={styles.selectAirport}
        filterOption={false}
        placeholder={formatMessage({ id: 'form.placeholder.selectAirport' })}
        notFoundContent={formatMessage({ id: 'form.tooltip.startTypingAirports' })}
        loading={loading}
        labelInValue
        onSearch={(e) => this.onSearch(e)}
        onChange={this.onChange}
        value={value}
        style={{ width: width || '100%', height: '34px' }}
        data-test="selectAirport"
      >
        {displayOptions}
      </Select>
    );
  }
}

const ComposedSelectAirport = injectIntl(SelectAirport);

export default forwardRef((props, ref) => <ComposedSelectAirport {...props} forwardedRef={ref} />);
