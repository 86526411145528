import { Button, Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import FiltersSidebarDraftModal from '../../../FiltersSidebar/FiltersSidebarDraftModal';
import filtersIcon from '../../../FiltersDrawer/assets/filters.svg';
import styles from './ModalFiltersDrawer.module.less';

class ModalFiltersDrawer extends Component {
  static propTypes = {
    aircraft: PropTypes.object,
    onFilterChange: PropTypes.func.isRequired,
    onFiltersClear: PropTypes.func.isRequired,
    mxItems: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  };

  static defaultProps = {
    aircraft: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      aircraft,
      filters,
      intl: { formatMessage },
      mxItems,
      onFilterChange,
      onFiltersClear,
    } = this.props;
    const { visible } = this.state;

    return (
      <>
        <Button type="link" onClick={this.showDrawer} data-test="filtersButton">
          <img src={filtersIcon} alt="filtersIcon" className={styles.filtersIcon} />{' '}
          {formatMessage({ id: 'title.filters' })}
        </Button>
        <Drawer
          title={formatMessage({ id: 'title.filters' })}
          placement="right"
          width={270}
          closable
          className={styles.filtersDrawer}
          onClose={this.closeDrawer}
          visible={visible}
          data-test="filtersDrawer"
        >
          <FiltersSidebarDraftModal
            aircraft={aircraft}
            allItems={mxItems}
            activeFilters={filters}
            onFilterChange={(filterKey, groupKey) => onFilterChange(filterKey, groupKey)}
            onFiltersClear={(groupKey) => onFiltersClear(groupKey)}
            data-test="filtersSidebar"
          />
        </Drawer>
      </>
    );
  }
}

export default injectIntl(ModalFiltersDrawer);
