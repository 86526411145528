import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  revertChanges as reduxRevertChanges,
  setDrawerChanges,
} from '../../models/drawer';
import { getTripUpdate } from '../../services/api';
import { deleteUpdate, getAircraftTripEntries, updateEntry } from '../../models/trips/actions';
import { checkNumberIsTwoDecimalPlaces } from '../../utils/utils';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import HistorySection from '../HistoryTable/HistorySection';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import DrawerInfo from './DrawerInfo';
import TripUpdateAirframeSection from './TripUpdateAirframeSection';
import TripUpdateDetails from './TripUpdateDetails';
import TripUpdateEngineSection from './TripUpdateEnginesSection';
import TripUpdateHeader from './TripUpdateHeader';
import TripUpdateOilSection from './TripUpdateOilSection';
import TripUpdateHydraulicSection from './TripUpdateHydraulicSection';
import IDGUpliftsSection from './IDGUpliftsSection';
import BUGUpliftsSection from './BUGUpliftSection';
import TripUpdateFuelSection from './TripUpdateFuelSection';

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  width: 100%;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

const SaveWrapper = styled.div`
  margin-right: 16px;
`;

const CardWrapper = styled.div`
  margin-bottom: 12px;
`;

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
`;

const Text = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`;

const ModalButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .cancelButton {
    height: 30px;
    border-radius: 15px;
    margin-left: 20px;
  }
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 20px;
`;

const TripUpdateDrawer: React.FC = () => {
  const [tripUpdateData, setTripUpdateData] = useState(null);
  const [originalTripUpdateData, setOriginalTripUpdateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode, revertChanges, drawerId },
    trips: { tripsMap },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 451;

  const displayIDGSection = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.enabled;
  const IDGRequired = aircraftMap.get(id)?.standard_fields?.oil_uplifts?.required;
  const IDGRename = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.name_override;
  const IDGCount = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.count;
  const displayBUGSection = aircraftMap.get(id)?.standard_fields?.back_up_generator?.enabled;
  const BUGRequired = aircraftMap.get(id)?.standard_fields?.oil_uplifts?.required;
  const BUGRename = aircraftMap.get(id)?.standard_fields?.back_up_generator?.name_override;
  const BUGCount = aircraftMap.get(id)?.standard_fields?.back_up_generator?.count;

  const updateTripUpdateData = (changes: { value: any; key: string }[]): void => {
    const newData = JSON.parse(JSON.stringify(tripUpdateData));
    changes.forEach(({ value, key }) => {
      newData[`${key}`] = value;
    });
    setTripUpdateData(newData);
  };

  const validateTripUpdateData = (): boolean => {
    let isValid = true;
    if (tripUpdateData?.fluid_uplifts_attributes) {
      tripUpdateData.fluid_uplifts_attributes.forEach((uplift, index) => {
        if (uplift?.uplift && !checkNumberIsTwoDecimalPlaces(uplift?.uplift)) {
          isValid = false;
          dispatch(
            addToast({
              payload: {
                title: `${uplift?.fluid_type} uplift number ${index + 1}, must be to 2 decimal places`,
                type: ToastTypes.ERROR,
                category: ToastCategories.FLAG,
                message: '',
              },
            }),
          );
        }
        if (uplift?.initial_value && !checkNumberIsTwoDecimalPlaces(uplift?.initial_value)) {
          isValid = false;
          dispatch(
            addToast({
              payload: {
                title: `${uplift?.fluid_type} uplift number ${index + 1}, must be to 2 decimal places`,
                type: ToastTypes.ERROR,
                category: ToastCategories.FLAG,
                message: '',
              },
            }),
          );
        }
        if (uplift?.carried_forwards && !checkNumberIsTwoDecimalPlaces(uplift?.carried_forwards)) {
          isValid = false;
          dispatch(
            addToast({
              payload: {
                title: `${uplift?.fluid_type} uplift number ${index + 1}, must be to 2 decimal places`,
                type: ToastTypes.ERROR,
                category: ToastCategories.FLAG,
                message: '',
              },
            }),
          );
        }
        if (!uplift?.uplift_unit) {
          isValid = false;
          dispatch(
            addToast({
              payload: {
                title: `${uplift?.fluid_type} uplift number ${index + 1}, unit is required`,
                type: ToastTypes.ERROR,
                category: ToastCategories.FLAG,
                message: '',
              },
            }),
          );
        }
        if ((IDGRequired && uplift?.fluid_type === 'idg') || (BUGRequired && uplift?.fluid_type === 'bug')) {
          if (!uplift?.uplift) {
            isValid = false;
            dispatch(
              addToast({
                payload: {
                  title: `${uplift?.fluid_type} uplift number ${index + 1}, uplift is required`,
                  type: ToastTypes.ERROR,
                  category: ToastCategories.FLAG,
                  message: '',
                },
              }),
            );
          }
        }
      });
    }
    if (
      IDGRequired &&
      IDGCount &&
      IDGCount > 0 &&
      (!tripUpdateData ||
        !tripUpdateData?.fluid_uplifts_attributes ||
        !tripUpdateData?.fluid_uplifts_attributes?.filter((uplift) => uplift?.fluid_destination === 'idg')?.length) &&
      !tripUpdateData?.fluid_uplifts?.filter((item) => item?.fluid_destination === 'idg')?.length
    ) {
      isValid = false;
      dispatch(
        addToast({
          payload: {
            title: `${IDGRename || 'IDG'} uplift is required`,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
    if (
      BUGRequired &&
      BUGCount &&
      BUGCount > 0 &&
      (!tripUpdateData ||
        !tripUpdateData?.fluid_uplifts_attributes ||
        !tripUpdateData?.fluid_uplifts_attributes?.filter((uplift) => uplift?.fluid_destination === 'bug')?.length) &&
      !tripUpdateData?.fluid_uplifts?.filter((item) => item?.fluid_destination === 'bug')?.length
    ) {
      isValid = false;
      dispatch(
        addToast({
          payload: {
            title: `${BUGRename || 'BUG'} uplift is required`,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
    return isValid;
  };

  const handleSaveClick = (): void => {
    if (validateTripUpdateData()) {
      setLoading(true);
      dispatch(
        updateEntry({
          payload: {
            id: drawerId,
            trip: tripUpdateData,
          },
        }),
      );
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const handleCancelClick = (): void => {
    if (mode === 'add' && !_.isEqual(tripUpdateData, originalTripUpdateData)) {
      setModalVisible(true);
    } else if (mode === 'add') {
      dispatch(deleteUpdate({ payload: { id: tripUpdateData.id } }));
      dispatch(changeDrawerVisibility({ payload: false }));
    } else {
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const sanitizeData = (data: any): object => {
    const sanitizedData = data;

    // These fields cannot be updated and should not be returned when saving
    delete sanitizedData.carried_forwards.airframe_days;
    delete sanitizedData.carried_forwards.airframe_last_flown_date;

    return sanitizedData;
  };

  useEffect(() => {
    const getTripUpdateData = async (): Promise<void> => {
      setLoading(true);
      const res = await getTripUpdate(drawerId);
      Promise.all([res]).then((values) => {
        const sanitizedData = sanitizeData(values[0]);

        setTripUpdateData(sanitizedData);
        setOriginalTripUpdateData(sanitizedData);
        setLoading(false);
      });
    };
    if (drawerId) {
      getTripUpdateData();
    }
  }, [drawerId, tripsMap]);

  useEffect(() => {
    if (!_.isEqual(tripUpdateData, originalTripUpdateData)) {
      dispatch(setDrawerChanges({ payload: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripUpdateData]);

  useEffect(() => {
    if (revertChanges) {
      setTripUpdateData(originalTripUpdateData);
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revertChanges]);

  return (
    <DrawerWrapper data-testid="TripUpdateDrawer-DrawerWrapper">
      <Loading loading={loading} />
      <TripUpdateHeader trip={tripUpdateData} />
      <TripUpdateDetails trip={tripUpdateData} updateTripUpdateData={updateTripUpdateData} />
      <DrawerInfo />
      <CardWrapper data-testid="TripUpdateDrawer-AirframeSectionWrapper">
        <TripUpdateAirframeSection
          trip={tripUpdateData}
          originalTripUpdateData={originalTripUpdateData}
          updateTripUpdateData={updateTripUpdateData}
        />
      </CardWrapper>
      <CardWrapper data-testid="TripUpdateDrawer-EngineSectionWrapper">
        <TripUpdateEngineSection
          trip={tripUpdateData}
          originalTripUpdateData={originalTripUpdateData}
          updateTripUpdateData={updateTripUpdateData}
        />
      </CardWrapper>
      <CardWrapper data-testid="TripUpdateDrawer-FuelSectionWrapper">
        <TripUpdateFuelSection trip={tripUpdateData} updateTripUpdateData={updateTripUpdateData} />
      </CardWrapper>
      <CardWrapper data-testid="TripUpdateDrawer-OilSectionWrapper">
        <TripUpdateOilSection trip={tripUpdateData} updateTripUpdateData={updateTripUpdateData} />
      </CardWrapper>
      {displayIDGSection ? (
        <CardWrapper data-testid="TripUpdateDrawer-IDGSectionWrapper">
          <IDGUpliftsSection trip={tripUpdateData} updateTripUpdateData={updateTripUpdateData} />
        </CardWrapper>
      ) : null}
      {displayBUGSection ? (
        <CardWrapper data-testid="TripUpdateDrawer-BUGSectionWrapper">
          <BUGUpliftsSection trip={tripUpdateData} updateTripUpdateData={updateTripUpdateData} />
        </CardWrapper>
      ) : null}
      <CardWrapper data-testid="TripUpdateDrawer-HydraulicSectionWrapper">
        <TripUpdateHydraulicSection trip={tripUpdateData} updateTripUpdateData={updateTripUpdateData} />
      </CardWrapper>
      {mode === 'view' ? <HistorySection versions={tripUpdateData?.versions} title="trip update" /> : null}
      {mode !== 'view' ? (
        <ButtonWrapper data-testid="TripUpdateDrawer-ButtonWrapper">
          <SaveWrapper>
            <Button height="32px" onClick={(): void => handleSaveClick()}>
              Save
            </Button>
          </SaveWrapper>
          <Button height="32px" primary={false} onClick={(): void => handleCancelClick()}>
            Cancel
          </Button>
        </ButtonWrapper>
      ) : null}
      <Modal width={420} isOpen={modalVisible} handleClose={(): void => setModalVisible(false)}>
        <ModalWrapper>
          <Title>Are you sure you want to cancel?</Title>
          <Text>
            Are you sure you want to leave this page without saving your changes? If you leave before saving, your
            changes will be lost.
          </Text>
          <ModalButtonWrapper>
            <SubmitButtonWrapper>
              <Button
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                onClick={(): void => {
                  dispatch(
                    deleteUpdate({ payload: { id: tripUpdateData.id } }, () =>
                      dispatch(getAircraftTripEntries({ payload: { id } })),
                    ),
                  );
                  dispatch(changeDrawerVisibility({ payload: false }));
                }}
              >
                {isMobile ? 'Continue' : 'Discard changes'}
              </Button>
            </SubmitButtonWrapper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setModalVisible(false)}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>
    </DrawerWrapper>
  );
};

export default TripUpdateDrawer;
