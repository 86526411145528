/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ItemWrapper, Label } from '../../CommonStyledComponents/CommonStyledComponents';
import TFInput from '../../TFInput/TFInput';
import BaseModalFields from './BaseModalFields';

const MXModalSCHContents = ({ updateItemData, item }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ItemWrapper marginBottom="16px" data-testid="MxModalSCHContents--TaskDescription">
        <Label edit>{formatMessage({ id: 'form.labels.taskDescription' })}</Label>
        <TFInput id="" value={item?.name} onChange={(e) => updateItemData([{ value: e.target.value, key: 'name' }])} />
      </ItemWrapper>
      <BaseModalFields item={item} updateItemData={updateItemData} />
    </>
  );
};

MXModalSCHContents.propTypes = {
  updateItemData: PropTypes.func.isRequired,
  item: PropTypes.object,
};

MXModalSCHContents.defaultProps = {
  item: null,
};

export default MXModalSCHContents;
