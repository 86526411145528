import { Row, Dropdown, Menu, Icon } from 'antd';
import React, { useState, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { Aircraft, SimpleAircraft } from '../../models/aircraft';
import { add, addFavouriteAircraftId, removeFavouriteAircraftId } from '../../models/userSettings/actions';
import { saveAllAircraft } from '../../models/aircraft/actions';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import { addToFavourites, removeFromFavourites } from '../../services/api';
import { queryAircraftByOperator, querySimpleAircraftByOperator, queryFavouriteAircraft } from '../../services/apiNew';
import TFFilterButton from '../../components/TFFilterButton';
import EmptyState from '../../components/EmptyState/EmptyState';
import FleetIcon from '../../assets/topNav/title-fleet.svg';
import GridIconDefault from '../../assets/icon-grid-view-default.svg';
import GridIconSelected from '../../assets/icon-grid-view-selected.svg';
import TableIconDefault from '../../assets/icon-table-view-default.svg';
import TableIconSelected from '../../assets/icon-table-view-selected.svg';
import EmptyStateAircraft from '../../assets/emptyState/empty-state-aircraft-not-found.svg';
import TypedAircraftCard from '../../components/AircraftCard/TypedAircraftCard';

const ToggleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const OperatorDiv = styled.div`
  margin-bottom: 24px;
`;

const CardsDiv = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 976px) {
    grid-template-columns: 1fr;
  }
`;

const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: 1440px) {
  }
  @media screen and (max-width: 991px) {
    row-gap: 8px;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 24px;
  @media screen and (max-width: 1440px) {
  }
  @media screen and (max-width: 991px) {
    row-gap: 8px;
    justify-content: center;
  }
`;

const FilterText = styled.span`
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const GridImg = styled.img`
  cursor: pointer;
`;

const TableImg = styled.img`
  cursor: pointer;
`;

const SearchWrapper = styled.div`
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const SortByLabel = styled.span`
  margin-right: 4px;
  font-weight: 600;
`;

const Fleet: React.FC = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [serviceabilityFilters, setServiceabilityFilters] = useState({
    ok: false,
    critical: false,
    overdue: false,
    unknown: false,
  });
  const [aircraftStatusFilters, setAircraftStatusFilters] = useState({
    archived: false,
    deleted: false,
    demo: false,
    locked: false,
    'mel only': false,
    setup: false,
  });
  const [flightStatusFilters, setFlightStatusFilters] = useState({
    on_ground: false,
    in_flight: false,
  });
  const [operatorFilter, setOperatorFilter] = useState<string[] | null>([]);
  const [typeFilter, setTypeFilter] = useState<string[] | null>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [hasSetAircraftMap, setHasSetAircraftMap] = useState<boolean>(false);
  const [resetSearch, setResetSearch] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('asc');
  const [filteredAircraftQueries, setfilteredAircraftQueries] = useState<[Aircraft[]] | []>();
  const [filteredFavourites, setFilteredFavourites] = useState<Aircraft[]>();
  const prevSearchTextRef = useRef<string | undefined>();
  const prevServiceabilityRef = useRef<any | undefined>();
  const prevAircraftStatusRef = useRef<any | undefined>();
  const prevFlightStatusRef = useRef<any | undefined>();
  const prevOperatorRef = useRef<any | undefined>();
  const prevTypeRef = useRef<any | undefined>(typeFilter);
  const prevSortByRef = useRef<any | undefined>();
  const prevData = useRef<any | undefined>();
  const {
    userSettings: { details },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { isSuccess, data } = useQuery({
    queryKey: ['aircraft', 'favourites'],
    queryFn: () => queryFavouriteAircraft(),
    refetchInterval: 60000,
  });

  const simpleAircraftQueries = useQueries({
    queries: operators.map((operator) => ({
      queryKey: ['getSimpleAircraftByOperators', operator.id],
      queryFn: () => querySimpleAircraftByOperator(operator.id),
    })),
  });

  const aircraftQueries = useQueries({
    queries: operators.map((operator) => ({
      queryKey: ['aircraft', operator.id],
      queryFn: () => queryAircraftByOperator(operator.id),
    })),
  });

  const addToFavouritesMutation = useMutation({
    mutationFn: (aircraft: Aircraft) => {
      return addToFavourites(aircraft.id);
    },
    onSuccess: (_, context) => {
      queryClient.invalidateQueries(['aircraft', 'favourites']);
      dispatch(addFavouriteAircraftId({ aircraftId: context.id }));
    },
  });

  const removeFromFavouritesMutation = useMutation({
    mutationFn: (aircraft: Aircraft) => {
      return removeFromFavourites(aircraft.id);
    },
    onSuccess: (_, context) => {
      queryClient.invalidateQueries(['aircraft', 'favourites']);
      dispatch(removeFavouriteAircraftId({ aircraftId: context.id }));
    },
  });

  useEffect(() => {
    const allLoaded = aircraftQueries.every((query) => query.isSuccess);
    if (!allLoaded) return;
    const localAircraft: any = [];
    const searchChanged = searchText !== prevSearchTextRef.current;
    const serviceabilityChanged = serviceabilityFilters !== prevServiceabilityRef.current;
    const aircraftStatusChanged = aircraftStatusFilters !== prevAircraftStatusRef.current;
    const flightStatusChanged = flightStatusFilters !== prevFlightStatusRef.current;
    const operatorChanged = operatorFilter !== prevOperatorRef.current;
    const typeChanged = typeFilter !== prevTypeRef.current;
    const sortByChanged = sortBy !== prevSortByRef.current;
    const dataChanged = data?.data !== prevData.current;
    if (
      searchChanged ||
      serviceabilityChanged ||
      aircraftStatusChanged ||
      flightStatusChanged ||
      operatorChanged ||
      typeChanged ||
      sortByChanged ||
      dataChanged
    ) {
      const serviceabilityFiltersActive = Object.values(serviceabilityFilters).some((value) => value);
      const aircraftStatusFiltersActive = Object.values(aircraftStatusFilters).some((value) => value);
      const flightStatusFiltersActive = Object.values(flightStatusFilters).some((value) => value);
      const operatorFilterActive = operatorFilter.length > 0;
      const typeFilterActive = typeFilter.length > 0;
      const searchTextActive = searchText.length > 0;
      operators.forEach((operator, index) => {
        let operatorAircraft: Aircraft[] = aircraftQueries[index].data?.data;
        if (aircraftQueries[index].isSuccess) {
          // filter by serviceability
          if (serviceabilityFiltersActive) {
            const selectedServiceabilityFilters = Object.keys(serviceabilityFilters).filter(
              (key) => serviceabilityFilters[key],
            );
            operatorAircraft = operatorAircraft.filter((aircraft) => {
              return selectedServiceabilityFilters.includes(aircraft.maintenance_status);
            });
          }
          // filter by aircraftStatus
          if (aircraftStatusFiltersActive) {
            const selectedAircraftStatusFilters = Object.keys(aircraftStatusFilters).filter(
              (key) => aircraftStatusFilters[key],
            );
            operatorAircraft = operatorAircraft.filter((aircraft) => {
              // handle locked as doesnt live on billing_status
              if (selectedAircraftStatusFilters.includes('locked')) {
                const isAircraftLocked = aircraft.locked;
                return (
                  selectedAircraftStatusFilters.includes(aircraft.billing_status.toLowerCase()) || isAircraftLocked
                );
              }
              return selectedAircraftStatusFilters.includes(aircraft.billing_status.toLowerCase());
            });
          }
          // filter by flightStatus
          if (flightStatusFiltersActive) {
            const selectedFlightStatusFilters = Object.keys(flightStatusFilters).filter(
              (key) => flightStatusFilters[key],
            );
            operatorAircraft = operatorAircraft.filter((aircraft) => {
              return selectedFlightStatusFilters.includes(aircraft.flight_status);
            });
          }
          // filter by operator
          if (operatorFilterActive) {
            operatorAircraft = operatorAircraft.filter((aircraft) => {
              return operatorFilter.includes(aircraft.operator_id);
            });
          }
          // filter by type
          if (typeFilterActive) {
            operatorAircraft = operatorAircraft.filter((aircraft) => {
              return typeFilter.includes(aircraft.aircraft_type.id);
            });
          }
          // filter by searchText
          if (searchTextActive) {
            operatorAircraft = operatorAircraft.filter((aircraft) => {
              return aircraft.registration.toLowerCase().includes(searchText.toLowerCase());
            });
          }
          if (sortBy === 'asc') {
            operatorAircraft.sort((a, b) => (a.registration > b.registration ? 1 : -1));
          }
          if (sortBy === 'desc') {
            operatorAircraft.sort((a, b) => (a.registration < b.registration ? 1 : -1));
          }
          localAircraft.push(operatorAircraft);
        }
      });
      const filteredAircraftIds = localAircraft.flat().map((aircraft) => aircraft.id);
      const test = data?.data?.filter((aircraft: Aircraft) => filteredAircraftIds.includes(aircraft.id));
      prevSearchTextRef.current = searchText;
      prevServiceabilityRef.current = serviceabilityFilters;
      prevAircraftStatusRef.current = aircraftStatusFilters;
      prevFlightStatusRef.current = flightStatusFilters;
      prevOperatorRef.current = operatorFilter;
      prevTypeRef.current = typeFilter;
      prevSortByRef.current = sortBy;
      prevData.current = data?.data;
      setfilteredAircraftQueries(localAircraft);
      setFilteredFavourites(test);
    }
  }, [
    operators,
    searchText,
    aircraftQueries,
    serviceabilityFilters,
    aircraftStatusFilters,
    flightStatusFilters,
    operatorFilter,
    sortBy,
    typeFilter,
    data,
    isSuccess,
  ]);

  const handleSwitchChange = (style: string): void => {
    const formData = {
      fleet_style: style,
    };
    dispatch(add({ payload: { form: formData, type: 'fleetStyle' } }));
  };

  const handleFavouriteButtonClick = (isFavourited, aircraft): void => {
    if (isFavourited) {
      removeFromFavouritesMutation.mutate(aircraft);
    } else {
      addToFavouritesMutation.mutate(aircraft);
    }
  };
  const renderSimpleAircraftCards = (simpleAircraftResponse): ReactNode => {
    const simpleAircraftData = simpleAircraftResponse.data as SimpleAircraft[];
    return simpleAircraftData.map((aircraft) => {
      return (
        <div key={aircraft.id}>
          <TypedAircraftCard
            aircraftId={aircraft.id}
            preAircraft={aircraft}
            clickable={aircraft.clickable}
            data-test="aircraftCardCol"
            data-testid="aircraftCardCol"
            handleFavouriteButtonClick={(isFavourited, ac): void => handleFavouriteButtonClick(isFavourited, ac)}
          />
        </div>
      );
    });
  };

  const renderFullAircraftCards = (fullAircraftResponse, isFavourite?): ReactNode => {
    const fullAircraftData = fullAircraftResponse as Aircraft[];
    return fullAircraftData?.map((aircraft) => {
      return (
        <div key={aircraft.id}>
          <TypedAircraftCard
            aircraftId={aircraft.id}
            aircraft={aircraft}
            preAircraft={aircraft}
            clickable={aircraft.clickable || isFavourite}
            data-test="aircraftCardCol"
            data-testid="aircraftCardCol"
            isFavourite={isFavourite}
            handleFavouriteButtonClick={(isFavourited, ac): void => handleFavouriteButtonClick(isFavourited, ac)}
          />
        </div>
      );
    });
  };

  const renderSortingControls = (): ReactNode => {
    const sortingMenu = (
      <>
        <Menu>
          <Menu.Item key="asc" onClick={(): void => setSortBy('asc')}>
            {formatMessage({ id: 'menu.registrationAZ' })}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="desc" onClick={(): void => setSortBy('desc')}>
            {formatMessage({ id: 'menu.registrationZA' })}
          </Menu.Item>
        </Menu>
      </>
    );
    return (
      <span>
        <SortByLabel>{formatMessage({ id: 'label.sortBy' })}:</SortByLabel>
        <Dropdown overlay={sortingMenu} trigger={['hover']}>
          <span>
            {sortBy === 'asc' ? 'Registration (A-Z)' : 'Registration (Z-A)'}
            <Icon type="caret-down" />
          </span>
        </Dropdown>
      </span>
    );
  };

  const resetFilters = (): void => {
    setServiceabilityFilters({
      ok: false,
      critical: false,
      overdue: false,
      unknown: false,
    });
    setAircraftStatusFilters({
      archived: false,
      deleted: false,
      demo: false,
      locked: false,
      'mel only': false,
      setup: false,
    });
    setFlightStatusFilters({
      on_ground: false,
      in_flight: false,
    });
    setOperatorFilter([]);
    setTypeFilter([]);
    setSearchText('');
    setResetSearch(!resetSearch);
  };

  const renderFilterControls = (): ReactNode => {
    const isStillLoading = aircraftQueries.some((query) => query.isLoading);
    if (isStillLoading) {
      return null;
    }
    const allAircraft = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < operators.length; i++) {
      if (aircraftQueries[i].data) {
        allAircraft.push(...aircraftQueries[i].data?.data);
      }
    }

    if (!hasSetAircraftMap) {
      dispatch(saveAllAircraft({ payload: allAircraft }));
      setHasSetAircraftMap(true);
    }

    const aircraftTypes = allAircraft.map((item) => {
      return item.aircraft_type;
    });
    aircraftTypes.sort((a, b) => (a.manufacturer > b.manufacturer ? 1 : -1));
    const uniqueIds = new Set();
    const uniqueAircraftTypes = aircraftTypes.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
    const TableStyle = details?.dash_preferences?.fleet_style === 'light';
    return (
      <FiltersRow>
        <FiltersWrapper>
          <FilterText>Filters:</FilterText>
          <TFFilterButton
            id="Serviceability"
            options={[
              {
                title: 'Serviceable',
                count: allAircraft.filter((item) => item.maintenance_status === 'ok').length,
                value: 'ok',
                selected: serviceabilityFilters.ok,
              },
              {
                title: 'Unserviceable',
                value: 'overdue',
                count: allAircraft.filter((item) => item.maintenance_status === 'overdue').length,
                selected: serviceabilityFilters.overdue,
              },
              {
                title: 'In tolerance',
                count: allAircraft.filter((item) => item.maintenance_status === 'critical').length,
                value: 'critical',
                selected: serviceabilityFilters.critical,
              },
              {
                title: 'Unknown',
                count: allAircraft.filter((item) => item.maintenance_status === 'unknown').length,
                value: 'unknown',
                selected: serviceabilityFilters.unknown,
              },
            ]}
            handleClick={(val): void => {
              setServiceabilityFilters((prevState) => ({
                ...prevState,
                ...val,
              }));
            }}
            handleClear={() => {
              setServiceabilityFilters({
                ok: false,
                critical: false,
                overdue: false,
                unknown: false,
              });
            }}
            label="Serviceability"
          />
          <TFFilterButton
            id="AircraftStatus"
            options={[
              {
                title: 'Archived',
                count: allAircraft.filter((item) => item.billing_status === 'Archived').length,
                value: 'archived',
                selected: aircraftStatusFilters.archived,
              },
              {
                title: 'Deleted',
                count: allAircraft.filter((item) => item.billing_status === 'Deleted').length,
                value: 'deleted',
                selected: aircraftStatusFilters.deleted,
              },
              {
                title: 'Demo',
                count: allAircraft.filter((item) => item.billing_status === 'Demo').length,
                value: 'demo',
                selected: aircraftStatusFilters.demo,
              },
              {
                title: 'Locked',
                count: allAircraft.filter((item) => item.locked === true).length,
                value: 'locked',
                selected: aircraftStatusFilters.locked,
              },
              {
                title: 'MEL Only',
                count: allAircraft.filter((item) => item.billing_status === 'MEL Only').length,
                value: 'mel only',
                selected: aircraftStatusFilters['mel only'],
              },
              {
                title: 'Setup',
                count: allAircraft.filter((item) => item.billing_status === 'Setup').length,
                value: 'setup',
                selected: aircraftStatusFilters.setup,
              },
            ]}
            handleClick={(val): void => {
              setAircraftStatusFilters((prevState) => ({
                ...prevState,
                ...val,
              }));
            }}
            handleClear={() => {
              setAircraftStatusFilters({
                archived: false,
                deleted: false,
                demo: false,
                locked: false,
                'mel only': false,
                setup: false,
              });
            }}
            label="Aircraft status"
          />
          <TFFilterButton
            id="FlightStatus"
            options={[
              {
                title: 'On Ground',
                count: allAircraft.filter((item) => item.flight_status === 'on_ground').length,
                value: 'on_ground',
                selected: flightStatusFilters.on_ground,
              },
              {
                title: 'In flight',
                count: allAircraft.filter((item) => item.flight_status === 'in_flight').length,
                value: 'in_flight',
                selected: flightStatusFilters.in_flight,
              },
            ]}
            handleClick={(val): void => {
              setFlightStatusFilters((prevState) => ({
                ...prevState,
                ...val,
              }));
            }}
            handleClear={() => {
              setFlightStatusFilters({
                on_ground: false,
                in_flight: false,
              });
            }}
            label="Flight status"
          />
          <TFFilterButton
            id="Operators"
            options={operators.map((item) => ({
              title: `${item.name}`,
              value: item.id,
              count: allAircraft.filter((aircraft) => aircraft.operator_id === item.id).length,
              selected: operatorFilter.includes(item.id),
            }))}
            handleClick={(val): void => {
              const operatorId = Object.keys(val)[0];
              const hasBeenSelected = Object.values(val)[0];
              if (hasBeenSelected) {
                setOperatorFilter((prevState) => {
                  if (!prevState.includes(operatorId)) {
                    return [...prevState, operatorId];
                  }
                  return prevState;
                });
              } else {
                setOperatorFilter((prevState) => prevState.filter((id) => id !== operatorId));
              }
            }}
            handleClear={(): void => {
              setOperatorFilter([]);
            }}
            label="Operators"
            width="330px"
          />
          <TFFilterButton
            id="Types"
            options={uniqueAircraftTypes.map((item) => ({
              title: `${item.manufacturer} ${item.model}`,
              value: item.id,
              count: allAircraft.filter((aircraft) => aircraft.aircraft_type.id === item.id).length,
              selected: typeFilter.includes(item.id),
            }))}
            handleClick={(val): void => {
              const typeId = Object.keys(val)[0];
              const hasBeenSelected = Object.values(val)[0];
              if (hasBeenSelected) {
                setTypeFilter((prevState) => {
                  if (!prevState.includes(typeId)) {
                    return [...prevState, typeId];
                  }
                  return prevState;
                });
              } else {
                setTypeFilter((prevState) => prevState.filter((id) => id !== typeId));
              }
            }}
            handleClear={() => {
              setTypeFilter([]);
            }}
            label="Types"
            width="330px"
          />
          <SearchWrapper>
            <Search
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder={formatMessage({ id: 'form.placeholder.searchAircraft' })}
              onClear={(): void => setSearchText('')}
              reset={resetSearch}
            />
          </SearchWrapper>
        </FiltersWrapper>
        <FiltersWrapper>
          {renderSortingControls()}
          <ToggleWrapper>
            <span>{formatMessage({ id: 'text.toggleView' })}</span>
            <GridImg src={TableStyle ? GridIconDefault : GridIconSelected} />
            <TableImg
              onClick={() => handleSwitchChange('light')}
              src={TableStyle ? TableIconSelected : TableIconDefault}
            />
          </ToggleWrapper>
        </FiltersWrapper>
      </FiltersRow>
    );
  };

  const renderFavourites = (): ReactNode => {
    if (filteredFavourites && filteredFavourites?.length === 0) {
      return null;
    }
    if (isSuccess && data?.data.length > 0) {
      return (
        <OperatorDiv>
          <Row>
            <h3>{formatMessage({ id: 'text.favourites' })}</h3>
          </Row>
          <CardsDiv>{renderFullAircraftCards(filteredFavourites || data?.data, true)}</CardsDiv>
        </OperatorDiv>
      );
    }
    return null;
  };

  function areArraysEmpty(arr): boolean {
    for (const subArray of arr) {
      if (subArray.length !== 0) {
        return false;
      }
    }
    return true;
  }
  let filtersAreEmpty = false;

  operators.forEach((operator, index) => {
    if (aircraftQueries[index].isSuccess && filteredAircraftQueries) {
      filtersAreEmpty = areArraysEmpty(filteredAircraftQueries);
    }
  });

  return (
    <PageHeaderWrapper icon={FleetIcon} title={formatMessage({ id: 'title.fleet' })}>
      <div>{renderFilterControls()}</div>
      {filtersAreEmpty ? (
        <EmptyState
          image={EmptyStateAircraft}
          text="We couldn't find any matching aircraft"
          subText="Try adjusting your filters or searching with another term."
          button="Clear all"
          buttonAction={resetFilters}
        />
      ) : (
        <>
          {renderFavourites()}
          {operators.map((operator, index) => (
            <>
              {filteredAircraftQueries && filteredAircraftQueries[index].length === 0 ? (
                <></>
              ) : (
                <OperatorDiv>
                  <Row>
                    <h3>{operator.name}</h3>
                  </Row>
                  {simpleAircraftQueries[index].isSuccess && aircraftQueries[index].isLoading && (
                    <CardsDiv>{renderSimpleAircraftCards(simpleAircraftQueries[index].data)}</CardsDiv>
                  )}
                  <CardsDiv>
                    {aircraftQueries[index].isSuccess &&
                      renderFullAircraftCards(
                        filteredAircraftQueries ? filteredAircraftQueries[index] : aircraftQueries[index]?.data?.data,
                      )}
                  </CardsDiv>
                </OperatorDiv>
              )}
            </>
          ))}
        </>
      )}
    </PageHeaderWrapper>
  );
};

export default Fleet;
