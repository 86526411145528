/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@arcflight/tf-component-library';
import moment, { Moment } from 'moment';
import { Defect } from '../../../models/defects';
import { DashboardState } from '../../../models';
import melChapters from '../../../assets/melChapters.json';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import TFDropdown from '../../TFDropdown/TFDropdown';
import TFInput from '../../TFInput/TFInput';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import DeferUntil from './DeferUntil';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import RectificationLimits from './RectificationLimits';

interface ManuallyEnterMELDetailsProps {
  defect: Defect;
  originalDefectData: Defect;
  rectificationCategory?: { title: string; colour?: string };
  setRectificationCategory?: (option: { title: string; colour?: string }) => void;
  deferred: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  setDateDue: (input) => void;
  noMEL?: boolean;
  apuInstalled?: boolean;
  reference: string;
  setReference: (value: string) => void;
  setATAChapter: (value: string) => void;
  setATASection: (value: number) => void;
  ATAChapter: string;
  ATASection: string;
  deferredTime: Moment;
  setDeferredTime: (input: Moment) => void;
  updateDefectData: (changes: any[]) => void;
  aircraftId?: string;
  poIntl: {
    po_short_name: string;
    po_long_name: string;
    po_suffix: string;
  };
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: 20px;
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
  margin-left: -3px;
`;

const FlexDiv = styled.div`
  width: 460px;
  margin-top: 20px;
  @media (max-width: 450px) {
    width: 300px;
  }
`;

const DropdownWrapper = styled.div`
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '0')};
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  width: 300px;
  margin-top: 20px;
`;

const ManuallyEnterMELDetails: React.FC<ManuallyEnterMELDetailsProps> = ({
  defect,
  originalDefectData,
  rectificationCategory,
  setRectificationCategory,
  deferred,
  noMEL,
  apuInstalled,
  setReference,
  ATAChapter,
  setATAChapter,
  ATASection,
  setATASection,
  deferredTime,
  setDeferredTime,
  updateDefectData,
  aircraftId,
  poIntl,
}) => {
  const { formatMessage } = useIntl();

  const {
    aircraft: { aircraftMap },
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const melNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [calendarDays, setCalendarDays] = useState(null);
  const [showRecticationLimits, setShowRectificationLimits] = useState(false);
  const [MELChapters, setMELChapters] = useState([]);
  const [MELSections, setMELSections] = useState([]);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const updateDisplayData = (value: any, key: string): void => {
    const newDisplayData = {
      ...defect?.display_data,
      [`${key}`]: value,
    };
    if (key === 'ata') {
      newDisplayData.ata_section = 0;
    }
    updateDefectData([{ value: newDisplayData, key: 'display_data' }]);
  };

  const getNewDateDue = (interval): string => {
    let date = moment(deferredTime) || moment();
    if (interval === 'B') {
      date = date.add(3, 'days');
    }
    if (interval === 'C') {
      date = date.add(10, 'days');
    }
    if (interval === 'D') {
      date = date.add(120, 'days');
    }
    return date.format('YYYY-MM-DD');
  };

  const handleSelectChange = (option: { title: string }): void => {
    setRectificationCategory(option);
    const newDateDue = getNewDateDue(option.title);
    if (option.title === 'A') {
      updateDefectData([
        { value: option.title, key: 'rectification_category' },
        { value: option.title, key: 'display_data.category' },
        { value: newDateDue, key: 'date_due' },
      ]);
    } else {
      updateDefectData([
        { value: option.title, key: 'rectification_category' },
        { value: option.title, key: 'display_data.category' },
        { value: newDateDue, key: 'date_due' },
        { value: null, key: 'flight_seconds_limit' },
        { value: null, key: 'apu_seconds_limit' },
        { value: null, key: 'flight_days_limit' },
        { value: null, key: 'seconds_limit' },
        { value: null, key: 'calendar_days_limit' },
        { value: null, key: 'cycles_limit' },
        { value: null, key: 'flights_limit' },
        { value: null, key: 'other_limit' },
      ]);
    }
  };

  const handleCalendarDaysChange = (value: string): void => {
    if (!value) {
      const newDateDue = moment
        .utc(originalDefectData?.date_due)
        .subtract(originalDefectData?.calendar_days_limit, 'days');
      updateDefectData([
        { value: newDateDue, key: 'date_due' },
        { value, key: 'calendar_days_limit' },
      ]);
    } else {
      const newDateDue = originalDefectData?.date_due
        ? moment.utc(originalDefectData?.date_due).add(parseInt(value, 10), 'days')
        : moment.utc().add(parseInt(value, 10), 'days');
      updateDefectData([
        { value: newDateDue, key: 'date_due' },
        { value, key: 'calendar_days_limit' },
      ]);
    }
  };

  const handleChapterChange = (option): void => {
    setATAChapter(option.value);
    updateDisplayData(option.value, 'ata');
  };

  const handleSectionChange = (option): void => {
    setATASection(option.value);
    updateDisplayData(option.value, 'ata_section');
  };

  const handleDeferralTimeChange = (dateTime: string): void => {
    setDeferredTime(moment.utc(dateTime));
    updateDefectData([{ value: moment.utc(dateTime, dateFormat), key: 'deferred_at' }]);
  };

  const handleReferenceChange = (value: string): void => {
    setReference(value);
    updateDefectData([{ value, key: 'reference' }]);
  };

  useEffect(() => {
    const createMELChapters = (): any => {
      const chaptersArray = [];
      Object.keys(melChapters).forEach((chapter) => {
        const childrenArray = [];
        if (melChapters[chapter]?.children && Object.keys(melChapters[chapter]?.children)?.length) {
          Object.keys(melChapters[chapter].children).forEach((section) => {
            childrenArray.push({
              value: section,
              title: `${section} - ${melChapters[chapter]?.children[section]}`,
            });
          });
        }
        chaptersArray.push({
          value: chapter,
          title: `${chapter} - ${melChapters[chapter]?.title}`,
          sections: childrenArray,
        });
      });
      return chaptersArray;
    };
    if (melChapters) {
      const chapters = createMELChapters();
      setMELChapters(chapters);
    }
  }, [ATAChapter]);

  useEffect(() => {
    if (defect && defect.mel_item) {
      if (!ATAChapter && defect?.display_data?.ata) setATAChapter(defect?.display_data?.ata?.split(' ')[0]);
      if (!ATASection && defect?.display_data?.ata_section) setATASection(defect?.display_data?.ata_section);
      setReference(defect?.reference || '');
      setCalendarDays(defect?.calendar_days_limit);
      if (defect?.display_data?.category) setRectificationCategory({ title: defect?.display_data?.category });
    }
  }, [defect]);

  useEffect(() => {
    if (MELChapters && ATAChapter) {
      if (MELChapters.find((chap) => chap.value === ATAChapter)) {
        const sections = MELChapters.find((chap) => chap.value === ATAChapter)?.sections;
        if (sections?.length) {
          setMELSections(sections);
          setATASection(sections[0].value);
        }
      }
    }
  }, [ATAChapter, MELChapters]);

  useEffect(() => {
    if (rectificationCategory?.title === 'A') {
      setShowRectificationLimits(true);
    } else {
      setShowRectificationLimits(false);
    }
  }, [rectificationCategory]);

  const isMobile = window.innerWidth < 451;

  return (
    <div>
      {noMEL && deferred ? (
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {`This aircraft has no ${melNameOverride || 'MEL'} currently, please manually enter the ${
            melNameOverride || 'MEL'
          } details below.`}
        </Label>
      ) : (
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage(
            { id: 'text.pleaseEnterReferenceNumber' },
            { po: poIntl.po_short_name, suffix: poIntl.po_suffix },
          )}
        </Label>
      )}
      <InputWrapper>
        <TFInput
          id="reference"
          value={defect?.reference || ''}
          onChange={(e): void => handleReferenceChange(e.target.value)}
          label={formatMessage({ id: 'title.reference' })}
        />
      </InputWrapper>
      {MELChapters ? (
        <FlexDiv>
          <TFDropdown
            options={MELChapters}
            initialValue={
              defect?.display_data?.ata
                ? MELChapters.find((chap) => chap.value === defect?.display_data?.ata.split(' ')[0])
                : null
            }
            onSelect={handleChapterChange}
            label={formatMessage({ id: 'title.ataChapter' })}
            id="ATAChapter"
            searchable
          />
        </FlexDiv>
      ) : null}
      <FlexWrapper column>
        <FlexWrapper column={isMobile}>
          <DropdownWrapper width="300px" marginRight="20px">
            <TFDropdown
              options={MELSections}
              initialValue={
                defect?.display_data?.ata_section || defect?.display_data?.ata_section === 0
                  ? MELSections.find((section) => section.value === defect?.display_data?.ata_section.toString())
                  : null
              }
              onSelect={handleSectionChange}
              label={formatMessage({ id: 'title.ataSection' })}
              id="ATASection"
            />
          </DropdownWrapper>
          {deferred && (
            <DropdownWrapper width="140px" marginRight="20px">
              <TFDropdown
                initialValue={{
                  title: rectificationCategory?.title || defect?.display_data?.category,
                  value: rectificationCategory?.title || defect?.display_data?.category,
                }}
                options={[
                  { title: 'A', value: 'A' },
                  { title: 'B', value: 'B' },
                  { title: 'C', value: 'C' },
                  { title: 'D', value: 'D' },
                  { title: 'Advisory only', value: 'Advisory only' },
                ]}
                onSelect={handleSelectChange}
                id="RectificationCategory"
                label={formatMessage({ id: 'title.rectificationCategory' })}
              />
            </DropdownWrapper>
          )}
        </FlexWrapper>
        {deferred && (
          <FlexWrapper marginTop={20} column={isMobile} width="100%">
            <FlexWrapper marginRight={20}>
              <DateTimePicker
                dateTime={deferredTime?.toISOString()}
                headerDate="Deferral date"
                headerTime="Deferral time"
                handleDateTimeChange={handleDeferralTimeChange}
                disabled={!unlockDeferralTime}
              />
            </FlexWrapper>
            <FlexWrapper marginTop={isMobile ? 20 : 0} width="100%">
              <DeferUntil defect={defect} rectificationCategory={rectificationCategory} />
            </FlexWrapper>
            <ButtonWrapper>
              {!unlockDeferralTime ? (
                <Button
                  primary={false}
                  height="24px"
                  padding="0 12px"
                  onClick={(): void => setUnlockDeferralTime(true)}
                >
                  <StyledIcon src={padlockIcon} alt="padlock icon" />
                  Unlock
                </Button>
              ) : null}
            </ButtonWrapper>
          </FlexWrapper>
        )}
      </FlexWrapper>
      {showRecticationLimits && deferred ? (
        <RectificationLimits
          defect={defect}
          setCalendarDays={handleCalendarDaysChange}
          apuInstalled={apuInstalled}
          updateDefectData={updateDefectData}
        />
      ) : null}
    </div>
  );
};

export default ManuallyEnterMELDetails;
