import React from 'react';
import styles from '../workpackDrawer.module.less';

const Totals = ({ ta, operatorSettings }): JSX.Element => {
  if (ta.length > 0) {
    const arrayOrder = {
      airframe: 0,
      engine_1: 1,
      engine_2: 2,
      engine_3: 3,
      engine_4: 4,
      apu: 5,
      prop_1: 6,
      prop_2: 7,
      prop_3: 8,
      prop_4: 9,
    };

    const compare = (a, b): number => {
      if (a === b) {
        return 0;
      }
      return a < b ? -1 : 1;
    };

    ta.sort((a, b) => {
      const indexResult = compare(arrayOrder[a.name], arrayOrder[b.name]);
      if (indexResult === 0) {
        return compare(a.name, b.name);
      }
      return indexResult;
    });
  }
  return ta.map((item) => (
    <div className={styles.rowItem} key={item.name}>
      <span className={styles.headingGray}>{operatorSettings[item.name] || item.name.replace(/_/g, ' ')} </span>
      <span className={styles.units}>{item.seconds !== null && `${(item.seconds / 60 / 60).toFixed(0)} hrs`}</span>
      <span className={styles.units}>{item.cycles !== null && `${item.cycles} cycles`}</span>
    </div>
  ));
};

const AircraftTotals = ({ totals, showApu, operatorSettings }): JSX.Element => {
  let totalsArray = [];
  if (totals) {
    totalsArray = Object.keys(totals).reduce((acc, curr) => {
      if (curr === 'APU') {
        if (showApu) acc.push({ name: curr, ...totals[curr] });
      } else {
        acc.push({ name: curr, ...totals[curr] });
      }
      return acc;
    }, []);
  }

  return (
    <div className={styles.workpackWhiteBox}>
      <div className={`${styles.minorHeading} ${styles.rowPad20}`}>Aircraft Totals</div>
      <div className={styles.aircraftTotalGrid}>
        <Totals ta={totalsArray} operatorSettings={operatorSettings} />
      </div>
    </div>
  );
};

export default AircraftTotals;
