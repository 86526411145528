import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import AircraftStatusCollapseContent from '../AircraftStatusCollapseContent/AircraftStatusCollapseContent';
import GreyCrossIcon from '../../assets/cross-grey.svg';
import RedCrossIcon from '../../assets/cross-red.svg';
import GreenTickIcon from '../../assets/tick-green.svg';
import YellowTickIcon from '../../assets/tick-yellow.svg';
import styles from './AircraftStatusTooltip.module.less';

const AircraftStatusTooltip = ({ content, userSettings, eropsTitle }) => {
  const collapseContentArray = [];
  let rowContent = {};

  const formatStatus = (status) => {
    if (status === 'not_complete')
      return { title: 'Not Completed', style: styles.checkStatusNotCompleted, icon: GreyCrossIcon };
    if (status === 'not_required')
      return { title: 'Not Required', style: styles.checkStatusNotCompleted, icon: GreyCrossIcon };
    if (status === 'in_flight')
      return { title: 'In Flight', style: styles.checkStatusNotCompleted, icon: GreyCrossIcon };
    if (status === 'overdue') return { title: 'Expired', style: styles.checkStatusExpired, icon: RedCrossIcon };
    if (status === 'open') return { title: 'Valid', style: styles.checkStatusValid, icon: GreenTickIcon };
    if (status === 'critical') return { title: 'Critical', style: styles.checkStatusCritical, icon: YellowTickIcon };
    return null;
  };

  const formatValidityTime = (time, status) => {
    if (time && time >= 0 && status !== 'not_required') {
      return `Valid for ${moment.duration(time, 'hours').humanize()}`;
    }
    return null;
  };

  const tooltipLabel = (validityDates) => {
    if (!validityDates.last_performed && !validityDates.valid) return null;
    if (validityDates.last_performed && !validityDates.valid) {
      return (
        <div>
          <div className={styles.tooltipLabel}>LAST PERFORMED</div>
          <div className={styles.tooltipDate}>{validityDates.last_performed}</div>
        </div>
      );
    }
    if (validityDates.last_performed && validityDates.valid) {
      const formatValid = moment().add(validityDates.valid, 'h').format(userSettings.dateFormat);
      return (
        <div className={styles.tooltipWrapper} data-testid="AircraftStatusTile--Tooltip">
          <div className={styles.lastPerformedWrapper}>
            <div className={styles.tooltipLabel}>LAST PERFORMED</div>
            <div className={styles.tooltipDate}>
              {moment.utc(validityDates.last_performed).format(userSettings.dateFormat)}
            </div>
          </div>
          <div>
            <div className={styles.tooltipLabel}>VALID UNTIL</div>
            <div className={styles.tooltipDate}>{formatValid}</div>
          </div>
        </div>
      );
    }
    return null;
  };

  const rowContentBuilder = (tooltipContent) => {
    return (
      <Tooltip
        title={tooltipLabel(tooltipContent.validFromTo)}
        className={tooltipContent.title === `${eropsTitle} Check` ? styles.contentRowNoPad : styles.contentRow}
        placement="bottom"
        key={tooltipContent.title}
      >
        <AircraftStatusCollapseContent
          collapseContent={tooltipContent}
          popoverContent={tooltipLabel(tooltipContent.validFromTo)}
        />
      </Tooltip>
    );
  };

  if (content) {
    Object.entries(content).forEach(([key, data]) => {
      const rawStatus = data?.status?.status || data?.status;
      rowContent = {
        title: `${key.replace(/_/g, ' ')} Check`,
        status: formatStatus(rawStatus),
        valid: formatValidityTime(data?.valid, rawStatus),
        validFromTo: data,
      };
      const row = rowContentBuilder(rowContent);
      collapseContentArray.push(row);
    });
  }

  return collapseContentArray;
};

AircraftStatusTooltip.propTypes = {
  content: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
};

export default connect(({ userSettings }) => ({ userSettings }))(AircraftStatusTooltip);
