import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UserPermission } from '../../models/userSettings';

export const hasDashboardPermission = (peoplePermissions, requiredResource, requiredPermissionLevel) => {
  try {
    if (peoplePermissions) {
      const permission = peoplePermissions.find((person) => {
        const resource = person.permission_groups[0].permissions.filter(
          (group) => group.resource_type === requiredResource,
        )[0];
        return resource && resource[requiredPermissionLevel];
      });
      return !!permission;
    }
    return false;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

export const hasAircraftPermission = (aircraft, requiredResource, requiredPermissionLevel, permissionGroup) => {
  try {
    if (aircraft && permissionGroup) {
      const canAccessAircraft = aircraft && permissionGroup.aircraft.includes(aircraft.id);

      if (canAccessAircraft) {
        const resourcePermission = permissionGroup.permissions.find(
          (permission) => permission.resource_type === requiredResource,
        );

        if (!resourcePermission) {
          throw new Error('Resource does not exist on this aircraft');
        }

        return !!resourcePermission[requiredPermissionLevel];
      }
      throw new Error('User does not have permission on this aircraft');
    }
    throw new Error('Aircraft or permissions objects not supplied');
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

export const checkAdminRequired = (person, adminRequired) => {
  let valid = true;
  if (adminRequired) {
    if (person?.position !== 'Admin') {
      valid = false;
    }
  }
  return valid;
};

const AircraftAuthentication = ({
  children,
  aircraftMap,
  people,
  operators,
  aircraftId,
  requiredResource,
  requiredPermissionLevel,
  requiredFeatureFlag,
  onlyAdmin,
}) => {
  const requiredAircraft = aircraftMap.get(aircraftId);
  let operatorFeatureFlags = [];

  if (
    requiredPermissionLevel !== UserPermission.READ &&
    (requiredAircraft?.locked || requiredAircraft?.billing_status === 'Archived')
  ) {
    return null;
  }

  if (requiredAircraft && requiredFeatureFlag) {
    const currentOp = operators?.find((op) => op.id === requiredAircraft.operator_id);
    operatorFeatureFlags = currentOp?.feature_flags.map((f) => f.feature_name);
  }

  const aircraftFeatureFlags = requiredAircraft?.feature_flags?.map((featureFlag) => featureFlag.feature_name) || [];

  const personInOrgWithPermissions = people?.find((person) =>
    person.permission_groups[1].aircraft.includes(aircraftId),
  );

  let hasEnabledFeature = true;

  if (requiredFeatureFlag) {
    hasEnabledFeature =
      operatorFeatureFlags.includes(requiredFeatureFlag) || aircraftFeatureFlags.includes(requiredFeatureFlag);
  }

  if (
    personInOrgWithPermissions &&
    requiredAircraft &&
    hasEnabledFeature &&
    checkAdminRequired(personInOrgWithPermissions, onlyAdmin) &&
    hasAircraftPermission(
      requiredAircraft,
      requiredResource,
      requiredPermissionLevel,
      personInOrgWithPermissions.permission_groups[1],
    )
  ) {
    return children;
  }
  return null;
};

AircraftAuthentication.propTypes = {
  children: PropTypes.node.isRequired,
  aircraftMap: PropTypes.object.isRequired,
  requiredResource: PropTypes.string,
  requiredPermissionLevel: PropTypes.string.isRequired,
  requiredFeatureFlag: PropTypes.string,
  onlyAdmin: PropTypes.bool,
};

AircraftAuthentication.defaultProps = {
  requiredResource: null,
  requiredFeatureFlag: null,
  onlyAdmin: false,
};

export const AircraftAuthenticationWrapper = connect(({ aircraft, userSettings }) => ({
  aircraftMap: aircraft.aircraftMap,
  people: userSettings.details?.people,
  operators: userSettings.details?.operators,
}))(AircraftAuthentication);

const AuthenticationWrapper = ({ children, userSettings, requiredResource, requiredPermissionLevel }) => {
  const peoplePermissions = userSettings.details && userSettings.details.people;

  if (hasDashboardPermission(peoplePermissions, requiredResource, requiredPermissionLevel)) {
    return children;
  }
  return null;
};

export default connect(({ userSettings }) => ({ userSettings }))(AuthenticationWrapper);
