import React, { useEffect, useState } from 'react';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trip } from '../../models/trips';
import { Person } from '../../models/people';
import { Operator } from '../../models/userSettings';
import { DashboardState } from '../../models';
import { DisplayText, Header } from '../FlightDrawer/FlightDrawer';
import SelectPilot from '../FlightDrawer/SelectPilot';
import plusIcon from '../../assets/plus-blue.svg';
import DeleteIcon from '../../assets/delete.svg';
import AdditionalCrewIcon from '../../assets/icon-card-additional-crew.svg';
import { queryPeople } from '../../services/api';

interface AdditionalCrewSectionProps {
  trip: Trip;
  updateTripData: (key: string, value: any, secondKey?: string, secondValue?: any) => void;
  currentOp: Operator;
}

const CrewWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '20px')};
  grid-template-rows: auto;
`;

const SectionWrapper = styled.div`
  margin-bottom: 32px;
`;

export const SectionHeader = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const AddButtonWrapper = styled.div`
  margin-right: 20px;
`;

const InputSection = styled.div`
  padding-top: 1.5rem;
  display: ${({ edit }): string => (edit ? 'block' : 'grid')};
  grid-template-columns: ${({ edit }): string => (edit ? '' : '1fr 1fr 1fr 1fr')};
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const NoAdditionalCrewText = styled.span`
  margin-left: 8px;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RolesWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 40px;
  margin-bottom: 1rem;
  @media (max-width: 451px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: end;
`;

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const LineBreak = styled.div`
  height: 1px;
  margin: 20px 0 20px 40px;
  background-color: ${({ theme }): string => theme.colors.black05Alpha};
`;

const AdditionalCrewSection: React.FC<AdditionalCrewSectionProps> = ({ trip, updateTripData, currentOp }) => {
  const {
    drawer: { mode },
    people: { peopleMap, deletedPeopleMap, lastFetched, ttl },
  } = useSelector((state: DashboardState) => state);

  const [additionalCrew, setAdditionalCrew] = useState([]);
  const [personRoles, setPersonRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [combinedPeopleArray, setCombinedPeopleArray] = useState([]);

  const isMobile = window.innerWidth < 451;

  const createOptionsFromPeopleArray = (people): [] => {
    const persons = people.map((p) => ({ title: `${p.first_name} ${p.last_name}`, value: p.id }));
    persons.push({ title: 'None', value: undefined });
    return persons;
  };

  const getPeopleForRole = (operator_person_role_id, index): Person[] => {
    const ppl = users;
    // eslint-disable-next-line no-underscore-dangle
    const selectedIds = additionalCrew.filter((person) => !person._destroy).map((ac) => ac.person_id);
    selectedIds.splice(index, 1);
    if (trip.captain_id) {
      selectedIds.push(trip.captain_id);
    }
    if (trip.first_officer_id) {
      selectedIds.push(trip.first_officer_id);
    }
    const availablePeople = ppl.reduce((arr, person) => {
      if (selectedIds.includes(person.id)) {
        return arr;
      }
      arr.push(person);
      return arr;
    }, []);
    if (operator_person_role_id === '' || operator_person_role_id === null) {
      return createOptionsFromPeopleArray(availablePeople);
    }
    const role = personRoles.find((pr) => pr.id === operator_person_role_id);
    const rolePositions = role?.positions || [];

    const peopleInRoles = availablePeople.reduce((arr, person) => {
      if (rolePositions.includes(person.position)) {
        arr.push(person);
      }
      return arr;
    }, []);
    return createOptionsFromPeopleArray(peopleInRoles);
  };

  const handleAddAdditionalCrew = (e): void => {
    if (mode === 'add' || (mode === 'edit' && isExpanded === true)) {
      e.stopPropagation();
    }
    setIsExpanded(true);
    const crew = additionalCrew;
    const newCrew = {
      operator_person_role_id: null,
      person_id: null,
    };
    crew.push(newCrew);
    setAdditionalCrew([...crew]);
  };

  const removeCrew = (index): void => {
    const newArray = additionalCrew;
    const dutyLogs = trip.duty_logs || [];
    const dutyLogIndex = dutyLogs?.findIndex((dl) => dl?.person_id === newArray[index]?.person_id);
    const isStoredInCore = typeof newArray[index].id === 'string';
    if (isStoredInCore) {
      // eslint-disable-next-line no-underscore-dangle
      newArray[index]._destroy = true;
      if (dutyLogIndex) {
        // eslint-disable-next-line no-underscore-dangle
        dutyLogs[dutyLogIndex]._destroy = true;
      }
    } else {
      newArray.splice(index, 1);
      if (dutyLogIndex) {
        dutyLogs.splice(index, 1);
      }
    }
    updateTripData('additional_crews_attributes', [...newArray], 'duty_logs_attributes', [...dutyLogs]);
  };

  const handleSelectedRole = (id, index): void => {
    const newArray = additionalCrew;
    if (id !== additionalCrew[index].operator_person_role_id) {
      newArray[index].person_id = null;
      newArray[index].operator_person_role_id = id;
      setAdditionalCrew([...newArray]);
      updateTripData('additional_crews_attributes', [...newArray]);
    }
  };

  const handleSelectedPerson = (id, index): void => {
    const newArray = additionalCrew;
    newArray[index].person_id = id;
    setAdditionalCrew([...newArray]);
    updateTripData('additional_crews_attributes', [...newArray]);
  };

  useEffect(() => {
    if (currentOp) {
      setPersonRoles(currentOp.person_roles);
      if (combinedPeopleArray.length) {
        const filteredUsers = combinedPeopleArray.filter(
          (p) => p.first_name !== null && p?.organisation?.id === currentOp?.id,
        );
        setUsers(filteredUsers);
      }
    }
  }, [combinedPeopleArray, currentOp]);

  useEffect(() => {
    if (peopleMap.size > 0) {
      setCombinedPeopleArray([...peopleMap.values(), ...deletedPeopleMap.values()]);
    }
  }, [deletedPeopleMap, peopleMap]);

  useEffect(() => {
    if ((peopleMap.size === 0 && deletedPeopleMap.size === 0) || lastFetched < Date.now() - ttl) {
      queryPeople({ with_deleted: true });
    }
  }, [deletedPeopleMap.size, lastFetched, peopleMap.size, ttl]);

  useEffect(() => {
    if (trip !== null && currentOp) {
      const additCrews = trip?.additional_crews_attributes || trip?.additional_crews;
      if (additCrews) {
        if (additCrews.length > 0) {
          if (additCrews[0].operator_person_role_id === undefined) {
            additCrews.forEach((crew) => {
              const currentCrew = currentOp.person_roles.find((pr) => pr.name === crew.position);
              if (currentCrew) {
                // eslint-disable-next-line no-param-reassign
                crew.operator_person_role_id = currentCrew.id;
              }
            });
          }
        }
        setAdditionalCrew(additCrews);
      }
    }
  }, [trip, currentOp]);

  let additionalCrewTitle = 'Additional crew added to trip';
  if (!additionalCrew?.length) additionalCrewTitle = 'No additional crew currently added';
  if (isMobile) {
    if (!additionalCrew?.length) additionalCrewTitle = 'No Additional crew';
    additionalCrewTitle = 'Additional crew added';
  }
  return (
    <SectionWrapper>
      <SectionHeader marginLeft="20px" data-testid="TripTrendingSection-Header">
        Additional Crew
      </SectionHeader>
      <Accordion
        headerContent={
          <HeaderInner>
            <div>
              <img src={AdditionalCrewIcon} alt="no defects" />
              <NoAdditionalCrewText>{additionalCrewTitle}</NoAdditionalCrewText>
            </div>
            {mode !== 'view' ? (
              <AddButtonWrapper>
                <Button height="24px" onClick={handleAddAdditionalCrew} primary={false}>
                  <AddTextWrapper>
                    <PlusIcon src={plusIcon} alt="plus icon" />
                    {isMobile ? 'Add' : 'Add crew'}
                  </AddTextWrapper>
                </Button>
              </AddButtonWrapper>
            ) : null}
          </HeaderInner>
        }
        dash
        isExpanded={mode === 'add'}
      >
        {additionalCrew.length === 0 ? null : (
          <CrewWrapper noMargin>
            {additionalCrew.length > 0 && (
              <InputSection edit={mode !== 'view'}>
                {additionalCrew.map((crew, index) => {
                  // eslint-disable-next-line no-underscore-dangle
                  if (crew._destroy) {
                    return null;
                  }
                  const user = users.find((p) => p.id === crew.person_id);
                  return (
                    <div key={crew.person_id}>
                      <RolesWrapper>
                        {mode === 'view' ? (
                          <UserWrapper>
                            <Header editable={mode !== 'view'}>
                              {crew.position && personRoles.length
                                ? `${personRoles.find((p) => p.name === crew.position)?.name}`
                                : '-'}
                            </Header>
                            <DisplayText>
                              {crew.person_id && user ? `${user.first_name} ${user.last_name}` : '-'}
                            </DisplayText>
                          </UserWrapper>
                        ) : (
                          <>
                            <div>
                              <Header editable={mode !== 'view'}>Role</Header>

                              <SelectPilot
                                width={isMobile ? 240 : 200}
                                people={personRoles ? personRoles?.map((p) => ({ title: p.name, value: p.id })) : []}
                                handlePersonChange={(id): void => {
                                  handleSelectedRole(id, index);
                                }}
                                pilotFlying={{
                                  title: additionalCrew[index].operator_person_role_id
                                    ? personRoles?.find((p) => p.id === additionalCrew[index].operator_person_role_id)
                                        .name
                                    : '',
                                  value: additionalCrew[index].operator_person_role_id
                                    ? personRoles?.find((p) => p.id === additionalCrew[index].operator_person_role_id)
                                        .id
                                    : '',
                                }}
                                loading={false}
                              />
                            </div>
                            <div>
                              <Header editable={mode !== 'view'}>User</Header>
                              {mode === 'view' ? (
                                <DisplayText>
                                  {crew.person_id && user ? `${user.first_name} ${user.last_name}` : '-'}
                                </DisplayText>
                              ) : (
                                <InputWrapper>
                                  <SelectPilot
                                    width={isMobile ? 240 : 200}
                                    people={getPeopleForRole(additionalCrew[index].operator_person_role_id, index)}
                                    handlePersonChange={(id): void => {
                                      handleSelectedPerson(id, index);
                                    }}
                                    pilotFlying={{
                                      title:
                                        additionalCrew[index].person_id &&
                                        users.find((p) => p.id === additionalCrew[index].person_id)?.first_name &&
                                        users.find((p) => p.id === additionalCrew[index].person_id)?.last_name &&
                                        users.length
                                          ? `${
                                              users.find((p) => p.id === additionalCrew[index].person_id).first_name
                                            } ${users.find((p) => p.id === additionalCrew[index].person_id).last_name}`
                                          : '',
                                      value:
                                        additionalCrew[index].person_id && users.length
                                          ? users.find((p) => p.id === additionalCrew[index].person_id).id
                                          : '',
                                    }}
                                    loading={false}
                                  />
                                  {isMobile ? (
                                    <ButtonWrapper>
                                      <DeleteButton
                                        type="button"
                                        onClick={(): void => {
                                          removeCrew(index);
                                        }}
                                      >
                                        <img src={DeleteIcon} alt="delete button" />
                                      </DeleteButton>
                                    </ButtonWrapper>
                                  ) : null}
                                </InputWrapper>
                              )}
                            </div>
                          </>
                        )}
                        {mode !== 'view' && !isMobile ? (
                          <ButtonWrapper>
                            <DeleteButton
                              type="button"
                              onClick={(): void => {
                                removeCrew(index);
                              }}
                            >
                              <img src={DeleteIcon} alt="delete button" />
                            </DeleteButton>
                          </ButtonWrapper>
                        ) : null}
                      </RolesWrapper>
                      {index + 1 < additionalCrew.length && mode !== 'view' ? <LineBreak /> : null}
                    </div>
                  );
                })}
              </InputSection>
            )}
          </CrewWrapper>
        )}
      </Accordion>
    </SectionWrapper>
  );
};

export default AdditionalCrewSection;
