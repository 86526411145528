import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.less';

const FooterView = ({ links, lightText, copyright }) => {
  return (
    <div className={styles.globalFooter}>
      {links && (
        <div className={styles.links}>
          {links.map((link) => (
            <a
              key={link.key}
              target={link.blankTarget ? '_blank' : '_self'}
              rel={link.blankTarget ? 'noreferrer' : null}
              href={link.href}
            >
              {link.title}
            </a>
          ))}
        </div>
      )}
      {copyright && (
        <div className={`${styles.copyright} ${lightText ? styles.lightText : null}`} data-test="copyrightText">
          {copyright}
        </div>
      )}
    </div>
  );
};

FooterView.defaultProps = {
  links: [],
  lightText: false,
};

FooterView.propTypes = {
  links: PropTypes.array,
  lightText: PropTypes.bool,
  copyright: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
export default FooterView;
