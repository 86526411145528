const produceFilterModel = (totals) => {
  return [
    {
      groupLabel: 'Status',
      filters: [
        {
          label: 'Pending Acceptance',
          key: 'status',
          value: 'pending',
          badge: 'amber',
          count: totals ? totals.status.pending : 0,
          filterFunction: (item) => item.status === 'pending',
        },
        {
          label: 'Draft',
          key: 'status',
          value: 'draft',
          badge: 'grey',
          count: totals ? totals.status.draft : 0,
          filterFunction: (item) => item.status === 'draft',
        },
        {
          label: 'Active',
          key: 'status',
          value: 'active',
          badge: 'blue',
          count: totals ? totals.status.active : 0,
          filterFunction: (item) => item.status === 'active',
        },
        {
          label: 'Complete',
          key: 'status',
          value: 'complete',
          badge: 'green',
          count: totals ? totals.status.complete : 0,
          filterFunction: (item) => item.status === 'complete',
        },
      ],
    },
    {
      groupLabel: 'Source',
      filters: [
        {
          label: 'CAMP',
          key: 'source',
          value: 'camp',
          count: totals ? totals.source.camp : 0,
          filterFunction: (item) => item.source === 'camp',
        },
        {
          label: 'TrustFlight',
          key: 'source',
          value: 'trustflight',
          count: totals ? totals.source.trustflight : 0,
          filterFunction: (item) => item.source === 'trustflight',
        },
      ],
    },
  ];
};

export default produceFilterModel;
