const initialState: DocumentTypesState = {
  documentTypes: [],
  lastFetched: 0,
};

export enum DocumentTypesActionTypes {
  SAVE = 'documentTypes/save',
}

export interface SaveDocumentTypesActionType {
  type: DocumentTypesActionTypes.SAVE;
  payload: DocumentType[];
}

export default function (state = initialState, action: SaveDocumentTypesActionType): DocumentTypesState {
  switch (action.type) {
    case DocumentTypesActionTypes.SAVE: {
      return {
        documentTypes: action.payload,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}

export interface DocumentTypesState {
  documentTypes: DocumentType[];
  lastFetched: number;
}

export type DocumentType = string;
