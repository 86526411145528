import React from 'react';
import { Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import updatedIcon from '../../assets/edited-icon.svg';
import ProgressBars from '../../../../MXCard/ProgressBars';
import MXTimeDetails from '../../../../MXCard/MXTimeDetails';
import MXEstimatedDetails from '../../../../MXCard/MXEstimatedDetails';
import SmallTag from '../../../../MXCard/SmallTag';
import styles from './DraftsList.module.less';
import UpdateCalendar from './UpdateCalendar';

const ItemCardUpdate = ({ item, aircraftThreshold, hasAPU, intl: { formatMessage } }) => {
  const campDiffs = item.camp_diffs || [];
  const campDiffsKeys = campDiffs.map((diff) => diff.key);
  const campDiffsObj = {};
  campDiffs.reduce((map, obj) => {
    campDiffsObj[obj.key] = obj;
    return campDiffsObj;
  }, {});

  const showCalendar =
    campDiffsKeys.includes('estimated_date') &&
    Math.abs(moment(campDiffsObj.estimated_date.value).diff(item.estimated_date, 'years')) < 1;

  const hrsTimeDetails = {
    repeat: item.flight_seconds_repeat,
    due: item.flight_seconds_due,
    threshold: item.flight_seconds_threshold,
    tolerance: item.flight_seconds_tolerance,
    format: 'seconds',
    repeat_status: campDiffsKeys.includes('flight_seconds_repeat') ? 'REMOVED' : null,
    due_status: campDiffsKeys.includes('flight_seconds_due') ? 'REMOVED' : null,
    threshold_status: campDiffsKeys.includes('flight_seconds_threshold') ? 'REMOVED' : null,
    tolerance_status: campDiffsKeys.includes('flight_seconds_tolerance') ? 'REMOVED' : null,
  };

  const cyclesTimeDetails = {
    repeat: item.cycles_repeat,
    due: item.cycles_due,
    threshold: item.cycles_threshold,
    tolerance: item.cycles_tolerance,
    format: 'number',
    repeat_status: campDiffsKeys.includes('cycles_repeat') ? 'REMOVED' : null,
    due_status: campDiffsKeys.includes('cycles_due') ? 'REMOVED' : null,
    threshold_status: campDiffsKeys.includes('cycles_threshold') ? 'REMOVED' : null,
    tolerance_status: campDiffsKeys.includes('cycles_tolerance') ? 'REMOVED' : null,
  };

  const daysTimeDetails = {
    repeat: item.months_repeat,
    due: item.date_due,
    threshold: item.months_threshold,
    tolerance: item.months_tolerance,
    format: 'months',
    repeat_status: campDiffsKeys.includes('months_repeat') ? 'REMOVED' : null,
    due_status: campDiffsKeys.includes('date_due') ? 'REMOVED' : null,
    threshold_status: campDiffsKeys.includes('months_threshold') ? 'REMOVED' : null,
    tolerance_status: campDiffsKeys.includes('months_tolerance') ? 'REMOVED' : null,
  };

  const apuHrsTimeDetails = {
    repeat: item.apu_seconds_repeat,
    due: item.apu_seconds_due,
    threshold: item.apu_seconds_threshold,
    tolerance: item.apu_seconds_tolerance,
    format: 'seconds',
    repeat_status: campDiffsKeys.includes('apu_seconds_repeat') ? 'REMOVED' : null,
    due_status: campDiffsKeys.includes('apu_seconds_due') ? 'REMOVED' : null,
    threshold_status: campDiffsKeys.includes('apu_seconds_threshold') ? 'REMOVED' : null,
    tolerance_status: campDiffsKeys.includes('apu_seconds_tolerance') ? 'REMOVED' : null,
  };

  const estimatedDetails = {
    due: item.estimated_date,
    due_status: campDiffsKeys.includes('estimated_date') ? 'REMOVED' : null,
  };

  const hrsTimeDetailsUpdated = {
    repeat: campDiffsKeys.includes('flight_seconds_repeat')
      ? campDiffsObj.flight_seconds_repeat.value
      : item.flight_seconds_repeat,
    due: campDiffsKeys.includes('flight_seconds_due') ? campDiffsObj.flight_seconds_due.value : item.flight_seconds_due,
    threshold: campDiffsKeys.includes('flight_seconds_threshold')
      ? campDiffsObj.flight_seconds_threshold.value
      : item.flight_seconds_threshold,
    tolerance: campDiffsKeys.includes('flight_seconds_tolerance')
      ? campDiffsObj.flight_seconds_tolerance.value
      : item.flight_seconds_tolerance,
    format: 'seconds',
    repeat_status: campDiffsKeys.includes('flight_seconds_repeat') ? 'UPDATE' : null,
    due_status: campDiffsKeys.includes('flight_seconds_due') ? 'UPDATE' : null,
    threshold_status: campDiffsKeys.includes('flight_seconds_threshold') ? 'UPDATE' : null,
    tolerance_status: campDiffsKeys.includes('flight_seconds_tolerance') ? 'UPDATE' : null,
  };

  const cyclesTimeDetailsUpdated = {
    repeat: campDiffsKeys.includes('cycles_repeat') ? campDiffsObj.cycles_repeat.value : item.cycles_repeat,
    due: campDiffsKeys.includes('cycles_due') ? campDiffsObj.cycles_due.value : item.cycles_due,
    threshold: campDiffsKeys.includes('cycles_threshold') ? campDiffsObj.cycles_threshold.value : item.cycles_threshold,
    tolerance: campDiffsKeys.includes('cycles_tolerance') ? campDiffsObj.cycles_tolerance.value : item.cycles_tolerance,
    format: 'number',
    repeat_status: campDiffsKeys.includes('cycles_repeat') ? 'UPDATE' : null,
    due_status: campDiffsKeys.includes('cycles_due') ? 'UPDATE' : null,
    threshold_status: campDiffsKeys.includes('cycles_threshold') ? 'UPDATE' : null,
    tolerance_status: campDiffsKeys.includes('cycles_tolerance') ? 'UPDATE' : null,
  };

  const daysTimeDetailsUpdated = {
    repeat: campDiffsKeys.includes('months_repeat') ? campDiffsObj.months_repeat.value : item.months_repeat,
    due: campDiffsKeys.includes('date_due') ? campDiffsObj.date_due.value : item.date_due,
    threshold: campDiffsKeys.includes('months_threshold') ? campDiffsObj.months_threshold.value : item.months_threshold,
    tolerance: campDiffsKeys.includes('months_tolerance') ? campDiffsObj.months_tolerance.value : item.months_tolerance,
    format: 'months',
    repeat_status: campDiffsKeys.includes('months_repeat') ? 'UPDATE' : null,
    due_status: campDiffsKeys.includes('date_due') ? 'UPDATE' : null,
    threshold_status: campDiffsKeys.includes('months_threshold') ? 'UPDATE' : null,
    tolerance_status: campDiffsKeys.includes('months_tolerance') ? 'UPDATE' : null,
  };

  const apuHrsTimeDetailsUpdated = {
    repeat: campDiffsKeys.includes('apu_seconds_repeat')
      ? campDiffsObj.apu_seconds_repeat.value
      : item.apu_seconds_repeat,
    due: campDiffsKeys.includes('apu_seconds_due') ? campDiffsObj.apu_seconds_due.value : item.apu_seconds_due,
    threshold: campDiffsKeys.includes('apu_seconds_threshold')
      ? campDiffsObj.apu_seconds_threshold.value
      : item.apu_seconds_threshold,
    tolerance: campDiffsKeys.includes('apu_seconds_tolerance')
      ? campDiffsObj.apu_seconds_tolerance.value
      : item.apu_seconds_tolerance,
    format: 'seconds',
    repeat_status: campDiffsKeys.includes('apu_seconds_repeat') ? 'UPDATE' : null,
    due_status: campDiffsKeys.includes('apu_seconds_due') ? 'UPDATE' : null,
    threshold_status: campDiffsKeys.includes('apu_seconds_threshold') ? 'UPDATE' : null,
    tolerance_status: campDiffsKeys.includes('apu_seconds_tolerance') ? 'UPDATE' : null,
  };

  const estimatedDetailsUpdated = {
    due: campDiffsKeys.includes('estimated_date') ? campDiffsObj.estimated_date.value : item.estimated_date,
    due_status: campDiffsKeys.includes('estimated_date') ? 'UPDATE' : null,
  };

  const updateItems = Object.keys(campDiffsObj).reduce((acc, curr) => {
    acc[curr] = campDiffsObj[curr].value;
    return acc;
  }, {});

  const mxItemWithUpdate = { ...item, ...updateItems };

  return (
    <Card className={`${styles.itemCard} ${styles.itemCardUpdate}`}>
      <Row gutter={16} className={styles.mxDetails}>
        {showCalendar && (
          <UpdateCalendar
            item={item}
            newDate={campDiffsKeys.includes('estimated_date') ? campDiffsObj.estimated_date.value : item.estimated_date}
            data-test="updateCalendar"
          />
        )}
        <Row gutter={16} data-test="rowTitle">
          <Col md={4}>
            <div className={`${styles.rowTitle} ${styles.showMD}`} data-test="rowTitle">
              {formatMessage({ id: 'title.item' })}
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div
              className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanStrong} ${styles.colWrapperKanbanLeft}`}
            >
              <div className={styles.titleWrapper}>
                <SmallTag
                  type={item.mx_type.substring(item.mx_type.lastIndexOf('_'), item.mx_type.length).substring(1, 4)}
                  data-test="tagLeft"
                />
                <div
                  className={`${styles.itemStatusTitle} ${
                    campDiffsKeys.includes('name') ? styles.itemStatusTitleREMOVED : undefined
                  }`}
                  data-test="titleLeft"
                >
                  {item.name || '-'}
                </div>
              </div>
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanStrong}`}>
              <div className={styles.titleWrapper}>
                <img src={updatedIcon} alt="updatedIcon" data-test="iconRight" />
                <SmallTag
                  type={item.mx_type.substring(item.mx_type.lastIndexOf('_'), item.mx_type.length).substring(1, 4)}
                  data-test="tagRight"
                />
                <div
                  className={`${styles.itemStatusTitle} ${
                    campDiffsKeys.includes('name') ? styles.itemStatusTitleUPDATE : undefined
                  }`}
                  data-test="titleRight"
                >
                  {campDiffsKeys.includes('name') ? campDiffsObj.name.value : item.name || '-'}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} data-test="rowFlightHours">
          <Col md={4}>
            <div className={styles.rowTitle} data-test="rowTitle">
              {formatMessage({ id: 'text.flightHours' })}
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div
              className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanLeft} ${styles.colWrapperMXTimeDetails}`}
            >
              <MXTimeDetails
                unitSingular={formatMessage({ id: 'units.hourShort' })}
                unitPlural={formatMessage({ id: 'units.hoursShort' })}
                item={hrsTimeDetails}
                layout="inline"
                data-test="mxTimeDetailsLeft"
              />
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={`${styles.colWrapperKanban} ${styles.colWrapperMXTimeDetails}`}>
              <MXTimeDetails
                unitSingular={formatMessage({ id: 'units.hourShort' })}
                unitPlural={formatMessage({ id: 'units.hoursShort' })}
                item={hrsTimeDetailsUpdated}
                layout="inline"
                data-test="mxTimeDetailsRight"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16} data-test="rowCycles">
          <Col md={4}>
            <div className={styles.rowTitle} data-test="rowTitle">
              {formatMessage({ id: 'period.Cycles' })}
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div
              className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanLeft} ${styles.colWrapperMXTimeDetails}`}
            >
              <MXTimeDetails
                unitSingular={formatMessage({ id: 'units.cycle' })}
                unitPlural={formatMessage({ id: 'units.cycles' })}
                item={cyclesTimeDetails}
                layout="inline"
                data-test="mxTimeDetailsLeft"
              />
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={`${styles.colWrapperKanban} ${styles.colWrapperMXTimeDetails}`}>
              <MXTimeDetails
                unitSingular={formatMessage({ id: 'units.cycle' })}
                unitPlural={formatMessage({ id: 'units.cycles' })}
                item={cyclesTimeDetailsUpdated}
                layout="inline"
                data-test="mxTimeDetailsRight"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16} data-test="rowMonths">
          <Col md={4}>
            <div className={styles.rowTitle} data-test="rowTitle">
              {formatMessage({ id: 'form.labels.months' })}
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div
              className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanLeft} ${styles.colWrapperMXTimeDetails}`}
            >
              <MXTimeDetails
                unitSingular={formatMessage({ id: 'units.monthShort' })}
                unitPlural={formatMessage({ id: 'units.monthsShort' })}
                item={daysTimeDetails}
                layout="inline"
                data-test="mxTimeDetailsLeft"
              />
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={`${styles.colWrapperKanban} ${styles.colWrapperMXTimeDetails}`}>
              <MXTimeDetails
                unitSingular={formatMessage({ id: 'units.monthShort' })}
                unitPlural={formatMessage({ id: 'units.monthsShort' })}
                item={daysTimeDetailsUpdated}
                layout="inline"
                data-test="mxTimeDetailsRight"
              />
            </div>
          </Col>
        </Row>
        {hasAPU && (
          <Row gutter={16} data-test="rowAPUHrs">
            <Col md={4}>
              <div className={styles.rowTitle} data-test="rowTitle">
                {formatMessage({ id: 'title.apuHours' })}
              </div>
            </Col>
            <Col md={9} offset={1}>
              <div
                className={`${styles.colWrapperKanban}
                ${styles.colWrapperKanbanLeft} ${styles.colWrapperMXTimeDetails}`}
              >
                <MXTimeDetails
                  unitSingular={formatMessage({ id: 'units.hourShort' })}
                  unitPlural={formatMessage({ id: 'units.hoursShort' })}
                  item={apuHrsTimeDetails}
                  layout="inline"
                  data-test="mxTimeDetailsLeft"
                />
              </div>
            </Col>
            <Col md={9} offset={1}>
              <div className={`${styles.colWrapperKanban} ${styles.colWrapperMXTimeDetails}`}>
                <MXTimeDetails
                  unitSingular={formatMessage({ id: 'units.hourShort' })}
                  unitPlural={formatMessage({ id: 'units.hoursShort' })}
                  item={apuHrsTimeDetailsUpdated}
                  layout="inline"
                  data-test="mxTimeDetailsRight"
                />
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={16} data-test="rowOverview">
          <Col md={4}>
            <div className={styles.rowTitle} data-test="rowTitle">
              {formatMessage({ id: 'title.overview' })}
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanLeft}`}>
              <ProgressBars
                mxItem={item}
                hasAPU={hasAPU}
                aircraftThreshold={aircraftThreshold}
                layout="inline"
                data-test="progressBarsLeft"
              />
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={styles.colWrapperKanban}>
              <ProgressBars
                mxItem={mxItemWithUpdate}
                hasAPU={hasAPU}
                aircraftThreshold={aircraftThreshold}
                layout="inline"
                data-test="progressBarsRight"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16} data-test="rowEstimatedDue">
          <Col md={4}>
            <div className={styles.rowTitle} data-test="rowTitle">
              {formatMessage({ id: 'title.estimatedDueLong' })}
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={`${styles.colWrapperKanban} ${styles.colWrapperKanbanLeft}`}>
              <MXEstimatedDetails item={estimatedDetails} layout="inline" data-test="mxEstimatedLeft" />
            </div>
          </Col>
          <Col md={9} offset={1}>
            <div className={styles.colWrapperKanban}>
              <MXEstimatedDetails item={estimatedDetailsUpdated} layout="inline" data-test="mxEstimatedRight" />
            </div>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

ItemCardUpdate.propTypes = {
  item: PropTypes.object.isRequired,
  aircraftThreshold: PropTypes.object.isRequired,
  hasAPU: PropTypes.bool.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(ItemCardUpdate);
