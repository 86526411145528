/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RightContent from '../GlobalHeader/RightContent';
import BaseMenu from '../SiderMenu/BaseMenu';
import styles from './index.module.less';

export default class TopNavHeader extends PureComponent {
  static propTypes = {
    contentWidth: PropTypes.string,
    theme: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    logoText: PropTypes.string.isRequired,
  };

  static defaultProps = {
    contentWidth: '',
  };

  static getDerivedStateFromProps(props) {
    return {
      maxWidth: (props.contentWidth === 'Fixed' ? 1200 : window.innerWidth) - 330 - 165 - 4 - 36,
    };
  }

  render() {
    const { theme, contentWidth, logo, logoText } = this.props;
    return (
      <div className={`${styles.head} ${theme === 'light' ? styles.light : ''}`}>
        <div
          ref={(ref) => {
            this.maim = ref;
          }}
          className={`${styles.main} ${contentWidth === 'Fixed' ? styles.wide : ''}`}
        >
          <div className={styles.left}>
            <div className={styles.logo} key="logo" id="logo">
              <Link to="/">
                <img id={styles.logoImage} src={logo} alt="logo" />
                <img id={styles.logoText} src={logoText} alt="logo text" />
              </Link>
            </div>
            <div className={styles.aboveBaseMenuDiv}>
              <BaseMenu {...this.props} className={styles.baseMenu} />
            </div>
          </div>
          <RightContent {...this.props} />
        </div>
      </div>
    );
  }
}
