import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerWrapper, ItemWrapper, RadioWrapper, Title } from '../CommonStyledComponents/CommonStyledComponents';
import { DashboardState } from '../../models';
import { changeDrawerVisibility } from '../../models/drawer';
import Loading from '../TFLoading';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import Dropdown from '../TFDropdown/TFDropdown';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import MXModalSCHContents from '../Modals/Maintenance/MXModalSCHContents';
import MXModalOOPContents from '../Modals/Maintenance/MXModalOOPContents';
import MXModalLLPContents from '../Modals/Maintenance/MXModalLLPContents';
import { addMaintenance, updateMaintenance } from '../../services/apiNew';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { fetchAircraftStatus } from '../../models/aircraft/actions';
import { updateMxItemSkipAPI } from '../../models/maintenance/actions';
import updateLocalDataObject from '../../utils/updateLocalDataObject';
import { Maintenance } from '../../models/maintenance';
import MaintenanceDrawerViewSection from './MaintenanceDrawerViewSection';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FormWrapper = styled.div`
  margin-left: 16px;
`;

const ContentWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px 0;
`;

const CancelButtonWrapper = styled.div`
  margin-left: 16px;
`;

const TypeWrapper = styled.div`
  margin-bottom: 16px;
`;

const MaintenanceDrawer: React.FC = () => {
  const {
    drawer: { drawerId, mode },
    maintenance: { maintenanceMap },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [itemData, setItemData] = useState<Maintenance | any>({ mx_type: 'scheduled' });
  const [mxType, setMxType] = useState('scheduled');
  const [mxArea, setMxArea] = useState('');
  const [areaOptions, setAreaOptions] = useState([]);
  const [foundArea, setFoundArea] = useState(null);

  const handleCloseDrawer = (): void => {
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const updateItemData = (changes: { value: any; key: string }[]): void => {
    const newItemData = itemData || {};
    const newData = updateLocalDataObject(changes, newItemData);
    setItemData(newData);
  };

  const validatePayload = (): boolean => {
    let valid = true;
    if (!mxArea) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.formError' }),
            message: formatMessage({ id: 'text.pleaseSelectArea' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      valid = false;
    }
    if (!itemData?.name) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.formError' }),
            message: formatMessage({ id: 'text.pleaseEneterTaskDescription' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      valid = false;
    }
    if (
      !itemData.flight_seconds_due &&
      !itemData.cycles_due &&
      !itemData.apu_seconds_due &&
      !itemData.date_due &&
      !itemData.datetime_due
    ) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.formError' }),
            message: formatMessage({ id: 'message.fillInDueValue' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      valid = false;
    }
    if (itemData?.calendar_days_amber_limit && itemData?.calendar_seconds_amber_limit) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.formError' }),
            message: formatMessage({ id: 'text.onlyOneIndividualThreshold' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      valid = false;
    }
    return valid;
  };

  const updateMxType = (value): void => {
    let newMxType;
    if (!itemData?.mx_type) {
      newMxType = `${mxArea || ''}_${value}`;
    } else {
      const lastIndex = itemData.mx_type.lastIndexOf('_');
      newMxType = `${itemData.mx_type.substring(0, lastIndex)}_${value}`;
    }
    updateItemData([{ value: newMxType, key: 'mx_type' }]);
  };

  const updateMxArea = (value): void => {
    let newMxArea;
    if (!itemData?.mx_type) {
      newMxArea = `${value}_${mxType || ''}`;
    } else {
      const lastIndex = itemData.mx_type.lastIndexOf('_');
      newMxArea = `${value}_${itemData.mx_type.substring(lastIndex + 1)}`;
    }
    updateItemData([{ value: newMxArea, key: 'mx_type' }]);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!itemData) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.formError' }),
            message: formatMessage({ id: 'text.formCannotBeBlank' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      return;
    }
    const aircraft = aircraftMap.get(id);
    setSubmitting(true);
    if (!validatePayload()) {
      setSubmitting(false);
      return;
    }
    itemData.mx_type = `${mxArea}_${mxType}`;
    if (itemData.unit_of_time === undefined) itemData.unit_of_time = 'days';
    if (itemData.months_tolerance || itemData.months_repeat) {
      itemData.days_tolerance = null;
      itemData.days_repeat = null;
    }
    if (itemData.days_tolerance || itemData.days_repeat) {
      itemData.months_tolerance = null;
      itemData.months_repeat = null;
    }
    if (itemData.months_tolerance || itemData.months_repeat) {
      itemData.days_tolerance = null;
      itemData.days_repeat = null;
    }

    const payload = {
      id: drawerId,
      aircraft: aircraft.id,
      itemData,
      aircraft_id: aircraft.id,
      organisation_id: aircraft.operator_id,
    };
    if (drawerId) {
      try {
        const response = await updateMaintenance(payload);
        if (response && response.status >= 200 && response.status < 400) {
          dispatch(
            addToast({
              payload: {
                title: formatMessage({ id: 'text.itemUpdated' }),
                message: formatMessage({ id: 'message.mxItemUpdated' }, { name: response.data.name }),
                type: ToastTypes.SUCCESS,
                category: ToastCategories.FLASH,
              },
            }),
          );
          dispatch(fetchAircraftStatus({ payload: aircraft.id }));
          dispatch(updateMxItemSkipAPI({ payload: response.data }));
          setSubmitting(false);
          dispatch(changeDrawerVisibility({ payload: false }));
        }
      } catch (error) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.failedToUpdateMXItem' }),
              type: ToastTypes.ERROR,
              message: '',
              category: ToastCategories.FLAG,
            },
          }),
        );
        setSubmitting(false);
      }
    } else {
      try {
        const response = await addMaintenance(payload);
        if (response && response.status >= 200 && response.status < 400) {
          dispatch(
            addToast({
              payload: {
                title: formatMessage({ id: 'text.itemCreated' }),
                message: formatMessage({ id: 'message.mxItemCreated' }, { name: response.data.name }),
                type: ToastTypes.SUCCESS,
                category: ToastCategories.FLASH,
              },
            }),
          );
          dispatch(fetchAircraftStatus({ payload: aircraft.id }));
          dispatch(updateMxItemSkipAPI({ payload: response.data }));
          setSubmitting(false);
          dispatch(changeDrawerVisibility({ payload: false }));
        }
      } catch (error) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'text.failedToCreateMxItem' }),
              type: ToastTypes.ERROR,
              message: '',
              category: ToastCategories.FLAG,
            },
          }),
        );
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      const aircraft = aircraftMap.get(id);

      const options = [{ title: 'Airframe', value: 'airframe' }];
      const engineCount = aircraft?.aircraft_type?.engine_count;
      if (engineCount) {
        for (let i = 0; i < engineCount; i += 1) {
          options.push({ title: `Engine ${i + 1}`, value: `engine_${i + 1}` });
        }
        if (aircraft.aircraft_type.engine_type === 'piston' || aircraft?.aircraft_type?.engine_type === 'turboprop') {
          for (let j = 0; j < engineCount; j += 1) {
            options.push({ title: `Prop_${j + 1}`, value: `prop_${j + 1}` });
          }
        }
        if (aircraft?.apu_installed) {
          options.push({ title: 'APU', value: 'apu' });
        }
      }
      setAreaOptions(options);
    }
  }, [aircraftMap, id]);

  useEffect(() => {
    if (drawerId) {
      const foundItem = maintenanceMap.get(drawerId);
      if (foundItem) {
        const { mx_type } = foundItem;
        const formattedType = mx_type.substring(mx_type.lastIndexOf('_') + 1, mx_type.length);
        const area = mx_type.substring(0, mx_type.lastIndexOf('_'));
        setMxArea(area);
        setMxType(formattedType);
        setItemData(foundItem);
      }
    }
  }, [drawerId, maintenanceMap]);

  useEffect(() => {
    if (mxArea) {
      const foundOption = areaOptions.find((option) => option.value === mxArea);
      setFoundArea(foundOption);
    }
  }, [areaOptions, mxArea]);

  return (
    <DrawerWrapper>
      <Loading loading={submitting} contain />
      <Wrapper>
        {mode === 'view' ? (
          <MaintenanceDrawerViewSection itemData={itemData} mxType={mxType} area={mxArea} />
        ) : (
          <FormWrapper>
            <Title data-testid="MaintenanceDrawer-Title">
              {drawerId ? formatMessage({ id: 'title.updateMXItem' }) : formatMessage({ id: 'title.addMXItem' })}
            </Title>
            <ContentWrapper>
              <TypeWrapper>
                {itemData?.package ? (
                  <RadioInputButton text="Package" active onClick={null} />
                ) : (
                  <RadioWrapper>
                    <RadioInputButton
                      text="Scheduled"
                      active={mxType === 'scheduled'}
                      onClick={(): void => {
                        setMxType('scheduled');
                        updateMxType('scheduled');
                      }}
                      marginRight="8px"
                    />
                    <RadioInputButton
                      text="Out of Phase"
                      active={mxType === 'oop'}
                      onClick={(): void => {
                        setMxType('oop');
                        updateMxType('oop');
                      }}
                      marginRight="8px"
                    />
                    <RadioInputButton
                      text="Life Limited Parts"
                      active={mxType === 'llp'}
                      onClick={(): void => {
                        setMxType('llp');
                        updateMxType('llp');
                      }}
                    />
                  </RadioWrapper>
                )}
              </TypeWrapper>
              <ItemWrapper marginBottom="16px" data-testid="MaintenanceDrawer-Area">
                <Dropdown
                  label="Area"
                  options={areaOptions}
                  initialValue={foundArea || null}
                  onSelect={(option): void => {
                    setMxArea(option.value);
                    updateMxArea(option.value);
                  }}
                  id=""
                />
              </ItemWrapper>
              {mxType === 'scheduled' && <MXModalSCHContents updateItemData={updateItemData} item={itemData} />}
              {mxType === 'oop' && <MXModalOOPContents updateItemData={updateItemData} item={itemData} />}
              {mxType === 'llp' && <MXModalLLPContents updateItemData={updateItemData} item={itemData} />}
              <ButtonWrapper data-testid="MaintenanceDrawer-Buttons">
                <Button size={ButtonSize.MEDIUM} onClick={handleSubmit}>
                  {mode === 'edit'
                    ? formatMessage({ id: 'form.button.updateItem' })
                    : formatMessage({ id: 'form.button.saveComplete' })}
                </Button>
                <CancelButtonWrapper>
                  <Button size={ButtonSize.MEDIUM} onClick={handleCloseDrawer} primary={false}>
                    Cancel
                  </Button>
                </CancelButtonWrapper>
              </ButtonWrapper>
            </ContentWrapper>
          </FormWrapper>
        )}
      </Wrapper>
    </DrawerWrapper>
  );
};

export default MaintenanceDrawer;
