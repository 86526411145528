import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Modal } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Maintenance } from '../../../models/maintenance';
import { updateScheduledMxItemsTolerances } from '../../../models/maintenance/actions';
import { DashboardState } from '../../../models';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import TypedToleranceCounter from './TypedToleranceCounter';

interface MxMultipleToleranceModalProps {
  visible: boolean;
  hideModal: () => void;
  items: Maintenance[];
  setLoading: (value: boolean) => void;
}

const StyledRow = styled.div`
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
`;

const StyledCol = styled.div`
  display: ${({ flex }): string => (flex ? 'flex' : 'block')};
`;

const StyledItemTitle = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  margin-bottom: 16px;
  position: relative;
  background-color: rgb(250, 251, 253);
  span {
    background: #fafbfd;
    display: inline-block;
    padding-right: 10px;
    position: relative;
    z-index: 1;
  }
  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #dfe0eb;
    position: absolute;
    top: 12px;
  }
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #181818;
  margin-bottom: 16px;
`;

const ModalContent = styled.div``;

const CancelWrapper = styled.div`
  margin-left: 10px;
`;

const NameWrapper = styled.span`
  text-transform: capitalize;
`;

const MxMultipleToleranceModal: React.FC<MxMultipleToleranceModalProps> = ({
  hideModal,
  visible,
  items,
  setLoading,
}) => {
  const {
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();

  const [currentItem, setCurrentItem] = useState<Maintenance>(null);
  const [passedItems, setPassedItems] = useState<Maintenance[]>([]);
  const [toleranceAppliedItems, setToleranceAppliedItems] = useState<
    { id: string; attributes: { [key: string]: unknown } }[]
  >([]);

  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const hasAPU = aircraftMap.get(id)?.apu_installed;

  const handleSubmit = () => {
    if (passedItems?.length !== 1) {
      const newArray = _.cloneDeep(passedItems);
      newArray.shift();
      setPassedItems([...newArray]);
    } else {
      if (toleranceAppliedItems?.length > 0) {
        dispatch(updateScheduledMxItemsTolerances({ items: toleranceAppliedItems, aircraft_id: aircraftId }));
        setLoading(true);
        setCurrentItem(null);
        setPassedItems([]);
        setToleranceAppliedItems([]);
      }
      hideModal();
    }
  };

  const toleranceChange = (field, value) => {
    const itemIndex = toleranceAppliedItems.findIndex((item) => item.id === currentItem.id);
    if (itemIndex === -1) {
      const newItem = { id: currentItem.id, attributes: { [field]: value } };
      toleranceAppliedItems.push(newItem);
      setToleranceAppliedItems([...toleranceAppliedItems]);
    } else {
      const newItems = [...toleranceAppliedItems];
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        attributes: { ...newItems[itemIndex].attributes, [field]: value },
      };
      setToleranceAppliedItems([...newItems]);
    }
  };

  const getItemAreaName = (item) => {
    if (!item) return '';
    const { mx_type, name } = item;
    let itemArea = mx_type.substring(0, mx_type.lastIndexOf('_'));
    if (itemArea === 'apu') itemArea = 'APU';
    return (
      <>
        <NameWrapper>{itemArea.replace(/_/g, ' ')}, </NameWrapper>
        {name}
      </>
    );
  };

  useEffect(() => {
    if (items) {
      setPassedItems(items);
    }
  }, [items]);

  useEffect(() => {
    if (passedItems?.length) {
      const newArray = _.cloneDeep(passedItems);
      const newItem = newArray[0];
      setCurrentItem(newItem);
    }
  }, [passedItems]);

  let submitButtonText =
    passedItems?.length === 1
      ? formatMessage({ id: 'text.applyToleranceSubmit' })
      : formatMessage({ id: 'form.button.applyTolerance' });

  if (toleranceAppliedItems?.length === 0 && passedItems?.length === 1) {
    submitButtonText = 'No changes made, close popup';
  }

  return (
    <Modal handleClose={hideModal} isOpen={visible}>
      <ModalTitle data-testid="MxMultipleToleranceModal--Title">
        {formatMessage({ id: 'title.applyTolerance' })} {getItemAreaName(currentItem)}
      </ModalTitle>
      <ModalContent data-testid="MxMultipleToleranceModal--Content">
        {currentItem ? (
          <>
            {currentItem.flight_seconds_due !== null && (
              <StyledRow marginBottom="16px">
                <StyledCol>
                  <StyledItemTitle data-testid="MxMultipleToleranceModal--FlightHoursTitle">
                    <span>{formatMessage({ id: 'period.fltHours' })}</span>
                  </StyledItemTitle>
                </StyledCol>
                <StyledCol>
                  <TypedToleranceCounter
                    itemTolerance={currentItem.flight_seconds_tolerance}
                    itemVisibleTolerance={currentItem.flight_seconds_visible_tolerance}
                    nextDue={currentItem.flight_seconds_due}
                    remaining={currentItem.flight_seconds_remaining}
                    currentItem={currentItem}
                    unitSingular={formatMessage({ id: 'units.hourShort' })}
                    unitPlural={formatMessage({ id: 'units.hoursShort' })}
                    type="seconds"
                    onChange={(value) => toleranceChange('flight_seconds_visible_tolerance', value)}
                    data-testid="toleranceCounterFlightHours"
                  />
                </StyledCol>
              </StyledRow>
            )}
            {currentItem.cycles_due !== null && (
              <StyledRow marginBottom="16px">
                <StyledCol>
                  <StyledItemTitle data-testid="MxMultipleToleranceModal--CyclesTitle">
                    <span>{formatMessage({ id: 'text.cycles' })}</span>
                  </StyledItemTitle>
                </StyledCol>
                <StyledCol>
                  <TypedToleranceCounter
                    itemTolerance={currentItem.cycles_tolerance}
                    itemVisibleTolerance={currentItem.cycles_visible_tolerance}
                    nextDue={currentItem.cycles_due}
                    remaining={currentItem.cycles_remaining}
                    currentItem={currentItem}
                    unitSingular={formatMessage({ id: 'units.cycle' })}
                    unitPlural={formatMessage({ id: 'units.cycles' })}
                    onChange={(value) => toleranceChange('cycles_visible_tolerance', value)}
                    data-testid="toleranceCounterCycles"
                  />
                </StyledCol>
              </StyledRow>
            )}
            {currentItem.date_due !== null && currentItem.unit_of_time === 'months' && (
              <StyledRow marginBottom="16px">
                <StyledCol>
                  <StyledItemTitle data-testid="MxMultipleToleranceModal--MonthsTitle">
                    <span>{formatMessage({ id: 'title.months' })}</span>
                  </StyledItemTitle>
                </StyledCol>
                <StyledCol>
                  <TypedToleranceCounter
                    dateFormat={userSettings?.dateFormat}
                    itemTolerance={currentItem.months_tolerance}
                    itemVisibleTolerance={currentItem.months_visible_tolerance}
                    nextDue={currentItem.date_due}
                    remaining={currentItem.days_remaining}
                    currentItem={currentItem}
                    unitSingular={formatMessage({ id: 'units.monthShort' })}
                    unitPlural={formatMessage({ id: 'units.monthsShort' })}
                    type="months"
                    onChange={(value) => toleranceChange('months_visible_tolerance', value)}
                    data-testid="toleranceCounterMonths"
                  />
                </StyledCol>
              </StyledRow>
            )}
            {currentItem.date_due !== null && currentItem.unit_of_time === 'days' && (
              <StyledRow marginBottom="16px">
                <StyledCol>
                  <StyledItemTitle data-testid="MxMultipleToleranceModal--DaysTitle">
                    <span>{formatMessage({ id: 'title.days' })}</span>
                  </StyledItemTitle>
                </StyledCol>
                <StyledCol>
                  <TypedToleranceCounter
                    dateFormat={userSettings?.dateFormat}
                    itemTolerance={currentItem.days_tolerance}
                    itemVisibleTolerance={currentItem.days_visible_tolerance}
                    nextDue={currentItem.date_due}
                    remaining={currentItem.days_remaining}
                    currentItem={currentItem}
                    unitSingular={formatMessage({ id: 'units.day' })}
                    unitPlural={formatMessage({ id: 'units.days' })}
                    type="days"
                    onChange={(value) => toleranceChange('days_visible_tolerance', value)}
                    data-testid="toleranceCounterDays"
                  />
                </StyledCol>
              </StyledRow>
            )}
            {hasAPU && currentItem.apu_seconds_due !== null && (
              <StyledRow marginBottom="16px">
                <StyledCol>
                  <StyledItemTitle data-testid="MxMultipleToleranceModal--ApuHoursTitle">
                    <span>{formatMessage({ id: 'period.APUhours' })}</span>
                  </StyledItemTitle>
                </StyledCol>
                <StyledCol>
                  <TypedToleranceCounter
                    itemTolerance={currentItem.apu_seconds_tolerance}
                    itemVisibleTolerance={currentItem.apu_seconds_visible_tolerance}
                    nextDue={currentItem.apu_seconds_due}
                    remaining={currentItem.apu_seconds_remaining}
                    currentItem={currentItem}
                    unitSingular={formatMessage({ id: 'units.apuHour' })}
                    unitPlural={formatMessage({ id: 'units.apuHours' })}
                    type="seconds"
                    onChange={(value) => toleranceChange('apu_seconds_visible_tolerance', value)}
                    data-testid="toleranceCounterAPUHours"
                  />
                </StyledCol>
              </StyledRow>
            )}
            <StyledRow>
              <StyledCol flex>
                <Button
                  onClick={() => handleSubmit()}
                  size={ButtonSize.MEDIUM}
                  data-testid="MxMultipleToleranceModal--SubmitButton"
                >
                  {submitButtonText}
                </Button>
                <CancelWrapper>
                  <Button size={ButtonSize.MEDIUM} onClick={hideModal} primary={false}>
                    {formatMessage({ id: 'text.cancel' })}
                  </Button>
                </CancelWrapper>
              </StyledCol>
            </StyledRow>
          </>
        ) : (
          <span>{formatMessage({ id: 'text.noApplyableTolerances' })}</span>
        )}
      </ModalContent>
    </Modal>
  );
};

export default MxMultipleToleranceModal;
