import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { StyledInput } from '../../components/FlightDrawer/FlightDrawer';
import { IntermittentFaultOccurrence } from '../../models/intermittentFaults';
import SelectPilot from '../../components/FlightDrawer/SelectPilot';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { People } from '../../models/aircraft';

interface IntermittentFaultOccurrenceFormProps {
  occurrence: IntermittentFaultOccurrence;
  editable?: boolean;
  people?: People[];
  onHandleInputChange?: (key: string, value: string) => void;
}

const Label = styled.div`
  font-size: 12px;
  font-weight: ${({ editable }): string => (editable ? '600' : 'normal')};
  line-height: 1.33;
  color: ${({ editable }): string => (editable ? '#242d41' : '#9f9f9f')};
  text-transform: ${({ editable }): string => (editable ? 'none' : 'uppercase')};
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
  gap: 1rem;
  > :first-child {
    grid-column: span 2;
    @media (max-width: 450px) {
      grid-column: span 1;
    }
  }
`;

const IntermittentFaultOccurrenceForm: React.FC<IntermittentFaultOccurrenceFormProps> = ({
  occurrence,
  editable,
  people,
  onHandleInputChange,
}) => {
  const { userSettings } = useSelector((state: DashboardState) => state);
  return (
    <div>
      <form>
        <FormGrid>
          <div>
            <Label editable={editable}>Further Details</Label>
            {editable ? (
              <StyledInput
                value={occurrence.details}
                type="text"
                id="occurrenceDetails"
                onChange={(e): void => {
                  onHandleInputChange('details', e.target.value);
                }}
              />
            ) : (
              <span>{occurrence.details}</span>
            )}
          </div>
          <div>
            <Label editable={editable}>Reported By</Label>
            {editable ? (
              <SelectPilot
                people={people || []}
                handlePersonChange={(personId: string): void => {
                  onHandleInputChange('reported_by_id', personId);
                }}
                pilotFlying={
                  occurrence.reported_by_name && occurrence.reported_by_id
                    ? { title: occurrence.reported_by_name, value: occurrence.reported_by_id }
                    : null
                }
              />
            ) : (
              <span>{occurrence.reported_by_name}</span>
            )}
          </div>
          <div>
            {editable ? (
              <DateTimePicker
                headerDate="Date Recorded"
                headerTime="Time Recorded"
                handleDateTimeChange={(value): void => onHandleInputChange('reported_at', value)}
                dateTime={occurrence.reported_at}
                noFuture
              />
            ) : (
              <>
                <Label editable={editable}>Reported At</Label>
                <span>{moment(occurrence.reported_at).utc().format(`${userSettings.dateFormat} HH:mm`) || '-'}</span>
              </>
            )}
          </div>
        </FormGrid>
      </form>
    </div>
  );
};

export default IntermittentFaultOccurrenceForm;
