import { useMutation } from '@tanstack/react-query';
import { createFRATQuestion } from '../../services/api';

const useCreateFRATQuestion = ({ handleAddFRATQuestionOnSuccess, handleAddFRATQuestionOnError }) => {
  const addFRATQuestion = useMutation({
    mutationFn: (payload: {
      question: string;
      sequence_number: number;
      flight_risk_assessment_question_category_id: string;
      flight_risk_assessment_options_attributes: { option: string; sequence_number: number; weight: number }[];
    }) => {
      return createFRATQuestion(payload);
    },
    onSuccess: (data) => {
      handleAddFRATQuestionOnSuccess(data);
    },
    onError: (err: any) => handleAddFRATQuestionOnError(err),
  });

  return addFRATQuestion;
};

export default useCreateFRATQuestion;
