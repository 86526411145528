/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { deleteMEL, getMEL, getMELItems, getMELs, putMEL } from '../../services/api';
import { MELsActionTypes } from '.';

export const get =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getMEL(payload.id, payload.chapterNumber);
    dispatch({
      type: MELsActionTypes.SAVE,
      payload: response,
    });
  };

export const getMelItemsForAircraft =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getMELItems(payload);
    if (response && response.r && response.r.status > 199 && response.r.status < 399) {
      dispatch({
        type: MELsActionTypes.SAVE_AIRCRAFT_MEL,
        payload: { mel: response.data, id: payload.id },
      });
    }
  };

export const getAll =
  () =>
  async (dispatch): Promise<any> => {
    const response = await getMELs();
    dispatch({
      type: MELsActionTypes.SAVE_ALL,
      payload: response,
    });
  };

export const addWithoutApi =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: MELsActionTypes.SAVE,
      payload,
    });
  };

export const edit =
  ({ payload, source, cb, melType }) =>
  async (dispatch): Promise<any> => {
    const masterMel = melType === 'master' || payload.melType === 'master';
    const response = await putMEL(payload);

    const sendToast = ({ title, type }): void => {
      dispatch(
        addToast({
          payload: {
            title,
            type,
            category: type === ToastTypes.SUCCESS ? ToastCategories.FLASH : ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    };

    if (response && response.statusCode > 199 && response.statusCode < 400) {
      const melItem = response.body;

      dispatch({
        type: MELsActionTypes.UPDATE,
        payload: melItem,
      });

      if (source === 'modal') {
        sendToast({
          title: intl.formatMessage(
            masterMel ? { id: 'message.masterMelChapterSaved' } : { id: 'message.melChapterSaved' },
          ),
          type: ToastTypes.SUCCESS,
        });
      } else {
        sendToast({
          title: intl.formatMessage(
            masterMel ? { id: 'message.masterMelChapterSaved' } : { id: 'message.melChapterSaved' },
          ),
          type: ToastTypes.SUCCESS,
        });
      }
      if (cb) cb();
    } else {
      sendToast({
        title: intl.formatMessage({ id: 'message.masterMelProblemSave' }),
        type: ToastTypes.ERROR,
      });
    }
  };

export const editWithoutApi =
  ({ payload, selectedChapter }) =>
  async (dispatch): Promise<any> => {
    const response = payload;
    if (response && response.statusCode > 199 && response.statusCode < 400) {
      const melItem = response.body;
      if (selectedChapter) {
        melItem.mel_items = melItem.mel_items.filter(
          (item) =>
            item.chapter_number ===
            parseInt(payload.mel_item ? payload.mel_items[0].chapter_number : selectedChapter || 21, 10),
        );
      }
      dispatch({
        type: MELsActionTypes.SAVE,
        payload: melItem,
      });
    }
  };

export const remove =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await deleteMEL(payload);
    if (response.statusCode > 199 && response.statusCode < 400) {
      dispatch({
        type: MELsActionTypes.DELETE,
        payload,
      });
    }
  };

export const removeItem =
  ({ payload, melType }) =>
  async (dispatch): Promise<any> => {
    const masterMel = melType === 'master';

    const response = await putMEL(payload);

    if (response.statusCode > 199 && response.statusCode < 400) {
      const melItem = response.body;

      if (payload.selectedChapter) {
        melItem.mel_items = melItem.mel_items.filter(
          (item) => item.chapter_number === parseInt(payload.selectedChapter, 10),
        );
      }

      dispatch({
        type: MELsActionTypes.DELETEITEM,
        payload,
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage(
              masterMel ? { id: 'message.masterMelChapterSaved' } : { id: 'message.melChapterSaved' },
            ),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    }
  };
