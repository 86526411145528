import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Toast, ToastCategories, ToastTypes } from '../../models/toasts';
import { DashboardState } from '../../models';
import FlashToast from './FlashToast';

const DisplayToastWrapper = styled.div`
  position: fixed;
  bottom: ${({ position }): string => `${position * 100 + 24}px`};
  right: 24px;
  z-index: 1000000;
  transition: bottom 0.3s ease;
`;

const ToastBuilder: React.FC = () => {
  const {
    toasts: { toasts },
  } = useSelector((state: DashboardState) => state);

  const [flashSuccessToasts, setFlashSuccessToasts] = useState<Toast[]>([]);
  const [flashErrorToasts, setFlashErrorToasts] = useState<Toast[]>([]);
  const [flashInfoToasts, setFlashInfoToasts] = useState<Toast[]>([]);
  const [flashWarningToasts, setFlashWarningToasts] = useState<Toast[]>([]);
  const [flagSuccessToasts, setFlagSuccessToasts] = useState<Toast[]>([]);
  const [flagErrorToasts, setFlagErrorToasts] = useState<Toast[]>([]);
  const [flagInfoToasts, setFlagInfoToasts] = useState<Toast[]>([]);
  const [flagWarningToasts, setFlagWarningToasts] = useState<Toast[]>([]);
  const [populatedArrays, setPopulatedArrays] = useState<Toast[][]>([]);

  const getIndexOf = (passedArray): number => {
    const index = populatedArrays.findIndex((array) => array === passedArray);
    return index;
  };

  useEffect(() => {
    if (toasts) {
      const newFlashSuccessToasts = toasts.filter(
        (t) => t.type === ToastTypes.SUCCESS && t.category === ToastCategories.FLASH,
      );
      const newFlashErrorToasts = toasts.filter(
        (t) => t.type === ToastTypes.ERROR && t.category === ToastCategories.FLASH,
      );
      const newFlashInfoToasts = toasts.filter(
        (t) => t.type === ToastTypes.INFO && t.category === ToastCategories.FLASH,
      );
      const newFlashWarningToasts = toasts.filter(
        (t) => t.type === ToastTypes.WARNING && t.category === ToastCategories.FLASH,
      );
      const newFlagSuccessToasts = toasts.filter(
        (t) => t.type === ToastTypes.SUCCESS && t.category === ToastCategories.FLAG,
      );
      const newFlagErrorToasts = toasts.filter(
        (t) => t.type === ToastTypes.ERROR && t.category === ToastCategories.FLAG,
      );
      const newFlagInfoToasts = toasts.filter((t) => t.type === ToastTypes.INFO && t.category === ToastCategories.FLAG);
      const newFlagWarningToasts = toasts.filter(
        (t) => t.type === ToastTypes.WARNING && t.category === ToastCategories.FLAG,
      );
      setFlashSuccessToasts([...newFlashSuccessToasts]);
      setFlashErrorToasts([...newFlashErrorToasts]);
      setFlashInfoToasts([...newFlashInfoToasts]);
      setFlashWarningToasts([...newFlashWarningToasts]);
      setFlagSuccessToasts([...newFlagSuccessToasts]);
      setFlagErrorToasts([...newFlagErrorToasts]);
      setFlagInfoToasts([...newFlagInfoToasts]);
      setFlagWarningToasts([...newFlagWarningToasts]);
    }
  }, [toasts]);

  useEffect(() => {
    const possibleArrays = [
      flagErrorToasts,
      flashErrorToasts,
      flagWarningToasts,
      flashWarningToasts,
      flagInfoToasts,
      flashInfoToasts,
      flagSuccessToasts,
      flashSuccessToasts,
    ];
    const newArray = [];
    for (let i = 0; i < possibleArrays.length; i += 1) {
      if (possibleArrays[i].length) newArray.push(possibleArrays[i]);
    }
    setPopulatedArrays([...newArray]);
  }, [
    flagErrorToasts,
    flashErrorToasts,
    flagWarningToasts,
    flashWarningToasts,
    flagInfoToasts,
    flashInfoToasts,
    flagSuccessToasts,
    flashSuccessToasts,
  ]);

  return (
    <>
      {populatedArrays.map((array) => {
        return (
          <DisplayToastWrapper
            key={getIndexOf(array)}
            position={getIndexOf(array)}
            data-testid="ToastBuilder--DisplayWrapper"
          >
            <FlashToast toast={array[0]} index={array.findIndex((t) => _.isEqual(t, array[0]))} total={array.length} />
          </DisplayToastWrapper>
        );
      })}
    </>
  );
};

export default ToastBuilder;
