import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import estDueTimeOfAircraft from '../../utils/aircraftEstDueTime';
import ProgressBarMX from './ProgressBarMX';
import styles from './ProgressBars.module.less';

const ProgressBars = ({ title, mxItem, hasAPU, unitsOnly, layout, intl: { formatMessage } }) => {
  const isDaysCritical = () => {
    let status = '';
    if (mxItem.widgets.days.tolerance !== null && mxItem.widgets.days.remaining < 0) {
      const dateOfRemaining = moment().add(mxItem.widgets.days.remaining, 'days');
      const remainingWithTolerance = moment(dateOfRemaining).add(mxItem.widgets.days.tolerance, 'months');
      if (moment(remainingWithTolerance).diff(moment(), 'days') >= 0) {
        status = 'critical';
      } else {
        status = '';
      }
    }
    return status;
  };

  const aircraftThresholds = {
    flight_seconds:
      mxItem?.widgets?.flight_hours?.remaining < 0 &&
      mxItem?.widgets?.flight_hours?.tolerance !== null &&
      mxItem?.widgets?.flight_hours?.remaining + mxItem?.widgets?.flight_hours?.tolerance > 0
        ? 'critical'
        : '',
    cycles:
      mxItem?.widgets?.cycles?.remaining < 0 &&
      mxItem?.widgets?.cycles?.tolerance !== null &&
      mxItem?.widgets?.cycles?.remaining + mxItem?.widgets?.cycles?.tolerance > 0
        ? 'critical'
        : '',
    days: isDaysCritical,
    apu_seconds:
      mxItem?.widgets?.apu_hours?.remaining < 0 &&
      mxItem?.widgets?.apu_hours?.tolerance !== null &&
      mxItem?.widgets?.apu_hours?.remaining + mxItem?.widgets?.apu_hours?.tolerance > 0
        ? 'critical'
        : '',
  };

  const hrsItem = {
    remaining: mxItem?.flight_seconds_remaining,
    threshold: aircraftThresholds?.flight_seconds,
    tolerance: mxItem?.widgets?.flight_hours?.tolerance,
    format: 'seconds',
    dot: true,
  };

  const cyclesItem = {
    remaining: mxItem?.cycles_remaining,
    threshold: aircraftThresholds?.cycles,
    tolerance: mxItem?.widgets?.cycles?.tolerance,
    format: 'number',
    dot: true,
  };

  const daysRemaining = estDueTimeOfAircraft(mxItem?.days_remaining);

  const daysItem = {
    remaining: daysRemaining,
    threshold: aircraftThresholds?.days,
    tolerance: mxItem?.widgets?.days?.tolerance,
    format: 'days',
    dot: true,
  };

  const apuHrsItem = {
    remaining: mxItem?.apu_seconds_remaining,
    threshold: aircraftThresholds?.apu_seconds,
    tolerance: mxItem?.widgets?.apu_hours?.tolerance,
    format: 'seconds',
    dot: true,
  };

  return (
    <>
      {title && (
        <div className={styles.itemTitle} data-test="progressBarsTitle">
          {title}
        </div>
      )}
      <ProgressBarMX
        aircraft
        enabled={!(mxItem.flight_seconds_remaining == null)}
        unitSingular={unitsOnly ? formatMessage({ id: 'units.hour' }) : formatMessage({ id: 'units.hourLeft' })}
        unitPlural={unitsOnly ? formatMessage({ id: 'units.hours' }) : formatMessage({ id: 'units.hoursLeft' })}
        item={hrsItem}
        layout={layout}
        data-test="progressBarFlightHrs"
      />
      <ProgressBarMX
        aircraft
        enabled={!(mxItem.cycles_remaining == null)}
        unitSingular={unitsOnly ? formatMessage({ id: 'units.cycle' }) : formatMessage({ id: 'units.cycleLeft' })}
        unitPlural={unitsOnly ? formatMessage({ id: 'units.cycles' }) : formatMessage({ id: 'units.cyclesLeft' })}
        item={cyclesItem}
        layout={layout}
        data-test="progressBarCycles"
      />
      <ProgressBarMX
        aircraft
        enabled={!(mxItem.days_remaining == null)}
        unitSingular={unitsOnly ? formatMessage({ id: 'units.day' }) : formatMessage({ id: 'units.dayLeft' })}
        unitPlural={unitsOnly ? formatMessage({ id: 'units.days' }) : formatMessage({ id: 'units.daysLeft' })}
        item={daysItem}
        layout={layout}
        data-test="progressBarDays"
      />
      {hasAPU && (
        <ProgressBarMX
          aircraft
          enabled={!(mxItem.apu_seconds_remaining == null)}
          unitSingular={unitsOnly ? formatMessage({ id: 'units.apuHour' }) : formatMessage({ id: 'units.apuHourLeft' })}
          unitPlural={unitsOnly ? formatMessage({ id: 'units.apuHours' }) : formatMessage({ id: 'units.apuHoursLeft' })}
          item={apuHrsItem}
          layout={layout}
          data-test="progressBarAPU"
        />
      )}
    </>
  );
};

ProgressBars.defaultProps = {
  title: '',
  hasAPU: false,
  unitsOnly: false,
  layout: null,
};

ProgressBars.propTypes = {
  title: PropTypes.string,
  mxItem: PropTypes.object.isRequired,
  hasAPU: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  unitsOnly: PropTypes.bool,
  layout: PropTypes.string,
};
export default injectIntl(ProgressBars);
