/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';

interface CloseBackgroundProps {
  handleClose: () => void;
  opaqueBackground?: boolean;
}

const ClickableBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props): string => (props.opaqueBackground ? 'rgba(3, 44, 82, 0.33)' : 'transparent')};
`;

const CloseableBackground: React.FC<CloseBackgroundProps> = ({ handleClose, children, opaqueBackground }) => {
  return (
    <>
      <ClickableBackground role="button" onClick={handleClose} opaqueBackground={opaqueBackground} />
      {children}
    </>
  );
};

export default CloseableBackground;
