import { Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './ExpandableCard.module.less';
import SmallTag from './SmallTag';

const MXDetailsPackage = ({ item, inModal }) => {
  const { formatMessage } = useIntl();
  const getPackageItem = (number, pkgItem) => {
    return (
      <div key={pkgItem} className={`${styles.singlePkgItem} ${styles[`singlePkgItem${pkgItem.draft_type}`]}`}>
        <span className={styles.number} data-test="singleNumber">{`${number}.`}</span>
        <div className={styles.tagWrapper} data-test="singleTag">
          <SmallTag
            type={pkgItem.mx_type.substring(pkgItem.mx_type.lastIndexOf('_'), pkgItem.mx_type.length).substring(1, 4)}
          />
        </div>
        <span className={styles.referenceSpan} data-test="singleReference">
          {pkgItem.reference}
        </span>
        <span data-test="singleName">{`- ${pkgItem.name}`}</span>
      </div>
    );
  };
  return item.package && item.package_items.length > 0 ? (
    <Row className={`${styles.mxPackageDetails} ${inModal ? styles.mxPackageDetailsModal : undefined}`}>
      <Col md={24}>
        <div className={styles.partTitle} data-test="detailsTitle">
          {formatMessage(
            { id: 'text.itemInsidePackage' },
            { number: item.package_items.length > 0 ? `(${item.package_items.length})` : null },
          )}
          :
        </div>
        <div className={styles.partDetails}>
          {item.package_items.map((pkgItem, key) => getPackageItem(key + 1, pkgItem))}
        </div>
      </Col>
    </Row>
  ) : null;
};

MXDetailsPackage.propTypes = {
  item: PropTypes.object.isRequired,
  inModal: PropTypes.bool,
};

MXDetailsPackage.defaultProps = {
  inModal: false,
};

export default MXDetailsPackage;
