import { useSelector } from 'react-redux';
import { DashboardState } from '../models';

export default function (flag: string, type: string, id: string): boolean {
  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  let active = false;
  if (type === 'aircraft') {
    const foundAircraft = aircraftMap.get(id);
    if (foundAircraft) {
      const featureFlags = foundAircraft?.feature_flags;
      if (featureFlags?.length) {
        active = featureFlags.some((ff) => ff?.feature_name === flag);
      }
    }
  } else if (type === 'operator') {
    const foundOperator = operators.find((op) => op?.id === id);
    if (foundOperator) {
      const featureFlags = foundOperator?.feature_flags;
      if (featureFlags?.length) {
        active = featureFlags.some((ff) => ff?.feature_name === flag);
      }
    }
  }
  return active;
}
