import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Toast, ToastTypes, removeToast } from '../../models/toasts';
import errorIcon from '../../assets/login-error.svg';
import warningIcon from '../../assets/login-error-amber.svg';
import successIcon from '../../assets/icon-complete.svg';
import closeIcon from '../../assets/icon-action-close.svg';
import infoIcon from '../../assets/icon-info.svg';

interface BasicToastProps {
  toast: Toast;
  index: number;
  total: number;
  setClosingToastIndex: (index: number | null) => void;
}

const ToastContainer = styled.div`
  width: 400px;
  min-height: ${({ message }): string => (message ? '90px' : '50px')};
  padding: 12px;
  ${({ type, theme }): string => {
    if (type === ToastTypes.SUCCESS) {
      return `border-bottom: 2px solid ${theme.colours.greenToast};`;
    }
    if (type === ToastTypes.ERROR) {
      return `border-bottom: 2px solid ${theme.colours.red};`;
    }
    if (type === ToastTypes.WARNING) {
      return `border-bottom: 2px solid ${theme.colours.amberToast};`;
    }
    if (type === ToastTypes.INFO) {
      return `border-bottom: 2px solid ${theme.colours.blue};`;
    }
    return '';
  }}
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 20px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 20px;
  span {
    width: fit-content;
  }
  img {
    height: 14px;
    width: 14px;
    margin-right: 12px;
    margin-top: 4px;
  }
`;

const Title = styled.div`
  color: ${({ type, theme }): string => {
    if (type === ToastTypes.SUCCESS) {
      return theme.colours.green;
    }
    if (type === ToastTypes.ERROR) {
      return theme.colours.red;
    }
    if (type === ToastTypes.WARNING) {
      return theme.colours.amberToast;
    }
    if (type === ToastTypes.INFO) {
      return theme.colours.blue;
    }
    return '';
  }};
`;

const MessageWrapper = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }): string => theme.colors.black80Alpha};
  padding-left: 26px;
  margin-top: 12px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: flex-start;
`;

const NumberWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const ToastNumber = styled.div`
  color: ${({ theme }): string => theme.colors.black50Alpha};
  font-size: 12px;
  line-height: 1.33;
  width: auto;
  flex-shrink: 0;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  img {
    width: 14px;
    height: 14px;
  }
  :focus {
    outline: none;
  }
`;

const BasicToast: React.FC<BasicToastProps> = ({ toast, index, total, setClosingToastIndex }) => {
  const dispatch = useDispatch();

  const handleRemoveToast = (): void => {
    setClosingToastIndex(index);
    setTimeout(() => {
      dispatch(removeToast({ payload: toast }));
      setClosingToastIndex(null);
    }, 300);
  };

  return (
    <ToastContainer type={toast?.type} message={toast?.message}>
      <HeaderWrapper>
        <Header>
          {toast.type === ToastTypes.SUCCESS && (
            <img src={successIcon} alt="success" data-testid="FlashToast--SuccessIcon" />
          )}
          {toast.type === ToastTypes.ERROR && <img src={errorIcon} alt="error" data-testid="FlashToast--ErrorIcon" />}
          {toast.type === ToastTypes.WARNING && (
            <img src={warningIcon} alt="warning" data-testid="FlashToast--WarningIcon" />
          )}
          {toast.type === ToastTypes.INFO && <img src={infoIcon} alt="info" data-testid="FlashToast--InfoIcon" />}
          <Title type={toast.type} data-testid="FlashToast--Title">
            <span>{toast?.title}</span>
          </Title>
        </Header>
        <CloseButton onClick={(): void => handleRemoveToast()} data-testid="FlashToast--CloseButton">
          <img src={closeIcon} alt="close" data-testid="FlashToast--CloseIcon" />
        </CloseButton>
      </HeaderWrapper>
      {toast?.message || total > 1 ? (
        <NumberWrapper>
          {toast?.message ? (
            <MessageWrapper data-testid="FlashToast--Message">{toast?.message}</MessageWrapper>
          ) : (
            <div />
          )}
          {total > 1 ? <ToastNumber data-testid="FlashToast--Number">{`${index + 1} of ${total}`}</ToastNumber> : null}
        </NumberWrapper>
      ) : null}
    </ToastContainer>
  );
};

export default BasicToast;
