/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import iIcon from '../../assets/i.svg';
import { DashboardState } from '../../models';

const BannerWrapper = styled.div`
  background: #fbfcfd;
  border-radius: 2px;
  border: #126fd6 1px solid;
  height: ${({ isMobile }): string => (isMobile ? 'auto' : '70px')};
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding-right: 20px;
`;

const IconWrapper = styled.div`
  background-image: linear-gradient(to bottom, #126fd6, #57aaff);
  width: 40px;
  height: ${({ isMobile }): string => (isMobile ? '126px' : '70px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0;
`;

const DrawerInfo: React.FC = () => {
  const isMobile = window.innerWidth < 451;
  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  return (
    <BannerWrapper isMobile={isMobile}>
      <IconWrapper isMobile={isMobile}>
        <img src={iIcon} alt="info icon" />
      </IconWrapper>
      {`${operatorSettings?.trip_update}s allow recording aircraft state changes in the form of additional hours or cycles, or overriding the
      totals carried forward if, for example, part changes have occured.`}
    </BannerWrapper>
  );
};

export default DrawerInfo;
