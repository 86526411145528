import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { DutyLog, Trip } from '../../models/trips';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import { DisplayText, Header, SectionHeader } from '../FlightDrawer/FlightDrawer';
import { Operator } from '../../models/userSettings';
import TFCheckBox from '../TFCheckBoxes/TFCheckBox';

interface TripCrewDutyLogsProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any, secondKey?: string, secondValue?: any) => void;
  aircraftId: string;
  currentOp: Operator;
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ editable, isMobile }): string => {
    if (editable && isMobile) return '1fr';
    if (editable || isMobile) return '1fr 1fr';
    return '1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  padding: 16px 20px;
  border-top: none;
`;

const SignatureSection = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 4px 0 20px 20px;
`;

const StyledImg = styled.img`
  width: 240px;
  margin-left: 12px;
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 4px;
`;

const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PartsDiv = styled.div`
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(36, 45, 65 0.8);
  line-height: 1.33;
`;

const SectionWrapper = styled.div`
  margin-bottom: 32px;
`;

const AccordionWrapper = styled.div`
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  grid-column: 1 / span-2;
`;

const SubHeader = styled.div`
  font-size: 12px;
  color: #242d41;
  margin-bottom: 12px;
  grid-column: 1 / span-2;
`;

const SplitOnDiv = styled.div`
  align-self: end;
`;

const TripCrewDutyLogs: React.FC<TripCrewDutyLogsProps> = ({
  trip,
  editable,
  updateTripData,
  aircraftId,
  currentOp,
}) => {
  const {
    userSettings: { dateFormat, details },
    drawer: { mode },
    people: { peopleMap },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const dutyNameOverride = aircraftMap.get(aircraftId)?.standard_fields?.duty_log?.name_override;

  const [dutyLogArray, setDutyLogArray] = useState([]);
  const [captain, setCaptain] = useState(null);
  const [firstOfficer, setFirstOfficer] = useState(null);
  // const [additionalCrew, setAdditionalCrew] = useState([]);

  const handleDateTimeChange = (value: string, id: string, key: string): void => {
    const newArray = [...dutyLogArray];

    const foundLogIndex = dutyLogArray.findIndex((log) => log?.person_id === id);
    newArray[foundLogIndex] = { ...newArray[foundLogIndex], [`${key}`]: new Date(value).toISOString() };
    setDutyLogArray(newArray);
    updateTripData('duty_logs', newArray);
    updateTripData('duty_logs_attributes', newArray);
  };

  const handleCheckboxClick = (value: boolean, id: string, key: string): void => {
    const newArray = [...dutyLogArray];

    const foundLogIndex = dutyLogArray.findIndex((log) => log?.person_id === id);
    newArray[foundLogIndex] = { ...newArray[foundLogIndex], [`${key}`]: value };
    if (key === 'start_carried_over' && value === true) {
      newArray[foundLogIndex] = { ...newArray[foundLogIndex], [`end_carried_forward`]: !value };
    }
    if (key === 'end_carried_forward' && value === true) {
      newArray[foundLogIndex] = { ...newArray[foundLogIndex], [`start_carried_over`]: !value };
    }
    setDutyLogArray(newArray);
    updateTripData('duty_logs', newArray);
    updateTripData('duty_logs_attributes', newArray);
  };

  useEffect(() => {
    const captainId = trip?.captain_id || trip?.captain?.id || null;
    const firstOfficerId = trip?.first_officer_id || trip?.first_officer?.id || null;
    if (captainId) {
      const foundCaptain = peopleMap.get(captainId);
      setCaptain({ id: foundCaptain?.id, first_name: foundCaptain?.first_name, last_name: foundCaptain?.last_name });
    } else {
      setCaptain(null);
    }
    if (firstOfficerId) {
      const foundFirstOfficer = peopleMap.get(firstOfficerId);
      setFirstOfficer({
        id: foundFirstOfficer?.id,
        first_name: foundFirstOfficer?.first_name,
        last_name: foundFirstOfficer?.last_name,
      });
    } else {
      setFirstOfficer(null);
    }
  }, [peopleMap, trip]);

  useEffect(() => {
    const newDuty = trip?.duty_logs_attributes || trip?.duty_logs;
    const removeDuplicates = (array) => {
      const uniqueValues = new Set();
      const result = [];

      for (const obj of array) {
        const value = obj?.person_id;

        if (!uniqueValues.has(value)) {
          uniqueValues.add(value);
          result.push(obj);
        }
      }
      return result;
    };
    const dutyLogs = newDuty && removeDuplicates(newDuty);
    if (dutyLogs) {
      const captainId = trip?.captain_id || trip?.captain?.id || null;
      const firstOfficerId = trip?.first_officer_id || trip?.first_officer?.id || null;
      const dutyTracked = currentOp?.person_roles.filter((role) => role.duty_tracked) || [];
      const dutyTrackedPosition = dutyTracked.map((dt) => dt.id);
      let dutyTrackedAdditonalCrew = [];
      const additCrew = trip?.additional_crews_attributes || trip?.additional_crews;
      if (mode === 'add') {
        dutyTrackedAdditonalCrew =
          additCrew?.filter(
            // eslint-disable-next-line no-underscore-dangle
            (crew) => dutyTrackedPosition.includes(crew.operator_person_role_id) && crew._destroy !== true,
          ) || [];
      } else {
        dutyTrackedAdditonalCrew =
          additCrew?.filter(
            // eslint-disable-next-line no-underscore-dangle
            (crew) => dutyTrackedPosition.includes(crew.operator_person_role_id),
          ) || [];
      }
      if (dutyLogs.length) {
        if (mode === 'view') {
          setDutyLogArray(dutyLogs);
          return;
        }
        if (mode === 'edit') {
          setDutyLogArray(dutyLogs);
        }
        let captainsLog;
        let firstOfficerLog;
        const additionalLogs = [];
        if (captainId) {
          if (mode === 'edit') {
            captainsLog = dutyLogs.find((log) => log?.person_id === captainId) || {
              person_id: captainId,
            };
          } else {
            captainsLog = dutyLogArray.find((log) => log?.person_id === captainId) || {
              person_id: captainId,
            };
          }
        }
        if (firstOfficerId) {
          if (mode === 'edit') {
            firstOfficerLog = dutyLogs.find((log) => log?.person_id === firstOfficerId) || {
              person_id: firstOfficerId,
            };
          } else {
            firstOfficerLog = dutyLogArray.find((log) => log?.person_id === firstOfficerId) || {
              person_id: firstOfficerId,
            };
          }
        }
        if (dutyTrackedAdditonalCrew.length > 0) {
          dutyTrackedAdditonalCrew.forEach((crew, index) => {
            if (mode === 'edit') {
              const existingDutyLog = dutyLogs.find((log) => log?.person_id === crew.person_id);
              if (existingDutyLog) {
                additionalLogs.push(existingDutyLog);
              } else {
                let count = 0;
                if (captainsLog) count += 1;
                if (firstOfficerLog) count += 1;
                const previousDutyLog = dutyLogs[index + count];
                if (previousDutyLog) {
                  previousDutyLog.person_id = crew.person_id;
                  additionalLogs.push(previousDutyLog);
                } else {
                  const dutyLog = dutyLogs.find((log) => log?.person_id === crew.person_id) || {
                    person_id: crew.person_id,
                    end_carried_forward: false,
                    end_time: null,
                    integration_data: {},
                    position: null,
                    split_off: null,
                    split_on: null,
                    start_carried_over: false,
                    start_time: null,
                    trip_id: trip.id,
                  };
                  additionalLogs.push(dutyLog);
                }
              }
            } else {
              const dutyLog = dutyLogArray.find((log) => log?.person_id === crew.person_id) || {
                person_id: crew.person_id,
              };
              additionalLogs.push(dutyLog);
            }
          });
        }
        const localDutyLogs = [];

        if (captainsLog) localDutyLogs.push(captainsLog);
        if (firstOfficerLog) localDutyLogs.push(firstOfficerLog);
        if (additionalLogs.length) localDutyLogs.push(...additionalLogs);
        if (JSON.stringify(trip.duty_logs) !== JSON.stringify(localDutyLogs)) {
          setDutyLogArray(localDutyLogs);
          updateTripData('duty_logs', localDutyLogs, 'duty_logs_attributes', localDutyLogs);
        }
      } else {
        const newArray = [];
        if (captainId) {
          newArray.push({ person_id: captainId });
        }
        if (firstOfficerId) {
          newArray.push({ person_id: firstOfficerId });
        }
        if (dutyTrackedAdditonalCrew.length > 0) {
          dutyTrackedAdditonalCrew.forEach((crew) => {
            newArray.push({ person_id: crew.person_id });
          });
        }
        if (newArray.length > 0) {
          setDutyLogArray(newArray);
          updateTripData('duty_logs', newArray, 'duty_logs_attributes', newArray);
        }
      }
    } else {
      updateTripData('duty_logs', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip, details, mode, updateTripData, currentOp]);

  const getHeaderText = (log: DutyLog): { position: string; first_name: string; last_name: string } => {
    if (log.person_id === captain?.id) {
      return { position: 'PIC', first_name: captain?.first_name, last_name: captain?.last_name };
    }
    if (log.person_id === firstOfficer?.id) {
      return { position: 'SIC', first_name: firstOfficer?.first_name, last_name: firstOfficer?.last_name };
    }
    const person = peopleMap.get(log.person_id);
    const role = trip?.additional_crews?.find((crew) => crew.person_id === log.person_id);
    return {
      position: role?.position || 'Additional Crew',
      first_name: person?.first_name,
      last_name: person?.last_name,
    };
  };

  const isMobile = window.innerWidth < 451;
  const displayLog = dutyLogArray?.map((log, index) => {
    const personInfo = getHeaderText(log);
    // eslint-disable-next-line no-underscore-dangle
    if (log._destroy) {
      return null;
    }
    return (
      <AccordionWrapper key={log.id} marginBottom={index !== dutyLogArray.length - 1}>
        <Accordion
          dash
          add
          isExpanded={mode === 'add'}
          headerContent={
            <RowHeader data-testid={`TripCrewDutyLogs-RowHeader${index}`}>
              <div>
                <span>
                  {`${personInfo?.position}: `}
                  <BoldText>
                    {personInfo?.first_name} {personInfo?.last_name}
                  </BoldText>
                </span>
              </div>
              <PartsDiv>4 parts</PartsDiv>
            </RowHeader>
          }
        >
          <ContentWrapper isMobile={isMobile} editable={editable} data-testid={`TripCrewDutyLogs-RowContent${index}`}>
            <div>
              {editable ? (
                <>
                  <SubHeader>On times</SubHeader>
                  <DateTimePicker
                    handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'start_time')}
                    dateTime={log?.start_time}
                    headerDate="On Date"
                    headerTime="On Time"
                  />
                </>
              ) : (
                <>
                  <SubHeader>On times</SubHeader>
                  <Header>On</Header>
                  <DisplayText>
                    {log?.start_time ? moment(log?.start_time).utc().format(`${dateFormat} HH:mm`) : '-'}
                  </DisplayText>
                </>
              )}
            </div>
            <SplitOnDiv>
              {editable ? (
                <TFCheckBox
                  value={log?.start_carried_over}
                  checked={log?.start_carried_over === true}
                  title="Start carried over"
                  onChange={(value): void => handleCheckboxClick(!value, log?.person_id, 'start_carried_over')}
                />
              ) : (
                <>
                  <Header>Start Carried Over</Header>
                  <DisplayText>{log?.start_carried_over ? 'On' : 'Off'}</DisplayText>
                </>
              )}
            </SplitOnDiv>
            <Separator />

            <div>
              {editable ? (
                <>
                  <SubHeader>Split times</SubHeader>
                  <DateTimePicker
                    handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'split_off')}
                    dateTime={log?.split_off}
                    headerDate="Split Off"
                    headerTime="Split Off Time"
                  />
                </>
              ) : (
                <>
                  <SubHeader>Split times</SubHeader>
                  <Header>split off</Header>
                  <DisplayText>
                    {log?.split_off ? moment(log?.split_off).utc().format(`${dateFormat} HH:mm`) : '-'}
                  </DisplayText>
                </>
              )}
            </div>
            <SplitOnDiv>
              {editable ? (
                <DateTimePicker
                  handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'split_on')}
                  dateTime={log?.split_on}
                  headerDate="Split On"
                  headerTime="Split On Time"
                />
              ) : (
                <>
                  <Header>split on</Header>
                  <DisplayText>
                    {log?.split_on ? moment(log?.split_on).utc().format(`${dateFormat} HH:mm`) : '-'}
                  </DisplayText>
                </>
              )}
            </SplitOnDiv>
            <Separator />

            <div>
              {editable ? (
                <>
                  <SubHeader>Off times</SubHeader>
                  <DateTimePicker
                    handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'end_time')}
                    dateTime={log?.end_time}
                    headerDate="Off Date"
                    headerTime="Off Time"
                  />
                </>
              ) : (
                <>
                  <SubHeader>Off times</SubHeader>
                  <Header>off</Header>
                  <DisplayText>
                    {log?.end_time ? moment(log?.end_time).utc().format(`${dateFormat} HH:mm`) : '-'}
                  </DisplayText>
                </>
              )}
            </div>
            <SplitOnDiv>
              {editable ? (
                <TFCheckBox
                  value={log?.end_carried_forward}
                  checked={log?.end_carried_forward === true}
                  title="End carried forward"
                  onChange={(value): void => handleCheckboxClick(!value, log?.person_id, 'end_carried_forward')}
                />
              ) : (
                <>
                  <Header>End carried forward</Header>
                  <DisplayText>{log?.end_carried_forward ? 'On' : 'Off'}</DisplayText>
                </>
              )}
            </SplitOnDiv>
          </ContentWrapper>
          {trip?.completion_signature_image_url ? (
            <SignatureSection>
              Trip completion:
              <StyledImg alt="Signature" src={`data:image/png;base64, ${trip?.completion_signature_data}`} />
            </SignatureSection>
          ) : null}
        </Accordion>
      </AccordionWrapper>
    );
  });
  return (
    <SectionWrapper data-testid="TripDrawer-TripCrewDutyLogs">
      {dutyLogArray?.length ? (
        <SectionHeader marginLeft="20px" data-testid="TripCrewDutyLogs-Header">
          {`${dutyNameOverride || 'Crew Duty Logs'}`}
        </SectionHeader>
      ) : null}
      {displayLog}
    </SectionWrapper>
  );
};

export default TripCrewDutyLogs;
