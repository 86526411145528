import React from 'react';
import AuthenticationWrapper, { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import styles from './AuthDropdownMenu.module.less';

const AuthMenuItem = ({
  onMenuItemClick,
  action,
  optionalClass,
  aircraftId,
  resource,
  permissionLevel,
  featureFlag,
}: AuthMenuItemProps): React.ReactElement => {
  return aircraftId ? (
    <AircraftAuthenticationWrapper
      aircraftId={aircraftId}
      requiredResource={resource}
      requiredPermissionLevel={permissionLevel}
      requiredFeatureFlag={featureFlag}
    >
      <button
        type="button"
        className={optionalClass ? `${optionalClass} ${styles.menuItem}` : `${styles.menuItem}`}
        onClick={(e): void => {
          e.stopPropagation();
          onMenuItemClick();
        }}
      >
        {action}
      </button>
    </AircraftAuthenticationWrapper>
  ) : (
    <AuthenticationWrapper requiredResource={resource} requiredPermissionLevel={permissionLevel}>
      <button
        type="button"
        className={optionalClass ? `${optionalClass} ${styles.menuItem}` : `${styles.menuItem}`}
        onClick={(e): void => {
          e.stopPropagation();
          onMenuItemClick();
        }}
      >
        {action}
      </button>
    </AuthenticationWrapper>
  );
};

type AuthMenuItemProps = {
  onMenuItemClick: () => void;
  action: string | JSX.Element;
  optionalClass?: string;
  aircraftId?: string;
  resource: string;
  permissionLevel: string;
  featureFlag?: string;
};

export default AuthMenuItem;
